const baseStyle = {
  bg: "white",
  color: "var(--primary-text)",
  px: "1rem",
  py: "0.5rem",
  mx: "0.5rem",
  borderRadius: "5px",
  fontWeight: "medium",
  fontSize: "1.1rem",
  maxW: "min(95vw, 50rem)",
  zIndex: "tooltip",
  boxShadow: "0px 3px 6px 3px #00000029",
};

export const tooltipTheme = {
  baseStyle,
};
