import {
  Flex,
  Image,
  Modal,
  ModalContent,
  ModalOverlay,
  UseModalProps,
} from "@chakra-ui/react";
import { withPresignedUrl } from "components/HOC/presignedUrl";
import { SvgIcon } from "components/SvgIcon";

const ImagePresigned = withPresignedUrl(Image);

interface Props extends UseModalProps {
  srcImage?: string;
}
function ModalPreviewImage({ isOpen, onClose, srcImage }: Props) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        my="auto"
        w="95%"
        h="95%"
        maxH="85.3rem"
        maxW="132.9rem"
        p="2rem"
      >
        <SvgIcon
          onClick={onClose}
          position="absolute"
          right="1.8rem"
          top="1.8rem"
          src="/img/icon-navigation-close.svg"
          className="button"
        />
        <Flex
          pt="2.4rem"
          mx="auto"
          maxHeight="100%"
          maxWidth="100%"
          margin="auto"
          justifyContent="center"
        >
          <ImagePresigned src={srcImage} />
        </Flex>
      </ModalContent>
    </Modal>
  );
}

export default ModalPreviewImage;
