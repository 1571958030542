import {
  Box,
  Flex,
  FlexProps,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { SvgIcon } from "components/SvgIcon";
import { folderIconPath } from "constants/file";
import { useVisibleTooltip } from "hooks/useVisibleTooltip";
import { DocumentCategoryDTO } from "interfaces/dtos/documentCategoryDTO";
import { DocumentItemDTO } from "interfaces/dtos/documentItemDTO";
import { iDocumentDataProps } from "interfaces/models/document";
import { DocumentCategory } from "interfaces/models/documentCategory";
import { DocumentGroup } from "interfaces/models/documentGroup";
import { WSMessage } from "interfaces/models/websocket";
import isEqual from "lodash/isEqual";
import { isBlockDocumentCategory } from "models/documentCategory";
import React, { memo, useCallback, useEffect, useMemo, useRef } from "react";
import DocumentCategoryDropDown from "./DocumentCategoryDropDown";
import { useFocus } from "./hooks/useFocus";
import DocumentGroupMenu from "./menu/DocumentGroupMenu";

interface Props extends FlexProps {
  currentUserId: string | undefined;
  isOnline: boolean;
  documentGroup: DocumentGroup;
  parentRef: React.MutableRefObject<HTMLElement | undefined>;
  isCreateDocumentItem: boolean;
  isSelect?: boolean;
  selectChildDocumentCategoryId?: string;
  selectChildDocumentItemId?: string;
  selectChildDocumentItemCategoryId?: string;
  documentCategories: DocumentCategoryDTO[];
  mapDocumentItemBlockIdsByTemplateId: { [templateId: string]: Set<string> };
  onToggleVisibleDocumentItem: (
    event: any,
    documentItem: DocumentItemDTO
  ) => Promise<void>;
  onDeletedDocumentItem: (
    documentItem: DocumentItemDTO,
    onOk?: (documentCategory?: DocumentCategoryDTO) => void
  ) => Promise<void>;
  handleClickDocumentGroup?: (documentGroup: DocumentGroup) => void;
  handleClickDocumentCategory: (documentCategory: DocumentCategoryDTO) => void;
  handleClickDocumentItem: (data: iDocumentDataProps) => void;
  onSelectDeleteDocumentGroup: (documentGroup: DocumentGroup) => void;
  onClickAddDocumentItem: (
    e: React.MouseEvent<HTMLDivElement>,
    documentCategory: DocumentCategoryDTO
  ) => void;
  onSelectUpdateMenuItem: (documentGroup: DocumentGroup) => void;
  onSelectCategoryUpdate(category: DocumentCategory): void;
  onSelectCategoryDelete(category: DocumentCategory): void;
  onOpenOfflineModeTutorialModal: () => void;
  sendWebSocketMessage: (message: WSMessage) => void;
}

const DocumentGroupDropDown = ({
  currentUserId,
  isOnline,
  documentGroup,
  parentRef,
  isCreateDocumentItem,
  isSelect,
  selectChildDocumentCategoryId,
  selectChildDocumentItemId,
  selectChildDocumentItemCategoryId,
  documentCategories,
  mapDocumentItemBlockIdsByTemplateId,
  onToggleVisibleDocumentItem,
  onDeletedDocumentItem,
  handleClickDocumentGroup,
  handleClickDocumentCategory,
  handleClickDocumentItem,
  onClickAddDocumentItem,
  onSelectUpdateMenuItem,
  onSelectDeleteDocumentGroup,
  onSelectCategoryUpdate,
  onSelectCategoryDelete,
  onOpenOfflineModeTutorialModal,
  sendWebSocketMessage,
  ...rest
}: Props) => {
  const { isOpen, onToggle } = useDisclosure();

  const documentGroupTitleContainerRef = useRef<HTMLDivElement>(null);

  const onClickToggle = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      onToggle();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onToggle]
  );

  const handleClickGroup = useCallback(() => {
    if (isOpen && isSelect) {
      onToggle();
    }
    handleClickDocumentGroup?.(documentGroup);
  }, [isOpen, documentGroup, isSelect, handleClickDocumentGroup, onToggle]);

  const isSelected =
    !!isSelect ||
    !!selectChildDocumentCategoryId ||
    !!selectChildDocumentItemId;

  const { isShowTooltip, onChangeTextRef } = useVisibleTooltip({
    wrapperRef: documentGroupTitleContainerRef,
  });

  const { onChangeRef } = useFocus(!!isSelect, parentRef);

  useEffect(() => {
    if (!isOpen && isSelected) {
      onToggle();
    }
  }, [isSelected]);

  useEffect(() => {
    if (!isOpen) {
      if (!!selectChildDocumentItemId || selectChildDocumentCategoryId) {
        onToggle();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!selectChildDocumentItemId, selectChildDocumentCategoryId]);

  const isOnlySelectedDocumentGroup = isSelect;

  const children = useMemo(() => {
    if (!isOpen) {
      return <></>;
    }

    return documentCategories?.map((category) => {
      const isSelect = category.id === selectChildDocumentCategoryId;

      return (
        <DocumentCategoryDropDown
          key={category.id}
          currentUserId={currentUserId}
          isOnline={isOnline}
          documentCategory={category}
          parentRef={parentRef}
          isFromDocumentGroup={true}
          isCreateDocumentItem={isCreateDocumentItem && isSelect}
          ml="3.2rem"
          width="calc(100% - 3.2rem)"
          highlightColor="#E0F2FE"
          isSelect={isSelect}
          selectChildId={
            category.id === selectChildDocumentItemCategoryId
              ? selectChildDocumentItemId
              : undefined
          }
          setDocumentItemBlockIds={
            mapDocumentItemBlockIdsByTemplateId[category.templateId]
          }
          isBlockDocumentCategory={isBlockDocumentCategory(category.blockedBy)}
          onToggleVisibleDocumentItem={onToggleVisibleDocumentItem}
          onDeletedDocumentItem={onDeletedDocumentItem}
          handleClickDocumentCategory={handleClickDocumentCategory}
          handleClickDocumentItem={handleClickDocumentItem}
          onClickAddDocumentItem={onClickAddDocumentItem}
          onSelectUpdate={onSelectCategoryUpdate}
          onSelectDelete={onSelectCategoryDelete}
          onOpenOfflineModeTutorialModal={onOpenOfflineModeTutorialModal}
          sendWebSocketMessage={sendWebSocketMessage}
        />
      );
    });
  }, [
    isOpen,
    currentUserId,
    isOnline,
    isCreateDocumentItem,
    parentRef,
    selectChildDocumentCategoryId,
    documentCategories,
    selectChildDocumentItemCategoryId,
    selectChildDocumentItemId,
    mapDocumentItemBlockIdsByTemplateId,
    sendWebSocketMessage,
    onOpenOfflineModeTutorialModal,
    onToggleVisibleDocumentItem,
    onDeletedDocumentItem,
    handleClickDocumentCategory,
    handleClickDocumentItem,
    onClickAddDocumentItem,
    onSelectCategoryUpdate,
    onSelectCategoryDelete,
  ]);

  return (
    <Box
      bgColor={isSelected ? "#F0F9FF" : "transparent"}
      width="100%"
      position="relative"
      boxShadow="0px 1px 2px 0px #0000004D"
      ref={onChangeRef}
      id={`doc-group-${documentGroup?.id}`}
    >
      <Flex
        height="4.4rem"
        cursor="pointer"
        alignItems="center"
        width="100%"
        sx={
          isOnlySelectedDocumentGroup
            ? {
                "&::before": {
                  content: '""',
                  zIndex: 2,
                  position: "absolute",
                  height: "4.4rem",
                  borderLeft: "4px solid #009BE0",
                  left: rest.pl,
                },
              }
            : {}
        }
        onClick={handleClickGroup}
      >
        <Flex w="4rem" h="4rem" alignItems="center" justifyContent="center">
          <SvgIcon
            src="/img/icon-expand-item-left-bar.svg"
            w="2rem"
            transition="0.1s"
            transform={isOpen ? "rotate(90deg)" : "rotate(0deg)"}
            className="button"
            onClick={onClickToggle as any}
          />
        </Flex>

        <Flex alignItems="center" gap="0.4rem" w="70%" flexGrow={2}>
          <SvgIcon
            flexShrink={0}
            width="2.4rem"
            height="2.4rem"
            src={folderIconPath}
            sx={{
              path: {
                fill: isOnlySelectedDocumentGroup ? "#171717" : "#737373",
              },
            }}
          />
          <Box ref={documentGroupTitleContainerRef} height="2.4rem" flex={1}>
            <Tooltip
              placement="top-start"
              label={documentGroup?.name}
              isDisabled={!isShowTooltip}
            >
              <Text
                ref={onChangeTextRef}
                width="fit-content"
                noOfLines={1}
                marginTop="-1px"
                fontSize="1.6rem"
                height="2.4rem"
                color={isOnlySelectedDocumentGroup ? "#171717" : "#737373"}
                fontWeight={isOnlySelectedDocumentGroup ? 700 : 500}
              >
                {documentGroup?.name}
              </Text>
            </Tooltip>
          </Box>
        </Flex>
        <Flex pr="1rem">
          <DocumentGroupMenu
            onSelectUpdateMenuItem={() => onSelectUpdateMenuItem(documentGroup)}
            onSelectDeleteMenuItem={() =>
              onSelectDeleteDocumentGroup(documentGroup)
            }
            isDisabled={!isOnline}
          />
        </Flex>
      </Flex>

      <Box display={isOpen ? "block" : "none"} position="relative">
        {isOpen && <Box>{children}</Box>}
      </Box>
    </Box>
  );
};

const areEquals = (prevProps: any, nextProps: any): boolean => {
  return isEqual(prevProps, nextProps);
};

export default memo(DocumentGroupDropDown, areEquals);
