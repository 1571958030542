import React, { memo } from "react";
import { ModalInputProps } from "../../ui/ModalInput";
import { css } from "@emotion/react";
import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { SvgIcon } from "components/SvgIcon";

const ModalConfirmDelete = memo<{
  isOpen?: boolean;
  isLoading: boolean;
  isCentered?: boolean;
  minWidth?: number;
  iconHeader: React.ReactNode;
  titleHeader: string;
  content?: React.ReactNode;
  onClose: () => void;
  onApply: () => Promise<any>;
  modalProps?: ModalInputProps;
}>(
  ({
    isOpen = true,
    iconHeader,
    content,
    titleHeader,
    isCentered = true,
    isLoading,
    onApply,
    onClose,
  }) => {
    return (
      <Modal isOpen={isOpen} onClose={onClose} isCentered={isCentered}>
        <ModalOverlay />
        <ModalContent
          sx={{
            borderRadius: 0,
          }}
          maxW={"100%"}
          flex={`0 0 1000px`}
        >
          <ModalHeader
            css={css`
              border-bottom: 1px solid #dedede;
            `}
            pt="3rem"
            pl="4rem"
            pr="4rem"
          >
            <ModalCloseButton
              fontSize="12px"
              color="#737373"
              _focus={{
                border: "none",
              }}
              pt="1rem"
            />
            <Box
              css={css`
                display: flex;
                flex-direction: row;
                align-items: center;
                padding-top: 3rem;
                gap: 1rem;
              `}
            >
              {iconHeader}
              <Box
                css={css`
                  fontweight: 700;
                  font-size: 2.4rem; /* 36px */
                  line-height: 2.88rem; /* 40px */
                `}
              >
                {titleHeader}
              </Box>
            </Box>
          </ModalHeader>
          <ModalBody>
            <Flex p="3rem 8rem" direction={"column"}>
              {content}
            </Flex>
          </ModalBody>
          <ModalFooter
            css={css`
              padding-top: 2rem;
              padding-bottom: 2rem;
              gap: 1rem;
              margin-top: -6px;
              background: white;
            `}
          >
            <Button
              isLoading={isLoading}
              sx={{
                height: "4rem",
                background: "white",
                border: "1px solid #A3A3A3",
                color: "#009BE0",
                fontSize: "1.4rem",
                borderRadius: "4px",
                padding: "0 1.6rem",
              }}
              onClick={isLoading ? () => {} : onClose}
            >
              キャンセル
            </Button>
            <Button
              isLoading={isLoading}
              sx={{
                height: "4rem",
                background: "#DC2626",
                border: "none",
                color: "white",
                fontSize: "1.4rem",
                fontWeight: "600",
                borderRadius: "6px",
                padding: "0 1.6rem",
              }}
              onClick={onApply}
            >
              <SvgIcon
                pathFill="white"
                w="2.4rem"
                h="2.4rem"
                src="/img/icon-trash.svg"
              />{" "}
              <Text ml="0.5rem">削除する</Text>
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }
);

export default ModalConfirmDelete;
