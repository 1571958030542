import { authApi, userApi } from "apiClient/v2";
import { defaultAvatarPath } from "constants/file";
import { UserDTO } from "interfaces/dtos/UserDTO";
import { User } from "interfaces/models/user";
import isEqual from "lodash/isEqual";
import { setOnline, setShouldLogout } from "redux/appSlice";
import store from "redux/store";
import { setCurrentUser } from "redux/userSlice";
import { getPreSignUrl, imageUrlToBase64 } from "./file";

let logoutRequest: any;

export const storageUser = (user: User) => {
  const currentUser = store.getState().user.currentUser;
  if (!isEqual(currentUser, user)) {
    store.dispatch(setCurrentUser(user));
  }

  localStorage.setItem("userInfo", JSON.stringify(user));
};

export const getLocalStorageUser = (): User | null => {
  const user = localStorage.getItem("userInfo");

  return user ? JSON.parse(user) : null;
};

export const deleteLocalStorageUser = () => {
  store.dispatch(setCurrentUser(null));

  return localStorage.removeItem("userInfo");
};

export const getListUser = async (shouldCache = false) => {
  const { data } = await userApi.getUserList(shouldCache);
  const mapPictureBase64 = Object.assign(
    {},
    ...(await Promise.all(
      data?.map((user) => {
        return new Promise(async (resolve) => {
          const valueDefault = { [user.id!]: defaultAvatarPath };

          if (!user.id) {
            return resolve(valueDefault);
          }

          if (!user.avatar) {
            return resolve(valueDefault);
          }

          const presignedUrl = await getPreSignUrl(
            user.avatar,
            "",
            false,
            shouldCache
          );

          if (!presignedUrl) {
            return resolve(valueDefault);
          }

          const base64 = await imageUrlToBase64(presignedUrl).catch(
            () => defaultAvatarPath
          );

          return resolve({ [user.id]: base64 });
        });
      })
    ))
  );
  const newDataItems: UserDTO[] = data?.map((item) => {
    return {
      ...item,
      avatarBase64: mapPictureBase64[item?.id || ""],
    };
  });

  return newDataItems;
};

export const logout = async () => {
  if (!store.getState().app.isOnline) {
    return;
  }

  logoutRequest = logoutRequest || authApi.logoutFromPlanet();
  store.dispatch(setOnline(true));
  await logoutRequest;
  logoutRequest = null;
  store.dispatch(setShouldLogout());
};
