import { ComponentStyleConfig } from "@chakra-ui/react";

export const progressStyle: ComponentStyleConfig = {
  variants: {
    blue: {
      filledTrack: {
        bg: "#1795FE",
      },
    },
    red: {
      filledTrack: {
        bg: "#CE2121",
      },
    },
    orange: {
      filledTrack: {
        bg: "#F29C26",
      },
    },
    green: {
      filledTrack: {
        bg: "#21BA45",
      },
    },
  },
};
