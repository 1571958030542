import { IconButton, IconButtonProps } from "@chakra-ui/react";
import { SvgIcon } from "components/SvgIcon";

interface Props extends Partial<Omit<IconButtonProps, "icon">> {
  icon?: string;
  isDisabled?: boolean;
}

const ButtonPlus = ({
  icon = "/img/icon-plus.svg",
  isDisabled = false,
  ...restProps
}: Props) => {
  return (
    <IconButton
      aria-label="icon-plus"
      p="1.1rem"
      ml="auto"
      bgColor="#fff"
      border="1px solid #A3A3A3"
      icon={<SvgIcon src={icon} />}
      borderRadius="6px"
      isDisabled={isDisabled}
      {...restProps}
    />
  );
};

export default ButtonPlus;
