import { useState } from "react";

interface Props {
  wrapperRef: React.RefObject<HTMLDivElement>;
}

export const useVisibleTooltip = (props: Props) => {
  const { wrapperRef } = props;
  const [isShowTooltip, setIsShowTooltip] = useState(false);

  const onChangeTextRef = (e: any) => {
    if (!e) {
      return;
    }
    const wrapperWidth = wrapperRef.current?.clientWidth;
    const width = e.clientWidth;
    const shouldShowTooltip = !!wrapperWidth && width >= wrapperWidth;
    if (shouldShowTooltip !== isShowTooltip) {
      setIsShowTooltip(shouldShowTooltip);
    }
  };

  return { isShowTooltip, onChangeTextRef };
};
