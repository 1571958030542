import { Box, Flex, FlexProps, Image, Text } from "@chakra-ui/react";
import { IconBase } from "components/base";
import { SvgIcon } from "components/SvgIcon";
import {
  DeadlineType,
  InspectionItemType,
  MapInspectionItemIconStatus,
  SIZE_ICON_TASK_STATUS,
  SystemModeType,
} from "constants/enum";
import { defaultAvatarPath } from "constants/file";
import { TaskDTO } from "interfaces/dtos/taskDTO";
import { UserDTO } from "interfaces/dtos/UserDTO";
import { isEmpty } from "lodash";
import { handleSelectTask } from "models/task";
import { useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { RootState } from "redux/store";
import { getDeadlineType } from "utils/dashboard";
import { formatDate } from "utils/date";
import { getStatusColor } from "utils/forge/extensions/custom-label/utils";
import CustomTooltipByScroll from "./CustomTooltipByScroll";
import { EventDetector, EventType } from "utils/handler";
import useForgeViewerZoomToLabel from "pages/forge-viewer/LeftPanel/document/hooks/useForgeViewerZoomToLabel";
import { isMobile } from "react-device-detect";

interface Props extends FlexProps {
  data: TaskDTO;
  users: UserDTO[];
  parentRef: React.RefObject<HTMLElement | undefined>;
}

function CellTask({ data, users, parentRef, ...rest }: Props) {
  const { taskTypes, taskSelected } = useSelector(
    (state: RootState) => state.task
  );

  const { settings } = useSelector((state: RootState) => state.user);

  const ref = useRef<any>();
  const dataRef = useRef(data);
  const dispatch = useDispatch();
  const location = useLocation();

  const areaTitle = useMemo(() => {
    return "";
  }, []);

  const autoScrollToSelectedTask = () => {
    if (taskSelected?.id && data.id === taskSelected?.id) {
      setTimeout(() => {
        if (ref.current)
          ref.current.scrollIntoView({
            behavior: "auto",
            block: "center",
          });
      }, 500);
    }
  };

  useMemo(() => {
    autoScrollToSelectedTask();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskSelected?.id, settings]);

  const name = useMemo(() => {
    if (!data.taskTypeId) return "-";

    return taskTypes.find((item) => item.id === data.taskTypeId)?.title || "-";
  }, [data.taskTypeId, taskTypes]);

  const userAvatar = useMemo(() => {
    return users.length ? users[0].avatarBase64 : defaultAvatarPath;
  }, [users]);

  const isSelected = taskSelected?.id === data.id;

  const deadlineType = useMemo(() => {
    return getDeadlineType(data?.deadline as string);
  }, [data?.deadline]);

  const isSelectedRef = useRef(isSelected);
  isSelectedRef.current = isSelected;

  const { onZoom } = useForgeViewerZoomToLabel();

  const detector = useRef(
    new EventDetector({
      onDoubleEvent: () => {
        if (isSelectedRef.current) {
          onZoom(dataRef.current.id);
        } else {
          setTimeout(() => {
            onZoom(dataRef.current.id);
          }, 100);
          handleSelectTask(dataRef.current, dispatch);
        }
      },
      onEvent: () => {
        handleSelectTask(dataRef.current, dispatch);
      },
    })
  );

  useEffect(() => {
    dataRef.current = data;
  }, [data]);

  useEffect(() => {
    const taskSelected = (location.state as any)?.taskSelected;

    if (!isEmpty(taskSelected)) {
      handleSelectTask(taskSelected, dispatch);
    }
  }, [dispatch, location]);

  const event = useMemo(() => {
    return isMobile
      ? {
          ...rest,
          onTouchMove: detector.current.dispatchEvent(EventType.TOUCH_MOVE),
          onTouchStart: detector.current.dispatchEvent(EventType.TOUCH_START),
          onTouchEnd: detector.current.dispatchEvent(EventType.TOUCH_END),
        }
      : {
          ...rest,
          onClick: detector.current.dispatchEvent(EventType.CLICK),
        };
  }, [rest]);

  return (
    <Flex
      bgColor={isSelected ? "#F0F9FF" : "#FFFFFF"}
      borderTop="1px solid #E2E2E3"
      color="#000000CC"
      fontSize="1.4rem"
      cursor="pointer"
      alignItems="flex-start"
      p="1rem"
      ref={ref}
      {...event}
    >
      <Flex flexDir="column" flex={4} gap="0.5rem">
        <Flex gap="0.5rem">
          <IconBase
            width="24px"
            height="24px"
            color={getStatusColor(data.status, SystemModeType.Task)}
            icon="/img/pin-on-map.svg"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <IconBase
              marginTop="-4px"
              {...SIZE_ICON_TASK_STATUS[
                (data.status || InspectionItemType.Defect) as InspectionItemType
              ]}
              color="white"
              icon={
                MapInspectionItemIconStatus[
                  (data.status ||
                    InspectionItemType.Defect) as InspectionItemType
                ]
              }
            />
          </IconBase>
          <Text
            display="flex"
            alignItems="center"
            justifyContent="center"
            backgroundColor="var(--primary-border-color)"
            color="#171717"
            borderRadius="4px"
            px="0.7rem"
          >
            {data.indexId ? `#${data.indexId}` : "-"}
          </Text>
          {areaTitle && (
            <Text
              maxWidth={{
                base: "4.5rem",
                lg: "10rem",
                xl: "14rem",
              }}
              display="flex"
              alignItems="center"
              justifyContent="center"
              overflow="hidden"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              backgroundColor="#F2F2F2"
              borderRadius="4px"
              px="0.7rem"
            >
              {areaTitle}
            </Text>
          )}
        </Flex>
        <CustomTooltipByScroll
          parentRef={parentRef as any}
          label={name}
          isSelected={isSelected}
          isCheckTextOverflow
          maxW="100%"
          whiteSpace={"pre-wrap"}
        />
      </Flex>

      <Flex
        flexDir="column"
        height="100%"
        padding="1px"
        alignItems="flex-end"
        gap="0.5rem"
      >
        <Flex gap="5px">
          {data.images && data.images.length > 0 && (
            <SvgIcon src="/img/icon-camera-black.svg" />
          )}
          <Box
            display="inline-flex"
            flexDir="row-reverse"
            sx={{
              "& > img:not(:last-child)": {
                marginLeft: "-1.9rem",
              },
            }}
          >
            {users.length > 2 && (
              <Image
                pos="relative"
                flexShrink={0}
                border="1px solid #fff"
                w="2.5rem"
                h="2.5rem"
                borderRadius="50%"
                src={defaultAvatarPath}
              />
            )}
            {users.length > 1 && (
              <Image
                pos="relative"
                flexShrink={0}
                border="1px solid #fff"
                w="2.5rem"
                h="2.5rem"
                borderRadius="50%"
                src={defaultAvatarPath}
              />
            )}
            <Image
              pos="relative"
              flexShrink={0}
              border="1px solid #fff"
              w="2.5rem"
              h="2.5rem"
              borderRadius="50%"
              src={userAvatar}
            />
          </Box>
        </Flex>

        <Flex alignItems="center">
          {deadlineType === DeadlineType.expired && (
            <Box
              height="14px"
              width="14px"
              bg="#DC2626"
              borderRadius="50%"
              color="white"
              display="flex"
              justifyContent="center"
              alignItems="center"
              mr="5px"
              fontSize="9px"
            >
              !
            </Box>
          )}
          <Text
            color={
              deadlineType === DeadlineType.expired
                ? "#DC2626"
                : deadlineType === DeadlineType.aboutToExpire
                ? "#D97706"
                : "#ccc"
            }
            fontWeight={
              deadlineType !== DeadlineType.notExpired ? "700" : "500"
            }
            fontSize="1.2rem"
          >
            {formatDate(data?.deadline, "YYYY/MM/DD")}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default CellTask;
