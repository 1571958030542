import { blackboardTemplateApi } from "apiClient/v2";
import useLoading from "hooks/useLoading";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setBlackboardTemplateList } from "redux/documentSlice";
import { RootState } from "redux/store";

interface iProps {
  isShowLoading?: boolean;
}

const useBlackboardTemplateList = ({ isShowLoading = true }: iProps) => {
  const {
    blackboardTemplateList,
    isOpenSelectBlackboardTemplateModal,
    documentItemSelected,
  } = useSelector((state: RootState) => state.document);

  const { turnOnLoading, turnOffLoading } = useLoading();
  const dispatch = useDispatch();

  const getBlackboardTemplateList = useCallback(async () => {
    isShowLoading && turnOnLoading();

    try {
      (async () => {
        const { data } = (await blackboardTemplateApi.getTemplateList()) || [];
        dispatch(setBlackboardTemplateList(data));
        isShowLoading && turnOffLoading();
      })();
    } catch (err) {
      dispatch(setBlackboardTemplateList([]));
      isShowLoading && turnOffLoading();
    }
  }, [turnOffLoading, turnOnLoading, dispatch, isShowLoading]);

  useEffect(() => {
    if (
      documentItemSelected?.blackboardTemplateId &&
      !blackboardTemplateList.length
    ) {
      getBlackboardTemplateList();
    }
  }, [
    getBlackboardTemplateList,
    documentItemSelected?.blackboardTemplateId,
    blackboardTemplateList.length,
  ]);

  useEffect(() => {
    if (isOpenSelectBlackboardTemplateModal && !blackboardTemplateList.length) {
      getBlackboardTemplateList();
    }
  }, [
    getBlackboardTemplateList,
    isOpenSelectBlackboardTemplateModal,
    blackboardTemplateList.length,
  ]);

  return {
    blackboardTemplateList,
    getBlackboardTemplateList,
  };
};

export default useBlackboardTemplateList;
