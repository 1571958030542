import { ComponentStyleConfig } from "@chakra-ui/react";

export const inputStyle: ComponentStyleConfig = {
  variants: {
    outline: {
      field: {
        borderColor: "border.default",
        _placeholder: {
          opacity: 1,
          color: "font.placeholder",
        },
      },
    },
    underline: {
      field: {
        borderRadius: "0",
        bg: "transparent",
        fontSize: "1.4rem",
        borderBottom: "1px solid #E2E2E3",
        color: "#000000CC",
        _placeholder: {
          opacity: 1,
          color: "font.placeholder",
          fontSize: "1.4rem",
        },
      },
    },
  },
};
