import { Box, Flex, Input, Text } from "@chakra-ui/react";
import Dropdown from "components/Dropdown";
import SelectUserSingle from "components/input/SelectUserSingle";
import { SvgIcon } from "components/SvgIcon";
import DayPicker from "components/ui/DayPicker";
import { TagsInput } from "components/ui/TagInput";
import {
  CellPropertyDocumentDataForTableOptions,
  CellPropertyDocumentDataForTableOptionType,
} from "constants/documentTemplate";
import { SelectUserSingleSx } from "constants/dropdown";
import {
  DocumentCategoryKey,
  DocumentStatusType,
  LinkedDataField,
  MapExtractionInspectionResultType,
} from "constants/enum";
import { DocumentCategoryDTO } from "interfaces/dtos/documentCategoryDTO";
import { UserDTO } from "interfaces/dtos/UserDTO";
import { CellType } from "interfaces/models/component";
import { DocumentTemplate } from "interfaces/models/documentTemplate";
import { Family } from "interfaces/models/family";
import { PartnerCompany } from "interfaces/models/partnerCompany";
import { Fragment, memo, useCallback, useMemo } from "react";
import Select from "react-select";
import useFormCategoryForDocumentData from "./hook";

interface Props {
  template: DocumentTemplate | undefined;
  partnerCompanies: PartnerCompany[];
  listUserById: {
    [key: string]: UserDTO | null;
  };
  data: DocumentCategoryDTO;
  families: Family[];
  onChange: (key: string) => (e: any) => void;
  onBlur: (key: keyof DocumentCategoryDTO) => () => void;
  isDisabled?: boolean;
}

const FormCategoryForDocumentData = ({
  template,
  listUserById,
  data,
  partnerCompanies,
  families,
  onChange,
  onBlur,
  isDisabled,
}: Props) => {
  const { documentDataCells } = useFormCategoryForDocumentData({ template });

  const renderElementByTypeSelect = useCallback(
    ({ name, field }: { name?: string; field?: string }) => {
      let isRender = true;
      let element = <></>;

      switch (field) {
        case LinkedDataField.DOCUMENT_CATEGORY.TAGS:
          element = (
            <Flex w="calc(100% - 8rem)" flex={1}>
              <TagsInput
                boxProps={{ width: "100%", opacity: isDisabled ? 0.4 : 1 }}
                options={families.map((family) => ({
                  name: family.name,
                  value: family.name,
                }))}
                value={data?.tags}
                placeHolder="タグ"
                isWithSubmenu
                onChange={onChange(DocumentCategoryKey.TAGS)}
                onBlur={onBlur(DocumentCategoryKey.TAGS)}
                isDisable={isDisabled}
              />
            </Flex>
          );

          break;

        case LinkedDataField.DOCUMENT_CATEGORY.INSPECTION_RESULT:
          const options = Object.keys(MapExtractionInspectionResultType).map(
            (key) => ({
              name: MapExtractionInspectionResultType[
                key as keyof typeof MapExtractionInspectionResultType
              ],
              value: key,
            })
          );
          const value = data?.inspectionResult || DocumentStatusType.Incomplete;

          element = (
            <Flex w="calc(100% - 8rem)" flex={1}>
              <Dropdown
                options={options}
                onChange={onChange(field)}
                value={value}
                defaultColor={
                  value === options[1]?.value
                    ? "#2084D0"
                    : value === options[2]?.value
                    ? "#CE2121"
                    : "#333"
                }
                isDisabled={isDisabled}
              />
            </Flex>
          );
          break;

        case LinkedDataField.DOCUMENT_CATEGORY.PARTNER_COMPANY:
          element = (
            <Flex w="calc(100% - 8rem)" flex={1}>
              <Select
                options={partnerCompanies}
                getOptionValue={(item) => item.id}
                getOptionLabel={(item) => item.name}
                noOptionsMessage={() => "オプションなし"}
                placeholder="協力会社"
                value={partnerCompanies.find(
                  (item) => item.id === data?.partnerCompany
                )}
                onChange={(selected) => {
                  onChange(DocumentCategoryKey.PARTNER_COMPANY)({
                    value: selected?.id,
                  });
                  onBlur(DocumentCategoryKey.PARTNER_COMPANY);
                }}
                styles={{
                  menu: (base) => ({
                    ...base,
                    zIndex: "var(--chakra-zIndices-dropdown)",
                  }),
                  container: (baseStyles) => ({
                    ...baseStyles,
                    width: "100%",
                    opacity: isDisabled ? 0.4 : 1,
                  }),
                  input: (baseStyles) => ({
                    ...baseStyles,
                    height: "3.4rem",
                  }),
                }}
                isDisabled={isDisabled}
              />
            </Flex>
          );
          break;

        case LinkedDataField.DOCUMENT_CATEGORY.USER_IMPLEMENT:
        case LinkedDataField.DOCUMENT_CATEGORY.USER_MEASURE:
        case LinkedDataField.DOCUMENT_CATEGORY.USER_MANAGE:
        case LinkedDataField.DOCUMENT_CATEGORY.MANAGER:
          const opt = data?.[field];

          element = (
            <Flex w="calc(100% - 8rem)" flex={1}>
              <SelectUserSingle
                users={listUserById}
                value={opt}
                onChange={onChange(field)}
                width="100%"
                components={{
                  DropdownIndicator: () => (
                    <SvgIcon
                      src="/img/icon-react-select-custom-dropdown.svg"
                      mx=".8rem"
                    />
                  ),
                }}
                sx={SelectUserSingleSx}
                zIndex={undefined as any}
                placeHolder={name}
                styles={{
                  menu: (base) => ({
                    ...base,
                    zIndex: "var(--chakra-zIndices-dropdown)",
                  }),
                }}
                isDisabled={isDisabled}
              />
            </Flex>
          );
          break;

        default:
          isRender = false;
      }

      return { isRender, element };
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, listUserById, partnerCompanies]
  );

  const renderElementByTypeInput = useCallback(
    ({ name, field }: { name?: string; field?: string }) => {
      let element = <></>;
      let isRender = true;

      switch (field) {
        case LinkedDataField.DOCUMENT_CATEGORY.CORPORATE_COMPANY:
        case LinkedDataField.DOCUMENT_CATEGORY.SYSTEM_NAME:
        case LinkedDataField.DOCUMENT_CATEGORY.MEASURING_DEVICE:
        case LinkedDataField.DOCUMENT_CATEGORY.DUCT_SYSTEM:
        case LinkedDataField.DOCUMENT_CATEGORY.MANUFACTOR:
        case LinkedDataField.DOCUMENT_CATEGORY.CONSTRUCTION_NAME:
          const value = data?.[field];

          element = (
            <Flex width="calc(100% - 8rem)">
              <Input
                height="4.4rem"
                fontSize="1.4rem"
                width="100%"
                placeholder={name}
                onChange={onChange(field)}
                onBlur={onBlur(field)}
                value={value || ""}
                isDisabled={isDisabled}
              />
            </Flex>
          );
          break;

        default:
          isRender = false;
      }

      return { isRender, element };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  const renderElementByTypeDate = useCallback(
    ({ field }: { name?: string; field?: string }) => {
      let element = <></>;
      let isRender = true;

      switch (field) {
        case LinkedDataField.DOCUMENT_CATEGORY.APPROVE_DATE:
        case LinkedDataField.DOCUMENT_CATEGORY.INSPECTION_DATE:
        case LinkedDataField.DOCUMENT_CATEGORY.MEASURE_DATE:
        case LinkedDataField.DOCUMENT_CATEGORY.DEADLINE:
        case LinkedDataField.DOCUMENT_CATEGORY.IMPLEMENT_DATE:
          const opt = data?.[field];

          element = (
            <Box flex="1">
              <DayPicker
                name={field}
                value={opt}
                onSelectedDay={onChange(field)}
                onClose={onBlur(field)}
                isDisabled={isDisabled}
              />
            </Box>
          );
          break;

        default:
          isRender = false;
      }

      return { isRender, element };
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  const renderField = useCallback(
    (cell: CellType) => {
      const option = CellPropertyDocumentDataForTableOptions.find(
        (opt) => opt.value === cell.cellLinkedData?.field
      );
      const type = option?.type;
      const name = option?.name;

      let data = {
        element: <></>,
        isRender: true,
      };

      switch (type) {
        case CellPropertyDocumentDataForTableOptionType.SELECT:
          data = renderElementByTypeSelect({
            name,
            field: option?.value,
          });

          break;

        case CellPropertyDocumentDataForTableOptionType.INPUT:
          data = renderElementByTypeInput({
            name,
            field: option?.value,
          });
          break;

        case CellPropertyDocumentDataForTableOptionType.DATE:
          data = renderElementByTypeDate({
            name,
            field: option?.value,
          });

          break;

        default:
          data = {
            isRender: false,
            element: <></>,
          };
          break;
      }

      return { name, element: data.element, isRender: data.isRender };
    },
    [
      renderElementByTypeSelect,
      renderElementByTypeInput,
      renderElementByTypeDate,
    ]
  );

  const renderFields = useMemo(() => {
    return documentDataCells.map((cell) => {
      const { name, element, isRender } = renderField(cell);

      if (!isRender) {
        return <Fragment key={cell.cellId}></Fragment>;
      }

      return (
        <Flex w="100%" alignItems="center" key={cell.cellId} minH="4.8rem">
          <Text textAlign="left" flexBasis="8rem">
            {name}
          </Text>
          {element}
        </Flex>
      );
    });
  }, [documentDataCells, renderField]);

  return (
    <Flex width="100%" flexDirection="column" gap="1rem">
      {renderFields}
    </Flex>
  );
};

export default memo(FormCategoryForDocumentData);
