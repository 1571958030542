import { DocumentCategoryKey, DocumentItemKey } from "constants/enum";
import { DocumentCategoryDTO } from "interfaces/dtos/documentCategoryDTO";
import { DocumentItemDTO } from "interfaces/dtos/documentItemDTO";
import { TaskDTO } from "interfaces/dtos/taskDTO";
import { DocumentSubItem } from "interfaces/models/documentItem";
import { useCallback, useRef } from "react";

export enum TypeHandleInitData {
  TASK,
  SUB_ITEM,
  DOCUMENT_CATEGORY,
  DOCUMENT_ITEM,
}

const useSupportSyncDataOffline = <T>() => {
  const fieldUpdateDataRef = useRef<(keyof T)[]>([]);
  const initDataRef = useRef<T | undefined | null>(null);

  const clearFieldUpdateData = useCallback(() => {
    fieldUpdateDataRef.current = [];
  }, []);

  const handleUpdateFieldsChangeData = useCallback((keys: (keyof T)[]) => {
    fieldUpdateDataRef.current = [
      ...new Set([...(fieldUpdateDataRef.current || []), ...keys]),
    ];
  }, []);

  const handleInitData = useCallback(
    ({ data, type }: { data: T; type: TypeHandleInitData }) => {
      fieldUpdateDataRef.current = [];
      const initData = {} as any;
      let fieldsInitData = [] as any[];
      const dataDefaultByField: Record<string, any> = {};
      if (type === TypeHandleInitData.TASK) {
        fieldsInitData = [
          "tags",
          "userAssigned",
          "images",
          "confirmedImages",
          "attaches",
        ] as (keyof TaskDTO)[];
        fieldsInitData.forEach((field) => {
          dataDefaultByField[field] = [];
        });
      }

      if (type === TypeHandleInitData.DOCUMENT_ITEM) {
        fieldsInitData = [
          DocumentItemKey.TAGS,
          DocumentItemKey.ATTACHES,
          DocumentItemKey.DATA,
        ] as (keyof DocumentItemDTO)[];

        fieldsInitData.forEach((field) => {
          dataDefaultByField[field] = [];
          if (field === DocumentItemKey.DATA) {
            dataDefaultByField[field] = {};
          }
        });
      }

      if (type === TypeHandleInitData.DOCUMENT_CATEGORY) {
        fieldsInitData = [
          DocumentCategoryKey.TAGS,
          DocumentCategoryKey.USER_ASSIGNED,
          DocumentCategoryKey.DATA,
          DocumentCategoryKey.USER_APPROVED,
          DocumentCategoryKey.PARTNER_USERS,
          DocumentCategoryKey.SELECTED_EXTERNAL_IDS,
          DocumentCategoryKey.NEPTUNE_AREA_IDS,
          DocumentCategoryKey.ITEM_IDS,
        ] as (keyof DocumentCategoryDTO)[];

        fieldsInitData.forEach((field) => {
          dataDefaultByField[field] = [];
          if (field === DocumentCategoryKey.DATA) {
            dataDefaultByField[field] = {};
          }
        });
      }

      if (type === TypeHandleInitData.SUB_ITEM) {
        fieldsInitData = [
          "attaches",
          "takeImageDates",
          "data",
        ] as (keyof DocumentSubItem)[];

        fieldsInitData.forEach((field) => {
          dataDefaultByField[field] = [];
          if (field === "data") {
            dataDefaultByField[field] = {};
          }
        });
      }
      fieldsInitData.forEach((key) => {
        initData[key] = data?.[key as keyof T] || dataDefaultByField[key];
      });

      initDataRef.current = {
        ...data,
        initData,
      };
    },
    []
  );

  return {
    fieldUpdateDataRef,
    initDataRef,
    clearFieldUpdateData,
    handleUpdateFieldsChangeData,
    handleInitData,
  };
};

export default useSupportSyncDataOffline;
