import { LinkedDataField } from "constants/enum";

export const icons = {
  selection:
    '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 27.98 27.981"><path id="Path_1311" data-name="Path 1311" d="M277.37,43.813c-.812-.32-1.216-.479-1.476-.393a.752.752,0,0,0-.474.475c-.085.258.074.664.393,1.475l7.952,20.183c.256.653.385.979.6,1.111a.748.748,0,0,0,.612.083c.237-.071.447-.352.869-.913l3.211-4.281,5.174,7.114c.285.391.426.587.607.668a.752.752,0,0,0,.507.04c.192-.052.363-.224.705-.565l4.764-4.764c.342-.342.513-.513.565-.7a.755.755,0,0,0-.041-.506c-.081-.181-.277-.324-.669-.608l-7.113-5.174,4.281-3.211c.561-.421.841-.632.912-.868a.741.741,0,0,0-.082-.612c-.131-.21-.458-.338-1.11-.6Z" transform="translate(-274.422 -42.422)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/></svg>',
  alignleft:
    '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 27.5 20"><path id="Path_1308" data-name="Path 1308" d="M22.5,92H3m25.5-6H3M28.5,98H3m19.5,6H3" transform="translate(-2 -85)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/></svg>',
  aligncenter:
    '<svg xmlns="http://www.w3.org/2000/svg" width="29" height="20" viewBox="0 0 29 20"><path id="Path_1306" data-name="Path 1306" d="M25.5,12H7.5M30,6H3M30,18H3m22.5,6H7.5" transform="translate(-2 -5)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/></svg>',
  alignright:
    '<svg xmlns="http://www.w3.org/2000/svg" width="27.5" height="20" viewBox="0 0 27.5 20"><path id="Path_1309" data-name="Path 1309" d="M29.5,132H10m19.5-6H4m25.5,12H4m25.5,6H10" transform="translate(-3 -125)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/></svg>',
  alignjustify:
    '<svg xmlns="http://www.w3.org/2000/svg" width="29" height="20" viewBox="0 0 29 20"><path id="Path_1307" data-name="Path 1307" d="M30,52H3M30,64H3M30,46H3M30,58H3" transform="translate(-2 -45)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/></svg>',
  decreaseindent:
    '<svg xmlns="http://www.w3.org/2000/svg" width="29" height="26" viewBox="0 0 29 26"><path id="Path_1313" data-name="Path 1313" d="M574,1004H547m27,24H547m13.5-16.125H547m13.5,8.25H547m25.08-9.435-5.8,4.35c-.433.33-.651.495-.729.69a.745.745,0,0,0,0,.54c.078.195.3.36.729.69l5.8,4.35c.618.465.927.69,1.185.69a.728.728,0,0,0,.576-.285c.159-.21.159-.6.159-1.365v-8.7c0-.765,0-1.155-.159-1.365a.728.728,0,0,0-.576-.285C573.007,1010,572.7,1010.225,572.08,1010.69Z" transform="translate(-546 -1003)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/></svg>',
  increaseindent:
    '<svg xmlns="http://www.w3.org/2000/svg" width="29" height="26" viewBox="0 0 29 26"><path id="Path_1312" data-name="Path 1312" d="M302,971.875H288.5M302,964H275m27,16.125H288.5M302,988H275m1.92-17.16,5.8,4.35c.433.325.651.489.729.688a.753.753,0,0,1,0,.543c-.078.2-.3.363-.729.688l-5.8,4.35c-.618.464-.927.695-1.185.69a.754.754,0,0,1-.576-.288c-.159-.2-.159-.59-.159-1.362v-8.7c0-.773,0-1.158.159-1.362a.755.755,0,0,1,.576-.288C275.993,970.146,276.3,970.376,276.92,970.84Z" transform="translate(-274 -963)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/></svg>',
  bold: '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 26.75 26"><path id="Path_1310" data-name="Path 1310" d="M7,404v24m5.25-24h9a6,6,0,0,1,0,12h1.5a6,6,0,0,1,0,12H12.25m0-24v24m0-24H4m8.25,24H4" transform="translate(-3 -403)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/></svg>',
  underline:
    '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 29 27.5"><path id="Path_1314" data-name="Path 1314" d="M843,884v9a10.5,10.5,0,0,1-21,0v-9m5.25,0v9a10.505,10.505,0,0,0,7.708,10.125M820.5,909.5h24M819,884h11.25m9.75,0h6" transform="translate(-818 -883)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/></svg>',
  link: '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 29.728 29.728"><path id="Path_1317" data-name="Path 1317" d="M17.682,26.167l-2.121,2.121A7.5,7.5,0,1,1,4.955,17.682l2.121-2.121m19.092,2.121,2.121-2.121A7.5,7.5,0,1,0,17.682,4.954L15.561,7.075m-4.189,14.8,10.5-10.5" transform="translate(-1.758 -1.758)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/></svg>',
  negative:
    '<label class="negative" style="padding-top: 2px; color: rgb(255, 255, 255);"><input style="display: none; border: 0px; clip: rect(0px, 0px, 0px, 0px); height: 1px; width: 1px; margin: -1px; padding: 0px; overflow: hidden; white-space: nowrap; position: absolute;" checked="checked" readonly="readonly" type="checkbox" value=""><span aria-hidden="true"><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" style="vertical-align: middle; border-radius: 4px;"><path id="Path" d="M16,0H2A2,2,0,0,0,0,2V16a2,2,0,0,0,2,2H16a2,2,0,0,0,2-2V2A2,2,0,0,0,16,0Z" fill="#db2828"></path><path id="Path_1270" data-name="Path 1270" d="M1.4-3.578h.824V-7.124c0-.709-.066-1.428-.1-2.109h.037L2.9-7.842,5.4-3.578h.889v-6.76H5.462V-6.82c0,.7.066,1.455.112,2.137H5.528L4.8-6.074,2.3-10.338H1.4Zm9.454.12a2.973,2.973,0,0,0,2.125-.783V-7.078H10.732v.709h1.451V-4.6a1.9,1.9,0,0,1-1.236.4c-1.479,0-2.293-1.068-2.293-2.772,0-1.685.9-2.735,2.275-2.735a1.938,1.938,0,0,1,1.488.645l.468-.553a2.63,2.63,0,0,0-1.975-.838c-1.816,0-3.145,1.326-3.145,3.509S9.057-3.459,10.854-3.459Z" transform="translate(1.811 16.169)" fill="#fff"></path></svg></span></label>',
  checked:
    '<label class="checked" style="padding-top: 2px; color: rgb(0, 0, 0); background-color: rgb(255, 255, 255);"><input style="display: none;border: 0px; clip: rect(0px, 0px, 0px, 0px); height: 1px; width: 1px; margin: -1px; padding: 0px; overflow: hidden; white-space: nowrap; position: absolute;" checked="checked" readonly="readonly" type="checkbox"><svg viewBox="0 0 14 14" focusable="false" style="width: 16px; border: 1px solid rgb(212, 212, 213); border-radius: 4px; height: 16px; vertical-align: middle; color: rgb(0, 0, 0);" data-mce-style="width: 16px; border: 2px solid rgb(212, 212, 213); border-radius: 4px; height: 16px; vertical-align: middle; color: rgb(0, 0, 0);"><g fill="currentColor"><polygon points="5.5 11.9993304 14 3.49933039 12.5 2 5.5 8.99933039 1.5 4.9968652 0 6.49933039"></polygon></g></svg></label>',
  unchecked:
    '<label class="unchecked" style="padding-top: 2px; color: rgb(0, 0, 0); background-color: rgb(255, 255, 255);"><input style="display: none; border: 0px; clip: rect(0px, 0px, 0px, 0px); height: 1px; width: 1px; margin: -1px; padding: 0px; overflow: hidden; white-space: nowrap; position: absolute;" readonly="readonly" type="checkbox"><svg viewBox="0 0 14 14" focusable="false" style="width: 16px; border: 1px solid rgb(212, 212, 213); border-radius: 4px; height: 16px; vertical-align: middle; color: rgb(0, 0, 0);" data-mce-style="width: 16px; border: 2px solid rgb(212, 212, 213); border-radius: 4px; height: 16px; vertical-align: middle; color: rgb(0, 0, 0);"><g fill="currentColor"></g></svg></label>',
};

export const iconsSvg = {
  unchecked: `<svg data-field-type="${LinkedDataField.COMMON.CHECK_BOX}" aria-checked="false" viewBox="0 0 14 14" focusable="false" style="cursor: pointer; width: 16px; height: 16px; border: 1px solid rgb(212, 212, 213); border-radius: 4px; vertical-align: middle; color: rgb(0, 0, 0);" data-mce-style="width: 16px; height: 16px; border: 1px solid rgb(212, 212, 213); border-radius: 4px; vertical-align: middle; color: rgb(0, 0, 0);"><g fill="currentColor"></g></svg>`,
  checked: `<svg data-field-type="${LinkedDataField.COMMON.CHECK_BOX}" aria-checked="true" viewBox="0 0 14 14" focusable="false" style="cursor: pointer; width: 16px; border: 1px solid rgb(212, 212, 213); border-radius: 4px; height: 16px; vertical-align: middle; color: rgb(0, 0, 0);" data-mce-style="width: 16px; border: 2px solid rgb(212, 212, 213); border-radius: 4px; height: 16px; vertical-align: middle; color: rgb(0, 0, 0);"><g fill="currentColor"><polygon points="5.5 11.9993304 14 3.49933039 12.5 2 5.5 8.99933039 1.5 4.9968652 0 6.49933039"></polygon></g></svg>`,
};

export const ICON_STATUS_TASK = {
  CONFIRMED: `<svg width="11" height="11" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.89187 3.9348L3.00602 5.1093L6.84461 1.0628C7.04263 0.854267 7.27002 0.75 7.52675 0.75C7.7837 0.75 8.01119 0.854267 8.20922 1.0628C8.40703 1.27155 8.50392 1.51349 8.49988 1.78862C8.49584 2.06374 8.39895 2.30131 8.20922 2.50132L3.81356 7.13507C3.58277 7.37836 3.31359 7.5 3.00602 7.5C2.69845 7.5 2.42927 7.37836 2.19849 7.13507L0.546724 5.37332C0.348908 5.16457 0.25 4.92476 0.25 4.65389C0.25 4.38325 0.348908 4.14355 0.546724 3.9348C0.744753 3.72605 0.970964 3.6238 1.22536 3.62806C1.47976 3.63254 1.70193 3.73479 1.89187 3.9348Z" fill="white"/>
        </svg>`,
  TREATED: `<svg width="14" height="14" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="24" height="24" rx="4" fill="#9668A5"/>
        <g clip-path="url(#clip0_7121_126839)">
        <path d="M12 20C10.4178 20 8.87103 19.5308 7.55544 18.6518C6.23985 17.7727 5.21447 16.5233 4.60897 15.0615C4.00347 13.5997 3.84504 11.9911 4.15372 10.4393C4.4624 8.88743 5.22433 7.46197 6.34315 6.34315C7.46197 5.22433 8.88743 4.4624 10.4393 4.15372C11.9911 3.84504 13.5997 4.00347 15.0615 4.60897C16.5233 5.21447 17.7727 6.23985 18.6518 7.55544C19.5308 8.87103 20 10.4178 20 12C19.9976 14.121 19.154 16.1545 17.6542 17.6542C16.1545 19.154 14.121 19.9976 12 20ZM12 5.6C10.7342 5.6 9.49683 5.97536 8.44435 6.6786C7.39188 7.38184 6.57158 8.38138 6.08717 9.55083C5.60277 10.7203 5.47603 12.0071 5.72298 13.2486C5.96992 14.4901 6.57946 15.6304 7.47452 16.5255C8.36958 17.4205 9.50995 18.0301 10.7514 18.277C11.9929 18.524 13.2797 18.3972 14.4492 17.9128C15.6186 17.4284 16.6182 16.6081 17.3214 15.5557C18.0247 14.5032 18.4 13.2658 18.4 12C18.3979 10.3033 17.7229 8.67663 16.5231 7.47686C15.3234 6.27708 13.6967 5.60212 12 5.6Z" fill="white"/>
        </g>
        <defs>
        <clipPath id="clip0_7121_126839">
        <rect width="16" height="16" fill="white" transform="translate(4 4)"/>
        </clipPath>
        </defs>
      </svg>`,
  DEFECT: `<svg width="11" height="11" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4.25875 5.27188L6.74365 7.75708C6.90967 7.9229 7.11825 8.00781 7.36937 8.01181C7.6207 8.0156 7.83317 7.93069 8.00678 7.75708C8.1804 7.58327 8.2672 7.3727 8.2672 7.12537C8.2672 6.87804 8.1804 6.66747 8.00678 6.49365L5.52188 4.00875L8.00678 1.52385C8.1728 1.35783 8.25771 1.14915 8.26151 0.897826C8.2653 0.646699 8.1804 0.434229 8.00678 0.260418C7.83317 0.0868059 7.6227 0 7.37537 0C7.12784 0 6.91726 0.0868059 6.74365 0.260418L4.25875 2.74562L1.77355 0.260418C1.60773 0.0945975 1.39915 0.0096895 1.14783 0.00569384C0.896499 0.00189796 0.684029 0.0868059 0.510417 0.260418C0.336806 0.434229 0.25 0.644801 0.25 0.892132C0.25 1.13946 0.336806 1.35004 0.510417 1.52385L2.99532 4.00875L0.510417 6.49365C0.344397 6.65967 0.25949 6.86835 0.255694 7.11967C0.251898 7.3708 0.336806 7.58327 0.510417 7.75708C0.684029 7.93069 0.894601 8.0175 1.14213 8.0175C1.38946 8.0175 1.59994 7.93069 1.77355 7.75708L4.25875 5.27188Z" fill="white"/>
        </svg>`,
};
