import { taskSheetTemplateApi } from "apiClient/v2";
import useLoading from "hooks/useLoading";
import { useRoles } from "hooks/usePermission";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store";
import { setTaskSheetTemplateList } from "redux/taskSlice";

interface iUseTaskSheetTemplateList {
  isShowLoading?: boolean;
}

const useTaskSheetTemplateList = ({
  isShowLoading = true,
}: iUseTaskSheetTemplateList) => {
  const { taskSheetTemplateList } = useSelector(
    (state: RootState) => state.task
  );
  const { isTakasagoGroupAndPartnerLeader } = useRoles();
  const { turnOnLoading, turnOffLoading, isLoading } = useLoading();

  const dispatch = useDispatch();

  const getTaskSheetTemplateList = useCallback(async () => {
    if (!isTakasagoGroupAndPartnerLeader) {
      return;
    }

    isShowLoading && turnOnLoading();

    try {
      (async () => {
        const { data } =
          (await taskSheetTemplateApi.getTemplateList({})) || [];
        dispatch(setTaskSheetTemplateList(data));
        isShowLoading && turnOffLoading();
      })();
    } catch (err) {
      dispatch(setTaskSheetTemplateList([]));
      isShowLoading && turnOffLoading();
    }
  }, [
    isShowLoading,
    turnOnLoading,
    isTakasagoGroupAndPartnerLeader,
    turnOffLoading,
    dispatch,
  ]);

  useEffect(() => {
    getTaskSheetTemplateList();
  }, [getTaskSheetTemplateList]);

  return {
    isLoading,
    taskSheetTemplateList,
  };
};

export default useTaskSheetTemplateList;
