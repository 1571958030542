import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { debounce } from "utils/common";

function useTextHover() {
  const textElementRef = useRef<HTMLParagraphElement>(null);
  const { levelSelected } = useSelector(
    (state: RootState) => state.forgeViewer
  );

  // compare once and add resize listener on "componentDidMount"
  useEffect(() => {
    const compareSize = debounce(() => {
      const compare =
        Number(textElementRef.current?.scrollWidth) >
        Number(textElementRef.current?.clientWidth);
      setHover(compare);
    }, 100);

    compareSize();
    window.addEventListener("resize", compareSize);

    return () => {
      window.removeEventListener("resize", compareSize);
    };
  }, [levelSelected]);

  // Define state and function to update the value
  const [hoverStatus, setHover] = useState(false);

  return { hoverStatus, textElementRef };
}

export default useTextHover;
