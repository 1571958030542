import { Box, Flex, Input, Spinner, Text } from "@chakra-ui/react";
import { documentGroupApi } from "apiClient/v2";
import CollapseModalIcon, {
  useCollapseModal,
} from "components/ui/CollapseModalIcon";
import { OPTION_ALL_AREA, OPTION_ALL_FLOOR } from "constants/area";
import { folderOutlineBoldIconPath } from "constants/file";
import { RIGHT_SIDEBAR_MODAL_CLASSNAME } from "constants/styleProps";
import { MessageType } from "constants/websocket";
import { useAppWebSocket } from "hooks/useAppWebSocket";
import { useAuthorization } from "hooks/usePermission";
import { DocumentGroup } from "interfaces/models/documentGroup";

import { LeftPanelHandleType } from "pages/forge-viewer/LeftPanel";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateDocumentGroup } from "redux/documentSlice";
import { RootState } from "redux/store";
import IconBase from "../../base/icon/index";

interface Props {
  documentGroup: DocumentGroup;
  leftPanelRef?: React.RefObject<LeftPanelHandleType>;
  isLoadingDocument: boolean;
}

export type DocumentGroupModelHandleType = {
  handleCollapse: (val?: boolean) => void;
};

const DocumentGroupModal = forwardRef<DocumentGroupModelHandleType, Props>(
  ({ leftPanelRef, documentGroup, isLoadingDocument }, ref) => {
    // const { isOnline } = useSelector((state: RootState) => state.app);
    const [documentGroupSelected, setDocumentGroupSelected] =
      useState<DocumentGroup>();
    const [formInfo, setFormInfo] = useState<DocumentGroup>();
    const { isCollapsed, setIsCollapsed } = useCollapseModal();
    const dispatch = useDispatch();
    const roles = useAuthorization();
    const lastLoadingDocumentRef = useRef(isLoadingDocument);
    useEffect(() => {
      if (
        !isLoadingDocument &&
        lastLoadingDocumentRef.current !== isLoadingDocument
      ) {
        // reset form data
        setFormInfo({ ...(documentGroup || {}) });
        setDocumentGroupSelected({ ...(documentGroup || {}) });
      }
      lastLoadingDocumentRef.current = isLoadingDocument;
    }, [isLoadingDocument]);

    const { sizePanel } = useSelector((state: RootState) => state.app);

    const widthPanel = useMemo(
      () => `${sizePanel.width}${sizePanel.unit}`,
      [sizePanel]
    );

    const { sendWebSocketMessage, webSocketMessage } = useAppWebSocket();

    useEffect(() => {
      if (!webSocketMessage) return;
      if (webSocketMessage.type === MessageType.CHANGE_DOC_GROUP_MODAL_INFO) {
        if (webSocketMessage.docGroupId === formInfo?.id) {
          setFormInfo((formInfo) => {
            return {
              ...formInfo,
              ...webSocketMessage.data,
            };
          });
        }
      }
    }, [formInfo?.id, webSocketMessage]);

    useEffect(() => {
      if (documentGroupSelected?.id !== documentGroup?.id) {
        setIsCollapsed(false);
        // set new data
        setTimeout(() => {
          setFormInfo({ ...(documentGroup || {}) });
          setDocumentGroupSelected({ ...(documentGroup || {}) });
        }, 0);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [documentGroupSelected?.id, documentGroup?.id]);

    const parentRef = useRef<HTMLDivElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);

    useImperativeHandle(ref, () => ({
      handleCollapse: (val = true) => {
        if (val) {
          setIsCollapsed(true);
        } else {
          setIsCollapsed(false);
        }
      },
    }));

    const handleUpdateData = useCallback(async () => {
      if (documentGroupSelected?.id === formInfo?.id) {
        if (
          documentGroupSelected?.name === formInfo?.name ||
          !formInfo?.name?.trim()
        ) {
          return;
        }
        const dataClone = { ...formInfo } as any;
        if ((dataClone as any).documentItems) {
          delete (dataClone as any).documentItems;
        }
        if ((dataClone as any).documentSubCategories) {
          delete (dataClone as any).documentSubCategories;
        }

        dataClone.neptuneAreaIds = dataClone.neptuneAreaIds?.filter(
          (e: string) => ![OPTION_ALL_AREA, OPTION_ALL_FLOOR].includes(e)
        );

        await documentGroupApi.updateGroup(dataClone);
        dispatch(updateDocumentGroup(formInfo as any));
        sendWebSocketMessage({
          type: MessageType.CHANGE_DOC_GROUP_MODAL_INFO,
          docGroupId: formInfo?.id,
          data: {
            name: formInfo.name,
          },
        });
      }
    }, [
      documentGroupSelected?.id,
      documentGroupSelected?.name,
      formInfo,
      dispatch,
    ]);

    const onChangeName = (e: any) => {
      setFormInfo({
        ...formInfo,
        name: e.target.value,
        updatedAt: new Date(),
      } as DocumentGroup);
    };

    const onBlurName = () => {
      handleUpdateData();
    };

    const handleCollapse = () => {
      !isCollapsed && handleUpdateData();
      setIsCollapsed((prev) => !prev);
    };

    return (
      <>
        <Box
          className={RIGHT_SIDEBAR_MODAL_CLASSNAME}
          w={isCollapsed ? "0" : widthPanel}
          position="absolute"
          right={0}
          height="100%"
          zIndex={11}
          transition="all 200ms ease-in-out 0s"
          filter={`drop-shadow(${"rgba(0, 0, 0, 0.15)"} 0px 8px 12px) drop-shadow(${"rgba(0, 0, 0, 0.3)"} 0px 4px 4px)`}
        >
          <Box
            ref={parentRef}
            bgColor="#FFFFFF"
            p="0"
            width="100%"
            height="100%"
            maxW={{ md: "480px" }}
            position="relative"
            flexDirection="column"
          >
            <CollapseModalIcon
              backgroundColor="white"
              borderRadius="5px 0px 0px 5px"
              borderRightStyle="hidden"
              borderLeftStyle="solid"
              height="12rem"
              width="3.8rem"
              top="50%"
              iconProps={{
                transform: isCollapsed ? "rotate(90deg)" : "rotate(-90deg)",
              }}
              transform="translateY(-50%)"
              onClose={handleCollapse}
            />

            <Flex
              ref={containerRef}
              flexDirection="column"
              overflow="hidden auto"
              h="calc(var(--app-height) - var(--header-height) - var(--sub-header-height))"
            >
              <>
                {isLoadingDocument && (
                  <Flex
                    position="absolute"
                    inset="0px"
                    width="100%"
                    height="100%"
                    alignItems="center"
                    justifyContent="center"
                    zIndex="1000"
                    backgroundColor="white"
                  >
                    <Spinner color="blue.500" size="lg" mt="1rem" />
                  </Flex>
                )}

                {/* document category name */}
                <Flex p="16px" direction={"column"}>
                  <Flex alignItems="center" flexDirection="row">
                    <IconBase
                      width="4.8rem"
                      height="4.8rem"
                      color="icon.blackBold"
                      icon={folderOutlineBoldIconPath}
                    />
                    <Flex ml={"1.2rem"} w="100%" flexDirection="column">
                      <Text
                        fontSize="1.4rem"
                        mb="0.5rem"
                        as={"span"}
                        textAlign="left"
                        color="#171717"
                      >
                        フォルダ名
                      </Text>
                      <Input
                        value={formInfo?.name || ""}
                        height="4rem"
                        fontSize="1.4rem"
                        w="100%"
                        _placeholder={{
                          color: "#A3A3A3",
                          opacity: 1,
                        }}
                        placeholder="フォルダ名"
                        borderColor="#A3A3A3"
                        onChange={onChangeName}
                        onBlur={onBlurName}
                        isDisabled={!roles.canEditFolder}
                      />
                      {!formInfo?.name?.trim() && formInfo?.id && (
                        <Text fontSize="1.2rem" mt="0.3rem" color="#EF4444">
                          フォルダ名が必要です。
                        </Text>
                      )}
                    </Flex>
                  </Flex>
                </Flex>
              </>
            </Flex>
          </Box>
        </Box>
      </>
    );
  }
);

export default DocumentGroupModal;
