import { Flex, FlexProps } from "@chakra-ui/react";
import { useRef } from "react";

interface iProps extends FlexProps {
  children?: React.ReactNode;
}

const ForgeToolbar: React.FC<iProps> = ({ children, ...rest }) => {
  const ref = useRef<HTMLDivElement | null>();

  return (
    <Flex
      id="neptune-forge-toolbar"
      position="absolute"
      bottom="2rem"
      ref={(e) => {
        ref.current = e;
      }}
      left={`calc(50% - ${(ref.current?.clientWidth || 0) / 2}px)`}
      w={"fit-content"}
      h="5.6rem"
      padding="5px"
      bgColor="#ffffff"
      boxShadow="1px 3px 10px 0 rgb(0 0 0 / 50%)"
      borderRadius="5px"
      color="#171717"
      boxSizing="border-box"
      zIndex={10}
      alignItems="center"
      gap="2px"
      justifyContent="space-between"
      {...rest}
    >
      {children}
    </Flex>
  );
};

export default ForgeToolbar;
