import { useDisclosure } from "@chakra-ui/react";
import { documentGroupApi } from "apiClient/v2";
import { DocumentCategoryStatusType, ModalType } from "constants/enum";
import { FilterDocumentCategoryByUserSettingProps } from "models/document";
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { message as messageObj } from "components/base";

import {
  addDocumentGroup,
  updateDocumentGroup,
  setDocumentCategorySelected,
  setDocumentGroupSelected,
  setDocumentItemSelected,
  setDocumentSubCategorySelected,
} from "redux/documentSlice";
import { setModalType } from "redux/forgeViewerSlice";
import { RootState } from "redux/store";
import { getCurrentViewer, selectDbIds } from "utils/forge";
import { getLocalStorageUser } from "utils/user";
import { DocumentGroup } from "interfaces/models/documentGroup";
import { MessageType } from "constants/websocket";
import { useAppWebSocket } from "hooks/useAppWebSocket";

const useAddDocumentGroup = ({
  filterDocumentCategoryOptions,
}: {
  filterDocumentCategoryOptions: FilterDocumentCategoryByUserSettingProps;
}) => {
  const { sendWebSocketMessage } = useAppWebSocket();
  const [isAdding, setIsAdding] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { levelSelected, modalType, isFilter } = useSelector(
    (state: RootState) => state.forgeViewer
  );
  const { documentCategories, documentTemplates } = useSelector(
    (state: RootState) => state.document
  );

  const { projectBimFileId } = useParams();
  const dispatch = useDispatch();

  const onAfterAddDocumentGroup = ({
    message,
    status,
  }: {
    message: string | string[];
    status: "success" | "error";
  }) => {
    onClose();
    setIsAdding(false);
    dispatch(setDocumentItemSelected());
    dispatch(setDocumentSubCategorySelected());
    if (status === "error") {
      messageObj.error(message, {
        duration: 2000,
      });
    } else {
      messageObj.success(message, {
        duration: 2000,
      });
    }
  };

  const handleAddDocumentGroup = useCallback(
    async (data: Partial<DocumentGroup>) => {
      setIsAdding(true);
      if (!projectBimFileId) {
        return onAfterAddDocumentGroup({
          message: ["フォルダの追加", `フォルダの作成がエラーです。`],
          status: "error",
        });
      }

      try {
        const promise = data.id
          ? documentGroupApi.updateGroup({
              id: data.id,
              name: data.name?.trim() || "",
            })
          : documentGroupApi.createGroup({
              createdBy: getLocalStorageUser()?.id || "",
              name: data.name?.trim() || "",
              projectBimFileId,
            });
        const documentGroup = await promise.then((res) => res.data);

        sendWebSocketMessage({
          type: MessageType.ADD_DOC_GROUP,
          data: documentGroup,
        });
        // create template and generate data success
        if (!!documentGroup) {
          const isSelectDocumentGroup = documentGroup && !isFilter;

          if (data.id) {
            dispatch(updateDocumentGroup(documentGroup));
          } else {
            dispatch(addDocumentGroup(documentGroup));
          }

          if (isSelectDocumentGroup) {
            dispatch(setDocumentGroupSelected(documentGroup));
            dispatch(setDocumentSubCategorySelected());
            dispatch(setDocumentItemSelected());
            dispatch(setDocumentCategorySelected());
            selectDbIds([], {
              color: DocumentCategoryStatusType.NotStarted,
            });
            (getCurrentViewer()?.utilities as any).goHome();
          }
          if (modalType !== ModalType.DOC_GROUP && isSelectDocumentGroup) {
            dispatch(setModalType(ModalType.DOC_GROUP));
          }

          return onAfterAddDocumentGroup({
            message: [
              "フォルダの追加",
              `${documentGroup.name}を追加しました。`,
            ],
            status: "success",
          });
        }

        return onAfterAddDocumentGroup({
          message: ["フォルダの追加", `フォルダの作成がエラーです。`],
          status: "error",
        });
      } catch {
        onAfterAddDocumentGroup({
          message: ["フォルダの追加", `フォルダの作成がエラーです。`],
          status: "error",
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      projectBimFileId,
      documentTemplates,
      documentCategories,
      modalType,
      levelSelected.label,
      filterDocumentCategoryOptions,
      onAfterAddDocumentGroup,
    ]
  );

  return {
    isAdding,
    isOpen,
    onOpen,
    onClose,
    handleAddDocumentGroup,
  };
};

export default useAddDocumentGroup;
