import { PayloadAction } from "@reduxjs/toolkit";
import { TYPE_USER } from "constants/app";
import { DataProjectModel } from "interfaces/models/dataProjectModel";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { fetchDataProjectDetail, fetchProjectDetail } from "redux/projectSlice";
import { RootState } from "redux/store";
import { routePath } from "routes/path";
import { getBimFileInfo } from "utils/bim";
import { getLocalStorageUser } from "utils/user";

export const useProjectBimFile = () => {
  const { projectBimFileId, bimFileId, version } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { dataProjectDetail } = useSelector(
    (state: RootState) => state.project
  );

  const loadProject = useCallback(
    async (projectBimFileId: string) => {
      const { payload }: PayloadAction<DataProjectModel> = (await dispatch(
        fetchDataProjectDetail(projectBimFileId)
      )) as any;
      dispatch(fetchProjectDetail({ projectId: payload.projectId }));
    },
    [dispatch]
  );

  useEffect(() => {
    if (projectBimFileId) {
      loadProject(projectBimFileId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectBimFileId]);

  useEffect(() => {
    (async () => {
      if (dataProjectDetail && Object.keys(dataProjectDetail).length) {
        const { id, role } = getLocalStorageUser() || {};
        let accessLinkNotFound = !dataProjectDetail;
        if (bimFileId && version) {
          const urn =
            dataProjectDetail?.defaultBimPathId?.split("/").pop() || "";
          const infoBim = getBimFileInfo(urn);
          accessLinkNotFound =
            accessLinkNotFound ||
            infoBim?.bimFileId !== bimFileId ||
            infoBim?.version !== version;
        }
        if (accessLinkNotFound) {
          return navigate(routePath.NotFound);
        }
        if (
          role != TYPE_USER.ROLE_ADMIN &&
          !dataProjectDetail?.personInCharge?.includes(id || "") &&
          id !== dataProjectDetail?.userCreated
        ) {
          return navigate(routePath.AccessError);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bimFileId, dataProjectDetail, version]);
};
