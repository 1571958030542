import { UserSetting } from "interfaces/models/user";
import { DEFAULT_DOCUMENT_FILTER_DATA } from "./document";
import { DEFAULT_TASK_FILTER_DATA } from "./task";

export const DEFAULT_USER_SETTING: UserSetting = {
  userId: "",
  applyToLeftBar: true,
  applyToViewer: true,
  displayTaskTitle: true,
  displayTaskBlackBoard: true,
  displayTaskId: true,
  displayTaskPhoto: true,
  displayTaskPriority: true,
  displayDocumentBlackBoard: true,
  displayDocumentId: true,
  displayDocumentPhoto: true,
  displayDocumentPriority: true,
  displayDocumentTitle: true,
  displayDocumentSubTitle: true,
  ...DEFAULT_TASK_FILTER_DATA,
  ...DEFAULT_DOCUMENT_FILTER_DATA,
  startDateScheduledFrom: null as any,
  startDateScheduledTo: null as any,
  deadlineFrom: null as any,
  deadlineTo: null as any,
  documentStartDateScheduledFrom: null as any,
  documentStartDateScheduledTo: null as any,
  documentEndDateScheduledFrom: null as any,
  documentEndDateScheduledTo: null as any,
} as UserSetting;
