import { Badge, Box, BoxProps, Flex, Text, Tooltip } from "@chakra-ui/react";
import { IconBase, message } from "components/base";
import { SvgIcon } from "components/SvgIcon";
import {
  DocumentCategoryStatusType,
  MapDocumentCategoryStatusType,
  MapDocumentCategoryStatusTypeColor,
  ModalType,
} from "constants/enum";
import { useVisibleTooltip } from "hooks/useVisibleTooltip";
import { DocumentCategoryDTO } from "interfaces/dtos/documentCategoryDTO";
import { DocumentItemDTO } from "interfaces/dtos/documentItemDTO";
import { iDocumentDataProps } from "interfaces/models/document";
import isEqual from "lodash/isEqual";
import { getColorTextByStatus } from "models/document";
import { isSelfInspectionTemplate } from "models/documentCategory";
import React, { useEffect, useMemo, useRef } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch } from "react-redux";
import { setDocumentItemSelected } from "redux/documentSlice";
import { setModalType } from "redux/forgeViewerSlice";
import { FONT_SIZE_DISPLAY_ORDER } from "utils/forge";
import { clearLabelAndForgeView } from "utils/forge/extensions/custom-label";
import { EventDetector, EventType } from "utils/handler";
import { useFocus } from "./hooks/useFocus";
import useForgeViewerZoomToLabel from "./hooks/useForgeViewerZoomToLabel";
import DocumentItemMenu from "./menu/DocumentItemMenu";

const COLOR_VISIBLE = "#A3A3A3";

interface Props extends BoxProps {
  index: number;
  isOnline: boolean;
  documentItem: DocumentItemDTO;
  documentCategories?: DocumentCategoryDTO[];
  parentRef: React.RefObject<HTMLElement | undefined>;
  wrapperListRef: React.MutableRefObject<HTMLElement | undefined>;
  highlightColor?: string;
  isFromDocumentGroup?: boolean;
  documentItemSelected?: DocumentItemDTO;
  documentCategorySelected?: DocumentCategoryDTO;
  isDisabledDrag?: boolean;
  isSelect?: boolean;
  isVisible?: boolean;
  isShowMenuEditItem: boolean;
  isShowDragAction: boolean;
  isBlockDocumentItem: boolean;
  documentCategoryTitle?: string;
  onToggleVisibleDocumentItem: (
    event: any,
    documentItem: DocumentItemDTO
  ) => Promise<void>;
  onDeletedDocumentItem: (
    documentItem: DocumentItemDTO,
    onOk?: (documentCategory?: DocumentCategoryDTO) => void
  ) => Promise<void>;
  handleClickDocumentCategory: (documentCategory: DocumentCategoryDTO) => void;
  handleClickDocumentItem: (data: iDocumentDataProps) => void;
}

type ContentModalDeletedProps = {
  label: number;
  status: string;
  color: string;
  colorStatusText: string;
  title: string | undefined;
};

const ContentModalDeleted = ({
  label,
  status,
  title,
  color,
  colorStatusText,
}: ContentModalDeletedProps) => {
  return (
    <Flex flexDirection="row" alignItems="center" gap="0.8rem">
      <Box flexGrow={"auto"}>
        <IconBase
          width="32px"
          height="32px"
          icon="/img/pin-on-map.svg"
          color={color}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Text
            fontSize={
              FONT_SIZE_DISPLAY_ORDER[
                label.toString().length as keyof typeof FONT_SIZE_DISPLAY_ORDER
              ]
            }
            mb="3px"
            fontWeight="400"
            color={colorStatusText}
          >
            {label}
          </Text>
        </IconBase>
      </Box>
      <Box flex="0 0 auto">
        <Flex
          color={colorStatusText}
          fontSize="1.4rem"
          fontWeight="400"
          paddingInline="0.8rem"
          alignItems="center"
          height="2.4rem"
          borderRadius="0.4rem"
          bg={color}
          flexShrink={"auto"}
        >
          {status}
        </Flex>
      </Box>
      <Text
        fontSize="1.6rem"
        lineHeight="1.6rem"
        fontWeight="400"
        color="#737373"
        flexGrow={1}
      >
        {title}
      </Text>
    </Flex>
  );
};

const DocumentItemDrag = ({
  index,
  isOnline,
  documentItem,
  highlightColor,
  parentRef,
  documentItemSelected,
  documentCategorySelected,
  wrapperListRef,
  isShowDragAction,
  isShowMenuEditItem,
  documentCategories,
  isFromDocumentGroup,
  isSelect,
  isVisible,
  isDisabledDrag,
  isBlockDocumentItem,
  documentCategoryTitle,
  onToggleVisibleDocumentItem,
  onDeletedDocumentItem,
  handleClickDocumentItem,
  handleClickDocumentCategory,
  ...rest
}: Props) => {
  const documentItemTitleContainerRef = useRef<HTMLDivElement>(null);
  const isSelectedRef = useRef(isSelect);
  isSelectedRef.current = isSelect;
  const dispatch = useDispatch();

  const { onZoom } = useForgeViewerZoomToLabel();

  const detector = useRef(
    new EventDetector({
      onDoubleEvent: () => {
        if (isSelectedRef.current) {
          onZoom(documentItem.id);
        } else {
          setTimeout(() => {
            onZoom(documentItem.id);
          }, 100);
          if (isSelfInspectionTemplate(documentItem.documentType)) {
            handleClickDocumentItem({
              document: { ...documentItem, displayOrder: index - 1 },
            });
          } else {
            handleClickDocumentItem({
              document: documentItem,
            });
          }
        }
      },
      onEvent: () => {
        if (isSelfInspectionTemplate(documentItem.documentType)) {
          handleClickDocumentItem({
            document: { ...documentItem, displayOrder: index - 1 },
          });
        } else {
          handleClickDocumentItem({
            document: documentItem,
          });
        }
      },
    })
  );

  const { onChangeRef } = useFocus(!!isSelect, wrapperListRef);
  const { isShowTooltip, onChangeTextRef } = useVisibleTooltip({
    wrapperRef: documentItemTitleContainerRef,
  });

  const status = useMemo(() => {
    return (documentItem.status ||
      DocumentCategoryStatusType.NotStarted) as DocumentCategoryStatusType;
  }, [documentItem.status]);

  const bgColorDocumentItem = useMemo(() => {
    if (!isVisible || isBlockDocumentItem) {
      return "#E0E0E0";
    }

    return isSelect ? highlightColor ?? "#F0F9FF" : "transparent";
  }, [isSelect, isVisible, highlightColor, isBlockDocumentItem]);

  const textDecoration = useMemo(
    () => (isVisible ? "unset" : "line-through"),
    [isVisible]
  );

  const opacity = useMemo(() => (isVisible ? 1 : 0.6), [isVisible]);

  const event = useMemo(() => {
    return isMobile
      ? {
          onTouchMove: detector.current.dispatchEvent(EventType.TOUCH_MOVE),
          onTouchStart: detector.current.dispatchEvent(EventType.TOUCH_START),
          onTouchEnd: detector.current.dispatchEvent(EventType.TOUCH_END),
        }
      : {
          onClick: detector.current.dispatchEvent(EventType.CLICK),
        };
  }, []);

  useEffect(() => {
    if (isSelect && isBlockDocumentItem) {
      dispatch(setDocumentItemSelected());
      dispatch(setModalType(ModalType.DOC_CATEGORY));
      clearLabelAndForgeView();
    }
  }, [isSelect, isBlockDocumentItem, dispatch]);

  const handleSelectBlockItem = () => {
    if (isBlockDocumentItem) {
      const categoryTitle = documentCategoryTitle
        ? `「${documentCategoryTitle}」`
        : "";
      const itemTitle = documentItem?.title ? `「${documentItem?.title}」` : "";

      message.warning(
        `書類${categoryTitle}のピン${itemTitle}がロックかかっている別の書類に存在しているため、編集できません。`
      );
    }
  };

  return (
    <Box onClick={handleSelectBlockItem}>
      <Box
        ref={onChangeRef}
        cursor="pointer"
        width="100%"
        {...rest}
        pointerEvents={isBlockDocumentItem ? "none" : "initial"}
      >
        <Flex
          height="4.4rem"
          cursor="pointer"
          alignItems="center"
          width="100%"
          bgColor={bgColorDocumentItem}
          sx={
            isSelect
              ? {
                  "&::before": {
                    content: '""',
                    zIndex: 2,
                    position: "absolute",
                    height: "4.4rem",
                    borderLeft: "4px solid #009BE0",
                    left: rest.pl,
                  },
                }
              : {}
          }
          borderTop="1px solid #D4D4D4"
        >
          <Flex
            alignItems="center"
            gap="0.6rem"
            w="70%"
            flexGrow={2}
            {...event}
          >
            <Flex
              alignItems="center"
              justifyContent="center"
              width="4rem"
              height="4rem"
              flexShrink={0}
            >
              {!isDisabledDrag && isShowDragAction && (
                <SvgIcon
                  src="/img/icon-drag.svg"
                  width="2.4rem"
                  height="2.4rem"
                />
              )}
            </Flex>
            <Box>
              <IconBase
                icon="/img/pin-on-map.svg"
                color="white"
                width="32px"
                height="32px"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <IconBase
                  color={
                    isVisible
                      ? MapDocumentCategoryStatusTypeColor[status]
                      : COLOR_VISIBLE
                  }
                  icon="/img/pin-on-map.svg"
                  width="30px"
                  height="30px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Text
                    fontSize={
                      FONT_SIZE_DISPLAY_ORDER[
                        index.toString()
                          .length as keyof typeof FONT_SIZE_DISPLAY_ORDER
                      ]
                    }
                    mb="3px"
                    fontWeight="400"
                    color={
                      status === DocumentCategoryStatusType.NotStarted
                        ? "font.default"
                        : "font.white"
                    }
                    textDecoration={isVisible ? "unset" : "line-through"}
                  >
                    {index}
                  </Text>
                </IconBase>
              </IconBase>
            </Box>

            <Badge
              bgColor={
                isVisible
                  ? MapDocumentCategoryStatusTypeColor[status]
                  : COLOR_VISIBLE
              }
              opacity={opacity}
              height="2.4rem"
              borderRadius="4px"
              minW="6rem"
              padding={0}
              flexShrink={0}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Text
                width="100%"
                textAlign="center"
                fontSize="1.4rem"
                lineHeight="2.2rem"
                fontWeight={400}
                color={getColorTextByStatus(status)}
                zIndex={10}
              >
                {MapDocumentCategoryStatusType[status]}
              </Text>
            </Badge>
            <Box
              height="2.4rem"
              maxW={isFromDocumentGroup ? "40%" : "50%"}
              flexGrow={2}
              flex={1}
              ref={documentItemTitleContainerRef}
            >
              <Tooltip
                placement="top-start"
                isDisabled={!isShowTooltip}
                label={documentItem.title}
              >
                <Text
                  ref={onChangeTextRef}
                  fontSize="1.6rem"
                  height="2.4rem"
                  noOfLines={1}
                  width="fit-content"
                  color={isVisible && isSelect ? "#171717" : "#737373"}
                  fontWeight={isSelect ? 700 : 500}
                  textDecoration={textDecoration}
                >
                  {documentItem.title}
                </Text>
              </Tooltip>
            </Box>
          </Flex>

          {isShowDragAction && (
            <DocumentItemMenu
              isVisibled={isVisible}
              documentItem={documentItem}
              paddingRight="1rem"
              flexShrink={0}
              onToggleVisibleDocumentItem={onToggleVisibleDocumentItem}
              onDeletedDocumentItem={onDeletedDocumentItem}
              isDisableDelete={!isOnline}
              contentModalDeleted={
                <ContentModalDeleted
                  label={index}
                  color={MapDocumentCategoryStatusTypeColor[status]}
                  status={MapDocumentCategoryStatusType[status]}
                  colorStatusText={getColorTextByStatus(status)}
                  title={documentItem.title}
                />
              }
            />
          )}
        </Flex>
      </Box>
    </Box>
  );
};

const areEquals = (prevProps: any, nextProps: any): boolean => {
  return isEqual(prevProps, nextProps);
};

export default React.memo(DocumentItemDrag, areEquals);
