import { documentTaskApi } from "apiClient/v2";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store";
import { setDocumentTasks, setIsLoadedDocumentTasks } from "redux/taskSlice";

interface iProps {
  bimFileId: string | undefined;
  isFetch?: boolean;
}

const useFetchDocumentTasks = ({ bimFileId, isFetch = true }: iProps) => {
  const { documentTasks, isLoadedDocumentTasks } = useSelector(
    (state: RootState) => state.task
  );
  const { dataProjectDetail } = useSelector(
    (state: RootState) => state.project
  );
  const { levelSelected } = useSelector(
    (state: RootState) => state.forgeViewer
  );
  const bimFileIdCurrentRef = useRef("");
  const levelCurrentRef = useRef("");

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isLoadedDocumentTasks) {
      bimFileIdCurrentRef.current = "";
      levelCurrentRef.current = "";
    }
    if (
      !bimFileId ||
      (bimFileIdCurrentRef.current === bimFileId &&
        levelCurrentRef.current === levelSelected.label) ||
      !isFetch
    ) {
      return;
    }
    bimFileIdCurrentRef.current = bimFileId;
    levelCurrentRef.current = levelSelected.label;

    (async () => {
      const documentTasks =
        await documentTaskApi.getAllDocumentTasksByBimFileId({ bimFileId });
      dispatch(setDocumentTasks(documentTasks));
      dispatch(setIsLoadedDocumentTasks(true));
    })();
  }, [
    dispatch,
    bimFileId,
    isLoadedDocumentTasks,
    documentTasks,
    dataProjectDetail?.id,
    isFetch,
    levelSelected.label,
  ]);

  return {};
};

export default useFetchDocumentTasks;
