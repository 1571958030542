import { useBoolean } from "@chakra-ui/react";
import {
  areaApi,
  bimFileApi,
  documentCategoryApi,
  documentGroupApi,
  documentItemApi,
  documentTemplateApi,
  projectBimFileApi,
  s3Api,
} from "apiClient/v2";
import { encode } from "base-64";
import { TaskModalHandleType } from "components/modal/TaskModal";
import {
  InspectionItemType,
  MapInspectionItemColor,
  ModalType,
  STATUS_UPDATE_FORGE_VERSION,
  SystemModeType,
} from "constants/enum";
import {
  ALL_LEVEL_LABEL,
  CURRENT_LEVEL_KEY,
  DB_ID_COLOR_SELECTED_COLOR_DEFAULT,
  DISPLAY_MODE,
  LEVEL_ALL,
} from "constants/forge";
import { FORGE_DATA_FOLDER_PATH } from "constants/s3";
import { OPERATION } from "constants/task";
import { MessageType } from "constants/websocket";
import useFetchFamilies from "hooks/useFetchFamilies";
import { useRoles } from "hooks/usePermission";
import { DocumentCategoryDTO } from "interfaces/dtos/documentCategoryDTO";
import { DocumentItemDTO } from "interfaces/dtos/documentItemDTO";
import { TaskDTO } from "interfaces/dtos/taskDTO";
import {
  FilterDataType,
  Level,
  OperationCaptureKeyplanData,
} from "interfaces/models";
import { NeptuneArea, Space } from "interfaces/models/area";
import { DocumentGroup } from "interfaces/models/documentGroup";
import { WSMessage } from "interfaces/models/websocket";
import { cloneDeep, isEmpty } from "lodash";
import isEqual from "lodash/isEqual";
import {
  doMapDocumentCategories,
  isPhotoLedgerTemplate,
  isSelfInspectionTemplate,
} from "models/documentCategory";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { setSyncDataOption } from "redux/appSlice";
import {
  fetchBlackBoardByCategoryId,
  setAllDocItemAndDocCategory,
  setDocumentCategories,
  setDocumentCategorySelected,
  setDocumentGroups,
  setDocumentGroupSelected,
  setDocumentItems,
  setDocumentItemSelected,
  setDocumentTemplates,
  setIsFetchingDocument,
  setIsLoadingDocument,
  setIsLoadingDocumentItem,
  toggleIsCaptureKeynoteByOperation,
} from "redux/documentSlice";
import {
  fetchNeptuneAreasByBimFile,
  setDisplayMode,
  setFamilyInstances,
  setIsGeneratingFamilyIntances,
  setIsGeneratingSpaces,
  setIsLoadedNeptuneAreas,
  setIsLoadedSheetTransformRatio,
  setIsLoadedSpaces,
  setIsLoadedViewer,
  setIsShowArea,
  setLevels,
  setLevelSelected,
  setLoadedFamilyInstances,
  setLoadedLevels,
  setModalType,
  setNeptuneAreas,
  setSpaces,
} from "redux/forgeViewerSlice";
import { setDataProjectDetail } from "redux/projectSlice";
import store, { RootState } from "redux/store";
import { setTaskSelected } from "redux/taskSlice";
import { compareArray } from "utils/array";
import { getNetworkStatus, safelyParseJSON } from "utils/common";
import { updateForgeWhenSelectCategory } from "utils/document";
import {
  hightLightDocumentItem,
  updateForgeWhenSelectDocumentItem,
} from "utils/documentItem";
import {
  clearForgeSelection,
  getAECData,
  getCurrentViewer,
  getFamilyInstancesFromS3,
  getLevelsData,
  getMapDbId,
  getSheetsData,
  getSpacesFromS3,
  selectDbIds,
  setModelDbIds,
  uploadFamilyInstancesToS3,
} from "utils/forge";
import { get3DTo2DMatrix } from "utils/forge/aec";
import {
  AreaExtension,
  getAreaExtension,
} from "utils/forge/extensions/area-extension";
import {
  ClickInfo,
  SINGLE_CLICK_EVENT,
} from "utils/forge/extensions/click-extension";
import {
  AreaDisplayItem,
  clearSelectedLabel,
  CustomLabelExtension,
  DisplayItem,
  getLabelExtension,
  NormalDisplayItem,
  ShowLabelsOptions,
} from "utils/forge/extensions/custom-label";
import { LabelClicked } from "utils/forge/extensions/custom-label/constant";
import { setSheetTransformMatrix } from "utils/forge/forge2d";
import {
  getFamilyInstancesProperties,
  getSpaces,
  ___viewer3d,
} from "utils/forge/forge3d";
import { logDev, logError } from "utils/logs";
import { transformBodyForCombineData } from "utils/offline";
import { getLocalStorage, setLocalStorage } from "utils/storage";
import { uploadMultipartToS3 } from "utils/upload-multipart";
import useArea from "./useArea";
import { TypeHandleInitData } from "./useSupportSyncDataOffline";

interface Props {
  filterTasks: TaskDTO[];
  isLoadedExternalId: boolean;
  isSettingFloor: boolean;
  mapTaskType: { [key: string]: string };
  clickedLabelInfoRef: any;
  taskModalRef: React.RefObject<TaskModalHandleType>;
  filterDocumentGroupAndCategories: {
    categories: DocumentCategoryDTO[];
    groups: DocumentGroup[];
  };
  setClickInfo: (clickInfo: ClickInfo) => void;
  setClickedLabelInfo: (data: any) => void;
  sendWebSocketMessage: (message: WSMessage) => void;
  webSocketMessage: WSMessage | null;
}

let isGettingSpacesDataGloal = false;

export default function useForgeViewer({
  isLoadedExternalId,
  filterTasks,
  isSettingFloor,
  mapTaskType,
  filterDocumentGroupAndCategories,
  webSocketMessage,
  setClickInfo,
  setClickedLabelInfo,
  sendWebSocketMessage,
}: Props) {
  const [operation] = useState(
    new URLSearchParams(window.location.search).get("operation")
  );
  const [getModelDbIdsTime, setGetModelDbIdsTime] = useState<number | null>();
  const filterDocumentGroupAndCategoriesRef = useRef(
    filterDocumentGroupAndCategories
  );
  filterDocumentGroupAndCategoriesRef.current =
    filterDocumentGroupAndCategories;

  const {
    isShowArea,
    isGeneratingFamilyInstances,
    isGeneratingSpaces,
    isLoadedViewerModelData,
    isLoadedViewer,
    isLoadedNeptuneAreas,
    isLoadedSpaces,
    displayMode,
    levels,
    levelSelected,
    systemMode,
    isLoadedLevels,
    familyInstances,
    isLoadedSheetTransformRatio,
    statusUpdateForgeVersion,
    isLoadedFamilies,
    isFilter,
    isOpenFilter,
  } = useSelector((state: RootState) => state.forgeViewer);

  const {
    documentTemplates,
    documentCategorySelected,
    isLoadingDocument,
    documentItemSelected,
    documentGroupSelected,
  } = useSelector((state: RootState) => state.document);

  const { settings } = useSelector((state: RootState) => state.user);
  const { tasks, taskSelected } = useSelector((state: RootState) => state.task);
  const forgeViewContainerRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const { bimFileId, version, projectBimFileId } = useParams();
  const { dataProjectDetail } = useSelector(
    (state: RootState) => state.project
  );
  const { isSyncOfflineData, syncDataOption } = useSelector(
    (state: RootState) => state.app
  );

  const { isTakasagoGroupAndPartnerLeader } = useRoles();
  const controllerDocumentItemsRef = useRef(new AbortController());
  const controllerDocumentCategoriesRef = useRef(new AbortController());

  const { getObjectTypesOfFamilyInstance } = useFetchFamilies();
  const areaParentCategoryId = useRef<string | null>(null);

  const urn = useMemo(() => {
    return bimFileId && version
      ? encode(decodeURIComponent(`${bimFileId}?version=${version}`))
      : "";
  }, [bimFileId, version]);

  const { areas, spaces, allAreas, allSpaces } = useArea({});
  const areasRef = useRef<NeptuneArea[]>([]);
  const spacesRef = useRef<Space[]>([]);
  const allAreasRef = useRef<NeptuneArea[]>([]);
  const allSpacesRef = useRef<Space[]>([]);
  const levelSelectedRef = useRef<Level | undefined>(levelSelected);
  const documentGroupSelectedRef = useRef<DocumentGroup | undefined>(
    documentGroupSelected
  );
  const documentItemSelectedRef = useRef<DocumentItemDTO | undefined>(
    documentItemSelected
  );
  const documentCategorySelectedRef = useRef<DocumentCategoryDTO | undefined>(
    documentCategorySelected
  );
  const isShowAreaRef = useRef(true);

  useEffect(() => {
    areasRef.current = areas;
  }, [areas]);

  useEffect(() => {
    spacesRef.current = spaces;
  }, [spaces]);

  useEffect(() => {
    allAreasRef.current = allAreas;
  }, [allAreas]);

  useEffect(() => {
    allSpacesRef.current = allSpaces;
  }, [allSpaces]);

  useEffect(() => {
    levelSelectedRef.current = levelSelected;
  }, [levelSelected]);

  useEffect(() => {
    documentItemSelectedRef.current = documentItemSelected;
  }, [documentItemSelected]);

  useEffect(() => {
    documentCategorySelectedRef.current = documentCategorySelected;
  }, [documentCategorySelected]);

  useEffect(() => {
    documentGroupSelectedRef.current = documentGroupSelected;
  }, [documentGroupSelected]);

  useEffect(() => {
    isShowAreaRef.current = isShowArea;
  }, [isShowArea]);

  useEffect(() => {
    if (!documentItemSelected && !documentCategorySelected) {
      clearForgeSelection();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!documentItemSelected, !!documentCategorySelected]);

  useEffect(() => {
    if (
      isLoadedViewer &&
      isLoadedViewerModelData &&
      documentCategorySelected &&
      getModelDbIdsTime &&
      systemMode === SystemModeType.Document
    ) {
      if (documentItemSelected) {
        if (!isSelfInspectionTemplate(documentCategorySelected?.documentType)) {
          hightLightDocumentItem(documentItemSelected);
        }
        updateForgeWhenSelectDocumentItem(
          documentCategorySelected!,
          documentItemSelected
        );
      } else {
        updateForgeWhenSelectCategory(documentCategorySelected);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadedViewer, isLoadedViewerModelData, getModelDbIdsTime]);

  // set status model cached
  useEffect(() => {
    const mapModelCached = structuredClone(
      syncDataOption?.mapModelCached || {}
    );
    const modelCached =
      mapModelCached?.[projectBimFileId || ""]?.[levelSelected?.label || ""] ||
      {};

    if (
      !levelSelected?.guid ||
      !isLoadedViewer ||
      !projectBimFileId ||
      !displayMode ||
      !!modelCached?.modelType?.includes(displayMode)
    ) {
      return;
    }

    modelCached.modelType = [
      ...new Set([...(modelCached?.modelType || []), displayMode]),
    ];

    dispatch(
      setSyncDataOption({
        mapModelCached: {
          ...mapModelCached,
          [projectBimFileId]: {
            ...(mapModelCached?.[projectBimFileId || ""] || {}),
            [levelSelected.label]: modelCached,
          },
        },
      })
    );
  }, [
    isLoadedViewer,
    syncDataOption?.mapModelCached,
    projectBimFileId,
    levelSelected?.guid,
    levelSelected?.label,
    displayMode,
    dispatch,
  ]);

  useEffect(() => {
    const isClearGrayOutForge =
      (systemMode === SystemModeType.Task && isEmpty(taskSelected)) ||
      (systemMode === SystemModeType.Document &&
        !documentCategorySelected &&
        !documentItemSelected);
    if (isClearGrayOutForge) {
      clearForgeSelection();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [systemMode]);

  const getDocumentCategorySelected = useCallback(
    (documentCategorySelected: DocumentCategoryDTO) => {
      return filterDocumentGroupAndCategoriesRef.current.categories.find(
        (doc) => doc.id === documentCategorySelected.id
      );
    },
    []
  );

  const updateLabels = useCallback(
    async (customLabelExtension: CustomLabelExtension) => {
      areaParentCategoryId.current = null;
      let data: DisplayItem[] = [];
      const isSystemModeTask = systemMode === SystemModeType.Task;

      if (isSystemModeTask) {
        data = (settings?.applyToViewer ? filterTasks : tasks).map((task) => ({
          id: task.id,
          indexId: task.indexId,
          position: task.position,
          title: mapTaskType[task.taskTypeId || ""] || "-",
          status: task.status,
          showImage: Number(task.images?.length) > 0,
          externalId: task.externalId,
        }));
      } else {
        const mapDocCategoryData = (categories: DocumentCategoryDTO[]) => {
          const docData = categories || [];
          const result = docData
            .map((documentCategory) => {
              if (isSelfInspectionTemplate(documentCategory?.documentType)) {
                const isAllFloor = !!documentCategory?.allFloor;
                const isNoArea = !!documentCategory?.noArea;
                let areaIds = documentCategory?.neptuneAreaIds || [];
                if (isAllFloor) {
                  areaIds = areasRef.current.map((item) => item.id);
                } else if (isNoArea) {
                  areaIds = [];
                }

                return {
                  id: `${documentCategory?.id}/${areaIds.join(",") || ""}`,
                  title: documentCategory.title || "-",
                  status: documentCategory.status || "",
                  originId: documentCategory?.id,
                  externalIds: areaIds,
                  templateId: documentCategory.templateId,
                  items: (documentCategory.documentItems ?? []).map(
                    (doc, docIndex) =>
                      ({
                        id: doc.id,
                        title: doc.title,
                        indexId: doc.indexId,
                        position: new THREE.Vector3(),
                        status: doc.status,
                        categoryId: documentCategory.id,
                        templateId: documentCategory.templateId,
                        displayOrder: docIndex,
                      } as NormalDisplayItem)
                  ),
                } as AreaDisplayItem;
              } else if (!!documentCategory.documentItems?.length) {
                return documentCategory.documentItems.map(
                  (documentItem, documentItemIndex) => {
                    return {
                      id: `${documentItem.id}`,
                      originId: documentItem.id,
                      position: documentItem.position!,
                      title: documentCategory.title || "-",
                      subTitle: documentItem.title || "-",
                      displayOrder: documentItemIndex,
                      status: documentItem.status,
                      externalId: documentItem.externalId,
                      categoryId: documentCategory.id,
                      templateId: documentCategory.templateId,
                      unVisible: !(
                        documentItem.externalId &&
                        documentCategory.selectedExternalIds?.includes(
                          documentItem.id
                        )
                      ),
                    } as NormalDisplayItem;
                  }
                );
              }

              return [];
            })
            .flat();

          return result;
        };
        let categories: DocumentCategoryDTO[] = [];
        if (!!documentCategorySelected && !documentGroupSelected) {
          const selected = getDocumentCategorySelected(
            documentCategorySelected
          );
          categories = selected ? [selected] : [];
        }
        data = mapDocCategoryData(categories);
      }

      if (!data.length) {
        customLabelExtension.data = {};
        customLabelExtension.clear();

        return;
      }

      if (settings?.applyToViewer) {
        customLabelExtension.setSettings(settings);
      }
      const displayingData = Object.values(customLabelExtension.data);
      customLabelExtension.setShouldUpdateData(true);
      const isShowLabels = !document.querySelector(
        `div.adsk-viewing-viewer .canvas-wrap label.markup`
      );

      if (isSystemModeTask) {
        if (isShowLabels || !compareArray(data, displayingData)) {
          customLabelExtension.showLabels({
            mode: SystemModeType.Task,
            data,
          });
        }
      } else {
        if (isShowLabels || !isEqual(data, displayingData)) {
          const options: ShowLabelsOptions = {
            parentId: areaParentCategoryId.current,
            displayMode,
            sheetGuid: levelSelected.sheetGuid,
            forceUpdate: true,
          };
          if (
            documentCategorySelected &&
            isSelfInspectionTemplate(documentCategorySelected?.documentType)
          ) {
            options.selectedIds = [documentItemSelectedRef.current?.id || ""];
          }
          customLabelExtension.showLabels({
            mode: SystemModeType.Document,
            data,
            options,
          });
        }
      }
    },
    [
      filterTasks,
      mapTaskType,
      systemMode,
      settings,
      tasks,
      documentGroupSelected,
      documentCategorySelected,
      displayMode,
      levelSelected.sheetGuid,
    ]
  );

  useEffect(() => {
    if (
      !isLoadedViewer ||
      !(displayMode === DISPLAY_MODE["3D"] || isLoadedSheetTransformRatio)
    ) {
      return;
    }
    const viewer = getCurrentViewer();
    const customLabelExtension = viewer?.getExtension(
      "CustomLabelExtension"
    ) as CustomLabelExtension | undefined;

    if (customLabelExtension) {
      updateLabels(customLabelExtension);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isLoadedViewer,
    displayMode,
    systemMode,
    levelSelected.sheetGuid,
    isLoadedSheetTransformRatio,
    levelSelected,
    documentCategorySelected?.id,
    isFilter,
    updateLabels,
  ]);

  useEffect(() => {
    if (!isLoadedViewer) {
      return;
    }
    const viewer = getCurrentViewer();
    const areaExtension = viewer?.getExtension("AreaExtension") as
      | AreaExtension
      | undefined;
    if (areaExtension) {
      areaExtension.setShouldReDrawArea(true);
    }
  }, [isLoadedViewer]);

  /**
   * Auto disable area extensions when visit forge view page
   */
  useEffect(() => {
    if (isShowArea) {
      dispatch(setIsShowArea(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [levelSelected]);

  useEffect(() => {
    const viewer = getCurrentViewer();
    const areaExtension = viewer?.getExtension("AreaExtension") as
      | AreaExtension
      | undefined;

    if (
      !isLoadedViewer ||
      !isLoadedNeptuneAreas ||
      !isLoadedSpaces ||
      !(displayMode === DISPLAY_MODE["3D"] || isLoadedSheetTransformRatio)
    ) {
      return;
    }
    if (!isShowArea) {
      areaExtension?.setSpacesToDraw(spaces);
      areaExtension?.setAreasToDraw(areas);

      return;
    }

    if (areaExtension) {
      areaExtension?.drawAreas({
        initialize: true,
      });
      areaExtension?.toggleVisibleArea(isShowAreaRef.current);
      const category = documentCategorySelectedRef.current;
      if (
        category?.neptuneAreaIds?.length &&
        isShowAreaRef.current &&
        systemMode === SystemModeType.Document
      ) {
        setTimeout(() => {
          areaExtension.select(category);
        }, 1);
      }
    }
  }, [
    areas,
    spaces,
    isLoadedViewer,
    isLoadedNeptuneAreas,
    isLoadedSpaces,
    displayMode,
    systemMode,
    levelSelected.sheetGuid,
    isLoadedSheetTransformRatio,
    levelSelected,
    isShowArea,
  ]);

  const handleForgeClick = useCallback(
    (info: ClickInfo) => {
      const targetEvent = info.originalEvent?.target;

      if (
        targetEvent.closest(
          ".area-label, .label-item, .marker-pin, .combined-label"
        )
      ) {
        return;
      }
      const state = store.getState();
      if (state.forgeViewer.isCreateSelfInspectionTask) {
        setClickInfo(info);

        return;
      }
      if (!state.document.isMovingDoc && !state.document.documentItemSelected) {
        clearSelectedLabel();
      }

      // case click anywhere on forge viewer if has task selected => clear selected task and redirect task selected
      if (
        !isEmpty(state.task.taskSelected) &&
        systemMode === SystemModeType.Task &&
        !state.forgeViewer.isMoveTaskLabel
      ) {
        clearSelectedLabel();
        dispatch(setTaskSelected());
      }

      // case click anywhere on forge viewer if has label selected => clear selected label and redirect category selected
      if (
        !isEmpty(getLabelExtension().getSelectedItems()) &&
        systemMode === SystemModeType.Document
      ) {
        getLabelExtension()?.setSelectedItems([]);
        dispatch(setModalType(ModalType.DOC_CATEGORY));
        dispatch(setDocumentItemSelected());
        // highlight object when document category selected
        documentCategorySelected &&
          updateForgeWhenSelectCategory(documentCategorySelected);

        const elementRemoveLabels = document.querySelectorAll(
          ".combined-child-label-wrapper, .combined-child-label, .combined-label.selected-label, .document-label.selected-label"
        );
        for (const elm of elementRemoveLabels) {
          elm.classList.remove("selected-label");
        }
      }

      if (state.forgeViewer.isMoveTaskLabel) {
        setClickInfo(info);

        return;
      }

      if (state.forgeViewer.isCreatingNewTask) {
        return;
      }

      if (
        state.forgeViewer.isCreateTask ||
        state.forgeViewer.isCreateDocumentItem
      ) {
        setClickInfo(info);
      }

      const viewer = getCurrentViewer();

      if (info.forgeData?.dbId && viewer) {
        const color = DB_ID_COLOR_SELECTED_COLOR_DEFAULT; // color default selected for forge viewer
        viewer.set2dSelectionColor(color, 1);
      }
    },
    [systemMode, setClickInfo, dispatch, documentCategorySelected]
  );

  useEffect(() => {
    const viewer = getCurrentViewer();
    if (!isLoadedExternalId || !isLoadedViewerModelData || !viewer) {
      return;
    }

    const instanceTree = viewer.model.getData().instanceTree;
    const dbIdToIndex = instanceTree?.nodeAccess?.dbIdToIndex || {};
    const main: number[] = [];
    const linked: number[] = [];
    const mapDbId = getMapDbId();
    for (const dbId in dbIdToIndex) {
      const dbIdNum = Number(dbId);
      const externalId = mapDbId[dbIdNum];
      if (!externalId || dbIdNum === 0) {
        continue;
      }
      if (!externalId.includes("/")) {
        main.push(dbIdNum);
      } else {
        linked.push(dbIdNum);
      }
    }

    setModelDbIds({ main, linked });
    setGetModelDbIdsTime(new Date().getTime());
  }, [isLoadedExternalId, isLoadedViewerModelData, displayMode]);

  useEffect(() => {
    const viewer = getCurrentViewer();
    if (!viewer) {
      return;
    }

    const handleClickEvent = (e: any) => {
      handleForgeClick(e.payload);
    };

    const handleLabelClick = (e: any) => {
      setClickedLabelInfo(e.payload);
    };

    const handleTouchEnd = () => {
      const hitTest = viewer.clientToWorld(
        window.innerWidth / 2,
        window.innerHeight / 2,
        true
      );
      viewer.navigation.setPivotPoint(hitTest.point);
    };

    viewer.addEventListener(SINGLE_CLICK_EVENT, handleClickEvent);
    viewer.addEventListener(LabelClicked, handleLabelClick);
    viewer.addEventListener("touchend", handleTouchEnd);

    return () => {
      viewer.removeEventListener(SINGLE_CLICK_EVENT, handleClickEvent);
      viewer.removeEventListener(LabelClicked, handleLabelClick);
      viewer.removeEventListener("touchend", handleTouchEnd);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadedViewer, handleForgeClick]);

  const isCaptureKeyplanByOperation = useMemo(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const data: { documentTaskId: string; guid: string } = JSON.parse(
      urlParams.get("data") || "{}"
    );

    return (
      data?.documentTaskId &&
      data?.guid &&
      operation === OPERATION.CaptureKeyplan
    );
  }, [operation]);

  const handleChangeSheet = useCallback(
    async (sheetGuid: string, isSaveData = true) => {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const data = urlParams.get("data");
      let level = { ...levelSelected, sheetGuid };

      const selectedSheetGuid =
        levelSelected?.sheetGuid || levelSelected?.sheets?.[0]?.guid;

      if (selectedSheetGuid === sheetGuid) {
        return;
      }

      getLabelExtension()?.clear();
      dispatch(setIsLoadedSheetTransformRatio(false));
      dispatch(setIsShowArea(true));
      if (isCaptureKeyplanByOperation && data) {
        const dataFromURL: OperationCaptureKeyplanData = JSON.parse(
          decodeURIComponent(data) || "{}"
        );
        const filterData: FilterDataType = JSON.parse(dataFromURL?.filterData);
        const _levelSelected = levels.find(
          (l) => l.label === filterData?.levelSelected.label
        );

        if (_levelSelected?.label) {
          level = { ..._levelSelected, sheetGuid };
        }
      }

      dispatch(setLevelSelected(level));
      const levelData = (cloneDeep(dataProjectDetail.levelData) || {}) as {
        [key: string]: Level;
      };
      levelData[level.label || ""] = level;

      const newProject = {
        ...dataProjectDetail,
        levelData: levelData,
      };
      dispatch(setDataProjectDetail(newProject));
      const levelsData = Object.values(levelData).filter(
        (level) => level.label !== ALL_LEVEL_LABEL
      );
      dispatch(setLevels(levelsData));

      if (!isSaveData || isCaptureKeyplanByOperation) {
        return;
      }

      await projectBimFileApi.updateProject(newProject);
    },
    [
      dataProjectDetail,
      dispatch,
      levelSelected,
      isCaptureKeyplanByOperation,
      levels,
    ]
  );

  //#region generate levels data
  useEffect(() => {
    const urn = dataProjectDetail.defaultBimPathId?.split("/").pop();

    if (!dataProjectDetail.id || !urn) {
      return;
    }
    (async () => {
      dispatch(setLoadedLevels(false));
      const newProject = { ...dataProjectDetail };
      let shouldUpdate = false;

      if (!dataProjectDetail.sheetData) {
        const sheetData = await getSheetsData({
          projectId: dataProjectDetail.projectId,
          versionId: decodeURIComponent(urn),
        });
        newProject.sheetData = sheetData;
        shouldUpdate = true;
      }

      if (!dataProjectDetail.levelData) {
        const levelData = await getLevelsData({
          projectId: dataProjectDetail.projectId,
          versionId: decodeURIComponent(urn),
        });

        const map = levelData.reduce(
          (map: { [key: string]: Level }, level: Level) => {
            map[level.label || ""] = level;

            return map;
          },
          {}
        );
        newProject.levelData = map;
        shouldUpdate = true;
      }

      if (shouldUpdate) {
        dispatch(setDataProjectDetail(newProject));
        await projectBimFileApi.updateProject(newProject);
      }

      const levelData = newProject.levelData! as {
        [key: string]: Level;
      };

      const levels = Object.values(levelData)?.filter(
        (item) => item.label !== ALL_LEVEL_LABEL
      );

      dispatch(setLevels(levels));

      const currentLevel = levels?.find((item) => {
        const levelGuid = safelyParseJSON(
          localStorage.getItem(CURRENT_LEVEL_KEY) as string
        )?.[projectBimFileId as string];

        return item.guid === levelGuid;
      });

      const operation = new URLSearchParams(window.location.search).get(
        "operation"
      );

      if (
        [OPERATION.ExportTask, OPERATION.CaptureKeyplan].includes(
          operation || ""
        )
      ) {
        dispatch(setLevelSelected(undefined));
      } else {
        dispatch(setLevelSelected(currentLevel || levels?.[0]));
      }

      dispatch(setLoadedLevels(true));
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProjectDetail?.id]);
  //#endregion

  // #region generate spaces
  useEffect(() => {
    if (!bimFileId || !version || isGettingSpacesDataGloal) {
      return;
    }

    dispatch(setIsLoadedSpaces(false));
    dispatch(setIsLoadedNeptuneAreas(false));
    isGettingSpacesDataGloal = true;
    (async () => {
      const spacesData = await getSpacesFromS3({ bimFileId, version });
      if (spacesData) {
        dispatch(setIsGeneratingSpaces(false));
        const spaces = spacesData?.spaces || [];
        dispatch(setSpaces(spaces));
        dispatch(setIsLoadedSpaces(true));
        setGenerateSpacesData.off();
        isGettingSpacesDataGloal = false;

        const timeout = setTimeout(() => {
          dispatch(fetchNeptuneAreasByBimFile({ bimFileId }));
        }, 500);

        return () => {
          timeout && clearTimeout(timeout);
        };
      } else {
        setGenerateSpacesData.on();
        dispatch(setIsGeneratingSpaces(true));
        if (levelSelected.guid) {
          const level = levels.find((f) => !f.guid);
          dispatch(setLevelSelected(level));
          const currentLevel = getLocalStorage("currentLevel") || {};
          currentLevel[String(projectBimFileId)] = "";
          setLocalStorage("currentLevel", currentLevel);

          return;
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bimFileId, projectBimFileId, version]);

  const generateSpaceData = useCallback(async () => {
    const spaces = (await getSpaces()).filter((e) => e.level);
    let newAreas: NeptuneArea[] = [];
    let newSpaces: Space[] = [];
    isGettingSpacesDataGloal = false;
    if (spaces) {
      const uploaded = await uploadMultipartToS3({
        filePath: FORGE_DATA_FOLDER_PATH,
        fileName: `f-spaces-${encodeURIComponent(bimFileId!)}-v${version}.json`,
        fileData: { spaces },
      });

      if (uploaded) {
        await areaApi.createNeptuneArea({
          bimFileId: bimFileId!,
          version: +version!,
        });

        newAreas = (await areaApi.getNeptuneAreaList(bimFileId!)).data;
        newSpaces = spaces;
      }
    }

    dispatch(setIsLoadedViewer(false));
    dispatch(setIsGeneratingSpaces(false));
    dispatch(setIsLoadedNeptuneAreas(true));
    dispatch(setNeptuneAreas(newAreas));
    dispatch(setSpaces(newSpaces));
    dispatch(setIsLoadedSpaces(true));
  }, [bimFileId, version, dispatch]);

  useEffect(() => {
    if (
      isLoadedViewer &&
      isLoadedViewerModelData &&
      getModelDbIdsTime &&
      !isEmpty(taskSelected)
    ) {
      selectDbIds(taskSelected.externalId, {
        color:
          MapInspectionItemColor[
            (taskSelected.status ||
              InspectionItemType.Defect) as InspectionItemType
          ],
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadedViewer, isLoadedViewerModelData, getModelDbIdsTime]);

  useEffect(() => {
    if (
      isGeneratingSpaces &&
      bimFileId &&
      version &&
      isLoadedViewer &&
      isLoadedViewerModelData &&
      !!___viewer3d
    ) {
      generateSpaceData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    bimFileId,
    isGeneratingSpaces,
    isLoadedExternalId,
    isLoadedViewerModelData,
    isLoadedLevels,
    isLoadedViewer,
    version,
  ]);

  //#region forge data
  const [shouldGenerateFamilyInstanceData, setGenerateFamilyInstanceData] =
    useBoolean();
  const [shouldGenerateSpacesData, setGenerateSpacesData] = useBoolean();

  // get family instances
  const isGettingFamilyInstance = useRef<boolean>(false);
  useEffect(() => {
    if (
      isSettingFloor ||
      !bimFileId ||
      !version ||
      isGeneratingSpaces ||
      isGettingFamilyInstance.current ||
      !isLoadedFamilies
    ) {
      return;
    }

    isGettingFamilyInstance.current = true;
    dispatch(setIsLoadingDocument(true));
    dispatch(setLoadedFamilyInstances(false));
    (async () => {
      // load family instances data
      const familyInstancesData = await getFamilyInstancesFromS3({
        bimFileId,
        version,
      });
      const familyInstances = familyInstancesData?.familyInstances || {};
      const familyInstanceKeys = Object.keys(familyInstances);
      const isOnline = getNetworkStatus();
      if (familyInstancesData) {
        familyInstanceKeys.forEach((id: string) => {
          familyInstances[id].objectTypes = getObjectTypesOfFamilyInstance(
            familyInstances[id]
          );
        });
        if (isOnline) {
          await bimFileApi.updateBimFile({ id: bimFileId, isGenerated: true });
        }
        dispatch(setFamilyInstances(familyInstances));
        dispatch(setLoadedFamilyInstances(true));
        dispatch(setIsGeneratingFamilyIntances(false));
        setGenerateFamilyInstanceData.off();
      } else {
        setGenerateFamilyInstanceData.on();
        dispatch(setIsGeneratingFamilyIntances(true));
        if (isOnline) {
          await bimFileApi.updateBimFile({ id: bimFileId, isGenerated: false });
          if (familyInstancesData) {
            const dataFile = `${FORGE_DATA_FOLDER_PATH}/f-data-${encodeURIComponent(
              bimFileId
            )}-v${version}.json`;
            await s3Api.deleteFiles([dataFile]);
          }
        }
        if (levelSelected.guid) {
          const currentLevel = getLocalStorage(CURRENT_LEVEL_KEY) || {};
          currentLevel[String(projectBimFileId)] = "";
          setLocalStorage(CURRENT_LEVEL_KEY, currentLevel);
          dispatch(setLevelSelected(LEVEL_ALL));
          currentLevel[String(projectBimFileId)] = "";
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    bimFileId,
    version,
    isGeneratingSpaces,
    isLoadedFamilies,
    isSettingFloor,
  ]);

  // generate family instances data in first access
  const generateFamilyInstancesData = async ({
    viewer,
    bimFileId,
    versionId,
    version,
    projectId,
  }: {
    viewer: Autodesk.Viewing.GuiViewer3D;
    bimFileId: string;
    version: string;
    versionId: string;
    projectId: string;
  }) => {
    const familyInstances = await getFamilyInstancesProperties({
      viewer,
      bimFileId,
      version,
      projectId,
      versionId,
    });

    if (!levelSelected.guid) {
      const uploaded = await uploadFamilyInstancesToS3({
        bimFileId,
        version,
        levels,
        familyInstances,
      });

      if (uploaded) {
        await Promise.all([
          documentItemApi.updateItemArea({ bimFileId, version }),
          bimFileApi.updateBimFile({ id: bimFileId, isGenerated: true }),
        ]);

        Object.keys(familyInstances).forEach((id: string) => {
          familyInstances[id].objectTypes = getObjectTypesOfFamilyInstance(
            familyInstances[id]
          );
        });
        dispatch(setFamilyInstances(familyInstances));
        dispatch(setLoadedFamilyInstances(true));
        setGenerateFamilyInstanceData.off();
        dispatch(setIsGeneratingFamilyIntances(false));
      }
    }
  };

  useEffect(() => {
    const urn = dataProjectDetail.defaultBimPathId?.split("/").pop();

    if (
      !dataProjectDetail.defaultBimPathId ||
      !shouldGenerateFamilyInstanceData ||
      !bimFileId ||
      !version ||
      !isLoadedLevels ||
      !isLoadedViewerModelData ||
      !___viewer3d ||
      !isLoadedExternalId ||
      !projectBimFileId ||
      !urn ||
      !isLoadedNeptuneAreas ||
      !isLoadedSpaces
    ) {
      return;
    }
    (async () => {
      const viewer = ___viewer3d;

      // draw all area when generate family instances
      const areaExtension = getAreaExtension();
      areaExtension?.setAreasToDraw(allAreasRef.current);
      areaExtension?.setSpacesToDraw(allSpacesRef.current);
      await areaExtension?.drawAreas({
        initialize: true,
        isDrawAreaLabel: false,
      });

      await generateFamilyInstancesData({
        viewer,
        bimFileId,
        version,
        projectId: dataProjectDetail.projectId,
        versionId: decodeURIComponent(urn),
      });

      const defaultLevel = levels.filter((level) => level.guid)?.[0];
      dispatch(setLevelSelected(defaultLevel));

      dispatch(
        setDisplayMode(
          !!defaultLevel?.guid && defaultLevel?.sheets?.length
            ? DISPLAY_MODE["2D"]
            : DISPLAY_MODE["3D"]
        )
      );
      const currentLevel = getLocalStorage("currentLevel") || {};
      currentLevel[String(projectBimFileId)] = defaultLevel.guid;
      setLocalStorage("currentLevel", currentLevel);

      // set areas by level selected
      areaExtension?.setAreasToDraw(areasRef.current);
      areaExtension?.setSpacesToDraw(spacesRef.current);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dataProjectDetail.defaultBimPathId,
    dataProjectDetail.id,
    bimFileId,
    shouldGenerateFamilyInstanceData,
    isLoadedViewerModelData,
    version,
    isLoadedLevels,
    isLoadedExternalId,
    levelSelected.guid,
    displayMode,
    isLoadedNeptuneAreas,
    isLoadedSpaces,
  ]);
  //#endregion

  //#region calculate 2d transform ratio
  const calculate2dTransformRatio = async (
    bimFileId: string,
    version: string,
    sheetGuid: string
  ) => {
    try {
      let sheetMatrix: THREE.Matrix4 = new THREE.Matrix4();
      const aecData: any = await getAECData(`${bimFileId}?version=${version}`);
      const viewport = aecData.viewports.find(
        (v: any) => v.sheetGuid === sheetGuid
      );
      if (!!viewport) {
        sheetMatrix = get3DTo2DMatrix(viewport, 0.001).clone();
      }

      setSheetTransformMatrix(sheetMatrix);
      dispatch(setIsLoadedSheetTransformRatio(true));
    } catch (e) {
      logError(e);
    }
  };

  useEffect(() => {
    if (
      isGeneratingFamilyInstances ||
      isGeneratingSpaces ||
      isSettingFloor ||
      !isLoadedViewer ||
      !bimFileId ||
      !version ||
      !displayMode ||
      isLoadedSheetTransformRatio
    ) {
      return;
    }

    if (displayMode === DISPLAY_MODE["2D"]) {
      const sheetGuid =
        levelSelected.sheetGuid || levelSelected.sheets?.[0]?.guid || "";
      calculate2dTransformRatio(bimFileId, version, sheetGuid);
    } else {
      setSheetTransformMatrix(new THREE.Matrix4());
      dispatch(setIsLoadedSheetTransformRatio(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isLoadedSheetTransformRatio,
    bimFileId,
    displayMode,
    isLoadedViewer,
    levelSelected,
    version,
    isSettingFloor,
  ]);

  //#region fetch/generate document data
  const initDocumentData = useCallback(
    async (
      documentCategories: DocumentCategoryDTO[],
      documentItems: DocumentItemDTO[],
      documentGroups: DocumentGroup[]
    ) => {
      const {
        documentCategories: newDocumentCategories,
        documentItems: newDocumentItems,
        documentCategoryNeedUpdateItemIds,
      } = doMapDocumentCategories({
        documentCategories,
        documentItems,
        familyInstances,
        documentTemplates: Object.values(documentTemplates),
      });

      // update itemIds for get blackboard by category correct
      if (documentCategoryNeedUpdateItemIds.length) {
        Promise.all(
          documentCategoryNeedUpdateItemIds.map((category) =>
            documentCategoryApi.updateCategory(
              transformBodyForCombineData<DocumentCategoryDTO>({
                body: {
                  id: category.id!,
                  itemIds: category.itemIds,
                } as DocumentCategoryDTO,
                bodyBefore: undefined,
                typeInitData: TypeHandleInitData.DOCUMENT_CATEGORY,
              })
            )
          )
        );
      }

      dispatch(
        setAllDocItemAndDocCategory({
          documentCategories: newDocumentCategories,
          documentItems: newDocumentItems,
        })
      );
      let documentCategory = documentCategorySelected;
      if (documentCategorySelected) {
        documentCategory = newDocumentCategories.find(
          (item) => item.id === documentCategorySelected.id
        );
        dispatch(setDocumentCategorySelected(documentCategory));
        if (documentCategory) {
          updateForgeWhenSelectCategory(documentCategory);
        }
        // document item select allway have document category selected
        if (documentItemSelected && documentCategory) {
          const documentItem = documentCategory.documentItems?.find(
            (item) => item.id === documentItemSelected.id
          );
          updateForgeWhenSelectDocumentItem(
            documentCategory!,
            documentItemSelected
          );
          dispatch(setDocumentItemSelected(documentItem));
        }
        if (
          isPhotoLedgerTemplate(documentCategorySelected?.documentType) &&
          documentCategorySelected?.id
        ) {
          dispatch(fetchBlackBoardByCategoryId(documentCategorySelected.id));
        }
      }

      if (documentGroupSelected) {
        const documentGroup = documentGroups.find(
          (item) => item.id === documentGroupSelected.id
        );
        dispatch(setDocumentGroupSelected(documentGroup));
      }
    },
    [
      familyInstances,
      documentTemplates,
      documentCategorySelected,
      documentItemSelected,
      documentGroupSelected,
      dispatch,
    ]
  );

  const generateDocumentCategoriesAndItems = async (
    bimFileId: string,
    level?: string
  ) => {
    if (level && level !== levelSelectedRef.current?.label) {
      return;
    }
    dispatch(setIsFetchingDocument(true));
    console.log("---LOAD---");
    let documentTemplatesPromise: any = Promise.all([]);

    // need reload document templates and groups when offline -> online
    if (isTakasagoGroupAndPartnerLeader) {
      documentTemplatesPromise = documentTemplateApi.handleGetTemplateList({
        bimFileId: dataProjectDetail.bimFileId,
        isActive: true,
      });
    }

    const promiseDocumentGroup = documentGroupApi.getGroupsList({
      projectBimFileId,
    });

    const documentCategoriesPromise = documentCategoryApi.handleGetCategoryList(
      {
        bimFileId,
        level: levelSelected.label,
        signal: controllerDocumentCategoriesRef.current.signal,
      }
    );

    const [responseDocumentGroup, documentCategories, documentTemplates] =
      await Promise.all([
        promiseDocumentGroup,
        documentCategoriesPromise,
        documentTemplatesPromise,
      ]);

    if (documentTemplates) {
      dispatch(setDocumentTemplates(documentTemplates));
    }

    dispatch(setDocumentCategories(documentCategories));

    if (responseDocumentGroup?.data) {
      dispatch(setDocumentGroups(responseDocumentGroup?.data));
    }

    dispatch(setIsFetchingDocument(false));
    dispatch(setIsLoadingDocumentItem(true));

    const documentItems = await documentItemApi.handleGetItemList({
      bimFileId,
      level,
    });
    initDocumentData(
      documentCategories,
      documentItems,
      responseDocumentGroup?.data
    );
    dispatch(setIsLoadingDocumentItem(false));
    dispatch(setIsLoadingDocument(false));
  };

  useEffect(() => {
    const controllerDocumentCategories =
      controllerDocumentCategoriesRef.current;
    const controllerDocumentItems = controllerDocumentItemsRef.current;

    return () => {
      dispatch(toggleIsCaptureKeynoteByOperation(false));
      dispatch(setDocumentItems([]));
      dispatch(setDocumentCategories([]));
      controllerDocumentCategories.abort();
      controllerDocumentItems.abort();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const lastFetchArgs = useRef<{
    bimFileId: string | null;
    levelSelected: string | null;
  }>({ bimFileId: null, levelSelected: null });
  // generate document categories, document items
  useEffect(() => {
    if (
      !levelSelected.label ||
      !projectBimFileId ||
      !bimFileId ||
      statusUpdateForgeVersion !== STATUS_UPDATE_FORGE_VERSION.CHECKED ||
      !isLoadingDocument
    ) {
      return;
    }

    if (isLoadingDocument) {
      lastFetchArgs.current = { bimFileId: null, levelSelected: null };
    }

    /**
     * Disable refetch if the user change the system mode
     */
    if (
      lastFetchArgs.current.bimFileId === bimFileId &&
      lastFetchArgs.current.levelSelected === levelSelected.label
    ) {
      return;
    }

    if (
      systemMode === SystemModeType.Document ||
      taskSelected ||
      isOpenFilter
    ) {
      lastFetchArgs.current = { bimFileId, levelSelected: levelSelected.label };
      logDev("---FETCH---");
      generateDocumentCategoriesAndItems(bimFileId, levelSelected.label);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isOpenFilter,
    systemMode,
    levelSelected.label,
    projectBimFileId,
    statusUpdateForgeVersion,
    bimFileId,
    taskSelected,
    isLoadingDocument,
  ]);

  useEffect(() => {
    (async () => {
      if (
        !isLoadedLevels ||
        !projectBimFileId ||
        !bimFileId ||
        isSyncOfflineData
      ) {
        return;
      }

      sendWebSocketMessage({
        type: MessageType.RELOAD_DOCUMENT,
        data: "",
      });
      dispatch(setIsLoadingDocument(true));
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSyncOfflineData]);

  useEffect(() => {
    if (!webSocketMessage) {
      return;
    }

    if (webSocketMessage?.type === MessageType.RELOAD_DOCUMENT) {
      dispatch(setIsLoadingDocument(true));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webSocketMessage]);

  //#endregion
  const handleSetLoadedViewerOff = useCallback(() => {
    dispatch(setIsLoadedViewer(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    urn,
    familyInstances,
    isLoadedViewer,
    isLoadedSheetTransformRatio,
    isGeneratingSpaces,
    isGeneratingFamilyInstances,
    forgeViewContainerRef,
    isLoadedViewerModelData,
    shouldGenerateFamilyInstanceData,
    shouldGenerateSpacesData,
    handleChangeSheet,
    handleSetLoadedViewerOff,
  };
}
