import { ComponentStyleConfig } from "@chakra-ui/react";

export const tabsStyle: ComponentStyleConfig = {
  variants: {
    radius: {
      tab: {
        border: "none",
        p: "1.4rem 3rem",
        _hover: {},
        _active: {
          background: "transparent",
        },
        _selected: {
          backgroundColor: "var(--secondary-bg)",
          borderRadius: "4px 4px 0 0",
        },
        fontSize: "1.6rem",
        color: "#000000CC",
        fontWeight: "bold",
        mt: "1.1rem",
      },
    },
    line: {
      tab: {
        p: "1.1rem 3rem",
        _hover: {},
        _selected: {
          color: "#000000CC",
          borderBottom: "4px solid var(--blue)",
        },
        fontSize: "1.8rem",
        color: "#000000CC",
        fontWeight: "bold",
        whiteSpace: "nowrap",
        justifyContent: "flex-start",
        w: "100%",
        _focus: {
          boxShadow: "none",
        },
        _active: {
          bgColor: "transparent",
          opacity: 0.7,
        },
      },
    },
    lite: {
      tab: {
        p: "1.2rem 1.6rem",
        _hover: {},
        _selected: {
          color: "#000000",
          borderBottom: "4px solid var(--blue)",
        },
        borderBottom: "1px solid #707070",
        fontSize: "1.6rem",
        lineHeight: "2.4rem",
        color: "#B4B4B4",
        fontWeight: "bold",
        whiteSpace: "nowrap",
        justifyContent: "flex-start",
        w: "100%",
        _focus: {
          boxShadow: "none",
        },
        _active: {
          bgColor: "transparent",
          opacity: 0.7,
        },
      },
    },
    lineFull: {
      tab: {
        p: "1.1rem 3rem",
        _hover: {},
        _selected: {
          color: "#000000CC",
          borderBottom: "4px solid var(--blue)",
        },
        borderBottom: "4px solid #E2E2E3",
        fontSize: "1.6rem",
        color: "#000000CC",
        fontWeight: "bold",
        whiteSpace: "nowrap",
        justifyContent: "flex-start",
        w: "100%",
        _focus: {
          boxShadow: "none",
        },
        _active: {
          bgColor: "transparent",
          opacity: 0.7,
        },
      },
    },
    shadow: {
      tab: {
        p: "1.8rem 3rem",
        _hover: {},
        _selected: {
          color: "#1795FE",
          bg: "var(--table-alternate-background)",
          boxShadow: "3px -3px 6px #00000029",
        },
        boxShadow: "inset 0px -3px 6px #00000029",
        clipPath: "inset(-9px -9px 0px 0px)",
        fontSize: "1.4rem",
        lineHeight: "2rem",
        color: "#B4B4B4",
        fontWeight: "bold",
        whiteSpace: "nowrap",
        bg: "#ECECEC",
        w: "100%",
        _focus: {
          boxShadow: "none",
        },
        _active: {
          opacity: 0.7,
        },
      },
    },
  },
};
