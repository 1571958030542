import { FilterPhotoOptionType } from "./enum";

export const FilterPhotoOptions = [
  { value: FilterPhotoOptionType.WorkType, label: "工種" },
  { value: FilterPhotoOptionType.ShootingDate, label: "撮影年月日" },
  { value: FilterPhotoOptionType.Type, label: "種別" },
  { value: FilterPhotoOptionType.Subdivision, label: "細別" },
  {
    value: FilterPhotoOptionType.LargeClassificationPhotographs,
    label: "写真区分",
  },
];

export const defaultFilterPhoto = {
  option1: [],
  option2: [],
  option3: [],
  option4: [],
  option5: [],
};

export const defaultFilterOptions = {
  option1: FilterPhotoOptionType.WorkType,
  option2: FilterPhotoOptionType.ShootingDate,
  option3: FilterPhotoOptionType.Type,
  option4: FilterPhotoOptionType.Subdivision,
  option5: FilterPhotoOptionType.LargeClassificationPhotographs,
};

export const defaultProperties = {
  leftList: {
    "1": { id: "1", field: "photoTitle", name: "写真タイトル" },
    // "2": { id: "2", field: "", name: "写真区分" },
    "3": { id: "3", field: "builder", name: "施工者" },
    "4": { id: "4", field: "witness", name: "立会者" },
    "5": { id: "5", field: "constructionName", name: "工事名" },
    "6": {
      id: "6",
      field: "largeClassificationPhotographs",
      name: "写真大分類",
    },
    "7": {
      id: "7",
      field: "constructionManagementValue",
      name: "施工管理値",
    },
    "8": { id: "8", field: "contractorDescription", name: "受注者説明文" },
    "9": { id: "9", field: "filename", name: "ファイル名" },
    "10": {
      id: "10",
      field: "preliminaryWorkTypeClassification",
      name: "工種区分予備",
    },
  },
  rightList: {
    "11": { id: "11", field: "shootingDate", name: "撮影年月日" },
    "12": { id: "12", field: "workType", name: "工種" },
    "13": { id: "13", field: "subdivision", name: "細別" },
    "14": { id: "14", field: "shootingDateTime", name: "撮影日時" },
    "15": { id: "15", field: "location", name: "撮影場所" },
    "16": { id: "16", field: "type", name: "種別" },
  },
};

export const IMAGE_FILE_EXTENSIONS = [
  "jpg",
  "png",
  "jpeg",
  "jfif",
  "webp",
  "gif",
  "dng",
  "psg",
];
