export const elementIsVisibleInViewport = (
  el: any,
  partiallyVisible = false
) => {
  const { top, left, bottom, right } = el.getBoundingClientRect();
  const { innerHeight, innerWidth } = window;

  return partiallyVisible
    ? ((top > 0 && top < innerHeight) ||
        (bottom > 0 && bottom < innerHeight)) &&
        ((left > 0 && left < innerWidth) || (right > 0 && right < innerWidth))
    : top >= 0 && left >= 0 && bottom <= innerHeight && right <= innerWidth;
};

export const isVisibleInContainer = (
  element: HTMLElement | null | undefined,
  container: HTMLElement | null | undefined
) => {
  if (!element || !container) {
    return false;
  }
  const rect = element.getBoundingClientRect();
  const containerRect = container.getBoundingClientRect();
  const { innerHeight, innerWidth } = window;

  // Check if element is within container's viewport

  return (
    ((rect.top > 0 &&
      rect.top < containerRect.top + containerRect.height &&
      rect.top < innerHeight) ||
      (rect.bottom > 0 &&
        rect.bottom < containerRect.top + containerRect.height &&
        rect.bottom < innerHeight)) &&
    ((rect.left > 0 &&
      rect.left < containerRect.left + containerRect.width &&
      rect.left < innerWidth) ||
      (rect.right > 0 &&
        rect.right < containerRect.left + containerRect.width &&
        rect.right < innerWidth))
  );
};
