import { documentItemLogApi } from "apiClient/v2";
import { DocumentItemLog } from "interfaces/models/documentItem";
import { useEffect, useState } from "react";
import { sortArrayByField } from "utils/array";

interface Props {
  subItemId?: string;
}

const useSubItemLogs = (props?: Props) => {
  const { subItemId } = props || {};

  const [subItemLogs, setSubItemLogs] = useState<DocumentItemLog[]>([]);

  useEffect(() => {
    (async () => {
      if (!subItemId) {
        return;
      }

      const documentItemLogs = await documentItemLogApi.handleGetItemLogList({
        subItemId,
      });
      setSubItemLogs(
        sortArrayByField<DocumentItemLog>(documentItemLogs, "createdAt")
      );
    })();
  }, [subItemId]);

  return { subItemLogs, setSubItemLogs };
};

export default useSubItemLogs;
