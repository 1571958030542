import { DocumentCategoryDTO } from "interfaces/dtos/documentCategoryDTO";
import { NeptuneArea, Space } from "interfaces/models/area";
import { getHighlightColor } from "utils/color";
import { getCurrentViewer, selectDbIds } from "utils/forge";
import { ___sheetTransformMatrix } from "utils/forge/forge2d";
import { logDev } from "utils/logs";
import {
  DEFAULT_BORDER_COLOR,
  getCenterPoint,
  getEdgesMesh,
  getLeftTopVertexPosition,
} from "utils/threejs";
import "assets/fonts/typefaces/Noto_Sans_JP_Bold.js";
import { sleep } from "utils/common";

export interface AreaMesh extends THREE.Mesh {
  externalId: string;
  areaId: string;
  dbId: number;
}

const AreaOverlay = "area-overlay";
const DEFAULT_AREA_COLOR = "#fffbeb";

const DEFAULT_AREA_MATERIAL = new THREE.MeshPhongMaterial({
  color: "#009be0",
  opacity: 0,
  transparent: true,
  depthWrite: false,
  depthTest: true,
  side: THREE.DoubleSide,
});

const DEFAULT_LABEL_BACKGROUND_COLOR = "#696969";

export class AreaExtension extends Autodesk.Viewing.Extension {
  private spaceMap = new Map<string, AreaMesh[]>();
  private meshMap = new Map<string, THREE.Mesh>();
  private positionMap = new Map<string, THREE.Vector3>();
  private visible = true;
  private shouldReDrawArea = true;
  private areasToDraw: NeptuneArea[] = [];
  private spacesToDraw: Space[] = [];

  load() {
    if (!this.viewer.overlays.hasScene(AreaOverlay)) {
      this.viewer.impl.createOverlayScene(AreaOverlay);
    }

    logDev("area extension loaded");

    return true;
  }

  unload() {
    if (this.viewer.overlays.hasScene(AreaOverlay)) {
      this.viewer.impl.removeOverlayScene(AreaOverlay);
    }

    return true;
  }

  private createAreaLabel(areaTitle: string, mesh: THREE.Mesh) {
    // text
    const textGeometry = new (THREE as any).TextGeometry(areaTitle, {
      font: "notosansjp",
      size: 0.5,
      height: 0,
      curveSegments: 0,
      weight: "bold",
      bevelEnabled: false,
      bevelSegments: 0,
    });
    textGeometry.computeBoundingBox();
    const textMesh = new THREE.Mesh(
      textGeometry,
      new THREE.MeshBasicMaterial({
        color: "#fff",
        opacity: 0.75,
        transparent: true,
      })
    );
    textMesh.position.z = 0.2;

    // text background
    const bgGeometry = new THREE.PlaneBufferGeometry(
      textGeometry.boundingBox.max.x - textGeometry.boundingBox.min.x + 1,
      1
    );
    bgGeometry.computeBoundingBox();
    const bgMaterial = new THREE.MeshBasicMaterial({
      color: DEFAULT_LABEL_BACKGROUND_COLOR,
      opacity: 0.75,
      transparent: true,
    });
    const bgMesh = new THREE.Mesh(bgGeometry, bgMaterial);
    bgMesh.position.x =
      (textGeometry.boundingBox.max.x - textGeometry.boundingBox.min.x) / 2;
    bgMesh.position.y =
      (textGeometry.boundingBox.max.y - textGeometry.boundingBox.min.y) / 2;

    const group = new THREE.Group();
    group.add(textMesh);
    group.add(bgMesh);
    const textPosition = getLeftTopVertexPosition(mesh);
    group.position.set(
      textPosition.x + 0.55,
      textPosition.y -
        (bgGeometry.boundingBox.max.y - bgGeometry.boundingBox.min.y) +
        0.15,
      textPosition.z + 0.1
    );
    group.quaternion.set(0, 0, 0, 1);

    return group;
  }

  public setShouldReDrawArea(val: boolean) {
    this.shouldReDrawArea = val;
  }

  public getShouldReDrawArea() {
    return this.shouldReDrawArea;
  }

  public setAreasToDraw(areas: NeptuneArea[]) {
    this.areasToDraw = areas;
  }

  public setSpacesToDraw(spaces: Space[]) {
    this.spacesToDraw = spaces;
  }

  public async drawAreas({
    initialize = false,
    isDrawAreaLabel = true,
    isVisible,
  }: {
    isDrawAreaLabel?: boolean;
    initialize: boolean;
    isVisible?: boolean;
  }) {
    if (!this.shouldReDrawArea) {
      return;
    }
    const areas = this.areasToDraw;
    const spaces = this.spacesToDraw;
    const shouldApplyMatrixMesh =
      this?.viewer?.model?.is2d() && ___sheetTransformMatrix;
    this.setShouldReDrawArea(false);
    if (this.viewer.overlays.hasScene(AreaOverlay)) {
      this.viewer.overlays.clearScene(AreaOverlay);
    } else {
      this.viewer.overlays.addScene(AreaOverlay);
    }
    const mapSpace = new Map(spaces.map((space) => [space.externalId, space]));
    const loader = new (THREE as any).ObjectLoader();
    //@ts-ignore
    for (const area of areas) {
      const customSpaces = await Promise.all(
        area.externalIds.map((externalId) => {
          return new Promise<AreaMesh>((resolve) => {
            const space = mapSpace.get(externalId);
            if (space) {
              loader.parse(space.mesh, (mesh: AreaMesh) => {
                mesh.material = DEFAULT_AREA_MATERIAL.clone();
                mesh.dbId = space.mesh.dbId;
                mesh.externalId = space.externalId;
                mesh.add(getEdgesMesh(mesh));
                if (shouldApplyMatrixMesh) {
                  mesh.applyMatrix(___sheetTransformMatrix!);
                }
                isDrawAreaLabel &&
                  mesh.add(this.createAreaLabel(area.name, mesh));
                resolve(mesh);
              });
            } else {
              resolve(undefined as any);
            }
          });
        })
      ).then((res) => res.filter((i) => !!i));

      if (customSpaces.length) {
        this.spaceMap.set(area.id, customSpaces);
        // Initialize mesh
        if (initialize) {
          const defaultOptions = {
            meshColor: DEFAULT_BORDER_COLOR,
            opacity: 0,
            addMesh: true,
            isVisible,
          };
          customSpaces?.forEach((mesh) => {
            this.drawSpace(mesh, defaultOptions);
          });
        }
      }
    }
    //@ts-ignore
  }

  public async getSpaces() {
    if (this.shouldReDrawArea) {
      await this.drawAreas({ initialize: true, isVisible: false });
      // await this.viewer.overlays.addMesh
      await sleep(200);
    }

    return Array.from(this.spaceMap.values()).flat();
  }

  public select(documentCategory: DocumentCategoryDTO) {
    // Clear all mesh
    this.clearSelection();
    const statusColor = DEFAULT_AREA_COLOR;

    const extIds: any[] = [];
    if (documentCategory.neptuneAreaIds) {
      for (const id of documentCategory.neptuneAreaIds) {
        const spaces = this.spaceMap.get(id);
        if (spaces?.length) {
          for (const { externalId } of spaces) {
            extIds.push(externalId);
          }
        }
      }
    }

    this.highlightColorInMaterial({ statusColor, extIds });
  }

  private highlightColorInMaterial({
    extIds,
    statusColor,
  }: {
    extIds: string[];
    statusColor: string;
  }) {
    const meshes = (extIds
      .map((id) => this.meshMap.get(id))
      .filter((item) => !!item) || []) as unknown as THREE.Mesh[];

    meshes.forEach((mesh) => {
      const material = DEFAULT_AREA_MATERIAL.clone();
      material.color.set(getHighlightColor(new THREE.Color(statusColor)));
      material.opacity = 0.3;
      mesh.material = material;

      const bgMaterial = new THREE.MeshBasicMaterial({
        color: `#${(
          getHighlightColor(DEFAULT_LABEL_BACKGROUND_COLOR, 30) as any
        ).getHexString()}`,
      });
      if (mesh?.children?.[0]?.children?.[1]) {
        (mesh.children[0].children[1] as THREE.Mesh).material = bgMaterial;
      }

      (mesh?.children?.[0]?.children || [])?.forEach((child) => {
        (child as THREE.Mesh).material = new THREE.MeshBasicMaterial({
          color: statusColor,
        });
      });
      if (mesh?.children?.[1]?.children?.[1]) {
        (mesh.children[1].children[1] as THREE.Mesh).material =
          new THREE.MeshBasicMaterial({
            color: DEFAULT_LABEL_BACKGROUND_COLOR,
            opacity: 0.75,
            transparent: true,
          });
      }
    });

    this.viewer.impl.invalidate(true);
  }

  public drawSpace(
    mesh: AreaMesh,
    options?: {
      meshColor?: string;
      childColor?: string;
      opacity?: number;
      addMesh?: boolean;
      isVisible?: boolean;
    }
  ) {
    const material = DEFAULT_AREA_MATERIAL.clone();
    const opacity = options?.opacity ?? 1;
    if (options?.meshColor) {
      material.color.set(getHighlightColor(new THREE.Color(options.meshColor)));
    }
    material.opacity = opacity;
    mesh.material = material;
    mesh.children[0].children.forEach((child) => {
      (child as THREE.Mesh).material = new THREE.MeshBasicMaterial({
        color: options?.childColor || DEFAULT_BORDER_COLOR,
      });
    });
    mesh.visible = options?.isVisible ?? this.visible;

    if (options?.addMesh) {
      this.viewer.overlays.addMesh(mesh, AreaOverlay);
    }

    this.meshMap.set(mesh.externalId, mesh);
  }

  public clearSelection() {
    this.spaceMap.forEach((spaces) => {
      spaces.forEach((mesh) => {
        const material = DEFAULT_AREA_MATERIAL.clone();
        material.opacity = 0;
        mesh.material = material;
        (mesh?.children?.[0]?.children || []).forEach((child) => {
          (child as THREE.Mesh).material = new THREE.MeshBasicMaterial({
            color: DEFAULT_BORDER_COLOR,
          });
        });
        if (mesh?.children?.[1]?.children?.[1]) {
          (mesh.children[1]?.children[1] as THREE.Mesh).material =
            new THREE.MeshBasicMaterial({
              color: DEFAULT_LABEL_BACKGROUND_COLOR,
              opacity: 0.75,
              transparent: true,
            });
        }
      });
    });

    this.viewer.impl.invalidate(true);
  }

  public clear(isResetData = true) {
    if (this.viewer.overlays.hasScene(AreaOverlay)) {
      this.viewer.overlays.clearScene(AreaOverlay);
    }
    // Reset
    if (isResetData) {
      this.positionMap.clear();
      this.spaceMap.clear();
    }
  }

  public activateCreateTaskMode(
    documentCategory: DocumentCategoryDTO | undefined,
    options: {
      active: boolean;
      fitMesh?: boolean;
    }
  ) {
    selectDbIds([], {});
    const areas = documentCategory?.neptuneAreaIds || [];

    if (!areas?.length) {
      return;
    }

    const spaces = areas
      .map((area) => this.getSpace(area))
      .flat(1)
      .filter((i) => !!i) as AreaMesh[];

    if (!spaces) {
      return;
    }

    const $viewer = $(
      `#${this.viewer.clientContainer.id} div.adsk-viewing-viewer .canvas-wrap`
    );

    this.clearSelection();

    if (options.fitMesh) {
      this.fitMeshToView(spaces[0]);
    }

    spaces.forEach((mesh) => {
      const statusColor = options.active ? "#009BE0" : DEFAULT_AREA_COLOR;

      const material = DEFAULT_AREA_MATERIAL.clone();
      material.color.set(getHighlightColor(new THREE.Color(statusColor)));
      if (options.active) {
        // Do not thing
      } else {
        material.opacity = 0.3;
      }
      mesh.material = material;
      mesh.children[0].children.forEach((child) => {
        (child as THREE.Mesh).material = new THREE.MeshBasicMaterial({
          color: statusColor,
        });
      });

      // Color title and border
      $viewer.find(`.area-label[data-id='${areas?.join("-")}']`).css({
        "--status-color": statusColor,
      });
    });
    this.viewer.impl.invalidate(true);
  }

  private getSpace(areaId: string) {
    const spaces = this.spaceMap.get(areaId);
    if (spaces?.length) {
      return spaces[0];
    }
  }

  public getPosition(id: `${string}/${string}`) {
    const areaIds = id.split("/").pop()?.split(",");
    if (!areaIds?.length) {
      return;
    }
    const spaces: AreaMesh[] = areaIds
      .map((id) => this.getSpace(id))
      .filter((space) => !!space) as AreaMesh[];

    if (spaces.length) {
      const position = getCenterPoint(spaces);

      return position;
    }
  }

  public toggleVisibleArea(visible: boolean) {
    this.spaceMap.forEach((spaces) => {
      spaces.forEach((mesh) => {
        mesh.visible = visible;
      });
    });
    this.visible = visible;
    this.viewer.impl.invalidate(true);
  }

  private fitMeshToView(mesh: THREE.Mesh | undefined | null, _offset = 0) {
    if (!mesh) {
      return;
    }

    const is2d = this.viewer.model.is2d();

    const bound = new THREE.Box3().setFromObject(mesh);
    const offset = (is2d ? 20 : 4) + _offset;

    bound.min = bound.min.subScalar(offset);
    bound.max = bound.max.addScalar(offset);

    this.viewer.navigation.fitBounds(false, bound, true);
  }

  static register = () => {
    Autodesk.Viewing.theExtensionManager.registerExtension(
      "AreaExtension",
      AreaExtension
    );
  };
}

export const getAreaExtension = () => {
  return getCurrentViewer()?.getExtension("AreaExtension") as AreaExtension;
};
