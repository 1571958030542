import { Box } from "@chakra-ui/react";
import { LINE_HEIGHT_DEFAULT } from "components/modal/PreviewDocumentCategory/hooks/useHandleTextOverflow";
import { PHOTO_BOOK_CONTENT_TYPE } from "constants/enum";
import { TaskDTO } from "interfaces/dtos/taskDTO";
import { EditData } from "interfaces/models";
import { DocumentTaskDataFieldPhotoBooks } from "interfaces/models/documentTask";
import { memo, useCallback, useRef } from "react";
import ContentOfField from "./ContentPhotoBooksReport/ContentOfField";
import { iHandleDocumentTaskDetail } from "./hooks/usePreviewTaskSheetTemplate";

export const FIELD_NAME_INSTRUCTIONS = {
  TITLE: "タイトル",
  CONTENT: "指摘内容",
  USER_ASSIGNED: "高砂担当者",
  DEADLINE: "完了期限",
  PARTNER_COMPANY: "協力会社",
};

interface iProps {
  title: string;
  memo: string;
  partnerCompany: string;
  deadline: string;
  userAssigned: string;
  task: TaskDTO;

  photoBookContentKey?: string;
  editMode: boolean;
  defaultValue?: string;
  editDataStyle: EditData["style"];

  onEditPhotoBooksContent?: iHandleDocumentTaskDetail["onEditPhotoBooksContent"];
}

const ContentPhotoBooksReport = ({
  task,
  editMode,
  editDataStyle,
  title,
  memo: memoValue,
  partnerCompany,
  deadline,
  userAssigned,
  photoBookContentKey,
  onEditPhotoBooksContent,
}: iProps) => {
  const boxContainerRef = useRef<HTMLDivElement>(null);
  const sizeDefaultRef = useRef({
    fontSize: 14,
    lineHeight: 14 * LINE_HEIGHT_DEFAULT,
  });

  const handleSubmit = useCallback(
    (field: keyof DocumentTaskDataFieldPhotoBooks) => (data: EditData) => {
      const element = boxContainerRef.current;
      if (!photoBookContentKey || !element) {
        return;
      }

      const fontSizeNumber = Number(
        getComputedStyle(element).fontSize.replace("px", "")
      );
      const style = {
        fontSize: fontSizeNumber,
        lineHeight: fontSizeNumber * LINE_HEIGHT_DEFAULT,
      };

      const newData = structuredClone(data);
      if (field === "title") {
        newData.origin = task.taskTypeId;
      } else {
        newData.origin = (task as any)?.[field];
      }

      onEditPhotoBooksContent?.({
        key: photoBookContentKey,
        data: {
          [field]: newData,
          instructionStyle: style,
          taskId: task.id,
          type: PHOTO_BOOK_CONTENT_TYPE.INSTRUCTION,
        },
      });
    },
    [onEditPhotoBooksContent, photoBookContentKey, task]
  );

  return (
    <Box
      ref={boxContainerRef}
      lineHeight={`${
        editDataStyle?.lineHeight || sizeDefaultRef.current.lineHeight
      }px`}
      fontSize={`${
        editDataStyle?.fontSize || sizeDefaultRef.current.fontSize
      }px`}
      textAlign="left"
      height="100%"
    >
      <ContentOfField
        editMode={editMode}
        value={title}
        editDataStyle={editDataStyle}
        onEditSubmit={handleSubmit("title")}
      />
      <ContentOfField
        editMode={editMode}
        value={memoValue}
        editDataStyle={editDataStyle}
        onEditSubmit={handleSubmit("memo")}
      />
      <ContentOfField
        editMode={editMode}
        value={partnerCompany}
        editDataStyle={editDataStyle}
        onEditSubmit={handleSubmit("partnerCompany")}
      />
      <ContentOfField
        editMode={editMode}
        value={deadline}
        editDataStyle={editDataStyle}
        onEditSubmit={handleSubmit("deadline")}
      />
      <ContentOfField
        editMode={editMode}
        value={userAssigned}
        editDataStyle={editDataStyle}
        onEditSubmit={handleSubmit("userAssigned")}
      />
    </Box>
  );
};

export default memo(ContentPhotoBooksReport);
