import { documentCategoryApi, documentItemApi, projectApi, projectBimFileApi } from "apiClient/v2";
import { MenuOption } from "components/widgets/DashBoard/components/SelectTag";
import { RateI } from "components/widgets/DashBoard/components/TrendSection/Achievement";
import {
  DataModeE,
  DeadlineType,
  DocumentCategoryStatusType,
  MapDocumentCategoryStatusType,
  MapDocumentCategoryStatusTypeColor,
  MapTaskDealineTitle,
  SetImageType,
} from "constants/enum";
import { listCustomTags, listOfficeTags, listSystemTags } from "constants/task";
import useGetCurrentPath from "hooks/useGetCurrentPath";
import { DocumentCategoryDTO } from "interfaces/dtos/documentCategoryDTO";
import { DocumentItemDTO } from "interfaces/dtos/documentItemDTO";
import {
  DataModeT,
  DocDeadlineI,
  DocSectionI,
  DocType,
  FilterFormI,
  QuickFilterForm,
} from "interfaces/models/dashboard";
import { DataProjectModel } from "interfaces/models/dataProjectModel";
import { PartnerCompany } from "interfaces/models/partnerCompany";
import { User } from "interfaces/models/user";
import { flattenDeep, intersection, isEmpty } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setDocumentCategories, setDocumentItems } from "redux/documentSlice";
import { RootState } from "redux/store";
import { compareArray, uniqueArray } from "utils/array";
import { getBimFileInfo } from "utils/bim";
import { getDeadlineType, getProjectByBimFileId } from "utils/dashboard";
import {
  checkIsBetween,
  DATE_FORMAT,
  formatDate,
  getDaysFromNow,
} from "utils/date";
import { logError } from "utils/logs";
import { SEPERATOR } from "./useTask";

interface PropsI {
  users: User[];
  filteredForm?: FilterFormI;
  quickFilterForm?: QuickFilterForm;
  isUserDashboard?: boolean;
  mode?: DataModeT;
  levelOptions?: MenuOption[];
  projectOptions?: MenuOption[];
  projects?: DataProjectModel[];
  isProjectDashboard?: boolean;
  dataProjectList?: DataProjectModel[];
  isExpired?: boolean;
  trendStatuses?: string[];
  partnerCompanies?: PartnerCompany[];
}

export const TABLE_ITEMS_TITLE = "項目";

const useDocument = (props: PropsI) => {
  const {
    users,
    filteredForm,
    quickFilterForm,
    isUserDashboard,
    projects,
    isProjectDashboard,
    dataProjectList,
    isExpired,
    trendStatuses,
    partnerCompanies,
  } = props;
  const dispatch = useDispatch();
  const [loadingDocumentData, setLoadingDocumentData] =
    useState<boolean>(false);

  const { params } = useGetCurrentPath();
  const { families } = useSelector((state: RootState) => state.forgeViewer);
  const { documentCategories, documentItems } = useSelector(
    (state: RootState) => state.document
  );

  const getBimfileId = async () => {
    const dataProjects = dataProjectList?.length
      ? dataProjectList
      : (await projectBimFileApi.getProjectList()).data;

    const currentDataProject = dataProjects?.find(
      (project) => project.id === params?.projectId
    );
    const urn = currentDataProject?.defaultBimPathId?.split("/").pop();
    const { bimFileId } = getBimFileInfo(urn || "");

    return bimFileId;
  };

  const fetchDocumentItems = async () => {
    const bimFileId = await getBimfileId();

    if (isProjectDashboard) {
      return await documentItemApi.handleGetItemList({
        bimFileId,
      });
    } else {
      return await documentItemApi.handleGetItemList({});
    }
  };

  const fetchDocumentCategories = async () => {
    const bimFileId = await getBimfileId();

    return await documentCategoryApi.handleGetCategoryList({
      ...(isProjectDashboard && {
        bimFileId,
      }),
    });
  };

  const fetchDocumentData = async () => {
    setLoadingDocumentData(true);
    try {
      const [documentCategories, documentItems] = await Promise.all([
        fetchDocumentCategories(),
        fetchDocumentItems(),
      ]);
      dispatch(setDocumentItems(documentItems));
      dispatch(setDocumentCategories(documentCategories));
    } catch (err) {
      logError({ err });
    } finally {
      setLoadingDocumentData(false);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const filterDocuments = useCallback(
    (docs: DocType[], filterForm: FilterFormI) => {
      const filteredLevels = filterForm?.floor?.value || [];

      const filteredProjects = filterForm?.projects?.value || [];

      const filteredPartnerCompanies = filterForm?.partnerCompany?.value || [];
      const allTags = flattenDeep([
        listCustomTags.map((item) => item.value),
        listOfficeTags.map((item) => item.value),
        listSystemTags.map((item) => item.value),
        families.map((family) => family.name) || [],
      ]);
      const tagsInQuickFilterForm = quickFilterForm?.tags?.length
        ? quickFilterForm?.tags?.map((item) => item.value)
        : allTags;
      const tagsInFilterForm = filterForm?.tags?.value?.length
        ? filterForm?.tags?.value
        : allTags;

      const filteredTags = intersection(
        tagsInQuickFilterForm,
        tagsInFilterForm
      );

      const filteredStatuses = filterForm?.status?.value || [];

      const filteredSetImages = filterForm?.images?.value;

      return docs?.filter((item) => {
        // const documentItemsInDocumentCategory = documentItems.filter(
        //   (docItem) => {
        //     return (
        //       docItem.templateId === item.templateId &&
        //       item.areaId && docItem.areaIds?.includes(item.areaId) &&
        //       docItem.level === item.level
        //     );
        //   }
        // );

        const project = getProjectByBimFileId(
          item?.bimFileId as string,
          projects as DataProjectModel[]
        );

        const filteredByProject =
          isProjectDashboard || !filteredProjects.length
            ? true
            : filteredProjects?.some((option) => {
              return option?.value === project?.id;
            });

        const filteredByStartDateScheduled = checkIsBetween({
          targetDate: formatDate(item?.startDateScheduled, DATE_FORMAT),
          startDate: filterForm?.startDateScheduled?.value?.startDate,
          endDate: filterForm?.startDateScheduled?.value?.endDate,
        });

        const filteredByEndDateScheduled = checkIsBetween({
          targetDate: formatDate(item?.endDateScheduled, DATE_FORMAT),
          startDate: filterForm?.endDateScheduled?.value?.startDate,
          endDate: filterForm?.endDateScheduled?.value?.endDate,
        });

        const filteredByStartDate = checkIsBetween({
          targetDate: formatDate(item?.createdAt, DATE_FORMAT),
          startDate: filterForm?.startDate?.value?.startDate,
          endDate: filterForm?.startDate?.value?.endDate,
        });

        const filteredByEndDate =
          !filterForm?.endDate?.value?.startDate &&
            !filterForm?.endDate?.value?.endDate
            ? true
            : checkIsBetween({
              targetDate: formatDate(
                (item as DocumentItemDTO)?.approvedDateTime,
                DATE_FORMAT
              ),
              startDate: filterForm?.endDate?.value?.startDate,
              endDate: filterForm?.endDate?.value?.endDate,
            });

        const listAssignedUser = Array.isArray(item?.userAssigned)
          ? item?.userAssigned
          : [];

        const filteredByAssignedUser = !isEmpty(filterForm?.manager?.value)
          ? listAssignedUser?.some(
            (userId) => userId === filterForm?.manager?.value?.value
          )
          : true;

        const filteredByLevel =
          isUserDashboard || !filteredLevels.length
            ? true
            : filteredLevels?.some((level) => level?.name === item.level);

        const filteredByTags = compareArray(filteredTags, uniqueArray(allTags))
          ? true
          : intersection(filteredTags, item?.tags).length;

        const filteredByStatus = !filteredStatuses?.length
          ? true
          : filteredStatuses?.some((status) => {
            if (status.value === DocumentCategoryStatusType.NotStarted) {
              return (
                item?.status === DocumentCategoryStatusType.NotStarted ||
                !item.status ||
                !Object.keys(DocumentCategoryStatusType)?.includes(
                  item?.status as keyof typeof DocumentCategoryStatusType
                )
              );
            }

            return status.value === item?.status;
          });

        // #2306
        // const filteredBySetImages =
        //   filteredSetImages?.length === 2 || !filteredSetImages?.length
        //     ? true
        //     : isDocumentCategory
        //     ? filteredSetImages?.length === 1
        //       ? filteredSetImages?.[0] === SetImageType.SetImage
        //         ? documentItemsInDocumentCategory?.some(
        //             (docItem) => (docItem as DocumentItemDTO)?.images?.length
        //           )
        //         : documentItemsInDocumentCategory?.some(
        //             (docItem) => !(docItem as DocumentItemDTO)?.images?.length
        //           )
        //       : false
        //     : filteredSetImages?.length === 1
        //     ? filteredSetImages?.[0] === SetImageType.SetImage
        //       ? (item as DocumentItemDTO)?.images?.length
        //       : !(item as DocumentItemDTO)?.images?.length
        //     : false;
        const filteredBySetImages = true;

        const filteredByPartnerCompany =
          !filteredPartnerCompanies.length ||
          filteredPartnerCompanies.length === partnerCompanies?.length;

        return (
          filteredByAssignedUser &&
          filteredByLevel &&
          filteredByTags &&
          filteredByStatus &&
          filteredBySetImages &&
          filteredByProject &&
          filteredByStartDateScheduled &&
          filteredByEndDateScheduled &&
          filteredByStartDate &&
          filteredByEndDate &&
          filteredByPartnerCompany
        );
      });
    },
    [
      documentItems,
      families,
      isProjectDashboard,
      isUserDashboard,
      partnerCompanies?.length,
      projects,
      quickFilterForm?.tags,
    ]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getFilteredDocumentsByStatus = ({
    filterForm,
    isDocumentCategory,
  }: {
    filterForm: FilterFormI;
    isDocumentCategory?: boolean;
  }) => {
    const sortedFilteredDocsKeys = [
      DocumentCategoryStatusType.NotStarted,
      DocumentCategoryStatusType.Underway,
      DocumentCategoryStatusType.SendBack,
      DocumentCategoryStatusType.WaitConfirm,
      DocumentCategoryStatusType.Approved,
    ];

    const filteredDocuments: {
      [DocumentCategoryStatusType.NotStarted]: DocType[];
      [DocumentCategoryStatusType.Underway]: DocType[];
      [DocumentCategoryStatusType.SendBack]: DocType[];
      [DocumentCategoryStatusType.WaitConfirm]: DocType[];
      [DocumentCategoryStatusType.Approved]: DocType[];
    } = {
      [DocumentCategoryStatusType.NotStarted]: [],
      [DocumentCategoryStatusType.Underway]: [],
      [DocumentCategoryStatusType.SendBack]: [],
      [DocumentCategoryStatusType.WaitConfirm]: [],
      [DocumentCategoryStatusType.Approved]: [],
    };

    const docs: DocType[] = isDocumentCategory
      ? documentCategories
      : documentItems;

    docs
      ?.map((item) => {
        const documentItemsInDocumentCategory = isDocumentCategory
          ? documentItems.filter(
            (docItem) =>
              docItem.templateId === item.templateId &&
              docItem.level === item.level &&
              (item as DocumentCategoryDTO).areaId &&
              docItem.areaIds?.includes(
                (item as DocumentCategoryDTO).areaId!
              ) &&
              !docItem.parentId
          )
          : null;

        const numOfDocumentItemsApproved =
          documentItemsInDocumentCategory?.filter(
            (item) => item.status === DocumentCategoryStatusType.Approved
          ).length;

        const progress = documentItemsInDocumentCategory?.length
          ? `${numOfDocumentItemsApproved}/${documentItemsInDocumentCategory?.length}`
          : SEPERATOR;

        const deadlineType = getDeadlineType(item?.endDateScheduled as string);

        const documentCategoryTitle =
          documentCategories.find((docCategory) => {
            return (
              docCategory.templateId === item.templateId &&
              docCategory.level === item.level &&
              (docCategory as DocumentCategoryDTO).areaId &&
              (item as DocumentItemDTO).areaIds?.includes(
                (docCategory as DocumentCategoryDTO).areaId!
              )
            );
          })?.title || SEPERATOR;

        const project = getProjectByBimFileId(
          String(item?.bimFileId),
          projects as DataProjectModel[]
        );

        const projectName = project?.name;

        const statusName =
          MapDocumentCategoryStatusType?.[
          item?.status as keyof typeof MapDocumentCategoryStatusType
          ] ||
          MapDocumentCategoryStatusType?.[
          DocumentCategoryStatusType.NotStarted
          ];

        const listAssignedUser = Array.isArray(item?.userAssigned)
          ? item?.userAssigned
          : [];

        return {
          ...item,
          ...(isDocumentCategory && {
            progress,
          }),
          assignedUsers: listAssignedUser?.map((userId) => {
            const user = users?.find((user) => user?.id === userId);

            return {
              assignedUserName: user?.name || SEPERATOR,
              avatarUrl: user?.avatar,
              userId,
            };
          }),
          deadlineType,
          documentCategoryTitle,
          statusName,
          projectName,
        };
      })
      .filter((item) => {
        return isDocumentCategory
          ? item?.progress !== SEPERATOR
          : item?.documentCategoryTitle !== SEPERATOR;
      })
      .forEach((item) => {
        if (
          !item?.status ||
          item?.status === DocumentCategoryStatusType.NotStarted ||
          !sortedFilteredDocsKeys?.includes(item?.status as any)
        ) {
          filteredDocuments?.[DocumentCategoryStatusType.NotStarted]?.push(
            item
          );
        } else {
          filteredDocuments?.[
            item?.status as `${DocumentCategoryStatusType}`
          ]?.push(item);
        }
      });

    return Object.keys(filteredDocuments)
      .sort((a, b) => {
        return (
          sortedFilteredDocsKeys.indexOf(a as any) -
          sortedFilteredDocsKeys.indexOf(b as any)
        );
      })
      ?.map((key) => {
        const docs = filterDocuments(
          filteredDocuments[key as `${DocumentCategoryStatusType}`],
          filterForm
        );

        return {
          title: TABLE_ITEMS_TITLE,
          docs,
          status: key,
        };
      });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getFilteredDocumentsByDeadline = ({
    filterForm,
    isDocumentCategory,
  }: {
    filterForm: FilterFormI;
    isDocumentCategory?: boolean;
  }) => {
    const filteredDocs: DocDeadlineI = {
      expired: {
        title: "",
        docs: [],
      },
      aboutToExpire: {
        title: "",
        docs: [],
      },
      notExpired: {
        title: "",
        docs: [],
      },
    };

    const docs: DocType[] = isDocumentCategory
      ? documentCategories
      : documentItems;

    docs
      ?.map((item) => {
        const documentItemsInDocumentCategory = isDocumentCategory
          ? documentItems.filter((docItem) => {
            return (
              docItem.templateId === item.templateId &&
              docItem.level === item.level &&
              (item as DocumentCategoryDTO).areaId &&
              docItem.areaIds?.includes(
                (item as DocumentCategoryDTO).areaId!
              ) &&
              !docItem.parentId
            );
          })
          : null;

        const numOfDocumentItemsApproved =
          documentItemsInDocumentCategory?.filter(
            (item) => item.status === DocumentCategoryStatusType.Approved
          ).length;

        const progress = documentItemsInDocumentCategory?.length
          ? `${numOfDocumentItemsApproved}/${documentItemsInDocumentCategory?.length}`
          : SEPERATOR;

        const documentCategoryTitle =
          documentCategories.find((docCategory) => {
            return (
              docCategory.templateId === item.templateId &&
              docCategory.level === item.level &&
              (docCategory as DocumentCategoryDTO).areaId &&
              (item as DocumentItemDTO).areaIds?.includes(
                (docCategory as DocumentCategoryDTO).areaId!
              )
            );
          })?.title || SEPERATOR;

        const statusName =
          MapDocumentCategoryStatusType?.[
          item?.status as keyof typeof MapDocumentCategoryStatusType
          ] ||
          MapDocumentCategoryStatusType?.[
          DocumentCategoryStatusType.NotStarted
          ];

        const project = getProjectByBimFileId(
          String(item?.bimFileId),
          projects as DataProjectModel[]
        );

        const projectName = project?.name;

        const listAssignedUser = Array.isArray(item?.userAssigned)
          ? item?.userAssigned
          : [];

        return {
          ...item,
          ...(isDocumentCategory && {
            progress,
          }),
          assignedUsers: listAssignedUser?.map((userId) => {
            const user = users?.find((user) => user?.id === userId);

            return {
              assignedUserName: user?.name || SEPERATOR,
              avatarUrl: user?.avatar,
              userId,
            };
          }),
          documentCategoryTitle,
          statusName,
          projectName,
        };
      })
      .filter((item) =>
        isDocumentCategory
          ? item?.progress !== SEPERATOR
          : item?.documentCategoryTitle !== SEPERATOR
      )
      .forEach((doc) => {
        const deadlineType = getDeadlineType(doc.endDateScheduled as string);

        if (deadlineType === DeadlineType.expired) {
          filteredDocs?.expired?.docs?.push(doc);
          filteredDocs.expired.title = MapTaskDealineTitle.expired;

          return;
        }

        if (deadlineType === DeadlineType.aboutToExpire) {
          filteredDocs?.aboutToExpire?.docs?.push(doc);
          filteredDocs.aboutToExpire.title = MapTaskDealineTitle.aboutToExpire;

          return;
        }

        filteredDocs?.notExpired.docs?.push(doc);
        filteredDocs.notExpired.title = MapTaskDealineTitle.notExpired;
      });

    return Object.keys(filteredDocs)?.map((key) => {
      return {
        deadlineType: key as DeadlineType,
        title: filteredDocs[key as keyof typeof MapTaskDealineTitle].title,
        docs: filterDocuments(
          filteredDocs[key as keyof typeof MapTaskDealineTitle].docs,
          filterForm
        ),
      };
    });
  };

  const filteredDocumentItemsByDeadline = getFilteredDocumentsByDeadline({
    filterForm: filteredForm as FilterFormI,
    isDocumentCategory: false,
  });

  const filteredDocumentCategoriesByDeadline = getFilteredDocumentsByDeadline({
    filterForm: filteredForm as FilterFormI,
    isDocumentCategory: true,
  });

  const filterDocumentItemsByStatus = getFilteredDocumentsByStatus({
    filterForm: filteredForm as FilterFormI,
    isDocumentCategory: false,
  });

  const filterDocumentCategoriesByStatus = getFilteredDocumentsByStatus({
    filterForm: filteredForm as FilterFormI,
    isDocumentCategory: true,
  });

  const docData: DocSectionI[] = useMemo(() => {
    if (quickFilterForm?.mode === DataModeE.ALL) {
      return filteredDocumentItemsByDeadline;
    }

    if (quickFilterForm?.mode === DataModeE.DOC) {
      return filterDocumentItemsByStatus;
    }

    return filterDocumentCategoriesByStatus;
  }, [
    filterDocumentCategoriesByStatus,
    filterDocumentItemsByStatus,
    filteredDocumentItemsByDeadline,
    quickFilterForm?.mode,
  ]);

  const filteredDocumentsByStatus =
    quickFilterForm?.mode === DataModeE.DOC
      ? filterDocumentItemsByStatus
      : filterDocumentCategoriesByStatus;

  const documentRates: RateI[] = useMemo(() => {
    const total = filteredDocumentsByStatus?.reduce((acc, item) => {
      return acc + Number(item.docs.length);
    }, 0);

    return filteredDocumentsByStatus?.map((item) => {
      return {
        title: `${MapDocumentCategoryStatusType[
          item?.status as keyof typeof MapDocumentCategoryStatusType
          ]
          }: ${item?.docs?.length}`,
        rate: (item?.docs?.length / total) * 100,
        color:
          MapDocumentCategoryStatusTypeColor[
          item.status as keyof typeof MapDocumentCategoryStatusTypeColor
          ],
      };
    });
  }, [filteredDocumentsByStatus]);

  const trendingList = useMemo(() => {
    const numOfExpiredDoc =
      (quickFilterForm?.mode === DataModeE.DOC
        ? filteredDocumentItemsByDeadline
        : filteredDocumentCategoriesByDeadline
      ).find((item) => item.deadlineType === "expired")?.docs?.length || 0;

    const {
      numOfNotStartedDoc,
      numOfUnderwayDoc,
      numOfSendBackDoc,
      numOfWaitConfirmDoc,
      numOfApprovedDoc,
    } = docData?.reduce(
      (acc, item) => {
        if (item.status === DocumentCategoryStatusType.NotStarted) {
          Object.assign(acc, {
            numOfNotStartedDoc: item?.docs?.length,
          });
        }

        if (item.status === DocumentCategoryStatusType.Underway) {
          Object.assign(acc, {
            numOfUnderwayDoc: item?.docs?.length,
          });
        }

        if (item.status === DocumentCategoryStatusType.SendBack) {
          Object.assign(acc, {
            numOfSendBackDoc: item?.docs?.length,
          });
        }

        if (item.status === DocumentCategoryStatusType.WaitConfirm) {
          Object.assign(acc, {
            numOfWaitConfirmDoc: item?.docs?.length,
          });
        }

        if (item.status === DocumentCategoryStatusType.Approved) {
          Object.assign(acc, {
            numOfApprovedDoc: item?.docs?.length,
          });
        }

        return acc;
      },
      {
        numOfNotStartedDoc: 0,
        numOfUnderwayDoc: 0,
        numOfSendBackDoc: 0,
        numOfWaitConfirmDoc: 0,
        numOfApprovedDoc: 0,
      }
    );

    const totalDocs =
      numOfNotStartedDoc +
      numOfUnderwayDoc +
      numOfSendBackDoc +
      numOfWaitConfirmDoc +
      numOfApprovedDoc;

    return [
      {
        title:
          quickFilterForm?.mode === DataModeE.DOC
            ? "期限超過チェック項目"
            : "期限超過書類",
        count: numOfExpiredDoc,
        status: Object.values(DocumentCategoryStatusType),
      },
      {
        title:
          quickFilterForm?.mode === DataModeE.DOC
            ? "修正待ちチェック項目"
            : "修正待ち書類",
        count: numOfUnderwayDoc + numOfSendBackDoc,
        status: [
          DocumentCategoryStatusType.Underway,
          DocumentCategoryStatusType.SendBack,
        ],
      },
      {
        title:
          quickFilterForm?.mode === DataModeE.DOC
            ? "承認待ちチェック項目"
            : "承認待ち書類",
        count: numOfWaitConfirmDoc,
        status: [DocumentCategoryStatusType.WaitConfirm],
      },
      {
        title:
          quickFilterForm?.mode === DataModeE.DOC
            ? "承認済みチェック項目"
            : "承認済み書類",
        count: numOfApprovedDoc,
        status: [DocumentCategoryStatusType.Approved],
      },
      {
        title:
          quickFilterForm?.mode === DataModeE.DOC
            ? "合計チェック項目"
            : "合計書類",
        count: totalDocs,
        status: Object.values(DocumentCategoryStatusType),
      },
    ];
  }, [
    docData,
    filteredDocumentCategoriesByDeadline,
    filteredDocumentItemsByDeadline,
    quickFilterForm?.mode,
  ]);

  const isDocumentDataEmpty = useMemo(() => {
    return docData?.every((item) => {
      const filteredDocs = item.docs.filter((doc) => {
        const diffDays = getDaysFromNow(
          new Date(doc?.endDateScheduled as string)
        ) as number;

        if (isExpired) {
          return diffDays >= 0 && !!diffDays && doc?.endDateScheduled;
        }

        return true;
      });

      const hidden =
        (trendStatuses?.length &&
          !trendStatuses?.includes(String(item?.status))) ||
        !filteredDocs?.length;

      return !item.docs.length || hidden;
    });
  }, [docData, isExpired, trendStatuses]);

  const isFilteredDocumentCategoriesByDeadlineEmpty = useMemo(() => {
    return filteredDocumentCategoriesByDeadline?.every(
      (item) => !item.docs.length
    );
  }, [filteredDocumentCategoriesByDeadline]);

  useEffect(() => {
    fetchDocumentData();
  }, []);

  return {
    docData,
    loadingDocumentData,
    documentRates,
    trendingList,
    filteredDocumentCategoriesByDeadline,
    isDocumentDataEmpty,
    isFilteredDocumentCategoriesByDeadlineEmpty,
    filteredDocumentsByStatus,
  };
};

export default useDocument;
