import {
  Center,
  Button,
  Menu,
  MenuButton,
  MenuButtonProps,
  MenuItemOption,
  MenuList,
  MenuListProps,
  MenuOptionGroup,
  Text,
  UseMenuProps,
  Flex,
} from "@chakra-ui/react";
import { SvgIcon } from "components/SvgIcon";
import CustomTooltipByScroll from "components/ui/CustomTooltipByScroll";
import { ReactNode, useMemo, useRef } from "react";
import isEmpty from "lodash/isEmpty";
import { WARNING_BIM_FILE_MSG } from "constants/app";
import { WarningIcon } from "@chakra-ui/icons";

interface Props {
  options: {
    name: string | ReactNode;
    value: string;
    isDisabled?: boolean;
    data?: Record<string, any>;
  }[];
  onChange?: (value: string | string[], data?: Record<string, any>) => void;
  value: string;
  iconClose?: boolean;
  hiddenIcon?: boolean;
  placeholder?: string;
  menuListProps?: MenuListProps;
  iconArrow?: string;
  isShowTooltip?: boolean;
  menuProps?: UseMenuProps;
  dataType?: "BimFile" | "unknown";
}

const DEFAULT_SPACING = 60;
const DEFAULT_WIDTH_DROPDOWN = 300;

function Dropdown({
  options,
  onChange,
  value,
  iconClose,
  hiddenIcon,
  placeholder,
  menuListProps,
  iconArrow = "/img/icon-navigation-arrow_drop_down.svg",
  isShowTooltip = false,
  menuProps,
  dataType,
  ...props
}: Props & MenuButtonProps) {
  const dropdownRef = useRef<HTMLButtonElement>(null);
  const dropdownList = useRef<HTMLDivElement>(null);

  const isBimFile = dataType === "BimFile";

  const title = useMemo(() => {
    return options.find((o) => o.value === value)?.name || placeholder;
  }, [options, value, placeholder]);

  return (
    <Menu autoSelect={false} {...menuProps}>
      {({ isOpen }) => (
        <>
          <MenuButton
            ref={dropdownRef}
            variant="whiteIcon"
            as={Button}
            textAlign="left"
            pl="2rem"
            rightIcon={
              <SvgIcon
                src={iconArrow}
                transition="0.4s"
                transform={
                  isOpen
                    ? `rotate(${
                        (menuProps?.placement || "")?.includes("right")
                          ? "-90deg"
                          : "180deg"
                      })`
                    : "rotate(0deg)"
                }
              />
            }
            color="#171717"
            pos="relative"
            {...props}
            isDisabled={props.disabled}
          >
            <Text
              w="100%"
              overflow="hidden"
              textOverflow="ellipsis"
              color="#171717"
            >
              {title ? title : "選択します"}
            </Text>
          </MenuButton>
          <MenuList
            ref={dropdownList}
            minW={dropdownRef.current?.clientWidth}
            maxW="100%"
            zIndex={20}
            pos="absolute"
            left="0px"
            {...menuListProps}
          >
            {isEmpty(options) ? (
              <Center padding="1.2rem">データがありません</Center>
            ) : (
              <MenuOptionGroup title="" type="radio" value={value}>
                {options?.map((item, index) => {
                  const isShowWarningBimFile = isBimFile && item.isDisabled;

                  return (
                    <MenuItemOption
                      key={index}
                      isDisabled={item.isDisabled}
                      onClick={() => onChange?.(item?.value, item?.data)}
                      fontSize="1.4rem"
                      fontWeight="medium"
                      fontFamily="NotoSansCJKjp"
                      value={item?.value}
                      flexDirection="row-reverse"
                      icon={
                        hiddenIcon ? null : iconClose ? (
                          <SvgIcon src="/img/icon-close-menu.svg" />
                        ) : (
                          <SvgIcon src="/img/icon-action-check_circle.svg" />
                        )
                      }
                      color="#171717"
                      borderBottom="1px solid var(--primary-border-color)"
                      _last={{
                        borderBottom: "none",
                      }}
                      _hover={{
                        bgColor: "#F0F9FF",
                      }}
                    >
                      {isShowTooltip ? (
                        <CustomTooltipByScroll
                          parentRef={dropdownList}
                          label={
                            isShowWarningBimFile
                              ? WARNING_BIM_FILE_MSG
                              : ((item?.name || "") as string)
                          }
                        >
                          <Flex
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Text
                              maxW={`calc(${
                                dropdownRef.current?.clientWidth ||
                                DEFAULT_WIDTH_DROPDOWN
                              }px - ${DEFAULT_SPACING}px)`}
                              textOverflow="ellipsis"
                              overflow="hidden"
                              whiteSpace="nowrap"
                            >
                              {item?.name}
                            </Text>
                            {isShowWarningBimFile && (
                              <WarningIcon color="#dd3232" />
                            )}
                          </Flex>
                        </CustomTooltipByScroll>
                      ) : (
                        <>{item?.name}</>
                      )}
                    </MenuItemOption>
                  );
                })}
              </MenuOptionGroup>
            )}
          </MenuList>
        </>
      )}
    </Menu>
  );
}

export default Dropdown;
