import { Box, BoxProps } from "@chakra-ui/react";
import ComponentPreview from "components/editor-builder/component-preview";
import {
  BlackboardTemplateBackground,
  BlackboardTemplateBackgroundType,
  DEFAULT_BLACKBOARD_CONTENT_SIZE,
} from "constants/blackBoardTemplate";
import { DEFAULT_PAGE_RATIO, GRID_TEMPLATE_SIZE } from "constants/document";
import { ContentType } from "constants/enum";
import { Blackboard } from "interfaces/models/blackboard";
import { BlackboardTemplate } from "interfaces/models/blackboardTemplate";
import { TemplateComponent } from "interfaces/models/component";
import { iBlackboardTemplateProps } from "interfaces/models/documentTemplate";
import { memo, useEffect, useMemo, useRef, useState } from "react";
import { cloneDeep } from "lodash";

interface iProps extends BoxProps {
  blackboardData?: Blackboard;
  data: Partial<BlackboardTemplate>;
  contentType?: ContentType;
  blackboardTemplateProps?: iBlackboardTemplateProps;
  isDisabled?: boolean;

  onChangeBlackboardData?: (data: Blackboard) => void;
}

function BlackboardTemplateImage({
  blackboardData,
  data,
  contentType = ContentType.EDITOR,
  blackboardTemplateProps,
  onChangeBlackboardData,
  isDisabled,
  ...rest
}: iProps) {
  const [currentWidth, setCurrentWidth] = useState(0);
  const [currentHeight, setCurrentHeight] = useState(0);

  const boxRef = useRef<HTMLDivElement>(null);

  const components: TemplateComponent[] = useMemo(() => {
    return cloneDeep(data?.components || [])?.sort(
      (a, b) => a.position.y - b.position.y
    );
  }, [data.components]);

  const cellSize = useMemo(() => {
    return {
      width: GRID_TEMPLATE_SIZE,
      height: GRID_TEMPLATE_SIZE,
    };
  }, []);

  const ratio = useMemo(() => {
    return {
      sizePageRatio: DEFAULT_PAGE_RATIO,
      pageDirectionRatio: DEFAULT_PAGE_RATIO,
    };
  }, []);

  const zoomRatio = useMemo(
    () => currentWidth / DEFAULT_BLACKBOARD_CONTENT_SIZE.width,
    [currentWidth]
  );

  const blackboardTemplateBackground = useMemo(() => {
    return BlackboardTemplateBackground[
      data?.backgroundType || BlackboardTemplateBackgroundType.GREEN
    ]?.backgroundColor;
  }, [data?.backgroundType]);

  useEffect(() => {
    const ele = boxRef.current;
    if (!ele) {
      return;
    }

    const resizeObserver = new ResizeObserver((entries) => {
      const { width, height } = entries[0].contentRect;
      setCurrentWidth(width);
      setCurrentHeight(height);
    });

    resizeObserver.observe(ele);

    return () => {
      resizeObserver.unobserve(ele);
    };
  }, []);

  if (!data?.components) {
    return (
      <Box
        width="calc(100% + 1px)"
        height="calc(100% + 2px)"
        backgroundColor="#0D370E"
      />
    );
  }

  return (
    <Box
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: blackboardTemplateBackground,
        padding: "2px",
      }}
      ref={boxRef}
      {...rest}
    >
      <Box
        style={{
          position: "relative",
          width: "100%",
          height: "100%",
        }}
      >
        {components?.map((component, index) => {
          return (
            <ComponentPreview
              componentsLenght={components?.length}
              index={index}
              key={index}
              page={0}
              pages={[]}
              component={component}
              zoomRatio={zoomRatio}
              zoomRatioHeight={
                currentHeight / DEFAULT_BLACKBOARD_CONTENT_SIZE.height
              }
              cellSize={cellSize}
              sizePageRatio={ratio.sizePageRatio}
              pageDirectionRatio={ratio.pageDirectionRatio}
              isOnlyView={true}
              isBlackboardTemplate={true}
              isBlackboardTemplateImage={true}
              contentType={contentType}
              blackboardData={blackboardData}
              blackboardTemplateProps={blackboardTemplateProps}
              onChangeBlackboardData={(data: Blackboard) => {
                if (isDisabled) return;
                onChangeBlackboardData?.(data);
              }}
              isDisabled={isDisabled}
            />
          );
        })}
      </Box>
    </Box>
  );
}

export default memo(BlackboardTemplateImage);
