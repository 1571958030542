import jsCookie from "js-cookie";

export const EXPIRE_TIME_COOKIE = 30; // 30 day

export const getSessionStorage = <T = any>(
  key: string,
  fallbackValue = {}
): T => {
  if (typeof sessionStorage === "undefined") {
    return fallbackValue as unknown as T;
  }
  const data = sessionStorage.getItem(key);
  try {
    return (JSON.parse(data as string) || fallbackValue) as T;
  } catch (e) {
    return (data || fallbackValue) as unknown as T;
  }
};

export const setSessionStorage = (
  key: string,
  value?: string | boolean | number | object
) => {
  sessionStorage.setItem(key, JSON.stringify(value));
};

export const getLocalStorage = <T = any>(
  key: string,
  fallbackValue: any = {}
): T => {
  if (typeof localStorage === "undefined") {
    return fallbackValue as unknown as T;
  }
  const data = localStorage.getItem(key);
  try {
    if (fallbackValue === null) {
      return data as unknown as T;
    }

    return (JSON.parse(data as string) || fallbackValue) as T;
  } catch (e) {
    return (data || fallbackValue) as unknown as T;
  }
};

export const setLocalStorage = (
  key: string,
  value?: string | boolean | number | object
) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const storageCookie = (key: string, value: string) => {
  jsCookie.set(key, value, {
    expires: EXPIRE_TIME_COOKIE,
  });
};

export const getHumanReadableSize = function (bytes: number) {
  const thresh = 1024;
  if (Math.abs(bytes) < thresh) {
    return `${bytes} B`;
  }
  const units = ["KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  let u = -1;
  do {
    bytes /= thresh;
    ++u;
  } while (Math.abs(bytes) >= thresh && u < units.length - 1);

  return `${bytes.toFixed(1)} ${units[u]}`;
};
