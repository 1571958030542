import { userApi, authApi } from "apiClient/v2";

import { EXP_TOKEN_COOKIE, ID_TOKEN_COOKIE } from "constants/storage";
import { User } from "interfaces/models/user";
import { getLocalStorage, setLocalStorage } from "./storage";
import { logout, storageUser } from "./user";

export const EXPIRE_TIME_TOKEN = 5 * 60 * 1000;

export const getCurrentUser = async (
  callback: (error: any, data: any) => void
) => {
  const user: User = getLocalStorage("userInfo");

  if (!user?.id) {
    return callback(null, null);
  }

  try {
    const req = await userApi.handleGetProfile();

    return callback(null, req);
  } catch (err) {
    return callback(err, null);
  }
};

export const doRefreshToken = async () => {
  const response = await authApi.refreshTokenFromPlanet();

  if (!response) {
    logout();

    return;
  }

  const userData = await (
    await fetch(`${process.env.REACT_APP_API_HOST_URL}/v2/auth/current-user`, {
      credentials: "include",
    })
  ).json();
  const user = userData?.data?.user;
  const tokens = userData?.data?.tokens;

  if (!user || !tokens) {
    logout();

    return;
  }

  setLocalStorage(EXP_TOKEN_COOKIE, Date.now() + Number(tokens.expIn) * 1000);
  setLocalStorage(ID_TOKEN_COOKIE, tokens.idToken);
  storageUser(user);

  return user;
};

export const decodeJwt = (token: string) => {
  const jwtParts = token.split(".");
  const jwtPayload = jwtParts[1];
  const decodedPayload = window.atob(jwtPayload);
  const jsonPayload = JSON.parse(decodedPayload);

  return jsonPayload;
};
