import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { taskApi, taskTypeApi } from "apiClient/v2";
import { REDUCER_KEY } from "constants/redux";
import { TaskDTO } from "interfaces/dtos/taskDTO";
import { DocumentTask } from "interfaces/models/documentTask";
import { TaskSheetTemplate } from "interfaces/models/taskSheetTemplate";
import { TaskType } from "interfaces/models/taskType";
import { orderBy, reverse } from "lodash";
import { getDbIdByExternalId, ___mapExternalId } from "utils/forge";

export interface TaskState {
  taskTypes: TaskType[];
  filteredTaskTypes: TaskType[];
  isLoadingTaskType: boolean;
  isFetchedTaskType: boolean;
  taskSheetTemplateList: TaskSheetTemplate[];
  tasks: TaskDTO[];
  documentTasks: DocumentTask[] | null;
  listenProcessCreateTask?: TaskDTO;
  taskSelected: TaskDTO | undefined;
  isLoadingTask: boolean;
  isLoadedDocumentTasks: boolean;
}

const initialState: TaskState = {
  taskTypes: [],
  filteredTaskTypes: [],
  isLoadingTaskType: false,
  taskSheetTemplateList: [],
  tasks: [],
  documentTasks: null as any,
  listenProcessCreateTask: undefined,
  taskSelected: undefined,
  isLoadingTask: true,
  isLoadedDocumentTasks: false,
  isFetchedTaskType: false,
};

export const getAllTaskTypes = async () => {
  return (await taskTypeApi.handleGetTaskTypes({})) || [];
};

export const fetchTaskByBimFile = createAsyncThunk(
  "forgeViewer/fetchTaskByBimFile",
  async ({ bimFileId, level }: { bimFileId?: string; level?: string }) => {
    const res = await taskApi.handleGetTasks({ bimFileId, level });
    const tasks: TaskDTO[] = res.map((task) => {
      const dbId = ___mapExternalId
        ? getDbIdByExternalId(task.externalId)
        : task.dbId;

      return {
        ...task,
        dbId,
      };
    });

    return tasks;
  }
);

export const sortTaskTypes = (taskTypes: TaskType[]) =>
  reverse(
    orderBy(taskTypes, ({ createdAt }) => {
      if (typeof createdAt === "string") {
        return new Date(createdAt);
      }

      return createdAt;
    })
  );

export const fetchTaskTypes = createAsyncThunk(
  "task/fetchTaskType",
  getAllTaskTypes
);

export const taskSlice = createSlice({
  name: REDUCER_KEY.TASK,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchTaskByBimFile.pending, (state) => {
      state.isLoadingTask = true;
    });
    builder.addCase(fetchTaskByBimFile.fulfilled, (state, action) => {
      const tasks = action.payload || [];
      state.tasks = tasks;
      const selectedTask = tasks.find(
        (task) => state.taskSelected?.id === task.id
      );
      if (selectedTask) {
        state.taskSelected = selectedTask;
      } else if (state.taskSelected?.id) {
        // close task modal
        state.taskSelected = undefined as any;
      }
      state.isLoadingTask = false;
    });
    builder.addCase(fetchTaskTypes.pending, (state, action) => {
      state.isLoadingTaskType = true;
    });
    builder.addCase(
      fetchTaskTypes.fulfilled,
      (state, action: PayloadAction<TaskType[]>) => {
        state.filteredTaskTypes = sortTaskTypes(action.payload);
        state.taskTypes = action.payload;
        state.isLoadingTaskType = false;
        state.isFetchedTaskType = true;
      }
    );
    builder.addCase(fetchTaskTypes.rejected, (state, action) => {
      state.isLoadingTaskType = false;
    });
  },
  reducers: {
    addTaskType: (state, action: PayloadAction<TaskType>) => {
      const currentTaskTypes = [...state.taskTypes];
      currentTaskTypes.push(action.payload);
      state.taskTypes = currentTaskTypes;
    },
    setTaskType: (state, action: PayloadAction<TaskType>) => {
      const newTaskTypes = [...state.taskTypes];
      let isExist = false;
      for (const index in newTaskTypes) {
        if (newTaskTypes[index].id === action.payload.id) {
          newTaskTypes[index] = action.payload;
          isExist = true;
          break;
        }
      }
      if (!isExist) {
        newTaskTypes.push(action.payload);
      }

      state.taskTypes = newTaskTypes;
    },
    setTaskTypes: (state, action: PayloadAction<TaskType[]>) => {
      state.taskTypes = action.payload;
    },
    setFilteredTaskTypes: (state, action: PayloadAction<TaskType[]>) => {
      state.filteredTaskTypes = sortTaskTypes(action.payload);
    },
    setTaskSheetTemplateList: (
      state,
      action: PayloadAction<TaskSheetTemplate[]>
    ) => {
      state.taskSheetTemplateList = action.payload;
    },
    setIsLoadedDocumentTasks: (state, action: PayloadAction<boolean>) => {
      state.isLoadedDocumentTasks = action.payload;
    },
    setTaskSelected: (state, action: PayloadAction<TaskDTO | undefined>) => {
      state.taskSelected = action.payload || ({} as TaskDTO);
    },
    setTasks: (state, action: PayloadAction<TaskDTO[]>) => {
      state.tasks = action.payload;
      if (!!state.taskSelected?.id) {
        const taskSelected = action.payload.find(
          (task) => task.id === state.taskSelected?.id
        );
        if (taskSelected) {
          state.taskSelected = taskSelected;
        }
      }
    },
    transformDbIdForTasks: (state) => {
      if (!state.tasks.length) {
        return;
      }

      const tasks = state.tasks.map((task) => ({
        ...task,
        dbId: getDbIdByExternalId(task.externalId),
      }));

      const taskSelected = tasks.find(
        (task) => task.id === state.taskSelected?.id
      );
      if (taskSelected) {
        state.taskSelected = taskSelected;
      }

      state.tasks = tasks;
    },
    removeTask: (state, action: PayloadAction<TaskDTO>) => {
      let newTasks = [...state.tasks];
      newTasks = newTasks.filter((task) => task.id !== action.payload.id);
      state.tasks = newTasks;
    },
    updateTaskByDocumentItemId: (
      state,
      action: PayloadAction<{
        documentItemIds: string[];
        data: Partial<TaskDTO>;
      }>
    ) => {
      const documentItemIds = action.payload.documentItemIds;
      const data = action.payload.data;
      state.tasks = state.tasks.map((task) => {
        if (documentItemIds.includes(task?.documentItemId || "")) {
          return { ...task, ...data };
        }

        return task;
      });

      const isExistTaskSelected = !!documentItemIds.find(
        (id) => state.taskSelected?.documentItemId === id
      );
      if (isExistTaskSelected && state.taskSelected?.id) {
        state.taskSelected = { ...state.taskSelected, ...data };
      }
    },
    setTask: (state, action: PayloadAction<TaskDTO>) => {
      const newTasks = [...state.tasks];
      let isExist = false;
      for (const index in newTasks) {
        if (newTasks[index].id === action.payload.id) {
          newTasks[index] = action.payload;
          isExist = true;
          break;
        }
      }
      if (!isExist) {
        newTasks.push(action.payload);
      }

      if (action.payload.id === state.taskSelected?.id) {
        state.taskSelected = action.payload;
      }

      state.tasks = newTasks;
    },
    setListenProcessCreateTask: (
      state,
      action: PayloadAction<TaskDTO | undefined>
    ) => {
      state.listenProcessCreateTask = action.payload;
    },
    addDocumentTasks: (state, action: PayloadAction<DocumentTask[]>) => {
      const newDocumentTasks = action.payload;

      const docTaskIds = newDocumentTasks.map((doc) => doc.id);
      const arrExcludeDocumentTasks = (state.documentTasks || []).filter(
        (doc) => !docTaskIds.includes(doc.id)
      );

      state.documentTasks = [...newDocumentTasks, ...arrExcludeDocumentTasks];
    },
    setDocumentTasks: (state, action: PayloadAction<DocumentTask[] | null>) => {
      state.documentTasks = action.payload;
    },
    setRemoveDocumentTasks: (state, action: PayloadAction<string[]>) => {
      const ids = action.payload;
      state.documentTasks = (state.documentTasks || [])?.filter(
        (doc) => !ids.includes(doc.id)
      );
    },
    setIsLoadingTasks: (state, action: PayloadAction<boolean>) => {
      state.isLoadingTask = action.payload;
    },
    clearState: () => initialState,
  },
});

export const {
  addTaskType,
  setTaskTypes,
  setTaskType,
  setFilteredTaskTypes,
  setTaskSheetTemplateList,
  removeTask,
  setDocumentTasks,
  addDocumentTasks,
  setListenProcessCreateTask,
  setRemoveDocumentTasks,
  setTask,
  setTaskSelected,
  setIsLoadedDocumentTasks,
  setTasks,
  setIsLoadingTasks,
  clearState,
  transformDbIdForTasks,
  updateTaskByDocumentItemId,
} = taskSlice.actions;

export default taskSlice.reducer;
