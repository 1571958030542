import { fabric } from "fabric";
import { logDev } from "utils/logs";
import { SharpBase } from "./sharp-base";

// Draw Text
export class Text extends SharpBase {
  onMouseMove(o: { e: any }): void {
    logDev("Nothing");
  }

  bindEvents = function () {
    const instance = this;
    instance.canvas.on("mouse:down", function (o: any) {
      instance.onMouseDown(o);
    });
    instance.canvas.on("mouse:up", function (o: any) {
      instance.onMouseUp(o);
    });
    instance.canvas.on("object:moving", function (o: any) {
      instance.disable();
    });
  };

  onMouseDown = function (o: any) {
    const instance = this;
    instance.enable();

    const pointer = instance.canvas.getPointer(o.e);

    const elmSelected = instance.canvas._activeObject;

    if (elmSelected?.text) {
      return;
    }

    const textEl = new fabric.IText("Text", {
      stroke: instance.canvas.freeDrawingBrush.color,
      strokeWidth: instance.canvas.lineThickness,
      fill: instance.canvas.freeDrawingBrush.color,
      top: pointer.y,
      left: pointer.x,
      textAlign: "center",
      charSpacing: 0,
      cornerColor: "rgba(255,255,255,1)",
    });
    (textEl as any).hiddenTextareaContainer = (
      instance.canvas as any
    ).lowerCanvasEl.parentNode;
    instance.canvas.add(textEl);
    instance.canvas.setActiveObject(textEl);
    textEl.enterEditing();
    textEl.hiddenTextarea?.focus();
  };
}
