import {
  CellProperty,
  CellSizeSetting,
  ContentType,
  TemplateComponentType,
} from "constants/enum";
import { Blackboard } from "interfaces/models/blackboard";
import {
  CellSize,
  CellType,
  DisplayComponent,
  MinSize,
  PageFormat,
  Scale,
  TemplateComponent,
} from "interfaces/models/component";
import { iBlackboardTemplateProps } from "interfaces/models/documentTemplate";
import { memo, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { Rnd } from "react-rnd";
import { DocumentState } from "redux/documentSlice";
import { RootState } from "redux/store";
import useComponentPreview from "./hooks";
import useRnd from "./hooks/useRnd";
import ImageComponentPreview from "./ImageComponentPreview";
import TableComponentPreview, {
  iTableComponentPreview,
} from "./TableComponentPreview";
import TextboxComponentPreview, {
  iTextboxComponentPreview,
} from "./TextboxComponentPreview";

export interface iComponentPreview {
  componentsLenght?: number;
  index?: number;
  component: TemplateComponent;
  cellSize: { width: number; height: number };
  sizePageRatio: number;
  pageDirectionRatio: number;
  zoomRatioHeight?: number;
  zoomRatio: number;
  isBlackboardTemplate?: boolean;
  isBlackboardTemplateImage?: boolean;
  pages: PageFormat[];

  isTaskSheetTemplate?: boolean;
  isTaskSheetTemplateItem?: boolean;
  isShowDummyOfTaskSheetTemplate?: boolean;
  isOnlyView?: boolean;
  page: number;
  contentType?: ContentType;
  blackboardData?: Blackboard;
  blackboardTemplateProps?: iBlackboardTemplateProps;
  isDisabled?: boolean;

  onChangeBlackboardData?: (data: Blackboard) => void;
}

export interface iUseComponentPreview {
  componentSelected: TemplateComponent;
  sizePageRatio: number;
  pageDirectionRatio: number;
  zoomRatioHeight?: number;
  zoomRatio: number;
  cellSize: CellSize;
  isBlackboardTemplate?: boolean;
  component: TemplateComponent;
  pageSelected: DocumentState["pageSelected"];
  isOnlyView: boolean;
  page: number;
  pages: PageFormat[];
}

export interface iUseRnd {
  isOnlyView: boolean;
  isComponentDragging: boolean;
  scale: Scale;
  components: TemplateComponent[];
  component: TemplateComponent;
  page: number;
  isBlackboardTemplate: boolean;
  displayComponent: DisplayComponent;
  minSize: MinSize;
  selectedCells: CellType[];
  scanComponents: TemplateComponent[];
}

const ComponentPreview = (props: iComponentPreview) => {
  const {
    componentsLenght,
    index,
    component,
    cellSize,
    sizePageRatio,
    pageDirectionRatio,
    zoomRatioHeight,
    zoomRatio,
    isBlackboardTemplate = false,
    isBlackboardTemplateImage = false,
    isTaskSheetTemplateItem = false,
    isTaskSheetTemplate = false,
    isShowDummyOfTaskSheetTemplate = false,
    isOnlyView = false,
    page = 0,
    pages,
    contentType = ContentType.EDITOR,
    blackboardData,
    blackboardTemplateProps,
    onChangeBlackboardData,
    isDisabled,
  } = props;

  const {
    components,
    componentSelected,
    selectedCells,
    isComponentDragging,
    isDisableHeaderDragging,
    pageSelected,
  } = useSelector((state: RootState) => state.document);
  const { scanComponents } = useSelector((state: RootState) => state.editor);

  const { componentRef, scale, displayComponent, minSize } =
    useComponentPreview({
      pages,
      page,
      sizePageRatio,
      pageDirectionRatio,
      zoomRatioHeight,
      zoomRatio,
      isBlackboardTemplate,
      cellSize,
      componentSelected,
      component,
      pageSelected,
      isOnlyView,
    });

  const { rndProps, isResizable, isResizing, isDragging } = useRnd({
    isOnlyView,
    isComponentDragging: !!isComponentDragging,
    scale,
    components,
    component,
    page,
    isBlackboardTemplate,
    displayComponent,
    minSize,
    selectedCells,
    scanComponents,
  });

  useEffect(
    () => {
      if (
        !componentRef?.current ||
        (!isBlackboardTemplateImage && !isTaskSheetTemplateItem) ||
        contentType !== ContentType.EDITOR ||
        !isOnlyView
      ) {
        return;
      }

      componentRef?.current?.updatePosition({
        x: displayComponent?.position.x,
        y: displayComponent?.position.y,
      });

      componentRef?.current?.updateSize({
        width: displayComponent?.size?.width,
        height: displayComponent?.size?.height,
      });
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      component,
      displayComponent,
      isTaskSheetTemplateItem,
      isBlackboardTemplateImage,
      isOnlyView,
      contentType,
    ]
  );

  const renderComponent = useMemo(() => {
    const tableComponentPreviewProps: iTableComponentPreview = {
      component,
      cellSize,
      zoomRatio,
      displaySize: displayComponent?.size,
      isOnlyView,
      contentType,

      sizePageRatio,
      pageDirectionRatio,
      isResizing,
      isResizable,
      isBlackboardTemplate,
      isBlackboardTemplateImage,
      isTaskSheetTemplateItem,
      isTaskSheetTemplate,
      blackboardData,
      blackboardTemplateProps,
      onChangeBlackboardData,
    };

    const textboxComponentPreviewProps: iTextboxComponentPreview = {
      component,
      zoomRatio,
      isResizable,
      isOnlyView,
      contentType,
      isBlackboardTemplate,
      isAutoResize: !(
        isTaskSheetTemplateItem ||
        isBlackboardTemplate ||
        isBlackboardTemplateImage
      ),
    };

    switch (component?.type) {
      case TemplateComponentType.LinkedImage:
      case TemplateComponentType.FilterPhoto:
      case TemplateComponentType.Table:
      case TemplateComponentType.TableHeader:
        return (
          <TableComponentPreview
            {...tableComponentPreviewProps}
            isDisabled={isDisabled}
          />
        );

      case TemplateComponentType.Text:
        return (
          <TextboxComponentPreview
            {...textboxComponentPreviewProps}
            isDisabled={isDisabled}
          />
        );

      case TemplateComponentType.QR_CODE:
      case TemplateComponentType.TasksImage:
      case TemplateComponentType.PhotoBooksInstruction:
      case TemplateComponentType.PhotoBooksReport:
      case TemplateComponentType.Keyplan:
      case TemplateComponentType.ImageUploaded:
      case TemplateComponentType.Image: {
        return (
          <ImageComponentPreview
            isOnlyView={isOnlyView}
            isResizable={isResizable && !isOnlyView}
            component={component}
            contentType={contentType}
            isShowDummyOfTaskSheetTemplate={isShowDummyOfTaskSheetTemplate}
            zoomRatio={zoomRatio}
          />
        );
      }

      default:
        return null;
    }
  }, [
    isDisabled,
    onChangeBlackboardData,
    isShowDummyOfTaskSheetTemplate,
    isTaskSheetTemplateItem,
    isTaskSheetTemplate,
    isBlackboardTemplate,
    blackboardData,
    blackboardTemplateProps,
    contentType,
    isOnlyView,
    component,
    zoomRatio,
    cellSize,
    displayComponent.size,
    sizePageRatio,
    isResizable,
    isResizing,
    isBlackboardTemplateImage,
    pageDirectionRatio,
  ]);

  const zIndex = isDragging
    ? 3
    : component.componentId === componentSelected.componentId
    ? 2
    : isBlackboardTemplateImage && !blackboardTemplateProps?.isOnlyView
    ? (componentsLenght || 1) - (index || 0)
    : 1;

  const isPhotoBooks = useMemo(
    () =>
      [
        TemplateComponentType.TasksImage,
        TemplateComponentType.PhotoBooksInstruction,
        TemplateComponentType.PhotoBooksReport,
      ].includes(component?.type),
    [component?.type]
  );

  const minHeight = useMemo(() => {
    const isLinkedImage = component.type === TemplateComponentType.LinkedImage;
    let height = minSize.minHeight;
    if (isLinkedImage) {
      height = 0;
      component.detail?.rows?.forEach((row) => {
        const cell = row?.cells?.[0];
        if (!cell) {
          return;
        }

        if (cell.cellProperty === CellProperty.IMAGE) {
          height += Number(CellSizeSetting.MIN_HEIGHT) * 3;
        } else {
          height += cell.height || 0;
        }
      });
    }

    return height * scale.displayY;
  }, [minSize.minHeight, scale.displayY, component]);

  return (
    <Rnd
      id={`resize-${component.componentId}`}
      ref={(c) => {
        componentRef.current = c;
      }}
      minWidth={`${minSize.minWidth * scale.displayX}px`}
      minHeight={`${minHeight}px`}
      maxWidth={displayComponent.size.maxWidth}
      maxHeight={displayComponent.size.maxHeight}
      bounds="parent"
      cancel="textarea"
      disableDragging={
        isDisableHeaderDragging ||
        isOnlyView ||
        component.detail?.isFullsize ||
        isPhotoBooks
      }
      enableResizing={
        !isOnlyView && !component.detail?.isFullsize && !isPhotoBooks
      }
      {...((isBlackboardTemplateImage || isTaskSheetTemplateItem) &&
      isOnlyView &&
      contentType === ContentType.EDITOR
        ? {}
        : {
            size: displayComponent?.size,
            position: displayComponent?.position,
          })}
      style={{
        zIndex,
      }}
      data-zindex={zIndex}
      {...rndProps}
    >
      {renderComponent}
    </Rnd>
  );
};

export default memo(ComponentPreview);
