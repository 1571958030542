import {
  Box,
  Button,
  Drawer,
  DrawerContent,
  DrawerContentProps,
  DrawerOverlay,
  Flex,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text,
} from "@chakra-ui/react";
import { SvgIcon } from "components/SvgIcon";
import { useEffect, useState } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { routePath } from "routes/path";
import { formatDate } from "utils/date";

interface Props extends DrawerContentProps {
  isOpen: boolean;
  versions: any[] | null;
  onUpdate?: (version: number | string) => Promise<void>;
  onClose: () => void;
}

function VersionSelection({
  isOpen,
  versions,
  onUpdate,
  onClose,
  ...rest
}: Props) {
  const { bimFileId, projectBimFileId } = useParams();
  const navigate = useNavigate();
  const [currentVersionNumber, setCurrentVersionNumber] = useState<string>("");

  useEffect(() => {
    setCurrentVersionNumber(
      bimFileId?.split("?")[1]?.replace("version=", "") || ""
    );
  }, [bimFileId]);

  const onChangeVersion = async () => {
    if (onUpdate) {
      await onUpdate(currentVersionNumber);
    }
    navigate(
      generatePath(routePath.ForgeViewer, {
        projectBimFileId,
        bimFileId,
        version: currentVersionNumber,
      })
    );
    onClose();
  };

  const getCurrentVersionText = () => {
    if (!versions) {
      return;
    }

    const currentVersion = versions.find(
      (version) => String(version.versionNumber) === currentVersionNumber
    );

    if (currentVersion) {
      return `V${currentVersion.versionNumber}.${formatDate(
        currentVersion.createTime,
        "YYYYMMDDmm"
      )}`;
    }

    return "バージョンを選択する";
  };

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent
        w="100%"
        maxW="33.6rem"
        mt="var(--header-height)"
        {...rest}
      >
        <Box
          w="100%"
          h="var(--content-height)"
          zIndex={10}
          position="absolute"
          right="0"
          top="0"
          bgColor="#FFFFFF"
          p="0 0"
        >
          <Flex alignItems="center" p="1.7rem">
            <SvgIcon src={"/img/icon-content-note.svg"} />
            <Text fontWeight="bold" ml="0.6rem">
              バージョン選択
            </Text>
            <SvgIcon
              ml="auto"
              onClick={onClose}
              className="button"
              src="/img/icon-navigation-close.svg"
            />
          </Flex>
          <Flex flexDir="column" p="1.5rem">
            <Menu>
              <MenuButton
                as={Button}
                variant="white"
                border="1px solid #0000001F"
                boxShadow="none"
                color="#000000"
                fontSize="1.4rem"
                fontWeight="medium"
                minW="27.2rem"
                pr="0.5rem"
                mx="auto"
                sx={{
                  "span:nth-of-type(1)": {
                    flex: "unset",
                    mr: "auto",
                  },
                }}
                rightIcon={
                  <SvgIcon src="/img/icon-navigation-arrow_drop_down.svg" />
                }
              >
                {getCurrentVersionText()}
              </MenuButton>
              <MenuList
                minW="27.2rem"
                border="1px solid #F3F3F3"
                borderRadius="3px"
                py={0}
                overflow="clip"
              >
                <MenuOptionGroup
                  type="radio"
                  value={currentVersionNumber || ""}
                  onChange={(value) => {
                    setCurrentVersionNumber(String(value));
                  }}
                >
                  {(versions || [])?.map((d, index) => (
                    <MenuItemOption
                      key={d.versionNumber}
                      fontSize="1.3rem"
                      fontWeight="bold"
                      value={String(d.versionNumber)}
                      h="4rem"
                      pl="1.7rem"
                      borderBottom="1px solid #E9EAEA"
                    >
                      {`V${d.versionNumber}.${formatDate(
                        d.createTime,
                        "YYYYMMDDmm"
                      )}`}
                      {index}
                    </MenuItemOption>
                  ))}
                </MenuOptionGroup>
              </MenuList>
            </Menu>
            <Button
              ml="auto"
              mt="2.8rem"
              variant="blueLite"
              onClick={onChangeVersion}
            >
              バージョンを変更する
            </Button>
          </Flex>
        </Box>
      </DrawerContent>
    </Drawer>
  );
}

export default VersionSelection;
