import _ from "lodash";
import { useCallback, useState } from "react";

const useForm = <T = any>(defaultValue?: any) => {
    const [formValue, setFormValue] = useState<T | undefined>(defaultValue);

    const getValue = useCallback((key: string, defaultValue?: any) => {
        const next = _.get(formValue, key, defaultValue);
        
        return next ?? defaultValue;
    }, [formValue])

    const setValue = useCallback((key: string, value: any) => {
        setFormValue((prev: any) => {
            const next: any = _.isObject(prev) ? _.clone(prev) : {};
            next[key] = value;
            
            return next;
        })
    }, []);

    return {setValue, getValue, formValue, setFormValue};
}

export default useForm
