import SubTableComponentPreview from "components/editor-builder/component-preview/TableComponentPreview/SubTableComponentPreview";
import { iUseGetContentOfCell } from "components/editor-builder/component-preview/TableComponentPreview/TableTdComponentPreview";
import TableSheetCell from "components/widgets/TaskSheet/TableSheetCell";
import {
  CellProperty,
  LinkedDataField,
  TableDefaultStyle,
} from "constants/enum";
import { FieldData } from "interfaces/models";
import { CellType } from "interfaces/models/component";
import { useCallback, useMemo } from "react";
import { formatDate } from "utils/date";
import NormalTextPreview from "../../NormalTextPreview";

const useGetContentTypeDocumentTaskData = (props: iUseGetContentOfCell) => {
  const {
    component,
    currentTemplate,
    zoomRatio,
    isBlackboardTemplateImage,
    displaySize,
    width,
    height,
    limit,
    isLastColumn,
    isDuplicateRow,
    sizePageRatio,
    pageDirectionRatio,
    cellSize,
    isOnlyView,
    components,
    selectedCells,
    documentContainerSize,
    componentSelected,
    isComponentDragging,
    contentType,
    documentTaskDataProps,
  } = props;

  const handleDocumentTaskDetailProps = useMemo(
    () => documentTaskDataProps?.handleDocumentTaskDetailProps,
    [documentTaskDataProps?.handleDocumentTaskDetailProps]
  );

  const getContentTypeDocumentTaskData = useCallback(
    (cell: CellType) => {
      if (
        !documentTaskDataProps?.documentTaskData ||
        !handleDocumentTaskDetailProps
      ) {
        return null;
      }

      const documentTaskData = documentTaskDataProps?.documentTaskData;
      const currentUser = documentTaskDataProps?.currentUser;
      const projectDetail = documentTaskDataProps?.projectDetail;

      const color = cell?.style?.color;
      let value;
      let style;
      const {
        editMode,
        title,
        onEditDocumenTaskTitle,
        onEditDocumentTaskInfoSubmit,
        onEditProjectName,
        onEditTaskDisplayName,
      } = handleDocumentTaskDetailProps;

      switch (cell.cellLinkedData?.field) {
        case LinkedDataField.DOCUMENT_TASK_DATA.TITLE_REPORT:
        case LinkedDataField.DOCUMENT_TASK_DATA.TITLE_INSTRUCTION:
          value =
            typeof documentTaskData?.title === "string" // for old document task
              ? documentTaskData?.title
              : documentTaskData?.title?.editValue;
          style = documentTaskData?.title?.style || (null as any);

          return (
            <TableSheetCell
              defaultValue={title || ""}
              value={value}
              editMode={editMode}
              type="editData"
              editDataStyle={style}
              color={color}
              onEditSubmit={onEditDocumenTaskTitle as any}
            />
          );

        case LinkedDataField.DOCUMENT_TASK_DATA
          .DOCUMENT_TASK_INFO_CREATION_DATE_TIME:
          value = documentTaskData?.documentTaskInfo?.creationDateTime?.value;
          style =
            documentTaskData?.documentTaskInfo?.creationDateTime?.style ||
            (null as any);

          return (
            <TableSheetCell
              defaultValue={formatDate(new Date(), "YYYY/MM/DD")}
              value={value}
              editDataStyle={style}
              color={color}
              editMode={editMode}
              type="fieldData"
              onEditSubmit={(fieldData: FieldData) =>
                onEditDocumentTaskInfoSubmit({
                  fieldData,
                  fieldName: "creationDateTime",
                })
              }
            />
          );

        case LinkedDataField.DOCUMENT_TASK_DATA.DOCUMENT_TASK_INFO_EXAMINEE:
          value = documentTaskData?.documentTaskInfo?.examinee?.value;
          style =
            documentTaskData?.documentTaskInfo?.examinee?.style ||
            (null as any);

          return (
            <TableSheetCell
              defaultValue="高砂熱学工業"
              value={value}
              editMode={editMode}
              editDataStyle={style}
              color={color}
              type="fieldData"
              onEditSubmit={(fieldData: FieldData) =>
                onEditDocumentTaskInfoSubmit({
                  fieldData,
                  fieldName: "examinee",
                })
              }
            />
          );

        case LinkedDataField.DOCUMENT_TASK_DATA.DOCUMENT_TASK_INFO_INSPECTOR:
          value = documentTaskData?.documentTaskInfo?.inspector?.value;
          style =
            documentTaskData?.documentTaskInfo?.inspector?.style ||
            (null as any);

          return (
            <TableSheetCell
              defaultValue={currentUser?.name}
              value={value}
              editDataStyle={style}
              color={color}
              editMode={editMode}
              type="fieldData"
              onEditSubmit={(fieldData: FieldData) =>
                onEditDocumentTaskInfoSubmit({
                  fieldData,
                  fieldName: "inspector",
                })
              }
            />
          );

        case LinkedDataField.DOCUMENT_TASK_DATA.PROJECT_NAME:
          value = documentTaskData?.projectName?.editValue;
          style = documentTaskData?.projectName?.style || (null as any);

          return (
            <TableSheetCell
              defaultValue={`プロジェクト ${projectDetail?.name || ""} 詳細`}
              value={value}
              editDataStyle={style}
              color={color}
              editMode={editMode}
              type="editData"
              onEditSubmit={onEditProjectName as any}
            />
          );

        case LinkedDataField.DOCUMENT_TASK_DATA.CORPORATE_COMPANY:
          value =
            documentTaskData?.taskDisplayName?.corporateCompany?.editValue;
          style =
            documentTaskData?.taskDisplayName?.corporateCompany?.style ||
            (null as any);

          return (
            <TableSheetCell
              defaultValue="協力会社"
              value={value}
              editDataStyle={style}
              color={color}
              editMode={editMode}
              type="editData"
              onEditSubmit={(editData: any) =>
                onEditTaskDisplayName({
                  editData,
                  fieldName: "corporateCompany",
                })
              }
            />
          );

        default:
          return "";
      }
    },
    [
      documentTaskDataProps?.projectDetail,
      documentTaskDataProps?.currentUser,
      documentTaskDataProps?.documentTaskData,
      handleDocumentTaskDetailProps,
    ]
  );

  const contentOfCellTypeDocumentTaskData = useCallback(
    (cell: CellType) => {
      const data = structuredClone(cell);

      if (data?.subTable?.rows?.length) {
        return (
          <SubTableComponentPreview
            component={component}
            parentCell={cell}
            displaySize={{
              width:
                (width / component.size.width) * displaySize.width -
                  TableDefaultStyle.DEFAULT_BORDER_SIZE || 0,
              height:
                (height / component.size.height) * displaySize.height -
                  TableDefaultStyle.DEFAULT_BORDER_SIZE || 0,
            }}
            zoomRatio={zoomRatio}
            cellSize={cellSize}
            sizePageRatio={sizePageRatio}
            pageDirectionRatio={pageDirectionRatio}
            isOnlyView={isOnlyView}
            isDuplicateRow={isDuplicateRow}
            isParentLastColumn={isLastColumn}
            limit={limit}
            isBlackboardTemplateImage={isBlackboardTemplateImage}
            components={components}
            selectedCells={selectedCells}
            documentContainerSize={documentContainerSize}
            currentTemplate={currentTemplate}
            componentSelected={componentSelected}
            isComponentDragging={isComponentDragging}
            contentType={contentType}
          />
        );
      }

      switch (cell.cellProperty) {
        case CellProperty.DOCUMENT_TASK_DATA:
          return getContentTypeDocumentTaskData(cell);

        default:
          return <NormalTextPreview data={data} />;
      }
    },
    [
      component,
      getContentTypeDocumentTaskData,
      currentTemplate,
      zoomRatio,
      isBlackboardTemplateImage,
      displaySize,
      width,
      height,
      limit,
      isLastColumn,
      isDuplicateRow,
      sizePageRatio,
      pageDirectionRatio,
      cellSize,
      isOnlyView,
      components,
      selectedCells,
      documentContainerSize,
      componentSelected,
      isComponentDragging,
      contentType,
    ]
  );

  return { contentOfCellTypeDocumentTaskData };
};

export default useGetContentTypeDocumentTaskData;
