import { TableStyleType } from "interfaces/models/component";
import { BlackboardKey } from "./enum";

export const DEFAULT_BLACKBOARD_CONTENT_SIZE = {
  width: 860,
  height: 640,
};

export const DEFAULT_BLACKBOARD_BG_COLOR = "#0D370E";
export const DEFAULT_BLACKBOARD_BORDER_COLOR = "#fff";
export const DEFAULT_BLACKBOARD_FONT_COLOR = "#fff";
export const DEFAULT_BLACKBOARD_FONT_SIZE = 14;
export const ASPECT_RATIO_FOR_BLACKBOARD_TEMPLATE_BG = "1.625";
export const DEFAULT_BLACKBOARD_TEMPLATE_FONT_SIZE_SCALE = 1.3;

export const BlackboardTemplateCellStyle = {
  borderColor: DEFAULT_BLACKBOARD_BORDER_COLOR,
  fontSize: DEFAULT_BLACKBOARD_FONT_SIZE,
  color: DEFAULT_BLACKBOARD_FONT_COLOR,
  backgroundColor: DEFAULT_BLACKBOARD_BG_COLOR,
};

export enum BlackboardTemplateBackgroundType {
  "GREEN" = "GREEN",
  "WHITE" = "WHITE",
}

export const BlackboardTemplateBackground: {
  [key: string]: Partial<TableStyleType>;
} = {
  [BlackboardTemplateBackgroundType.GREEN]: {
    backgroundColor: "#0D370E",
    color: "#fff",
    borderColor: "#fff",
  },
  [BlackboardTemplateBackgroundType.WHITE]: {
    backgroundColor: "#fff",
    color: "#333",
    borderColor: "#808080b3",
  },
};

export const listOtherPartnerCompanies = [
  { label: "高砂熱学工業株式会社", value: "takasago" },
];

export const listConstruction = [
  {
    value: "1",
    label: "建築プロジェクト",
  },
  {
    value: "2",
    label: "建築プロジェクト2",
  },
];

export const listExecuteUser = [
  {
    value: "1",
    label: "田中",
  },
  {
    value: "2",
    label: "鈴木",
  },
];

export const listSupervisor = [
  {
    value: "1",
    label: "田中",
  },
  {
    value: "2",
    label: "鈴木",
  },
];

export const listBuilderFirst = [
  {
    value: "1",
    label: "株式会社A",
  },
  {
    value: "2",
    label: "株式会社B",
  },
];

export const listBuilderSecond = [
  {
    value: "1",
    label: "株式会社A",
  },
  {
    value: "2",
    label: "株式会社B",
  },
];

export const listWitnessFirst = [
  {
    value: "1",
    name: "AAA",
  },
];

export const listWitnessSecond = [
  {
    value: "1",
    name: "BBB",
  },
];

export const listConstructionAddress = [
  {
    value: "1",
    label: "中央区",
  },
  {
    value: "2",
    label: "千代田区",
  },
];

export const MapBlackboardKeyWithDataType: {
  [key: string]: {
    type: "select" | "input" | "date";
    selectOption?: {
      options:
        | { label: string; value: string }[]
        | "floor"
        | "list-user"
        | "partner-companies";
      fieldId: BlackboardKey;
      fieldText: BlackboardKey;
    };
  };
} = {
  // select type
  [BlackboardKey.SHOOTING_AREA_TEXT]: {
    type: "select",
    selectOption: {
      options: listConstructionAddress,
      fieldId: BlackboardKey.SHOOTING_AREA_ID,
      fieldText: BlackboardKey.SHOOTING_AREA_TEXT,
    },
  },
  [BlackboardKey.SHOOTING_FLOOR_TEXT]: {
    type: "select",
    selectOption: {
      options: "floor",
      fieldId: BlackboardKey.SHOOTING_FLOOR_ID,
      fieldText: BlackboardKey.SHOOTING_FLOOR_TEXT,
    },
  },
  [BlackboardKey.BUILDER_SECOND_TEXT]: {
    type: "select",
    selectOption: {
      options: listBuilderFirst,
      fieldId: BlackboardKey.BUILDER_SECOND_ID,
      fieldText: BlackboardKey.BUILDER_SECOND_TEXT,
    },
  },
  [BlackboardKey.BUILDER_FIRST_TEXT]: {
    type: "select",
    selectOption: {
      options: "partner-companies",
      fieldId: BlackboardKey.BUILDER_FIRST_ID,
      fieldText: BlackboardKey.BUILDER_FIRST_TEXT,
    },
  },
  [BlackboardKey.SUPERVISOR_TEXT]: {
    type: "select",
    selectOption: {
      options: "list-user",
      fieldId: BlackboardKey.SUPERVISOR_ID,
      fieldText: BlackboardKey.SUPERVISOR_TEXT,
    },
  },
  [BlackboardKey.USER_EXECUTE_TEXT]: {
    type: "select",
    selectOption: {
      options: "list-user",
      fieldId: BlackboardKey.USER_EXECUTE_ID,
      fieldText: BlackboardKey.USER_EXECUTE_TEXT,
    },
  },
  [BlackboardKey.CONSTRUCTION_ADDRESS_TEXT]: {
    type: "select",
    selectOption: {
      options: "floor",
      fieldId: BlackboardKey.CONSTRUCTION_ADDRESS_ID,
      fieldText: BlackboardKey.CONSTRUCTION_ADDRESS_TEXT,
    },
  },

  // date type
  [BlackboardKey.SHOOTING_TIME]: {
    type: "date",
  },

  // other is input
};
