import { Flex, Spinner } from "@chakra-ui/react";
import { ComponentType, useCallback, useEffect, useState } from "react";
import { getPreSignUrl, imageUrlToBase64 } from "utils/file";

export function withPresignedUrl<T = any>(Component: ComponentType<T>) {
  return (
    props: T & {
      src?: string;
      isOnline?: boolean;
      isConvertToBase64?: boolean;
      isCheckLoadingPresignedUrl?: boolean;
      boxLoadingWidth?: any;
      boxLoadingHeight?: any;
      onLoadedPresignedUrl?: (url: string) => void;
    }
  ) => {
    const {
      src,
      isConvertToBase64 = false,
      isCheckLoadingPresignedUrl = true,
      boxLoadingWidth = 0,
      boxLoadingHeight = 0,
      isOnline = true,
      onLoadedPresignedUrl,
      ...rest
    } = props;
    const [isLoadingPresignedUrl, setIsLoadingPresignedUrl] = useState(true);
    const [presignedUrl, setPresignedUrl] = useState<string>("");

    const getPresignedUrl = useCallback(async () => {
      try {
        let img = src || "";

        const isBase64 = img.includes("data:image/");
        if (!img?.includes(`${process.env.REACT_APP_S3_URL}/`) || isBase64) {
          setIsLoadingPresignedUrl(false);
          setPresignedUrl(img);
          onLoadedPresignedUrl?.(img);

          return;
        }

        setIsLoadingPresignedUrl(true);
        if (
          src &&
          src.includes(`${process.env.REACT_APP_S3_URL}/`) &&
          !src.includes("X-Amz-SignedHeaders=host")
        ) {
          img = await getPreSignUrl(src, "");
        }

        if (isConvertToBase64 && !img?.includes(".mp4")) {
          img = (await imageUrlToBase64(img)) as string;
        }

        setPresignedUrl(img);
        onLoadedPresignedUrl?.(img);
        setIsLoadingPresignedUrl(false);
      } catch (err) {
        setIsLoadingPresignedUrl(false);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [src, isOnline]);

    useEffect(() => {
      getPresignedUrl();
    }, [getPresignedUrl]);

    if (isLoadingPresignedUrl && isCheckLoadingPresignedUrl) {
      return (
        <Flex
          alignItems="center"
          justifyContent="center"
          width={boxLoadingWidth}
          height={boxLoadingHeight}
        >
          {!!boxLoadingWidth && !!boxLoadingHeight && (
            <Spinner color="blue.500" />
          )}
        </Flex>
      );
    }

    if (!presignedUrl) {
      return <></>;
    }

    return (
      <Component
        {...(rest as any)}
        src={presignedUrl}
        crossOrigin="anonymous"
      />
    );
  };
}
