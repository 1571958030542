import { ButtonProps } from "@chakra-ui/react";
import { Axis } from "./enum";

export const buttonHeaderItemStyle: ButtonProps = {
  height: "4rem",
  paddingLeft: { base: "1.6rem", lg: "1rem", "2xl": "1.6rem" },
  paddingRight: { base: "1.6rem", lg: "1rem", "2xl": "1.6rem" },
  fontSize: { base: "1.4rem", sm: "1rem", xl: "1.2rem", xxl: "1.4rem" },
  fontWeight: 500,
  letterSpacing: "1.246px",
  textTransform: "capitalize",
  border: "1px solid #A3A3A3 !important",
  borderRadius: "6px",
};

export const AXIS_OPTIONS = [
  { name: "縦向き", value: Axis.VERTICAL },
  { name: "横向き", value: Axis.HORIZONTAL },
];

export const Z_INDEX_HEADER = 123;
