import {
  defaultSuggestionsFilter,
  MentionData,
} from "@draft-js-plugins/mention";
import "@draft-js-plugins/mention/lib/plugin.css";
import { taskApi, taskCommentApi } from "apiClient/v2";
import { message } from "components/base";
import { InspectionItemType, MapInspectionItemColor } from "constants/enum";
import { defaultAvatarPath } from "constants/file";
import { MessageType } from "constants/websocket";
import { EditorState, getDefaultKeyBinding, KeyBindingUtil } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import useUserOfProject from "hooks/useUserOfProject";
import { TaskDTO } from "interfaces/dtos/taskDTO";
import { FileModel } from "interfaces/models";
import { Task, TaskComment } from "interfaces/models/task";
import { WSMessage } from "interfaces/models/websocket";
import get from "lodash/get";
import throttle from "lodash/throttle";
import { COMMENT_TYPE } from "models/commentLog";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import store, { RootState } from "redux/store";
import { setTask } from "redux/taskSlice";
import { sortArrayByField } from "utils/array";
import { getNetworkStatus, sleep } from "utils/common";
import { now } from "utils/date";
import { isAudio, isImage, uploadFileToS3 } from "utils/file";
import { getDbIdByExternalId, selectDbIds } from "utils/forge";
import { updateLabel } from "utils/forge/extensions/custom-label";
import { logDev } from "utils/logs";

const MIN_HEIGHT_SCROLL_LOAD_MORE = 300;
const OFFSET_SCROLL_LOAD_MORE = 5;

const useChat = ({
  mapTaskType,
  webSocketMessage,
  setTaskModalInfo,
  sendWebSocketMessage,
}: {
  setTaskModalInfo: React.Dispatch<React.SetStateAction<TaskDTO | undefined>>;
  mapTaskType: { [key: string]: string };
  sendWebSocketMessage: (message: WSMessage) => void;
  webSocketMessage: WSMessage | null;
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [comments, setComments] = useState<TaskComment[]>([]);
  const { isSyncOfflineData } = useSelector((state: RootState) => state.app);
  const { taskSelected } = useSelector((state: RootState) => state.task);
  const [disabled, setDisabled] = useState(false);
  const containerChatRef = useRef<HTMLInputElement | null>(null);
  const { currentUser } = useSelector((state: RootState) => state.user);
  const [loadingChat, setLoadingChat] = useState<boolean>(false);
  const loadingChatRef = useRef(true);
  const timeOutIdRef = useRef<any>(null);
  const timeOutWaitLoadDomRef = useRef<any>(null);
  const [loadingImage, setLoadingImage] = useState<boolean>(false);
  const hasComment = useRef(false);
  const [suggestions, setSuggestions] = useState<MentionData[]>([]);
  const [nextToken, setNextToken] = useState("");
  const scrollTopRef = useRef<null | number>(null);
  const scrollHeightRef = useRef<null | number>(null);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const dispatch = useDispatch();
  const { hasCommandModifier } = KeyBindingUtil;

  const { listUserById } = useUserOfProject();

  const chatSavedRef = useRef<TaskDTO | undefined | null>(null);
  const initChat = useCallback(() => {
    hasComment.current = false;
    scrollTopRef.current = null;
    scrollHeightRef.current = null;
    loadingChatRef.current = true;
    timeOutIdRef.current = null;
    clearTimeout(timeOutWaitLoadDomRef.current);
    setComments([]);
    setNextToken("");
    (async () => {
      setEditorState(EditorState.createEmpty());
      setLoadingChat(true);
      let token: any = "";
      const contentModalEle = document.getElementById(
        "pin-detail-content-modal"
      );
      let newComments = [];
      if (contentModalEle) {
        timeOutWaitLoadDomRef.current = await sleep(100);
        let isHasScroll = false;

        do {
          isHasScroll =
            contentModalEle.scrollHeight > contentModalEle.clientHeight;

          const result = await taskCommentApi.handleGetTaskComments({
            taskId: taskSelected?.id || "",
            cursor: token || "",
          });

          token = result?.pagination?.cursor ?? null;
          newComments.push(...(result?.data || []));
          newComments = sortArrayByField(newComments, "createdAt");
          setComments(newComments);
        } while (!isHasScroll && !!token);
        setNextToken(token);
      }

      setLoadingChat(false);
      loadingChatRef.current = false;
    })();
  }, [taskSelected?.id]);

  useEffect(() => {
    if (isSyncOfflineData) {
      return;
    }
    initChat();

    return () => {
      if (timeOutWaitLoadDomRef.current) {
        clearTimeout(timeOutWaitLoadDomRef.current);
      }

      if (timeOutIdRef.current) {
        clearTimeout(timeOutIdRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSyncOfflineData]);

  useEffect(() => {
    initChat();

    return () => {
      if (timeOutWaitLoadDomRef.current) {
        clearTimeout(timeOutWaitLoadDomRef.current);
      }

      if (timeOutIdRef.current) {
        clearTimeout(timeOutIdRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskSelected?.id]);

  useEffect(() => {
    if (!taskSelected?.id || !webSocketMessage) return;
    const { type, data, taskId } = webSocketMessage;
    const isUpdate =
      type === MessageType.ADD_TASK_COMMENT ||
      type === MessageType.UPDATE_TASK_COMMENT;
    if (!isUpdate || taskId !== taskSelected.id) {
      return;
    }

    const newTaskComment = data;
    if (type === MessageType.ADD_TASK_COMMENT) {
      return setComments([...comments, newTaskComment]);
    }

    const newComments = [...comments];
    newComments.forEach((comment, index) => {
      if (comment.id === newTaskComment.id) {
        newComments[index] = newTaskComment;
      }
    });
    setComments(newComments);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskSelected?.id, webSocketMessage]);

  const mentions: MentionData[] = useMemo(() => {
    const data = Object.keys(listUserById)
      .filter((key) => {
        const user = listUserById[key];

        return !!user?.name;
      })
      .map((key) => {
        const user = listUserById[key];

        return {
          name: user?.name || "",
          avatar: user?.avatar || defaultAvatarPath,
          id: user?.id || "",
        };
      });

    return data;
  }, [listUserById]);

  useEffect(() => {
    setSuggestions(mentions);
  }, [mentions]);

  const loadComment = useCallback(
    async (token = "", isInit = false) => {
      if (!taskSelected?.id || token === null) {
        return;
      }
      logDev("__load more");
      try {
        setLoadingChat(true);
        const result = await taskCommentApi.handleGetTaskComments({
          taskId: taskSelected?.id || "",
          cursor: token || "",
        });

        if (!result) {
          setLoadingChat(false);

          return;
        }

        setNextToken((result.pagination?.cursor ?? null) as any);
        const newComments = sortArrayByField(result.data || [], "createdAt");
        hasComment.current = !!newComments.length;
        if (token === "") {
          setComments(sortArrayByField(newComments, "createdAt"));
        } else {
          setComments((comments) =>
            sortArrayByField(newComments.concat(comments), "createdAt")
          );
        }

        if (isInit) {
          logDev("__init load comment");
          timeOutIdRef.current = setTimeout(() => {
            // Scroll to last item.
            moveToLast();
            // When the image finishes loading, the height of the comment list will be changed.
            // In some case: 1000ms can't load finished comment contain image => so it won't be able to scroll to the end.
          }, 1000);
        }

        setLoadingChat(false);
      } finally {
      }
    },
    [taskSelected?.id]
  );

  const debounceLoadChat = throttle(async () => {
    if ((nextToken && !comments.length) || nextToken === null) {
      scrollTopRef.current = null;
      scrollHeightRef.current = null;

      return;
    }
    setLoadingChat(true);
    scrollTopRef.current = containerChatRef?.current?.scrollTop ?? 0;
    scrollHeightRef.current = containerChatRef?.current?.scrollHeight ?? 0;
    await loadComment(nextToken);
    setLoadingChat(false);
  }, 500);

  const onScroll = async (e: any) => {
    const container: HTMLElement = e.target as any;
    const isLoadMoreComment =
      container.scrollTop + container.clientHeight + OFFSET_SCROLL_LOAD_MORE >
        container.scrollHeight &&
      !loadingChatRef.current &&
      !loadingChat &&
      nextToken !== null;

    if (isLoadMoreComment) {
      debounceLoadChat();
    }
  };

  const onSearchChange = useCallback(
    ({ value }: { value: string }) => {
      setSuggestions(defaultSuggestionsFilter(value, mentions));
    },
    [mentions]
  );

  const uploadListFilToS3 = async (files: FileModel[]) => {
    const listFileS3 = await Promise.all(
      files.map(async (file) => {
        const name = file.name;
        const src = file.src || "";
        if (file.file) {
          return await uploadFileToS3(file.file, name || "");
        } else {
          JSON.stringify({
            name,
            src,
          });
        }
      })
    );

    return listFileS3;
  };

  const addChatMessage = useCallback(
    async (
      content: string,
      typeKey: keyof typeof COMMENT_TYPE,
      files?: any,
      taskId?: string,
      statusChange?: string
    ) => {
      const isChatComment =
        COMMENT_TYPE[typeKey].typeKey === COMMENT_TYPE.ADD_COMMENT.typeKey ||
        COMMENT_TYPE[typeKey].typeKey ===
          COMMENT_TYPE.ADD_COMMENT_IMAGE.typeKey ||
        COMMENT_TYPE[typeKey].typeKey ===
          COMMENT_TYPE.ADD_COMMENT_AUDIO.typeKey ||
        COMMENT_TYPE[typeKey].typeKey === COMMENT_TYPE.ADD_COMMENT_FILE.typeKey;

      if (isChatComment) {
        setDisabled(true);
      }

      const newComment = {
        taskId: taskId || taskSelected?.id || "",
        content,
        type: COMMENT_TYPE[typeKey].typeKey,
        images: files,
        statusChange,
        createdBy: currentUser?.id,
        createdAt: new Date(),
      } as TaskComment;

      const { data: res } = await taskCommentApi.createComment(newComment);
      if (res?.id) {
        setComments((comments) => [...comments, res]);

        sendWebSocketMessage({
          taskId: taskId || taskSelected?.id,
          type: MessageType.ADD_TASK_COMMENT,
          data: res,
        });

        if (isChatComment) {
          setTimeout(() => {
            moveToLast();
          }, 1);
          setEditorState(EditorState.createEmpty());
        }
      }
      if (isChatComment) {
        setDisabled(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentUser?.id, taskSelected]
  );

  const updateStatusAndNumberImageTask = useCallback(
    async (
      numberFileImage: number,
      content?: string,
      statusChange?: string
    ) => {
      if (!taskSelected?.id) {
        return;
      }

      const { dbId: _, ...newTaskReq } = taskSelected;
      const isOnline = getNetworkStatus();
      const bodyUpdate: Partial<TaskDTO> = {
        numberImage: (newTaskReq?.numberImage || 0) + numberFileImage,
        status: statusChange || newTaskReq?.status,
        confirmedDateTime:
          statusChange === InspectionItemType.Confirmed &&
          !newTaskReq?.confirmedDateTime
            ? now()
            : newTaskReq?.confirmedDateTime,
        userConfirmed:
          statusChange === InspectionItemType.Confirmed &&
          !newTaskReq?.userConfirmed
            ? currentUser?.id
            : newTaskReq?.userConfirmed,
        userTreated:
          statusChange === InspectionItemType.Treated
            ? currentUser?.id
            : newTaskReq.userTreated,
        treatedComment:
          content && statusChange === InspectionItemType.Treated
            ? content
            : newTaskReq.treatedComment,
        endDateScheduled:
          statusChange === InspectionItemType.Treated &&
          !newTaskReq?.endDateScheduled
            ? now()
            : newTaskReq?.endDateScheduled,
      };
      const taskReq = {
        ...newTaskReq,
        ...bodyUpdate,
      } as Task;

      if (statusChange) {
        updateLabel(taskReq.id, {
          id: taskReq.id,
          position: taskReq.position,
          title: mapTaskType[taskReq.taskTypeId || ""] || "-",
          indexId: taskReq.indexId,
          showImage: Number(taskReq.images?.length) > 0,
          status: taskReq.status,
          externalId: taskReq.externalId,
        });
        dispatch(setTask(taskReq));

        setTimeout(async () => {
          if (taskReq.id === store.getState().task.taskSelected?.id) {
            taskSelected?.dbId &&
              selectDbIds(taskSelected?.dbId, {
                color:
                  MapInspectionItemColor[
                    (taskReq.status ||
                      InspectionItemType.Defect) as InspectionItemType
                  ],
              });
            setTaskModalInfo(taskReq);
          }
          await addChatMessage(statusChange, "CHANGE_STATUS");
        });
      }
      let { data: newTask } = await taskApi.updateTask({
        id: taskReq.id,
        ...bodyUpdate,
        updatedAt: new Date(),
      } as Task);

      if (!isOnline) {
        newTask = { ...taskReq, ...newTask };
      }

      if (newTask) {
        sendWebSocketMessage({
          type: MessageType.UPDATE_TASK,
          taskId: newTask.id,
          data: { id: newTask.id, level: newTask.level },
        });

        newTask.dbId = getDbIdByExternalId(newTask.externalId);
        dispatch(setTask(newTask));
        setTimeout(() => {
          if (newTask.id === store.getState().task.taskSelected?.id) {
            setTaskModalInfo(newTask);
          }
        });
      }
    },
    [
      currentUser?.id,
      mapTaskType,
      taskSelected,
      sendWebSocketMessage,
      addChatMessage,
      dispatch,
      setTaskModalInfo,
    ]
  );

  const toHTML = useCallback(() => {
    const contentState = editorState.getCurrentContent();
    const options = {
      entityStyleFn: (entity: any) => {
        const entityType = entity.get("type").toLowerCase();
        if (entityType === "mention") {
          const data = entity.getData();

          return {
            element: "span",
            attributes: {
              "data-mention-id": get(data, "mention.id"),
              class: "mention_class",
            },
            style: {
              background: "#e6f3ff",
            },
          };
        }
      },
    };

    return stateToHTML(contentState, options);
  }, [editorState]);

  const sendHtmlText = useCallback(
    async (files: FileModel[], statusChange?: string) => {
      chatSavedRef.current = taskSelected;
      setDisabled(true);
      const plainText = editorState.getCurrentContent().getPlainText();
      if (!plainText && !files.length && !statusChange) {
        setDisabled(false);

        return message.error("コメントを入力してください");
      }
      const listFile = await uploadListFilToS3(files);
      let numberFileImage = 0;
      let hasAudio = false;
      let hasOtherFile = false;

      listFile.forEach((file) => {
        const fileName = file?.split("?")[0].split("#")[0] || "";
        if (isImage(fileName)) {
          numberFileImage++;
        } else if (isAudio(fileName)) {
          hasAudio = true;
        } else {
          hasOtherFile = true;
        }
      });
      // update number file image
      const text = !!plainText.trim() ? JSON.stringify(toHTML()) : "";
      let commentType: keyof typeof COMMENT_TYPE = "ADD_COMMENT";
      if (hasAudio && !hasOtherFile && numberFileImage <= 0) {
        commentType = "ADD_COMMENT_AUDIO";
      } else if (numberFileImage > 0 && !hasAudio && !hasOtherFile) {
        commentType = "ADD_COMMENT_IMAGE";
      } else if (listFile.length) {
        commentType = "ADD_COMMENT_FILE";
      }
      if (listFile.length || statusChange) {
        await updateStatusAndNumberImageTask(
          numberFileImage,
          text,
          statusChange
        );
      }

      if (text.trim() || listFile.length) {
        await addChatMessage(
          text,
          commentType,
          listFile,
          undefined,
          statusChange
        );
      }
      setDisabled(false);
      chatSavedRef.current = null;
    },
    [
      addChatMessage,
      editorState,
      taskSelected,
      toHTML,
      updateStatusAndNumberImageTask,
    ]
  );

  const onAddMessage = useCallback(
    async (file: FileModel[], statusChange: string) => {
      sendHtmlText(file, statusChange);
    },
    [sendHtmlText]
  );
  const handleKeyCommand = async (command: any) => {
    if (command === "myEditor_submit") {
      // sendHtmlText();
    }
  };

  const onKeyUp = (e: any) => {
    if (e.ctrlKey && e.code === "Enter" && hasCommandModifier(e)) {
      return "myEditor_submit";
    }

    return getDefaultKeyBinding(e);
  };

  const moveToLast = () => {
    if (containerChatRef) {
      const scrollToBottom =
        scrollTopRef.current + (containerChatRef?.current as any)?.scrollHeight;
      (containerChatRef?.current as any)?.scroll({
        top: scrollToBottom || MIN_HEIGHT_SCROLL_LOAD_MORE,
      });
    }
  };

  const onDeleteImageChat = useCallback(
    async (comment: TaskComment, dataImage: string) => {
      setLoadingImage(true);
      const listImage = comment.images;
      const newListImage = listImage?.filter((item) => item !== dataImage);
      const newContent: TaskComment = {
        ...comment,
        images: newListImage,
        createdBy: currentUser?.id,
        type: COMMENT_TYPE.ADD_COMMENT.typeKey,
        id: comment.id,
      };
      try {
        const { data: res } = await taskCommentApi.updateComment(newContent);

        if (res?.id) {
          await updateStatusAndNumberImageTask(-1);
          sendWebSocketMessage({
            type: MessageType.UPDATE_TASK_COMMENT,
            taskId: taskSelected?.id,
            data: newContent,
          });

          setComments((comments) => {
            const currCmt = comments.find((cmt) => cmt.id === res?.id);
            if (currCmt) {
              currCmt.images = newListImage;
            }

            return [...comments];
          });
        }
      } catch (error) {
      } finally {
        setLoadingImage(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentUser?.id, taskSelected, updateStatusAndNumberImageTask]
  );

  const onUploadFileToS3 = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const target = event.target as any;
    const file = (target.files as FileList)[0];
    const imageFileURL = file ? await uploadFileToS3(file, file.name) : "";

    return imageFileURL;
  };

  const onUpdateImageComment = useCallback(
    async (
      e: React.ChangeEvent<HTMLInputElement>,
      comment: TaskComment,
      dataImage: string
    ) => {
      // setLoadingImage(true);
      const imageS3 = (await onUploadFileToS3(e)) || "";
      const listImage = comment.images;
      const newListImage = listImage?.map((imageItem: string) => {
        let cloneImage = imageItem;
        if (imageItem === dataImage) {
          cloneImage = imageS3;
        }

        return cloneImage;
      });
      const newContent: TaskComment = {
        ...comment,
        createdBy: currentUser?.id,
        images: newListImage,
        id: comment.id,
      };
      try {
        const { data: res } = await taskCommentApi.updateComment(newContent);

        if (res?.id) {
          const message = {
            type: MessageType.UPDATE_TASK_COMMENT,
            taskId: taskSelected?.id,
            data: res,
          };
          sendWebSocketMessage(message as any);
          // loadComment();
          setComments((comments) => {
            const currCmt = comments.find((cmt) => cmt.id === res.id);
            if (currCmt) {
              currCmt.images = newListImage;
              logDev("__onUpdateImageComment", message);
            }

            return [...comments];
          });
        }
      } catch (error) {
      } finally {
        setLoadingImage(false);
      }
    },
    [currentUser?.id, taskSelected, sendWebSocketMessage]
  );

  const onClear = () => {
    if (inputRef?.current?.value) {
      inputRef.current.value = "";
    }
  };

  const mailingList = useMemo(() => {
    return Object.values(listUserById).reduce<
      { name: string; value: string; highlight?: string }[]
    >((prev, curr, i) => {
      if (curr?.name) {
        prev.push({
          name: curr?.name,
          value: curr?.name ?? `${i}`,
          highlight: curr?.name,
        });
      }

      return prev;
    }, []);
  }, [listUserById]);

  return {
    comments,
    listUserById,
    mailingList,
    loadingChat,
    loadingImage,
    inputRef,
    containerChatRef,
    disabled,
    mentions,
    editorState,
    suggestions,
    onSearchChange,
    setEditorState,
    addChatMessage,
    moveToLast,
    onKeyUp,
    onAddMessage,
    onClear,
    onScroll,
    handleKeyCommand,
    onDeleteImageChat,
    onUpdateImageComment,
    chatSavedRef,
  };
};

export default useChat;
