import { fabric } from "fabric";
import { SharpBase } from "./sharp-base";

// Draw Line
export class Line extends SharpBase {
  onMouseMove = function (o: any) {
    const instance = this;
    if (!instance.isEnable()) {
      return;
    }

    const pointer = instance.canvas.getPointer(o.e);
    const activeObj = instance.canvas.getActiveObject();

    activeObj.set({
      x2: pointer.x,
      y2: pointer.y,
    });
    activeObj.setCoords();
    instance.canvas.renderAll();
  };

  onMouseDown = function (o: any) {
    const instance = this;
    instance.enable();

    const pointer = instance.canvas.getPointer(o.e);

    const points = [pointer.x, pointer.y, pointer.x, pointer.y];
    const line = new fabric.Line(points, {
      strokeWidth: instance.canvas.lineThickness,
      stroke: instance.canvas.freeDrawingBrush.color,
      fill: instance.canvas.freeDrawingBrush.color,
      originX: "center",
      originY: "center",
      hasBorders: false,
      hasControls: false,
      selectable: false,
      data: {
        noSaveState: true,
      },
    });
    instance.canvas.add(line).setActiveObject(line);
  };

  onMouseUp = () => {
    const instance = this;
    instance.disable();
    const activeObj = instance.canvas.getActiveObject();
    if (activeObj && activeObj.width && activeObj.height) {
      activeObj.hasControls = true;
      instance.canvas.saveState();
      instance.canvas.discardActiveObject();
      instance.canvas.renderAll();
    }
  };
}
