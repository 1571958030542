import { Tooltip } from "@chakra-ui/react";
import { InspectionItemType, SystemModeType } from "constants/enum";
import {
  getStatusColor,
  mapTaskIconStatus,
} from "utils/forge/extensions/custom-label/utils";

interface TaskInForgeViewerProps {
  id?: string;
  title?: string;
  status?: InspectionItemType;
}

const TaskInForgeViewer = ({
  id,
  title,
  status = InspectionItemType.Defect,
}: TaskInForgeViewerProps) => {
  return (
    <label className="task-in-forge-viewer">
      <div
        className="task-in-forge-viewer marker-pin"
        style={{
          backgroundColor: getStatusColor(status, SystemModeType.Task),
        }}
      >
        <span
          className="marker-label"
          id={status}
          dangerouslySetInnerHTML={{
            __html: mapTaskIconStatus(status),
          }}
        />
        {/* <div className="task-in-forge-viewer-icon">
        <span
          dangerouslySetInnerHTML={{
            __html: getStatusIcon(status, SystemModeType.Task),
          }}
        ></span>
      </div>
      <div className="task-in-forge-viewer-content">
        <Tooltip label={title}>
          <div className="text-ellipsis">{title || "-"}</div>
        </Tooltip>
        <span># {id || "-"}</span>
      </div> */}
      </div>
    </label>
  );
};

export default TaskInForgeViewer;
