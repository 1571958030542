import { Box, BoxProps } from "@chakra-ui/react";
import { PCTooltip } from "components/PCTooltip";

interface Props {
  label: string;
  toolTipCss?: BoxProps;
  children: JSX.Element;
}

const SelectTooltip = ({
  label,
  children,
  toolTipCss,
  ...props
}: Props & BoxProps) => {
  return (
    <PCTooltip
      hasArrow
      textAlign="center"
      label={label}
      bgColor={toolTipCss?.bgColor || "#000"}
      color="#fff"
      fontSize={toolTipCss?.fontSize || "1rem"}
      zIndex={1}
    >
      <Box {...props}>{children}</Box>
    </PCTooltip>
  );
};

export default SelectTooltip;
