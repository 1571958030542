import { FormControl, FormControlProps } from "@chakra-ui/react";
import { TypeOfFile } from "constants/file";
import { ReactNode } from "react";
import { verifyFile } from "utils/file";
import { message } from "./base";

interface Props {
  children: ReactNode;
  type: TypeOfFile;
  onChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    type: TypeOfFile,
    addCommentFunc?: () => void
  ) => void;
  onClick?: (event: React.MouseEvent<HTMLInputElement>) => void;
  addCommentFunc?: () => void;
  className?: string;
  style?: FormControlProps;
}

const FormUploadFile = ({
  children,
  type,
  addCommentFunc,
  onClick,
  onChange,
  className,
  style,
}: Props) => {
  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files as FileList;
    const errorTitle = await verifyFile(fileList, type);

    if (errorTitle) {
      return message.error(errorTitle);
    }

    if (onChange) {
      onChange(e, type, addCommentFunc);
    }
  };

  return (
    <FormControl
      className={className}
      isRequired
      w="auto"
      onClick={onClick}
      onChange={handleChange}
      {...style}
    >
      {children}
    </FormControl>
  );
};

export default FormUploadFile;
