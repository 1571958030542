import { fabric } from "fabric";

export abstract class SharpBase {
  className?: string;
  isDrawing: boolean;
  canvas: fabric.Canvas;

  constructor(canvas: fabric.Canvas, className?: string) {
    this.canvas = canvas;
    this.className = className;
    this.isDrawing = false;
    this.bindEvents();
  }

  abstract onMouseMove(o: { e: any }): void;
  abstract onMouseDown(o: { e: any }): void;

  bindEvents = function () {
    const instance = this;
    instance.canvas.on("mouse:down", function (o: any) {
      instance.onMouseDown(o);
    });
    instance.canvas.on("mouse:move", function (o: any) {
      instance.onMouseMove(o);
    });
    instance.canvas.on("mouse:up", function (o: any) {
      instance.onMouseUp(o);
    });
    instance.canvas.on("object:moving", function (o: any) {
      instance.disable();
    });
  };

  onMouseUp = function (o: any) {
    const instance = this;
    if (instance.isEnable()) {
      instance.disable();
    }
  };

  isEnable = function () {
    return this.isDrawing;
  };

  enable = function () {
    this.isDrawing = true;
  };

  disable = function () {
    this.isDrawing = false;
  };
}
