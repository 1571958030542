import { InputGroup, InputGroupProps } from "@chakra-ui/react";
import { ReactNode, useRef } from "react";
import { UseFormRegisterReturn } from "react-hook-form";

type FileUploadProps = {
  register?: UseFormRegisterReturn;
  accept?: string;
  multiple?: boolean;
  children?: ReactNode;
  onChangeInputRef?: (e: any) => void;
  isDisabled?: boolean;
};

const FileUpload = ({
  register,
  accept,
  multiple,
  children,
  onChangeInputRef,
  isDisabled = false,
  ...props
}: FileUploadProps & InputGroupProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { ref, ...rest } = register ?? { ref: (e: any) => {} };

  const handleClick = () => inputRef.current?.click();

  return (
    <InputGroup onClick={handleClick} {...props}>
      <input
        type="file"
        multiple={multiple || false}
        hidden
        accept={accept}
        disabled={isDisabled}
        {...rest}
        ref={(e) => {
          ref(e);
          if (onChangeInputRef) {
            onChangeInputRef(e);
          }
          inputRef.current = e;
        }}
      />
      {children}
    </InputGroup>
  );
};

export default FileUpload;
