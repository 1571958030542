export interface CustomEventI<T = any> extends CustomEvent {
  detail: T;
  initCustomEvent(
    typeArg: string,
    canBubbleArg: boolean,
    cancelableArg: boolean,
    detailArg: T
  ): void;
}

export interface ActionI {
  type: string;
  payload?: any;
}

export interface EventI {
  type: string;
  listener: (e: CustomEventI) => void;
}

function subscribe(event: EventI) {
  const { type, listener } = event;
  document.addEventListener(type, listener as any);
}

function unsubscribe(event: EventI) {
  const { type, listener } = event;
  document.removeEventListener(type, listener as any);
}

function dispatchEvent(action: ActionI) {
  const { type, payload } = action;
  const event = new CustomEvent(type, { detail: payload });
  document.dispatchEvent(event);
}

export { dispatchEvent, subscribe, unsubscribe };
