import {
  Box,
  Input,
  InputGroup,
  InputGroupProps,
  InputLeftElement,
  InputProps,
  InputRightElement,
} from "@chakra-ui/react";
import { forwardRef } from "react";
import { SvgIcon } from "./SvgIcon";

interface Props extends InputProps {
  containerProps?: InputGroupProps;
  iconDir?: "left" | "right";
  rightIcon?: JSX.Element;
  onSearch?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  rightIconProps?: Props;
  value?: string;
}

const SearchBar = forwardRef<HTMLInputElement, Props>(
  (
    {
      containerProps,
      iconDir = "right",
      rightIcon,
      onSearch,
      placeholder,
      rightIconProps,
      value,
      ...rest
    },
    ref
  ) => {
    return (
      <InputGroup maxW="33rem" {...containerProps}>
        {iconDir === "left" && (
          <InputLeftElement
            height="100%"
            mr="1rem"
            ml="1rem"
            children={
              <SvgIcon
                width="24px"
                height="24px"
                src="/img/icon-search-outline.svg"
              />
            }
          />
        )}
        <Input
          borderRadius="4px"
          bgColor="#FFFFFF"
          placeholder={placeholder ? placeholder : "検索..."}
          onChange={onSearch}
          value={value}
          fontSize="1.4rem"
          _placeholder={{ color: "#A3A3A3", fontSize: "1.4rem" }}
          height="4rem"
          p={
            iconDir === "left"
              ? "0.9rem 0.9rem 0.9rem 4rem"
              : "0.9rem 4rem 0.9rem 0.9rem"
          }
          ref={ref}
          {...rest}
        />
        {iconDir === "right" && (
          <InputRightElement
            height="2.4rem"
            mr="1rem"
            children={<SvgIcon src="/img/icon-search-outline.svg" />}
          />
        )}

        {rightIcon && (
          <InputRightElement
            height="100%"
            zIndex={9}
            mr={"1rem"}
            children={rightIcon}
            {...rightIconProps}
          />
        )}
      </InputGroup>
    );
  }
);

export default SearchBar;
