import { useDisclosure } from "@chakra-ui/react";
import {
  DEFAULT_PAGE_RATIO,
  MERGE_MESSAGE_TYPE,
  REMOVE_MESSAGE_TYPE,
  SPLIT_IMPACT_TYPE,
} from "constants/document";
import { DEFAULT_GRID_SIZE } from "constants/documentTemplate";
import {
  Axis,
  CellProperty,
  DocumentTemplateType,
  LinkedDataField,
  TemplateComponentType,
} from "constants/enum";
import {
  CellType,
  RowType,
  SubTableType,
  TemplateComponent,
} from "interfaces/models/component";
import _, { cloneDeep } from "lodash";
import {
  checkExistsLinkedTable,
  checkHasExistsHeader,
  checkHasOverlap,
  checkIsOutOfLimit,
  onDispatchComponents,
} from "models/document";
import {
  DEFAULT_BORDER_COLOR,
  DEFAULT_TEXT_COLOR,
} from "pages/document/template-page/hooks";
import { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setComponents,
  setComponentSelected,
  setSelectedCell,
} from "redux/documentSlice";
import { RootState } from "redux/store";
import { gcd, lcm } from "utils/common";
import {
  getAllCellSize,
  getCurrentMergedCellInTable,
  getIndexCell,
  getIndexFromId,
  getIndexFromSubCellId,
  getListRowFromComponent,
  getMaxMovingWidth,
  getMinColumnWidth,
  getMinRowHeight,
  getMinSubColumnWidth,
  getMinSubRowHeight,
  getOriginalSize,
  getParentCell,
  getTableSize,
  isComponentIncludesCell,
  modifyProperties,
  resizeSubTable,
} from "utils/document";
import { generateRandomString } from "utils/string";
import {
  getAllCells,
  getCellPositionByCellId,
  getComponentByCellId,
  updateCells,
} from "utils/tableCell";

interface Props {
  componentType: TemplateComponentType;
  zoomRatio: number;
  cellSize: { width: number; height: number };
  isBlackboardTemplate?: boolean;
}

export default function useRightPanelForTable({
  componentType,
  zoomRatio,
  cellSize,
  isBlackboardTemplate = false,
}: Props) {
  const dispatch = useDispatch();
  const {
    componentSelected,
    components,
    selectedCells,
    documentContainerSize,
    currentTemplate,
  } = useSelector((state: RootState) => state.document);

  const {
    isOpen: isOpenMergeCell,
    onOpen: onOpenMergeCell,
    onClose: onCloseMergeCell,
  } = useDisclosure();

  const {
    isOpen: isOpenSplitCell,
    onOpen: onOpenSplitCell,
    onClose: onCloseSplitCell,
  } = useDisclosure();

  const {
    isOpen: isOpenRemoveRowOrColumn,
    onOpen: onOpenRemoveRowOrColumn,
    onClose: onCloseRemoveRowOrColumn,
  } = useDisclosure();

  const {
    isOpen: isOpenAttachLink,
    onOpen: onOpenAttachLink,
    onClose: onCloseAttachLink,
  } = useDisclosure();

  const [selectedArea, setSelectedArea] = useState<
    | {
        start: {
          row: number;
          col: number;
        };
        end: {
          row: number;
          col: number;
        };
      }
    | undefined
  >(undefined);
  const [mergeMessageType, setMergeMessageType] = useState<MERGE_MESSAGE_TYPE>(
    MERGE_MESSAGE_TYPE.DataInCells
  );
  const [removeMessageType, setRemoveMessageType] =
    useState<REMOVE_MESSAGE_TYPE>(REMOVE_MESSAGE_TYPE.InvalidRemove);

  const tableSize = useMemo(() => {
    return getTableSize(componentSelected);
  }, [componentSelected]);

  const style = useMemo(() => {
    const tableStyle = componentSelected?.detail?.style;
    if (
      !selectedCells.length ||
      !componentSelected ||
      !isComponentIncludesCell(
        componentSelected,
        selectedCells[selectedCells.length - 1]
      )
    )
      return tableStyle;

    const lastCell = selectedCells[selectedCells.length - 1];
    if (lastCell.isSubCell) {
      const index = getCellPositionByCellId({
        cellId: lastCell.cellId,
        component: componentSelected,
      });

      return componentSelected.detail?.rows?.[index.parentIndex.row]?.cells?.[
        index.parentIndex.col
      ].subTable?.rows?.[index.index.row]?.cells?.[index.index.col]?.style;
    }

    const index = getIndexCell(lastCell);

    return componentSelected?.detail?.rows?.[index.row]?.cells?.[index.col]
      ?.style;
  }, [componentSelected, selectedCells]);

  const isSelfInspection: Boolean = useMemo(
    () => currentTemplate.documentType === DocumentTemplateType.SELF_INSPECTION,
    [currentTemplate.documentType]
  );

  const subcategories = useMemo(
    () =>
      components
        ?.filter((item) => item?.isLinkedHeader && !!item?.detail?.value)
        ?.map((item) => ({
          name: String(item?.detail?.value),
          value: String(item?.componentId),
        })),
    [components]
  );

  const isLinkedHeader = useMemo(
    () => componentSelected.isLinkedHeader,
    [componentSelected]
  );

  const isShowSelectAreas = useMemo(() => {
    return (
      componentSelected.type === TemplateComponentType.Text && isSelfInspection
    );
  }, [componentSelected, isSelfInspection]);

  const isTable = useMemo(() => {
    return (
      componentType === TemplateComponentType.Table ||
      componentType === TemplateComponentType.FilterPhoto
    );
  }, [componentType]);
  const isFilterPhoto = useMemo(() => {
    return componentType === TemplateComponentType.FilterPhoto;
  }, [componentType]);

  const checkValidPosition = (
    header: TemplateComponent,
    selected: TemplateComponent
  ) => {
    if (
      header &&
      !!selected?.componentId &&
      selected?.type !== TemplateComponentType.TableHeader
    ) {
      const newSize = { ...selected.size, width: header.size.width };
      let newPosition = selected.position;
      const isOutLimit = checkIsOutOfLimit({
        position: newPosition,
        size: newSize,
        isBlackboardTemplate,
      });

      if (isOutLimit) {
        newPosition = {
          ...newPosition,
          x: DEFAULT_GRID_SIZE.width - newSize.width,
        };
      }

      //selected, newPosition, newSize, Axis.HORIZONTAL
      return !checkHasOverlap({
        target: selected,
        position: newPosition,
        size: newSize,
        axis: Axis.HORIZONTAL,
        components,
      });
    }

    return false;
  };

  const ratio = useMemo(() => {
    return {
      sizePageRatio:
        currentTemplate?.pages[componentSelected?.page]?.sizePageRatio ??
        DEFAULT_PAGE_RATIO,
      pageDirectionRatio:
        currentTemplate?.pages[componentSelected?.page]?.pageDirectionRatio ??
        DEFAULT_PAGE_RATIO,
    };
  }, [currentTemplate, componentSelected]);

  const isDisableAddColumn = useMemo(
    () =>
      !!componentSelected.linkedHeaderId ||
      componentSelected.type === TemplateComponentType.TableHeader
        ? components.some((item) => {
            return (
              getMaxMovingWidth(
                item,
                components,
                zoomRatio,
                ratio.sizePageRatio,
                ratio.pageDirectionRatio,
                documentContainerSize
              ) < cellSize.width &&
              item.size.width - cellSize.width * getTableSize(item).column <
                cellSize.width
            );
          })
        : getMaxMovingWidth(
            componentSelected,
            components,
            zoomRatio,
            ratio.sizePageRatio,
            ratio.pageDirectionRatio,
            documentContainerSize
          ) < cellSize.width &&
          componentSelected.size.width - cellSize.width * tableSize.column <
            cellSize.width,
    [
      cellSize.width,
      componentSelected,
      components,
      documentContainerSize,
      tableSize.column,
      zoomRatio,
      ratio,
    ]
  );

  const minCellSizeWidth = useMemo(() => {
    return [
      TemplateComponentType.Table,
      TemplateComponentType.TableHeader,
    ].includes(componentSelected.type)
      ? !!selectedCells[0]?.isSubCell
        ? isComponentIncludesCell(componentSelected, selectedCells[0])
          ? getMinSubColumnWidth(
              getParentCell(selectedCells[0], componentSelected)?.subTable!
            )
          : []
        : getMinColumnWidth(componentSelected)
      : [];
  }, [componentSelected, selectedCells]);

  const minCellSizeHeight = useMemo(() => {
    return [
      TemplateComponentType.Table,
      TemplateComponentType.TableHeader,
    ].includes(componentSelected.type)
      ? !!selectedCells[0]?.isSubCell
        ? isComponentIncludesCell(componentSelected, selectedCells[0])
          ? getMinSubRowHeight(
              getParentCell(selectedCells[0], componentSelected)?.subTable!
            )
          : []
        : getMinRowHeight(componentSelected)
      : [];
  }, [componentSelected, selectedCells]);

  const handleUpdateCellStyle = useCallback(
    (value: any) => {
      const updatedCells = selectedCells.map((item) => ({
        ...item,
        style: {
          ...item.style,
          ...value,
        },
      }));
      const updatedComponentId = getComponentByCellId({
        cellId: selectedCells?.[0]?.cellId,
        components,
      })?.componentId as string;

      updateCells({
        updatedCells,
        updatedComponentIds: [updatedComponentId],
        components,
        documentType: currentTemplate?.documentType as DocumentTemplateType,
        dispatch,
      });
    },
    [components, currentTemplate, dispatch, selectedCells]
  );

  const isSelectAllSubCell = () => {
    return selectedCells.every((cell) => cell.isSubCell);
  };

  const checkImpactCell = (selectedCell: CellType, cell: CellType) => {
    const indexSelectedCell = {
      row: getIndexFromId(selectedCell.idRow),
      col: getIndexFromId(selectedCell.idColumn),
    };
    const indexCell = {
      row: getIndexFromId(cell.idRow),
      col: getIndexFromId(cell.idColumn),
    };

    if (
      indexSelectedCell.row <= indexCell.row &&
      indexCell.row <=
        indexSelectedCell.row + Number(selectedCell.rowSpan) - 1 &&
      indexSelectedCell.col <= indexCell.col &&
      indexCell.col <= indexSelectedCell.col + Number(selectedCell.colSpan) - 1
    ) {
      return SPLIT_IMPACT_TYPE.ImpactBySplitCell;
    }
    if (
      indexSelectedCell.row <= indexCell.row &&
      indexCell.row <= indexSelectedCell.row + Number(selectedCell.rowSpan) - 1
    ) {
      return SPLIT_IMPACT_TYPE.ImpactByRow;
    }
    if (
      indexSelectedCell.col <= indexCell.col &&
      indexCell.col <= indexSelectedCell.col + Number(selectedCell.colSpan) - 1
    ) {
      return SPLIT_IMPACT_TYPE.ImpactByColumn;
    }

    return SPLIT_IMPACT_TYPE.NotImpact;
  };

  const insertEmptyCellToRow = (
    cells: CellType[],
    colSpan: number,
    allCellWidth: number[]
  ) => {
    const initColumnId = generateRandomString(36, 9);
    const originCells = cloneDeep(cells);
    let newCells: CellType[] = [];
    const currentComponent = getComponentByCellId({
      cellId: selectedCells?.[0]?.cellId,
      components,
    });

    const indexOfInsertedCell =
      originCells?.length / Number(currentComponent?.detail?.numOfRepeatTable) -
      1;

    const insertedCell = {
      ...cells[indexOfInsertedCell],
      colSpan: 1,
      rowSpan: 1,
    } as CellType;

    const insertedCells: CellType[] = Array(colSpan)
      .fill(insertedCell)
      ?.map((cell, index) => {
        const cellId = generateRandomString(36, 10);

        return {
          ...cell,
          position: {
            ...cell.position,
            idColumn: initColumnId + index,
          },
          width: Number(selectedCells?.[0]?.width) / colSpan,
          cellId: cell?.isRepeatedTable ? generateRandomString(36, 10) : cellId,
          ...(cell?.isRepeatedTable && { repeatedFromCellId: cellId }),
        };
      });

    originCells.forEach((cell) => {
      if (cell.position?.idColumn === selectedCells?.[0]?.position?.idColumn) {
        newCells = newCells.concat(
          insertedCells?.map((item) => ({
            ...item,
            isRepeatedTable: cell.isRepeatedTable,
          }))
        );

        return;
      }

      newCells = newCells.concat([cell]);
    });

    return newCells.map((cell, index) => {
      return {
        ...cell,
        idRow: cell.idRow,
        idColumn: `${cell.idRow}-td-${index}`,
      };
    }) as CellType[];
  };

  const splitCurrentCell = (
    rows: RowType[],
    selectedCell: CellType,
    colSpan: number,
    rowSpan: number,
    newHeader?: TemplateComponent
  ) => {
    const insertIndex = getIndexCell(selectedCell);
    const allCellSize = getAllCellSize(rows, selectedCell);
    const newHeaderCells = newHeader?.detail?.rows?.[0]?.cells;

    return rows.map((row, indexRow) => {
      const initRowId = generateRandomString(36, 10);

      const cells = row.cells?.map((cell, indexCell) => {
        const cellPosition = {
          position: {
            ...cell.position,
            idRow: initRowId,
            idColumn: newHeader
              ? newHeaderCells?.[indexCell]?.position?.idColumn
              : cell.position?.idColumn,
          },
          cellId: generateRandomString(36, 10),
        };

        if (
          checkImpactCell(selectedCell, cell) !==
          SPLIT_IMPACT_TYPE.ImpactBySplitCell
        ) {
          return {
            ...cell,
            ...cellPosition,
          };
        }

        if (
          (indexCell - insertIndex.col) % colSpan === 0 &&
          (indexRow - insertIndex.row) % rowSpan === 0
        ) {
          let width = 0;
          for (let i = 0; i < colSpan; i++) {
            width += allCellSize.widthList[indexCell + i];
          }

          let height = 0;
          for (let i = 0; i < rowSpan; i++) {
            height += allCellSize.heightList[indexRow + i];
          }

          return {
            ...cell,
            colSpan: colSpan,
            rowSpan: rowSpan,
            width: width,
            height: height,
            value: selectedCell.value,
            cellProperty: selectedCell.cellProperty,
            iconSrc: selectedCell.iconSrc,
            placeholder: selectedCell.placeholder,
            cellLinkedData: selectedCell?.cellLinkedData,
            ...cellPosition,
          };
        }

        return {
          ...cell,
          colSpan: 0,
          rowSpan: 0,
          width: allCellSize.widthList[indexCell],
          height: allCellSize.heightList[indexRow],
          value: selectedCell.value,
          cellProperty: selectedCell.cellProperty,
          iconSrc: selectedCell.iconSrc,
          placeholder: selectedCell.placeholder,
          cellLinkedData: selectedCell?.cellLinkedData,
          ...cellPosition,
        };
      });

      return {
        ...row,
        cells: cells,
      };
    }) as any;
  };

  const insertEmptyRow = (
    rows: RowType[],
    selectedCell: CellType,
    rowSpan: number,
    allCellHeight: number[],
    parentCell?: CellType
  ) => {
    const indexSelectedCell = getIndexCell(selectedCell);
    let insertIndex = indexSelectedCell.row;
    const idPrefix = selectedCell.isSubCell
      ? parentCell?.idColumn
      : selectedCell.idColumn.split("-")[0];

    for (let i = 0; i < Number(selectedCell.rowSpan); i++) {
      const ind = insertIndex;
      const cells = rows[insertIndex].cells?.map((cell) => {
        return {
          ...cell,
          colSpan: 0,
          rowSpan: 0,
          height:
            Number(cell.rowSpan) > 1
              ? allCellHeight[indexSelectedCell.row + i] / rowSpan
              : Number(cell.height) / rowSpan,
        };
      });
      const emptyRow = {
        ...rows[insertIndex],
        cells: cells,
      };
      const emptyRowList = Array(rowSpan - 1).fill(emptyRow);
      rows = rows.map((row, indexRow) => ({
        ...row,
        cells:
          ind === indexRow
            ? row.cells?.map((cell) => ({
                ...cell,
                height:
                  cell.rowSpan === 0
                    ? Number(cell.height) / rowSpan
                    : cell.height,
              }))
            : row.cells,
      }));
      rows?.splice(insertIndex + 1, 0, ...emptyRowList);
      insertIndex += rowSpan;
    }

    return rows.map((row, indexRow) => {
      const cells = row.cells?.map((cell, indexColumn) => ({
        ...cell,
        idRow: `${idPrefix}-tr-${indexRow}`,
        idColumn: `${idPrefix}-tr-${indexRow}-td-${indexColumn}`,
      }));

      return {
        ...row,
        idRow: `${idPrefix}-tr-${indexRow}`,
        cells: cells,
      };
    });
  };

  const splitCell = (
    currentCell: CellType,
    component: TemplateComponent,
    rows: number,
    columns: number,
    newHeader?: TemplateComponent
  ) => {
    const table = currentCell?.isSubCell
      ? (getParentCell(currentCell, component)!.subTable as SubTableType)
      : component;
    const originListRow = getListRowFromComponent(table);

    const rowSpan = rows / gcd(Number(currentCell.rowSpan), rows);
    const colSpan = columns / gcd(Number(currentCell.colSpan), columns);
    const impactedMergedCellByColumn = Object.assign(
      {},
      ...(originListRow?.map((row) => {
        return _.countBy(
          row.cells?.map((cell) => {
            const currentMergedCell = getCurrentMergedCellInTable(cell, table);

            if (
              checkImpactCell(currentCell, cell) ===
              SPLIT_IMPACT_TYPE.ImpactByColumn
            ) {
              return currentMergedCell.idColumn;
            }

            return undefined;
          })
        );
      }) as any[])
    );
    const impactedMergedCellByRow = Object.assign(
      {},
      ...(originListRow![0].cells
        ?.map((column, index) => originListRow?.map((row) => row.cells![index]))
        .map((row) => {
          return _.countBy(
            row?.map((cell) => {
              const currentMergedCell = getCurrentMergedCellInTable(
                cell,
                table
              );

              if (
                checkImpactCell(currentCell, cell) ===
                SPLIT_IMPACT_TYPE.ImpactByRow
              ) {
                return currentMergedCell.idColumn;
              }

              return undefined;
            })
          );
        }) as any[])
    );

    const allCellSize = getAllCellSize(originListRow!, currentCell);
    let listRow = originListRow?.map((row) => {
      const allCellWidth = allCellSize.widthList;
      const cells = row.cells?.map((cell) => {
        switch (checkImpactCell(currentCell, cell)) {
          case SPLIT_IMPACT_TYPE.ImpactBySplitCell:
            if (cell.idColumn === currentCell.idColumn) {
              const rowSpan = lcm(rows, Number(currentCell.rowSpan));
              const colSpan = lcm(columns, Number(currentCell.colSpan));

              return {
                ...cell,
                rowSpan: rowSpan,
                colSpan: colSpan,
              };
            }

            return cell;
          case SPLIT_IMPACT_TYPE.ImpactByRow:
            return {
              ...cell,
              rowSpan: impactedMergedCellByRow.hasOwnProperty(cell.idColumn)
                ? Number(cell.rowSpan) +
                  (rowSpan - 1) * impactedMergedCellByRow[cell.idColumn!]
                : 0,
            };
          case SPLIT_IMPACT_TYPE.ImpactByColumn:
            return {
              ...cell,
              colSpan: impactedMergedCellByColumn.hasOwnProperty(cell.idColumn)
                ? Number(cell.colSpan) +
                  (colSpan - 1) * impactedMergedCellByColumn[cell.idColumn!]
                : 0,
            };
          default:
            return {
              ...cell,
              colSpan: impactedMergedCellByColumn.hasOwnProperty(cell.idColumn)
                ? Number(cell.colSpan) +
                  (colSpan - 1) * impactedMergedCellByColumn[cell.idColumn!]
                : cell.colSpan,
              rowSpan: impactedMergedCellByRow.hasOwnProperty(cell.idColumn)
                ? Number(cell.rowSpan) +
                  (rowSpan - 1) * impactedMergedCellByRow[cell.idColumn!]
                : cell.rowSpan,
            };
        }
      }) as CellType[];

      return {
        ...row,
        cells: insertEmptyCellToRow(cells, colSpan, allCellWidth),
      };
    }) as RowType[];

    listRow = insertEmptyRow(
      listRow,
      currentCell,
      rowSpan,
      allCellSize.heightList,
      currentCell.isSubCell ? getParentCell(currentCell, component) : undefined
    );

    listRow = splitCurrentCell(
      listRow,
      {
        ...currentCell,
        rowSpan: lcm(rows, Number(currentCell.rowSpan)),
        colSpan: lcm(columns, Number(currentCell.colSpan)),
      },
      lcm(columns, Number(currentCell.colSpan)) / columns,
      lcm(rows, Number(currentCell.rowSpan)) / rows,
      newHeader
    );

    if (currentCell.isSubCell) {
      const indexSubCell = getIndexFromSubCellId(currentCell.idColumn);

      return {
        ...component,
        detail: {
          ...component?.detail,
          rows: component.detail?.rows?.map((row, indexRow) => {
            if (indexRow === indexSubCell.parentIndex.row) {
              return {
                ...row,
                cells: row.cells?.map((cell, indexCell) => {
                  if (indexCell === indexSubCell.parentIndex.col) {
                    return {
                      ...cell,
                      subTable: {
                        ...cell.subTable,
                        rows: listRow,
                      },
                    };
                  }

                  return cell;
                }),
              };
            }

            return row;
          }),
        },
      } as TemplateComponent;
    }

    return {
      ...component,
      detail: {
        ...component?.detail,
        rows: listRow,
      },
    } as TemplateComponent;
  };

  const handleSplitCell = (
    rows: number,
    columns: number,
    component?: TemplateComponent,
    indexCell?: number,
    newHeader?: TemplateComponent
  ) => {
    if (
      componentSelected.type === TemplateComponentType.TableHeader &&
      rows > 1
    ) {
      return;
    }

    const currentComponent = component || componentSelected;
    const currentIndexCell =
      indexCell || getIndexFromId(selectedCells[0].idColumn);
    let newTable = {
      ...currentComponent,
    };

    if (!!currentComponent?.linkedHeaderId) {
      currentComponent.detail?.rows?.forEach((row, index) => {
        let cellSplitted;
        const indexCell = getIndexFromSubCellId(selectedCells[0].idColumn);
        if (
          currentTemplate?.documentType === DocumentTemplateType.PHOTO_LEDGER ||
          index === indexCell.parentIndex.row
        ) {
          if (selectedCells[0].isSubCell) {
            cellSplitted =
              newTable.detail!.rows![index].cells![indexCell.parentIndex.col]
                .subTable!.rows![indexCell.index.row].cells![currentIndexCell];
          } else {
            cellSplitted =
              newTable.detail!.rows![index].cells![currentIndexCell];
          }

          newTable = splitCell(
            cellSplitted,
            newTable,
            rows,
            columns,
            newHeader
          );
        }
      });
    } else {
      newTable = splitCell(selectedCells[0], currentComponent, rows, columns);
    }

    if (component && component.type !== TemplateComponentType.TableHeader) {
      return newTable;
    }

    const newListComponent = [...components];
    newListComponent.forEach((item, index) => {
      if (item.componentId === currentComponent.componentId) {
        newListComponent[index] = newTable;
      } else if (
        currentComponent.type === TemplateComponentType.TableHeader &&
        !!item.linkedHeaderId
      ) {
        newListComponent[index] =
          handleSplitCell(rows, columns, item, currentIndexCell, newTable) ||
          item;
      }
    });

    dispatch(setComponents(newListComponent));
    dispatch(setComponentSelected({} as TemplateComponent));
    dispatch(setSelectedCell([]));
  };

  const handleMergeCells = (
    component: TemplateComponent,
    selectedCells: CellType[],
    selectedArea: {
      start: {
        row: number;
        col: number;
      };
      end: {
        row: number;
        col: number;
      };
    }
  ) => {
    const firstMergedCell =
      selectedCells.find(
        (cell) =>
          getIndexFromId(cell.idColumn) === selectedArea.start.col &&
          getIndexFromId(cell.idRow) === selectedArea.start.row
      ) || ({} as CellType);

    const mergedCellsInRow = selectedCells.filter(
      (cell) =>
        getIndexFromId(cell.idRow) === getIndexFromId(firstMergedCell.idRow)
    );
    const mergedCellsInColumn = selectedCells.filter(
      (cell) =>
        getIndexFromId(cell.idColumn) ===
        getIndexFromId(firstMergedCell.idColumn)
    );

    // update rowSpan, colSpan of top left cell in selected cells
    const mergedCell = {
      ...firstMergedCell,
      colSpan: mergedCellsInRow.reduce(
        (totalColSpan, item) => totalColSpan + Number(item.colSpan),
        0
      ),
      rowSpan: mergedCellsInColumn.reduce(
        (totalRowSpan, item) => totalRowSpan + Number(item.rowSpan),
        0
      ),
      width: mergedCellsInRow.reduce(
        (totalWidth, item) => totalWidth + Number(item.width),
        0
      ),
      height: mergedCellsInColumn.reduce(
        (totalHeight, item) => totalHeight + Number(item.height),
        0
      ),
    };

    // change rowSpan, colSpan of all selected cells
    const listRow = component.detail?.rows?.map((row) => {
      if (
        selectedArea.start.row <= getIndexFromId(row.idRow) &&
        getIndexFromId(row.idRow) <= selectedArea.end.row
      ) {
        return {
          ...row,
          cells: row.cells?.map((cell) => {
            if (cell.idColumn === mergedCell.idColumn) {
              return mergedCell;
            } else if (
              selectedArea.start.col <= getIndexFromId(cell.idColumn) &&
              getIndexFromId(cell.idColumn) <= selectedArea.end.col
            ) {
              const originalSize = getOriginalSize(
                component.detail?.rows!,
                cell
              );

              return {
                ...cell,
                colSpan: 0,
                rowSpan: 0,
                width: cell.colSpan ? originalSize.width : cell.width,
                height: cell.rowSpan ? originalSize.height : cell.height,
              };
            }

            return cell;
          }),
        };
      }

      return row;
    });

    return {
      ...component,
      detail: {
        ...component?.detail,
        rows: listRow,
      },
    } as TemplateComponent;
  };

  const handleMergeSubCells = (
    subTable: SubTableType,
    selectedCells: CellType[],
    selectedArea: {
      start: {
        row: number;
        col: number;
      };
      end: {
        row: number;
        col: number;
      };
    }
  ) => {
    const firstMergedCell =
      selectedCells.find((cell) => {
        const indexCell = getIndexFromSubCellId(cell?.idColumn);

        return (
          indexCell.index.col === selectedArea.start.col &&
          indexCell.index.row === selectedArea.start.row
        );
      }) || ({} as CellType);
    const indexFirstMergedCell = getIndexFromSubCellId(
      firstMergedCell.idColumn
    );

    const mergedCellsInRow = selectedCells.filter((cell) => {
      const indexCell = getIndexFromSubCellId(cell.idColumn);

      return indexCell.index.row === indexFirstMergedCell.index.row;
    });
    const mergedCellsInColumn = selectedCells.filter((cell) => {
      const indexCell = getIndexFromSubCellId(cell.idColumn);

      return indexCell.index.col === indexFirstMergedCell.index.col;
    });

    // update rowSpan, colSpan of top left cell in selected cells
    const mergedCell = {
      ...firstMergedCell,
      colSpan: mergedCellsInRow.reduce(
        (totalColSpan, item) => totalColSpan + Number(item.colSpan),
        0
      ),
      rowSpan: mergedCellsInColumn.reduce(
        (totalRowSpan, item) => totalRowSpan + Number(item.rowSpan),
        0
      ),
      width: mergedCellsInRow.reduce(
        (totalWidth, item) => totalWidth + Number(item.width),
        0
      ),
      height: mergedCellsInColumn.reduce(
        (totalHeight, item) => totalHeight + Number(item.height),
        0
      ),
    };

    // change rowSpan, colSpan of all selected cells
    const listRow = subTable.rows?.map((row, indexRow) => {
      if (
        selectedArea.start.row <= indexRow &&
        indexRow <= selectedArea.end.row
      ) {
        return {
          ...row,
          cells: row.cells?.map((cell, indexCell) => {
            if (
              indexCell === indexFirstMergedCell.index.col &&
              indexRow === indexFirstMergedCell.index.row
            ) {
              return mergedCell;
            } else if (
              selectedArea.start.col <= indexCell &&
              indexCell <= selectedArea.end.col
            ) {
              const originalSize = getOriginalSize(subTable.rows!, cell);

              return {
                ...cell,
                colSpan: 0,
                rowSpan: 0,
                width: cell.colSpan ? originalSize.width : cell.width,
                height: cell.rowSpan ? originalSize.height : cell.height,
              };
            }

            return cell;
          }),
        };
      }

      return row;
    });

    return {
      ...subTable,
      rows: listRow,
    } as SubTableType;
  };

  const handleMergeSubCellsInLinkedTable = (
    component: TemplateComponent,
    selectedCells: CellType[],
    selectedArea: {
      start: {
        row: number;
        col: number;
      };
      end: {
        row: number;
        col: number;
      };
    }
  ) => {
    const parentCellIndex = getIndexFromSubCellId(
      selectedCells[0].idColumn
    ).parentIndex;
    const listRow = component.detail?.rows?.map((row, indexRow) => ({
      ...row,
      cells: row.cells?.map((cell, indexCell) => {
        if (
          indexCell === parentCellIndex.col &&
          (currentTemplate?.documentType ===
            DocumentTemplateType.PHOTO_LEDGER ||
            indexRow === parentCellIndex.row)
        ) {
          const newSelectedCells = selectedCells.map((cellSelected) => {
            const index = getIndexFromSubCellId(cellSelected.idColumn);

            return cell.subTable?.rows![index.index.row].cells![
              index.index.col
            ] as CellType;
          });

          return {
            ...cell,
            subTable: handleMergeSubCells(
              cell.subTable!,
              newSelectedCells,
              selectedArea
            ),
          };
        }

        return cell;
      }),
    }));

    return {
      ...component,
      detail: {
        ...component.detail,
        rows: listRow,
      },
    } as TemplateComponent;
  };

  const handleMergeCellsInHeaderTable = (
    component: TemplateComponent,
    selectedCell: CellType[],
    selectedArea: {
      start: {
        row: number;
        col: number;
      };
      end: {
        row: number;
        col: number;
      };
    },
    isCategory?: boolean
  ) => {
    if (selectedArea.start.row !== selectedArea.end.row) {
      return component;
    }

    const firstMergedCell =
      selectedCells.find(
        (cell) =>
          getIndexFromId(cell.idColumn) === selectedArea.start.col &&
          getIndexFromId(cell.idRow) === selectedArea.start.row
      ) || ({} as CellType);

    const mergedCell = {
      ...firstMergedCell,
      colSpan: 1,
      rowSpan: 1,
      width: selectedCell.reduce(
        (totalWidth, item) => totalWidth + Number(item.width),
        0
      ),
    };

    const listRow = component.detail?.rows?.map((row) => ({
      ...row,
      cells: row.cells
        ?.filter(
          (cell) =>
            !(
              selectedArea.start.col < getIndexFromId(cell.idColumn) &&
              getIndexFromId(cell.idColumn) <= selectedArea.end.col
            )
        )
        .map((cell, indexCell) => {
          if (cell.idColumn === mergedCell.idColumn) {
            if (isCategory) {
              const columnNumber =
                selectedArea.end.col - selectedArea.start.col + 1;

              const cellDefault = {
                value: "",
                style: mergedCell?.style,
                height: mergedCell.height! / 2,
                cellProperty: CellProperty.TEXT,
                rowSpan: 1,
                colSpan: 1,
                isSubCell: true,
              };

              const rows = new Array(2).fill(0).map((row, indexRow) => {
                const idRow = `${mergedCell.idColumn}-tr-${indexRow}`;
                const cells = new Array(columnNumber)
                  .fill(0)
                  .map((cell, indexCell) => ({
                    ...cellDefault,
                    cellId: generateRandomString(36, 10),
                    idRow,
                    idColumn: `${mergedCell.idColumn}-tr-${indexRow}-td-${indexCell}`,
                    value:
                      indexRow === 0 && indexCell === 0
                        ? mergedCell?.value
                        : "",
                    width:
                      indexRow === 0 && indexCell === 0
                        ? mergedCell.width!
                        : mergedCell.width! / columnNumber,
                    colSpan:
                      indexRow === 0 ? (indexCell === 0 ? columnNumber : 0) : 1,
                    rowSpan: indexCell === 0 ? 1 : 0,
                  }));

                return {
                  idRow,
                  cells,
                };
              });

              return {
                ...mergedCell,
                value: "",
                cellLinkedData: {},
                cellProperty: CellProperty.SUB_TABLE,
                subTable: { rows },
              };
            }

            return mergedCell;
          } else {
            return {
              ...cell,
              idColumn: `${cell.idRow}-td-${indexCell}`,
            };
          }
        }),
    }));

    return {
      ...component,
      detail: {
        ...component?.detail,
        rows: listRow,
      },
    } as TemplateComponent;
  };

  const handleMergeCellsInLinkedTable = (
    tableComponent: TemplateComponent,
    headerComponent: TemplateComponent,
    selectedAreaInRow: {
      start: number;
      end: number;
    },
    isCategory?: boolean
  ) => {
    const newComponent = {
      ...tableComponent,
      detail: {
        ...tableComponent.detail,
        rows: tableComponent.detail?.rows?.map((row) => ({
          ...row,
          cells: row.cells
            ?.filter(
              (cell, index) =>
                !(
                  selectedAreaInRow.start < index &&
                  index <= selectedAreaInRow.end
                )
            )
            .map((cell, index) => {
              const newWidth =
                headerComponent.detail?.rows![0].cells![index].width || 0;

              const cellDefault = {
                ...cell,
                idColumn: `${cell.idRow}-td-${index}`,
                colSpan: headerComponent.detail?.rows![0].cells![index].colSpan,
                rowSpan: headerComponent.detail?.rows![0].cells![index].rowSpan,
                width: newWidth,
                subTable:
                  cell.subTable?.rows?.length && newWidth !== cell.width
                    ? resizeSubTable(cell, cell.subTable, {
                        width: newWidth - cell.width!,
                        height: 0,
                      })
                    : cell.subTable,
              } as CellType;

              if (isCategory && index === selectedAreaInRow.start) {
                const columnNumber =
                  selectedAreaInRow.end - selectedAreaInRow.start + 1;

                const rows = cellDefault.subTable
                  ? cellDefault.subTable?.rows?.map((subRow, indexSubRow) => {
                      const idSubRow = `${cell.idColumn}-tr-${indexSubRow}`;

                      return {
                        ...subRow,
                        idRow: idSubRow,
                        cells: new Array(columnNumber)
                          .fill(0)
                          .map((c, indexCell) => ({
                            ...cellDefault,
                            cellId: generateRandomString(36, 10),
                            idRow: idSubRow,
                            idColumn: `${idSubRow}-td-${indexCell}`,
                            value: "",
                            width: cell.width!,
                            height: getOriginalSize(
                              cellDefault.subTable?.rows!,
                              cellDefault.subTable?.rows?.[indexSubRow]
                                .cells?.[0] as CellType
                            ).height,
                            colSpan: 1,
                            rowSpan: 1,
                            cellProperty: CellProperty.TEXT,
                            style: {
                              border: true,
                              borderColor: DEFAULT_BORDER_COLOR,
                              color: DEFAULT_TEXT_COLOR,
                              borderLeft: true,
                              borderRight: true,
                              borderBottom: true,
                              borderTop: true,
                            },
                            isSubCell: true,
                          })),
                      };
                    })
                  : [
                      {
                        idRow: `${cellDefault.idColumn}-tr-0`,
                        cells: new Array(columnNumber)
                          .fill(0)
                          .map((c, indexCell) => ({
                            ...cellDefault,
                            cellId: generateRandomString(36, 10),
                            idRow: `${cellDefault.idColumn}-tr-0`,
                            idColumn: `${cellDefault.idColumn}-tr-0-td-${indexCell}`,
                            value: "",
                            width: cell.width!,
                            height: cell.height!,
                            colSpan: 1,
                            rowSpan: 1,
                            cellProperty: CellProperty.TEXT,
                            style: {
                              border: true,
                              borderColor: DEFAULT_BORDER_COLOR,
                              color: DEFAULT_TEXT_COLOR,
                              borderLeft: true,
                              borderRight: true,
                              borderBottom: true,
                              borderTop: true,
                            },
                            isSubCell: true,
                          })),
                      },
                    ];

                return {
                  ...cellDefault,
                  value: "",
                  cellLinkedData: {},
                  cellProperty: CellProperty.TEXT,
                  subTable: { rows },
                };
              }

              return cellDefault;
            }),
        })),
      },
    } as TemplateComponent;

    return newComponent;
  };

  const handleSubmitMergeCells = (selectedArea: {
    start: {
      row: number;
      col: number;
    };
    end: {
      row: number;
      col: number;
    };
  }) => {
    const isExistsLinkedTable = checkExistsLinkedTable(components);

    const newTable =
      componentSelected.type === TemplateComponentType.TableHeader &&
      isSelectAllSubCell()
        ? handleMergeSubCellsInLinkedTable(
            componentSelected,
            selectedCells,
            selectedArea
          )
        : componentSelected.type === TemplateComponentType.TableHeader
        ? handleMergeCellsInHeaderTable(
            componentSelected,
            selectedCells,
            selectedArea
          )
        : isSelectAllSubCell()
        ? handleMergeSubCellsInLinkedTable(
            componentSelected,
            selectedCells,
            selectedArea
          )
        : handleMergeCells(componentSelected, selectedCells, selectedArea);

    dispatch(setSelectedCell([]));

    // sync merging cells in linked tables
    if (
      componentSelected.type === TemplateComponentType.TableHeader &&
      isExistsLinkedTable
    ) {
      const newListComponent = components.map((component) => {
        if (component.componentId === componentSelected.componentId) {
          return newTable;
        } else if (!!component.linkedHeaderId) {
          return handleMergeCellsInLinkedTable(component, newTable, {
            start: selectedArea.start.col,
            end: selectedArea.end.col,
          });
        }

        return component;
      });
      dispatch(setComponents(newListComponent));
    } else if (
      !isExistsLinkedTable ||
      componentSelected.type !== TemplateComponentType.TableHeader
    ) {
      onDispatchComponents({ newData: newTable, components });
    }
  };

  const getCurrentMergedCell = (currentCell: CellType) => {
    if (currentCell?.colSpan && currentCell?.rowSpan) {
      return currentCell;
    }
    const cellIndex = {
      col: getIndexFromId(currentCell?.idColumn),
      row: getIndexFromId(currentCell?.idRow),
    };
    const component = components.filter((component) => {
      return component.componentId === currentCell?.idColumn?.split("-")[0];
    })[0];
    let result = {} as CellType;
    component?.detail?.rows?.forEach((row) => {
      const rowIndex = getIndexFromId(row?.idRow);
      if (rowIndex <= cellIndex.row) {
        row.cells?.forEach((cell) => {
          const columnIndex = getIndexFromId(cell?.idColumn);
          if (columnIndex <= cellIndex.col) {
            if (
              (cell.rowSpan! > 1 || cell?.colSpan! > 1) &&
              cellIndex.row - rowIndex < cell?.rowSpan! &&
              cellIndex.col - columnIndex < cell?.colSpan!
            ) {
              result = cell;
            }
          }
        });
      }
    });

    return result;
  };

  const getTitleCell = useCallback(
    (
      prefixSubTitle: string,
      titleIndex: number,
      cells?: CellType[],
      rowIndex?: number
    ) => {
      if (titleIndex >= 0 && cells?.length) {
        let newCells = cells[titleIndex]?.value || "";
        if (rowIndex !== undefined) {
          newCells =
            cells?.[titleIndex]?.subTable?.rows?.[rowIndex]?.cells
              ?.map((item) => item.value)
              ?.join(" ") || "";
          newCells = prefixSubTitle + newCells.replace(prefixSubTitle, "");
        }

        return newCells.trim().replace(/\s\s+/g, "　");
      }

      return "";
    },
    []
  );

  /**
   * titleIndex: index of col want to get title
   * columnIndex: index of col selected
   * cells: root cells or subTable cells, used to map data to cells
   * titleCells: get the original title and assign it to the cells
   * rowIndex: only sub table
   * parentRowIndexForSubTable: only sub table
   */
  const setTitleCellBySpecifiedColumn = useCallback(
    ({
      titleIndex,
      columnIndex,
      selectedCell,
      cells,
      titleCells,
      rowIndex,
      parentRowIndexForSubTable,
      prefixSubTitle = "",
    }: {
      titleIndex: number;
      columnIndex: number;
      selectedCell: CellType;
      cells?: CellType[];
      titleCells?: CellType[];
      rowIndex?: number;
      parentRowIndexForSubTable?: number;
      prefixSubTitle: string;
    }) => {
      const listCell: CellType[] = [...(cells ?? [])];
      const dynamicFieldSection =
        selectedCell.cellLinkedData?.options?.dynamicFieldSection;
      const dynamicFieldLabelForCell =
        selectedCell.cellLinkedData?.options?.dynamicFieldLabelForCell;

      return listCell.map((cell, cellIndex) => {
        // Update title for cell
        if (
          cell?.cellLinkedData?.field ===
            LinkedDataField.COMMON.DYNAMIC_FIELDS_FOR_ITEM &&
          (columnIndex === parentRowIndexForSubTable ||
            columnIndex === cellIndex)
        ) {
          cell = {
            ...cell,
            cellLinkedData: {
              ...cell.cellLinkedData,
              options:
                titleIndex >= 0
                  ? {
                      ...cell.cellLinkedData?.options,
                      dynamicFieldSection,
                      dynamicFieldLabelForCell,
                      dynamicFieldLabel: getTitleCell(
                        prefixSubTitle,
                        titleIndex,
                        titleCells,
                        rowIndex
                      ),
                    }
                  : undefined,
            },
          };
        }

        // Update cell for sub table
        if (cell?.subTable?.rows?.length) {
          prefixSubTitle += cell?.subTable?.rows[0]?.cells?.length
            ? cell?.subTable?.rows[0]?.cells[0]?.value
            : "";
          const subTableRows = [...(cell.subTable.rows || [])]?.map(
            (subTableRow, subTableRowIndex) => {
              return {
                ...subTableRow,
                cells: setTitleCellBySpecifiedColumn({
                  titleIndex,
                  columnIndex,
                  selectedCell,
                  cells: subTableRow.cells,
                  titleCells: cells,
                  rowIndex: subTableRowIndex,
                  parentRowIndexForSubTable: cellIndex,
                  prefixSubTitle,
                }),
              } as RowType;
            }
          );

          cell = {
            ...cell,
            cellLinkedData: {},
            subTable: {
              ...cell.subTable,
              rows: subTableRows,
            },
          };
        }

        return cell;
      });
    },
    [getTitleCell]
  );

  const modifyPropertiesModuleChillerForAllCell = useCallback(
    (
      selectedCell: CellType,
      titleIndex: number,
      columnIndex: number,
      components?: TemplateComponent[]
    ) => {
      const currentRows =
        [...(componentSelected.detail?.rows || [])]?.map((row) => {
          return {
            ...row,
            cells: row.cells?.map((c) => {
              if (c.cellId === selectedCell.cellId) {
                return selectedCell;
              }

              return c;
            }),
          };
        }) || [];

      const newComponentSelected = {
        ...componentSelected,
        detail: {
          ...componentSelected.detail,
          rows: [...currentRows],
        },
      };

      const newComponents = (components || []).map((component) => {
        if (component.componentId === newComponentSelected.componentId) {
          return newComponentSelected;
        }
        if (!component.linkedHeaderId) {
          return component;
        }

        const listRow = [...(component?.detail?.rows ?? [])];
        const newRows = listRow.map((row) => {
          if (row.cells) {
            return {
              ...row,
              cells: setTitleCellBySpecifiedColumn({
                titleIndex,
                columnIndex,
                selectedCell,
                cells: row.cells,
                titleCells: row.cells,
                prefixSubTitle: "",
              }),
            };
          }

          return row;
        });

        return {
          ...component,
          detail: {
            ...component?.detail,
            rows: newRows,
          },
        };
      });

      dispatch(setComponentSelected(newComponentSelected));
      dispatch(setComponents(newComponents));
    },
    [componentSelected, dispatch, setTitleCellBySpecifiedColumn]
  );

  const modifyPropertiesOfSelectedCells = (data: Partial<CellType>) => {
    const newTable = modifyProperties(
      componentSelected,
      currentTemplate,
      selectedCells,
      data
    );

    onDispatchComponents({ newData: newTable, components });
  };

  const handleChangeCellProperty = (value: string) => {
    const allCells = components
      .map((component) => getAllCells(component))
      .flat(1)
      ?.filter((cell) => cell.cellProperty === CellProperty.DOCUMENT_DATA);

    const allCellOrder = allCells?.map(
      (cell) => cell?.cellLinkedData?.options?.blackboard?.order || 0
    );
    const lastOrder = !!allCellOrder?.length ? Math.max(...allCellOrder) : 0;
    const opt: Partial<CellType> = isBlackboardTemplate
      ? {
          cellLinkedData: {
            options: { blackboard: { order: lastOrder + 1 } },
          },
        }
      : {
          cellLinkedData: undefined,
        };

    const cell: CellType = {
      ...selectedCells[0],
      cellProperty: value,
      ...opt,
    };

    dispatch(setSelectedCell([cell]));
    modifyPropertiesOfSelectedCells({
      cellProperty: value,
      ...opt,
      value: "",
    });
  };

  const handleSelectSubcategory = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const isChecked = event?.target.checked;

      const updatedComponents = components?.map((component) => {
        if (
          componentSelected.componentId === component.componentId &&
          component.type === TemplateComponentType.Text
        ) {
          return {
            ...component,
            isLinkedHeader: isChecked,
          };
        }

        return component;
      });

      dispatch(setComponents(updatedComponents as TemplateComponent[]));
    },
    [componentSelected.componentId, components, dispatch]
  );

  const handleChangeSubcategory = useCallback(
    (subcategoryId: string) => {
      const updatedComponents = components?.map((component) => {
        if (component?.componentId === componentSelected?.componentId) {
          const subcategoryIdSelected = subcategoryId;

          const listRow = component.detail?.rows?.map((row) => {
            const newCells: CellType[] = (row?.cells || [])?.map((cell) => ({
              ...cell,
              subcategoryIdSelected,
            }));

            return {
              ...row,
              cells: newCells,
            };
          });

          return {
            ...component,
            detail: {
              ...component.detail,
              rows: listRow,
            },
            subcategoryIdSelected,
          };
        }

        return component;
      });

      dispatch(setComponents(updatedComponents as TemplateComponent[]));
    },
    [componentSelected?.componentId, components, dispatch]
  );

  const handleChangeAdjustChangeFont = useCallback(() => {
    const updatedComponents = components?.map((component) => {
      if (component?.componentId === componentSelected?.componentId) {
        return {
          ...component,
          detail: {
            ...component.detail,
            allowDynamicAdjustFontSize:
              !component.detail?.allowDynamicAdjustFontSize,
          },
        };
      }

      return component;
    });

    dispatch(
      setComponentSelected({
        ...componentSelected,
        detail: {
          ...componentSelected.detail,
          allowDynamicAdjustFontSize:
            !componentSelected.detail?.allowDynamicAdjustFontSize,
        },
      })
    );

    dispatch(setComponents(updatedComponents as TemplateComponent[]));
  }, [componentSelected, components, dispatch]);

  const maxDisplayOrderOfSubcategory = useMemo(() => {
    return (
      components.filter((component) => !!component.isLinkedHeader)?.length || 1
    );
  }, [components]);

  const displayOrderOfSubcategory = useMemo(() => {
    return componentSelected.detail?.displayOrder
      ? `${componentSelected.detail?.displayOrder}`
      : "1";
  }, [componentSelected.detail?.displayOrder]);

  const handleChangeDisplayOrderOfSubcategory = (_: string, value: number) => {
    const updatedComponents = components.map((component) => {
      if (component.componentId === componentSelected.componentId) {
        return {
          ...component,
          detail: {
            ...component.detail,
            displayOrder: value,
          },
        };
      }

      return component;
    });

    dispatch(setComponents(updatedComponents as TemplateComponent[]));
  };

  return {
    components,
    componentSelected,
    selectedCells,
    isOpenAttachLink,
    isOpenMergeCell,
    isOpenSplitCell,
    isOpenRemoveRowOrColumn,
    isTable,
    tableSize,
    style,
    documentContainerSize,
    isDisableAddColumn,
    isLinkedHeader,
    isShowSelectAreas,
    subcategories,
    isFlexibleDuct: isSelfInspection,
    currentTemplate,
    selectedArea,
    mergeMessageType,
    removeMessageType,
    ratio,
    isFilterPhoto,
    minCellSizeHeight,
    minCellSizeWidth,
    getCurrentMergedCell,
    maxDisplayOrderOfSubcategory,
    displayOrderOfSubcategory,
    handleUpdateCellStyle,
    onCloseAttachLink,
    onCloseMergeCell,
    onCloseSplitCell,
    onCloseRemoveRowOrColumn,
    handleChangeCellProperty,
    handleChangeAdjustChangeFont,
    handleSelectSubcategory,
    handleChangeSubcategory,
    handleSubmitMergeCells,
    handleSplitCell,
    checkValidPosition,
    onOpenAttachLink,
    setSelectedArea,
    setMergeMessageType,
    onOpenMergeCell,
    setRemoveMessageType,
    onOpenRemoveRowOrColumn,
    onOpenSplitCell,
    modifyPropertiesOfSelectedCells,
    modifyPropertiesModuleChillerForAllCell,
    handleChangeDisplayOrderOfSubcategory,
  };
}
