import { documentTaskApi } from "apiClient/v2";
import { message } from "components/base";
import { BASE_S3_URL } from "constants/app";
import {
  PaperDirectionType,
  PaperSize,
  PaperType,
  PHOTO_BOOK_CONTENT_TYPE,
} from "constants/enum";
import { DISPLAY_MODE } from "constants/forge";
import { S3_PATH } from "constants/s3";
import { DOM_TO_IMAGE_SCALE_VALUE } from "hooks/useDownloadPdf";
import { TaskDTO } from "interfaces/dtos/taskDTO";
import { EditData } from "interfaces/models";
import {
  DocumentTask,
  DocumentTaskData,
  DocumentTaskDataFieldPhotoBooks,
  MainImageData,
} from "interfaces/models/documentTask";
import jsPDF from "jspdf";
import isEqual from "lodash/isEqual";
import {
  checkDuplicateDocumentTaskTitle,
  checkIsTaskLabelChangeStatusAndPosition,
  getLastIndexDuplicateTitle,
  handleSyncDataKeynoteOtherDocumentTask,
  uploadTaskListToS3,
} from "models/documentTask";
import { domToJpeg } from "modern-screenshot";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDeviceSelectors } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { setIsDownloadPdfOnMobile } from "redux/forgeViewerSlice";
import { RootState } from "redux/store";
import { addDocumentTasks } from "redux/taskSlice";
import { mergeObject, sleep } from "utils/common";
import { formatDate, formatStringDate } from "utils/date";
import { downloadPdfByUrl } from "utils/download-pdf";
import { downloadObject, removeFileS3, uploadFileToS3 } from "utils/file";
import { getCurrentViewer } from "utils/forge";
import { centimetersToPixels } from "utils/measure";
import { getTaskMap } from "utils/task";

interface Props {
  bimFileId: string;
  currentTaskList?: TaskDTO[];
  initTasks?: TaskDTO[];
  documentTasks: DocumentTask[];
  documentTask: DocumentTask;
  sheetOptions:
    | {
        title: string;
        options: { name: string; value: string }[];
      }
    | undefined;
  sheetIdSelected: string;

  onUpdateDocumentTask?: (documentTask: DocumentTask) => void;
  onChangeSheet: (value: string | undefined, isRecapture: boolean) => void;
}

export const DEFAULT_TASK_SHEET_PAGE_WIDTH = centimetersToPixels(
  PaperSize[PaperType.A4].width
);
export const DEFAULT_TASK_SHEET_PAGE_HEIGHT = centimetersToPixels(
  PaperSize[PaperType.A4].height
);

const PHOTO_BOOK_FIELDS: {
  [key: string]: (keyof DocumentTaskDataFieldPhotoBooks)[];
} = {
  [PHOTO_BOOK_CONTENT_TYPE.REPORT]: [
    "confirmedMemo",
    "endDateScheduled",
    "confirmedDateTime",
    "userConfirmed",
  ],

  [PHOTO_BOOK_CONTENT_TYPE.INSTRUCTION]: [
    "title",
    "memo",
    "deadline",
    "userAssigned",
    "partnerCompany",
  ],
};

const useTaskSheet = ({
  bimFileId,
  currentTaskList,
  initTasks,
  documentTasks,
  documentTask,
  sheetIdSelected,
  sheetOptions,
  onUpdateDocumentTask,
  onChangeSheet,
}: Props) => {
  const [zoomPageValue, setZoomPageValue] = useState(1);
  const { currentUser } = useSelector((state: RootState) => state.user);
  const { projectDetail } = useSelector((state: RootState) => state.project);
  const [loadingDownload, setLoadingDownload] = useState<boolean>(false);
  const [loadingPrint, setLoadingPrint] = useState<boolean>(false);
  const [loadingEdit, setLoadingEdit] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [documentTaskData, setDocumentTaskData] = useState<DocumentTaskData>(
    null as any
  );
  const [documentTaskDataChange, setDocumentTaskDataChange] =
    useState<DocumentTaskData>({});
  const [tasks, setTasks] = useState<TaskDTO[] | null>(null);
  const [isCheckStatusDone, setIsCheckStatusDone] = useState(false);
  const [isDiffTasksStatus, setIsDiffTasksStatus] = useState(false);

  const zoomPageValueRef = useRef(1);
  const dispatch = useDispatch();
  const [{ isMobile }] = useDeviceSelectors(window.navigator.userAgent);

  const mapTasks = useMemo(() => {
    if (!tasks) {
      return {};
    }

    return getTaskMap(tasks);
  }, [tasks]);

  useEffect(() => {
    (async () => {
      if (!documentTask?.id || sheetOptions === undefined) {
        return;
      }

      if (documentTask?.id && !documentTask?.mainImageData?.guid) {
        onChangeSheet(sheetOptions?.options?.[0]?.value, true);

        return;
      }

      if (
        !sheetOptions?.options?.length &&
        sheetIdSelected !== DISPLAY_MODE["3D"]
      ) {
        return;
      }

      if (!sheetIdSelected) {
        return;
      }

      // for page forge
      if (initTasks) {
        setTasks(initTasks);
        setIsCheckStatusDone(true);

        return;
      }

      const _isSheetNotExists =
        sheetOptions?.options?.findIndex(
          (opt) => opt.value === sheetIdSelected
        ) === -1 && !!sheetOptions?.options?.length;

      const sheetIdDefault = _isSheetNotExists
        ? sheetOptions?.options?.[0]?.value
        : undefined;

      // for page document tasks
      const filePath = `${documentTask.id}.json`;
      const url =
        documentTask.taskListUrl ||
        `${BASE_S3_URL}${S3_PATH.TaskSheet}/${filePath}`;
      let newTasks: TaskDTO[] = await downloadObject(url).catch(() => {
        return;
      });

      // recapture keyplan when s3 error
      if (!newTasks && currentTaskList) {
        const [, taskListUrl] = await Promise.all([
          removeFileS3([url.replace(BASE_S3_URL, "")]),
          uploadTaskListToS3(currentTaskList),
        ]);

        await documentTaskApi.createUpdateDocumentTask({
          ...documentTask,
          taskListUrl,
        });
        onChangeSheet(sheetIdDefault, true);

        return;
      }

      if (currentTaskList) {
        let isShouldSaveTaskListToS3 = false;
        newTasks = newTasks?.map((task) => {
          const currentTask = currentTaskList?.find((t) => t.id === task.id);

          if (currentTask) {
            delete currentTask.dbId;
          }

          if (task) {
            delete task.dbId;
          }

          if (currentTask?.id && !isEqual(currentTask, task)) {
            isShouldSaveTaskListToS3 = true;

            return currentTask;
          }

          return task;
        });

        if (isShouldSaveTaskListToS3) {
          const [, taskListUrl] = await Promise.all([
            removeFileS3([url.replace(BASE_S3_URL, "")]),
            uploadTaskListToS3(newTasks),
          ]);

          await documentTaskApi.createUpdateDocumentTask({
            ...documentTask,
            taskListUrl,
          });
        }

        const {
          isDiffPosition: _isDiffTasksPosition,
          isDiffStatus: _isDiffTasksStatus,
        } = checkIsTaskLabelChangeStatusAndPosition(documentTask, newTasks);

        setIsDiffTasksStatus(
          _isDiffTasksStatus || _isSheetNotExists || _isDiffTasksPosition
        );

        (_isDiffTasksStatus || _isSheetNotExists || _isDiffTasksPosition) &&
          onChangeSheet(sheetIdDefault, true);
      }

      setIsCheckStatusDone(true);
      setTasks(newTasks || []);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    documentTask?.id,
    initTasks,
    currentTaskList,
    sheetOptions,
    sheetIdSelected,
  ]);

  useEffect(() => {
    if (editMode) return;

    setDocumentTaskDataChange({} as DocumentTaskData);
  }, [editMode]);

  const getDefaultDocumentTaskData = useCallback((): DocumentTaskData => {
    return {
      documentTaskInfo: {
        creationDateTime: {
          displayName: "検査日",
          value: formatDate(documentTask.createdAt, "YYYY/MM/DD"),
        },
        inspector: {
          displayName: "検査者",
          value: currentUser?.name,
        },
        examinee: {
          displayName: "受検者",
          value: "高砂熱学工業",
        },
      },
      projectName: {
        origin: `プロジェクト ${projectDetail?.name || ""} 詳細`,
      },
      title: {
        origin: documentTask.title,
      },
      taskDisplayName: {
        taskId: "ID",
        corporateCompany: { origin: "協力会社" },
      },
      tasks: (tasks || [])?.map((item) => ({
        taskId: item.id,
        contentType: {
          origin: item.taskTypeId,
        } as EditData,
        userTreated: {
          origin: item.userTreated,
        } as EditData,
        endDateScheduled: {
          origin: formatDate(item?.endDateScheduled, "YYYY/MM/DD"),
        } as EditData,
        confirmedDateTime: {
          origin: formatDate(item?.confirmedDateTime, "YYYY/MM/DD"),
        } as EditData,
        userConfirmed: {
          origin: item.userConfirmed,
        } as EditData,
        userAssigned: {
          origin: item.userAssigned,
        } as EditData,
        deadline: {
          origin: item?.deadline as any,
        },
        confirmedMemo: {
          origin: item?.confirmedMemo,
        },
        partnerCompany: {
          origin: item?.partnerCompany,
        },
        memo: {
          origin: item.memo,
        } as EditData,
      })),
    };
  }, [
    projectDetail.name,
    currentUser?.name,
    tasks,
    documentTask?.title,
    documentTask?.createdAt,
  ]);

  const getPhotoBooksChangeFromFetchData = useCallback(
    (fetchData: DocumentTaskData) => {
      const photoBooks = structuredClone(fetchData?.photoBooks || {});
      const arrStatusPhotoBooks = Object.keys(photoBooks).map(() => false);

      Object.entries(photoBooks).forEach(([key, photoBook], photoBookIndex) => {
        const task: TaskDTO = mapTasks?.[photoBook?.taskId || ""];
        const photoBookType =
          photoBook.type || PHOTO_BOOK_CONTENT_TYPE.INSTRUCTION;
        const photoBookFields = PHOTO_BOOK_FIELDS[photoBookType];
        const arrStatusDiffData = photoBookFields.map(() => false);

        photoBookFields.forEach((field, index) => {
          const currentContent: EditData = photoBook?.[field] || ({} as any);
          const orginValue = currentContent?.origin;

          let content: EditData = {};
          switch (field) {
            case "deadline":
              arrStatusDiffData[index] =
                !task?.deadline && !orginValue
                  ? false
                  : formatStringDate(task?.deadline as any) !==
                    formatStringDate(orginValue);
              content = arrStatusDiffData[index]
                ? { origin: task?.deadline }
                : currentContent;
              break;

            case "confirmedDateTime":
              arrStatusDiffData[index] =
                !task?.confirmedDateTime && !orginValue
                  ? false
                  : formatStringDate(task?.confirmedDateTime as any) !==
                    formatStringDate(orginValue);
              content = arrStatusDiffData[index]
                ? { origin: task?.confirmedDateTime as any }
                : currentContent;
              break;

            case "endDateScheduled":
              arrStatusDiffData[index] =
                !task?.endDateScheduled && !orginValue
                  ? false
                  : formatStringDate(task?.endDateScheduled) !==
                    formatStringDate(orginValue);
              content = arrStatusDiffData
                ? { origin: task?.endDateScheduled }
                : currentContent;
              break;

            case "title":
              arrStatusDiffData[index] =
                !task?.taskTypeId && !orginValue
                  ? false
                  : task?.taskTypeId !== orginValue;
              content = arrStatusDiffData[index]
                ? { origin: task?.taskTypeId }
                : currentContent;
              break;

            default:
              arrStatusDiffData[index] =
                !task?.[field as keyof TaskDTO] && !orginValue
                  ? false
                  : task?.[field as keyof TaskDTO] !== orginValue;
              content = arrStatusDiffData[index]
                ? { origin: task?.[field as keyof TaskDTO] as any }
                : currentContent;
              break;
          }

          photoBooks[key] = {
            ...(photoBooks?.[key] || {}),
            [field]: content,
          };
        });

        arrStatusPhotoBooks[photoBookIndex] = arrStatusDiffData.some(
          (stt) => stt
        );
      });

      return {
        photoBooks,
        isUpdatedPhotoBooks: arrStatusPhotoBooks.some((stt) => stt),
      };
    },
    [mapTasks]
  );

  const getTasksChangeFromFetchData = useCallback(
    (fetchData: DocumentTaskData) => {
      return fetchData.tasks?.map((item) => {
        const task: TaskDTO = mapTasks[item.taskId || ""];

        const contentType = task?.taskTypeId;
        const {
          userTreated,
          endDateScheduled,
          confirmedDateTime,
          userConfirmed,
          deadline,
          partnerCompany,
          confirmedMemo,
          userAssigned,
          memo,
        } = task ?? {};

        return {
          ...item,
          contentType:
            contentType !== item.contentType?.origin
              ? {
                  origin: contentType,
                  editValue: undefined,
                }
              : item.contentType,
          userTreated:
            userTreated !== item.userTreated?.origin
              ? {
                  origin: userTreated,
                  editValue: undefined,
                }
              : item.userTreated,
          endDateScheduled:
            formatDate(endDateScheduled, "YYYY/MM/DD") !==
            item?.endDateScheduled?.origin
              ? {
                  origin: endDateScheduled,
                  editValue: undefined,
                }
              : item?.endDateScheduled,
          confirmedDateTime:
            formatDate(confirmedDateTime, "YYYY/MM/DD") !==
            item?.confirmedDateTime?.origin
              ? {
                  origin: formatDate(confirmedDateTime, "YYYY/MM/DD"),
                  editValue: undefined,
                }
              : item?.confirmedDateTime,
          userConfirmed:
            userConfirmed !== item.userConfirmed?.origin
              ? {
                  origin: userConfirmed,
                  editValue: undefined,
                }
              : item.userConfirmed,
          deadline:
            formatDate(deadline, "YYYY/MM/DD") !== item?.deadline?.origin
              ? {
                  origin: formatDate(deadline, "YYYY/MM/DD"),
                  editValue: undefined,
                }
              : item?.deadline,
          userAssigned:
            userAssigned !== item.userAssigned?.origin
              ? {
                  origin: userAssigned as any,
                  editValue: undefined,
                }
              : item.userAssigned,
          partnerCompany:
            partnerCompany !== item.partnerCompany?.origin
              ? {
                  origin: partnerCompany,
                  editValue: undefined,
                }
              : item.partnerCompany,
          confirmedMemo:
            confirmedMemo !== item.confirmedMemo?.origin
              ? {
                  origin: confirmedMemo,
                  editValue: undefined,
                }
              : item.confirmedMemo,
          memo:
            memo !== item.memo?.origin
              ? {
                  origin: memo,
                  editValue: undefined,
                }
              : item.memo,
        };
      });
    },
    [mapTasks]
  );

  const checkIsUpdateTasks = useCallback(
    (fetchData: DocumentTaskData) => {
      return (fetchData?.tasks || [])?.some((item) => {
        const task: TaskDTO = mapTasks[item.taskId || ""];

        const {
          taskTypeId,
          userTreated,
          endDateScheduled,
          confirmedDateTime,
          userConfirmed,
          memo,
          deadline,
          confirmedMemo,
          partnerCompany,
          userAssigned,
        } = task ?? {};

        const isDiffContentType =
          taskTypeId !== item.contentType?.origin &&
          (!!taskTypeId || !!item.contentType?.origin);
        const isDiffUserTreated =
          userTreated !== item.userTreated?.origin &&
          (!!userTreated || !!item.userTreated?.origin);
        const isDiffEndDate =
          formatStringDate(endDateScheduled) !==
          formatStringDate(item?.endDateScheduled?.origin);
        const isDiffConfirmDateTime =
          formatStringDate(confirmedDateTime as any) !==
          formatStringDate(item?.confirmedDateTime?.origin);
        const isDiffUserConfirmed =
          userConfirmed !== item.userConfirmed?.origin &&
          (!!userConfirmed || !!item.userConfirmed?.origin);
        const isDiffMemo =
          memo !== item.memo?.origin && (!!memo || !!item.memo?.origin);
        const isDiffConfirmedMemo =
          confirmedMemo !== item.confirmedMemo?.origin &&
          (!!confirmedMemo || !!item.confirmedMemo?.origin);
        const isDiffDeadline =
          formatStringDate(deadline) !==
          formatStringDate(item?.deadline?.origin);
        const isDiffUserAssigned =
          userAssigned !== item.userAssigned?.origin &&
          (!!userAssigned || !!item.userAssigned?.origin);
        const isDiffPartnerCompany =
          partnerCompany !== item.partnerCompany?.origin &&
          (!!partnerCompany || !!item.partnerCompany?.origin);

        return (
          isDiffPartnerCompany ||
          isDiffUserAssigned ||
          isDiffDeadline ||
          isDiffConfirmedMemo ||
          isDiffContentType ||
          isDiffUserTreated ||
          isDiffEndDate ||
          isDiffConfirmDateTime ||
          isDiffUserConfirmed ||
          isDiffMemo
        );
      });
    },
    [mapTasks]
  );

  const handleSaveDataAfterUploadChangedToS3 = useCallback(
    async ({
      newDocumentTask,
      isSaveKeynoteMoved = false,
      onUpdateDocumentTask,
    }: {
      newDocumentTask: DocumentTask;
      isSaveKeynoteMoved?: boolean;
      onUpdateDocumentTask?: (documentTask: DocumentTask) => void;
    }) => {
      onUpdateDocumentTask?.(newDocumentTask);

      const [res, documentTasksSync] = await Promise.all([
        documentTaskApi.createUpdateDocumentTask(newDocumentTask),
        handleSyncDataKeynoteOtherDocumentTask({
          mainImageData: isSaveKeynoteMoved
            ? newDocumentTask?.mainImageData || {}
            : undefined,
          currentDocumentTask: newDocumentTask,
          bimFileId,
        }),
      ]);
      dispatch(addDocumentTasks([res, ...documentTasksSync]));
    },
    [bimFileId, dispatch]
  );

  const uploadChangedToS3 = useCallback(
    async ({
      changedData,
      title,
      mainImageData,
      onUpdateDocumentTask,
    }: {
      changedData: DocumentTaskData;
      title?: string;
      mainImageData?: MainImageData;
      onUpdateDocumentTask?: (documentTask: DocumentTask) => void;
    }) => {
      let newTitle = title || documentTask.title;

      const isDuplicateTitle = await checkDuplicateDocumentTaskTitle({
        title: newTitle,
        bimFileId,
      });

      if (isDuplicateTitle && newTitle !== documentTask.title) {
        const lastIndex = getLastIndexDuplicateTitle({
          titleDuplicate: newTitle,
          documentTasks,
          currentDocumentTask: documentTask,
        });
        newTitle = `${newTitle} (${lastIndex + 1})`;
      }

      const filePath = `${documentTask.id}-${Date.now()}.json`;
      const changedDataUrl =
        (await uploadFileToS3(
          new File([JSON.stringify(changedData)], filePath),
          filePath,
          S3_PATH.Task,
          { keepOriginName: true }
        )) || "";
      const newDocumentTask: DocumentTask = {
        ...documentTask,
        detailDataUrl: changedDataUrl,
        title: newTitle,
        mainImageData: mainImageData
          ? mainImageData
          : documentTask.mainImageData,
        createdAt: onUpdateDocumentTask ? new Date() : documentTask.createdAt,
      };

      handleSaveDataAfterUploadChangedToS3({
        newDocumentTask,
        isSaveKeynoteMoved: !!mainImageData,
        onUpdateDocumentTask,
      });
    },
    [
      documentTasks,
      documentTask,
      bimFileId,
      handleSaveDataAfterUploadChangedToS3,
    ]
  );

  useEffect(() => {
    if (!documentTask?.id || !Object.keys(mapTasks).length) {
      return;
    }

    let initDocumentTaskData = getDefaultDocumentTaskData() as DocumentTaskData;
    if (!documentTask?.detailDataUrl) {
      setDocumentTaskData(initDocumentTaskData);

      return;
    }

    // check documentTask data from url
    (async () => {
      const fetchData = (await downloadObject(
        documentTask.detailDataUrl!
      ).catch(() => undefined)) as DocumentTaskData | undefined;

      if (!fetchData) {
        setDocumentTaskData(initDocumentTaskData);
        uploadChangedToS3({ changedData: initDocumentTaskData });

        return;
      }

      const { photoBooks, isUpdatedPhotoBooks } =
        getPhotoBooksChangeFromFetchData(fetchData);
      const isUpdateTasks = checkIsUpdateTasks(fetchData);
      if (!fetchData?.tasks) {
        fetchData.tasks = initDocumentTaskData.tasks;
      }

      if (!isUpdateTasks && !isUpdatedPhotoBooks) {
        setDocumentTaskData(fetchData);

        return;
      }

      initDocumentTaskData = fetchData;
      if (isUpdateTasks) {
        initDocumentTaskData.tasks =
          getTasksChangeFromFetchData(fetchData) || [];
      }
      if (isUpdatedPhotoBooks) {
        initDocumentTaskData.photoBooks = photoBooks;
      }

      setDocumentTaskData(initDocumentTaskData);
      uploadChangedToS3({ changedData: initDocumentTaskData });
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    documentTask?.detailDataUrl,
    documentTask?.id,
    checkIsUpdateTasks,
    projectDetail?.name,
    mapTasks,
  ]);

  const onEditDocumentTask = async (mainImageData?: MainImageData) => {
    setLoadingEdit(true);
    if (Object.keys(documentTaskDataChange).length === 0) {
      if (mainImageData) {
        const newDocumentTask: DocumentTask = {
          ...documentTask,
          mainImageData: mainImageData,
        };

        await handleSaveDataAfterUploadChangedToS3({
          newDocumentTask,
          isSaveKeynoteMoved: true,
          onUpdateDocumentTask,
        });
      }

      setLoadingEdit(false);
      setEditMode(false);

      return;
    }

    const photoBooksDataChange: {
      [key: string]: DocumentTaskDataFieldPhotoBooks;
    } = {};
    Object.entries(documentTaskDataChange?.photoBooks || {}).forEach(
      ([key, value]) => {
        const currentData = documentTaskData?.photoBooks?.[key];
        const photoBookType =
          value?.type || PHOTO_BOOK_CONTENT_TYPE.INSTRUCTION;

        photoBooksDataChange[key] = {
          ...photoBooksDataChange[key],
          taskId: value?.taskId,
          type: value?.type,
          ...(photoBookType === PHOTO_BOOK_CONTENT_TYPE.INSTRUCTION
            ? {
                instructionStyle: mergeObject(
                  currentData?.instructionStyle || {},
                  value?.instructionStyle || {}
                ),
              }
            : {
                reportStyle: mergeObject(
                  currentData?.reportStyle || {},
                  value?.reportStyle || {}
                ),
              }),
        };

        const photoBookFields = PHOTO_BOOK_FIELDS[photoBookType];
        photoBookFields.forEach((field) => {
          const valueAfterMerge = mergeObject(
            currentData?.[field] || {},
            value?.[field] || {}
          );
          photoBooksDataChange[key] = {
            ...(photoBooksDataChange?.[key] || {}),
            [field]: valueAfterMerge,
          };
        });
      }
    );

    const taskChangeMap = getTaskMap(documentTaskDataChange.tasks || []);
    const photoBooks = {
      ...(documentTaskData?.photoBooks || {}),
      ...(photoBooksDataChange || {}),
    };

    const changedData = {
      ...documentTaskData,
      photoBooks,
      documentTaskInfo: {
        ...documentTaskData.documentTaskInfo,
        creationDateTime: mergeObject(
          documentTaskData.documentTaskInfo?.creationDateTime,
          documentTaskDataChange.documentTaskInfo?.creationDateTime
        ),
        inspector: mergeObject(
          documentTaskData.documentTaskInfo?.inspector,
          documentTaskDataChange.documentTaskInfo?.inspector
        ),
        examinee: mergeObject(
          documentTaskData.documentTaskInfo?.examinee,
          documentTaskDataChange.documentTaskInfo?.examinee
        ),
      },
      projectName:
        documentTaskDataChange.projectName || documentTaskData.projectName,
      title: documentTaskDataChange.title || documentTaskData.title,
      taskDisplayName: mergeObject(
        documentTaskData.taskDisplayName,
        documentTaskDataChange.taskDisplayName
      ),
      tasks: documentTaskData.tasks?.map((item) => {
        const changedItem = taskChangeMap[item.taskId || ""];

        return changedItem
          ? {
              ...item,
              contentType: mergeObject(
                item.contentType,
                changedItem.contentType
              ),
              userTreated: mergeObject(
                item.userTreated,
                changedItem.userTreated
              ),
              endDateScheduled: mergeObject(
                item?.endDateScheduled,
                changedItem?.endDateScheduled
              ),
              confirmedDateTime: mergeObject(
                item?.confirmedDateTime,
                changedItem?.confirmedDateTime
              ),
              userConfirmed: mergeObject(
                item.userConfirmed,
                changedItem.userConfirmed
              ),
              memo: mergeObject(item.memo, changedItem.memo),
              confirmedMemo: mergeObject(
                item.confirmedMemo,
                changedItem.confirmedMemo
              ),
              partnerCompany: mergeObject(
                item.partnerCompany,
                changedItem.partnerCompany
              ),
              userAssigned: mergeObject(
                item.userAssigned,
                changedItem.userAssigned
              ),
              deadline: mergeObject(item.deadline, changedItem.deadline),
            }
          : item;
      }),
    } as DocumentTaskData;

    const newTitle =
      documentTaskDataChange.title?.editValue ||
      documentTaskData.title?.editValue ||
      documentTask.title;

    await uploadChangedToS3({
      changedData,
      title: newTitle,
      onUpdateDocumentTask,
      mainImageData,
    });
    setDocumentTaskData(changedData);
    setDocumentTaskDataChange({});
    setLoadingEdit(false);
    setEditMode(false);
  };

  const getPageDirectionByPageIndex = (pageIndex: number) => {
    return document
      .getElementById(`page-${pageIndex}`)
      ?.getAttribute("page-direction") === PaperDirectionType.HORIZONTAL
      ? "l"
      : "p";
  };

  const getPageSizeByPageIndex = (pageIndex: number) => {
    return document
      .getElementById(`page-${pageIndex}`)
      ?.getAttribute("page-size") === PaperType.A4
      ? "a4"
      : "a3";
  };

  const closeLoading = async (isPrint = false) => {
    if (isMobile) {
      const timeOut = (await sleep(3000)) as any;

      dispatch(setIsDownloadPdfOnMobile(false));
      clearTimeout(timeOut);
    }

    if (isPrint) {
      setLoadingPrint(false);
    } else {
      setLoadingDownload(false);
    }
  };

  const onDownloadPDF =
    (isPrint = false) =>
    async () => {
      const inputs = document.querySelectorAll(".download-PDF-task-sheet");
      try {
        if (!inputs.length) {
          message.error("データがありません")

          return;
        }

        const viewer = getCurrentViewer();
        if (isMobile && viewer) {
          dispatch(setIsDownloadPdfOnMobile(true));
        }

        if (isPrint) {
          setLoadingPrint(true);
        } else {
          setLoadingDownload(true);
        }
        setZoomPageValue(1);
        const timeOut = (await sleep(100)) as any; // wait reset zoom page
        const firstPageEle = document.getElementById("page-0");
        const fristPageDirection =
          firstPageEle?.getAttribute("page-direction") ===
          PaperDirectionType.HORIZONTAL
            ? "l"
            : "p";
        const firstPageSize =
          firstPageEle?.getAttribute("page-size") === PaperType.A4
            ? "a4"
            : "a3";
        const pdf: any = new jsPDF(fristPageDirection, "px", firstPageSize);
        const arrayNodes = Array.from(inputs);

        for await (const [index, node] of arrayNodes.entries()) {
          const newWidth = pdf.internal.pageSize.getWidth();
          const newHeight = pdf.internal.pageSize.getHeight();

          const image = await domToJpeg(node, {
            scale: DOM_TO_IMAGE_SCALE_VALUE,
            font: !isMobile as any,
            workerUrl: "/modern-screenshot@4.4.31_dist_worker.js",
          });

          pdf.addImage(
            image,
            "jpeg",
            0,
            0,
            newWidth,
            newHeight,
            undefined,
            "FAST"
          );

          if (index !== inputs.length - 1) {
            const currentPageDirection = getPageDirectionByPageIndex(index);
            const currentPageSize = getPageSizeByPageIndex(index);
            const nextPageSize = getPageSizeByPageIndex(index + 1);
            const nextPageDirection = getPageDirectionByPageIndex(index + 1);
            const isLastPage = index + 1 !== inputs.length;

            if (
              (currentPageDirection !== nextPageDirection ||
                currentPageSize !== nextPageSize) &&
              isLastPage
            ) {
              pdf.addPage(null, nextPageDirection, nextPageSize);
            } else {
              pdf.addPage(null, currentPageDirection, currentPageSize);
            }
          }
        }

        setZoomPageValue(zoomPageValueRef.current);
        timeOut && clearTimeout(timeOut);

        if (isPrint) {
          closeLoading(isPrint);
          window.open(pdf.output("bloburl"), "_blank");

          return;
        }

        const blob = pdf.output("bloburl");
        await downloadPdfByUrl(blob, `${documentTask.title}.pdf`);
        closeLoading(isPrint);
      } finally {
      }
    };

  const onChangeZoomPage = useCallback(
    (_: string, value: number, __?: string, inputType?: string) => {
      let newValue = value;
      if (isNaN(value) || value <= 50) {
        newValue = 50;
      }

      const val = newValue / 100;

      zoomPageValueRef.current = val;
      setZoomPageValue(val);
    },
    []
  );

  return {
    tasks,
    zoomPageValue,
    loadingDownload,
    loadingPrint,
    loadingEdit,
    zoomPageValueRef,
    editMode,
    documentTaskData,
    documentTaskDataChange,
    onDownloadPDF,
    setEditMode,
    onEditDocumentTask,
    setDocumentTaskDataChange,
    onChangeZoomPage,
    setTasks,
    setZoomPageValue,
    isCheckStatusDone,
    isDiffTasksStatus,
  };
};

export default useTaskSheet;
