import {
  Badge,
  Box,
  Checkbox,
  Flex,
  Stack,
  Text,
  useBoolean,
} from "@chakra-ui/react";
import { SvgIcon } from "components/SvgIcon";
import ForgeToolbarButton from "components/ui/ForgeViewer/ForgeToolbarButton";
import ForgeToolbarButtonGroup from "components/ui/ForgeViewer/ForgeToolbarButtonGroup";
import {
  DocumentCategoryStatusType,
  InspectionItemType,
  MapDocumentCategoryStatusType,
  MapDocumentCategoryStatusTypeColor,
  MapInspectionItemIcon,
  MapInspectionItemType,
  ModalType,
  SystemModeType,
} from "constants/enum";
import {
  ALL_LEVEL_LABEL,
  DISPLAY_MODE,
  GRAY_OUT_COLOR_VECTOR4,
  LEVEL_OTHER_ID,
  NavToolType,
  SectionToolType,
  SECTION_TOOL_ICONS,
} from "constants/forge";
import { getColorTextByStatus } from "models/document";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDeviceSelectors } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  setDocumentCategorySelected,
  setDocumentGroupSelected,
  setDocumentItemSelected,
  setDocumentSubCategorySelected,
} from "redux/documentSlice";
import {
  setCreateTask,
  setModalType,
  switchDisplayMode,
  setIsShowArea,
} from "redux/forgeViewerSlice";
import { RootState } from "redux/store";
import { updateUserSetting } from "redux/userSlice";
import {
  getCurrentViewer,
  setCameraToHomeAsync,
  showElements,
} from "utils/forge";
import { clearSelectedLabel } from "utils/forge/extensions/custom-label";
import {
  DisplayDocumentSettings,
  DisplayTaskSettings,
} from "utils/forge/extensions/custom-label/constant";

interface Props {
  isLoadedExternalId: boolean;
  isLoadedSheetTransformRatio: boolean;
  isLoadedViewerModelData: boolean;
  handleChangeSheet: (id: string) => Promise<void>;
}

export default function useForgeToolbar({
  isLoadedExternalId,
  isLoadedSheetTransformRatio,
  isLoadedViewerModelData,
  handleChangeSheet,
}: Props) {
  const dispatch = useDispatch();
  const {
    levelSelected,
    isCreateTask,
    displayMode,
    systemMode,
    isLoadedViewer,
    isLoadedNeptuneAreas,
    isLoadedSpaces,
    isShowArea,
    neptuneAreas,
    spaces,
  } = useSelector((state: RootState) => state.forgeViewer);
  const {
    documentItemSelected,
    documentCategorySelected,
    documentSubCategorySelected,
  } = useSelector((state: RootState) => state.document);
  const { isOnline, syncDataOption } = useSelector(
    (state: RootState) => state.app
  );
  const { settings } = useSelector((state: RootState) => state.user);
  const [{ isMobile }] = useDeviceSelectors(window.navigator.userAgent);
  const [is3dView, setIs3dView] = useBoolean(
    !levelSelected.guid || !levelSelected.sheets?.length
  );
  const [currentNavTool, setCurrentNavTool] = useState<
    NavToolType | undefined
  >();
  const [isActiveSectionTool, activeSectionTool] = useBoolean();
  const [isShowMainModelElements, setIsShowMainModelElements] =
    useState<boolean>(true);
  const [isShowLinkedModelElements, setIsShowLinkedModelElements] =
    useState<boolean>(true);

  const { projectBimFileId } = useParams();

  const switchView = useCallback(() => {
    if (!levelSelected?.guid || levelSelected?.label === ALL_LEVEL_LABEL) {
      setIs3dView.toggle();
    } else {
      dispatch(switchDisplayMode());
    }
  }, [dispatch, levelSelected, setIs3dView]);

  const isAreaBtnDisabled = useMemo(() => {
    if (!isLoadedViewer || !isLoadedNeptuneAreas || !isLoadedSpaces) {
      return true;
    }

    const levelAreas = neptuneAreas.filter(
      (area) => area.level === levelSelected.label
    );
    const levelSpaces = spaces.filter(
      (space) => space.level === levelSelected.label
    );

    if (!levelAreas.length || !levelSpaces.length) {
      return true;
    }

    return false;
  }, [
    spaces,
    neptuneAreas,
    isLoadedNeptuneAreas,
    isLoadedSpaces,
    isLoadedViewer,
    levelSelected.label,
  ]);

  const changeDisplayModeButton = useMemo(() => {
    const modelCached =
      syncDataOption?.mapModelCached?.[projectBimFileId || ""]?.[
        levelSelected?.label || ""
      ];
    const modelType = modelCached?.modelType || [];
    const isDisabledWhenOffline =
      !isOnline &&
      displayMode === DISPLAY_MODE["2D"] &&
      !!projectBimFileId &&
      !modelType.includes(DISPLAY_MODE["3D"]);
    const isDisabled =
      levelSelected.guid === LEVEL_OTHER_ID ||
      (displayMode === DISPLAY_MODE["2D"] && !levelSelected.guid) ||
      (displayMode === DISPLAY_MODE["3D"] && !levelSelected.sheets?.length) ||
      (!isLoadedViewer && !levelSelected.sheets?.length) ||
      isDisabledWhenOffline;

    const tooltip = `${
      displayMode === DISPLAY_MODE["2D"] ||
      (!is3dView && (!levelSelected.guid || !levelSelected.sheets?.length))
        ? DISPLAY_MODE["3D"]
        : DISPLAY_MODE["2D"]
    }表示モード`;

    return (
      <ForgeToolbarButton
        icon="/img/3d_2d_icon.svg"
        tooltip={tooltip}
        isDisabled={isDisabled}
        onClick={switchView}
        isHoverStyle={!isMobile}
      />
    );
  }, [
    projectBimFileId,
    syncDataOption?.mapModelCached,
    isOnline,
    displayMode,
    is3dView,
    isLoadedViewer,
    isMobile,
    levelSelected.label,
    levelSelected.guid,
    levelSelected.sheets?.length,
    switchView,
  ]);

  const labelDisplaySettingButton = useMemo(() => {
    return (
      <ForgeToolbarButton
        icon="/img/label-display-selection.svg"
        tooltip={`ラベルの設定`}
        popup={
          <Flex
            p="2rem"
            flexDirection="column"
            gap="1rem"
            color="#171717"
            bgColor="#fff"
            boxShadow="1px 3px 10px 0 rgb(0 0 0 / 50%)"
            borderRadius={4}
          >
            <Text fontSize="1.5rem" whiteSpace="nowrap" fontWeight="bold">
              ピン内容の表示変更
            </Text>
            {(() => {
              const displaySettings =
                systemMode === SystemModeType.Task
                  ? DisplayTaskSettings
                  : DisplayDocumentSettings;

              return Object.keys(displaySettings).map((key, index) => (
                <Checkbox
                  key={index}
                  sx={{
                    "[aria-hidden=true][data-checked],[aria-hidden=true][data-checked]:hover":
                      {
                        borderColor: "#009BE0 !important",
                        bgColor: "#009BE0 !important",
                      },
                    "[data-focus]": {
                      boxShadow: "none!important",
                    },
                  }}
                  isChecked={!!(settings as any)?.[key]}
                  onChange={(e) => {
                    dispatch(updateUserSetting({ [key]: e.target.checked }));
                  }}
                >
                  <Text>{(displaySettings as any)[key].label}</Text>
                </Checkbox>
              ));
            })()}
          </Flex>
        }
        isHoverStyle={!isMobile}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile, settings, systemMode]);

  const isSelectItem = useMemo(
    () =>
      documentItemSelected?.id ||
      documentCategorySelected?.id ||
      documentSubCategorySelected?.id,
    [
      documentItemSelected?.id,
      documentCategorySelected?.id,
      documentSubCategorySelected?.id,
    ]
  );

  const resetItemSelected = useCallback(() => {
    if (isSelectItem) {
      clearSelectedLabel();
      dispatch(setDocumentItemSelected());
      dispatch(setDocumentCategorySelected());
      dispatch(setDocumentSubCategorySelected());
      dispatch(setDocumentGroupSelected());
      dispatch(setModalType(ModalType.NONE));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelectItem]);

  const labelFilterButton = useMemo(() => {
    return (
      <ForgeToolbarButton
        icon="/img/label-filter.svg"
        tooltip="ピンをステータスで絞り込む"
        popup={
          <>
            <Flex
              p="2rem"
              flexDirection="column"
              gap="1rem"
              color="#171717"
              bgColor="#fff"
              boxShadow="1px 3px 10px 0 rgb(0 0 0 / 50%)"
              borderRadius={4}
            >
              <Text
                mb="1rem"
                fontSize="1.5rem"
                whiteSpace="nowrap"
                fontWeight="bold"
              >
                ピンをステータスで絞り込む
              </Text>
              {systemMode === SystemModeType.Task ? (
                <>
                  {Object.values(InspectionItemType).map((type, index) => (
                    <Checkbox
                      sx={{
                        "[aria-hidden=true][data-checked],[aria-hidden=true][data-checked]:hover":
                          {
                            borderColor: "#009BE0 !important",
                            bgColor: "#009BE0 !important",
                          },
                        "[data-focus]": {
                          boxShadow: "none!important",
                        },
                      }}
                      key={index}
                      isChecked={settings?.status?.includes(type)}
                      onChange={(e) => {
                        const checked = (e.target as any).checked;
                        let newStatus = [...settings?.status];
                        if (checked) {
                          newStatus.push(type);
                        } else {
                          newStatus = newStatus.filter((t) => t !== type);
                        }
                        dispatch(updateUserSetting({ status: newStatus }));
                      }}
                    >
                      <Flex gap="5px" alignItems="center">
                        <SvgIcon
                          src={
                            MapInspectionItemIcon[type as InspectionItemType]
                          }
                        ></SvgIcon>
                        <Text>
                          {MapInspectionItemType[type as InspectionItemType]}
                        </Text>
                      </Flex>
                    </Checkbox>
                  ))}
                </>
              ) : undefined}
            </Flex>
          </>
        }
        isHoverStyle={!isMobile}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    resetItemSelected,
    isMobile,
    settings?.documentPriority,
    settings?.documentStatus,
    settings?.priority,
    settings?.status,
    systemMode,
  ]);

  const createTaskButton = useMemo(() => {
    const isDisabled = !isLoadedViewerModelData || !isLoadedSheetTransformRatio;

    return (
      <ForgeToolbarButton
        icon="/img/icon-create-task.svg"
        tooltip="指摘追加"
        isActive={isCreateTask}
        isDisabled={isDisabled}
        isHoverStyle={!isMobile}
        onClick={() => {
          const viewer = getCurrentViewer();

          if (currentNavTool === NavToolType.orbit) {
            const orbitTool = (viewer?.toolbar?.getControl("navTools") as any)
              ?.orbitbutton;
            orbitTool?.onClick();

            setCurrentNavTool(undefined);
          }
          if (viewer) {
            viewer.model?.setThemingColor(1, GRAY_OUT_COLOR_VECTOR4, true);
          }
          dispatch(setCreateTask(!isCreateTask));
        }}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isCreateTask,
    isLoadedViewerModelData,
    currentNavTool,
    isLoadedSheetTransformRatio,
  ]);

  const change2dSheetButton = useMemo(() => {
    return (
      <ForgeToolbarButton
        icon="/img/icon-sheet.svg"
        tooltip={`2D Sheets`}
        popup={
          <>
            <Flex
              p="2rem"
              flexDirection="column"
              gap="1rem"
              color="#171717"
              bgColor="#fff"
              boxShadow="1px 3px 10px 0 rgb(0 0 0 / 50%)"
              borderRadius={4}
            >
              <Text fontSize="1.5rem" whiteSpace="nowrap" fontWeight="bold">
                2D Sheets
              </Text>
              <Stack direction="column" maxH="20rem" overflow="auto">
                {levelSelected.sheets?.map((sheet, index) => (
                  <Flex gap="5px" alignItems="center" key={index}>
                    <Checkbox
                      sx={{
                        "[aria-hidden=true][data-checked],[aria-hidden=true][data-checked]:hover":
                          {
                            borderColor: "#009BE0 !important",
                            bgColor: "#009BE0 !important",
                          },
                        "[data-focus]": {
                          boxShadow: "none!important",
                        },
                      }}
                      isChecked={
                        (!levelSelected.sheetGuid && index === 0) ||
                        levelSelected.sheetGuid === sheet.guid
                      }
                      onChange={() => {
                        handleChangeSheet(sheet.guid);
                      }}
                    ></Checkbox>
                    <Text fontSize="1.4rem" whiteSpace="nowrap">
                      {sheet.name}
                    </Text>
                    {sheet.isMissingViewport && (
                      <Badge
                        fontSize="1rem"
                        colorScheme="red"
                        p="5px 5px 3px 5px"
                        borderRadius="10px"
                      >
                        ビューポートなし
                      </Badge>
                    )}
                    {sheet.isIncorrectViewport && (
                      <Badge
                        fontSize="1rem"
                        colorScheme="red"
                        p="5px 5px 3px 5px"
                        borderRadius="10px"
                      >
                        条件満たさないシート（図面枠がない等）
                      </Badge>
                    )}
                  </Flex>
                ))}
              </Stack>
            </Flex>
          </>
        }
        isHoverStyle={!isMobile}
        isStroke
      />
    );
  }, [
    handleChangeSheet,
    isMobile,
    levelSelected.sheetGuid,
    levelSelected.sheets,
  ]);

  const panToolButton = useMemo(() => {
    const viewer = getCurrentViewer();
    const panTool = (viewer?.toolbar?.getControl("navTools") as any)?.panbutton;

    return (
      <ForgeToolbarButton
        icon="/img/hand.svg"
        tooltip="画面移動"
        isDisabled={!isLoadedViewer}
        isActive={currentNavTool === NavToolType.pan}
        onClick={() => {
          panTool?.onClick();
          if (currentNavTool === NavToolType.pan) {
            setCurrentNavTool(undefined);
          } else {
            setCurrentNavTool(NavToolType.pan);
          }
        }}
        isHoverStyle={!isMobile}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadedViewer, currentNavTool, isMobile]);

  const orbitToolButton = useMemo(() => {
    const viewer = getCurrentViewer();
    const orbitTool = (viewer?.toolbar?.getControl("navTools") as any)
      ?.orbitbutton;

    return (
      <ForgeToolbarButton
        iconClass="adsk-button-icon adsk-icon-orbit-constrained"
        tooltip="オービット"
        isDisabled={!isLoadedViewer}
        isActive={currentNavTool === NavToolType.orbit}
        onClick={() => {
          orbitTool?.onClick();
          if (currentNavTool === NavToolType.orbit) {
            setCurrentNavTool(undefined);
          } else {
            setCurrentNavTool(NavToolType.orbit);
            dispatch(setCreateTask(false));
          }
        }}
        isHoverStyle={!isMobile}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadedViewer, currentNavTool, isMobile]);

  const sectionToolButton = useMemo(() => {
    const viewer = getCurrentViewer();
    const sectionTool = (
      viewer?.toolbar?.getControl("modelTools") as any
    )?.getControl("toolbar-sectionTool");

    const subControl: any[] = [];

    if (sectionTool && sectionTool.subMenu) {
      const numberOfSub = sectionTool.subMenu.getNumberOfControls();
      for (let i = 0; i < numberOfSub; i++) {
        subControl.push(
          sectionTool.subMenu.getControl(sectionTool.subMenu.getControlId(i))
        );
      }
    }

    return (
      <ForgeToolbarButtonGroup
        icon="/img/section-analysis.svg"
        tooltip="断面解析"
        popup={
          <>
            <Flex
              flexDirection="column"
              gap="1rem"
              color="#fff"
              boxShadow="1px 3px 10px 0 rgb(0 0 0 / 50%)"
            >
              {subControl.map((control, index) => (
                <ForgeToolbarButton
                  key={index}
                  icon={SECTION_TOOL_ICONS[control._id as SectionToolType]}
                  tooltip={`${control._toolTipElement.innerText}`}
                  isActive={control._state === 0}
                  onClick={() => {
                    control.onClick();
                    activeSectionTool.toggle();
                  }}
                  isStroke={index !== 2}
                />
              ))}
            </Flex>
          </>
        }
        isDisabled={!isLoadedViewerModelData || !subControl.length}
        isActive={sectionTool?._state === 0}
        onClick={() => {
          sectionTool?.onClick();
          activeSectionTool.toggle();
        }}
        isHoverStyle={!isMobile}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActiveSectionTool, isLoadedViewerModelData, isMobile]);

  const showElementsButton = useMemo(() => {
    const props = {
      tooltip: "モデルの表示",
      isDisabled: !isLoadedViewerModelData || !isLoadedExternalId,
      popup: (
        <>
          <Flex
            p="2rem"
            flexDirection="column"
            gap="1rem"
            color="#171717"
            bgColor="#ffffff"
            boxShadow="1px 3px 10px 0 rgb(0 0 0 / 50%)"
            borderRadius={4}
          >
            <Text fontSize="1.5rem" whiteSpace="nowrap" fontWeight="bold">
              モデルの表示（オン/オフ）
            </Text>
            <Stack direction="column" maxH="20rem" overflow="auto">
              <Flex gap="5px" alignItems="center">
                <Checkbox
                  sx={{
                    "[aria-hidden=true][data-checked],[aria-hidden=true][data-checked]:hover":
                      {
                        borderColor: "#009BE0 !important",
                        bgColor: "#009BE0 !important",
                      },
                    "[data-focus]": {
                      boxShadow: "none!important",
                    },
                  }}
                  isChecked={isShowMainModelElements}
                  onChange={(e) => {
                    const checked = Boolean((e.target as any).checked);
                    setIsShowMainModelElements(checked);
                    showElements(checked, isShowLinkedModelElements);
                  }}
                ></Checkbox>
                <Text fontSize="1.4rem" whiteSpace="nowrap">
                  メインモデル
                </Text>
              </Flex>
              <Flex gap="5px" alignItems="center">
                <Checkbox
                  sx={{
                    "[aria-hidden=true][data-checked],[aria-hidden=true][data-checked]:hover":
                      {
                        borderColor: "#009BE0 !important",
                        bgColor: "#009BE0 !important",
                      },
                    "[data-focus]": {
                      boxShadow: "none!important",
                    },
                  }}
                  isChecked={isShowLinkedModelElements}
                  onChange={(e) => {
                    const checked = Boolean((e.target as any).checked);
                    setIsShowLinkedModelElements(checked);
                    showElements(isShowMainModelElements, checked);
                  }}
                ></Checkbox>
                <Text fontSize="1.4rem" whiteSpace="nowrap">
                  リンクされたモデル
                </Text>
              </Flex>
            </Stack>
          </Flex>
        </>
      ),
      isHoverStyle: !isMobile,
    };

    return (
      <>
        <Box
          display={
            !isShowLinkedModelElements && !isShowMainModelElements
              ? "display"
              : "none"
          }
        >
          <ForgeToolbarButton icon="/img/eye-off.svg" {...props} />
        </Box>
        <Box
          display={
            isShowLinkedModelElements || isShowMainModelElements
              ? "display"
              : "none"
          }
        >
          <ForgeToolbarButton icon="/img/eye.svg" {...props} />
        </Box>
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isLoadedViewerModelData,
    isShowLinkedModelElements,
    isShowMainModelElements,
    isMobile,
    isLoadedExternalId,
  ]);

  const areaButtonContainerRef = useRef<HTMLDivElement>(null);
  const showAreaButton = useMemo(() => {
    const props = {
      isDisabled: isAreaBtnDisabled,
      onClick: () => {
        dispatch(setIsShowArea(!isShowArea));
      },
      isHoverStyle: !isMobile,
      tooltipProps: {
        portalProps: {
          containerRef: areaButtonContainerRef,
        },
      },
    };

    return (
      <Box ref={areaButtonContainerRef}>
        <Box display={isShowArea ? "display" : "none"}>
          <ForgeToolbarButton
            icon="/img/icon-show-area-active.svg"
            isStroke={true}
            tooltip="エリアの非表示"
            svgIconProps={{
              sx: {
                path: {
                  stroke: "#009BE0",
                },
              },
            }}
            {...props}
          />
        </Box>

        <Box display={isShowArea ? "none" : "display"}>
          <ForgeToolbarButton
            icon="/img/icon-show-area.svg"
            tooltip="エリアの表示"
            {...props}
          />
        </Box>
      </Box>
    );
  }, [isShowArea, isAreaBtnDisabled, isMobile, dispatch]);

  const showHomeButton = useMemo(() => {
    return (
      <ForgeToolbarButton
        icon="/img/home-cube.svg"
        tooltip="ホーム"
        onClick={async () => {
          const viewer = getCurrentViewer() as Autodesk.Viewing.GuiViewer3D;
          await setCameraToHomeAsync(viewer);
        }}
        isHoverStyle={!isMobile}
      />
    );
  }, [isMobile]);

  useEffect(() => {
    if (!!levelSelected.guid && levelSelected?.sheets?.length) {
      setIs3dView.off();
    } else {
      setIs3dView.on();
    }
    try {
      showElements(true, true);
      setIsShowMainModelElements(true);
      setIsShowLinkedModelElements(true);
    } catch {}

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [levelSelected.guid, levelSelected.sheetGuid, displayMode]);

  return {
    is3dView,
    setIs3dView,
    isShowMainModelElements,
    isShowLinkedModelElements,
    setIsShowMainModelElements,
    setIsShowLinkedModelElements,
    currentNavTool,
    setCurrentNavTool,
    isActiveSectionTool,
    activeSectionTool,
    orbitToolButton,
    panToolButton,
    change2dSheetButton,
    changeDisplayModeButton,
    labelDisplaySettingButton,
    labelFilterButton,
    createTaskButton,
    sectionToolButton,
    showElementsButton,
    showAreaButton,
    showHomeButton,
  };
}
