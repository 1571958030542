import { documentKeyNoteApi } from "apiClient/v2";
import { BASE_S3_URL } from "constants/app";
import {
  DocumentCategoryStatusType,
  MapDocumentCategoryStatusTypeColor,
} from "constants/enum";
import {
  DISPLAY_MODE,
  FORGE_SHEET_FOLDER_NAME,
  SHEET_PREFIX,
} from "constants/forge";
import { DISABLE_LEFT_SIDEBAR_ID } from "constants/styleProps";
import { DocumentCategoryDTO } from "interfaces/dtos/documentCategoryDTO";
import { DocumentItemDTO } from "interfaces/dtos/documentItemDTO";
import { DocumentSubCategory } from "interfaces/models/documentCategory";
import { DocumentItem } from "interfaces/models/documentItem";
import {
  DocumentKeyNote,
  KeynoteImageData,
} from "interfaces/models/documentKeynote";
import { getUniqDocumentItemStatusFromSubCategory } from "models/document";
import { getKeyLabelProps } from "models/documentTask";
import { getDocumentStatus } from "./document";
import { removeFileS3, uploadFileToS3 } from "./file";
import {
  captureKeynote,
  getDbIdByExternalId,
  getSizeOfModelBoundingBox,
  iSetSelectionMutilColorByDbId,
} from "./forge";

interface CaptureKeynote {
  instances?: DocumentItemDTO[] | DocumentSubCategory[];
  isInstancesDiffKeynotes: boolean;
  currentKeynoteImageData: KeynoteImageData;
  guid: string;
  documentCategory: DocumentCategoryDTO;
  documentKeynote: DocumentKeyNote;
  isMobile?: boolean;
  setIsCreatingKeynote?: (param: boolean) => void;
}

interface CaptureKeynoteData {
  needRecapture?: boolean;
  newKeynoteImageData?: KeynoteImageData;
  dataUpdate?: DocumentKeyNote;
}

export const handleCaptureKeynote = async (
  params: CaptureKeynote
): Promise<CaptureKeynoteData> => {
  const {
    instances,
    isInstancesDiffKeynotes,
    currentKeynoteImageData,
    guid,
    documentCategory,
    documentKeynote,
    setIsCreatingKeynote,
  } = params;
  const disableLeftSidebarElement = document.getElementById(
    DISABLE_LEFT_SIDEBAR_ID
  );
  if (disableLeftSidebarElement) {
    disableLeftSidebarElement.style.display = "flex";
    disableLeftSidebarElement.style.zIndex = "10";
  }

  const selections = instances?.map((item) => {
    const subCategory = documentCategory?.documentSubCategories?.find((sub) =>
      sub.documentItems?.map((i) => i.id).includes(item?.id)
    );

    let { bgColor } = getDocumentStatus((item as DocumentItem)?.status);
    if (subCategory && (subCategory?.documentItems?.length || 0) > 1) {
      const status = getUniqDocumentItemStatusFromSubCategory(subCategory);

      bgColor =
        MapDocumentCategoryStatusTypeColor[
          status as DocumentCategoryStatusType
        ];
    }

    const dbId = getDbIdByExternalId(item.externalId);
    const { r, g, b } = new THREE.Color(bgColor);

    return {
      dbId,
      color: new THREE.Vector4(r, g, b, 1),
    };
  }) as iSetSelectionMutilColorByDbId["selections"];

  if (setIsCreatingKeynote) {
    setIsCreatingKeynote(true);
  }

  const capture = await captureKeynote(selections);

  if (!capture) {
    return { needRecapture: true };
  }

  // remove old image if the instance's different
  if (
    isInstancesDiffKeynotes &&
    currentKeynoteImageData?.mapLabelsByGuid?.[guid]?.imageUrl
  ) {
    removeFileS3([
      currentKeynoteImageData?.mapLabelsByGuid?.[guid]?.imageUrl.replace(
        BASE_S3_URL,
        ""
      ),
    ]);
  }

  // upload image
  const blob = await fetch(capture.blobUrl).then((r) => r.blob());
  const file = new File([blob as Blob], `${SHEET_PREFIX}-${guid}.png`, {
    type: "image/png",
  });

  const imageUrl =
    (await uploadFileToS3(file, file.name || "", FORGE_SHEET_FOLDER_NAME)) ||
    "";

  // calculate keynote's position
  let { width: widthOfModelBoundingBox, height: heightOfModelBoundingBox } =
    getSizeOfModelBoundingBox();
  if (guid === DISPLAY_MODE["3D"]) {
    widthOfModelBoundingBox = capture.imageWidth;
    heightOfModelBoundingBox = capture.imageHeight;
  }

  const offsetX =
    (Number(capture?.canvas?.offsetWidth) - widthOfModelBoundingBox) / 2;
  const offsetY =
    (Number(capture?.canvas?.offsetHeight) - heightOfModelBoundingBox) / 2;
  const imageWidth = widthOfModelBoundingBox;
  const imageHeight = heightOfModelBoundingBox;

  const keyLabelProps = await getKeyLabelProps({
    documentCategory: documentCategory,
    imageWidth: widthOfModelBoundingBox,
    imageHeight: heightOfModelBoundingBox,
    offsetX,
    offsetY,
  });

  const keyLabelsEdited = (
    currentKeynoteImageData?.mapLabelsByGuid?.[guid]?.keyLabelsEdited || []
  ).map((item) => {
    const label = keyLabelProps.find((i) => i.id === item.id)?.label;

    return { ...item, label: label || item.label };
  });

  const newKeynoteImageData: KeynoteImageData = {
    mapLabelsByGuid: {
      ...currentKeynoteImageData?.mapLabelsByGuid,
      [guid]: {
        keyLabelsEdited,
        keyLabels: keyLabelProps,
        imageWidth,
        imageHeight,
        imageUrl,
        offsetX,
        offsetY,
      },
    },
    guid,
  };

  // save document keynote
  let dataUpdate = {
    ...(documentKeynote || {}),
    documentCategoryId: documentCategory?.id,
    keynoteImageData: newKeynoteImageData,
  } as any;

  if (documentKeynote?.id) {
    dataUpdate = await documentKeyNoteApi
      .updateKeynote(dataUpdate)
      .then((res) => res.data);
  } else {
    dataUpdate = await documentKeyNoteApi
      .createKeynote(dataUpdate)
      .then((res) => res.data);
  }

  if (disableLeftSidebarElement) {
    disableLeftSidebarElement.style.display = "none";
    disableLeftSidebarElement.style.zIndex = "0";
  }

  return { newKeynoteImageData, dataUpdate };
};
