import { TaskDTO } from "interfaces/dtos/taskDTO";
import { EditData, FieldData } from "interfaces/models";
import {
  DocumentTask,
  DocumentTaskData,
  DocumentTaskDataFieldInfo,
  DocumentTaskDataFieldPhotoBooks,
  DocumentTaskDataFieldTask,
  DocumentTaskDataFieldTaskDisplayName,
} from "interfaces/models/documentTask";
import {
  checkDuplicateDocumentTaskTitle,
  getLastIndexDuplicateTitle,
} from "models/documentTask";
import { useCallback, useEffect, useRef } from "react";

interface iProps {
  title: string | null;
  bimFileId: string;
  editMode: boolean;
  documentTask: DocumentTask;
  documentTasks: DocumentTask[];
  handleChangeDocumentTaskDetail: (data: DocumentTaskData) => void;
  onChangeTitle: (value: string) => void;
}

interface iOnEditTask {
  editData: EditData;
  task: TaskDTO;
  fieldName: keyof DocumentTaskDataFieldTask;
}

interface iOnEditDocumentTaskInfoSubmit {
  fieldData: FieldData;
  fieldName: keyof DocumentTaskDataFieldInfo;
  isDisplayName?: boolean;
}

interface iOnEditTaskDisplayName {
  editData: EditData;
  fieldName: keyof DocumentTaskDataFieldTaskDisplayName;
}

const useChangeDocumentTaskDetail = ({
  title,
  bimFileId,
  editMode,
  documentTask,
  documentTasks,
  onChangeTitle,
  handleChangeDocumentTaskDetail,
}: iProps) => {
  const documentTaskDataChangeRef = useRef<DocumentTaskData>({});

  useEffect(() => {
    if (editMode) return;

    documentTaskDataChangeRef.current = {} as DocumentTaskData;
  }, [editMode]);

  const onEditDocumentTaskInfoSubmit = useCallback(
    ({ fieldData, fieldName }: iOnEditDocumentTaskInfoSubmit) => {
      const data =
        documentTaskDataChangeRef.current?.documentTaskInfo?.[fieldName] ||
        ({} as any);

      documentTaskDataChangeRef.current = {
        ...documentTaskDataChangeRef.current,
        documentTaskInfo: {
          ...documentTaskDataChangeRef.current.documentTaskInfo,
          [fieldName]: {
            ...data,
            ...fieldData,
          },
        },
      };
      handleChangeDocumentTaskDetail(documentTaskDataChangeRef.current);
    },
    [handleChangeDocumentTaskDetail]
  );

  const onEditPhotoBooksContent = useCallback(
    ({
      data,
      key,
    }: {
      data: DocumentTaskDataFieldPhotoBooks;
      key: string; // key => taskId-tasks'image name
    }) => {
      const photoBooks = documentTaskDataChangeRef.current?.photoBooks;
      const currentPhotoBooksContent = photoBooks?.[key] || {};
      documentTaskDataChangeRef.current = {
        ...documentTaskDataChangeRef.current,
        photoBooks: {
          ...photoBooks,
          [key]: {
            ...currentPhotoBooksContent,
            ...data,
          },
        },
      };
      handleChangeDocumentTaskDetail(documentTaskDataChangeRef.current);
    },
    [handleChangeDocumentTaskDetail]
  );

  const onEditProjectName = useCallback(
    (editData: EditData) => {
      documentTaskDataChangeRef.current = {
        ...documentTaskDataChangeRef.current,
        projectName: editData,
      };
      handleChangeDocumentTaskDetail(documentTaskDataChangeRef.current);
    },
    [handleChangeDocumentTaskDetail]
  );

  const onEditTaskDisplayName = useCallback(
    ({ editData, fieldName }: iOnEditTaskDisplayName) => {
      documentTaskDataChangeRef.current = {
        ...documentTaskDataChangeRef.current,
        taskDisplayName: {
          ...documentTaskDataChangeRef.current.taskDisplayName,
          [fieldName]: editData,
        },
      };
      handleChangeDocumentTaskDetail(documentTaskDataChangeRef.current);
    },
    [handleChangeDocumentTaskDetail]
  );

  const onEditTask = useCallback(
    ({ editData, task, fieldName }: iOnEditTask) => {
      const newTasks = [...(documentTaskDataChangeRef.current.tasks || [])];
      const editedTask = newTasks.find((item) => item.taskId === task.id);
      let newEditTasks;
      if (editedTask) {
        newEditTasks = newTasks.map((item) => ({
          ...item,
          [fieldName]:
            item.taskId === editedTask.taskId
              ? editData
              : item[fieldName as keyof typeof item],
        }));
      } else if (newTasks.length) {
        newTasks.splice(newTasks.length, 0, {
          taskId: task.id,
          [fieldName]: editData,
        });
        newEditTasks = newTasks;
      } else {
        newEditTasks = [
          {
            taskId: task.id,
            [fieldName]: editData,
          },
        ];
      }

      documentTaskDataChangeRef.current = {
        ...documentTaskDataChangeRef.current,
        tasks: newEditTasks,
      };
      handleChangeDocumentTaskDetail(documentTaskDataChangeRef.current);
    },
    [handleChangeDocumentTaskDetail]
  );

  const onEditDocumenTaskTitle = useCallback(
    async (editData: EditData) => {
      let newTitle = editData?.editValue || "";
      const isDuplicateTitle = await checkDuplicateDocumentTaskTitle({
        title: newTitle,
        bimFileId,
      });

      if (isDuplicateTitle && newTitle !== documentTask.title) {
        const lastIndex = getLastIndexDuplicateTitle({
          titleDuplicate: newTitle,
          documentTasks,
          currentDocumentTask: documentTask,
        });
        newTitle = `${newTitle} (${lastIndex + 1})`;
      }

      documentTaskDataChangeRef.current = {
        ...documentTaskDataChangeRef.current,
        title: {
          ...editData,
          editValue: newTitle,
        },
      };

      handleChangeDocumentTaskDetail(documentTaskDataChangeRef.current);
      onChangeTitle(newTitle);
    },
    [
      onChangeTitle,
      handleChangeDocumentTaskDetail,
      documentTasks,
      documentTask,
      bimFileId,
    ]
  );

  return {
    title,
    editMode,
    onEditDocumentTaskInfoSubmit,
    onEditTaskDisplayName,
    onEditTask,
    onEditDocumenTaskTitle,
    onEditProjectName,
    onEditPhotoBooksContent,
  };
};

export default useChangeDocumentTaskDetail;
