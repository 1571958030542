import { Box, Flex, Text } from "@chakra-ui/react";
import { DynamicFieldMeasure } from "interfaces/models/component";
import { memo } from "react";

interface Props {
  title: string;
  children: React.ReactNode;
  dynamicFieldMeasure: DynamicFieldMeasure | undefined;
}

const GroupDynamicFields = ({
  title,
  dynamicFieldMeasure,
  children,
}: Props) => {
  return (
    <Flex
      p="1.5rem 0"
      flexDirection="column"
      borderTop="1px solid #A3A3A3"
      as="section"
      // title={title}
      // containerHeaderProps={{
      //   p: "1rem 1.5rem",
      // }}
      // containerContentProps={{
      //   pl: "2rem",
      // }}
    >
      <Box>
        <Text fontWeight="bold" fontSize="1.6rem" m="1rem 0">
          {title}
        </Text>
      </Box>
      {/* {!!dynamicFieldMeasure?.values?.length && (
        <Flex
          width="100%"
          alignItems="center"
          px={{ base: "2rem", md: "1rem", "2xl": "2rem" }}
        >
          <Text
            flexBasis={{ base: "10rem", md: "7rem", "2xl": "10rem" }}
            pr="5px"
          />

          <Flex gap="5px" flex="1">
            {dynamicFieldMeasure?.values?.map((_, valIndex) => (
              <Text
                textAlign="left"
                flex={1}
                flexShrink="0"
                key={valIndex}
                whiteSpace="nowrap"
                pl=".5rem"
              >
                {valIndex + 1}回
              </Text>
            ))}

            <Text flex={1} textAlign="left" pl=".5rem">
              平均
            </Text>
          </Flex>
        </Flex>
      )} */}
      {children}
    </Flex>
  );
};

export default memo(GroupDynamicFields);
