import { Box } from "@chakra-ui/react";
import { DOWNLOAD_KEYNOTE_CLASS } from "constants/download";
import { Keynote, KeynoteImage } from "interfaces/models/documentKeynote";
import { memo, useMemo } from "react";
import { getCurrentViewer } from "utils/forge";

interface Props extends Keynote {
  rotation?: number;
  navigatorLength?: number;
  containerSize?: {
    width: number;
    height: number;
  };
  keyNoteImage: KeynoteImage;
  labelRotation?: number;
}

const POINT_SIZE = 3;
const ICON_COLOR = "rgba(56, 161, 105, 0.9)";

export const ICON_SIZE = 24;

export const DEFAULT_NAVIGATOR_LENGTH = 50;
const DEFAULT_SHEET_SHADOW = 10;
const SHEET_BORDER_WIDTH = 1;

const KeyNote = (props: Props) => {
  const {
    position,
    rotation = 45,
    navigatorLength = DEFAULT_NAVIGATOR_LENGTH,
    label,
    keyNoteImage,
    containerSize,
    labelRotation = 0,
  } = props;

  const labelPosition = useMemo(() => {
    const ro =
      rotation > 180 ? Math.abs(270 - rotation) : Math.abs(rotation - 90);

    const top =
      navigatorLength * Math.cos((ro / 180) * Math.PI) +
      ICON_SIZE / 2 -
      POINT_SIZE / 2;

    const left =
      navigatorLength * Math.sin((ro / 180) * Math.PI) +
      ICON_SIZE / 2 -
      POINT_SIZE / 2;

    return {
      [`${rotation > 0 && rotation < 180 ? "bottom" : "top"}`]: `-${top}px`,
      [`${rotation > 90 && rotation < 270 ? "left" : "right"}`]: `-${left}px`,
    };
  }, [navigatorLength, rotation]);

  const pointPosition = useMemo(() => {
    const viewer = getCurrentViewer();
    const hasPageShadow = viewer?.model?.getData()?.hasPageShadow;

    const scaleY =
      Number(containerSize?.height) / Number(keyNoteImage?.imageHeight);
    const scaleX =
      Number(containerSize?.width) /
      Number(
        Number(keyNoteImage?.imageWidth) -
          (hasPageShadow ? DEFAULT_SHEET_SHADOW : 0) / 2
      );

    const y = scaleY * Math.abs(position?.y) - SHEET_BORDER_WIDTH;
    const x = scaleX * Math.abs(position?.x) - SHEET_BORDER_WIDTH;

    return { x, y };
  }, [containerSize, keyNoteImage, position]);

  return (
    <Box
      style={{
        height: `${POINT_SIZE}px`,
        width: `${POINT_SIZE}px`,
        borderRadius: "50%",
        backgroundColor: ICON_COLOR,
        position: "absolute",
        top: `${pointPosition?.y}px`,
        left: `${pointPosition?.x}px`,
      }}
    >
      <Box
        style={{
          position: "relative",
          height: "100%",
          width: "100%",
        }}
      >
        <Box
          style={{
            width: `${navigatorLength}px`,
            height: "1px",
            borderTop: "1px solid",
            borderColor: ICON_COLOR,
            position: "absolute",
            top: `${POINT_SIZE / 2}px`,
            left: `${POINT_SIZE / 2}px`,
            transform: `rotate(${rotation}deg)`,
            transformOrigin: "0",
          }}
        />

        <Box
          style={{
            position: "absolute",
            width: `${ICON_SIZE}px`,
            height: `${ICON_SIZE}px`,
            backgroundColor: ICON_COLOR,
            borderRadius: "50%",
            ...labelPosition,
          }}
        >
          <Box
            className={DOWNLOAD_KEYNOTE_CLASS}
            style={{
              position: "relative",
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "10px",
              color: "white",
              transform: `rotate(${labelRotation}deg)`,
            }}
          >
            {label}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default memo(KeyNote);
