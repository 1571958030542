import { DocumentCategoryModelHandleType } from "components/modal/DocumentCategoryModal";
import { DocumentGroupModelHandleType } from "components/modal/DocumentGroupModal";
import { DocumentItemModalHandleType } from "components/modal/DocumentItemModal";
import { DocumentTemplateHandleType } from "components/modal/DocumentTemplateModal";
import { changeStoredSystemMode } from "components/ui/SystemModeSwitch";
import {
  DocumentTemplateType,
  ModalType,
  SystemModeType,
} from "constants/enum";
import { OPERATION } from "constants/task";
import { DocumentCategoryDTO } from "interfaces/dtos/documentCategoryDTO";
import { DocumentItemDTO } from "interfaces/dtos/documentItemDTO";
import { iDocumentDataProps } from "interfaces/models/document";
import { DocumentGroup } from "interfaces/models/documentGroup";
import cloneDeep from "lodash/cloneDeep";
import { FilterDocumentCategoryByUserSettingProps } from "models/document";
import {
  getFilterDocumentCategories,
  isPhotoLedgerTemplate,
  isSelfInspectionTemplate,
} from "models/documentCategory";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  fetchBlackBoardByCategoryId,
  setDocumentCategory,
  setDocumentCategorySelected,
  setDocumentGroupSelected,
  setDocumentItemSelected,
  setIsLoadedBlackboard,
} from "redux/documentSlice";
import {
  setCreateTask,
  setIsShowArea,
  setModalType,
} from "redux/forgeViewerSlice";
import store, { RootState } from "redux/store";
import { sleep } from "utils/common";
import { updateForgeWhenSelectCategory } from "utils/document";
import {
  hightLightDocumentItem,
  updateForgeWhenSelectDocumentItem,
} from "utils/documentItem";
import { getCurrentViewer, selectDbIds } from "utils/forge";
import { getAreaExtension } from "utils/forge/extensions/area-extension";
import { ClickInfo } from "utils/forge/extensions/click-extension";
import {
  clearLabelAndForgeView,
  clearSelectedLabel,
} from "utils/forge/extensions/custom-label";
import { logDev } from "utils/logs";

interface Props {
  operation?: string | null;
  clickInfo: ClickInfo | undefined;
  filterDocumentCategoryOptions: FilterDocumentCategoryByUserSettingProps;
  setClickedLabelInfo?: (data: any | undefined) => void;
}

export default function useDocument({
  filterDocumentCategoryOptions,
  clickInfo,
  operation,
  setClickedLabelInfo,
}: Props) {
  const {
    levelSelected,
    modalType,
    isCreateTask,
    systemMode,
    isShowArea,
    isFilter,
  } = useSelector((state: RootState) => state.forgeViewer);
  const {
    isLoadingDocument,
    documentItems,
    documentCategories,
    documentCategorySelected,
    documentItemSelected,
    documentGroups,
    documentGroupSelected,
  } = useSelector((state: RootState) => state.document);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const documentCategoryModalRef =
    useRef<DocumentCategoryModelHandleType>(null);
  const documentTemplateModalRef = useRef<DocumentTemplateHandleType>(null);
  const documentGroupModalRef = useRef<DocumentGroupModelHandleType>(null);
  const modalTypeRef = useRef<ModalType>(ModalType.NONE);
  const documentItemModalRef = useRef<DocumentItemModalHandleType>(null);

  useEffect(() => {
    modalTypeRef.current = modalType as ModalType;
  }, [modalType]);

  const filterDocumentGroupAndCategories = useMemo(() => {
    return getFilterDocumentCategories({
      filterDocumentCategoryOptions,
      documentCategories,
      levelSelected,
      documentGroups,
      isFilter,
    });
  }, [
    filterDocumentCategoryOptions,
    documentCategories,
    documentGroups,
    levelSelected,
    isFilter,
  ]);

  const onCloseDocumentPreview = useCallback(() => {
    if (documentCategoryModalRef.current) {
      documentCategoryModalRef.current?.onClosePreview();
    }
  }, []);

  // get all areas and spaces
  const handleDrawAreas = async ({
    isSelfInspection,
  }: {
    isSelfInspection?: boolean;
  }) => {
    if (isSelfInspection && getAreaExtension()?.getShouldReDrawArea()) {
      await getAreaExtension()?.drawAreas({
        initialize: true,
        isVisible: true,
      });
      await sleep(200);
    }
  };
  const refDependences = useRef<any>();
  refDependences.current = {
    isShowArea,
    filterDocumentGroupAndCategories,
    documentCategorySelected,
    documentItemSelected,
    documentGroupSelected,
  };

  const handleClickDocumentCategory = useCallback(
    async (documentCategory: DocumentCategoryDTO) => {
      const {
        documentCategorySelected,
        documentItemSelected,
        documentGroupSelected,
      } = refDependences.current;
      documentCategory = cloneDeep(documentCategory) as DocumentCategoryDTO;
      clearLabelAndForgeView();

      const isSelfInspection = isSelfInspectionTemplate(
        documentCategory?.documentType
      );
      await handleDrawAreas({ isSelfInspection });

      const isDiffCateSelected =
        documentCategory?.id !== documentCategorySelected?.id;
      const isPhotoLedger = isPhotoLedgerTemplate(
        documentCategory?.documentType
      );
      if (!isPhotoLedger) {
        dispatch(setIsLoadedBlackboard(true));
      }

      if (
        !isDiffCateSelected &&
        !documentItemSelected &&
        !documentGroupSelected
      ) {
        (getCurrentViewer()?.utilities as any).goHome();
        dispatch(setDocumentCategorySelected());

        return;
      }
      logDev(documentCategory);
      if (isDiffCateSelected) {
        dispatch(setDocumentCategorySelected(documentCategory));
        // dispatch(setDocumentCategory(documentCategory));
      }
      if (isSelfInspection) {
        dispatch(setIsShowArea(true));
      }
      selectDbIds([], {});
      if (modalTypeRef.current !== ModalType.DOC_CATEGORY) {
        dispatch(setModalType(ModalType.DOC_CATEGORY));
      }
      documentItemSelected && dispatch(setDocumentItemSelected());
      documentGroupSelected && dispatch(setDocumentGroupSelected());
      documentCategoryModalRef?.current?.handleCollapse(false);

      if (isPhotoLedger) {
        dispatch(fetchBlackBoardByCategoryId(documentCategory.id));
      }
      updateForgeWhenSelectCategory(documentCategory);
      setClickedLabelInfo?.(undefined);
      onCloseDocumentPreview?.();
    },
    [dispatch, setClickedLabelInfo, onCloseDocumentPreview]
  );

  useEffect(() => {
    if (!documentItemSelected) return;
    const categorySelected = filterDocumentGroupAndCategories.categories.find(
      (node) => node.id === documentItemSelected.documentCategoryId
    );

    if (categorySelected) {
      const itemSelected = categorySelected.documentItems?.find(
        (node) => node.id === documentItemSelected.id
      );
      if (itemSelected) {
        if (!isSelfInspectionTemplate(itemSelected?.documentType)) {
          hightLightDocumentItem(itemSelected);
        }

        return;
      } else {
        dispatch(setDocumentItemSelected());
        handleClickDocumentCategory(categorySelected);
        selectDbIds([], {});
      }
    }
  }, [filterDocumentGroupAndCategories.categories]);

  // clear category, item selected when it not in filterDocumentCategories
  useEffect(() => {
    if (isLoadingDocument || !documentCategories?.length) {
      return;
    }
    const { categories, groups: documentGroups } =
      filterDocumentGroupAndCategories;
    if (!documentCategorySelected?.id && !documentGroupSelected?.id) {
      return;
    }
    let existCategory = true;
    if (documentGroupSelected) {
      existCategory = documentGroups.some(
        (group) => group.id === documentGroupSelected?.id
      );
    }
    if (existCategory && documentCategorySelected) {
      const categoryId = documentCategorySelected?.id;
      const groupdId = documentGroupSelected?.id;
      existCategory = !!categories?.find((item) =>
        categoryId
          ? item.id === documentCategorySelected?.id
          : item.groupId === groupdId
      );
    }
    if (!existCategory) {
      const viewer = getCurrentViewer();
      viewer?.select(undefined);
      clearSelectedLabel();
      getAreaExtension()?.clearSelection();
      dispatch(setModalType(ModalType.NONE));
      dispatch(setDocumentCategorySelected());
      dispatch(setDocumentItemSelected());
      dispatch(setDocumentGroupSelected());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    documentCategorySelected?.id,
    documentGroupSelected?.id,
    documentCategories?.length,
    filterDocumentGroupAndCategories,
  ]);

  const handleClickDocumentGroup = useCallback(
    (documentGroup: DocumentGroup) => {
      documentGroup = cloneDeep(documentGroup) as DocumentGroup;
      clearLabelAndForgeView();
      const state = store.getState()?.document;
      const {
        documentItemSelected,
        documentCategorySelected,
        documentGroupSelected,
      } = state;

      const isDiffGroupSelectedSelected =
        documentGroup?.id !== documentGroupSelected?.id;
      if (
        !isDiffGroupSelectedSelected &&
        !documentCategorySelected &&
        !documentItemSelected
      ) {
        (getCurrentViewer()?.utilities as any).goHome();
        dispatch(setDocumentGroupSelected(undefined));

        return;
      }
      documentGroupModalRef?.current?.handleCollapse(false);
      dispatch(setDocumentGroupSelected(documentGroup));

      if (modalTypeRef.current !== ModalType.DOC_GROUP) {
        dispatch(setModalType(ModalType.DOC_GROUP));
      }

      documentItemSelected && dispatch(setDocumentItemSelected());
      documentCategorySelected && dispatch(setDocumentCategorySelected());

      setClickedLabelInfo?.(undefined);
      onCloseDocumentPreview?.();
    },
    [dispatch, onCloseDocumentPreview, setClickedLabelInfo]
  );

  const handleClickDocumentItem = useCallback(
    async (data: iDocumentDataProps) => {
      const state = store.getState()?.document;
      const { filterDocumentGroupAndCategories, isShowArea } =
        refDependences.current;
      const {
        documentGroupSelected,
        documentItemSelected,
        documentCategorySelected,
      } = state;
      let documentItem = structuredClone(data.document) as DocumentItemDTO;
      // logDev(documentItem);
      const documentCategory = filterDocumentGroupAndCategories.categories.find(
        (d: any) => {
          return (
            d.level === documentItem.level &&
            d.templateId === documentItem.templateId &&
            d.id === documentItem.documentCategoryId
          );
        }
      );

      if (documentCategory) {
        const transformDocumentItem = documentCategory?.documentItems?.find(
          (item: any) => item.id === documentItem.id
        );
        if (transformDocumentItem) {
          documentItem = transformDocumentItem;
        }
      }

      clearSelectedLabel();
      const isEqualItem =
        documentItem?.id === documentItemSelected?.id &&
        documentItem.documentCategoryId ===
          documentItemSelected?.documentCategoryId;
      const isPhotoLedger =
        documentCategory?.documentType === DocumentTemplateType.PHOTO_LEDGER;

      if (!isPhotoLedger) {
        dispatch(setIsLoadedBlackboard(true));
      }

      const isSelfInspection = isSelfInspectionTemplate(
        documentCategory?.documentType
      );
      if (isEqualItem && systemMode === SystemModeType.Document) {
        (getCurrentViewer()?.utilities as any).goHome();
        dispatch(setDocumentCategorySelected());
        dispatch(setDocumentItemSelected());
        dispatch(setDocumentGroupSelected());
        getAreaExtension()?.clearSelection();

        return false;
      }

      await handleDrawAreas({ isSelfInspection });

      if (!isSelfInspection) {
        hightLightDocumentItem(documentItem);
      }

      const isDiffCateId =
        documentCategorySelected?.id !== documentCategory?.id;
      if (isDiffCateId) {
        dispatch(setDocumentCategorySelected(documentCategory));
      }
      documentItemModalRef?.current?.handleCollapse(false);

      if (isDiffCateId && isPhotoLedger) {
        dispatch(fetchBlackBoardByCategoryId(documentCategory.id));
      }

      updateForgeWhenSelectDocumentItem(documentCategory!, documentItem);

      // when clicking the document item if different category clear all selected
      if (
        documentItem.documentCategoryId !==
          documentItemSelected?.documentCategoryId &&
        documentCategorySelected?.id !== documentCategory?.id &&
        documentCategory &&
        !getAreaExtension()?.getShouldReDrawArea()
      ) {
        getAreaExtension()?.clearSelection();
        getAreaExtension()?.select(documentCategory);
      }

      if (isSelfInspection) {
        dispatch(
          setDocumentItemSelected({
            ...documentItem,
            displayOrder: (data.document as any)?.displayOrder || 0,
          })
        );
        if (!isShowArea) {
          dispatch(setIsShowArea(true));
        }
      } else {
        dispatch(setDocumentItemSelected(documentItem));
      }

      documentGroupSelected && dispatch(setDocumentGroupSelected());
      if (modalTypeRef.current !== ModalType.DOC_ITEM) {
        dispatch(setModalType(ModalType.DOC_ITEM));
      }

      if (onCloseDocumentPreview) {
        onCloseDocumentPreview();
      }

      return true;
    },
    [dispatch, systemMode, onCloseDocumentPreview]
  );

  useEffect(() => {
    if (
      !!clickInfo?.forgeData?.position &&
      isCreateTask &&
      documentCategorySelected?.documentType ===
        DocumentTemplateType.SELF_INSPECTION
    ) {
      dispatch(setModalType("DOC_ITEM"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clickInfo]);

  useEffect(() => {
    if (isCreateTask) {
      dispatch(setCreateTask(false));
      getAreaExtension()?.clearSelection();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentCategorySelected, documentItemSelected]);

  // handle auto navigate document item modal from task modal
  useEffect(() => {
    if (
      operation === OPERATION.navigateDocumentItemModal &&
      systemMode !== SystemModeType.Document
    ) {
      changeStoredSystemMode(SystemModeType.Document);
    }
  }, [operation, systemMode]);

  useEffect(() => {
    if (
      operation !== OPERATION.navigateDocumentItemModal ||
      isLoadingDocument
    ) {
      return;
    }

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const data = urlParams.get("data");

    if (!data) {
      return navigate(window.location.pathname, { replace: true });
    }

    const dataFromURL: { documentItemId: string; documentCategoryId: string } =
      JSON.parse(decodeURIComponent(data) || "{}");

    let documentItem = documentItems.find(
      (item) => item.id === dataFromURL?.documentItemId
    );
    if (!documentItem) {
      return navigate(window.location.pathname, { replace: true });
    }

    if (dataFromURL.documentCategoryId) {
      documentItem = {
        ...documentItem,
        documentCategoryId: dataFromURL.documentCategoryId,
      };
    }

    handleClickDocumentItem({
      document: documentItem,
      modalType: ModalType.DOC_ITEM,
    });
    navigate(window.location.pathname, { replace: true });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operation, isLoadingDocument]);

  return {
    filterDocumentGroupAndCategories,
    documentItemModalRef,
    documentCategoryModalRef,
    documentTemplateModalRef,
    documentGroupModalRef,
    handleClickDocumentGroup,
    onCloseDocumentPreview,
    handleClickDocumentItem,
    handleClickDocumentCategory,
  };
}
