import {
  Box,
  BoxProps,
  Flex,
  Image,
  Spinner,
  useMediaQuery,
} from "@chakra-ui/react";
import { documentTaskApi } from "apiClient/v2";
import TableComponentPreview, {
  iTableComponentPreview,
} from "components/editor-builder/component-preview/TableComponentPreview";
import KeyLabel, { iKeyLabel } from "components/ui/KeyLabel";
import { PresignedImageWithRef } from "components/ui/PresignedImage";
import TaskItem from "components/widgets/TaskSheet/TaskItem";
import { BASE_S3_URL } from "constants/app";
import { DEFAULT_PAGE_RATIO, GRID_TEMPLATE_SIZE } from "constants/document";
import {
  Axis,
  CellSizeSetting,
  ContentType,
  InspectionItemType,
  MapInspectionItemColor,
  PaperDirectionType,
  PaperSize,
  PaperType,
  PHOTO_BOOK_CONTENT_TYPE,
  TableDefaultStyle,
  TemplateComponentType,
} from "constants/enum";
import { KEYNOTE_IMAGE_CLASSNAME } from "constants/styleProps";
import { DEFAULT_LIMIT_ITEM_EXPORT_SHEET_TASK } from "constants/task";
import useGetPartnerCompanies from "hooks/useGetPartnerCompanies";
import { TaskDTO } from "interfaces/dtos/taskDTO";
import { PageFormat, TemplateComponent } from "interfaces/models/component";
import { DocumentTask, DocumentTaskData } from "interfaces/models/documentTask";
import { TaskSheetTemplate } from "interfaces/models/taskSheetTemplate";
import debounce from "lodash/debounce";
import {
  checkDuplicateDocumentTaskTitle,
  getLastIndexDuplicateTitle,
  getTaskLabelOfDocumentTask,
} from "models/documentTask";
import { DEFAULT_BORDER_COLOR } from "pages/document/template-page/hooks";
import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "redux/store";
import { addDocumentTasks } from "redux/taskSlice";
import { paginate, sortArrayByField } from "utils/array";
import { centimetersToPixels } from "utils/measure";
import PhotoBooksInstructionsItem, {
  TaskPhotoBookInstruction,
} from "../PhotoBooksInstructionsItem";
import PhotoBooksReportItem, {
  MAX_HEIGHT_CONTENT_PHOTO_BOOKS_REPORT_HORIZONTAL,
  MAX_HEIGHT_CONTENT_PHOTO_BOOKS_REPORT_VERTICAL,
  PHOTO_BOOKS_CONTAINER_PADDING_BOTTOM,
  TaskPhotoBooksReport,
} from "../PhotoBooksReportItem";
import useChangeDocumentTaskDetail from "./useChangeDocumentTaskDetail";

const DEFAULT_CONTAINER_PADDING = 40;

interface iProps {
  isChangeRatio?: boolean;
  isResetDataKeyplan?: boolean;
  bimFileId: string;
  loadingEdit: boolean;
  documentTask: DocumentTask;
  documentTasks: DocumentTask[];
  taskSheetsRef: React.MutableRefObject<(HTMLDivElement | null)[]>;
  taskSheetContainerRef: React.MutableRefObject<HTMLDivElement>;
  keyplanContainerRef: React.RefObject<HTMLDivElement>;
  documentTaskData: DocumentTaskData | null;
  qrCodeImage: string;
  tasks: TaskDTO[];
  keyLabelsProps: iKeyLabel[] | null;
  zoomPageValue: number;
  editMode: boolean;
  componentsOfTemplate: TemplateComponent[];
  taskSheetTemplate: TaskSheetTemplate | null | undefined;

  isLoadKeyplanImage: boolean;
  keyplanImage: string;
  loading: boolean;
  onLoadKeyplanImage: () => void;
  onUpdateDocumentTask?: (documentTask: DocumentTask) => void;
  setDocumentTaskDataChange: React.Dispatch<
    React.SetStateAction<DocumentTaskData>
  >;
  setZoomPageValue: React.Dispatch<React.SetStateAction<number>>;
  callbackDragKeynoteStop?: (props: Partial<iKeyLabel>) => void;
  zoomPageValueRef: React.MutableRefObject<number>;
}

interface iPageInfo extends PageFormat {
  components: TemplateComponent[];
  pageIndex: number;
  pageOrder: number;
}

interface iRenderPreviewComponent {
  component: TemplateComponent;
  page: iPageInfo;
  pageId: string; // id of element
  isKeyplanFullPage?: boolean;
  componentSizeHeight: number;
  pageHeight: number;
  paddingPageY: number;
}

export type iHandleDocumentTaskDetail = ReturnType<
  typeof useChangeDocumentTaskDetail
>;

const usePreviewTaskSheetTemplate = ({
  isChangeRatio,
  isResetDataKeyplan,
  bimFileId,
  loadingEdit,
  editMode,
  documentTasks,
  documentTask,
  taskSheetsRef,
  taskSheetContainerRef,
  keyplanContainerRef,
  documentTaskData,
  qrCodeImage,
  tasks,
  isLoadKeyplanImage,
  keyplanImage,
  loading,
  keyLabelsProps,
  zoomPageValue,
  componentsOfTemplate,
  taskSheetTemplate,
  zoomPageValueRef,
  onUpdateDocumentTask,
  onLoadKeyplanImage,
  setDocumentTaskDataChange,
  setZoomPageValue,
  callbackDragKeynoteStop,
}: iProps) => {
  const [title, setTitle] = useState<string | null>(null as any);
  const [mapStatusAllImageOfTask, setMapStatusAllImageOfTask] = useState<{
    [key: string]: boolean;
  }>({});
  const { projectDetail } = useSelector((state: RootState) => state.project);
  const { currentUser } = useSelector((state: RootState) => state.user);
  const [isLaptop] = useMediaQuery("(max-width: 1400px)");
  const { taskTypes } = useSelector((state: RootState) => state.task);
  const { projectBimFileId } = useParams();

  const dispatch = useDispatch();

  const { mapPartnerCompanyById } = useGetPartnerCompanies({
    projectBimFileId,
  });
  const keynoteImageRef = useRef<HTMLImageElement | null>(null);

  useEffect(() => {
    if (!documentTask?.title || title !== null) {
      return;
    }

    setTitle(documentTask?.title);
  }, [documentTask?.title, title]);

  const isAllImageOfTaskLoaded = useMemo(() => {
    if (!tasks.length) {
      return false;
    }

    const isHasPhotoBooksComponents = !!componentsOfTemplate.find((com) =>
      [
        TemplateComponentType.PhotoBooksReport,
        TemplateComponentType.PhotoBooksInstruction,
      ].includes(com.type)
    );

    if (!isHasPhotoBooksComponents) {
      return true;
    }

    const arrStatusAllImageOfTask = Object.values(mapStatusAllImageOfTask);

    return (
      arrStatusAllImageOfTask.length ===
        tasks.map((t) => t.images?.length)?.flat(1)?.length &&
      arrStatusAllImageOfTask.every((s) => s)
    );
  }, [mapStatusAllImageOfTask, componentsOfTemplate, tasks]);

  const updateDocumentTask = useMemo(() => {
    return debounce(async (value: string) => {
      let title = value;

      // check duplicate title
      const isDuplicateTitle = await checkDuplicateDocumentTaskTitle({
        title,
        bimFileId,
      });
      if (isDuplicateTitle && documentTask.title !== title) {
        const lastIndex = getLastIndexDuplicateTitle({
          titleDuplicate: title,
          documentTasks,
          currentDocumentTask: documentTask,
        });
        title = `${title} (${lastIndex + 1})`;
        setTitle(title);
      }

      const newDocumentTask: DocumentTask = {
        ...documentTask!,
        title,
        createdAt: new Date(),
      };
      if (onUpdateDocumentTask) {
        onUpdateDocumentTask(newDocumentTask);
      }
      const _newDocumentTask = await documentTaskApi.createUpdateDocumentTask(
        newDocumentTask
      );
      dispatch(addDocumentTasks([_newDocumentTask]));
    }, 1000);
  }, [documentTask, onUpdateDocumentTask, documentTasks, dispatch, bimFileId]);

  const onChangeTitle = useCallback(
    (value: string) => {
      if (!loadingEdit) return;

      setTitle(value);
      updateDocumentTask(value);
    },
    [loadingEdit, updateDocumentTask]
  );

  const handleChangeDocumentTaskDetail = useCallback(
    (data: DocumentTaskData) => {
      setDocumentTaskDataChange(data);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleChangeDocumentTaskDetailProps = useChangeDocumentTaskDetail({
    title,
    bimFileId,
    editMode,
    documentTask,
    documentTasks,
    onChangeTitle,
    handleChangeDocumentTaskDetail,
  });

  const memoHandleChangeDocumentTaskDetailProps = useMemo(
    (): iHandleDocumentTaskDetail => handleChangeDocumentTaskDetailProps,

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      handleChangeDocumentTaskDetailProps.title,
      handleChangeDocumentTaskDetailProps.editMode,
      handleChangeDocumentTaskDetailProps.onEditDocumentTaskInfoSubmit,
      handleChangeDocumentTaskDetailProps.onEditTaskDisplayName,
      handleChangeDocumentTaskDetailProps.onEditDocumenTaskTitle,
      handleChangeDocumentTaskDetailProps.onEditPhotoBooksContent,
      handleChangeDocumentTaskDetailProps.onEditTask,
    ]
  );

  const getOffsetLeft = useCallback(
    (width: number) => {
      const taskSheetContainerWidth =
        taskSheetContainerRef?.current?.clientWidth || 0;

      return taskSheetContainerWidth && taskSheetContainerWidth < width
        ? (width - taskSheetContainerWidth) / 2
        : 0;
    },
    [taskSheetContainerRef]
  );

  const generatePhotoBookContentKey = (taskId: string, image = "") => {
    return `${taskId}-${image
      .replace(BASE_S3_URL, "")
      .replaceAll(/[ ./]/g, "-")}`;
  };

  const transformTaskForPhotoBooksInstructions = useCallback(
    (tasks: TaskDTO[]) => {
      const transformTasks: TaskPhotoBookInstruction[] = [];
      tasks.forEach((task, taskIndex) => {
        const partnerCompanyName =
          mapPartnerCompanyById?.[task?.partnerCompany || ""]?.name;

        if (!task.images?.length) {
          const photoBookContentKey = `${task.id}-${taskIndex}`;

          transformTasks.push({
            ...task,
            isShowHeader: true,
            partnerCompanyName,
            photoBookContentKey,
            photoBookContentEdit:
              documentTaskData?.photoBooks?.[photoBookContentKey],
          });

          return;
        }

        task.images.forEach((image, imageIndex) => {
          const photoBookContentKey = generatePhotoBookContentKey(
            task.id,
            image.src
          );
          transformTasks.push({
            ...task,
            isShowHeader: imageIndex === 0,
            images: [image],
            partnerCompanyName,
            photoBookContentKey,
            photoBookContentEdit:
              documentTaskData?.photoBooks?.[photoBookContentKey],
          });
        });
      });

      return transformTasks;
    },
    [mapPartnerCompanyById, documentTaskData]
  );

  const transformTaskForPhotoBooksReport = useCallback(
    (tasks: TaskDTO[]) => {
      const transformTasks: TaskPhotoBooksReport[] = [];
      tasks.forEach((task, taskIndex) => {
        const images = task?.images || [];
        const confirmedImages = task?.confirmedImages || [];
        const partnerCompanyName =
          mapPartnerCompanyById?.[task?.partnerCompany || ""]?.name;

        if (!images?.length && !confirmedImages?.length) {
          const photoBookInstructionContentKey = `${task.id}-${taskIndex}-${PHOTO_BOOK_CONTENT_TYPE.INSTRUCTION}`;
          const photoBookReportContentKey = `${task.id}-${taskIndex}-${PHOTO_BOOK_CONTENT_TYPE.REPORT}`;

          transformTasks.push({
            ...task,
            isShowHeader: true,
            isShowImage: true,
            isShowConfirmedImage: true,
            photoBookInstructionContentKey,
            photoBookInstructionContentEdit:
              documentTaskData?.photoBooks?.[photoBookInstructionContentKey],
            photoBookReportContentKey,
            photoBookReportContentEdit:
              documentTaskData?.photoBooks?.[photoBookReportContentKey],
            partnerCompanyName,
          });

          return;
        }

        const maxLoop = Math.max(
          task?.images?.length || 0,
          task?.confirmedImages?.length || 0
        );
        const isConditionCommon =
          images?.length <= 1 && confirmedImages?.length <= 1;
        for (let i = 0; i < maxLoop; i++) {
          const photoBookInstructionContentKey = !images?.[i]
            ? `${task.id}-${taskIndex}-${PHOTO_BOOK_CONTENT_TYPE.INSTRUCTION}`
            : generatePhotoBookContentKey(task.id, images?.[i].src);
          const photoBookReportContentKey = !confirmedImages?.[i]
            ? `${task.id}-${taskIndex}-${PHOTO_BOOK_CONTENT_TYPE.REPORT}`
            : generatePhotoBookContentKey(task.id, confirmedImages?.[i].src);

          transformTasks.push({
            ...task,
            partnerCompanyName,
            isShowHeader: i === 0,
            images: images?.[i] ? [images?.[i]] : [],
            photoBookInstructionContentKey,
            photoBookInstructionContentEdit:
              documentTaskData?.photoBooks?.[photoBookInstructionContentKey],
            photoBookReportContentKey,
            photoBookReportContentEdit:
              documentTaskData?.photoBooks?.[photoBookReportContentKey],
            isShowImage:
              isConditionCommon || (!isConditionCommon && !!images?.[i]),
            isShowConfirmedImage:
              isConditionCommon ||
              (!isConditionCommon && !!confirmedImages?.[i]),
            confirmedImages: confirmedImages?.[i] ? [confirmedImages?.[i]] : [],
          });
        }
      });

      return transformTasks;
    },
    [mapPartnerCompanyById, documentTaskData]
  );

  const sortTasks = useCallback(
    (
      size: number,
      page: number,
      type?:
        | TemplateComponentType.PhotoBooksReport
        | TemplateComponentType.PhotoBooksInstruction
    ) => {
      const sortedTasks = sortArrayByField(tasks || [], "indexId").map((e) => ({
        ...e,
        id: e.id || e.taskId,
      }));
      let transformTasks = [];

      switch (type) {
        case TemplateComponentType.PhotoBooksInstruction: {
          transformTasks = transformTaskForPhotoBooksInstructions(sortedTasks);
          break;
        }

        case TemplateComponentType.PhotoBooksReport: {
          transformTasks = transformTaskForPhotoBooksReport(sortedTasks);
          break;
        }

        default: {
          transformTasks = sortedTasks;
          break;
        }
      }

      return paginate(transformTasks, size, page);
    },
    [
      tasks,
      transformTaskForPhotoBooksInstructions,
      transformTaskForPhotoBooksReport,
    ]
  );

  const isHasKeyplanComponent = useMemo(() => {
    return componentsOfTemplate.some(
      (com) => com.type === TemplateComponentType.Keyplan
    );
  }, [componentsOfTemplate]);

  const pageIndexHasSingleKeyplan = useMemo(() => {
    const keyplanComponents = componentsOfTemplate?.filter(
      (com) => com.type === TemplateComponentType.Keyplan
    );

    return keyplanComponents
      .filter(
        (keyplan) =>
          componentsOfTemplate.filter((c) => c.page === keyplan.page)
            ?.length === 1
      )
      .map((com) => com.page);
  }, [componentsOfTemplate]);

  const pages = useMemo(() => {
    if (!taskSheetTemplate || !tasks?.length) {
      return null;
    }

    const allPages: iPageInfo[] = [];
    const pagesOfTemplate: PageFormat[] = taskSheetTemplate.pages;
    const componentsOfPage = (page: number) =>
      componentsOfTemplate.filter((c) => c.page === page);

    pagesOfTemplate.forEach((page, pageIndex) => {
      const components = componentsOfPage(pageIndex);
      const tableLinked = components.find((com) => !!com.linkedHeaderId);
      const isHasLinkedTable = tableLinked?.componentId;
      const isHasTasksImage = components.some(
        (com) => com.type === TemplateComponentType.TasksImage
      );

      const isHasPhotoBooksInstructions = components.some(
        (com) => com.type === TemplateComponentType.PhotoBooksInstruction
      );

      const isHasPhotoBooksReport = components.some(
        (com) => com.type === TemplateComponentType.PhotoBooksReport
      );

      if (isHasLinkedTable) {
        const totalTableSheetPage = Math.ceil(
          (tasks || [])?.length / (tableLinked.detail?.numOfRepeat || 1)
        );

        [...Array(totalTableSheetPage).keys()].forEach((p) =>
          allPages.push({
            ...page,
            components,
            pageOrder: pageIndex,
            pageIndex: p + 1,
          })
        );

        return;
      }

      if (isHasTasksImage) {
        const totalTasksImagePage = Math.ceil(
          (tasks || []).length / DEFAULT_LIMIT_ITEM_EXPORT_SHEET_TASK
        );

        [...Array(totalTasksImagePage).keys()].forEach((p) =>
          allPages.push({
            ...page,
            components,
            pageOrder: pageIndex,
            pageIndex: p + 1,
          })
        );

        return;
      }

      if (isHasPhotoBooksReport) {
        const totalTasksImagePage = Math.ceil(
          (transformTaskForPhotoBooksReport(tasks) || []).length /
            DEFAULT_LIMIT_ITEM_EXPORT_SHEET_TASK
        );

        [...Array(totalTasksImagePage).keys()].forEach((p) =>
          allPages.push({
            ...page,
            components,
            pageOrder: pageIndex,
            pageIndex: p + 1,
          })
        );

        return;
      }

      if (isHasPhotoBooksInstructions) {
        const totalTasksImagePage = Math.ceil(
          (transformTaskForPhotoBooksInstructions(tasks) || []).length /
            DEFAULT_LIMIT_ITEM_EXPORT_SHEET_TASK
        );

        [...Array(totalTasksImagePage).keys()].forEach((p) =>
          allPages.push({
            ...page,
            components,
            pageOrder: pageIndex,
            pageIndex: p + 1,
          })
        );

        return;
      }

      allPages.push({
        ...page,
        components,
        pageOrder: pageIndex,
        pageIndex: pageIndex + 1,
      });
    });

    return allPages;
  }, [
    tasks,
    taskSheetTemplate,
    componentsOfTemplate,
    transformTaskForPhotoBooksReport,
    transformTaskForPhotoBooksInstructions,
  ]);

  const maxSizeOfPages = useMemo(() => {
    const listPageWidth = (pages?.map((item) =>
      centimetersToPixels(
        item.pageDirection === PaperDirectionType.VERTICAL
          ? PaperSize[item?.pageSize]?.width
          : PaperSize[item?.pageSize]?.height
      )
    ) || []) as number[];

    return Math.max(...listPageWidth);
  }, [pages]);

  const getScale = useCallback((page: iPageInfo) => {
    const { sizePageRatio, pageDirectionRatio } = page;
    const zoomRatio = 1;

    const scale = {
      x: 1 / zoomRatio / sizePageRatio / pageDirectionRatio,
      y: (1 / zoomRatio / sizePageRatio) * pageDirectionRatio,
      displayX: zoomRatio * sizePageRatio * pageDirectionRatio,
      displayY: (zoomRatio * sizePageRatio) / pageDirectionRatio,
    };

    return scale;
  }, []);

  const getDisplayComponent = useCallback(
    (
      page: iPageInfo,
      scale: ReturnType<typeof getScale>,
      component: TemplateComponent
    ) => {
      let maxWidth =
        page.numOfColumns * CellSizeSetting.MIN_WIDTH +
        TableDefaultStyle.DEFAULT_BORDER_SIZE;

      let maxHeight =
        page.numOfRows * CellSizeSetting.MIN_HEIGHT +
        TableDefaultStyle.DEFAULT_BORDER_SIZE;

      if (page.isFullSize) {
        const size = {
          width: centimetersToPixels(PaperSize[page?.pageSize]?.width),
          height: centimetersToPixels(PaperSize[page?.pageSize]?.height),
        };

        [maxWidth, maxHeight] =
          page.pageDirection === PaperDirectionType.VERTICAL
            ? [size.width, size.height]
            : [size.height, size.width];
      }

      const newWidth = component.size.width * scale.displayX;
      const newHeight = component.size.height * scale.displayY;

      const width = newWidth > maxWidth ? maxWidth : newWidth;
      const height = newHeight > maxHeight ? maxHeight : newHeight;

      return {
        size: {
          width,
          height,
        },
      };
    },
    []
  );

  const checkAllImageOfTaskLoaded = useCallback((taskId: string) => {
    setMapStatusAllImageOfTask((prev) => ({ ...prev, [taskId]: true }));
  }, []);

  const renderPreviewComponent = useCallback(
    ({
      component,
      page,
      pageId,
      componentSizeHeight,
      isKeyplanFullPage,
      pageHeight,
      paddingPageY,
    }: iRenderPreviewComponent) => {
      const scale = getScale(page);

      const tableComponentProps = {
        component,
        cellSize: {
          width: GRID_TEMPLATE_SIZE,
          height: GRID_TEMPLATE_SIZE,
        },
        zoomRatio: 1,
        isOnlyView: true,
        displaySize: {
          width: component.size.width * scale.displayX,
          height: component.size.height * scale.displayY,
        },
        sizePageRatio: page?.sizePageRatio || DEFAULT_PAGE_RATIO,
        pageDirectionRatio: page?.pageDirectionRatio || DEFAULT_PAGE_RATIO,
        isResizable: false,
        isResizing: false,
        template: taskSheetTemplate,
      } as iTableComponentPreview;

      let taskImageHeight = 0;
      let listTask: any[] = [];
      let photoHeaderHeight = 0;
      const realPageHeight = pageHeight - paddingPageY * 2;
      const photoBooksContainerPadding =
        PHOTO_BOOKS_CONTAINER_PADDING_BOTTOM *
        DEFAULT_LIMIT_ITEM_EXPORT_SHEET_TASK;

      if (!documentTaskData) {
        return <></>;
      }

      switch (component.type) {
        case TemplateComponentType.Table:
        case TemplateComponentType.TableHeader:
          if (!!component.linkedHeaderId) {
            return (
              <TableComponentPreview
                {...tableComponentProps}
                contentType={ContentType.TASK_ITEM_DATA}
                taskItemDataProps={{
                  mapPartnerCompanyById,
                  page: page.pageIndex,
                  documentTaskData,
                  taskItems: sortTasks(
                    component.detail?.numOfRepeat || 1,
                    page.pageIndex
                  ),
                  component,
                  handleDocumentTaskDetailProps:
                    memoHandleChangeDocumentTaskDetailProps,
                }}
              />
            );
          }

          return (
            <TableComponentPreview
              {...tableComponentProps}
              contentType={ContentType.DOCUMENT_TASK_DATA}
              documentTaskDataProps={{
                documentTaskData,
                component,
                currentUser,
                projectDetail,
                handleDocumentTaskDetailProps:
                  memoHandleChangeDocumentTaskDetailProps,
              }}
            />
          );

        case TemplateComponentType.QR_CODE:
          return (
            <Flex alignItems="center" width="100%" height="100%">
              <Image
                src={qrCodeImage}
                alt=""
                m="auto"
                style={{
                  objectFit: "contain",
                  width: `${component.realSize.width - 2}px`,
                  height: `${component.realSize.height - 2}px`,
                }}
              />
            </Flex>
          );

        case TemplateComponentType.Keyplan:
          return (
            <Flex
              key={component.componentId}
              justifyContent="center"
              alignItems="center"
              flex="1"
              position="relative"
              {...(!isKeyplanFullPage
                ? {
                    border: `1px solid ${DEFAULT_BORDER_COLOR}`,
                    width: "calc(100% + 2px)",
                    height: "calc(100% + 1px)",
                  }
                : { height: "100%" })}
            >
              <Box
                position="relative"
                width="fit-content"
                mx="auto"
                ref={keyplanContainerRef}
              >
                <PresignedImageWithRef
                  id={KEYNOTE_IMAGE_CLASSNAME}
                  _ref={keynoteImageRef}
                  isConvertToBase64={true}
                  border="1px solid #555"
                  borderWidth="0.5px 1px 0.5px 0.5px"
                  display={
                    isLoadKeyplanImage || !keyplanImage || loading
                      ? "none"
                      : "block"
                  }
                  src={keyplanImage}
                  crossOrigin="anonymous"
                  alt=""
                  width="auto"
                  maxH={`${componentSizeHeight}px`}
                  height="auto"
                  onLoad={onLoadKeyplanImage}
                />

                {keyLabelsProps?.map((item) => (
                  <KeyLabel
                    key={item.id}
                    {...item}
                    loadingEdit={loadingEdit}
                    isChangeRatio={isChangeRatio}
                    isResetData={isResetDataKeyplan}
                    isEditMode={editMode}
                    keynoteImageElement={keynoteImageRef.current as any}
                    callbackDragStop={callbackDragKeynoteStop}
                    color={
                      MapInspectionItemColor[
                        (item.status ||
                          InspectionItemType.Defect) as InspectionItemType
                      ]
                    }
                  />
                ))}
              </Box>

              {(!keyplanImage || isLoadKeyplanImage) && (
                <Flex
                  position="absolute"
                  top="0px"
                  left="0px"
                  width="100%"
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Spinner color="blue.500" size="lg" />
                </Flex>
              )}
            </Flex>
          );

        case TemplateComponentType.PhotoBooksInstruction:
          listTask = sortTasks(
            DEFAULT_LIMIT_ITEM_EXPORT_SHEET_TASK,
            page.pageIndex,
            TemplateComponentType.PhotoBooksInstruction
          );
          photoHeaderHeight = (listTask as TaskPhotoBooksReport[]).reduce(
            (acc, curr) => acc + (!!curr?.isShowHeader ? 40 : 0),
            0
          );

          taskImageHeight =
            (realPageHeight - photoHeaderHeight - photoBooksContainerPadding) /
              DEFAULT_LIMIT_ITEM_EXPORT_SHEET_TASK -
            DEFAULT_LIMIT_ITEM_EXPORT_SHEET_TASK;

          return (
            <>
              {listTask.map((task, taskIndex) => {
                const currentTaskData = documentTaskData.tasks?.find(
                  (item) => item.taskId === task.taskId
                );

                return (
                  <Fragment key={`${task?.indexId}-${taskIndex}`}>
                    <PhotoBooksInstructionsItem
                      task={task}
                      contentTypeChanged={
                        currentTaskData?.contentType?.editValue
                      }
                      taskImageHeight={taskImageHeight}
                      taskTypes={taskTypes}
                      editMode={
                        memoHandleChangeDocumentTaskDetailProps?.editMode
                      }
                      onEditPhotoBooksContent={
                        memoHandleChangeDocumentTaskDetailProps?.onEditPhotoBooksContent
                      }
                      checkImageLoaded={checkAllImageOfTaskLoaded}
                    />
                  </Fragment>
                );
              })}
            </>
          );

        case TemplateComponentType.PhotoBooksReport:
          listTask = sortTasks(
            DEFAULT_LIMIT_ITEM_EXPORT_SHEET_TASK,
            page.pageIndex,
            TemplateComponentType.PhotoBooksReport
          );

          photoHeaderHeight = (listTask as TaskPhotoBooksReport[]).reduce(
            (acc, curr) => acc + (!!curr?.isShowHeader ? 40 : 0),
            0
          );
          const photoBooksContentHeight =
            (page.pageDirection === PaperDirectionType.VERTICAL
              ? MAX_HEIGHT_CONTENT_PHOTO_BOOKS_REPORT_VERTICAL
              : MAX_HEIGHT_CONTENT_PHOTO_BOOKS_REPORT_HORIZONTAL) *
            DEFAULT_LIMIT_ITEM_EXPORT_SHEET_TASK;

          taskImageHeight =
            (realPageHeight -
              photoHeaderHeight -
              photoBooksContentHeight -
              photoBooksContainerPadding) /
              DEFAULT_LIMIT_ITEM_EXPORT_SHEET_TASK -
            DEFAULT_LIMIT_ITEM_EXPORT_SHEET_TASK;

          return (
            <>
              {listTask.map((task, taskIndex) => {
                const currentTaskData = documentTaskData.tasks?.find(
                  (item) => item.taskId === task.taskId
                );

                return (
                  <Fragment key={`${task?.indexId}-${taskIndex}`}>
                    <PhotoBooksReportItem
                      pageId={pageId}
                      task={task}
                      pageDirection={page.pageDirection}
                      contentTypeChanged={
                        currentTaskData?.contentType?.editValue
                      }
                      taskTypes={taskTypes}
                      isLastItem={taskIndex === listTask.length - 1}
                      taskImageHeight={taskImageHeight}
                      maxHeightContentPhotoBooks={
                        page?.pageDirection === PaperDirectionType.VERTICAL
                          ? MAX_HEIGHT_CONTENT_PHOTO_BOOKS_REPORT_VERTICAL
                          : MAX_HEIGHT_CONTENT_PHOTO_BOOKS_REPORT_HORIZONTAL
                      }
                      editMode={
                        memoHandleChangeDocumentTaskDetailProps?.editMode
                      }
                      onEditPhotoBooksContent={
                        memoHandleChangeDocumentTaskDetailProps?.onEditPhotoBooksContent
                      }
                      checkImageLoaded={checkAllImageOfTaskLoaded}
                    />
                  </Fragment>
                );
              })}
            </>
          );

        case TemplateComponentType.TasksImage:
          return (
            <>
              {sortTasks(
                DEFAULT_LIMIT_ITEM_EXPORT_SHEET_TASK,
                page.pageIndex
              ).map((task, taskIndex) => {
                const currentTaskData = documentTaskData?.tasks?.find(
                  (item) => item.taskId === task.taskId
                );

                return (
                  <Fragment key={`${task?.indexId}-${taskIndex}`}>
                    <TaskItem
                      task={task}
                      contentTypeChanged={
                        currentTaskData?.contentType?.editValue
                      }
                      taskImageHeight={taskImageHeight}
                      checkImageLoaded={checkAllImageOfTaskLoaded}
                    />
                  </Fragment>
                );
              })}
            </>
          );
      }

      return <></>;
    },
    [
      taskTypes,
      mapPartnerCompanyById,
      memoHandleChangeDocumentTaskDetailProps,
      currentUser,
      projectDetail,
      taskSheetTemplate,
      loadingEdit,
      isChangeRatio,
      isResetDataKeyplan,
      editMode,
      keyLabelsProps,
      documentTaskData,
      qrCodeImage,
      keyplanContainerRef,
      isLoadKeyplanImage,
      loading,
      keyplanImage,
      callbackDragKeynoteStop,
      checkAllImageOfTaskLoaded,
      sortTasks,
      onLoadKeyplanImage,
      getScale,
    ]
  );

  const renderPages = useMemo(() => {
    return (
      <>
        {pages?.map((page, pageIndex) => {
          let pageWidth = centimetersToPixels(PaperSize[page.pageSize]?.width);
          let pageHeight = centimetersToPixels(
            PaperSize[page.pageSize]?.height
          );
          const isVertical = page.pageDirection === PaperDirectionType.VERTICAL;
          const pageSize = page?.pageSize || PaperType.A4;
          const isPageFullSize = page?.isFullSize;
          let paddingX = pageSize === PaperType.A4 ? 36 : 51;
          let paddingY = pageSize === PaperType.A4 ? 20 : 26;

          if (!isVertical) {
            [pageHeight, pageWidth] = [pageWidth, pageHeight];
            [paddingY, paddingX] = [paddingX, paddingY];
          }

          if (isPageFullSize) {
            [paddingX, paddingY] = [0, 0];
          }

          const isKeyplanFullPage = pageIndexHasSingleKeyplan.includes(
            page.pageOrder
          );

          const taskLabelOfDocumentTask =
            getTaskLabelOfDocumentTask(documentTask);
          const taskLabelByGuid = taskLabelOfDocumentTask?.taskLabelByGuid;

          const axis = taskLabelByGuid?.axis;
          const isAxisHorizontal = axis === Axis.HORIZONTAL;
          const pageDirection = page.pageDirection;

          if (isKeyplanFullPage && axis) {
            [pageWidth, pageHeight] = isAxisHorizontal
              ? pageDirection === PaperDirectionType.VERTICAL
                ? [pageHeight, pageWidth]
                : [pageWidth, pageHeight]
              : pageDirection === PaperDirectionType.VERTICAL
              ? [pageWidth, pageHeight]
              : [pageHeight, pageWidth];
            [paddingX, paddingY] = isAxisHorizontal
              ? pageDirection === PaperDirectionType.VERTICAL
                ? [paddingY, paddingX]
                : [paddingX, paddingY]
              : pageDirection === PaperDirectionType.VERTICAL
              ? [paddingX, paddingY]
              : [paddingY, paddingX];
          }

          const offsetLeft = getOffsetLeft(pageWidth);
          const offsetScale =
            zoomPageValue !== 1 ? (pageHeight * (1 - zoomPageValue)) / 2 : 0;

          const pageId = `page-${pageIndex}`;
          const styleTaskSheetBox: Partial<BoxProps> = {
            id: pageId,
            "page-direction": isKeyplanFullPage
              ? isAxisHorizontal
                ? PaperDirectionType.HORIZONTAL
                : axis
                ? PaperDirectionType.VERTICAL
                : pageDirection
              : pageDirection,
            "page-size": page.pageSize,
            key: `${page}-${pageIndex}`,
            display: "table",
            position: "relative",
            width: pageWidth,
            height: pageHeight,
            margin: "0 auto",
            p: `${paddingY}px ${paddingX}px`,
            bgColor: "#fff",
            className: "download-PDF-task-sheet",
            transform: `scale(${zoomPageValue})`,
            marginTop: `${-offsetScale}px`,
            marginLeft: offsetLeft ? `${-offsetLeft}px` : "auto",
            marginBottom: `${-offsetScale}px`,
            ...(zoomPageValue !== 1
              ? {
                  transform: `scale(${zoomPageValue})`,
                  marginTop: `${-offsetScale}px`,
                  marginLeft: offsetLeft ? `${-offsetLeft}px` : "auto",
                  marginBottom: `${-offsetScale}px`,
                }
              : {}),
          } as any;

          const scale = getScale(page);

          return (
            <Box
              key={pageIndex}
              ref={(el) => (taskSheetsRef.current[pageIndex] = el)}
            >
              <Box py="7px">
                <Box {...styleTaskSheetBox}>
                  <Box position="relative" height="100%" width="100%">
                    {page.components.map((component) => {
                      const componentSize = getDisplayComponent(
                        page,
                        scale,
                        component
                      );
                      let componentSizeWidth = componentSize.size.width;
                      let componentSizeHeight = componentSize.size.height;
                      let componentPositionX =
                        component.position.x * scale.displayX;
                      let componentPositionY =
                        component.position.y * scale.displayY;

                      if (isKeyplanFullPage) {
                        [componentSizeWidth, componentSizeHeight] =
                          isAxisHorizontal
                            ? pageDirection === PaperDirectionType.VERTICAL
                              ? [componentSizeHeight, componentSizeWidth]
                              : [componentSizeWidth, componentSizeHeight]
                            : pageDirection === PaperDirectionType.VERTICAL
                            ? [componentSizeWidth, componentSizeHeight]
                            : axis
                            ? [componentSizeHeight, componentSizeWidth]
                            : [componentSizeWidth, componentSizeHeight];

                        [componentPositionX, componentPositionY] =
                          isAxisHorizontal
                            ? pageDirection === PaperDirectionType.VERTICAL
                              ? [componentPositionX, componentPositionY]
                              : [componentPositionY, componentPositionX]
                            : pageDirection === PaperDirectionType.VERTICAL
                            ? [componentPositionX, componentPositionY]
                            : axis
                            ? [componentPositionY, componentPositionX]
                            : [componentPositionX, componentPositionY];
                      }

                      return (
                        <Box
                          key={component.componentId}
                          position="absolute"
                          top={`${componentPositionY}px`}
                          left={`${componentPositionX}px`}
                          width={`${componentSizeWidth}px`}
                          height={`${componentSizeHeight}px`}
                        >
                          {renderPreviewComponent({
                            component,
                            page,
                            pageId,
                            isKeyplanFullPage,
                            componentSizeHeight,
                            pageHeight,
                            paddingPageY: paddingY,
                          })}
                        </Box>
                      );
                    })}
                  </Box>

                  <Box
                    position="absolute"
                    bottom="1rem"
                    right="2rem"
                    padding="2px"
                    bg="white"
                    minWidth="10rem"
                    textAlign="right"
                    fontSize="1.2rem"
                  >
                    {pageIndex + 1} / {pages.length}
                  </Box>
                </Box>
              </Box>
            </Box>
          );
        })}
      </>
    );
  }, [
    pages,
    pageIndexHasSingleKeyplan,
    documentTask,
    taskSheetsRef,
    zoomPageValue,
    renderPreviewComponent,
    getDisplayComponent,
    getScale,
    getOffsetLeft,
  ]);

  const axisKeyplanDefault = useMemo(() => {
    const pages = taskSheetTemplate?.pages;

    if (!pageIndexHasSingleKeyplan.length || !pages) {
      return Axis.VERTICAL;
    }

    return pages?.[pageIndexHasSingleKeyplan[0]].pageDirection ===
      PaperDirectionType.VERTICAL
      ? Axis.VERTICAL
      : Axis.HORIZONTAL;
  }, [pageIndexHasSingleKeyplan, taskSheetTemplate?.pages]);

  const setDefaultZoom = () => {
    if (!isLaptop) {
      return;
    }

    const defaultZoomPageInTablet = Number(
      Math.floor(
        ((window.innerWidth - DEFAULT_CONTAINER_PADDING) / maxSizeOfPages) * 10
      ) / 10
    );

    const newZoomValue =
      !defaultZoomPageInTablet || defaultZoomPageInTablet > 1
        ? 1
        : defaultZoomPageInTablet;

    zoomPageValueRef.current = newZoomValue;
    setZoomPageValue(newZoomValue);
  };

  useEffect(() => {
    setDefaultZoom();

    if (typeof window === "undefined") {
      return;
    }
    window.addEventListener("resize", setDefaultZoom);

    return () => {
      window.removeEventListener("resize", setDefaultZoom);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxSizeOfPages]);

  return {
    pages,
    renderPages,
    isKeyplanFullPage: !!pageIndexHasSingleKeyplan?.length,
    setTitle,
    isHasKeyplanComponent,
    axisKeyplanDefault,
    isAllImageOfTaskLoaded,
  };
};

export default usePreviewTaskSheetTemplate;
