import { Flex, FlexProps, Image, Text } from "@chakra-ui/react";
import { defaultAvatarPath } from "constants/file";
import { stylesSelectInputSmall } from "constants/styleProps";
import { UserDTO } from "interfaces/dtos/UserDTO";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import { useEffect, useMemo, useRef, useState } from "react";
import CreatableSelect from "react-select/creatable";
import {
  MenuPlacement,
  StylesConfig,
} from "react-select/dist/declarations/src";
import { SelectComponents } from "react-select/dist/declarations/src/components";
import { colors } from "theme/colors";

interface Props extends FlexProps {
  styleSx?: any;
  users: {
    [key: string]: UserDTO | null;
  };
  value?: any;
  onChange?: (value: any) => void;
  placeHolder?: string;
  placeholderColor?: string;
  isDisabled?: boolean;
  components?: Partial<SelectComponents<any, any, any>>;
  onBlur?: () => void;
  menuPlacement?: MenuPlacement;
  styles?: StylesConfig;
  readonly?: boolean;
  isClearable?: boolean;
}

const SelectUserSingle = ({
  styleSx = {},
  users = {},
  value = null,
  onChange,
  placeHolder = "",
  placeholderColor = "#ccd1d9",
  isDisabled = false,
  components,
  onBlur,
  menuPlacement = "auto",
  styles,
  readonly,
  isClearable = true,
  ...rest
}: Props) => {
  const {
    control: controlStyles,
    placeholder: placeholderStyles,
    ...propsStyles
  } = styles || {};
  const creatableSelectRef = useRef<any>();
  const [isFocus, setFocus] = useState(false);
  const [checkShowUser, setCheckShowUser] = useState(false);

  useEffect(() => {
    setCheckShowUser(!isFocus);
  }, [isFocus]);

  return useMemo(
    () => (
      <Flex
        width="100%"
        sx={{
          ...stylesSelectInputSmall,
          "div[class*='control']": {
            border: "none",
            minHeight: "25px",
          },
          "div[class*='control']:hover": {
            border: "none",
          },
          "div[class*='singleValue'] > div": {
            height: "3rem",
            ".chakra-text": {
              color: "#FFF",
            },
          },
          ...styleSx,
        }}
        zIndex={10}
        _hover={{
          cursor: isDisabled ? "not-allowed" : "unset",
        }}
        opacity={isDisabled ? 0.4 : 1}
        {...rest}
      >
        <CreatableSelect
          isClearable={isClearable}
          menuPosition="absolute"
          menuPlacement={menuPlacement}
          ref={creatableSelectRef}
          styles={{
            ...propsStyles,
            control: (baseStyles) => ({
              ...baseStyles,
              borderColor: colors.border.default,
              ...controlStyles,
            }),
            placeholder: (baseStyles) => ({
              ...baseStyles,
              color: colors.font.placeholder,
              opacity: 1,
              ...placeholderStyles,
            }),
          }}
          onBlur={() => {
            setFocus(false);
            onBlur && onBlur();
          }}
          onFocus={() => setFocus(true)}
          filterOption={(option, inputSearch) => {
            return ((option.data as any).name || "")
              .toLowerCase()
              .includes(inputSearch.toLowerCase());
          }}
          className="containerSelectInput"
          noOptionsMessage={() => "オプションなし"}
          isValidNewOption={() => false}
          isDisabled={isDisabled}
          components={components}
          placeholder={placeHolder}
          options={
            Object.keys(users).map((userId) => ({
              label: (
                <>
                  <Flex
                    height="4.2rem"
                    alignItems="center"
                    style={{ maxWidth: "29rem" }}
                  >
                    <Image
                      borderRadius="50%"
                      w="2.5rem"
                      h="2.5rem"
                      src={users[userId]?.avatarBase64 || defaultAvatarPath}
                      mr="0.5rem"
                    />
                    <Text
                      style={{
                        maxWidth: "27rem",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                      color="#171717"
                    >
                      {users[userId]?.name}
                    </Text>
                  </Flex>
                </>
              ),
              value: userId,
              name: users[userId]?.name,
            })) as any
          }
          value={
            value &&
            checkShowUser && {
              value,
              label: (
                <>
                  <Flex height="4.2rem" alignItems="center">
                    <Image
                      borderRadius="50%"
                      w="2.5rem"
                      h="2.5rem"
                      src={users[value]?.avatarBase64 || defaultAvatarPath}
                      mr="0.5rem"
                    />
                    <Text color="#171717">{users[value]?.name}</Text>
                  </Flex>
                </>
              ),
            }
          }
          onChange={(event) => {
            // Fix call double time
            if (typeof event === "string") return;
            if (isEqual(event, null)) {
              creatableSelectRef.current.setValue("");
            }
            setCheckShowUser(!isEmpty(event));
            onChange && onChange(event);
          }}
        />
      </Flex>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [styleSx, users, value]
  );
};

export default SelectUserSingle;
