import { FamilyInstanceKey } from "./enum";

export enum Condition {
  Equal = "Equal",
  NotEqual = "NotEqual",
  LessOrEqual = "LessOrEqual",
  Less = "Less",
  GreaterOrEqual = "GreaterOrEqual",
  Greater = "Greater",
  Exists = "Exists",
  NotExists = "NotExists",
  Contains = "Contains",
  NotContains = "NotContains",
}

export const Conditions = [
  // {
  //   name: "Equal to",
  //   value: String(Condition.Equal),
  // },
  // {
  //   name: "Not equal to",
  //   value: String(Condition.NotEqual),
  // },
  // {
  //   name: "Less than or equal to",
  //   value: String(Condition.LessOrEqual),
  // },
  // {
  //   name: "Less than",
  //   value: String(Condition.Less),
  // },
  // {
  //   name: "Greater than or equal to",
  //   value: String(Condition.GreaterOrEqual),
  // },
  // {
  //   name: "Greater than",
  //   value: String(Condition.Greater),
  // },
  // {
  //   name: "Exists",
  //   value: String(Condition.Exists),
  // },
  // {
  //   name: "Not exists",
  //   value: String(Condition.NotExists),
  // },
  {
    name: "Contains",
    value: String(Condition.Contains),
  },
  // {
  //   name: "Not contains",
  //   value: String(Condition.NotContains),
  // },
];

export const DEFAULT_FAMILY_FILTER = {
  attribute: Object.values(FamilyInstanceKey)[0],
  condition: Condition.Contains,
  value: "",
};
