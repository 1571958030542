import {
  Box,
  Flex,
  Link,
  TableCellProps,
  Td,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { DEFAULT_BLACKBOARD_TEMPLATE_FONT_SIZE_SCALE } from "constants/blackBoardTemplate";
import {
  CellProperty,
  ContentType,
  FontFamiLyType,
  TableDefaultStyle,
  TableElementType,
  TemplateComponentType,
  TextPosition,
} from "constants/enum";
import { MCE_EDITABLE_CLASSNAME } from "constants/styleProps";
import { DocumentItemDTO } from "interfaces/dtos/documentItemDTO";
import { Blackboard } from "interfaces/models/blackboard";
import {
  CellSize,
  CellType,
  DisplayComponent,
  TableStyleType,
  TemplateComponent,
} from "interfaces/models/component";
import { DocumentItem } from "interfaces/models/documentItem";
import { DocumentTemplate } from "interfaces/models/documentTemplate";
import {
  DEFAULT_BORDER_COLOR_ACTIVE,
  DEFAULT_FONT_SIZE,
} from "pages/document/template-page/hooks";
import { STEP_TAB } from "pages/document/template-page/RightPanel/TextStyle";
import { memo, useMemo } from "react";
import { DocumentState } from "redux/documentSlice";
import { getIndexCell } from "utils/document";
import { getPreviewDocumentCommonProps, iPreviewDocumentCommonProps } from ".";
import NormalTextPreview from "../NormalTextPreview";
import CrossLine from "./CrossLine";
import useGetContentTypeBlackboard from "./hooks/useGetContentTypeBlackboard";
import useGetContentTypeDocumentTaskData from "./hooks/useGetContentTypeDocumentTaskData";
import useGetContentTypeEditor from "./hooks/useGetContentTypeEditor";
import useGetContentTypeFlexibleDuct from "./hooks/useGetContentTypeFlexibleDuct";
import useGetContentTypeLinkedImage from "./hooks/useGetContentTypeLinkedImage";
import useGetContentTypeModuleChiller from "./hooks/useGetContentTypeModuleChiller";
import useGetContentTypePac from "./hooks/useGetContentTypePac";
import useGetContentTypePreview from "./hooks/useGetContentTypePreview";
import useGetContentTypeSleevePipe from "./hooks/useGetContentTypeSleevePipe";
import useGetContentTypeTaskItemData from "./hooks/useGetContentTypeTaskItemData";
import useTableTdComponentPreview from "./hooks/useTableTdComponentPreview";

export interface iTableTdComponentPreview
  extends iUseTableTdComponentPreview,
    iPreviewDocumentCommonProps {
  isLastColumn?: boolean;
  isLastRow?: boolean;
  isSelected: boolean;
  isOnlyView?: boolean;
  isRepeatedTable?: boolean;
  isBlackboardTemplate?: boolean;
  isBlackboardTemplateImage?: boolean;
  isTaskSheetTemplate?: boolean;
  isTaskSheetTemplateItem?: boolean;

  width: number;
  height: number;
  rowIndex: number;

  borderColor: string;

  displaySize: DisplayComponent["size"];
  tdProps?: Partial<TableCellProps>;

  numOfColumns?: number;
  columnIndex?: number;
  prevCellEl?: CellType;
  nextCellEl?: CellType;
  numOfRows?: number;
  hasKeyNote?: boolean;
  isDisabled?: boolean;

  onChangeBlackboardData?: (data: Blackboard) => void;
}

export interface iUseTableTdComponentPreview {
  isResizing: boolean;
  isComponentDragging: boolean;
  canEditCell: boolean;
  isTableSelected: boolean;
  isDuplicateRow: boolean;
  isBlackboardTemplate?: boolean;
  isBlackboardTemplateImage?: boolean;
  isTaskSheetTemplateItem?: boolean;

  trId: string;
  tableId: string;

  pageDirectionRatio: number;
  sizePageRatio: number;
  zoomRatio: number;

  displaySize: DisplayComponent["size"];
  component: TemplateComponent;
  cellSize: CellSize;
  cell: CellType;
  selectedCells: CellType[];
  components: TemplateComponent[];
  documentContainerSize: DocumentState["documentContainerSize"];
  currentTemplate: DocumentTemplate;
  componentSelected: TemplateComponent;
  numOfColumns?: number;
  columnIndex?: number;
  rowIndex?: number;
  isLastColumn?: boolean;
  isLastRow?: boolean;
  isSelected?: boolean;
  borderColor?: string;
  prevCellEl?: CellType;
  nextCellEl?: CellType;
  isResizable?: boolean;
  onSelectCell: (e: React.MouseEvent<HTMLElement>, cell: CellType) => void;
}

export interface iLimit {
  width: number;
  height: number;
}

export interface iUseGetContentOfCell extends iPreviewDocumentCommonProps {
  isTaskSheetTemplate?: boolean;
  isDuplicateRow: boolean;
  isLastColumn: boolean;
  isBlackboardTemplate?: boolean;
  isBlackboardTemplateImage?: boolean;
  isTaskSheetTemplateItem?: boolean;
  isComponentDragging: boolean;
  cell: CellType;
  contentType: ContentType;
  currentTemplate: DocumentTemplate;
  component: TemplateComponent;
  css: TableStyleType;
  zoomRatio: number;
  width: number;
  height: number;
  displaySize: DisplayComponent["size"];
  pageDirectionRatio: number;
  sizePageRatio: number;
  limit: iLimit;
  cellSize: CellSize;
  isOnlyView: boolean;
  components: TemplateComponent[];
  selectedCells: CellType[];
  componentSelected: TemplateComponent;
  documentContainerSize: DocumentState["documentContainerSize"];
  fontSize?: string;
  hasKeyNote?: boolean;
  isDisabled?: boolean;

  onChangeBlackboardData?: (data: Blackboard) => void;
}

const TableTdComponentPreview = (props: iTableTdComponentPreview) => {
  const {
    cell,
    width,
    height,
    component,
    displaySize,
    borderColor,
    zoomRatio,
    cellSize,
    isDuplicateRow,
    isComponentDragging,
    canEditCell,
    selectedCells,
    components,
    sizePageRatio,
    pageDirectionRatio,
    documentContainerSize,
    isTableSelected,
    trId,
    tableId,
    currentTemplate,
    componentSelected,
    isResizing,
    isOnlyView = false,
    isLastColumn,
    isLastRow,
    isRepeatedTable = false,
    rowIndex,
    isSelected,
    isBlackboardTemplateImage = false,
    isBlackboardTemplate = false,
    isTaskSheetTemplate = false,
    isTaskSheetTemplateItem = false,
    tdProps,
    numOfColumns,
    columnIndex,
    prevCellEl,
    nextCellEl,
    isResizable,
    hasKeyNote,
    isDisabled,
    onChangeBlackboardData,
    onSelectCell,
    ...rest
  } = props;

  const { contentType } = getPreviewDocumentCommonProps(rest);

  const {
    shouldShowInput,
    inputRef,
    name,
    limit,
    _borderColor,
    borderStyle,
    borderWidth,
    isShowCopiedLabel,

    resizeMouseDown,
    handleShowEditInput,
    handleChangeName,
    handleOnClick,
    handleBlurName,
    targetLink,
  } = useTableTdComponentPreview({
    isTaskSheetTemplateItem,
    isBlackboardTemplateImage,
    isBlackboardTemplate,
    displaySize,
    zoomRatio,
    component,
    cellSize,
    cell,
    isComponentDragging,
    isDuplicateRow,
    canEditCell,
    selectedCells,
    components,
    sizePageRatio,
    pageDirectionRatio,
    documentContainerSize,
    isTableSelected,
    trId,
    tableId,
    currentTemplate,
    componentSelected,
    isResizing,
    numOfColumns,
    columnIndex,
    rowIndex,
    isLastColumn,
    isLastRow,
    isSelected,
    borderColor,
    prevCellEl,
    nextCellEl,
    isResizable,
    onSelectCell,
  });

  const css = cell?.style;
  const fontSize = useMemo(() => {
    return `${Math.round(
      (css?.fontSize || 14) *
        zoomRatio *
        (isBlackboardTemplateImage
          ? DEFAULT_BLACKBOARD_TEMPLATE_FONT_SIZE_SCALE
          : 1)
    )}px`;
  }, [css?.fontSize, zoomRatio, isBlackboardTemplateImage]);

  const useGetContentOfCellProps = {
    isOnlyView,
    isTaskSheetTemplate,
    isBlackboardTemplateImage,
    isBlackboardTemplate,
    isTaskSheetTemplateItem,
    isDuplicateRow,
    isLastColumn: !!isLastColumn,
    limit,
    displaySize,
    width,
    height,
    pageDirectionRatio,
    sizePageRatio,
    cell,
    currentTemplate,
    component,
    css: cell?.style || {},
    zoomRatio,
    cellSize,
    components,
    selectedCells,
    componentSelected,
    documentContainerSize,
    isComponentDragging,
    fontSize,
    isDisabled,

    hasKeyNote,
    onChangeBlackboardData,
    ...getPreviewDocumentCommonProps(rest),
  };

  const { contentOfCellTypeEditor } = useGetContentTypeEditor(
    useGetContentOfCellProps
  );
  const { contentOfCellTypePreivew } = useGetContentTypePreview(
    useGetContentOfCellProps
  );
  const { contentOfCellTypePac } = useGetContentTypePac(
    useGetContentOfCellProps
  );
  const { contentOfCellTypeSleevePipe } = useGetContentTypeSleevePipe(
    useGetContentOfCellProps
  );
  const { contentOfCellTypeModuleChiller } = useGetContentTypeModuleChiller(
    useGetContentOfCellProps
  );
  const { contentOfCellTypeBlackboard } = useGetContentTypeBlackboard(
    useGetContentOfCellProps
  );
  const { contentOfCellTypeLinkedImage } = useGetContentTypeLinkedImage(
    useGetContentOfCellProps
  );
  const { contentOfCellTypeFlexibleDuct } = useGetContentTypeFlexibleDuct(
    useGetContentOfCellProps
  );

  const { contentOfCellTypeDocumentTaskData } =
    useGetContentTypeDocumentTaskData(useGetContentOfCellProps);

  const { contentOfCellTypeTaskItemData } = useGetContentTypeTaskItemData(
    useGetContentOfCellProps
  );

  const isLinkedImageLine =
    cell?.cellProperty === CellProperty.LINKED_IMAGE_LINE;

  // props for Td
  const textDecoration = useMemo(() => {
    return css?.underline && css?.lineThrough
      ? "underline line-through"
      : css?.underline
      ? "underline"
      : css?.lineThrough
      ? "line-through"
      : "";
  }, [css?.lineThrough, css?.underline]);

  const backgroundColor = useMemo(() => {
    if (
      cell?.subTable?.rows?.length ||
      (!css?.backgroundColor && component?.detail?.style?.backgroundColor) ||
      css?.backgroundColor === component?.detail?.style?.backgroundColor
    ) {
      return "initial";
    }

    return css?.backgroundColor || "#fff";
  }, [
    css?.backgroundColor,
    component?.detail?.style?.backgroundColor,
    cell.subTable,
  ]);

  const tdStyle: TableCellProps["style"] = useMemo(() => {
    return {
      position: "relative",
      padding: 0,
      width: `${
        (width / component.size.width) * displaySize.width -
        TableDefaultStyle.DEFAULT_BORDER_SIZE
      }px`,
      height:
        (height / component.size.height) * displaySize.height -
        TableDefaultStyle.DEFAULT_BORDER_SIZE,
      borderColor: _borderColor,
      borderWidth,
      borderStyle,
      justifyContent: css?.justifyContent || TextPosition.START,
      fontWeight: css?.bold ? "bold" : "normal",
      fontStyle: css?.italic ? "italic" : "",
      fontFamily:
        css?.fontFamily === FontFamiLyType.NOTO_SANS
          ? `NotoSansCJKjp`
          : css?.fontFamily === FontFamiLyType.NOTO_SERIF
          ? `Noto Serif JP, serif`
          : "",
      textDecoration:
        css?.underline && css?.lineThrough
          ? "underline line-through"
          : css?.underline
          ? "underline"
          : css?.lineThrough
          ? "line-through"
          : "",
      fontSize,
      color: css?.color ? css?.color : "",
      backgroundColor,
      whiteSpace: "pre-wrap",
      textOverflow: "ellipsis",
      overflowWrap: "anywhere",
      lineHeight: "1.2",
    };
  }, [
    width,
    component,
    displaySize,
    height,
    backgroundColor,
    _borderColor,
    borderWidth,
    borderStyle,
    css,
    fontSize,
  ]);

  const newTdProps = useMemo((): Partial<TableCellProps> => {
    const defaultValue: Partial<TableCellProps> = {
      className: isBlackboardTemplate && "td-blackboard-template-image",
      id: cell.cellId,
      rowSpan: cell?.rowSpan,
      colSpan: cell?.colSpan,
      "data-property": cell?.cellProperty,
      "data-font-size": cell?.style?.fontSize || DEFAULT_FONT_SIZE,
      "data-field-name": cell?.cellLinkedData?.field,
      "data-field-content": cell?.value,
      "data-options": cell?.cellLinkedData?.options?.valueOfCheckbox || "",
      ...tdProps,
      style: { ...tdStyle, ...tdProps?.style },
    } as any;

    if (isOnlyView) {
      return defaultValue;
    }

    return {
      ...defaultValue,
      onDoubleClick: handleShowEditInput,
      ...(cell.cellProperty &&
      ![
        CellProperty.LINKED_IMAGE_LINE,
        CellProperty.LINKED_IMAGE_DEFAULT_TEXT,
      ].includes(cell.cellProperty as any)
        ? { onClick: handleOnClick }
        : {}),
    };
  }, [
    cell,
    tdStyle,
    isOnlyView,
    isBlackboardTemplate,
    tdProps,
    handleOnClick,
    handleShowEditInput,
  ]);

  const boxContentContainerStyle: TableCellProps["style"] = useMemo(
    () => ({
      position: "absolute",
      top: 0,
      left: 0,
      display: "flex",
      justifyContent: css?.justifyContent || TextPosition.CENTER,
      alignItems: TextPosition.CENTER,
      width: "100%",
      height: "100%",
      textAlign:
        css?.justifyContent === TextPosition.START
          ? TextPosition.LEFT
          : css?.justifyContent === TextPosition.END
          ? TextPosition.RIGHT
          : TextPosition.CENTER,
      wordWrap: "inherit",
      textDecoration: textDecoration,
      padding:
        css?.justifyContent !== TextPosition.JUSTIFY
          ? 0
          : `0 ${5 * zoomRatio}px`,
    }),
    [css, textDecoration, zoomRatio]
  );

  const renderInputToChangeTitle = useMemo(() => {
    if (isOnlyView || !shouldShowInput) {
      return null;
    }

    return (
      <Textarea
        position="relative"
        zIndex="2"
        rows={1}
        ref={inputRef}
        style={{
          width: "100%",
          height: "100%",
          textDecoration: textDecoration,
        }}
        maxH={`${height - 1}px`}
        p="0px 5px"
        resize="none"
        overflow="hidden"
        fontSize={fontSize}
        border="none"
        overflowY="auto"
        outline="none"
        _focus={{
          borderColor: "none",
        }}
        value={name}
        onChange={handleChangeName}
        onBlur={handleBlurName}
        borderRadius="0px"
        className="box-scroll-bar text-area-scroll-bar"
        isDisabled={isDisabled}
      />
    );
  }, [
    shouldShowInput,
    fontSize,
    textDecoration,
    name,
    inputRef,
    isOnlyView,
    height,
    handleBlurName,
    handleChangeName,
    isDisabled,
  ]);

  const renderHideBorderRight = useMemo(() => {
    if (!css?.border || css?.borderRight || isSelected) {
      return <></>;
    }
    if (isLastColumn && isTableSelected) {
      return <></>;
    }

    if (selectedCells?.length) {
      const currentIndex = getIndexCell(cell);
      const selectedIndex = getIndexCell(selectedCells?.[0]);

      if (
        currentIndex.row === selectedIndex.row &&
        currentIndex.col + 1 === selectedIndex.col
      ) {
        return <></>;
      }
    }

    return (
      <Box
        style={{
          position: "absolute",
          zIndex: 1,
          right: "-1px",
          top: "0px",
          width: "1px",
          height: "100%",
          backgroundColor: css?.backgroundColor || "#fff",
        }}
      ></Box>
    );
  }, [
    isLastColumn,
    isTableSelected,
    css?.border,
    css?.borderRight,
    css?.backgroundColor,
    isSelected,
    cell,
    selectedCells,
  ]);

  const renderHideBorderTop = useMemo(() => {
    if (!css?.border || css?.borderTop || isSelected) {
      return <></>;
    }
    const currentIndex = getIndexCell(cell);
    if (isTableSelected && currentIndex.row === 0) {
      return <></>;
    }

    if (selectedCells?.length) {
      const selectedIndex = getIndexCell(selectedCells?.[0]);

      if (
        currentIndex.row - 1 === selectedIndex.row &&
        currentIndex.col === selectedIndex.col
      ) {
        return <></>;
      }
    }

    return (
      <Box
        style={{
          position: "absolute",
          zIndex: 1,
          right: "0px",
          top: "-1px",
          width: "100%",
          height: "1px",
          backgroundColor: css?.backgroundColor || "#fff",
        }}
      ></Box>
    );
  }, [
    isTableSelected,
    css?.border,
    css?.borderTop,
    css?.backgroundColor,
    isSelected,
    cell,
    selectedCells,
  ]);

  const renderHideBorderBottom = useMemo(() => {
    if (!css?.border || css?.borderBottom || isSelected) {
      return <></>;
    }

    if (isLastRow && isTableSelected) {
      return <></>;
    }

    if (selectedCells?.length) {
      const currentIndex = getIndexCell(cell);
      const selectedIndex = getIndexCell(selectedCells?.[0]);

      if (
        currentIndex.row + 1 === selectedIndex.row &&
        currentIndex.col === selectedIndex.col
      ) {
        return <></>;
      }
    }

    return (
      <Box
        style={{
          position: "absolute",
          zIndex: 1,
          left: "0px",
          bottom: "-1px",
          width: "100%",
          height: "1px",
          backgroundColor: css?.backgroundColor || "#fff",
        }}
      ></Box>
    );
  }, [
    isLastRow,
    isTableSelected,
    css?.border,
    css?.borderBottom,
    css?.backgroundColor,
    isSelected,
    cell,
    selectedCells,
  ]);

  const renderHideBorderLeft = useMemo(() => {
    if (!css?.border || css?.borderLeft || isSelected) {
      return <></>;
    }
    const currentIndex = getIndexCell(cell);
    if (isTableSelected && currentIndex.col === 0) {
      return <></>;
    }

    if (selectedCells?.length) {
      const selectedIndex = getIndexCell(selectedCells?.[0]);

      if (
        currentIndex.row === selectedIndex.row &&
        currentIndex.col - 1 === selectedIndex.col
      ) {
        return <></>;
      }
    }

    return (
      <Box
        style={{
          position: "absolute",
          zIndex: 1,
          left: "-1px",
          top: "0px",
          width: "1px",
          height: "100%",
          backgroundColor: css?.backgroundColor || "#fff",
        }}
      ></Box>
    );
  }, [
    isTableSelected,
    css?.border,
    css?.borderLeft,
    css?.backgroundColor,
    isSelected,
    cell,
    selectedCells,
  ]);

  const renderAttach = useMemo(() => {
    if (!cell?.style?.attach) {
      return null;
    }

    return (
      <Box
        style={{
          height: "fit-content",
          minHeight: "1em",
          textAlign: css?.justifyContent || (TextPosition.CENTER as any),
          paddingLeft: `${0.5 * zoomRatio}rem`,
          paddingRight: `${0.5 * zoomRatio}rem`,
          minWidth: "2rem",
        }}
      >
        <Link
          style={{
            position: "relative",
            zIndex: "1",
            cursor: "pointer",
            outline: "none",
            textDecoration,
            border: "none",
            color: "#4589eb",
          }}
          isExternal
          onClick={targetLink}
          href={cell.style.attach}
          target="_blank"
          sx={{
            "&:focus": {
              boxShadow: "none",
            },
          }}
        >
          {cell?.value}
        </Link>
      </Box>
    );
  }, [cell, targetLink, textDecoration, css?.justifyContent, zoomRatio]);

  const templateProps = useMemo(() => {
    const { sleevePipeProps, pacProps, flexibleDuctProps, moduleChillerProps } =
      props;

    switch (contentType) {
      case ContentType.PHOTO_LEDGER:
        return sleevePipeProps;
      case ContentType.PAC:
        return pacProps;
      case ContentType.SELF_INSPECTION:
        return flexibleDuctProps;
      case ContentType.EQUIPMENT_DATA_SHEET:
        return moduleChillerProps;
      default:
        return;
    }
  }, [contentType, props]);

  const contentDefaultOfCell = useMemo(() => {
    if (css?.crossLine) {
      return <CrossLine />;
    }

    if (cell?.isRepeatedTable) {
      const numberOfRows = component?.detail?.rows?.length || 0;
      const rowIndex = templateProps?.option?.rowIndex || 0;
      const numberOfDocumentItem =
        (templateProps as any)?.displayItems?.length || 0;
      const repeatedTableIndex = cell?.repeatedTableIndex || 0;
      const offsetItemLinked = templateProps?.offsetItemLinked || 0;
      const numOfRepeatTable = component?.detail?.numOfRepeatTable || 0;

      if (
        templateProps &&
        rowIndex +
          1 +
          offsetItemLinked * numOfRepeatTable +
          repeatedTableIndex * numberOfRows >
          numberOfDocumentItem &&
        numberOfDocumentItem > 0
      ) {
        return <></>;
      }
    }

    const isEdiTemplate = window.location.pathname.includes(
      "document-template/edit"
    );

    const isRenderTextNormal = [
      cell.cellProperty,
      cell?.cellLinkedData?.field,
    ].includes(CellProperty.NO);
    const dynamicFieldValue = cell?.cellLinkedData?.options?.dynamicFieldValue;
    const isTableHeader = component?.type === TemplateComponentType.TableHeader;

    // show default value for dynamic field
    if (
      !cell.subTable?.rows?.length &&
      cell.value &&
      ((isRenderTextNormal && contentType === ContentType.EDITOR) ||
        !isRenderTextNormal)
    ) {
      let newValue = cell.value;

      if (dynamicFieldValue && !isTableHeader && !isEdiTemplate) {
        const headerComponent = templateProps?.headerComponent || [];
        const limitItemTableLinked =
          (templateProps as any)?.limitItemTableLinked || 0;
        const offsetItemLinked = templateProps?.offsetItemLinked || 0;
        const numOfRepeatTable = component?.detail?.numOfRepeatTable || 0;
        const cellIndex = templateProps?.option?.cellIndex || 0;
        const repeatedTableIndex = cell?.repeatedTableIndex || 0;
        let rowIndex =
          (templateProps?.option?.rowIndex || 0) +
          offsetItemLinked * numOfRepeatTable;
        let headerCellId = headerComponent?.[cellIndex]?.cellId;

        if (cell?.repeatedFromCellId) {
          rowIndex = rowIndex + limitItemTableLinked * repeatedTableIndex;
          headerCellId = headerComponent?.[cellIndex]?.repeatedFromCellId || "";
        }

        const documentItems: DocumentItemDTO[] =
          (templateProps as any)?.displayItems ||
          (templateProps as any)?.documentItems ||
          [];

        const item = documentItems;
        const documentItem: DocumentItem = item?.[rowIndex] || [];
        let itemId = documentItem?.id;
        let dataDynamicField = documentItem?.data || {};

        if (contentType === ContentType.SELF_INSPECTION) {
          const subItems =
            documentItems.find(
              (item) => item.linkedDataId === component?.subcategoryIdSelected
            )?.subItems || [];
          const subItem = subItems?.[rowIndex];
          dataDynamicField = subItem?.data || {};
          itemId = subItem?.id;
        }

        const key = `${headerCellId}-${itemId}`;
        newValue = dataDynamicField?.[key] || "";
      }

      if (css?.justifyContent !== TextPosition.JUSTIFY) {
        return (
          <NormalTextPreview
            id={`text-${cell.cellId}`}
            zoomRatio={zoomRatio}
            isAutoResize={
              !(
                isBlackboardTemplate ||
                isBlackboardTemplateImage ||
                isTaskSheetTemplateItem
              )
            }
            data={{
              ...cell,
              value: newValue,
            }}
            style={{
              height: "fit-content",
              minHeight: "1em",
              textAlign: css?.justifyContent || (TextPosition.CENTER as any),
              paddingLeft: `${0.5 * zoomRatio}rem`,
              paddingRight: `${0.5 * zoomRatio}rem`,
              minWidth: "2rem",
              color: css?.color
                ? css?.color
                : isEdiTemplate
                ? "#A3A3A3"
                : "inherit",
            }}
          />
        );
      }

      // render multiple line text spacing
      const characterStrings =
        cell?.value?.replace(/ /g, "\u00A0").split("\n") || [];

      const renderTab = () => {
        const count = (css?.tab || 0) / STEP_TAB;

        return new Array(count)
          .fill(0)
          .map(() => "\t")
          .join("");
      };

      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "fit-content",
          }}
        >
          {characterStrings.map((characterString, index) => {
            const characters = characterString?.split("") || [];

            return (
              <div
                key={`${characterString}-${index}`}
                className={MCE_EDITABLE_CLASSNAME}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                  width: "100%",
                  height: "100%",
                }}
              >
                {characters.map((c, i) => (
                  <p key={`${c}-${i}`}>{`${
                    i === 0 && index === 0 ? renderTab() : ""
                  }${c}`}</p>
                ))}
              </div>
            );
          })}
        </div>
      );
    }

    switch (contentType) {
      case ContentType.EDITOR:
        return contentOfCellTypeEditor(cell);

      case ContentType.PREVIEW:
        return contentOfCellTypePreivew(cell);

      case ContentType.PAC:
        return contentOfCellTypePac(cell);

      case ContentType.EQUIPMENT_DATA_SHEET:
        return contentOfCellTypeModuleChiller(cell);

      case ContentType.BLACKBOARD_TEMPLATE:
        return contentOfCellTypeBlackboard(cell);

      case ContentType.LINKED_IMAGE:
        return contentOfCellTypeLinkedImage(cell);

      case ContentType.PHOTO_LEDGER:
        return contentOfCellTypeSleevePipe(cell);

      case ContentType.SELF_INSPECTION:
        return contentOfCellTypeFlexibleDuct(cell);

      case ContentType.DOCUMENT_TASK_DATA:
        return contentOfCellTypeDocumentTaskData(cell);

      case ContentType.TASK_ITEM_DATA:
        return contentOfCellTypeTaskItemData(cell);

      default:
        break;
    }
  }, [
    isTaskSheetTemplateItem,
    isBlackboardTemplateImage,
    isBlackboardTemplate,
    cell,
    contentType,
    css,
    zoomRatio,
    contentOfCellTypeLinkedImage,
    contentOfCellTypeEditor,
    contentOfCellTypePreivew,
    contentOfCellTypePac,
    contentOfCellTypeModuleChiller,
    contentOfCellTypeBlackboard,
    contentOfCellTypeSleevePipe,
    contentOfCellTypeFlexibleDuct,
    contentOfCellTypeDocumentTaskData,
    contentOfCellTypeTaskItemData,
    component,
    templateProps,
  ]);

  const renderContentCell = useMemo(() => {
    if (shouldShowInput || cell?.style?.attach) {
      return null;
    }

    return (
      <Box className="mceNonEditable" style={boxContentContainerStyle}>
        {contentDefaultOfCell}
      </Box>
    );
  }, [contentDefaultOfCell, cell, shouldShowInput, boxContentContainerStyle]);

  const renderEdgeToResize = useMemo(() => {
    if (shouldShowInput || isDuplicateRow || isOnlyView || isRepeatedTable) {
      return null;
    }

    return (
      <Box position="absolute" width="100%" height="100%" top="0" left="0">
        {!isLastColumn && (
          <Box
            className="resize-column"
            onMouseDown={(e) => resizeMouseDown(e, cell, TableElementType.COL)}
            onClick={(e) => {
              e.stopPropagation();
            }}
          />
        )}

        {!isLastRow && !component.linkedHeaderId && (
          <Box
            className="resize-row"
            onMouseDown={(e) => resizeMouseDown(e, cell, TableElementType.ROW)}
            onClick={(e) => {
              e.stopPropagation();
            }}
          />
        )}
      </Box>
    );
  }, [
    component.linkedHeaderId,
    shouldShowInput,
    isLastRow,
    isLastColumn,
    isOnlyView,
    isDuplicateRow,
    isRepeatedTable,
    cell,
    resizeMouseDown,
  ]);

  return (
    <Td {...newTdProps}>
      {renderContentCell}
      {renderInputToChangeTitle}
      {renderAttach}
      {component.type !== TemplateComponentType.LinkedImage &&
        renderEdgeToResize}

      {renderHideBorderRight}
      {renderHideBorderTop}
      {renderHideBorderBottom}
      {renderHideBorderLeft}

      {isLinkedImageLine && (
        <Flex alignItems="center">
          <Box
            style={{
              border: "1px dashed rgba(128, 128, 128, 0.7)",
              width: "100%",
              height: "100%",
            }}
          />
        </Flex>
      )}

      {isShowCopiedLabel && (
        <Text
          position="absolute"
          top="0"
          right="0"
          bgColor={DEFAULT_BORDER_COLOR_ACTIVE}
          width="fit-content"
          height="fit-content"
          p="2px"
          color="white"
          fontSize="1.2rem"
        >
          リピート
        </Text>
      )}
    </Td>
  );
};

export default memo(TableTdComponentPreview);
