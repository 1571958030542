import { Box, BoxProps, Text } from "@chakra-ui/react";
import { message } from "components/base";
import useSelectBlackboardTemplateModal from "components/modal/SelectBlackboardTemplateModal/hook";
import { DEFAULT_BLACKBOARD_WIDTH } from "components/ui/BlackboardImage";
import BlackboardTemplateImage from "components/ui/BlackboardTemplateImage";
import {
  BlackboardTemplateBackground,
  BlackboardTemplateBackgroundType,
} from "constants/blackBoardTemplate";
import { BLACKBOARD_SCALE_DEFAULT } from "constants/document";
import { TemplateComponentType } from "constants/enum";
import { CellType, TemplateComponent } from "interfaces/models/component";
import { memo, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { getIndexFromId, getScaleBlackboard } from "utils/document";

interface Props {
  component: TemplateComponent;
  cellData: CellType;
  zoomRatio: number;
  isNormalImage?: boolean;
}

const ImageCell = ({
  component,
  cellData,
  zoomRatio,
  isNormalImage,
}: Props) => {
  const [scale, setScale] = useState(BLACKBOARD_SCALE_DEFAULT);

  const { blackboardTemplateList, components } = useSelector(
    (state: RootState) => state.document
  );

  const blackboardRef = useRef<HTMLDivElement>(null);

  const { onOpenSelectBlackboardTemplateModal } =
    useSelectBlackboardTemplateModal();

  const isCanToOpenSelectBlackboardModal = useMemo(() => {
    const cellIndex = {
      col: getIndexFromId(cellData?.idColumn),
      row: getIndexFromId(cellData?.idRow),
    };
    let isValid = cellIndex.row === 0;
    if (component.type === TemplateComponentType.LinkedImage) {
      isValid = cellIndex.row === 1 && cellIndex.col === 0;
    }

    return isValid;
  }, [cellData.idColumn, cellData.idRow, component.type]);

  useEffect(() => {
    if (!component.detail?.blackBoardTemplateId || !blackboardRef.current) {
      return;
    }

    const result = getScaleBlackboard(blackboardRef.current, {
      width: cellData.width!,
      height: cellData.height!,
    });

    setScale(result);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cellData, blackboardRef.current]);

  const renderBlackboard = useMemo(() => {
    if (isNormalImage) {
      return;
    }

    const blackboardTemplate = blackboardTemplateList?.find(
      (b) => b.id === component?.detail?.blackBoardTemplateId
    );

    const blackboardTemplateBackground =
      BlackboardTemplateBackground[
        blackboardTemplate?.backgroundType ||
          BlackboardTemplateBackgroundType.GREEN
      ]?.backgroundColor;

    const handleBeforeSelectBlackboard = () => {
      const isExistsComponentHasTemplate = components.filter(
        (com) =>
          com.detail?.blackBoardTemplateId &&
          com.componentId !== component.componentId
      ).length;
      if (isExistsComponentHasTemplate) {
        message.warning(
          "黒板はすでに存在しているため追加することはできません。"
        );
      } else {
        onOpenSelectBlackboardTemplateModal();
      }
    };

    const commonProps: BoxProps = isCanToOpenSelectBlackboardModal
      ? {
          cursor: "pointer",
          onClick: handleBeforeSelectBlackboard,
        }
      : {
          cursor: "initial",
        };

    if (!blackboardTemplate?.id) {
      return (
        <Box
          position="absolute"
          left={0}
          bottom={0}
          display="flex"
          justifyContent="center"
          alignItems="center"
          h="30%"
          w="30%"
          borderRight="1px solid gray"
          borderTop="1px solid gray"
          overflow="hidden"
          {...commonProps}
        >
          <Text fontSize={`${1.4 * zoomRatio}rem`}>黒板を配置</Text>
        </Box>
      );
    }

    return (
      <Box
        ref={blackboardRef}
        position="absolute"
        left="0px"
        bottom="0px"
        zIndex="1"
        w={`${DEFAULT_BLACKBOARD_WIDTH}px`}
        style={{
          aspectRatio: "calc(876 / 656)",
          transform: `scale(${(scale || 1) * zoomRatio})`,
          transformOrigin: "bottom left",
        }}
        backgroundColor={blackboardTemplateBackground}
        {...commonProps}
      >
        <Box
          position="relative"
          width="100%"
          height="100%"
          pointerEvents="none"
          boxShadow="rgba(0, 0, 0, 0.3) 0px 0px 9px"
        >
          <BlackboardTemplateImage data={blackboardTemplate} />
        </Box>
      </Box>
    );
  }, [
    components,
    isCanToOpenSelectBlackboardModal,
    scale,
    blackboardTemplateList,
    component.componentId,
    component?.detail?.blackBoardTemplateId,
    isNormalImage,
    zoomRatio,
    onOpenSelectBlackboardTemplateModal,
  ]);

  return (
    <Box
      position="relative"
      h="100%"
      w="100%"
      m={0}
      p={0}
      overflow="hidden"
      zIndex={2}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        h="100%"
        w="100%"
      >
        <Text
          fontSize={`${1.4 * zoomRatio}rem`}
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          width="0px"
          flex={1}
        >
          写真を配置
        </Text>
      </Box>

      {renderBlackboard}
    </Box>
  );
};

export default memo(ImageCell);
