import {
  Box,
  BoxProps,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Portal,
  Radio,
  RadioGroup,
  Spinner,
  Stack,
  Text,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import DropdownHover from "components/DropdownHover";
import FileUpload from "components/FileUpload";
import FormUploadFile from "components/FormUploadFile";
import SelectUserSingle from "components/input/SelectUserSingle";
import CameraModal from "components/modal/CameraModal";
import ConfirmModal from "components/modal/ConfirmDeleteModal";
import ContentIndicationModal from "components/modal/ContentIndicationModal";
import DrawToolModal from "components/modal/DrawToolModal";
import { SvgIcon } from "components/SvgIcon";
import TooltipForgeView from "components/TooltipForgeView";
import CollapseModalIcon, {
  useCollapseModal,
} from "components/ui/CollapseModalIcon";
import DayPicker from "components/ui/DayPicker";
import Slider from "components/ui/Slider";
import { TagsInput } from "components/ui/TagInput";
import { SelectUserSingleSx } from "constants/dropdown";
import {
  InspectionItemType,
  MapInspectionItemColor,
  MapInspectionItemIcon,
  MapInspectionItemType,
  TASK_PRINT_MODE,
} from "constants/enum";
import { TypeOfFile } from "constants/file";
import {
  buttonOutlineStyle,
  radioDefaultCSS,
  RIGHT_SIDEBAR_MODAL_CLASSNAME,
} from "constants/styleProps";
import { DEFAULT_TASK_MODAL_INFO } from "constants/task";
import { saveAs } from "file-saver";
import { useAppWebSocket } from "hooks/useAppWebSocket";
import { useDrawTool } from "hooks/useDrawTool";
import { usePermission, useRoles } from "hooks/usePermission";
import { useResize } from "hooks/useResize";
import { TaskDTO } from "interfaces/dtos/taskDTO";
import { FileModel } from "interfaces/models";
import { PartnerCompany } from "interfaces/models/partnerCompany";
import isEqual from "lodash/isEqual";
import { LeftPanelHandleType } from "pages/forge-viewer/LeftPanel";
import {
  forwardRef,
  Fragment,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { StylesConfig } from "react-select/dist/declarations/src";
import { RootState } from "redux/store";
import { sortArrayByField } from "utils/array";
import { getPreSignUrl } from "utils/file";
import { ClickInfo } from "utils/forge/extensions/click-extension";
import Comment from "./Comment";
import { PreviewFileComponent } from "./Comment/PreviewFileComponent";
import useTaskModal from "./hooks";
import useChat from "./useChat";
import useDropdownDirection from "./useDropdownDirection";
import useHandleMoveTaskLabel from "./useHandleMoveTaskLabel";
import { IconBase } from "components/base";

export interface Props extends BoxProps {
  task: TaskDTO | undefined;
  filterTasks: TaskDTO[];
  isOpen: boolean;
  leftPanelRef?: React.RefObject<LeftPanelHandleType>;
  headerHeight?: string;
  contentHeight?: string;
  imageCaptured: React.MutableRefObject<File | undefined>;
  clickInfo?: ClickInfo;
  partnerCompanies?: PartnerCompany[];
  clickedLabelInfo: any;
  setClickInfo: React.Dispatch<React.SetStateAction<ClickInfo | undefined>>;
  forgeViewContainerRef: React.RefObject<HTMLDivElement>;
  isFetchingPartnerCompanies?: boolean;
  onClose: () => void;
}

const selectStyles: StylesConfig = {
  menuList: (base) => ({
    ...base,
    maxHeight: "200px",
  }),
};

export type TaskModalHandleType = {
  handleCollapse: () => void;
};

const TaskModal = forwardRef<TaskModalHandleType, Props>(
  (
    {
      leftPanelRef,
      task,
      filterTasks,
      forgeViewContainerRef,
      isOpen,
      headerHeight = "var(--header-height)",
      contentHeight,
      imageCaptured,
      clickInfo,
      partnerCompanies = [],
      onClose,
      setClickInfo,
      clickedLabelInfo,
      isFetchingPartnerCompanies,
      ...rest
    }: Props,
    ref
  ) => {
    const { sendWebSocketMessage, webSocketMessage } = useAppWebSocket();
    const [isDownloadOriginImage, setIsDownloadOriginImage] = useState(false);
    const [originImageLinks, setOriginImageLinks] = useState<string[]>([]);
    const [originConfirmImageLinks, setOriginConfirmImageLinks] = useState<
      string[]
    >([]);
    const [taskModalInfo, setTaskModalInfo] = useState<TaskDTO | undefined>(
      (task || DEFAULT_TASK_MODAL_INFO) as TaskDTO
    );
    const { isOnline } = useSelector((state: RootState) => state.app);
    const { families } = useSelector((state: RootState) => state.forgeViewer);
    const { taskTypes, isLoadingTask } = useSelector(
      (state: RootState) => state.task
    );
    const { currentUser } = useSelector((state: RootState) => state.user);
    const { canEditTaskModal } = usePermission(currentUser?.role);
    const { isTakasagoGroup } = useRoles();

    const { sizePanel } = useSelector((state: RootState) => state.app);

    const widthPanel = useMemo(
      () => `${sizePanel.width}${sizePanel.unit}`,
      [sizePanel]
    );

    const modalRef = useRef<HTMLElement | null>(null);
    const { width } = useResize();
    const {
      isOpenDraw,
      setOpenDraw,
      imgDrawFile,
      confirmedImgDrawFile,
      setOpenConfirmedDraw,
      isOpenConfirmedDraw,
    } = useDrawTool();
    const containerRef = useRef<HTMLDivElement>(null);

    const { isCollapsed, setIsCollapsed } = useCollapseModal();

    useImperativeHandle(ref, () => ({
      handleCollapse: () => {
        !isCollapsed && handleCollapse();
      },
    }));

    useEffect(() => {
      if (isCollapsed) {
        handleCollapse();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clickedLabelInfo]);

    useEffect(() => {
      if (!containerRef.current) return;

      containerRef.current.scrollTop = 0;
    }, [task?.id]);

    const mapTaskType = useMemo(() => {
      return taskTypes.reduce((prev, item) => {
        return Object.assign(prev, {
          [item.id]: item.title,
        });
      }, {}) as { [key: string]: string };
    }, [taskTypes]);

    const { toggleMoveTaskLabelMode, showTooltipMoveTaskLabel } =
      useHandleMoveTaskLabel({
        clickInfo,
        setClickInfo,
        handleCloseTaskModal: onClose,
        mapTaskType,
        sendWebSocketMessage,
      });

    const {
      listUserById,
      comments,
      inputRef,
      containerChatRef,
      disabled: disableChat,
      loadingChat,
      loadingImage,
      editorState,
      suggestions,
      addChatMessage,
      onSearchChange,
      setEditorState,
      onKeyUp,
      onAddMessage,
      onScroll,
      handleKeyCommand,
      onDeleteImageChat,
      onUpdateImageComment,
      chatSavedRef,
    } = useChat({
      setTaskModalInfo,
      mapTaskType,
      sendWebSocketMessage,
      webSocketMessage,
    });

    const {
      documentCategoryNameOpts,
      isMoveTaskLabel,
      contentIndication,
      listImageSelected,
      attachesSelected,
      isOpenConfirmModal,
      isDeleting,
      isOpenCameraModal,
      setOpenCameraModal,
      isDisableUploadImage,
      isDeleteFile,
      indexFileUpload,
      taskSelected,
      isCreatingNewTask,
      taskSavedRef,
      isOpenConfirmedCameraModal,
      setOpenConfirmedCameraModal,
      isExistImages,
      isExistConfirmedImages,
      handleSaveDrawImage,
      onOpenConfirmModal,
      onCloseConfirmModal,
      onDeleteTask,
      onChangeMemo,
      updateDataOnBlur,
      onChangeTags,
      handleSelectedDay,
      setContentIndication,
      onClickBtnSelectFile,
      onChangeFile,
      onDeleteImage,
      saveModalData,
      handleSaveCaptureCamera,
      onDeleteAttachFile,
      onChangeContentType,
      onChangeUserSingle,
      changeStatusTask,
      setIsDisableUploadImage,
      handleChangePartnerCompanies,
      onChangeIdentificationMode,
      navigateDocumentItemModal,
    } = useTaskModal({
      filterTasks,
      task,
      onClose,
      imageCaptured,
      addChatMessage,
      clickInfo,
      setClickInfo,
      taskModalInfo,
      setTaskModalInfo,
      mapTaskType,
      sendWebSocketMessage,
    });

    const {
      partnerCompanyDirection,
      assignUserDirection,
      confirmedUserDirection,
      endDateScheduledDirection,
      deadlineDirection,
      formRef,
      confirmedDateTimeDirection,
      calculateScrolltop,
      checkDopdownDirection,
    } = useDropdownDirection();

    const isCurrentTask = useMemo(() => {
      return (
        taskModalInfo?.id === taskSavedRef.current?.id ||
        taskModalInfo?.id === chatSavedRef.current?.id
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [taskModalInfo, taskSavedRef.current, chatSavedRef.current]);

    const partnerCompanyOptions = useMemo(() => {
      return partnerCompanies?.map((item) => ({
        name: item?.name || "",
        value: item?.id,
      }));
    }, [partnerCompanies]);

    const mapPartnerCompany: { [id: string]: string } = useMemo(() => {
      return partnerCompanyOptions?.reduce((acc, item) => {
        return Object.assign(acc, {
          [item?.value]: item?.name,
        });
      }, {});
    }, [partnerCompanyOptions]);

    useEffect(() => {
      (async () => {
        const originImageLinks = await Promise.all(
          (taskSelected?.images || [])?.map(async (image) => {
            const src = image?.originSrc || image?.src || "";
            const isBase64 =
              src?.includes("data:image/") ||
              src?.includes("application/octet-stream;base64");

            if (!src || isBase64) {
              return "";
            }

            return await getPreSignUrl(src, "").catch(() => "");
          })
        );

        setOriginImageLinks(originImageLinks);
      })();
    }, [taskSelected?.images]);

    useEffect(() => {
      (async () => {
        const originConfirmImageLinks = await Promise.all(
          (taskSelected?.confirmedImages || [])?.map(async (image) => {
            const src = image?.originSrc || image?.src || "";
            const isBase64 =
              src?.includes("data:image/") ||
              src?.includes("application/octet-stream;base64");

            if (!src || isBase64) {
              return "";
            }

            return await getPreSignUrl(src, "").catch(() => "");
          })
        );

        setOriginConfirmImageLinks(originConfirmImageLinks);
      })();
    }, [taskSelected?.confirmedImages]);

    useEffect(() => {
      if (!clickInfo || !taskModalInfo?.id || isMoveTaskLabel) {
        return;
      }

      if (
        !isEqual(taskModalInfo, taskSelected) &&
        taskModalInfo?.id === taskSelected?.id
      ) {
        saveModalData(taskModalInfo);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clickInfo, taskModalInfo, taskSelected, isMoveTaskLabel]);

    const onChangeModalRef = (e: HTMLElement | null) => {
      modalRef.current = e;
    };

    const listTaskTypeSorted = useMemo(() => {
      return sortArrayByField(taskTypes || [], "createdAt", false);
    }, [taskTypes]);

    const handleCollapse = () => {
      setIsCollapsed((prev) => !prev);
    };

    const handleDownloadOriginImage = useCallback(
      async (link: string | undefined) => {
        if (!link) {
          return;
        }

        setIsDownloadOriginImage(true);
        const blob = await fetch(link).then((res) => res.blob());
        const path = new URL(link).pathname;
        const fileName = path.substring(path.lastIndexOf("/") + 1);
        setIsDownloadOriginImage(false);
        saveAs(blob, fileName);
      },
      []
    );

    const renderUploadFormImage = useCallback(
      (activeThumb: number) => {
        if (!canEditTaskModal?.canEditImagesField) {
          return <></>;
        }

        return (
          <FormUploadFile
            onClick={onClickBtnSelectFile}
            onChange={onChangeFile("images")}
            type={TypeOfFile.IMAGE}
            addCommentFunc={() => {
              addChatMessage("", "CHANGE_POINTED_OUT_PHOTO");
            }}
            style={{
              marginLeft: isExistImages ? "0" : "inherit",
            }}
          >
            <Flex
              alignItems="center"
              flexWrap="wrap"
              justifyContent="flex-end"
              gap="1rem"
            >
              <FileUpload width="auto" multiple accept="image/*">
                <Button
                  color="#009BE0"
                  bg="#FFFFFF"
                  border="1px solid #A3A3A3"
                  border-radius={6}
                  p="1.2rem 1.6rem"
                  fontSize="1.4rem"
                  _hover={{}}
                  isDisabled={isDisableUploadImage && isCurrentTask}
                >
                  選択
                </Button>
              </FileUpload>
              <Button
                color="#FFFFFF"
                bg="#009BE0"
                border="1px solid #A3A3A3"
                border-radius={6}
                p="1.2rem 1.6rem"
                fontSize="1.4rem"
                _hover={{}}
                onClick={setOpenCameraModal.on}
              >
                撮影
              </Button>

              {originImageLinks?.[activeThumb] && (
                <Button
                  {...buttonOutlineStyle}
                  isLoading={isDownloadOriginImage}
                  leftIcon={
                    <IconBase
                      w="2rem"
                      h="2rem"
                      icon="/img/icon-download.svg"
                      color="font.blue"
                    />
                  }
                  onClick={() =>
                    handleDownloadOriginImage(originImageLinks?.[activeThumb])
                  }
                >
                  元の写真をダウンロード
                </Button>
              )}

              {isOpenCameraModal && (
                <CameraModal
                  isOpen={isOpenCameraModal}
                  onClose={setOpenCameraModal.off}
                  onCapture={(file) => {
                    imgDrawFile.current = file;
                    setOpenDraw.on();
                  }}
                />
              )}

              {isOpenDraw && (
                <DrawToolModal
                  isOpen={isOpenDraw}
                  onSelect={handleSaveCaptureCamera("images")}
                  onClose={setOpenDraw.off}
                  fileRef={imgDrawFile}
                  reCapture={() => {
                    setOpenDraw.off();
                    setOpenCameraModal.on();
                  }}
                />
              )}
            </Flex>
          </FormUploadFile>
        );
      },

      // eslint-disable-next-line react-hooks/exhaustive-deps
      [
        isOpenCameraModal,
        isExistImages,
        isDisableUploadImage,
        isCurrentTask,
        isOpenDraw,
        imgDrawFile,
        canEditTaskModal?.canEditImagesField,
        onClickBtnSelectFile,
        onChangeFile,
        addChatMessage,
        handleSaveCaptureCamera,
      ]
    );

    const renderUploadFormConfirmImage = useCallback(
      (activeThumb: number) => {
        if (!canEditTaskModal?.canEditConfirmedImagesField) {
          return <></>;
        }

        return (
          <FormUploadFile
            onClick={onClickBtnSelectFile}
            onChange={onChangeFile("confirmedImages")}
            type={TypeOfFile.IMAGE}
            addCommentFunc={() => addChatMessage("", "CHANGE_IMAGE")}
            style={{
              marginLeft: isExistConfirmedImages ? "auto" : "inherit",
            }}
          >
            <Flex
              alignItems="center"
              flexWrap="wrap"
              justifyContent="flex-end"
              gap="1rem"
            >
              <FileUpload width="auto" multiple accept="image/*">
                <Button
                  color="#009BE0"
                  bg="#FFFFFF"
                  border="1px solid #A3A3A3"
                  border-radius={6}
                  p="1.2rem 1.6rem"
                  fontSize="1.4rem"
                  _hover={{}}
                  isDisabled={isDisableUploadImage && isCurrentTask}
                >
                  選択
                </Button>
              </FileUpload>
              <Button
                color="#FFFFFF"
                bg="#009BE0"
                border="1px solid #A3A3A3"
                border-radius={6}
                p="1.2rem 1.6rem"
                fontSize="1.4rem"
                _hover={{}}
                onClick={setOpenConfirmedCameraModal.on}
              >
                撮影
              </Button>
              {originConfirmImageLinks?.[activeThumb] && (
                <Button
                  {...buttonOutlineStyle}
                  isLoading={isDownloadOriginImage}
                  leftIcon={
                    <IconBase
                      w="2rem"
                      h="2rem"
                      icon="/img/icon-download.svg"
                      color="font.blue"
                    />
                  }
                  onClick={() =>
                    handleDownloadOriginImage(
                      originConfirmImageLinks?.[activeThumb]
                    )
                  }
                >
                  元の写真をダウンロード
                </Button>
              )}
              {isOpenConfirmedCameraModal && (
                <CameraModal
                  isOpen={isOpenConfirmedCameraModal}
                  onClose={setOpenConfirmedCameraModal.off}
                  onCapture={(file) => {
                    confirmedImgDrawFile.current = file;
                    setOpenConfirmedDraw.on();
                  }}
                />
              )}

              {isOpenConfirmedDraw && (
                <DrawToolModal
                  isOpen={isOpenConfirmedDraw}
                  onSelect={handleSaveCaptureCamera("confirmedImages")}
                  onClose={setOpenConfirmedDraw.off}
                  fileRef={confirmedImgDrawFile}
                  reCapture={() => {
                    setOpenConfirmedDraw.off();
                    setOpenConfirmedCameraModal.on();
                  }}
                />
              )}
            </Flex>
          </FormUploadFile>
        );
      },

      // eslint-disable-next-line react-hooks/exhaustive-deps
      [
        originConfirmImageLinks,
        isOpenConfirmedDraw,
        isOpenConfirmedCameraModal,
        isExistConfirmedImages,
        confirmedImgDrawFile,
        isDisableUploadImage,
        isCurrentTask,
        canEditTaskModal?.canEditConfirmedImagesField,
        onClickBtnSelectFile,
        onChangeFile,
        addChatMessage,
        handleSaveCaptureCamera,
      ]
    );

    return (
      <Box
        className={RIGHT_SIDEBAR_MODAL_CLASSNAME}
        width={isCollapsed ? "0" : widthPanel}
        position="absolute"
        right={0}
        height="100%"
        zIndex={11}
        transition="all 200ms ease-in-out 0s"
        filter="drop-shadow(rgba(0, 0, 0, 0.15) 0px 8px 12px) drop-shadow(rgba(0, 0, 0, 0.3) 0px 4px 4px)"
      >
        <Box
          p="0"
          width="100%"
          bgColor={"#FAFAFA"}
          height="100%"
          ref={onChangeModalRef}
          h="calc(var(--app-height) - var(--header-height) - var(--sub-header-height))"
          zIndex={7}
          position="relative"
          display="flex"
          flexDir="column"
          {...rest}
        >
          <CollapseModalIcon
            backgroundColor="#FAFAFA"
            borderRadius="5px 0px 0px 5px"
            borderRightStyle="hidden"
            borderLeftStyle="solid"
            height="12rem"
            width="3.8rem"
            top="50%"
            transform="translateY(-50%)"
            iconProps={{
              transform: isCollapsed ? "rotate(90deg)" : "rotate(-90deg)",
            }}
            onClose={handleCollapse}
          />

          {isCreatingNewTask || isLoadingTask || !taskModalInfo?.id ? (
            <Flex
              position="absolute"
              width="100%"
              height="100%"
              alignItems="center"
              justifyContent="center"
              border={"2px solid white"}
            >
              <Spinner color="blue.500" size={"xl"} mt="1rem" />
            </Flex>
          ) : (
            <Box
              ref={containerRef}
              id="pin-detail-content-modal"
              height="100%"
              overflowY={{ base: "hidden", md: "auto" }}
              overflowX="hidden"
              onScroll={(e) => {
                onScroll?.(e);
                calculateScrolltop?.(e);
              }}
            >
              {isDisableUploadImage && (
                <Box
                  position="absolute"
                  inset="0px"
                  backgroundColor="rgba(0,0,0,0.2)"
                  zIndex={999}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Spinner color="white" size="xl" mt="1rem" />
                </Box>
              )}

              <Flex p="16px" justifyContent="space-between">
                <Flex alignItems="center">
                  <Flex
                    h="31px"
                    background="var(--primary-border-color)"
                    borderRadius="4px"
                    py=".5rem"
                    px="1rem"
                    mr="1rem"
                    color="#171717"
                    alignItems="center"
                    fontSize={{ base: "12px", lg: "14px" }}
                  >
                    <Text>#{taskModalInfo.indexId || "-"}</Text>
                  </Flex>
                  <Menu closeOnSelect>
                    <MenuButton
                      ml="-1rem"
                      cursor={isTakasagoGroup ? "pointer" : "not-allowed"}
                    >
                      <Box px="1rem">
                        <Text
                          bg={
                            MapInspectionItemColor[
                              (taskModalInfo.status ||
                                InspectionItemType.Defect) as InspectionItemType
                            ]
                          }
                          color="#fff"
                          borderRadius="4px"
                          h="31px"
                          pt=".3rem"
                          pb={{ base: 0, lg: ".3rem" }}
                          px={{ base: ".5rem", lg: "1rem" }}
                          whiteSpace="nowrap"
                          fontSize={{ base: "12px", lg: "14px" }}
                          mr="1rem"
                          display="flex"
                          alignItems="center"
                        >
                          <SvgIcon
                            src={
                              MapInspectionItemIcon[
                                (taskModalInfo.status ||
                                  InspectionItemType.Defect) as InspectionItemType
                              ]
                            }
                          />
                          {
                            MapInspectionItemType[
                              (taskModalInfo.status ||
                                InspectionItemType.Defect) as InspectionItemType
                            ]
                          }
                        </Text>
                      </Box>
                    </MenuButton>

                    {isTakasagoGroup && (
                      <MenuList zIndex={19} minWidth="fit-content">
                        <MenuOptionGroup
                          value={
                            taskModalInfo.status || InspectionItemType.Defect
                          }
                          title=""
                          type="radio"
                          onChange={(value) => {
                            changeStatusTask?.(String(value));
                          }}
                        >
                          {Object.keys(MapInspectionItemType).map((type) => (
                            <MenuItemOption
                              key={type}
                              value={type}
                              icon={
                                <SvgIcon src="/img/icon-action-check_circle.svg" />
                              }
                              padding="0"
                            >
                              <Text
                                bg={
                                  MapInspectionItemColor[
                                    type as InspectionItemType
                                  ]
                                }
                                color="#fff"
                                py=".3rem"
                                px="1rem"
                                mx="1rem"
                                my="0.5rem"
                                borderRadius="4px"
                                display="flex"
                                alignItems="center"
                              >
                                <SvgIcon
                                  src={
                                    MapInspectionItemIcon[
                                      type as InspectionItemType
                                    ]
                                  }
                                />
                                {
                                  MapInspectionItemType[
                                    type as InspectionItemType
                                  ]
                                }
                              </Text>
                            </MenuItemOption>
                          ))}
                        </MenuOptionGroup>
                      </MenuList>
                    )}
                  </Menu>
                </Flex>

                {isTakasagoGroup && (
                  <Flex
                    justifyContent="flex-end"
                    alignItems="center"
                    gap="1rem"
                  >
                    <Flex alignItems="center" cursor="pointer">
                      <SvgIcon
                        src="/img/icon-arrows-4-directions.svg"
                        width="20px"
                        height="20px"
                        pathFill={
                          showTooltipMoveTaskLabel ? "#009be0" : "#171717"
                        }
                        onClick={toggleMoveTaskLabelMode}
                      />
                    </Flex>
                    <Flex
                      alignItems="center"
                      {...(isOnline
                        ? {
                            cursor: "pointer",
                            onClick: onOpenConfirmModal,
                          }
                        : {
                            cursor: "not-allowed",
                          })}
                    >
                      <IconBase
                        icon="/img/icon-delete.svg"
                        color={`rgba(220,38,38,${isOnline ? "1" : "0.4"})`}
                      />
                    </Flex>
                  </Flex>
                )}
              </Flex>

              <Flex
                w="100%"
                color="#171717"
                fontSize="1.4rem"
                mb="0.4rem"
                px="16px"
                flexDir="column"
              >
                <Text mb="0.5rem">タイトル</Text>
                <DropdownHover
                  options={listTaskTypeSorted}
                  value={String(taskModalInfo.taskTypeId)}
                  tags={taskModalInfo?.tags}
                  sendWebSocketMessage={sendWebSocketMessage}
                  onChange={(value) => onChangeContentType(value as string)}
                  onClickLink={(value) => {
                    setContentIndication({
                      isOpen: true,
                      href: value,
                    });
                  }}
                  isOnline={isOnline}
                  readOnly={!canEditTaskModal?.canEditTaskTypeField}
                />
                <ContentIndicationModal
                  isOpen={contentIndication.isOpen ?? false}
                  href={contentIndication.href}
                  onClose={() => setContentIndication({})}
                />
              </Flex>

              <Flex
                w="100%"
                color="#171717"
                fontSize="1.4rem"
                my="10px"
                px="16px"
                borderBottom="1px solid #A3A3A3"
                pb="2rem"
                flexDir="column"
              >
                <Text w="6rem" mb="0.5rem">
                  タグ
                </Text>
                <Box flex="1">
                  <TagsInput
                    options={families.map((family) => ({
                      name: family.name,
                      value: family.name,
                    }))}
                    value={taskModalInfo?.tags}
                    placeHolder="タグ"
                    onChange={(t) => onChangeTags(t)}
                    onBlur={() => {
                      updateDataOnBlur("tags", () => {
                        addChatMessage(
                          taskModalInfo.tags?.join(",") || "",
                          "CHANGE_TAGS"
                        );
                      });
                    }}
                    isWithSubmenu
                    isRevertSubmenu
                    zIndex={10}
                    readonly={!canEditTaskModal?.canEditTagsField}
                  />
                </Box>

                <RadioGroup
                  mt="2rem"
                  onChange={onChangeIdentificationMode as any}
                  value={taskModalInfo?.printMode || TASK_PRINT_MODE.PRINTABLE}
                  sx={radioDefaultCSS}
                  isDisabled={!canEditTaskModal?.canEditTaskTypeField}
                >
                  <Stack direction="row" gap="2rem">
                    <Radio value={TASK_PRINT_MODE.PRINTABLE}>印刷対象</Radio>
                    <Radio value={TASK_PRINT_MODE.NOT_PRINTABLE}>
                      印刷対象外
                    </Radio>
                  </Stack>
                </RadioGroup>
                {!!documentCategoryNameOpts?.length && (
                  <Flex
                    w="100%"
                    fontSize="1.4rem"
                    alignItems="center"
                    mb="1rem"
                    my="16px"
                    px="2px"
                    gap="1rem"
                  >
                    <Text
                      color="#171717"
                      mr="1rem"
                      mb="0.5rem"
                      whiteSpace="nowrap"
                      flexShrink={0}
                    >
                      関連書類
                    </Text>
                    <Box gap="0.5rem" flex={1}>
                      {documentCategoryNameOpts?.map((opt, index) => (
                        <Fragment key={opt.value}>
                          <Text
                            display="inline"
                            width="fit-content"
                            fontWeight="bold"
                            flexShrink={0}
                            cursor="pointer"
                            color="#009BE0"
                            textDecoration="underline"
                            onClick={() => {
                              navigateDocumentItemModal(opt.value);
                            }}
                          >
                            {opt.name}
                          </Text>

                          {documentCategoryNameOpts?.length !== index + 1 && (
                            <Text display="inline" width="fit-content">
                              ,&nbsp;&nbsp;
                            </Text>
                          )}
                        </Fragment>
                      ))}
                    </Box>
                  </Flex>
                )}
              </Flex>

              <Text
                px="16px"
                color="#171717"
                fontWeight="700"
                pb="1rem"
                fontSize="1.8rem"
              >
                指摘内容
              </Text>

              <VStack
                spacing="1rem"
                fontSize="1.4rem"
                color="#171717"
                h="calc(100% - 7rem)"
              >
                <Flex
                  w="100%"
                  color="#171717"
                  bg="#E5E5E5"
                  fontSize="1.4rem"
                  alignItems={isExistImages ? "center" : "unset"}
                  mb="0.4rem"
                  p="1rem 16px"
                  flexDir={isExistImages ? "column" : "unset"}
                >
                  <Text
                    flexBasis={isExistImages ? "unset" : "6rem"}
                    w="6rem"
                    mr="3rem"
                    mb="0.5rem"
                    alignSelf="start"
                    whiteSpace="nowrap"
                  >
                    指摘写真
                  </Text>
                  <Slider
                    originImageLinks={originImageLinks}
                    keyImageName={"images" as keyof TaskDTO}
                    currentTask={taskModalInfo}
                    onDeleteImage={(idx: number) =>
                      onDeleteImage(
                        idx,
                        () => addChatMessage("", "DELETE_POINTED_OUT_PHOTO"),
                        "images"
                      )
                    }
                    listImageSelected={listImageSelected?.images || []}
                    setIsDisableUploadImage={setIsDisableUploadImage}
                    onSaveDrawImage={handleSaveDrawImage("images")}
                    readonly={!canEditTaskModal?.canEditImagesField}
                    uploadForm={renderUploadFormImage}
                  />
                </Flex>

                <Flex
                  w="100%"
                  color="#171717"
                  fontSize="1.4rem"
                  mb="1rem"
                  my="16px"
                  px="16px"
                  flexDir="column"
                >
                  <Text w="6rem" mr="1rem" mb="0.5rem">
                    指摘内容
                  </Text>
                  <Textarea
                    fontSize="1.4rem"
                    minH="9rem"
                    maxH="12rem"
                    value={taskModalInfo.memo || ""}
                    onChange={onChangeMemo("memo")}
                    onBlur={({ target }) => {
                      updateDataOnBlur("memo", () => {
                        addChatMessage(
                          target.value || "",
                          "CHANGE_DESCRIPTION_CRITICISM"
                        );
                      });
                    }}
                    borderRadius={4}
                    w="100%"
                    border="1px solid var(--primary-border-color) !important"
                    placeholder={`指摘の説明内容です。`}
                    readOnly={!canEditTaskModal?.canEditMemoField}
                    cursor={
                      !canEditTaskModal?.canEditMemoField
                        ? "not-allowed"
                        : "unset"
                    }
                    bg={
                      !canEditTaskModal?.canEditMemoField
                        ? "#f1f1f1"
                        : "#FAFAFA"
                    }
                  />
                </Flex>

                <Flex
                  w="100%"
                  h="4.4rem"
                  color="#171717"
                  fontSize="1.4rem"
                  alignItems="center"
                  mb="0.4rem"
                  my="16px"
                  pl="16px"
                  pr="16px"
                  ref={(el) => {
                    Object.assign(formRef?.current, { assignUserEl: el });
                  }}
                >
                  <Text flexBasis="8.5rem">高砂担当者</Text>
                  <Flex w="calc(100% - 8.5rem)" alignItems="center" flex="1">
                    <SelectUserSingle
                      users={listUserById}
                      value={taskModalInfo?.userAssigned}
                      menuPlacement={assignUserDirection}
                      components={{
                        DropdownIndicator: () => (
                          <SvgIcon
                            src="/img/icon-react-select-custom-dropdown.svg"
                            mx=".8rem"
                          />
                        ),
                      }}
                      styles={selectStyles}
                      onChange={(user) =>
                        onChangeUserSingle(
                          "userAssigned",
                          user,
                          "CHANGE_ASSIGN"
                        )
                      }
                      sx={{
                        ...SelectUserSingleSx,
                        "div[class*='option']": {
                          borderBottom: "1px solid var(--primary-border-color)",
                          p: "0.5rem 1.5rem",
                        },
                        "div[class*='option']:last-child": {
                          borderBottom: "none",
                        },
                        ".containerSelectInput > div": {
                          background:
                            !canEditTaskModal?.canEditAssignedUserField
                              ? "#f1f1f1"
                              : "#FAFAFA",
                          borderColor: "var(--primary-border-color)",
                        },
                      }}
                      isDisabled={!canEditTaskModal?.canEditAssignedUserField}
                    />
                  </Flex>
                </Flex>

                <Flex
                  w="100%"
                  color="#171717"
                  fontSize="1.4rem"
                  alignItems="center"
                  mb="0.4rem"
                  my="16px"
                  pl="16px"
                  pr="16px"
                  ref={(el) => {
                    Object.assign(formRef.current, { endDateScheduledEl: el });
                  }}
                >
                  <Text flexBasis="8.5rem">完了期限</Text>
                  <Box flex="1">
                    <DayPicker
                      name="deadline"
                      value={taskModalInfo?.deadline}
                      onSelectedDay={handleSelectedDay("deadline")}
                      shouldCloseOnSelect
                      popperPlacement={`${endDateScheduledDirection}-start`}
                      onCalendarOpen={checkDopdownDirection}
                      popperModifiers={[
                        {
                          name: "flip",
                          enabled: false,
                        },
                      ]}
                      readonly={!canEditTaskModal?.canEditDeadlineField}
                    />
                  </Box>
                </Flex>

                <Flex
                  w="100%"
                  color="#171717"
                  fontSize="1.4rem"
                  px="16px"
                  alignItems="center"
                  borderBottom="1px solid #cfcfcf"
                  pb="2rem"
                  ref={(el) => {
                    Object.assign(formRef.current, { partnerCompanyEl: el });
                  }}
                >
                  <Text flexBasis="8.5rem">協力会社</Text>

                  <Flex flex="1">
                    <Menu
                      matchWidth
                      closeOnSelect
                      placement={partnerCompanyDirection}
                      onOpen={checkDopdownDirection}
                      flip={false}
                    >
                      {({ isOpen }) => (
                        <>
                          <MenuButton
                            w="100%"
                            sx={{
                              span: {
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "100%",
                                paddingLeft: "1rem",
                                paddingRight: ".5rem",
                                borderRadius: "4px",
                                border: "1px solid #ccc",
                              },
                            }}
                            cursor={
                              !canEditTaskModal?.canEditPartnerCompaniesField
                                ? "not-allowed"
                                : "unset"
                            }
                            bg={
                              !canEditTaskModal?.canEditPartnerCompaniesField
                                ? "#f1f1f1"
                                : "#fafafa"
                            }
                          >
                            <Flex alignItems="center">
                              <Text display="flex" h="4rem" alignItems="center">
                                {
                                  mapPartnerCompany?.[
                                    taskModalInfo?.partnerCompany as keyof typeof mapPartnerCompany
                                  ]
                                }
                              </Text>
                            </Flex>
                            {isFetchingPartnerCompanies ? (
                              <Spinner />
                            ) : (
                              <SvgIcon src="/img/icon-navigation-arrow_drop_down.svg" />
                            )}
                          </MenuButton>

                          {canEditTaskModal?.canEditPartnerCompaniesField && (
                            <MenuList
                              zIndex={11}
                              display={isOpen ? "block" : "none"}
                              maxH="160px"
                              overflowY="auto"
                            >
                              <MenuOptionGroup
                                value={taskModalInfo?.partnerCompany || ""}
                                title=""
                                type="radio"
                                onChange={handleChangePartnerCompanies}
                              >
                                {partnerCompanyOptions?.map((item) => (
                                  <MenuItemOption
                                    key={item?.value}
                                    value={item?.value}
                                    _hover={{ bg: "#F0F9FF" }}
                                    p=".5rem 1.5rem"
                                    borderBottom="1px solid var(--primary-border-color)"
                                    _last={{ borderBottom: "none" }}
                                    icon={
                                      <SvgIcon src="/img/icon-action-check_circle.svg" />
                                    }
                                  >
                                    <Text
                                      px="1rem"
                                      borderRadius="4px"
                                      display="flex"
                                      alignItems="center"
                                    >
                                      {item?.name}
                                    </Text>
                                  </MenuItemOption>
                                ))}
                              </MenuOptionGroup>
                            </MenuList>
                          )}
                        </>
                      )}
                    </Menu>
                  </Flex>
                </Flex>

                <Text
                  px="16px"
                  color="#171717"
                  fontWeight="700"
                  mb="1rem"
                  fontSize="1.8rem"
                  alignSelf="start"
                >
                  是正内容
                </Text>

                <Flex
                  w="100%"
                  color="#171717"
                  bg="#E5E5E5"
                  fontSize="1.4rem"
                  alignItems={isExistConfirmedImages ? "center" : "unset"}
                  mb="0.4rem"
                  my="16px"
                  p="1rem 16px"
                  flexDir={isExistConfirmedImages ? "column" : "unset"}
                >
                  <Text
                    flexBasis={isExistConfirmedImages ? "unset" : "6rem"}
                    mr="1rem"
                    mb="0.5rem"
                    alignSelf="start"
                    whiteSpace="nowrap"
                  >
                    是正写真
                  </Text>
                  <Slider
                    originImageLinks={originConfirmImageLinks}
                    keyImageName={"confirmedImages" as keyof TaskDTO}
                    currentTask={taskModalInfo}
                    onDeleteImage={(idx: number) =>
                      onDeleteImage(
                        idx,
                        () => addChatMessage("", "DELETE_IMAGE"),
                        "confirmedImages"
                      )
                    }
                    listImageSelected={listImageSelected?.confirmedImages || []}
                    onSaveDrawImage={handleSaveDrawImage("confirmedImages")}
                    readonly={!canEditTaskModal?.canEditConfirmedImagesField}
                    uploadForm={renderUploadFormConfirmImage}
                    isShowOriginalLink
                  />
                </Flex>

                <Flex
                  w="100%"
                  color="#171717"
                  fontSize="1.4rem"
                  mb="1rem"
                  my="16px"
                  px="16px"
                  flexDir="column"
                >
                  <Text w="6rem" mr="1rem" mb="0.5rem">
                    是正内容
                  </Text>
                  <Textarea
                    fontSize="1.4rem"
                    minH="9rem"
                    maxH="12rem"
                    value={taskModalInfo.confirmedMemo || ""}
                    onChange={onChangeMemo("confirmedMemo")}
                    onBlur={({ target }) => {
                      updateDataOnBlur("confirmedMemo", () => {
                        addChatMessage(
                          target.value || "",
                          "CHANGE_DESCRIPTION"
                        );
                      });
                    }}
                    borderRadius={4}
                    w="100%"
                    border="1px solid var(--primary-border-color) !important"
                    placeholder={`是正の説明内容です`}
                    readOnly={!canEditTaskModal?.canEditConfirmedMemoField}
                    cursor={
                      !canEditTaskModal?.canEditConfirmedMemoField
                        ? "not-allowed"
                        : "unset"
                    }
                    bg={
                      !canEditTaskModal?.canEditConfirmedMemoField
                        ? "#f1f1f1"
                        : "#FAFAFA"
                    }
                  />
                </Flex>

                <Flex
                  w="100%"
                  color="#171717"
                  fontSize="1.4rem"
                  alignItems="center"
                  mb="0.4rem"
                  my="16px"
                  pl="16px"
                  pr="16px"
                  ref={(el) => {
                    Object.assign(formRef.current, { deadlineEl: el });
                  }}
                >
                  <Text flexBasis="8.5rem">是正完了日</Text>
                  <Box flex="1">
                    <DayPicker
                      name="endDateScheduled"
                      value={taskModalInfo?.endDateScheduled}
                      onSelectedDay={handleSelectedDay("endDateScheduled")}
                      shouldCloseOnSelect
                      popperPlacement={`${deadlineDirection}-start`}
                      onCalendarOpen={checkDopdownDirection}
                      popperModifiers={[
                        {
                          name: "flip",
                          enabled: false,
                        },
                      ]}
                      readonly={!canEditTaskModal?.canEditEndDateScheduledField}
                    />
                  </Box>
                </Flex>

                <Flex
                  w="100%"
                  color="#171717"
                  fontSize="1.4rem"
                  alignItems="center"
                  mb="0.4rem"
                  my="16px"
                  pl="16px"
                  pr="16px"
                  ref={(el) => {
                    Object.assign(formRef.current, { confirmedDateTimeEl: el });
                  }}
                >
                  <Text flexBasis="8.5rem">高砂確認日</Text>
                  <Box flex="1">
                    <DayPicker
                      name="confirmedDateTime"
                      value={taskModalInfo?.confirmedDateTime}
                      onSelectedDay={handleSelectedDay("confirmedDateTime")}
                      shouldCloseOnSelect
                      popperPlacement={`${confirmedDateTimeDirection}-start`}
                      onCalendarOpen={checkDopdownDirection}
                      popperModifiers={[
                        {
                          name: "flip",
                          enabled: false,
                        },
                      ]}
                      readonly={
                        !canEditTaskModal?.canEditConfirmedDateTimeField
                      }
                    />
                  </Box>
                </Flex>

                <Flex
                  w="100%"
                  h="4.4rem"
                  color="#171717"
                  fontSize="1.4rem"
                  alignItems="center"
                  mb="0.4rem"
                  my="16px"
                  pl="16px"
                  pr="16px"
                  ref={(el) => {
                    Object.assign(formRef.current, { confirmedUserEl: el });
                  }}
                >
                  <Text flexBasis="8.5rem">高砂確認者</Text>
                  <Flex w="calc(100% - 8.5rem)" alignItems="center" flex="1">
                    <SelectUserSingle
                      users={listUserById}
                      value={taskModalInfo?.userConfirmed}
                      components={{
                        DropdownIndicator: () => (
                          <SvgIcon
                            src="/img/icon-react-select-custom-dropdown.svg"
                            mx=".8rem"
                          />
                        ),
                      }}
                      menuPlacement={confirmedUserDirection}
                      styles={selectStyles}
                      onChange={(user) =>
                        onChangeUserSingle(
                          "userConfirmed",
                          user,
                          "CHANGE_USER_CREATED"
                        )
                      }
                      sx={{
                        ...SelectUserSingleSx,
                        "div[class*='option']": {
                          borderBottom: "1px solid var(--primary-border-color)",
                          p: "0.5rem 1.5rem",
                        },
                        "div[class*='option']:last-child": {
                          borderBottom: "none",
                        },
                        ".containerSelectInput > div": {
                          background:
                            !canEditTaskModal?.canEditConfirmedUserField
                              ? "#f1f1f1"
                              : "#FAFAFA",
                          borderColor: "var(--primary-border-color)",
                        },
                      }}
                      isDisabled={!canEditTaskModal?.canEditConfirmedUserField}
                    />
                  </Flex>
                </Flex>

                <Flex
                  w="100%"
                  color="#171717"
                  fontSize="1.4rem"
                  alignItems={
                    attachesSelected?.length ? "flex-start" : "center"
                  }
                  my="16px"
                  p="1rem 16px"
                  flexDir={attachesSelected?.length ? "column" : "row"}
                  bg="#e5e5e5"
                  borderBottom="1px solid #A3A3A3"
                >
                  <Text w="8rem">添付資料</Text>
                  <Flex flex="1" width="100%" flexDirection="column">
                    <Flex alignItems="flex-start" flexWrap="wrap">
                      {attachesSelected?.map(
                        (file: FileModel, index: number) => {
                          return (
                            <PreviewFileComponent
                              key={index}
                              index={index}
                              file={file}
                              indexFileUpload={indexFileUpload}
                              onDelete={(e) => {
                                e.stopPropagation();
                                onDeleteAttachFile(index);
                              }}
                              isDelete={isDeleteFile}
                              readonly={
                                !canEditTaskModal?.canEditAttachsField ||
                                !isOnline
                              }
                            />
                          );
                        }
                      )}
                    </Flex>

                    {canEditTaskModal?.canEditAttachsField && (
                      <FormUploadFile
                        onClick={onClickBtnSelectFile}
                        onChange={onChangeFile()}
                        type={TypeOfFile.ATTACH}
                        addCommentFunc={() =>
                          addChatMessage("", "CHANGE_ATTACH")
                        }
                        style={{ flex: 1 }}
                      >
                        <Flex
                          justifyContent={
                            attachesSelected.length ? "end" : "start"
                          }
                          width="100%"
                        >
                          <FileUpload
                            width="auto"
                            accept="image/*, .pdf, .mp4"
                            multiple
                          >
                            <Button
                              variant="white"
                              color="#009BE0"
                              position="relative"
                              bg="#FFFFFF"
                              p="1.2rem 1.6rem"
                              borderColor="#A3A3A3"
                              borderRadius={6}
                              mt={attachesSelected?.length ? "5px" : 0}
                              isDisabled={
                                !!indexFileUpload.length && isCurrentTask
                              }
                            >
                              選択
                            </Button>
                          </FileUpload>
                        </Flex>
                      </FormUploadFile>
                    )}
                  </Flex>
                </Flex>

                <Box
                  w="100%"
                  flexDir="column"
                  fontSize="14px"
                  mt="auto!important"
                  flex="1"
                  background="#E5E5E5"
                >
                  <Comment
                    isOnline={isOnline}
                    editorState={editorState}
                    suggestions={suggestions}
                    comments={comments}
                    listUserById={listUserById}
                    loading={loadingChat}
                    loadingImage={loadingImage}
                    disabledChat={disableChat}
                    containerRef={containerChatRef}
                    inputRef={inputRef}
                    status={taskModalInfo.status}
                    position={{ base: "fixed", md: "absolute" }}
                    windowWidth={width}
                    onSearchChange={onSearchChange}
                    setEditorState={setEditorState}
                    onKeyUp={onKeyUp}
                    handleKeyCommand={handleKeyCommand}
                    setTempInfo={saveModalData}
                    onDeleteImage={onDeleteImageChat}
                    onUpdateImageComment={onUpdateImageComment}
                    onClickAddMessage={onAddMessage}
                    onClickBtnSelectFile={onClickBtnSelectFile}
                    taskSelected={task}
                    isCurrentTask={isCurrentTask}
                  />
                </Box>
              </VStack>
            </Box>
          )}

          {isOpenConfirmModal && (
            <ConfirmModal
              title="指摘削除の確認"
              content="この指摘を削除しますか？"
              buttonConfirm="削除"
              isOpen={isOpenConfirmModal}
              isLoading={isDeleting}
              onClose={onCloseConfirmModal}
              onProcessing={onDeleteTask}
            />
          )}

          {showTooltipMoveTaskLabel && (
            <Portal containerRef={forgeViewContainerRef}>
              <TooltipForgeView
                title="指摘ポイント移動モード"
                description="※移動先をクリックしてください。"
              />
            </Portal>
          )}
        </Box>
      </Box>
    );
  }
);

export default TaskModal;
