export const colors = {
  primary: {
    "300": "var(--primary300-color)",
    "400": "var(--primary400-color)",
    "500": "var(--primary500-color)",
  },
  secondary: {
    "300": "var(--secondary300-color)",
  },
  light: {
    default: "var(--light-color)",
  },
  dark: {
    default: "var(--dark-color)",
    "500": "var(--dark500-color)",
  },
  gray: {
    "400": "var(--gray400-color)",
  },
  progressing: {
    "500": "var(--yellow)",
  },
  paused: {
    "500": "var(--gray)",
  },
  completed: {
    "500": "#009BE0",
  },
  icon: {
    default: "#737373",
    blackBold: "#171717",
    danger: "#EF4444",
  },
  font: {
    default: "#171717",
    placeholder: "#A3A3A3",
    danger: "#EF4444",
    gray: "#737373",
    blue: "#009BE0",
    white: "#FFFFFF",
  },
  border: {
    default: "#A3A3A3",
  },
  divider: {
    default: "#A3A3A3",
  },
  skeleton: {
    start: "rgba(0, 0, 0, 0.06)",
    end: "rgba(0, 0, 0, 0.15)",
  },
  background: {
    secondary: "#FAFAFA",
  },
};
