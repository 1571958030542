import { extendTheme } from "@chakra-ui/react";

import {
  LG_SIZE,
  MD_SIZE,
  SM_SIZE,
  XL_SIZE,
  XXL_SIZE,
  _2XL_SIZE,
} from "constants/breakpoints";
import { badgeStyle } from "./badge";
import { buttonStyle } from "./button";
import { checkboxStyle } from "./checkbox";
import { colors } from "./colors";
import { inputStyle } from "./input";
import { progressStyle } from "./progress";
import { radioStyle } from "./radio";
import { selectStyle } from "./select";
import { tableStyle } from "./table";
import { tabsStyle } from "./tabs";
import { tooltipTheme } from "./tooltip";

const breakpoints = {
  sm: `${SM_SIZE}px`,
  md: `${MD_SIZE}px`,
  lg: `${LG_SIZE}px`,
  xl: `${XL_SIZE}px`,
  "2xl": `${_2XL_SIZE}px`,
  xxl: `${XXL_SIZE}px`,
};

const theme = extendTheme({
  styles: {
    global: {
      body: {
        background: "var(--secondary-bg)",
        minHeight: "var(--app-height)",
        color: "#000000CC",
        WebkitTouchCallout: "none",
        WebkitTapHighlightColor: "transparent",
        fontSize: "1.4rem",
        fontFamily: "Noto Sans JP, sans-serif",
      },
    },
  },
  fonts: {
    heading: "Noto Sans JP, sans-serif",
    body: "Noto Sans JP, sans-serif",
  },
  breakpoints,
  colors: colors,
  components: {
    Button: buttonStyle,
    Tabs: tabsStyle,
    Table: tableStyle,
    Select: selectStyle,
    Input: inputStyle,
    Badge: badgeStyle,
    Checkbox: checkboxStyle,
    Switch: {
      variants: {
        red: {
          track: {
            bg: "red",
          },
        },
      },
    },
    Radio: radioStyle,
    Progress: progressStyle,
    Tooltip: tooltipTheme,
  },
});

export default theme;
