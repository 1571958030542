import { documentCategoryApi, documentItemApi } from "apiClient/v2";
import { message } from "components/base";
import { DocumentCategoryKey, ModalType } from "constants/enum";
import { DocumentCategoryDTO } from "interfaces/dtos/documentCategoryDTO";
import { DocumentItemDTO } from "interfaces/dtos/documentItemDTO";
import { DocumentItem } from "interfaces/models/documentItem";
import {
  isPhotoLedgerTemplate,
  isSelfInspectionTemplate,
} from "models/documentCategory";
import { TypeHandleInitData } from "pages/forge-viewer/hooks/useSupportSyncDataOffline";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  removeDocumentItems,
  setDocumentCategorySelected,
  setDocumentItemSelected,
} from "redux/documentSlice";
import { setModalType } from "redux/forgeViewerSlice";
import store from "redux/store";
import { updateTaskByDocumentItemId } from "redux/taskSlice";
import { updateForgeWhenSelectCategory } from "utils/document";
import { checkDocumentItemUpdatedData } from "utils/documentItem";
import {
  clearSelectionId,
  getDbIdByExternalId,
  selectDbIds,
} from "utils/forge";
import { selectLabel } from "utils/forge/extensions/custom-label";
import { logDev } from "utils/logs";
import { arrayToObject } from "utils/object";
import { transformBodyForCombineData } from "utils/offline";

type UseDeletedDocumentItemReturnType = {
  onDeletedDocumentItem: (
    documentItem: DocumentItemDTO,
    onOk?: (documentCategory?: DocumentCategoryDTO) => void
  ) => Promise<void>;
};

const useDeletedDocumentItem = (): UseDeletedDocumentItemReturnType => {
  const dispatch = useDispatch();
  const onDeletedDocumentItem = useCallback(
    async (
      documentItem: DocumentItemDTO,
      onOk?: (documentCategory?: DocumentCategoryDTO) => void
    ) => {
      if (!documentItem) {
        return;
      }
      const state = store.getState().document;
      const documentCategories = state.documentCategories;
      const documentCategory = documentCategories.find(
        (item) => item.id === documentItem.documentCategoryId
      );
      if (!documentCategory) {
        return;
      }
      const documentItemId = documentItem.id;
      // setLoading(true);
      try {
        const documentCategoryId = documentItem.documentCategoryId;
        let isSoftDelete = checkDocumentItemUpdatedData(documentItem);
        if (isPhotoLedgerTemplate(documentCategory.documentType)) {
          const subItems = documentItem?.subItems;
          isSoftDelete =
            isSoftDelete ||
            !!subItems?.some((subItem) =>
              checkDocumentItemUpdatedData(subItem as DocumentItem)
            );
        }

        const promises: Promise<any>[] = [
          documentItemApi.deleteItemList([documentItemId], isSoftDelete),
        ];

        const transformDocumentCategory = (
          documentCategory: DocumentCategoryDTO
        ) => {
          const documentItems = [] as DocumentItemDTO[];
          const itemIds = [] as string[];
          let order: number = 0;

          documentCategory.documentItems?.forEach((documentItem) => {
            if (documentItem.id != documentItemId) {
              documentItems.push({
                ...documentItem,
                displayOrder: order,
              });
              order++;
              itemIds.push(documentItem.id);
            }
          });

          const cloneDocumentCategory = structuredClone(documentCategory);
          cloneDocumentCategory.documentItems = documentItems;
          cloneDocumentCategory.itemIds = itemIds;
          cloneDocumentCategory.selectedExternalIds =
            cloneDocumentCategory.selectedExternalIds?.filter(
              (id) => id !== documentItemId
            );

          return cloneDocumentCategory;
        };
        const newDocumentCategory = transformDocumentCategory(documentCategory);
        promises.push(
          documentCategoryApi.updateCategory(
            transformBodyForCombineData<DocumentCategoryDTO>({
              body: {
                id: documentCategoryId!,
                selectedExternalIds: newDocumentCategory.selectedExternalIds,
                itemIds: newDocumentCategory.itemIds,
              } as DocumentCategoryDTO,
              bodyBefore: documentCategory,
              typeInitData: TypeHandleInitData.DOCUMENT_CATEGORY,
            })
          )
        );

        // Update document categories related to templates and document items
        const otherDocumentCategories = documentCategories?.filter(
          (category) =>
            category?.itemIds?.includes(documentItemId) &&
            category.templateId === newDocumentCategory.templateId &&
            category.level === newDocumentCategory.level &&
            category.id !== documentCategoryId!
        );
        const mapOtherDocumentCategoriesById = arrayToObject(
          otherDocumentCategories,
          DocumentCategoryKey.ID
        );
        const tranformOtherDocumentCategories = otherDocumentCategories.map(
          transformDocumentCategory
        );

        promises.push(
          ...tranformOtherDocumentCategories.map((category) => {
            return documentCategoryApi.updateCategory(
              transformBodyForCombineData<DocumentCategoryDTO>({
                body: {
                  id: category.id,
                  selectedExternalIds: category.selectedExternalIds,
                  itemIds: category.itemIds,
                } as DocumentCategoryDTO,
                bodyBefore: mapOtherDocumentCategoriesById[category.id],
                typeInitData: TypeHandleInitData.DOCUMENT_CATEGORY,
              })
            );
          })
        );
        await Promise.all(promises);

        dispatch(
          updateTaskByDocumentItemId({
            documentItemIds: [documentItemId],
            data: {
              isDeleteDocItem: true,
            },
          })
        );
        dispatch(
          removeDocumentItems({
            deleteItemIds: [documentItemId],
            updatedDocumentCategories: [
              ...tranformOtherDocumentCategories,
              newDocumentCategory,
            ],
          })
        );
        dispatch(setDocumentItemSelected());
        if (!isSelfInspectionTemplate(documentCategory?.documentType)) {
          const dbId = getDbIdByExternalId(documentItem.externalId);
          clearSelectionId(dbId);
        }
        dispatch(setModalType(ModalType.DOC_CATEGORY));

        if (documentItem.title && (documentItem.title ?? "").trim()) {
          message.success([
            "ピンの削除",
            `ピン「${documentItem.title}」を削除しました。`,
          ]);
        } else {
          message.success(["ピンの削除", `ピンを削除しました。`]);
        }
        selectLabel([]);
        dispatch(setDocumentCategorySelected(newDocumentCategory));

        // clear selection in forge viewer
        selectDbIds([], {});

        // update forge viewer when select category
        updateForgeWhenSelectCategory(newDocumentCategory);

        onOk?.(newDocumentCategory);
      } catch (error) {
        logDev("🚀 ~ onHandleDeletedDocumentItem ~ error:", error);
      } finally {
        // setLoading(false);
      }
    },
    [dispatch]
  );

  return {
    onDeletedDocumentItem,
  };
};

export default useDeletedDocumentItem;
