import { Box, Flex, FlexProps, Text } from "@chakra-ui/react";
import { SvgIcon } from "components/SvgIcon";
import {
  DocumentCategoryStatusType,
  DocumentTemplateType,
  MapDocumentCategoryStatusTypeColor,
} from "constants/enum";
import { documentIconPath } from "constants/file";
import { DocumentCategoryDTO } from "interfaces/dtos/documentCategoryDTO";
import { DocumentTemplateDTO } from "interfaces/dtos/documentTemplateDTO";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";

interface Props {
  documentTemplate: DocumentTemplateDTO;
  onClickDocumentCategory: (documentCategory: DocumentCategoryDTO) => void;
  parentRef: React.RefObject<HTMLDivElement>;
  flexProps?: FlexProps;
}

export const DocumentCategoryList = (props: Props) => {
  const { documentTemplate, onClickDocumentCategory } = props;

  const { documentCategorySelected, documentCategories } = useSelector(
    (state: RootState) => state.document
  );

  const renderData = useMemo(() => {
    return documentTemplate.documentCategories?.map((category) => {
      const documentCategory =
        documentCategories.find((cat) => cat.id === category.id) || category;
      const itemCount =
        documentCategory.documentType === DocumentTemplateType.SELF_INSPECTION
          ? (documentCategory.documentItems || []).length
          : new Set(
              (documentCategory.documentItems || []).map(
                (item) => item.externalId
              )
            ).size;
      const isShowDocumentIcon =
        (!documentCategory.areaId &&
          documentCategory.documentType ===
            DocumentTemplateType.SELF_INSPECTION &&
          documentCategorySelected?.documentType ===
            DocumentTemplateType.PARENT_TEMPLATE) ||
        documentCategory.documentType !== DocumentTemplateType.SELF_INSPECTION;
      const statusColor =
        MapDocumentCategoryStatusTypeColor[
          (documentCategory.status ||
            DocumentCategoryStatusType.NotStarted) as DocumentCategoryStatusType
        ];

      return (
        <Box
          key={documentCategory.id}
          color="#171717"
          fontSize="1.4rem"
          cursor="pointer"
          borderTop="1px solid var(--primary-border-color)"
          _last={{
            borderBottom: "1px solid var(--primary-border-color)",
          }}
        >
          <Flex
            position="relative"
            alignItems="center"
            p={"1rem 0px"}
            ml="2.4rem"
          >
            <Box width="2.4rem" mr=".5rem">
              <SvgIcon
                flexBasis="2.4rem"
                width="100%"
                src={documentIconPath}
                sx={{
                  ".icon": {
                    fill: statusColor,
                  },
                  ".stroke": {
                    stroke: statusColor,
                  },

                  path: !isShowDocumentIcon
                    ? {
                        stroke: statusColor,
                      }
                    : {},
                }}
              />
            </Box>

            <Text
              as={"b"}
              className="text-ellipsis"
              color="#009BE0"
              textDecoration="underline"
              fontWeight="700"
              fontSize="1.4rem"
              onClick={() => {
                onClickDocumentCategory?.(documentCategory);
              }}
            >
              {`${documentCategory.title} (${itemCount})`}
            </Text>
          </Flex>
        </Box>
      );
    });
  }, [
    documentTemplate.documentCategories,
    documentCategories,
    documentCategorySelected?.documentType,
    onClickDocumentCategory,
  ]);

  return (
    <Flex
      w="100%"
      pt="1rem"
      flexDirection="column"
      mt={"3rem !important"}
      borderTop={"2px solid var(--primary-border-color)"}
      color="#171717"
    >
      <Flex gap={2} alignItems="center" pb={5} px="2rem" borderBottom={"none"}>
        <Text pr={4} textAlign="left" flexShrink={0}>
          書類リスト
        </Text>
      </Flex>

      {renderData}
    </Flex>
  );
};
