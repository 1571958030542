/* eslint-disable jsx-a11y/media-has-caption */
import { DeleteIcon } from "@chakra-ui/icons";
import { AspectRatio, Box, Flex, Image, Spinner } from "@chakra-ui/react";
import { withPresignedUrl } from "components/HOC/presignedUrl";
import { SvgIcon } from "components/SvgIcon";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";

const ImagePresigned = withPresignedUrl(Image);
const IframePresigned = withPresignedUrl((props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [src, setSrc] = useState(props.src);
  const iFrameRef = useRef<HTMLIFrameElement>(null);
  const isReloadRef = useRef(true);

  useEffect(() => {
    if (props.isOnline) {
      isReloadRef.current = true;
      setIsLoading(true);
      setSrc(props.src);

      return;
    }

    (async () => {
      const src = await fetch(props.src).then(async (res) => {
        const blob = await res.blob();
        const objectURL = URL.createObjectURL(blob);

        return objectURL;
      });
      isReloadRef.current = false;
      setIsLoading(false);

      setSrc(src);
    })();
  }, [props.src, props.isOnline]);

  // reload iframe fix case back online from offline
  const onLoad = () => {
    if (iFrameRef?.current?.src && isReloadRef.current) {
      setTimeout(() => {
        if (!iFrameRef?.current?.src) {
          return;
        }

        iFrameRef!.current!.src = iFrameRef!.current!.src;
        isReloadRef.current = false;
        setIsLoading(false);
      }, 2000);
    }
  };

  return (
    <>
      {isLoading && (
        <Box
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
          zIndex={10}
          bgColor="rgba(0, 0, 0, 0.3)"
          height="100%"
          width="100%"
        >
          <Spinner
            speed="0.65s"
            emptyColor="transparent"
            color="white"
            size="md"
          />
        </Box>
      )}
      <iframe
        ref={iFrameRef}
        {...props}
        src={src}
        title="pdf"
        onLoad={onLoad}
      />
    </>
  );
});

const VideoPresigned = withPresignedUrl((props) => {
  return (
    <Box position="relative">
      <video className="custom_video">
        <source {...props} type="video/mp4"></source>
      </video>
      <SvgIcon
        src="/img/icon-play-video.svg"
        border="1px solid #0000001F"
        borderRadius="4px"
        position="absolute"
        p="0.5rem"
        w="3rem"
        h="3rem"
        left={0}
        right={0}
        ml="auto"
        mr="auto"
      />
    </Box>
  );
});

export interface PreviewFileProps {
  file: any;
  index: number;
  width?: string;
  onDelete: (e: any) => void;
  isDelete?: boolean;
  indexFileUpload?: number[];
  readonly?: boolean;
}

export const PreviewFileComponent = ({
  file,
  index,
  width = "9rem",
  onDelete,
  isDelete = false,
  indexFileUpload = [],
  readonly = false,
}: PreviewFileProps) => {
  const { isOnline } = useSelector((state: RootState) => state.app);
  const [isProcessing, setIsProcessing] = useState(false);

  const styles = useMemo(() => {
    if (file.src?.includes(".pdf")) {
      return {
        width: "100%",
        height: "100%",
        top: "12px",
        left: "12px",
        transform: "scale(1.3)",
      };
    }

    return {};
  }, [file]);

  const fileType = useMemo(() => {
    const src: string = file?.name || file?.src || "";
    if (src.includes(".pdf")) return "pdf";
    if (src.includes(".mp4")) return "mp4";

    return "image";
  }, [file]);

  const [presignedUrl, setPresignedUrl] = useState<string>(file.src || "");

  const onLoadedPresignedUrl = useCallback((url: string) => {
    setPresignedUrl(url);
  }, []);

  const onClick = useCallback(() => {
    if (indexFileUpload.length) return;
    if (file.file) {
      return window.open(file.src, "_blank");
    }
    setIsProcessing(true);
    fetch(presignedUrl || "")
      .then(async (res) => {
        const blob = await res.blob();
        const objectURL = URL.createObjectURL(blob);
        window.open(objectURL, "_blank");
      })
      .finally(() => {
        setIsProcessing(false);
      });
  }, [file.file, file.src, indexFileUpload.length, presignedUrl]);

  const content = useMemo(() => {
    let thumbnail = <></>;
    switch (fileType) {
      case "mp4":
        thumbnail = (
          <VideoPresigned
            onLoadedPresignedUrl={onLoadedPresignedUrl}
            src={file.src}
          />
        );
        break;
      case "pdf":
        thumbnail = (
          <IframePresigned
            src={file.src}
            allowFullScreen
            onLoadedPresignedUrl={onLoadedPresignedUrl}
            style={styles}
            isOnline={isOnline}
          />
        );

        break;
      case "image":
        thumbnail = (
          <ImagePresigned
            onLoadedPresignedUrl={onLoadedPresignedUrl}
            src={file.src}
            alt=""
            objectFit="cover"
          />
        );

        break;
      default:
        break;
    }

    return (
      <AspectRatio width="100%" height="100%" ratio={1} zIndex={1}>
        {thumbnail}
      </AspectRatio>
    );
  }, [file.src, fileType, styles, isOnline, onLoadedPresignedUrl]);

  return (
    <Flex
      mb="1rem"
      width={width}
      height="9rem"
      mr="0.5rem"
      flexDir="column"
      cursor="pointer"
      position="relative"
      onClick={onClick}
      border="1px solid #e2e8f0"
      borderRadius="4px"
      _hover={{
        ".remove_icon": {
          display: "flex",
        },
      }}
      overflow="hidden"
      bg="white"
    >
      {(isDelete || isProcessing) && (
        <Flex
          justifyContent={"center"}
          alignItems={"center"}
          bgColor="rgb(0 0 0 / 60%)"
          position="absolute"
          zIndex={5}
          width="100%"
          height="100%"
        >
          <Spinner thickness="4px" size="xl" color="cyan.800" />
        </Flex>
      )}

      {!indexFileUpload.length && !isDelete && (
        <Box
          position="absolute"
          height="100%"
          width="100%"
          bg={readonly ? "unset" : "blackAlpha.500"}
          className="remove_icon"
          display="none"
          justifyContent="center"
          alignItems="center"
          zIndex={2}
        >
          {!readonly && (
            <DeleteIcon
              onClick={onDelete}
              color="white"
              opacity={1}
              height="1.8rem"
              width="1.8rem"
              _hover={{
                color: "var(--red)",
              }}
            />
          )}
        </Box>
      )}

      <Box>{content}</Box>
    </Flex>
  );
};
