import { useBoolean } from "@chakra-ui/react";
import { useCallback } from "react";

const useCamera = () => {
  const [isOpenCamera, setIsOpenCamera] = useBoolean();

  const handleOpenCamera = useCallback(() => {
    setIsOpenCamera.on();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseCamera = useCallback(() => {
    setIsOpenCamera.off();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isOpenCamera,
    handleOpenCamera,
    handleCloseCamera,
  };
};

export default useCamera;
