import {
  Box,
  Button,
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import { PCTooltip } from "components/PCTooltip";
import { SvgIcon } from "components/SvgIcon";
import { buttonOutlineStyle, buttonPrimaryStyle } from "constants/styleProps";
import { TaskSheetTemplate } from "interfaces/models/taskSheetTemplate";
import { memo, useState } from "react";
import { generatePath } from "react-router-dom";
import { routePath } from "routes/path";
import TaskSheetTemplateItem from "./TaskSheetTemplateItem";

interface iProps {
  isShowCancel?: boolean;
  isLoadingSelectTaskSheetTemplate?: boolean;
  isOpen: boolean;
  taskSheetTemplateList: TaskSheetTemplate[];
  onClose: () => void;
  onApply: (template: TaskSheetTemplate) => void;
  value?: string;
}

const TaskSheetTemplateSelectModal = ({
  value = "",
  isOpen,
  taskSheetTemplateList,
  isLoadingSelectTaskSheetTemplate = false,
  isShowCancel = true,
  onClose,
  onApply,
}: iProps) => {
  const [templateIdSelected, setTemplateIdSelected] = useState<string>(value);

  const handleSelectTemplate = (templateId: string) => {
    setTemplateIdSelected(templateId);
  };

  const handleApply = () => {
    onApply(
      taskSheetTemplateList.find(
        (t) => t.id === templateIdSelected
      )!
    );
  };

  const onViewTemplate = (templateId: string) => {
    const url = `${window.location.origin}${generatePath(
      routePath.TaskSheetTemplateView,
      {
        templateId,
      }
    )}`;

    window.open(url);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent
        width={{ base: "60rem", lg: "80rem" }}
        maxW={{ base: "60rem", lg: "80rem" }}
      >
        <ModalHeader
          padding="1.6rem 2.4rem 0px 2.4rem"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Text
            color="var(--primary-color-text)"
            fontWeight="700"
            fontSize="1.8rem"
            lineHeight="2.8rem"
          >
            是正指示書テンプレ選択
          </Text>

          {isShowCancel && (
            <SvgIcon
              cursor="pointer"
              src="/img/icon-close.svg"
              width="1.6rem"
              height="1.6rem"
              pathFill="var(--primary-color-text)"
              onClick={onClose}
            />
          )}
        </ModalHeader>

        <Divider m="1.6rem 0px 2.4rem" borderColor="var(--primary-border-color)" />

        <ModalBody padding="0 2.4rem">
          <SimpleGrid
            column={1}
            spacing="1.6rem"
            maxH="calc(var(--app-height) * 0.3)"
            overflowY="auto"
            pr="0.8rem"
          >
            {taskSheetTemplateList?.map((item, key) => (
              <Box
                key={key}
                position="relative"
                padding="2.4rem 1.2rem"
                backgroundColor={
                  templateIdSelected === item?.id
                    ? "#F0F9FF"
                    : "#fff"
                }
                sx={{
                  "&::before": {
                    zIndex: 100,
                    content: '""',
                    position: "absolute",
                    inset: "0px",
                    border:
                      templateIdSelected === item?.id
                        ? "2px solid var(--primary-color)"
                        : "1px solid #A3A3A3",
                    borderRadius: "0.4rem",
                  },

                  ".chakra-radio__label": {
                    fontSize: "1.4rem",
                    marginLeft: "0.6rem",
                  },

                  ".chakra-radio__control[data-checked]::before": {
                    background: "#009BE0",
                  },
                  ".chakra-radio__control[data-checked]": {
                    borderColor: "#009BE0",
                  },
                }}
                cursor="pointer"
                onClick={() => handleSelectTemplate(item.id!)}
              >
                <Flex alignItems="center" justifyContent="space-between">
                  <Box position="relative" zIndex={102}>
                    <Radio
                      isChecked={
                        templateIdSelected === item?.id
                      }
                      mb="0.6rem"
                    >
                      <PCTooltip label={item.templateName}>
                        <Text
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "initial",
                            wordBreak: "break-word",
                            display: "-webkit-box",
                            WebkitLineClamp: 1,
                            WebkitBoxOrient: "vertical",
                          }}
                        >
                          {item.templateName}
                        </Text>
                      </PCTooltip>
                    </Radio>
                    <TaskSheetTemplateItem template={item} />
                  </Box>
                  <SvgIcon
                    flexShrink={0}
                    position="relative"
                    zIndex={101}
                    width="2.4rem"
                    height="2.4rem"
                    cursor="pointer"
                    src="/img/icon-doc-search.svg"
                    onClick={() => onViewTemplate(item.id!)}
                  />
                </Flex>
              </Box>
            ))}
          </SimpleGrid>
        </ModalBody>

        <Divider m="4rem 0px 1.6rem 0px" borderColor="var(--primary-border-color)" />

        <ModalFooter
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          padding="0 2.4rem 1.6rem 2.4rem"
          gap="0.8rem"
        >
          {isShowCancel && (
            <Button
              {...buttonOutlineStyle}
              onClick={onClose}
              isDisabled={isLoadingSelectTaskSheetTemplate}
            >
              キャンセル
            </Button>
          )}

          <Button
            {...buttonPrimaryStyle}
            onClick={handleApply}
            isLoading={isLoadingSelectTaskSheetTemplate}
          >
            決定
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default memo(TaskSheetTemplateSelectModal);
