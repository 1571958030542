import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

import { RootState } from "redux/store";
import { getAreaExtension } from "utils/forge/extensions/area-extension";

interface Props {
  isHandleSideEffect?: boolean;
}

export default function useArea({ isHandleSideEffect = true }: Props) {
  const { neptuneAreas, spaces, levelSelected, isShowArea } = useSelector(
    (state: RootState) => state.forgeViewer
  );

  const { currentAreas, currentSpaces } = useMemo(() => {
    const currentAreas = !levelSelected.guid
      ? neptuneAreas
      : neptuneAreas.filter((area) => area.level === levelSelected.label);

    const currentSpaces = !levelSelected.guid
      ? spaces
      : spaces.filter((space) => space.level === levelSelected.label);

    return {
      currentAreas,
      currentSpaces,
    };
  }, [neptuneAreas, levelSelected.guid, levelSelected.label, spaces]);

  useEffect(() => {
    if (!isHandleSideEffect) {
      return;
    }

    getAreaExtension()?.toggleVisibleArea(isShowArea);
  }, [isShowArea, isHandleSideEffect]);

  return {
    areas: currentAreas,
    spaces: currentSpaces,
    allAreas: neptuneAreas,
    allSpaces: spaces,
  };
}
