import { iconsSvg } from "assets/icons/icons";
import { useCallback } from "react";

const MAX_LOOP_FIND_PARENT_COMPONENT = 5;

export const getComponentTd = (e: HTMLElement) => {
  let parentTd: HTMLElement = e;
  let loopCount = MAX_LOOP_FIND_PARENT_COMPONENT;

  while (parentTd && parentTd.tagName !== "TD" && loopCount) {
    loopCount--;
    parentTd = parentTd.parentElement!;
  }

  return parentTd.tagName === "TD" ? parentTd : null;
};

const useHandleEditorComponent = () => {
  const handleEditorDocumentTypeCheckbox = useCallback((e: any) => {
    const input: HTMLElement = e;
    const value = !(input.getAttribute("aria-checked") === "true");

    const optionValue =
      input?.parentElement?.getAttribute("data-option-value") || "";

    const parentTd = getComponentTd(e);

    if (parentTd) {
      const checked = optionValue ? (value ? optionValue : "") : value;
      parentTd.setAttribute("data-field-content", String(checked));

      const iconChecked = document.createElement("svg");
      iconChecked.innerHTML = iconsSvg.checked;
      const iconUnchecked = document.createElement("svg");
      iconUnchecked.innerHTML = iconsSvg.unchecked;
      const iconCheckedEle = iconChecked.firstChild as Node;
      const iconUncheckedEle = iconUnchecked.firstChild as Node;

      const currentCheckboxParentEle = input?.parentElement;

      // process check, uncheck
      if (currentCheckboxParentEle) {
        parentTd
          .querySelectorAll("label[class='custom-checkbox']")
          .forEach((element) => {
            if (value && currentCheckboxParentEle !== element) {
              element!.replaceChild(iconUncheckedEle, element!.childNodes[0]);

              (element!.childNodes[0] as any).setAttribute(
                "aria-checked",
                false
              );
            }
          });

        currentCheckboxParentEle!.replaceChild(
          value ? iconCheckedEle : iconUncheckedEle,
          currentCheckboxParentEle!.childNodes[0]
        );

        (currentCheckboxParentEle!.childNodes[0] as any).setAttribute(
          "aria-checked",
          value
        );
      }
    }
  }, []);

  const handleEditorDocumentTypeInput = useCallback((e: HTMLElement) => {
    if (e) {
      const value = e?.innerText;
      const parentTd = getComponentTd(e);

      if (parentTd) {
        parentTd.setAttribute("data-field-content", value);
      }
    }
  }, []);

  return {
    handleEditorDocumentTypeCheckbox,
    handleEditorDocumentTypeInput,
  };
};

export default useHandleEditorComponent;
