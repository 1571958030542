import { Box } from "@chakra-ui/react";
import { iUseGetContentOfCell } from "components/editor-builder/component-preview/TableComponentPreview/TableTdComponentPreview";
import CommentManageExecuteTextInput from "components/editor-builder/component-preview/TableComponentPreview/ui/CommentManageExecuteTextInput";
import ImageWithBlackboard from "components/editor-builder/component-preview/TableComponentPreview/ui/ImageWithBlackboard";
import {
  getContentTypeComment,
  renderContentTypeCommon,
} from "components/editor-builder/component-preview/TableComponentPreview/utils";
import {
  CellProperty,
  LinkedDataField,
  TableDefaultStyle,
} from "constants/enum";
import useUserOfProject from "hooks/useUserOfProject";
import {
  DocumentItemDTO,
  DocumentSubItemDTO,
} from "interfaces/dtos/documentItemDTO";
import { FileUploadInfo } from "interfaces/models";
import { CellType } from "interfaces/models/component";
import {
  iCommonDocumentTemplateProps,
  iSleevePipeProps,
} from "interfaces/models/documentTemplate";
import { isEmpty, isNumber } from "lodash";
import { useCallback } from "react";
import NormalTextPreview from "../../NormalTextPreview";
import CrossLine from "../CrossLine";

const useGetContentTypeSleevePipe = (props: iUseGetContentOfCell) => {
  const {
    component,
    currentTemplate,
    zoomRatio,
    isBlackboardTemplateImage,
    displaySize,
    width,
    height,
    limit,
    isLastColumn,
    isDuplicateRow,
    sizePageRatio,
    pageDirectionRatio,
    cellSize,
    isOnlyView,
    components,
    selectedCells,
    documentContainerSize,
    componentSelected,
    isComponentDragging,
    contentType,
    sleevePipeProps = {} as iSleevePipeProps,
  } = props;

  const { blackboardTemplateProps = {} } = sleevePipeProps;
  const {
    listOptionUsers = [],
    projectName = "",
    blackboardTemplate,
  } = blackboardTemplateProps;

  const { familyInstances = {} } = sleevePipeProps;
  const { listUserById } = useUserOfProject();

  const contentOfCellTypeSleevePipe = useCallback(
    (cell: CellType) => {
      const data = structuredClone(cell);

      const rowIndex = sleevePipeProps?.option?.rowIndex || 0;
      const offsetItemLinked = sleevePipeProps?.offsetItemLinked || 0;
      const documentItems = sleevePipeProps?.documentItems || [];

      const documentItem: DocumentItemDTO =
        documentItems?.[offsetItemLinked + rowIndex];
      const images: FileUploadInfo = documentItem?.images as any;
      const imageSrc = images?.src;

      const hasData = documentItems.length > 0;

      const renderCommon = renderContentTypeCommon({
        contentType,
        contentCommonProps: (sleevePipeProps ||
          {}) as iCommonDocumentTemplateProps,
        cell,
        familyInstances,
        documentItems,
        documentItem,
        component,
        subTableComponentPreviewProps: {
          component,
          parentCell: cell,
          displaySize: {
            width:
              (width / component.size.width) * displaySize.width -
                TableDefaultStyle.DEFAULT_BORDER_SIZE || 0,
            height:
              (height / component.size.height) * displaySize.height -
                TableDefaultStyle.DEFAULT_BORDER_SIZE || 0,
          },
          zoomRatio,
          cellSize,
          sizePageRatio,
          pageDirectionRatio,
          isOnlyView,
          isDuplicateRow,
          isParentLastColumn: isLastColumn,
          limit,
          isBlackboardTemplateImage,
          components,
          selectedCells,
          documentContainerSize,
          currentTemplate,
          componentSelected,
          isComponentDragging,
          contentType,
          sleevePipeProps,
        },
      });

      if (renderCommon) {
        return renderCommon;
      }

      if ("cellData" in cell && cell.cellData === CellProperty.NO) {
        data.value = String(
          hasData
            ? documentItem?.keyNoteIndex || ""
            : isNumber(rowIndex)
            ? rowIndex + 1
            : ""
        );
      }

      switch (data?.cellProperty) {
        case CellProperty.TEXT:
          break;

        case CellProperty.NO:
          data.value = String(
            hasData
              ? documentItem?.keyNoteIndex || ""
              : isNumber(rowIndex)
              ? rowIndex + 1
              : ""
          );

          break;

        case CellProperty.IMAGE:
          if (!documentItem) {
            return <CrossLine />;
          }

          return (
            <ImageWithBlackboard
              documentItem={documentItem}
              blackboardTemplateDetail={blackboardTemplate}
              blackboardTemplateProps={{
                ...(blackboardTemplateProps || ({} as any)),
                documentSubItem: documentItem as any,
                familyInstance:
                  familyInstances?.[documentItem.externalId || ""],
              }}
            />
          );
        case CellProperty.BLACK_BOARD_DATA:
          const images: FileUploadInfo | undefined =
            documentItem?.images as any;
          const takeImageDates = isEmpty(images)
            ? documentItem.takeImageDates || []
            : [
                (images?.lastModifiedDateFile as any) ??
                  (images?.uploadTime || ""),
              ];

          if (!images?.src) {
            data.value = "";
          } else {
            data.value = getContentTypeComment({
              field: data?.cellLinkedData?.field,
              blackBoard: (documentItem as any)?.blackBoard,
              documentItem: {
                ...(documentItem as DocumentSubItemDTO),
                takeImageDates,
              },
              projectName,
              listOptionUsers,
              listUserById,
            }) as any;
          }

          if (data?.cellLinkedData?.field === LinkedDataField.COMMON.NO) {
            data.value = String(documentItem?.keyNoteIndex || "");

            break;
          }

          break;

        case CellProperty.DOCUMENT_DATA:
          switch (data?.cellLinkedData?.field) {
            case LinkedDataField.DOCUMENT_ITEM.TITLE:
              data.value = !documentItem ? "" : documentItem?.title || "-";
              break;

            case LinkedDataField.COMMON.IMAGE:
              if (!documentItem) {
                return <CrossLine />;
              }

              return (
                <ImageWithBlackboard
                  documentItem={documentItem}
                  blackboardTemplateDetail={blackboardTemplate}
                  blackboardTemplateProps={{
                    ...(blackboardTemplateProps || ({} as any)),
                    documentSubItem: documentItem as any,
                    familyInstance:
                      familyInstances?.[documentItem.externalId || ""],
                  }}
                />
              );

            default:
              break;
          }

          break;

        default:
          break;
      }

      if (!data) {
        return null;
      }

      switch (data?.cellProperty) {
        case CellProperty.NO:
        case CellProperty.TEXT:
        case CellProperty.INPUT_DATA:
        case CellProperty.CALENDAR: {
          return <NormalTextPreview data={data} />;
        }

        case CellProperty.BLACK_BOARD_DATA:
          switch (data?.cellLinkedData?.field) {
            case LinkedDataField.BLACKBOARD.COMMENT_MANAGE_EXECUTE:
              if (!documentItem || !imageSrc) {
                return "";
              }

              const currentCell = sleevePipeProps?.option?.currentCell;
              const familyInstance = component?.detail?.isRepeatTable
                ? familyInstances[
                    documentItems?.[Number(currentCell?.rowIndex) - 1]
                      ?.externalId || ""
                  ]
                : familyInstances[documentItem?.externalId || ""];

              return (
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    height: "100%",
                    padding: `${0.5 * zoomRatio}rem`,
                    overflow: "hidden",
                  }}
                >
                  <CommentManageExecuteTextInput
                    cells={
                      (data?.cellLinkedData?.options?.blackboard?.cells ||
                        []) as CellType[]
                    }
                    familyInstance={familyInstance}
                    isAutoResize={!isBlackboardTemplateImage}
                    isOnlyViewBlackboard={true}
                    component={component}
                    commentManageExecute={
                      (documentItem as any)?.blackBoard?.commentManageExecute
                    }
                  />
                </Box>
              );

            default:
              return <NormalTextPreview data={data} />;
          }

        default:
          return <NormalTextPreview data={data} />;
      }
    },
    [
      blackboardTemplate,
      blackboardTemplateProps,
      sleevePipeProps,
      contentType,
      familyInstances,
      component,
      width,
      displaySize.width,
      displaySize.height,
      height,
      zoomRatio,
      cellSize,
      sizePageRatio,
      pageDirectionRatio,
      isOnlyView,
      isDuplicateRow,
      isLastColumn,
      limit,
      isBlackboardTemplateImage,
      components,
      selectedCells,
      documentContainerSize,
      currentTemplate,
      componentSelected,
      isComponentDragging,
      listOptionUsers,
      listUserById,
      projectName,
    ]
  );

  return {
    contentOfCellTypeSleevePipe,
  };
};

export default useGetContentTypeSleevePipe;
