import { Stack, StackProps } from "@chakra-ui/react";
import { CSSProperties } from "react";

interface iProps extends StackProps {
  children?: React.ReactNode;
  style?: CSSProperties;
}

const CheckboxContainer = ({
  children,
  style,
  ...rest
}: iProps & StackProps) => {
  return (
    <Stack
      divider={<hr style={{ borderColor: "transparent" }} />}
      style={{
        display: "flex",
        height: "100%",
        width: "100%",
        flexDirection: "column",
        justifyContent: "center",
        gap: "0.15rem",
        ...style,
      }}
      {...rest}
    >
      {children}
    </Stack>
  );
};

export default CheckboxContainer;
