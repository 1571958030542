import {
  DEFAULT_BLACKBOARD_HEIGHT,
  DEFAULT_BLACKBOARD_WIDTH,
  MIN_BLACKBOARD_HEIGHT,
  MIN_BLACKBOARD_WIDTH,
} from "components/ui/BlackboardImage";
import { SizePosition } from "interfaces/models/rnd";
import { useCallback, useEffect, useRef, useState } from "react";
import { DraggableData, RndResizeCallback } from "react-rnd";

const initSizeBB = {
  width: DEFAULT_BLACKBOARD_WIDTH,
  height: DEFAULT_BLACKBOARD_HEIGHT,
};

const initBB = {
  x: 0,
  y: 0,
  ...initSizeBB,
  scale: 1,
  imgWidth: 0,
  imgHeight: 0,
};

const enableResizing = {
  top: false,
  left: false,
  right: false,
  bottom: false,
  bottomLeft: true,
  topLeft: true,
  topRight: true,
  bottomRight: true,
};

interface iProps {
  isOpen?: boolean;
  parentWidth?: number;
  parentHeight?: number;
}

export const useBlackboardResize = ({
  isOpen = false,
  parentHeight = 0,
  parentWidth = 0,
}: iProps) => {
  const bbRef = useRef<SizePosition>({ ...initBB });
  const [pos, setPos] = useState({ x: 0, y: 0 });
  const isResizing = useRef(false);
  const isResized = useRef(false);
  const blackBoardRef = useRef<any>(null);
  const isFirstSettingSizeRef = useRef(false);
  const [blackboardSize, setBlackBoardSize] = useState({
    w: initSizeBB.width,
    h: initSizeBB.height,
  });
  const [rndSize, setRndSize] = useState<{ width: number; height: number }>({
    width: DEFAULT_BLACKBOARD_WIDTH,
    height: DEFAULT_BLACKBOARD_HEIGHT,
  });

  const posResizingRef = useRef({ x: 0, y: 0 });
  const rndSizeRef = useRef({
    width: DEFAULT_BLACKBOARD_WIDTH,
    height: DEFAULT_BLACKBOARD_HEIGHT,
  });

  useEffect(() => {
    setPos({ x: 0, y: 0 });
    isResizing.current = false;
    isResized.current = false;
    bbRef.current = { ...initBB };
    blackBoardRef.current = null;

    setRndSize({
      width: DEFAULT_BLACKBOARD_WIDTH,
      height: DEFAULT_BLACKBOARD_HEIGHT,
    });

    rndSizeRef.current = {
      width: DEFAULT_BLACKBOARD_WIDTH,
      height: DEFAULT_BLACKBOARD_HEIGHT,
    };

    setBlackBoardSize({
      w: initSizeBB.width,
      h: initSizeBB.height,
    });
    isFirstSettingSizeRef.current = false;
  }, [isOpen]);

  const checkOusidePosition = useCallback(
    ({ x, y }: { x: number; y: number }) => {
      if (parentHeight && rndSizeRef.current.height) {
        const limitY = parentHeight - rndSizeRef.current.height;
        y = y > limitY ? limitY : y < 0 ? 0 : y;
      }

      if (parentWidth && rndSizeRef.current.width) {
        const limitX = parentWidth - rndSizeRef.current.width;
        x = x > limitX ? limitX : x < 0 ? 0 : x;
      }

      return { x, y };
    },
    [parentHeight, parentWidth]
  );

  const onDragStop = (_: any, d: DraggableData) => {
    if (!!isResizing.current) {
      return;
    }

    const { x, y } = checkOusidePosition({ x: d.x, y: d.y });

    posResizingRef.current = { x, y };
    bbRef.current.x = x;
    bbRef.current.y = y;

    setPos({ x, y });
  };

  const onResizeStop: RndResizeCallback = (_, __, ref, ___, ____) => {
    bbRef.current.width = ref.offsetWidth;
    bbRef.current.height = ref.offsetHeight;
    const x = posResizingRef.current.x;
    const y = posResizingRef.current.y;

    const { x: newX, y: newY } = checkOusidePosition({ x, y });

    setPos({ x: newX, y: newY });
    bbRef.current.x = newX;
    bbRef.current.y = newY;
    isResizing.current = false;
  };

  const onResize: RndResizeCallback = (_, __, ref, ___, position) => {
    if (
      ref.offsetWidth < MIN_BLACKBOARD_WIDTH ||
      ref.offsetHeight < MIN_BLACKBOARD_HEIGHT
    ) {
      return;
    }

    isResized.current = true;
    isResizing.current = true;
    bbRef.current.width = ref.offsetWidth;
    bbRef.current.height = ref.offsetHeight;

    rndSizeRef.current = {
      width: ref.offsetWidth,
      height: ref.offsetHeight,
    };

    const { x, y } = checkOusidePosition({ x: position.x, y: position.y });
    posResizingRef.current = {
      x,
      y,
    };

    setRndSize({
      width: ref.offsetWidth,
      height: ref.clientHeight,
    });
  };

  return {
    posResizingRef,
    rndSizeRef,
    onDragStop,
    onResizeStop,
    pos,
    onResize,
    isResized,
    blackBoardRef,
    isResizing,
    rndSize,
    bbRef,
    enableResizing,
    blackboardSize,
    setRndSize,
    setPos,
  };
};
