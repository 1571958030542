export const REGEX_FAMILY_INSTANCE = /^.+?(\[\d+\]).*$/;
export const TOOLBAR_ID = "navTools";
export const RATIO_ZOOM_ON_FAMILY = 0.1;
export const DEFAULT_HEIGHT_ELEMENT = 1;
export const REGEX_FLOOR = /^((.*)F).*/;
export enum DISPLAY_MODE {
  "2D" = "2D",
  "3D" = "3D",
}
export const ALL_LEVEL_LABEL = "すべてのフロア";
export const CURRENT_LEVEL_KEY = "currentLevel";
export const DEFAULT_BOUND_2D = new THREE.Box3(
  new THREE.Vector3(-15, -15, 0),
  new THREE.Vector3(15, 15, 0)
);
export const DEFAULT_BOUND_3D = new THREE.Box3(
  new THREE.Vector3(-9, -9, 0),
  new THREE.Vector3(9, 9, 0)
);
export const FIT_TO_VIEW_OFFSET = new THREE.Vector3(1, 1, 1);
export enum NavToolType {
  pan = "pan",
  orbit = "orbit",
}

export enum SectionToolType {
  X = "toolbar-sectionTool-x",
  Y = "toolbar-sectionTool-y",
  Z = "toolbar-sectionTool-z",
  BOX = "toolbar-sectionTool-box",
}

export const SECTION_TOOL_ICONS = {
  [SectionToolType.X]: "/img/x-plane.svg",
  [SectionToolType.Y]: "/img/y-plane.svg",
  [SectionToolType.Z]: "/img/z-plane.svg",
  [SectionToolType.BOX]: "/img/box-plane.svg",
};

export const FORGE_SHEET_FOLDER_NAME = "forge-sheets";
export const SHEET_PREFIX = "sheet";

export const MAX_SIZE_IMAGE = 1000;

export const LEVEL_ALL = {
  guid: "",
  label: ALL_LEVEL_LABEL,
  sheets: [],
};

export const LEVEL_OTHER_ID = "Other_level";
export const LEVEL_OTHER = {
  guid: LEVEL_OTHER_ID,
  label: "（その他）",
  sheets: [],
};

export const GRAY_OUT_THEME_COLOR = "#333333";
export const GRAY_OUT_OPACITY = 0.085;
export const GRAY_OUT_COLOR_VECTOR4 = new THREE.Vector4(0.8, 0.8, 0.8, 1);
export const DB_ID_COLOR_SELECTED_COLOR_DEFAULT = new THREE.Color(
  0 / 255,
  155 / 255,
  224 / 255
);
