import { Box, Center, Grid, GridItem, Spinner, Text } from "@chakra-ui/react";
import { useCallback, useMemo } from "react";
import IconBase from "../icon";
import { JointContent, NoticeType } from "./interface";

type CustomComponentProps = {
  onClose: () => void;
  onCloseCallback?: () => void;
  content: JointContent;
  status?: NoticeType;
};

const COLORS = {
  success: "#10b981",
  error: "#ef4444",
  warning: "#f59e0b",
  info: "#009BE0",
  loading: "#009BE0",
};

const ICONS = {
  success: "/img/message/icon-success.svg",
  error: "/img/message/icon-error.svg",
  warning: "/img/message/icon-warning.svg",
  info: "/img/message/icon-info.svg",
  loading: "/img/message/icon-loading.svg",
};

const CustomComponent: React.FC<CustomComponentProps> = ({
  content,
  onClose,
  onCloseCallback,
  status = "success",
}) => {
  const renderContent = useMemo(() => {
    const typeofContent = typeof content;
    const isArray = typeofContent === "object" && Array.isArray(content);
    if (typeofContent === "string") {
      return (
        <Text fontWeight="500" fontSize="16px" color="white">
          {content}
        </Text>
      );
    }

    if (typeofContent === "object") {
      if (isArray && content.length <= 1) {
        return (
          <Text fontWeight="500" fontSize="16px" color="white">
            {content[0] ?? ""}
          </Text>
        );
      }

      if (isArray && content.length > 1) {
        return (
          <>
            <Text fontWeight="700" fontSize="16px" color="white">
              {content[0]}
            </Text>
            <Text fontWeight="400" fontSize="16px" color="white">
              {content[1]}
            </Text>
          </>
        );
      }

      return content;
    }
  }, [content]);

  const onHandleClose = useCallback(() => {
    onClose();
    onCloseCallback && onCloseCallback();
  }, [onCloseCallback, onClose]);

  return (
    <Box
      padding="12px 16px"
      borderRadius="6px"
      background={COLORS[status]}
      boxShadow="0px 1px 2px 0px #000004"
      maxWidth="465px"
      position="relative"
    >
      <Center
        top="4px"
        right="4px"
        position="absolute"
        cursor="pointer"
        width="24px"
        height="24px"
        borderRadius="4px"
        _hover={{
          background: "rgba(0, 0, 0, 0.1)",
        }}
        onClick={onHandleClose}
      >
        <IconBase
          width="16px"
          height="16px"
          icon="/img/message/icon-close.svg"
          color="white"
        />
      </Center>
      <Grid gridTemplateColumns="20px auto 24px" gap={4}>
        <GridItem>
          {status === "loading" ? (
            <Spinner
              thickness="3px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
            />
          ) : (
            <IconBase
              color="white"
              width="20px"
              height="20px"
              marginTop="2px"
              icon={ICONS[status]}
            />
          )}
        </GridItem>
        <GridItem>{renderContent}</GridItem>
      </Grid>
    </Box>
  );
};

export default CustomComponent;
