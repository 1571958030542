import { useCallback } from "react";
import { elementIsVisibleInViewport } from "utils/dom";

export const useScrollToElement = () => {
  return useCallback((element?: HTMLElement | null) => {
    if (!element) return;
    const isVisible = elementIsVisibleInViewport(element, true);
    if (!isVisible) {
      element.scrollIntoView({
        behavior: "auto",
        block: "center",
      });
    }
  }, []);
};
