
import { ComponentPropsWithRef, memo } from "react";

const IconDocument = memo<ComponentPropsWithRef<"svg">>(({ ...props }) => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="#737373" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M16 28H25C25.5667 28 26.0417 27.8083 26.425 27.425C26.8083 27.0417 27 26.5667 27 26C27 25.4333 26.8083 24.9583 26.425 24.575C26.0417 24.1917 25.5667 24 25 24H16C15.4333 24 14.9583 24.1917 14.575 24.575C14.1917 24.9583 14 25.4333 14 26C14 26.5667 14.1917 27.0417 14.575 27.425C14.9583 27.8083 15.4333 28 16 28ZM16 36H18C18.5667 36 19.0417 35.8083 19.425 35.425C19.8083 35.0417 20 34.5667 20 34C20 33.4333 19.8083 32.9583 19.425 32.575C19.0417 32.1917 18.5667 32 18 32H16C15.4333 32 14.9583 32.1917 14.575 32.575C14.1917 32.9583 14 33.4333 14 34C14 34.5667 14.1917 35.0417 14.575 35.425C14.9583 35.8083 15.4333 36 16 36ZM10 44C8.9 44 7.95833 43.6083 7.175 42.825C6.39167 42.0417 6 41.1 6 40V8C6 6.9 6.39167 5.95833 7.175 5.175C7.95833 4.39167 8.9 4 10 4H24.35C24.8833 4 25.3917 4.1 25.875 4.3C26.3583 4.5 26.7833 4.78333 27.15 5.15L36.85 14.85C37.2167 15.2167 37.5 15.6417 37.7 16.125C37.9 16.6083 38 17.1167 38 17.65V18.3C38 18.8667 37.8083 19.3417 37.425 19.725C37.0417 20.1083 36.5667 20.3 36 20.3C35.4333 20.3 34.9583 20.1083 34.575 19.725C34.1917 19.3417 34 18.8667 34 18.3V18H26C25.4333 18 24.9583 17.8083 24.575 17.425C24.1917 17.0417 24 16.5667 24 16V8H10V40H21.05C21.6167 40 22.0917 40.1917 22.475 40.575C22.8583 40.9583 23.05 41.4333 23.05 42C23.05 42.5667 22.8583 43.0417 22.475 43.425C22.0917 43.8083 21.6167 44 21.05 44H10Z" />
            <path fillRule="evenodd" clipRule="evenodd" d="M26 40.5V36.45C26 36.2645 26.0333 36.0848 26.1 35.9109C26.1667 35.7369 26.2833 35.5667 26.45 35.4L37.0306 24.8613C37.3344 24.5573 37.6719 24.3378 38.0431 24.2027C38.4144 24.0675 38.7833 24 39.15 24C39.55 24 39.9333 24.075 40.3 24.225C40.6667 24.375 41 24.6 41.3 24.9L43.15 26.75C43.45 27.05 43.6667 27.3833 43.8 27.75C43.9333 28.1167 44 28.4833 44 28.85C44 29.2167 43.925 29.5917 43.775 29.975C43.625 30.3583 43.3986 30.7021 43.0959 31.0062L32.6 41.55C32.4333 41.7167 32.2631 41.8333 32.0892 41.9C31.9152 41.9667 31.7355 42 31.55 42H27.5C27.075 42 26.7188 41.8563 26.4312 41.5688C26.1437 41.2813 26 40.925 26 40.5ZM29 39.0008H30.9L36.95 32.9008L36.05 31.9508L35.1 31.0508L29 37.1008V39.0008Z" />
        </svg>
    );
});

export default IconDocument;
