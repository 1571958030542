import { taskApi } from "apiClient/v2";
import { message } from "components/base";
import { SystemModeType } from "constants/enum";
import { MessageType } from "constants/websocket";
import { TaskDTO } from "interfaces/dtos/taskDTO";
import { WSMessage } from "interfaces/models/websocket";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCreateTask, setIsMoveTaskLabel } from "redux/forgeViewerSlice";
import { RootState } from "redux/store";
import { setTask } from "redux/taskSlice";
import { getNetworkStatus } from "utils/common";
import { getDbIdByExternalId } from "utils/forge";
import { ClickInfo } from "utils/forge/extensions/click-extension";
import { updateLabel } from "utils/forge/extensions/custom-label";

interface iProps {
  clickInfo?: ClickInfo;
  setClickInfo?: React.Dispatch<React.SetStateAction<ClickInfo | undefined>>;
  handleCloseTaskModal: () => void;
  mapTaskType: { [key: string]: string };
  sendWebSocketMessage: (message: WSMessage) => void;
}

const useHandleMoveTaskLabel = ({
  clickInfo,
  mapTaskType,
  setClickInfo,
  handleCloseTaskModal,
  sendWebSocketMessage,
}: iProps) => {
  const dispatch = useDispatch();
  const { isMoveTaskLabel, systemMode } = useSelector(
    (state: RootState) => state.forgeViewer
  );
  const { taskSelected } = useSelector((state: RootState) => state.task);

  const showTooltipMoveTaskLabel = useMemo(
    () => systemMode === SystemModeType.Task && isMoveTaskLabel,
    [systemMode, isMoveTaskLabel]
  );
  const taskSelectedRef = useRef<TaskDTO>();

  useEffect(() => {
    if (!taskSelected?.id) {
      dispatch(setIsMoveTaskLabel(false));
    }

    taskSelectedRef.current = taskSelected;
  }, [taskSelected, dispatch]);

  useEffect(() => {
    if (
      !clickInfo ||
      systemMode !== SystemModeType.Task ||
      !clickInfo.forgeData?.position ||
      !isMoveTaskLabel
    ) {
      return;
    }

    handleMoveTaskLabel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clickInfo, isMoveTaskLabel]);

  const handleMoveTaskLabel = async () => {
    const position = clickInfo?.forgeData?.position;
    const externalId = clickInfo?.forgeData?.externalId;
    const taskSelected = taskSelectedRef?.current;
    if (!position || !taskSelected?.id) {
      return;
    }

    const isOnline = getNetworkStatus();
    setClickInfo?.(undefined);
    const body = {
      id: taskSelected?.id,
      position,
      externalId,
      documentItemId: taskSelected?.documentItemId,
    } as TaskDTO;

    let { data: newTask } = await taskApi.updateTask(body);
    if (newTask) {
      newTask.dbId = getDbIdByExternalId(newTask.externalId);
      newTask.templateId = taskSelected?.templateId;
      if (!isOnline) {
        newTask = { ...taskSelected, ...newTask };
      }

      sendWebSocketMessage({
        type: MessageType.UPDATE_TASK,
        taskId: newTask?.id!,
        data: { id: newTask?.id, level: newTask?.level },
      });

      dispatch(setTask(newTask));
      updateLabel(newTask.id, {
        id: newTask.id,
        position: newTask.position,
        title: mapTaskType[newTask.taskTypeId || ""] || "-",
        indexId: newTask.indexId,
        showImage: Number(newTask.images?.length) > 0,
        status: newTask.status,
        externalId: newTask.externalId,
      });
      message.success("指摘ポイントの移動が完了しました。");
      dispatch(setIsMoveTaskLabel(false));
      handleCloseTaskModal();
    }
  };

  const toggleMoveTaskLabelMode = useCallback(() => {
    if (!isMoveTaskLabel) {
      dispatch(setCreateTask(false));
    }

    dispatch(setIsMoveTaskLabel(!isMoveTaskLabel));
  }, [dispatch, isMoveTaskLabel]);

  return { toggleMoveTaskLabelMode, showTooltipMoveTaskLabel };
};

export default useHandleMoveTaskLabel;
