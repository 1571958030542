import { Box, BoxProps } from "@chakra-ui/react";
import { ContentType } from "constants/enum";
import { useResize } from "hooks/useResize";
import { DocumentCategoryDTO } from "interfaces/dtos/documentCategoryDTO";
import {
  DocumentItemDTO,
  DocumentSubItemDTO,
} from "interfaces/dtos/documentItemDTO";
import { Blackboard } from "interfaces/models/blackboard";
import { BlackboardTemplate } from "interfaces/models/blackboardTemplate";
import { iBlackboardTemplateProps } from "interfaces/models/documentTemplate";
import { useEffect, useRef, useState } from "react";
import BlackboardTemplateImage from "./BlackboardTemplateImage";
import { PresignedImageWithRef } from "./PresignedImage";

interface Props extends BoxProps {
  src?: string;
  blackBoard?: Blackboard;
  widthContainer?: string;
  heightContainer?: string;
  positionBlackBoard?: number[];
  documentSubItem?: DocumentSubItemDTO;
  blackboardTemplateProps: iBlackboardTemplateProps;

  onImageClick?: () => void;
}

export const DEFAULT_SCALE = 0.2;
export const DEFAULT_BLACKBOARD_WIDTH = 320 * 1.3;
export const DEFAULT_BLACKBOARD_HEIGHT = 240 * 1.3;

export const MIN_BLACKBOARD_WIDTH = Math.floor(DEFAULT_BLACKBOARD_WIDTH * 0.2);
export const MIN_BLACKBOARD_HEIGHT = Math.floor(
  DEFAULT_BLACKBOARD_HEIGHT * 0.2
);

export default function BlackboardImage({
  documentSubItem,
  src,
  widthContainer,
  heightContainer,
  positionBlackBoard,
  blackBoard,
  blackboardTemplateProps,
  onImageClick,
  ...rest
}: Props) {
  const imgRef = useRef<HTMLImageElement>(null);
  const bbRef = useRef<HTMLDivElement>();
  const containerRef = useRef<HTMLDivElement>(null);

  const [isLoaded, setIsLoaded] = useState(false);
  const [bbWidth, setBbWidth] = useState(0);
  const [bbHeight, setBbHeight] = useState(0);
  const [bbX, setBbX] = useState(0);
  const [bbY, setBbY] = useState(0);

  const { width: windowWidth } = useResize();

  useEffect(() => {
    calculateScale();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowWidth, positionBlackBoard, blackBoard, bbRef]);

  useEffect(() => {
    const ele = imgRef.current;
    if (!ele) {
      return;
    }

    const resizeObserver = new ResizeObserver((entries) => {
      calculateScale();
    });

    resizeObserver.observe(ele);

    return () => {
      resizeObserver.unobserve(ele);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imgRef]);

  const calculateScale = () => {
    if (!imgRef?.current) {
      return;
    }

    const currentImageWidth = imgRef.current.clientWidth;
    const currentImageHeight = imgRef.current.clientHeight;
    const [x, y, width, imgWidth, height, imgHeight] = positionBlackBoard || [];

    const scaleImageWidth = currentImageWidth / imgWidth;
    const scaleImageHeight = currentImageHeight / imgHeight;

    setBbWidth(width * scaleImageWidth);
    setBbHeight(height * scaleImageHeight);
    setBbX(x * scaleImageWidth);
    setBbY(y * scaleImageHeight);
  };

  const onImageLoad = () => {
    setIsLoaded(true);
    calculateScale();
  };

  useEffect(() => {
    const containerElement = containerRef?.current;

    if (!containerElement) {
      return;
    }

    const resizeObserver = new ResizeObserver(calculateScale);
    resizeObserver.observe(containerElement);

    return () => {
      if (!containerElement) {
        return;
      }

      resizeObserver.unobserve(containerElement);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerRef?.current]);

  const changeBlackBoardRef = (e: any) => {
    if (e) {
      bbRef.current = e;
      calculateScale();
    }
  };

  return (
    <>
      <Box
        position="relative"
        width={widthContainer || "auto"}
        height={heightContainer || "100%"}
        ref={containerRef}
        {...rest}
      >
        <PresignedImageWithRef
          crossOrigin="anonymous"
          src={src}
          _ref={imgRef}
          onLoad={onImageLoad}
          isConvertToBase64={true}
          boxLoadingWidth="100%"
          boxLoadingHeight="302px"
          objectFit="contain"
          style={{
            height: "auto",
            width: "auto",
          }}
        />

        {blackBoard &&
          documentSubItem?.isShowBlackboard &&
          positionBlackBoard?.length &&
          isLoaded &&
          blackboardTemplateProps?.blackboardTemplate && (
            <Box
              ref={changeBlackBoardRef}
              position="absolute"
              width={bbWidth}
              height={bbHeight}
              top={bbY}
              left={bbX}
              background="red"
              border="1px solid #ccc"
            >
              <BlackboardTemplateImage
                blackboardTemplateProps={{
                  ...blackboardTemplateProps,
                  isOnlyView: true,
                }}
                blackboardData={blackBoard}
                data={blackboardTemplateProps?.blackboardTemplate}
                contentType={ContentType.BLACKBOARD_TEMPLATE}
              />
            </Box>
          )}
      </Box>
    </>
  );
}
