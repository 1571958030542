import { useEffect } from "react";

export const useEventListener = () => {
  useEffect(() => {
    const notPassiveMouseWheelEventListener = (event: any) => {
      if (!event.ctrlKey) {
        return;
      }
      event.preventDefault();
    };

    const onmouseDown = (event: any) => {
      if (event.target.closest("#forge-viewer label")) {
        // when scroll combine label -> stop propagation for camera forge viewer not change => not redraw label
        //@ts-ignore
        document.activeElement?.blur();
        event.preventDefault();
        event.stopPropagation();
      }
    };

    document.addEventListener("mousedown", onmouseDown, true);
    document.addEventListener("wheel", notPassiveMouseWheelEventListener, {
      passive: false,
    });

    return () => {
      document.removeEventListener("wheel", notPassiveMouseWheelEventListener);
      document.removeEventListener("mousedown", onmouseDown, true);
    };
  }, []);
};
