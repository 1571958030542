export const routePath = Object.freeze({
  // Login: "/login",
  SignUp: "/signup",
  Profile: "/profile",
  ChangePassword: "/change-password",
  Home: "/",
  ForgeViewer: "/:projectBimFileId/forge-viewer/:bimFileId/:version",
  ProjectOverviewCreate: "/:projectId/project-overview/create",
  ProjectOverview: "/:projectId/project-overview/:id",
  PhotoList: "/:projectId/photo-list",
  Blackboard: "/:projectId/blackboard",
  Document: "/:projectId/document",
  DocumentDetail: "/:projectId/document/:documentId",
  DocumentFloor: "/:projectId/document-floor",
  DocumentLevel: "/:projectBimFileId/document-level",
  DocumentTemplate: "/document/document-template",
  DocumentTemplateEdit: "/document/document-template/edit/:templateId",
  DocumentTemplateView: "/document/document-template/:templateId",
  UserDashboard: "/user-dashboard",
  ProjectDashboard: "/project-dashboard/:projectId",
  Shorten: "/shorten/:id",
  DocumentTemplateList: "/document/template-list/",
  DocumentTaskList: "/:projectBimFileId/document-task-list",
  ListTaskType: "/task-type-list",
  blackboardTemplateList: "/document/blackboard-template-list/",
  blackboardTemplateEdit: "/document/blackboard-template/edit/:templateId",
  blackboardTemplate: "/document/blackboard-template",
  TaskSheetTemplate: "/document/tasksheet-template",
  TaskSheetTemplateList: "/document/tasksheet-template-list",
  TaskSheetTemplateEdit: "/document/tasksheet-template/edit/:templateId",
  TaskSheetTemplateView: "/document/tasksheet-template/:templateId",
  AccessError: "/access-error",
  NotFound: "/not-found",
  SettingFamily: "/setting-family",
  Schedules: "/:projectId/schedules",
});
