import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DISPLAY_MODE } from "constants/forge";
import { REDUCER_KEY } from "constants/redux";

export interface iProjectCachedInfo {
  isFirstChecked: boolean;
  allUrlCachedCount: number;
  currentUrlCachedCount: number;
}

export interface AppState {
  size: {
    width: number;
    height: number;
  };
  isOpenMenu: boolean;
  isOnline: boolean;
  timeBeginOffline: number;
  syncDataOption: {
    mapSyncDataTimeByProject?: { [key: string]: Date | null };
    mapModelCached?: {
      [projectBimFileId: string]: {
        [level: string]: {
          modelType: DISPLAY_MODE[];
        };
      };
    };
  };
  isShowOfflineMsg: boolean;
  actualNetworkStatus: boolean;
  isServiceWorkerReady: boolean;
  isInstallingServiceWorker: boolean;
  isSyncOfflineData: boolean;
  isCheckedPrecacheMissStaticFile: boolean;
  shouldLogout: number;
  sizePanel: {
    width: number;
    unit: string;
  };
}

const initialState: AppState = {
  size: {
    width: 0,
    height: 0,
  },
  isOpenMenu: false,
  isOnline: navigator.onLine,
  timeBeginOffline: 0,
  syncDataOption: {
    mapSyncDataTimeByProject: {},
    mapModelCached: {},
  },
  isShowOfflineMsg: true,
  actualNetworkStatus: navigator.onLine,
  isServiceWorkerReady: false,
  isSyncOfflineData: false,
  isInstallingServiceWorker: false,
  shouldLogout: 0,
  isCheckedPrecacheMissStaticFile: false,
  sizePanel: {
    width: 0,
    unit: "px",
  },
};

export const appSlice = createSlice({
  name: REDUCER_KEY.APP,
  initialState,
  reducers: {
    setAppSize: (
      state,
      action: PayloadAction<{ width: number; height: number }>
    ) => {
      const { width } = action.payload;
      const widthRatio = Math.round(width / 3); // 3 is the ratio of the width of the panel
      const widthPanel = widthRatio >= 440 ? 440 : widthRatio; // 440 is the maximum width of the panel
      state.sizePanel = {
        width: widthPanel,
        unit: "px",
      };
      state.size = action.payload;
    },
    setActualNetworkStatus: (state, action: PayloadAction<boolean>) => {
      state.actualNetworkStatus = action.payload;
      if (action.payload === false) {
        state.timeBeginOffline = new Date().getTime();
        state.isOnline = false;
      }
    },
    setTimeBeginOffline: (state, action: PayloadAction<number>) => {
      state.timeBeginOffline = action.payload;
    },
    setSyncDataOption: (
      state,
      action: PayloadAction<Partial<AppState["syncDataOption"]>>
    ) => {
      state.syncDataOption = { ...state.syncDataOption, ...action.payload };
    },
    setOnline: (state, action: PayloadAction<boolean>) => {
      const isOnline = action.payload;
      state.isOnline = isOnline;
      if (!isOnline) {
        state.timeBeginOffline = new Date().getTime();
      }
    },
    setIsSyncOfflineData: (state, action: PayloadAction<boolean>) => {
      state.isSyncOfflineData = action.payload;
    },
    setIsServiceWorkerReady: (state, action: PayloadAction<boolean>) => {
      state.isServiceWorkerReady = action.payload;
    },
    setIsIsInstallingServiceWorker: (state, action: PayloadAction<boolean>) => {
      state.isInstallingServiceWorker = action.payload;
    },
    setIsCheckedPrecacheMissStaticFile: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isCheckedPrecacheMissStaticFile = action.payload;
    },
    setShouldLogout: (state) => {
      state.shouldLogout = Date.now();
    },
    setIsShowOfflineMsg: (state, action: PayloadAction<boolean>) => {
      state.isShowOfflineMsg = action.payload;
    },
    clearState: () => initialState,
  },
});

export const {
  setTimeBeginOffline,
  setAppSize,
  setOnline,
  setActualNetworkStatus,
  setIsServiceWorkerReady,
  setIsSyncOfflineData,
  setIsIsInstallingServiceWorker,
  setIsCheckedPrecacheMissStaticFile,
  setShouldLogout,
  setSyncDataOption,
  clearState,
  setIsShowOfflineMsg,
} = appSlice.actions;

export default appSlice.reducer;
