import { useBoolean } from "@chakra-ui/react";
import { blackboardApi, documentItemApi } from "apiClient/v2";
import {
  DocumentItemDTO,
  DocumentSubItemDTO,
} from "interfaces/dtos/documentItemDTO";
import { Blackboard } from "interfaces/models/blackboard";
import { DocumentSubItem } from "interfaces/models/documentItem";
import { DocumentTemplate } from "interfaces/models/documentTemplate";
import { SizePosition } from "interfaces/models/rnd";
import { GetContentItemLog } from "models/documentItemLog";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  setDataBlackboard,
  setDataBlackboards,
  updateDocumentItem,
} from "redux/documentSlice";
import { updateElementInArray } from "utils/array";

interface Props {
  subItem?: DocumentSubItemDTO;
  documentTemplate?: DocumentTemplate;
  isLoadedBlackboard: boolean;
  dataBlackBoards: Blackboard[];
  dataBlackboardDefault?: Partial<Blackboard>;
  documentItemSelected?: DocumentItemDTO;
  isDisabled?: boolean;
  insertItemLog?: (params: GetContentItemLog) => Promise<void>;
}

const useBlackboard = (props: Props) => {
  const {
    subItem,
    documentTemplate,
    dataBlackBoards,
    isLoadedBlackboard,
    documentItemSelected,
    dataBlackboardDefault,
    isDisabled,
    insertItemLog,
  } = props;

  const [isOpenSelectBlackboardPosition, setIsOpenSelectBlackboardPosition] =
    useBoolean();
  const [dataBlackboard, setDataBlackboardState] = useState<
    Blackboard | undefined
  >();
  const [loadingGetBlackboardData, setLoadingGetBlackboardData] =
    useBoolean(true);
  const dispatch = useDispatch();

  const dataBlackBoardDefaultRef = useRef<Partial<Blackboard> | undefined>();
  const dataBlackBoardsRef = useRef<Blackboard[]>(dataBlackBoards);

  const blackboardTemplate = useMemo(
    () => documentTemplate?.blackboardTemplateDetail,
    [documentTemplate?.blackboardTemplateDetail]
  );

  const isShowBlackboard = useMemo(
    () => !!blackboardTemplate?.id && subItem?.isShowBlackboard !== false,
    [blackboardTemplate?.id, subItem?.isShowBlackboard]
  );

  const blackboardPosition = useMemo(() => {
    const pos = subItem?.blackboardImagePosition as SizePosition;

    return [
      pos?.x ?? 0,
      pos?.y ?? 0,
      pos?.width ?? 0,
      pos?.imgWidth ?? 0,
      pos?.height ?? 0,
      pos?.imgHeight ?? 0,
    ];
  }, [subItem?.blackboardImagePosition]);

  useEffect(() => {
    dataBlackBoardsRef.current = dataBlackBoards;
  }, [dataBlackBoards]);

  useEffect(() => {
    dataBlackBoardDefaultRef.current = dataBlackboardDefault;
  }, [dataBlackboardDefault]);

  useEffect(() => {
    (async () => {
      if (!subItem?.id || !documentItemSelected?.id || !isLoadedBlackboard) {
        return;
      }

      const now = new Date();
      const blackboardId = subItem?.blackboardId;
      let dataBlackBoard: Blackboard | undefined;
      if (blackboardId) {
        dataBlackBoard = dataBlackBoardsRef.current?.find(
          (item) => item.id === blackboardId
        );
        if (!dataBlackBoard) {
          const { data } = await blackboardApi.getBlackboardList({
            documentCategoryId: documentItemSelected.documentCategoryId,
          });

          dispatch(setDataBlackboards(data));
          dataBlackBoard = data.find((item) => item.id === blackboardId);
        }
      } else if (dataBlackBoardDefaultRef.current) {
        const { data } = await blackboardApi.createBlackboard({
          ...dataBlackBoardDefaultRef.current,
          documentCategoryId: documentItemSelected.documentCategoryId,
        });

        dataBlackBoard = data;

        if (dataBlackBoard?.id) {
          await documentItemApi.updateSubItem({
            id: subItem.id,
            itemId: subItem.itemId,
            blackboardId: dataBlackBoard.id,
          } as DocumentSubItem);

          const itemItem = structuredClone(documentItemSelected);
          updateElementInArray({
            array: itemItem?.subItems || [],
            keyIndex: "id",
            element: {
              ...subItem,
              updatedAt: now,
              blackboardId: dataBlackBoard.id,
            } as DocumentSubItemDTO,
          });

          dispatch(updateDocumentItem(itemItem));
          dispatch(setDataBlackboard(dataBlackBoard));
        }
      }

      setDataBlackboardState(dataBlackBoard);
      setLoadingGetBlackboardData.off();
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subItem?.id, documentItemSelected?.id, isLoadedBlackboard]);

  const handleClosePositionBlackboardModal = useCallback(() => {
    setIsOpenSelectBlackboardPosition.off();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenPositionBlackboardModal = useCallback(() => {
    setIsOpenSelectBlackboardPosition.on();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeItemData = useCallback(
    (key: keyof DocumentSubItemDTO) => (e: any) => {
      if (!documentItemSelected?.id || !subItem?.id || isDisabled) {
        return;
      }

      const documentItem = structuredClone(documentItemSelected);
      const now = new Date();

      if (key === "isShowBlackboard") {
        const isShowBlackboard = e?.target?.checked || false;
        updateElementInArray({
          array: documentItem?.subItems || [],
          keyIndex: "id",
          element: {
            ...subItem,
            isShowBlackboard,
            updatedAt: now,
          },
        });

        documentItemApi.updateSubItem({
          id: subItem.id,
          itemId: subItem.itemId,
          isShowBlackboard,
        } as DocumentSubItemDTO);
        insertItemLog?.({ field: key as any });
      }
      dispatch(updateDocumentItem(documentItem));
    },
    [documentItemSelected, subItem, insertItemLog, dispatch, isDisabled]
  );

  const handleChangeBlackboardData = useCallback(
    (data: Blackboard) => {
      if (!dataBlackboard?.id || isDisabled) {
        return;
      }

      const body: Partial<Blackboard> = {
        ...data,
        updatedAt: new Date(),
      };
      const newDataBlackboard: Blackboard = {
        ...dataBlackboard,
        ...body,
      };

      setDataBlackboardState(newDataBlackboard);
      dispatch(setDataBlackboard(newDataBlackboard));
      blackboardApi.updateBlackboard({
        id: newDataBlackboard.id,
        ...body,
      });
      insertItemLog?.({ field: "blackboard" as any });
    },
    [dataBlackboard, isDisabled, insertItemLog, dispatch]
  );

  const handleUpdateDataBlackboardState = useCallback(
    (data: Partial<Blackboard>) => {
      if (isDisabled) return;
      if (dataBlackboard) {
        const now = new Date();

        setDataBlackboardState((prev) => ({
          ...prev!,
          ...data,
          updatedAt: now,
        }));
      }
    },
    [dataBlackboard, isDisabled]
  );

  return {
    isOpenSelectBlackboardPosition,
    dataBlackboard,
    isShowBlackboard,
    blackboardTemplate,
    loadingGetBlackboardData,
    blackboardPosition,

    handleChangeItemData,
    handleOpenPositionBlackboardModal,
    handleClosePositionBlackboardModal,
    handleChangeBlackboardData,
    handleUpdateDataBlackboardState,
  };
};

export default useBlackboard;
