import {
  Box,
  Button,
  Flex,
  FormControl,
  Grid,
  GridItem,
  Image,
  Spinner,
  Text,
  useBoolean,
} from "@chakra-ui/react";
import { documentItemApi } from "apiClient/v2";
import { IconBase, message } from "components/base";
import FileUpload from "components/FileUpload";
import { withPresignedUrl } from "components/HOC/presignedUrl";
import ConfirmModal from "components/modal/ConfirmDeleteModal";
import { SvgIcon } from "components/SvgIcon";
import BlackboardImage from "components/ui/BlackboardImage";
import { DocumentItemKey } from "constants/enum";
import { buttonOutlineStyle } from "constants/styleProps";
import { saveAs } from "file-saver";
import {
  DocumentItemDTO,
  DocumentSubItemDTO,
} from "interfaces/dtos/documentItemDTO";
import { UserDTO } from "interfaces/dtos/UserDTO";
import { FileModel, FileUploadInfo } from "interfaces/models";
import { Blackboard } from "interfaces/models/blackboard";
import { BlackboardTemplate } from "interfaces/models/blackboardTemplate";
import { iBlackboardTemplateProps } from "interfaces/models/documentTemplate";
import { User } from "interfaces/models/user";
import { GetContentItemLog, OPERATION_ITEM_LOG } from "models/documentItemLog";
import ComponentToggle from "pages/project-overview/ui/ComponentToggle";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateDocumentItem } from "redux/documentSlice";
import { RootState } from "redux/store";
import { updateElementInArray } from "utils/array";
import { getDateModifiedDateFile, getPreSignUrl } from "utils/file";
import CameraModal from "../CameraModal";
import DrawToolModal from "../DrawToolModal";
import EditPositionBlackBoardModal from "../EditPositionBlackBoardModal";
import { useCamera, useDrawImage, usePhoto } from "./hooks";
import { useAuthorization } from "hooks/usePermission";

const ImagePresigned = withPresignedUrl(Image);
const BlackboardImagePresigned = withPresignedUrl(BlackboardImage);

interface Props {
  documentItemSelected?: DocumentItemDTO;
  subItem?: DocumentSubItemDTO;
  dataBlackboard?: Blackboard;
  blackboardTemplate?: BlackboardTemplate;
  blackboardPosition: number[];
  isOpenSelectBlackboardPosition: boolean;
  currentUser?: User | null;
  users: UserDTO[];
  blackboardTemplateProps: iBlackboardTemplateProps;
  isDisabled?: boolean;

  handleUpdateDataBlackboardState: (data: Partial<Blackboard>) => void;
  insertSubItemLog: (params: GetContentItemLog) => Promise<void>;
  onOpenPositionBlackboardModal: () => void;
  onClosePositionBlackboardModal: () => void;
}

const Photo = (props: Props) => {
  const {
    subItem,
    dataBlackboard,
    blackboardTemplate,
    isOpenSelectBlackboardPosition,
    documentItemSelected,
    blackboardPosition,
    currentUser,
    users,
    blackboardTemplateProps,
    insertSubItemLog,
    onOpenPositionBlackboardModal,
    onClosePositionBlackboardModal,
    handleUpdateDataBlackboardState,
    isDisabled,
  } = props;

  const [originImageLink, setOriginImageLink] = useState("");
  const [isOpenConfirmDeleteImage, setIsOpenConfirmDeleteImage] = useBoolean();
  const [isDownloadOriginImage, setIsDownloadOriginImage] = useBoolean();
  const [originImgSelectedTemp, setOriginImgSelectedTemp] = useState<FileModel>(
    {}
  );

  const dispatch = useDispatch();
  const imageCaptured = useRef<File>();

  const { isOnline } = useSelector((state: RootState) => state.app);
  const { canEditTaskModal } = useAuthorization();

  const {
    isHasBlackboard,
    isHasBlackboardWithOutPosition,
    image,
    blackboardPositionTemp,
    setImage,
    handleClickSelectFile,
    handleChangeFile,
    handleSelectBlackboardPosition,
    handleClosePositionBlackboardModal,
  } = usePhoto({
    subItem,
    dataBlackboard,
    blackboardTemplate,
    documentItemSelected,
    currentUser,
    originImgSelectedTemp,

    insertSubItemLog,
    handleUpdateDataBlackboardState,
    onOpenPositionBlackboardModal,
    onClosePositionBlackboardModal,
  });

  const { isOpenCamera, handleOpenCamera, handleCloseCamera } = useCamera();

  const {
    isOpenDrawImage,
    handleOpenDrawImage,
    handleCloseDrawImage,
    handleSelectDrawImage,
    handleCloseDrawImageAfterSave,
    handleRecaptureImage,
  } = useDrawImage({
    currentUser,
    subItem,
    documentItemSelected,
    originImgSelectedTemp,
    isHasBlackboardWithOutPosition,
    imageCaptured,

    setImage,
    handleOpenCamera,
    onClosePositionBlackboardModal,
    onOpenPositionBlackboardModal,
  });

  const isLoadingUploadImage = useMemo(
    () => subItem?.isLoadingUpdateImage,
    [subItem?.isLoadingUpdateImage]
  );

  const mapUser = useMemo(() => {
    return users.reduce((prev, item) => {
      return Object.assign(prev, {
        [item.id || ""]: item.name,
      });
    }, {}) as { [key: string]: string };
  }, [users]);

  useEffect(() => {
    if (!isOpenSelectBlackboardPosition) {
      imageCaptured.current = undefined;
      setOriginImgSelectedTemp({} as any);
    }
  }, [isOpenSelectBlackboardPosition]);

  useEffect(() => {
    const image: FileUploadInfo = subItem?.images as any;
    const src = image?.originSrc || image?.src;
    const isBase64 = src?.includes("data:image/");

    (async () => {
      if (!image || !src || isBase64) {
        setOriginImageLink("");

        return;
      }

      const img = await getPreSignUrl(src, "");
      setOriginImageLink(img);
    })();
  }, [subItem?.images]);

  const handleCloseConfirmDeleteImage = useCallback(() => {
    setIsOpenConfirmDeleteImage.off();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenConfirmDeleteImage = useCallback(() => {
    setIsOpenConfirmDeleteImage.on();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDeleteImage = useCallback(() => {
    if (!subItem?.id || !documentItemSelected?.id) {
      return;
    }

    const documentItem = structuredClone(documentItemSelected);
    updateElementInArray({
      array: documentItem?.subItems || [],
      keyIndex: "id",
      element: {
        ...subItem,
        images: null as any,
        blackboardImagePosition: null as any,
      } as DocumentSubItemDTO,
    });

    dispatch(updateDocumentItem(documentItem));
    documentItemApi.updateSubItem({
      id: subItem.id,
      itemId: subItem.itemId,
      images: null as any,
      blackboardImagePosition: null as any,
    } as DocumentSubItemDTO);
    handleCloseConfirmDeleteImage();

    insertSubItemLog({
      field: DocumentItemKey.IMAGES,
      operation: OPERATION_ITEM_LOG.DELETE,
    });

    const index =
      documentItemSelected.subItems?.findIndex(
        (sub) => sub.id === subItem.id
      ) || 0;
    message.success(`写真${index + 1}を削除しました。`);
  }, [
    subItem,
    documentItemSelected,
    dispatch,
    handleCloseConfirmDeleteImage,
    insertSubItemLog,
  ]);

  const handleDownloadOriginImage = useCallback(async () => {
    const images: FileUploadInfo | undefined = subItem?.images as any;
    if (!images || !originImageLink) {
      return;
    }

    setIsDownloadOriginImage.on();
    const blob = await fetch(originImageLink).then((res) => res.blob());
    const path = new URL(originImageLink).pathname;
    const fileName = path.substring(path.lastIndexOf("/") + 1);
    setIsDownloadOriginImage.off();
    saveAs(blob, fileName);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subItem, originImageLink]);

  const handleCaptureCamera = useCallback(
    (file: File) => {
      if (isHasBlackboardWithOutPosition) {
        setOriginImgSelectedTemp({ file, name: `${file.name}.png` });
      }

      imageCaptured.current = file;
      handleOpenDrawImage();
    },
    [isHasBlackboardWithOutPosition, handleOpenDrawImage]
  );

  const renderFileInfo = useMemo(() => {
    const images: FileUploadInfo | undefined = subItem?.images as any;

    if (!images || !images?.src) {
      return <></>;
    }

    const link = originImageLink;

    return (
      <Grid
        templateColumns={{
          base: "1fr",
          lg: "auto 1fr",
        }}
        mt="1.2rem"
        gap={2}
      >
        <GridItem flexDirection="column" color="#737373">
          {!!images?.userUpload && (
            <Text fontSize="1rem">{`撮影：${
              mapUser[images.userUpload || ""]
            }`}</Text>
          )}
          {!!images?.uploadTime && (
            <Text fontSize="1rem">{`日時：${getDateModifiedDateFile(
              images
            )}`}</Text>
          )}
          {canEditTaskModal?.canEditImagesField && (
            <Text
              style={
                isOnline
                  ? {
                      fontSize: "1rem",
                      color: "#1795FE",
                      textDecoration: "underline",
                    }
                  : {
                      fontSize: "1rem",
                      color: "#737373",
                      textDecoration: "underline",
                    }
              }
              cursor={isOnline ? "pointer" : "not-allowed"}
              onClick={() => {
                if (isOnline) {
                  window.open(link);
                }
              }}
            >
              元の写真を参照
            </Text>
          )}
        </GridItem>
        <GridItem>
          <Flex
            alignItems="center"
            justifyContent="flex-end"
            gap="1rem"
            flexWrap="wrap"
          >
            {!isDisabled && (
              <>
                <Button
                  {...buttonOutlineStyle}
                  leftIcon={
                    <SvgIcon
                      w="16px"
                      h="16px"
                      src={"/img/icon-edit.svg"}
                      sx={{
                        path: {
                          stroke: "#1795FE",
                        },
                      }}
                    />
                  }
                  isLoading={isLoadingUploadImage}
                  onClick={handleOpenDrawImage}
                >
                  編集
                </Button>
                <Button
                  {...buttonOutlineStyle}
                  isLoading={isLoadingUploadImage}
                  leftIcon={
                    <SvgIcon
                      src={"/img/icon-delete.svg"}
                      w="16px"
                      h="16px"
                      sx={{
                        path: {
                          stroke: "#1795FE",
                        },
                      }}
                    />
                  }
                  isDisabled={!isOnline}
                  onClick={handleOpenConfirmDeleteImage}
                >
                  削除
                </Button>
              </>
            )}
            {link && canEditTaskModal?.canEditImagesField && (
              <Button
                {...buttonOutlineStyle}
                isLoading={isLoadingUploadImage || isDownloadOriginImage}
                leftIcon={
                  <IconBase
                    w="2rem"
                    h="2rem"
                    icon="/img/icon-download.svg"
                    color="font.blue"
                  />
                }
                onClick={handleDownloadOriginImage}
              >
                元の写真をダウンロード
              </Button>
            )}
          </Flex>
        </GridItem>
      </Grid>
    );
  }, [
    isOnline,
    originImageLink,
    subItem,
    mapUser,
    isDownloadOriginImage,
    isLoadingUploadImage,
    isDisabled,
    canEditTaskModal?.canEditImagesField,
    handleOpenDrawImage,
    handleDownloadOriginImage,
    handleOpenConfirmDeleteImage,
  ]);

  const render = useMemo(() => {
    if (!image?.src) {
      if (isDisabled) return null;

      return (
        <FormControl
          isRequired
          w="auto"
          onClick={handleClickSelectFile}
          onChange={handleChangeFile}
        >
          <Flex justifyContent="flex-end" alignItems="center">
            <FileUpload width="auto" accept={"image/*"} mr="1rem">
              <Button
                isLoading={isLoadingUploadImage}
                height="4rem"
                variant="white"
                color="var(--primary-color)"
                p="1rem"
                leftIcon={
                  <SvgIcon
                    src="/img/icon-image-white.svg"
                    sx={{
                      path: {
                        stroke: "var(--primary-color)",
                      },
                    }}
                  />
                }
              >
                選択
              </Button>
            </FileUpload>

            <Button
              isLoading={isLoadingUploadImage}
              height="4rem"
              variant="blue"
              color="white"
              bg="var(--primary-color)"
              p="1rem"
              leftIcon={<SvgIcon src="/img/icon-camera-white.svg" />}
              onClick={handleOpenCamera}
            >
              撮影
            </Button>
          </Flex>
        </FormControl>
      );
    }

    if (isHasBlackboard) {
      return (
        <BlackboardImagePresigned
          documentSubItem={subItem}
          src={image?.src}
          blackBoard={dataBlackboard}
          positionBlackBoard={blackboardPositionTemp}
          {...(!isLoadingUploadImage && subItem?.isShowBlackboard !== false
            ? { onClick: onOpenPositionBlackboardModal }
            : {})}
          blackboardTemplateProps={blackboardTemplateProps}
          isCheckLoadingPresignedUrl={false}
          cursor="pointer"
        />
      );
    }

    if (image?.src) {
      return (
        <Box
          minH="200px"
          display="flex"
          justifyContent="center"
          alignItems="center"
          mb="5px"
          cursor={isDisabled ? "not-allowed" : "pointer"}
        >
          <ComponentToggle
            isToggle={!!image?.src}
            ViewToggle={
              <ImagePresigned
                width="auto"
                height={image?.src ? "auto" : "200px"}
                src={image?.src}
                alt="product images"
                objectFit="contain"
                maxH="200px"
                boxLoadingWidth="100%"
                boxLoadingHeight="200px"
                isCheckLoadingPresignedUrl={!isLoadingUploadImage}
              />
            }
            ViewDefault={
              <Flex
                alignItems="center"
                width="100%"
                height="200px"
                cursor="initial"
              >
                <Spinner color="blue.500" />
              </Flex>
            }
          />
        </Box>
      );
    }
  }, [
    blackboardTemplateProps,
    isLoadingUploadImage,
    image,
    isHasBlackboard,
    dataBlackboard,
    subItem,
    blackboardPositionTemp,
    handleOpenCamera,
    handleChangeFile,
    handleClickSelectFile,
    onOpenPositionBlackboardModal,
    isDisabled,
  ]);

  return (
    <>
      {render}

      {renderFileInfo}

      {isOpenSelectBlackboardPosition && dataBlackboard && !isDisabled && (
        <EditPositionBlackBoardModal
          documentSubItem={subItem}
          blackboardTemplateDetail={blackboardTemplate}
          isOpen={isOpenSelectBlackboardPosition}
          blackBoard={dataBlackboard}
          imgSrc={image?.src}
          position={blackboardPosition}
          blackboardTemplateProps={blackboardTemplateProps}
          onSelect={handleSelectBlackboardPosition}
          onClose={handleClosePositionBlackboardModal}
        />
      )}

      {isOpenCamera && (
        <CameraModal
          isOpen={isOpenCamera}
          onClose={handleCloseCamera}
          onCapture={handleCaptureCamera}
          blackboardTemplateProps={blackboardTemplateProps}
        />
      )}

      {isOpenConfirmDeleteImage && (
        <ConfirmModal
          title="削除の確認"
          content="削除してよろしいですか。"
          buttonConfirm="削除"
          isOpen={isOpenConfirmDeleteImage}
          onClose={handleCloseConfirmDeleteImage}
          onProcessing={handleDeleteImage}
        />
      )}

      {isOpenDrawImage && !isDisabled && (
        <DrawToolModal
          isOpen={isOpenDrawImage}
          onSelect={handleSelectDrawImage}
          onClose={handleCloseDrawImage}
          onCloseAfterSave={handleCloseDrawImageAfterSave}
          fileRef={imageCaptured}
          imageUrl={imageCaptured.current ? "" : image?.src || ""}
          reCapture={handleRecaptureImage}
        />
      )}
    </>
  );
};

export default memo(Photo);
