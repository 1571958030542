import {
  DATA_STORE,
  FILE_STORE,
  PROJECT_DATA_CACHE_PROGRESS_STORE,
  UpdateToOnlineStatus,
} from "constants/serviceWorker";
import { iCachedItem } from "interfaces/models/serviceWorker";
import { logWarn } from "./logs";

export const INDEXED_DB_NAME = "neptune-indexed-db";
export const VERSION_INDEX_DB = 2;

class IndexedDb {
  private db: IDBDatabase | undefined;

  async openConnection(): Promise<void> {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(INDEXED_DB_NAME, VERSION_INDEX_DB);

      request.onerror = () => {
        reject("Failed to open indexed database");
      };

      request.onupgradeneeded = (event: IDBVersionChangeEvent) => {
        this.db = (event.target as IDBOpenDBRequest).result;

        if (
          !this.db.objectStoreNames.contains(PROJECT_DATA_CACHE_PROGRESS_STORE)
        ) {
          this.db.createObjectStore(PROJECT_DATA_CACHE_PROGRESS_STORE, {
            keyPath: "id",
          });
        }

        if (!this.db.objectStoreNames.contains(FILE_STORE)) {
          this.db.createObjectStore(FILE_STORE, { keyPath: "id" });
        }

        if (!this.db.objectStoreNames.contains(DATA_STORE)) {
          const store = this.db.createObjectStore(DATA_STORE, {
            keyPath: "id",
          });
          store.createIndex("store", "store", { unique: false });
        }
      };

      request.onsuccess = (event) => {
        this.db = (event.target as IDBOpenDBRequest).result;
        this.db.onversionchange = function (event) {
          (event.target as any)?.close();
        };
        resolve();
      };
    });
  }

  closeConnection() {
    this.db?.close();
  }

  emptyStore(storeName: string) {
    if (!this.db) {
      return;
    }
    try {
      const request = this.db
        ?.transaction(storeName, "readwrite")
        ?.objectStore(storeName)
        .clear();

      request.onsuccess = () => {
        console.log(`Object Store "${storeName}" emptied`);
      };

      request.onerror = (err) => {
        console.error(`Error to empty Object Store: ${storeName}`);
      };
    } catch (err) {
      logWarn(err);
    }
  }

  // will add a new record to the object store only if a record with the same key doesn't already exist
  async add(id: string, data: any): Promise<void> {
    if (!this.db) {
      return;
    }
    const transaction = this.db.transaction(DATA_STORE, "readwrite");
    const store = transaction.objectStore(DATA_STORE);
    store.add({ id, ...data });
    await new Promise<void>((resolve: any, reject: any) => {
      transaction.oncomplete = () => {
        resolve();
      };
      transaction.onerror = reject;
    });
  }

  // will add a new record to the object store if a record with the same key doesn't already exist, or update an existing record if a record with the same key already exists
  async put(id: string, data: any): Promise<boolean> {
    if (!this.db) {
      return false;
    }

    const transaction = this.db.transaction(DATA_STORE, "readwrite");
    const store = transaction.objectStore(DATA_STORE);
    store.put({ id, ...data });
    await new Promise<void>((resolve: any, reject: any) => {
      transaction.oncomplete = () => {
        resolve();
      };
      transaction.onerror = reject;
    });

    return true;
  }

  async putFile(id: string, data: any): Promise<boolean> {
    if (!this.db) {
      return false;
    }

    const transaction = this.db.transaction(FILE_STORE, "readwrite");
    const store = transaction.objectStore(FILE_STORE);
    store.put({ id, ...data });
    await new Promise<void>((resolve: any, reject: any) => {
      transaction.oncomplete = () => {
        resolve();
      };
      transaction.onerror = reject;
    });

    return true;
  }

  async putProjectProgress(id: string, data: any): Promise<boolean> {
    if (!this.db) {
      return false;
    }

    const transaction = this.db.transaction(
      PROJECT_DATA_CACHE_PROGRESS_STORE,
      "readwrite"
    );
    const store = transaction.objectStore(PROJECT_DATA_CACHE_PROGRESS_STORE);
    store.put({ id: id, ...data });
    await new Promise<void>((resolve: any, reject: any) => {
      transaction.oncomplete = () => {
        resolve();
      };
      transaction.onerror = reject;
    });

    return true;
  }

  async get(id: string): Promise<iCachedItem | undefined> {
    if (!this.db) {
      return;
    }
    const transaction = this.db.transaction(DATA_STORE, "readonly");
    const store = transaction.objectStore(DATA_STORE);
    const request = store.get(id);

    await new Promise<void>((resolve: any, reject: any) => {
      transaction.oncomplete = () => {
        resolve();
      };
      transaction.onerror = reject;
    });

    return request.result;
  }

  async getAll(storeName: string): Promise<any> {
    if (!this.db) {
      return;
    }
    try {
      const transaction = this.db.transaction(DATA_STORE, "readonly");
      const store = transaction.objectStore(DATA_STORE);
      const request = store.index("store").getAll(storeName);
      await new Promise<void>((resolve: any, reject: any) => {
        transaction.oncomplete = () => {
          resolve();
        };
        transaction.onerror = reject;
      });

      return request.result;
    } catch (err) {
      return [];
    }
  }

  async getAllFiles(): Promise<any> {
    if (!this.db) {
      return;
    }
    const transaction = this.db.transaction(FILE_STORE, "readonly");
    const store = transaction.objectStore(FILE_STORE);
    const request = store.getAll();
    await new Promise<void>((resolve: any, reject: any) => {
      transaction.oncomplete = () => {
        resolve();
      };
      transaction.onerror = reject;
    });

    return request.result;
  }

  async getProjectDataCacheProgress(): Promise<any> {
    if (!this.db) {
      return;
    }
    const transaction = this.db.transaction(
      PROJECT_DATA_CACHE_PROGRESS_STORE,
      "readonly"
    );
    const store = transaction.objectStore(PROJECT_DATA_CACHE_PROGRESS_STORE);
    const request = store.getAll();
    await new Promise<void>((resolve: any, reject: any) => {
      transaction.oncomplete = () => {
        resolve();
      };
      transaction.onerror = reject;
    });

    return request.result;
  }

  async delete(id: string): Promise<void> {
    if (!this.db) {
      return;
    }
    const transaction = this.db.transaction(DATA_STORE, "readwrite");
    const store = transaction.objectStore(DATA_STORE);
    store.delete(id);
    await new Promise<void>((resolve: any, reject: any) => {
      transaction.oncomplete = () => {
        resolve();
      };
      transaction.onerror = reject;
    });
  }

  async deleteFile(id: string): Promise<void> {
    if (!this.db) {
      return;
    }
    const transaction = this.db.transaction(FILE_STORE, "readwrite");
    const store = transaction.objectStore(FILE_STORE);
    store.delete(id);
    await new Promise<void>((resolve: any, reject: any) => {
      transaction.oncomplete = () => {
        resolve();
      };
      transaction.onerror = reject;
    });
  }

  async deleteList(ids: string[]): Promise<number> {
    if (!this.db) {
      return 0;
    }
    const transaction = this.db.transaction(DATA_STORE, "readwrite");
    const store = transaction.objectStore(DATA_STORE);
    for (const id of ids) {
      const request = store.get(id);
      request.onsuccess = () => {
        const item = request.result;

        if (item) {
          store.delete(id);
        }
      };
      request.onerror = () => {};
    }
    await new Promise<void>((resolve: any, reject: any) => {
      transaction.oncomplete = () => {
        resolve();
      };
      transaction.onerror = reject;
    });

    return ids.length;
  }

  async deleteProjectProgressByIds(ids: string[]): Promise<number> {
    if (!this.db) {
      return 0;
    }
    const transaction = this.db.transaction(
      PROJECT_DATA_CACHE_PROGRESS_STORE,
      "readwrite"
    );
    const store = transaction.objectStore(PROJECT_DATA_CACHE_PROGRESS_STORE);
    const request = store.getAll();
    if (!request) {
      return 0;
    }
    await new Promise((resolve, reject) => {
      request!.onsuccess = (ev) => {
        resolve(ev);
      };
      request.onerror = (err) => {
        reject(err);
      };
    });

    for (const id of ids) {
      const request = store.get(id);
      request.onsuccess = () => {
        const item = request.result;

        if (item) {
          store.delete(id);
        }
      };
      request.onerror = () => {};
    }
    await new Promise<void>((resolve: any, reject: any) => {
      transaction.oncomplete = () => {
        resolve();
      };
      transaction.onerror = reject;
    });

    return ids.length;
  }

  async deleteProjectProgress(projectId: string): Promise<number> {
    if (!this.db) {
      return 0;
    }
    const transaction = this.db.transaction(
      PROJECT_DATA_CACHE_PROGRESS_STORE,
      "readwrite"
    );
    const store = transaction.objectStore(PROJECT_DATA_CACHE_PROGRESS_STORE);
    const request = store.getAll();
    if (!request) {
      return 0;
    }
    const response: any = await new Promise((resolve, reject) => {
      request!.onsuccess = (ev) => {
        resolve(ev);
      };
      request.onerror = (err) => {
        reject(err);
      };
    });

    const ids = response.target.result
      .filter((item: any) => item.projectId === projectId)
      .map((item: any) => item.id);
    for (const id of ids) {
      const request = store.get(id);
      request.onsuccess = () => {
        const item = request.result;

        if (item) {
          store.delete(id);
        }
      };
      request.onerror = () => {};
    }
    await new Promise<void>((resolve: any, reject: any) => {
      transaction.oncomplete = () => {
        resolve();
      };
      transaction.onerror = reject;
    });

    return ids.length;
  }

  async clearCacheProgress(): Promise<number> {
    if (!this.db) {
      return 0;
    }
    const transaction = this.db.transaction(
      PROJECT_DATA_CACHE_PROGRESS_STORE,
      "readwrite"
    );
    const store = transaction.objectStore(PROJECT_DATA_CACHE_PROGRESS_STORE);
    const request = store.getAll();
    if (!request) {
      return 0;
    }
    const response: any = await new Promise((resolve, reject) => {
      request!.onsuccess = (ev) => {
        resolve(ev);
      };
      request.onerror = (err) => {
        reject(err);
      };
    });

    const ids = response.target.result.map((item: any) => item.id);
    for (const id of ids) {
      const request = store.get(id);
      request.onsuccess = () => {
        const item = request.result;

        if (item) {
          store.delete(id);
        }
      };
      request.onerror = () => {};
    }
    await new Promise<void>((resolve: any, reject: any) => {
      transaction.oncomplete = () => {
        resolve();
      };
      transaction.onerror = reject;
    });

    return ids.length;
  }

  async getTableSize(tableName: string): Promise<number> {
    if (!this.db) {
      return 0;
    }
    let size = 0;
    const transaction = this.db
      .transaction([tableName])
      .objectStore(tableName)
      .openCursor();

    return await new Promise<number>((resolve: any, reject: any) => {
      transaction.onsuccess = function (event: any) {
        const cursor = event.target.result;
        if (cursor) {
          const storedObject = cursor.value;
          if (storedObject.status !== UpdateToOnlineStatus.Success) {
            const json = JSON.stringify(storedObject);
            size += json.length;
          }
          if (tableName === FILE_STORE) {
            size += storedObject.data.size || 0;
          }
          cursor.continue();
        } else {
          resolve(size);
        }
      };
      transaction.onerror = function (err) {
        reject(`error in ${tableName}: ${err}`);
      };
    });
  }

  isOpen() {
    return !!this.db;
  }
}

export let _indexedDb: IndexedDb | undefined = undefined;

export const clearAllIndexDbStore = async () => {
  const indexDb = await getIndexedDb();
  if (!indexDb) {
    return;
  }
  await Promise.all(
    [FILE_STORE, DATA_STORE, PROJECT_DATA_CACHE_PROGRESS_STORE].map(
      (storeName) => {
        return indexDb.emptyStore(storeName);
      }
    )
  );
};

export const getIndexedDb = async () => {
  if (!_indexedDb) {
    _indexedDb = new IndexedDb();
  }

  if (!_indexedDb.isOpen()) {
    await _indexedDb.openConnection();
  }

  return _indexedDb;
};

export const getDatabaseSize = async () => {
  const indexedDb = await getIndexedDb();
  if (!indexedDb) {
    return 0;
  }
  const tableNames = [FILE_STORE, DATA_STORE];

  const tableSizeGetters: Promise<number>[] = tableNames.map(
    async (tableName) => {
      try {
        return await indexedDb!.getTableSize(tableName);
      } catch (err) {
        return 0;
      }
    }
  );

  return await Promise.all(tableSizeGetters).then((sizes) => {
    return sizes.reduce(function (acc, val) {
      return acc + val;
    }, 0);
  });
};
