import { iDocumentFilterData } from "interfaces/models";
import { BlackboardData } from "interfaces/models/component";
import {
  CellProperty,
  CellSizeSetting,
  DocumentCategoryStatusType,
  DocumentTemplateType,
  LinkedDataField,
  MapDocumentTitle,
  PaperSize,
  PaperType,
  Priority,
  TableDefaultStyle,
  TextPosition,
} from "./enum";

export const MAX_DOCUMENT_ITEM_CAN_PREVIEW = 150;

export const MAX_DOCUMENT_ITEMS_TO_CACHE_LOG = 3_000;

export interface FlexibleCheckListItem {
  id: string | number;
  title?: string;
  categoryId?: string;
  documentReference?: string;
  criteriaDecide?: string;
}

export const ALL_FlOOR = "全体";

export enum TYPE_CHECK_SLEEVE_DUCT {
  CONFIRM_STATUS_FIRE_PROTECTION_ONE = "1",
  CONFIRM_STATUS_FIRE_PROTECTION_TWO = "2",
  CONFIRM_STATUS_FIRE_PROTECTION_THREE = "3",
}

export const LIST_TYPE_CHECK_SLEEVE_DUCT = {
  [TYPE_CHECK_SLEEVE_DUCT.CONFIRM_STATUS_FIRE_PROTECTION_ONE]:
    "防火区画貫通処理状況確認",
  [TYPE_CHECK_SLEEVE_DUCT.CONFIRM_STATUS_FIRE_PROTECTION_TWO]:
    "防火区画貫通処理状況確認",
  [TYPE_CHECK_SLEEVE_DUCT.CONFIRM_STATUS_FIRE_PROTECTION_THREE]:
    "防火区画貫通処理状況確認",
};

export enum TYPE_CHECK_PAC {
  OPERATION = "1",
  VIBRATION = "2",
  ACTUATION = "3",
}

export enum TYPE_CHECK_FLEXIBLE_DUCT {
  SMOKED_DUCT_THICK_NESS = "1",
  CONNECTION_DUCT = "2",
  SPIRAL_DUCT_SURFACE = "3",
  SPIRAL_DUCT_WRAP = "4",
  HORIZONTAL_DUCT_HANGING = "5",
  HORIZONTAL_DUCT_VIBRATION = "6",
}

export const LIST_TYPE_CHECK_PAC = {
  [TYPE_CHECK_PAC.VIBRATION]: "騒音・振動",
  [TYPE_CHECK_PAC.OPERATION]: "送風機動作・回転",
  [TYPE_CHECK_PAC.ACTUATION]: "スイッチ作動",
};

export enum FLEXIBLE_DUCT_CHECK_CATEGORY {
  CONSTRUCTION_BEFORE = "1",
  CONSTRUCTION_INPROGRESS = "2",
  CONSTRUCTION_AFTER = "3",
}

export const FLEXIBLE_DUCT_CHECK_CATEGORY_NAME = {
  [FLEXIBLE_DUCT_CHECK_CATEGORY.CONSTRUCTION_BEFORE]: "施工前",
  [FLEXIBLE_DUCT_CHECK_CATEGORY.CONSTRUCTION_INPROGRESS]: "施工中",
  [FLEXIBLE_DUCT_CHECK_CATEGORY.CONSTRUCTION_AFTER]: "施工後",
};

export const DOCUMENT_CATEGORY_SETTING_EXECUTE_TITLE = {
  beforeExecute: {
    title: "施工前",
    value: "1",
  },
  inProgressExecute: {
    title: "施工中",
    value: "2",
  },
  afterExecute: {
    title: "施工後",
    value: "3",
  },
};

export const DOCUMENT_TYPE_INFO = {
  [DocumentTemplateType.PHOTO_LEDGER]: {
    typeName: "写真帳票",
    templateTypeName: "写真台帳テンプレート",
  },
  [DocumentTemplateType.SELF_INSPECTION]: {
    typeName: "検査帳票",
    templateTypeName: "ダクト自主検査チェックシート",
  },
  [DocumentTemplateType.COMMISSIONING_TABLE]: {
    typeName: "試運転票",
    templateTypeName: "送風機試運転データシート",
  },
  [DocumentTemplateType.EQUIPMENT_DATA_SHEET]: {
    typeName: "機器データシート",
    templateTypeName: " 機器データシート",
  },
  [DocumentTemplateType.PARENT_TEMPLATE]: {
    typeName: MapDocumentTitle[DocumentTemplateType.PARENT_TEMPLATE],
    templateTypeName: MapDocumentTitle[DocumentTemplateType.PARENT_TEMPLATE],
  },
};

export const LIST_TYPE_CHECK_FLEXIBLE_DUCT = {
  [TYPE_CHECK_FLEXIBLE_DUCT.SMOKED_DUCT_THICK_NESS]:
    "排煙ダクトの板厚は適切適切か",
  [TYPE_CHECK_FLEXIBLE_DUCT.CONNECTION_DUCT]:
    "スパイラルダクトの接続方法は適切か",
  [TYPE_CHECK_FLEXIBLE_DUCT.SPIRAL_DUCT_SURFACE]:
    "スパイラルダクトの継目外面はシーリングをしたか",
  [TYPE_CHECK_FLEXIBLE_DUCT.SPIRAL_DUCT_WRAP]:
    "スパイラルダクトの継目はダクト用テープで二重巻にしたか",
  [TYPE_CHECK_FLEXIBLE_DUCT.HORIZONTAL_DUCT_HANGING]:
    "横走りダクトの吊り間隔は適切か",
  [TYPE_CHECK_FLEXIBLE_DUCT.HORIZONTAL_DUCT_VIBRATION]:
    "横走りダクトの振止支持は適切か",
};

export const documentItemProperties = [
  {
    label: "撮影項目",
    key: "title",
  },
  {
    label: "撮影年月日",
    key: "takeImageDate",
  },
  {
    label: "工事名",
    key: "constructionNameText",
  },
  {
    label: "工事場所",
    key: "constructionAddressText",
  },
  {
    label: "施工者",
    key: "builder",
  },
  {
    label: "内容",
    key: "commentManageExecute",
  },
];

export const FILE_SIZE_LIMIT = 10485760;

export const DEFAULT_DOCUMENT_FILTER_DATA: iDocumentFilterData = {
  documentUserCreated: [],
  documentUserAssigned: [],
  documentHasImage: true,
  documentHasNoImage: true,
  documentTypeDocuments: [],
  documentAreas: [],
  documentWorkTypes: [],
  documentTypeInspections: [],
  documentTags: [],
  documentPriority: [
    ...Object.values(Priority).filter((t) => typeof t === "number"),
  ]?.sort() as number[],
  documentStatus: [
    ...Object.values(DocumentCategoryStatusType),
  ].sort() as string[],
  defaultDocumentEndDate: false,
  documentGroups: [],
};

export const ListTypeDocument = [
  {
    name: "貫通部処理 ",
    // highlight: "支持ピッチ",
    value: "3",
  },
  {
    name: "モジュールチラ",
    value: "5",
  },
  {
    name: "送風機試運転データシート",
    // highlight: "ダクトの勾配",
    value: "2",
  },
  {
    name: "スパイラル・フレキシブルダクト工事自主検査チェックシート",
    // highlight: "ビス留め",
    value: "1",
  },
];

export const ListTags = [
  {
    name: "系統ルート検査",
    value: "系統ルート検査",
  },
  {
    name: "ダンパ類取付検査",
    value: "ダンパ類取付検査",
  },
  {
    name: "防火区画",
    value: "防火区画",
  },
  {
    name: "処理状況確認",
    value: "処理状況確認",
  },
  {
    name: "スリーブインサート工事",
    value: "スリーブインサート工事",
  },
  {
    name: "ダクト工事",
    value: "ダクト工事",
  },
  {
    name: "空調配管工事",
    value: "空調配管工事",
  },
  {
    name: "冷媒配管工事",
    value: "冷媒配管工事",
  },
  {
    name: "衛生配管工事",
    value: "衛生配管工事",
  },
  {
    name: "消火配管工事",
    value: "消火配管工事",
  },
  {
    name: "保温工事",
    value: "保温工事",
  },
  {
    name: "自動制御工事",
    value: "自動制御工事",
  },
  {
    name: "機器据付工事",
    value: "機器据付工事",
  },
  {
    name: "電気工事",
    value: "電気工事",
  },
  {
    name: "仮設工事",
    value: "仮設工事",
  },
];

export const DOC_FILE_EXTENSIONS = ["pdf", "doc", "docx", "xls", "xlsx", "txt"];

export const GRID_TEMPLATE_SIZE = 20;
export const DEFAULT_ROW_GRID = 3;
export const DEFAULT_COLUMNS_GRID = 3;

export const letterPosition = [
  {
    label: "左揃え",
    src: "/img/left-text.svg",
    position: TextPosition.START,
  },
  {
    label: "中央揃え",
    src: "/img/center-text.svg",
    position: TextPosition.CENTER,
  },
  {
    label: "右揃え",
    src: "/img/right-text.svg",
    position: TextPosition.END,
  },
  {
    label: "両端揃え",
    src: "/img/justify-text.svg",
    position: TextPosition.JUSTIFY,
  },
];

export const BLACKBOARD_SCALE_DEFAULT = 0.4;

export const BlackboardDataDefaultOfFilterPhoto: BlackboardData[] = [
  {
    label: "No.",
    field: CellProperty.NO,
    valueDefault: "1",
  },
  {
    label: "撮影日",
    field: LinkedDataField.DOCUMENT_ITEM.TAKE_IMAGE_DATES,
  },
  {
    label: "撮影場所",
    field: LinkedDataField.DOCUMENT_ITEM.LEVEL,
  },
  {
    label: "撮影項目",
    field: null as any,
    valueDefault: "フィルター引抜検査",
  },
  {
    label: "内容",
    valueDefault: "PACフィルター引抜状況",
    field: null as any,
  },
];

export const CUSTOM_COMPONENT_PREFIX = "CUSTOM";

export const CONFIRM_STATUS_FIRE_PROTECTION = {
  1: "防火区画貫通処理状況確認",
  2: "防火区画貫通処理状況確認",
  3: "防火区画貫通処理状況確認",
};

export enum ModuleChillerCheckType {
  ColdWaterVolume,
  ColdWaterInletTemperature,
  ColdWaterOutletTemperature,
  coldWaterInletPressure,
  coldWaterOutletPressure,
  HotWaterAmount,
  HotWaterInletTemperature,
  HotWaterOutletTemperature,
  HotWaterInletPressure,
  HotWaterOutletPressure,
  OperatingVoltage,
  CompressorHydraulic,
  CompressorHighPressure,
  CompressorLowPressure,
  CoolingCapacity,
  HeatingCapacity,
}

export const MODULE_CHILLER_CHECK_TYPES = {
  [ModuleChillerCheckType.ColdWaterVolume]: "冷水量",
  [ModuleChillerCheckType.ColdWaterInletTemperature]: "冷水入口温度",
  [ModuleChillerCheckType.ColdWaterOutletTemperature]: "冷水出口温度",
  [ModuleChillerCheckType.coldWaterInletPressure]: "冷水入口圧力",
  [ModuleChillerCheckType.coldWaterOutletPressure]: "冷水出口圧力",
  [ModuleChillerCheckType.HotWaterAmount]: "温水量",
  [ModuleChillerCheckType.HotWaterInletTemperature]: "温水入口温度",
  [ModuleChillerCheckType.HotWaterOutletTemperature]: "温水出口温度",
  [ModuleChillerCheckType.HotWaterInletPressure]: "温水入口圧力",
  [ModuleChillerCheckType.HotWaterOutletPressure]: "温水出口圧力",
  [ModuleChillerCheckType.OperatingVoltage]: "運転電圧・電流",
  [ModuleChillerCheckType.CompressorHydraulic]: "圧縮機 油 圧",
  [ModuleChillerCheckType.CompressorHighPressure]: "圧縮機 高 圧",
  [ModuleChillerCheckType.CompressorLowPressure]: "圧縮機 低 圧",
  [ModuleChillerCheckType.CoolingCapacity]: "冷房能力",
  [ModuleChillerCheckType.HeatingCapacity]: "暖房能力",
};

export enum ExecuteProcess {
  Before,
  InProgress,
  After,
}

export const FLEXIBLE_DUCT_DOCUMENT_EXECUTE_DATA = {
  [ExecuteProcess.Before]: [],
  [ExecuteProcess.InProgress]: [
    {
      title: "排煙ダクトの板厚は適切適切か",
      documentReference: "公-2.2.6",
      criteriaDecide:
        "450A以下:0.8mm 450を超え700A以下:1mm 700Aを超えるもの:1.2mm",
    },
    {
      title: "スパイラルダクトの接続方法は適切か",
      documentReference: "公-2.2.4.1",
      criteriaDecide: "差込,フランジ接合",
    },
    {
      title: "スパイラルダクトの継目外面はシーリングをしたか",
      documentReference: "公-2.2.4.1",
      criteriaDecide: "シールが施されていること",
    },
    {
      title: "スパイラルダクトの継目はダクト用テープで二重巻にしたか",
      documentReference: "公-2.2.4.1",
      criteriaDecide: "二重巻されていること",
    },
    {
      title: "横走りダクトの吊り間隔は適切か",
      documentReference: "公-2.2.4.2",
      criteriaDecide: "4m以下(スパイラル)\n3.64m以下(排煙)\n1m以下(フレキ)",
    },
    {
      title: "横走りダクトの振止支持は適切か",
      documentReference: "公-2.2.4.2",
      criteriaDecide: "12m以下\n 45 + 15",
    },
  ],
  [ExecuteProcess.After]: [],
};

export enum MERGE_MESSAGE_TYPE {
  DataInCells,
  InvalidSelected,
  MergeHeader,
  MergeLinkedTables,
  MergeSubCells,
}

export const MERGE_MESSAGE = {
  [MERGE_MESSAGE_TYPE.DataInCells]:
    "セルをマージすると、左上の値のみが保持され、他の値は破棄されます。",
  [MERGE_MESSAGE_TYPE.MergeLinkedTables]:
    "リンクされたテーブルのセルは結合できません。",
  [MERGE_MESSAGE_TYPE.InvalidSelected]: "結合できません。",
  [MERGE_MESSAGE_TYPE.MergeHeader]:
    "ヘッダーの結合はリンクされたテーブルにも反映します。続行してよろしいでしょうか。",
  [MERGE_MESSAGE_TYPE.MergeSubCells]: "サブセルを結合しますか?",
};

export const DEFAULT_ZOOM_PAGE_RATIO = 0.7;
export const DEFAULT_PAGE_RATIO = 1;

export const DEFAULT_SIZE_GRID = {
  width:
    PaperSize[PaperType.A4].numOfColumns * CellSizeSetting.MIN_WIDTH +
    TableDefaultStyle.DEFAULT_BORDER_SIZE,
  height:
    PaperSize[PaperType.A4].numOfRows * CellSizeSetting.MIN_HEIGHT +
    TableDefaultStyle.DEFAULT_BORDER_SIZE,
};

export enum REMOVE_MESSAGE_TYPE {
  InvalidRemove,
  RemoveInvalidColumn,
  RemoveInvalidRow,
  RemoveInDifferentRows,
  RemoveInDifferentColumns,
  RemoveInLinkedTable,
  RemoveRowInHeader,
}

export const REMOVE_MESSAGE = {
  [REMOVE_MESSAGE_TYPE.InvalidRemove]: "削除できません。",
  [REMOVE_MESSAGE_TYPE.RemoveInvalidColumn]: "この列は削除できません。",
  [REMOVE_MESSAGE_TYPE.RemoveInvalidRow]: "この行は削除できません。",
  [REMOVE_MESSAGE_TYPE.RemoveInDifferentRows]:
    "異なる行のセルを削除できません。",
  [REMOVE_MESSAGE_TYPE.RemoveInDifferentColumns]:
    "異なる列のセルを削除できません。",
  [REMOVE_MESSAGE_TYPE.RemoveInLinkedTable]:
    "リンクされたテーブルのセルを削除できません。",
  [REMOVE_MESSAGE_TYPE.RemoveRowInHeader]:
    "ヘッダー テーブルの行を削除できません。",
};

export enum SPLIT_IMPACT_TYPE {
  NotImpact,
  ImpactByColumn,
  ImpactByRow,
  ImpactBySplitCell,
}

export const DEFAULT_WIDTH_HEADER_TABLE = 55;

export const DEFAULT_PROJECT = {
  name: "デフォルトテンプレート",
  value: "-1",
};

export const EMPTY_OPTION = "未着手";

export const DEFAULT_OPTION_WEATHER = [
  {
    value: "",
    name: "選択してください",
  },
  {
    value: "晴れ",
    name: "晴れ",
  },
  {
    value: "雨",
    name: "雨",
  },
  {
    value: "曇り",
    name: "曇り",
  },
];

export const REGEX_INPUT_NUMBER = /^-?\d+(\.\d+)?$/;
export const REGEX_ROUNDING_NUMBER = /^-?\d*(?:\.\d+)?(?:\s+0)?$/;

export const DEFAULT_OFFSET_HEIGHT_POPOVER = 50;
export const DEFAULT_HEIGHT_FOOTER_DOCUMENT_MODAL = 63;
export const DEFAULT_HEIGHT_MENU_TASK_TYPE_LIST = 300;

export const MIN_ZOOM_VALUE = 50;
export const MAX_ZOOM_VALUE = 200;
export const STEP_ZOOM_VALUE = 10;

export const MESSAGE_AFTER_SAVE_TEMPLATE = {
  NOT_BLACKBOARD_BEFORE_UPDATE:
    "黒板なしの写真帳票から黒板ありの写真帳票へ変更した場合、BIM画面上、写真の黒板配置を行う必要があります。",
};
