import { Flex, FlexProps, TableRowProps } from "@chakra-ui/react";
import useComponent from "components/editor-builder/component-preview/hooks/useComponent";
import {
  BorderSizeEventComponent,
  CellProperty,
  DocumentTemplateType,
  TemplateComponentType,
} from "constants/enum";
import {
  CellType,
  RowType,
  TableStyleType,
  TemplateComponent,
} from "interfaces/models/component";
import {
  DEFAULT_BORDER_COLOR,
  DEFAULT_BORDER_COLOR_ACTIVE,
} from "pages/document/template-page/hooks";
import { useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { setComponentSelected, setSelectedCell } from "redux/documentSlice";
import { setScanComponents } from "redux/editorSlice";
import { getAllCells } from "utils/tableCell";
import { iUseTableComponentPreview } from "..";

enum BorderStyleByType {
  linkedTable = "linkedTable",
  linkedImage = "linkedImage",
}

const useTableComponentPreview = (props: iUseTableComponentPreview) => {
  const {
    isOnlyView = false,
    componentSelected,
    selectedCells,
    component,
    scanComponents,
    zoomRatio,
    currentTemplate,
  } = props;
  const [canEditCell, setCanEditCell] = useState(false);

  const dispatch = useDispatch();
  const { deleteComponent } = useComponent();

  const isSelectComponent = useMemo(() => {
    return (
      componentSelected.componentId === component.componentId ||
      !!scanComponents?.find((c) => c?.componentId === component.componentId)
    );
  }, [component.componentId, componentSelected, scanComponents]);

  const isSelectTableOnly: boolean = useMemo(() => {
    return !isOnlyView && isSelectComponent && selectedCells.length === 0;
  }, [isSelectComponent, selectedCells, isOnlyView]);

  const isTableSelected = isSelectComponent && selectedCells?.length === 0;

  const isSelectSingleCell = useCallback(
    (row: RowType, cell: CellType) => {
      return (
        selectedCells.length === 1 &&
        selectedCells[0].idRow === row.idRow &&
        selectedCells[0].cellId === cell.cellId
      );
    },
    [selectedCells]
  );

  const flexContainerProps: Partial<FlexProps> =
    useMemo((): Partial<FlexProps> => {
      if (isOnlyView) {
        return {} as any;
      }

      return {
        onKeyDown: (e) => {
          e.key === "Delete" &&
            isSelectTableOnly &&
            isOnlyView &&
            deleteComponent(component);
        },
      };
    }, [isOnlyView, component, isSelectTableOnly, deleteComponent]);

  const getTrProps = useCallback(
    (rowIndex: number): Partial<TableRowProps> => {
      if (isOnlyView) {
        return {} as any;
      }

      const isFilterPhoto =
        componentSelected.type === TemplateComponentType.FilterPhoto;
      const isLinkedImage =
        componentSelected.type === TemplateComponentType.LinkedImage;
      const getBorderStyleByType = (type: BorderStyleByType) => {
        let borderStyle = "none";
        switch (type) {
          case BorderStyleByType.linkedTable:
            borderStyle = rowIndex === 0 ? "solid" : "dashed";
            break;

          case BorderStyleByType.linkedImage:
            borderStyle = [0, 1].includes(rowIndex) ? "solid" : "dashed";

            break;

          default:
            break;
        }

        return borderStyle;
      };

      const getBorderWidthByType = (type: BorderStyleByType) => {
        let borderWidth = "0";

        switch (type) {
          case BorderStyleByType.linkedTable:
            borderWidth =
              rowIndex === 0
                ? "1.5px"
                : rowIndex === (componentSelected?.detail?.numOfRepeat || 0) - 1
                ? "0 1.5px 1.5px 1.5px"
                : "0 1.5px";

            break;

          case BorderStyleByType.linkedImage:
            if (
              (componentSelected.detail?.rows?.length || 0) - 1 ===
              rowIndex
            ) {
              borderWidth = "0px 1.5px 1.5px 1.5px";
            } else {
              borderWidth =
                rowIndex === 0
                  ? "1.5px 1.5px 0px 1.5px"
                  : rowIndex === 1
                  ? "0px 1.5px 1.5px 1.5px"
                  : rowIndex / 2 ===
                    (componentSelected?.detail?.numOfRepeat || 0)
                  ? "0 1.5px 1.5px 1.5px"
                  : "0 1.5px";
            }

            break;

          default:
            break;
        }

        return borderWidth;
      };

      let borderStyle = "none";
      let borderWidth = "0";
      if (
        isTableSelected &&
        (!!componentSelected.linkedHeaderId || isFilterPhoto)
      ) {
        borderStyle = getBorderStyleByType(BorderStyleByType.linkedTable);
        borderWidth = getBorderWidthByType(BorderStyleByType.linkedTable);
      }

      if (isTableSelected && isLinkedImage) {
        borderStyle = getBorderStyleByType(BorderStyleByType.linkedImage);
        borderWidth = getBorderWidthByType(BorderStyleByType.linkedImage);
      }

      return {
        borderStyle,
        borderWidth,
        borderColor: DEFAULT_BORDER_COLOR_ACTIVE,
      };
    },
    [isOnlyView, componentSelected, isTableSelected]
  );

  const listMapCellSelected = useMemo(() => {
    const mapId: { [key: string]: string } = {};
    (selectedCells || []).forEach((item) => {
      const key: string = item.cellId || "";
      mapId[key] = key;
    });

    return mapId;
  }, [selectedCells]);

  const getBorderColor = useCallback((css: TableStyleType) => {
    if (!css?.border) {
      return `${css?.backgroundColor}`;
    }

    if (css?.border) {
      const top = css?.borderTop
        ? css?.borderColor || DEFAULT_BORDER_COLOR
        : css?.backgroundColor || "#fff";
      const right = css?.borderRight
        ? css?.borderColor || DEFAULT_BORDER_COLOR
        : css?.backgroundColor || "#fff";
      const bottom = css?.borderBottom
        ? css?.borderColor || DEFAULT_BORDER_COLOR
        : css?.backgroundColor || "#fff";
      const left = css?.borderLeft
        ? css?.borderColor || DEFAULT_BORDER_COLOR
        : css?.backgroundColor || "#fff";

      return `${top} ${right} ${bottom} ${left}`;
    }

    return `${css?.borderColor}`;
  }, []);

  const renderEdgeToResize = useMemo(() => {
    if (isOnlyView) {
      return null;
    }

    const borderSize = BorderSizeEventComponent.SIZE * Number(zoomRatio);

    const handleClick = (e: any) => {
      e.preventDefault();
      e.stopPropagation();

      dispatch(setComponentSelected(component as TemplateComponent));
      selectedCells.length !== 0 && dispatch(setSelectedCell([]));
      scanComponents.length && dispatch(setScanComponents([]));
    };

    return (
      isSelectComponent || (
        <>
          <Flex
            position="absolute"
            h={`calc(100% - 30px)`}
            w={`${borderSize}px`}
            top="15px"
            left={`-${BorderSizeEventComponent.SIZE + 2}px`}
            zIndex={1}
            cursor="pointer"
            backgroundColor="transparent"
            onClick={handleClick}
          />
          <Flex
            position="absolute"
            h={`${borderSize}px`}
            w={`calc(100% - 30px)`}
            top={`-${BorderSizeEventComponent.SIZE + 2}px`}
            left="15px"
            zIndex={1}
            cursor="pointer"
            backgroundColor="transparent"
            onClick={handleClick}
          />
          <Flex
            position="absolute"
            h={`calc(100% - 30px)`}
            w={`${borderSize}px`}
            bottom="15px"
            right={`-${BorderSizeEventComponent.SIZE + 2}px`}
            zIndex={1}
            cursor="pointer"
            backgroundColor="transparent"
            onClick={handleClick}
          />
          <Flex
            position="absolute"
            h={`${borderSize}px`}
            w={`calc(100% - 30px)`}
            bottom={`-${BorderSizeEventComponent.SIZE + 1}px`}
            right="15px"
            zIndex={1}
            cursor="pointer"
            backgroundColor="transparent"
            onClick={handleClick}
          />
        </>
      )
    );
  }, [
    isOnlyView,
    component,
    dispatch,
    isSelectComponent,
    scanComponents,
    zoomRatio,
    selectedCells,
  ]);

  const addCellToSelectedCells = (cell: CellType) => {
    const newSelectedCells = [...selectedCells, cell];

    dispatch(setSelectedCell(newSelectedCells));

    return;
  };

  const removeCellFromSelectedCells = (cell: CellType) => {
    const newSelectedCells = selectedCells?.filter(
      (item) => item.cellId !== cell.cellId
    );

    dispatch(setSelectedCell(newSelectedCells));

    return;
  };

  const selectMultiCells = (cell: CellType) => {
    const isSelectedCellsIncludesCell = !selectedCells?.some(
      (item) => item.cellId === cell.cellId
    );

    if (isSelectedCellsIncludesCell) {
      addCellToSelectedCells(cell);

      return;
    }

    removeCellFromSelectedCells(cell);

    return;
  };

  const selectSingleCell = (cell: CellType) => {
    const allCells = getAllCells(component);

    const repeatedCells =
      (componentSelected.type === TemplateComponentType.TableHeader &&
        componentSelected.detail?.isRepeatTable) ||
      componentSelected.detail?.isRepeat
        ? allCells.filter((item) => {
            return (
              item.cellProperty &&
              ![
                CellProperty.LINKED_IMAGE_DEFAULT_TEXT,
                CellProperty.LINKED_IMAGE_LINE,
              ].includes(item.cellProperty as any) &&
              item.position?.idColumn === cell.position?.idColumn
            );
          })
        : [cell];

    dispatch(setSelectedCell(repeatedCells));
  };

  const handleSelectCell = (
    e: React.MouseEvent<HTMLElement>,
    cell: CellType
  ) => {
    e.preventDefault();
    e.stopPropagation();

    if (cell?.isRepeatedTable) {
      dispatch(setComponentSelected(component));
      dispatch(setSelectedCell([]));

      return;
    }

    if (scanComponents.length) {
      dispatch(setScanComponents([]));
    }

    // handle select component
    if (componentSelected?.componentId !== component.componentId) {
      dispatch(setComponentSelected(component as TemplateComponent));
    }

    if (
      !!component.linkedHeaderId &&
      currentTemplate?.documentType !== DocumentTemplateType?.SELF_INSPECTION &&
      Number(cell.idRow?.split("-").pop()) > 0 &&
      component?.detail?.isRepeat
    ) {
      dispatch(setSelectedCell([]));

      return;
    }

    if (!e.ctrlKey && !e.metaKey) {
      selectSingleCell(cell);

      return;
    }

    selectMultiCells(cell);
  };

  return {
    isTableSelected,
    isSelectTableOnly,
    flexContainerProps,
    listMapCellSelected,
    isSelectSingleCell,
    canEditCell,
    getTrProps,
    getBorderColor,
    renderEdgeToResize,
    handleSelectCell,
  };
};

export default useTableComponentPreview;
