import { useEffect, useRef, useState } from "react";
import { Box, BoxProps, Flex, useDisclosure } from "@chakra-ui/react";
import { SvgIcon } from "components/SvgIcon";
import { ChromePicker, ColorResult, RGBColor } from "react-color";
import useOnClickOutside from "hooks/useOnClickOutside";
import SelectTooltip from "components/ui/SelectTooltip";

interface Props {
  defaultColor: string | RGBColor;
  name?: string;
  tooltip?: string;
  onChangeColor?: (color: any, name?: string) => void;
}

function ColorPicker({
  defaultColor,
  name,
  tooltip = "",
  onChangeColor,
  ...props
}: Props & BoxProps) {
  const {
    isOpen: isShowColorPicker,
    onOpen: handleOpenColorPicker,
    onToggle: handleToggleColorPicker,
    onClose: handleCloseColorPicker,
  } = useDisclosure();

  const [color, setColor] = useState(defaultColor);
  const [rgb, setRgb] = useState(defaultColor);
  const boxRef = useRef<HTMLDivElement | null>(null);

  const handleChangeColor = (color: ColorResult) => {
    setColor(color.hex);
    setRgb(color.rgb);
    onChangeColor?.(color.hex, name);
  };

  useOnClickOutside(boxRef, handleCloseColorPicker);

  useEffect(() => {
    if (defaultColor !== color) {
      setColor(defaultColor);
      setRgb(defaultColor);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultColor]);

  return (
    <Flex
      h="48px"
      w="100px"
      borderRadius="4px"
      borderColor=""
      border="1px solid #A3A3A3"
      alignItems="center"
      position="relative"
      cursor="pointer"
      backgroundColor="#fff"
      ref={boxRef}
      {...props}
    >
      <SelectTooltip w="70%" h="4rem" label={tooltip}>
        <Flex
          h="100%"
          borderTopLeftRadius="3px"
          borderBottomLeftRadius="3px"
          borderRight="1px solid var(--primary-border-color)"
          backgroundColor={String(color)}
          onClick={handleOpenColorPicker}
        />
      </SelectTooltip>

      <Flex
        justifyItems="center"
        alignItems="center"
        height="100%"
        width="30%"
        onClick={handleToggleColorPicker}
      >
        <SvgIcon
          src="/img/icon-navigation-arrow_drop_down.svg"
          transition="0.4s"
          transform={isShowColorPicker ? "rotate(-90deg)" : "rotate(0deg)"}
        />
      </Flex>
      {isShowColorPicker && (
        <Box position="absolute" zIndex={2} left="calc(100% + 8px)" top="0px">
          <ChromePicker color={rgb} onChange={handleChangeColor} />
        </Box>
      )}
    </Flex>
  );
}

export default ColorPicker;
