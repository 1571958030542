import { Box, Image } from "@chakra-ui/react";
import CrossLine from "components/editor-builder/component-preview/TableComponentPreview/CrossLine";
import { withPresignedUrl } from "components/HOC/presignedUrl";
import InspectionImage from "components/ui/InspectionImage";
import { FileUploadInfo } from "interfaces/models";
import { BlackboardTemplate } from "interfaces/models/blackboardTemplate";
import { DocumentItem } from "interfaces/models/documentItem";
import { iBlackboardTemplateProps } from "interfaces/models/documentTemplate";

const ImagePresigned = withPresignedUrl(Image);
const InspectionImagePresigned = withPresignedUrl(InspectionImage);

interface Props {
  documentItem?: DocumentItem;
  blackboardTemplateDetail?: BlackboardTemplate;
  blackboardTemplateProps: iBlackboardTemplateProps;
}

const ImageWithBlackboard = ({
  documentItem,
  blackboardTemplateProps,
}: Props) => {
  const images: FileUploadInfo = documentItem?.images as any;
  const imageSrc = images?.src;
  const blackboardTemplateDetail = blackboardTemplateProps?.blackboardTemplate;

  return (
    <Box
      className="disalbe-br mceNonEditable"
      style={{
        height: "100%",
        width: "100%",
        margin: 0,
        padding: "0",
        overflow: "hidden",
      }}
    >
      {(documentItem?.isShowBlackboard ?? true) &&
      imageSrc &&
      !!blackboardTemplateDetail?.id ? (
        <InspectionImagePresigned
          src={imageSrc}
          positionBlackBoard={
            (documentItem as any)?.blackboardImagePosition?.length > 0
              ? (documentItem as any)?.blackboardImagePosition
              : []
          }
          blackBoard={(documentItem as any)?.blackBoard}
          styleImage={{
            maxWidth: "100%",
            height: "auto",
          }}
          blackboardTemplateDetail={blackboardTemplateDetail}
          blackboardTemplateProps={blackboardTemplateProps}
          isConvertToBase64={true}
        />
      ) : (
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          {imageSrc ? (
            <ImagePresigned
              isConvertToBase64={true}
              style={{
                maxHeight: "99%",
                maxWidth: "100%",
                objectFit: "contain",
              }}
              crossOrigin="anonymous"
              className="blackboard-image"
              src={imageSrc}
            />
          ) : (
            <CrossLine />
          )}
        </Box>
      )}
    </Box>
  );
};

export default ImageWithBlackboard;
