import { ComponentStyleConfig } from "@chakra-ui/react";

export const tableStyle: ComponentStyleConfig = {
  variants: {
    border: {
      table: {
        border: "1px solid #fff",
      },
      td: {
        color: "#fff",
        fontSize: "1.3rem",
        lineHeight: "2.5rem",
        padding: "3px 0.5rem",
        border: "1px solid #fff",
        pl: "1.5rem",
      },
    },
    borderLg: {
      table: {
        border: "1px solid #fff",
      },
      td: {
        color: "#fff",
        fontSize: "1.8rem",
        lineHeight: "2.4rem",
        padding: "0.5rem",
        border: "1px solid #fff",
        pl: "1.5rem",
        h: "4.8rem",
      },
    },
    document: {
      table: {
        border: "1px solid #D2D2D2",
      },
      thead: {
        border: "1px solid #D2D2D2",
        background: "#E2E2E3",
      },
      tbody: {
        "tr:nth-of-type(odd)": {
          bgColor: "var(--table-alternate-background)",
        },
      },
      th: {
        color: "#000000CC",
        fontSize: "1.4rem",
        lineHeight: "2rem",
        padding: "1.3rem 0.4rem",
        pl: "2rem",
        fontWeight: "medium",
      },
      td: {
        color: "#000000CC",
        fontSize: "1.4rem",
        lineHeight: "2rem",
        padding: "1.3rem 0.4rem",
        pl: "2rem",
      },
    },
    borderRadius: {
      table: {
        overflow: "hidden",
        borderStyle: "hidden",
      },
      td: {
        color: "#000000DE",
        fontSize: "1.4rem",
        lineHeight: "2.3rem",
        padding: "1.3rem",
        border: "1px solid #E2E2E3",
        bgColor: "#FAFAFB",
      },
    },
  },
};
