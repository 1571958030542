import { useBoolean, useDisclosure } from "@chakra-ui/react";
import { s3Api, taskApi } from "apiClient/v2";
import {
  InspectionItemType,
  MapInspectionItemColor,
  TASK_PRINT_MODE,
} from "constants/enum";
import { TypeOfFile } from "constants/file";
import { DEFAULT_TASK_MODAL_INFO, OPERATION } from "constants/task";
import { MessageType } from "constants/websocket";
import { TaskDTO } from "interfaces/dtos/taskDTO";
import { FileModel, FileUploadInfo } from "interfaces/models";
import { TaskAttached } from "interfaces/models/task";
import { WSMessage } from "interfaces/models/websocket";
import isEmpty from "lodash/isEmpty";
import { COMMENT_TYPE } from "models/commentLog";
import useSupportSyncDataOffline, {
  TypeHandleInitData,
} from "pages/forge-viewer/hooks/useSupportSyncDataOffline";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import {
  resetState,
  setCreateTask,
  setIsCreatingNewTask,
  setIsMoveTaskLabel,
} from "redux/forgeViewerSlice";
import { RootState } from "redux/store";
import {
  removeTask,
  setListenProcessCreateTask,
  setTask,
  setTaskSelected,
} from "redux/taskSlice";
import { routePath } from "routes/path";
import { getNetworkStatus } from "utils/common";
import { formatDate, now } from "utils/date";
import {
  compressionImageByFile,
  fileToBase64,
  getDateTimeOriginalExif,
  uploadFileToS3,
} from "utils/file";
import {
  getDbIdByExternalId,
  selectDbIds,
  setSelectionColor,
} from "utils/forge";
import { ClickInfo } from "utils/forge/extensions/click-extension";
import { selectLabel, updateLabel } from "utils/forge/extensions/custom-label";
import { HandleSelectDrawImageProps } from "../DrawToolModal";

interface Props {
  task?: TaskDTO | null;
  filterTasks: TaskDTO[];
  taskModalInfo: TaskDTO | undefined;
  setTaskModalInfo: React.Dispatch<React.SetStateAction<TaskDTO | undefined>>;

  imageCaptured: React.MutableRefObject<File | undefined>;
  onClose: () => void;
  addChatMessage: (
    content: string,
    typeKey: keyof typeof COMMENT_TYPE,
    files?: any,
    taskId?: string
  ) => void;
  clickInfo?: ClickInfo;
  setClickInfo: React.Dispatch<React.SetStateAction<ClickInfo | undefined>>;
  mapTaskType: { [key: string]: string };
  sendWebSocketMessage: (message: WSMessage) => void;
}

type SelectedImages = {
  images: FileModel[];
  confirmedImages: FileModel[];
};

const useTaskModal = ({
  task,
  filterTasks,
  taskModalInfo,
  imageCaptured,
  clickInfo,
  mapTaskType,
  setTaskModalInfo,
  addChatMessage,
  onClose,
  setClickInfo,
  sendWebSocketMessage,
}: Props) => {
  const { projectBimFileId, bimFileId, version } = useParams();
  const {
    levelSelected,
    familyInstances,
    isCreatingNewTask,
    isMoveTaskLabel,
    isFilter,
  } = useSelector((state: RootState) => state.forgeViewer);

  const dispatch = useDispatch();
  const [listImageSelected, setListImageSelected] = useState<SelectedImages>({
    images: [],
    confirmedImages: [],
  });
  const [attachesSelected, setAttachesSelected] = useState<FileModel[]>([]);
  const [indexFileUpload, setIndexFileUpload] = useState<number[]>([]);
  const {
    isOpen: isOpenConfirmModal,
    onOpen: onOpenConfirmModal,
    onClose: onCloseConfirmModal,
  } = useDisclosure();
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDeleteFile, setIsDeleteFile] = useState(false);
  const { currentUser } = useSelector((state: RootState) => state.user);
  const { taskTypes, tasks, taskSelected, isLoadingTask } = useSelector(
    (state: RootState) => state.task
  );
  const { documentCategories } = useSelector(
    (state: RootState) => state.document
  );
  const navigate = useNavigate();

  const lastTaskRef = useRef<TaskDTO | undefined | null>(null);
  const taskSavedRef = useRef<TaskDTO | undefined | null>(null);
  const [contentIndication, setContentIndication] = useState<{
    isOpen?: boolean;
    href?: string;
  }>({});
  const taskSelectedRef = useRef<TaskDTO>();
  const [operation] = useState(
    new URLSearchParams(window.location.search).get("operation")
  );

  const [isOpenCameraModal, setOpenCameraModal] = useBoolean();
  const [isOpenConfirmedCameraModal, setOpenConfirmedCameraModal] =
    useBoolean();

  const {
    fieldUpdateDataRef,
    initDataRef: initTaskDataRef,
    clearFieldUpdateData,
    handleUpdateFieldsChangeData,
    handleInitData: handleInitTaskData,
  } = useSupportSyncDataOffline<TaskDTO>();

  // close task modal when taskSelected not in filter tasks
  useEffect(() => {
    if (!isFilter || !taskSelected?.id) {
      return;
    }
    const filterTasksIds = filterTasks.map((item) => item.id);

    if (!filterTasksIds.includes(taskSelected?.id)) {
      onClose();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskSelected, isFilter, filterTasks]);

  useEffect(() => {
    taskSelectedRef.current = taskSelected;
  }, [taskSelected]);

  useEffect(() => {
    if (isEmpty(taskModalInfo) || !taskModalInfo?.position) {
      return;
    }

    // create task when open modal
    if (!taskModalInfo?.id) {
      (async () => {
        dispatch(setIsCreatingNewTask(true));

        if (imageCaptured.current) {
          const fileData = {
            [TypeOfFile.IMAGE]: [
              {
                file: imageCaptured.current,
                name: imageCaptured.current.name,
                type: TypeOfFile.IMAGE,
              } as FileModel,
            ],
          };
          imageCaptured.current = undefined;

          await saveModalData(taskModalInfo, undefined, fileData, "images");
        } else {
          await saveModalData(taskModalInfo);
        }

        if (operation === OPERATION.CreateTask) {
          dispatch(setCreateTask(false));
          dispatch(setIsMoveTaskLabel(true));
        }

        dispatch(setIsCreatingNewTask(false));
      })();
    }
    // eslint-disable-next-line
  }, [taskModalInfo?.id]);

  const getDataImageHasModifiedDataFile = (image: FileUploadInfo) => ({
    src: image.src,
    userUpload: image.userUpload,
    uploadTime: image.uploadTime,
    originSrc: image?.originSrc,
    lastModifiedDateFile: image.lastModifiedDateFile
      ? image.lastModifiedDateFile
      : undefined,
  });

  useEffect(() => {
    const isEqualId =
      taskSelected?.id && taskSelected?.id === taskModalInfo?.id;
    if (isLoadingTask) {
      clearFieldUpdateData();
      setTaskModalInfo(undefined as any);

      return;
    }

    if (isEqualId) {
      handleInitTaskData({
        data: taskSelected,
        type: TypeHandleInitData.TASK,
      });
    } else {
      clearFieldUpdateData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskSelected?.id, taskModalInfo?.id, isLoadingTask]);

  useEffect(() => {
    if (task?.id === taskSelected?.id) {
      setListImageSelected({
        images: taskSelected?.images
          ?.filter((image) => !!image)
          ?.map((image) =>
            getDataImageHasModifiedDataFile(image)
          ) as FileModel[],
        confirmedImages: taskSelected?.confirmedImages?.map((image) =>
          getDataImageHasModifiedDataFile(image)
        ) as FileModel[],
      });
      setListFiles(taskSelected?.attaches || []);
      lastTaskRef.current = task;
    }

    setTaskModalInfo(taskSelected || (DEFAULT_TASK_MODAL_INFO as any));
    // eslint-disable-next-line
  }, [taskSelected]);

  const documentCategoryNameOpts = useMemo(() => {
    const opts: { name: string; value: string }[] = [];
    if (taskModalInfo?.isDeleteDocItem || !taskModalInfo?.id) {
      return opts;
    }

    documentCategories.forEach((category) => {
      const validCategory =
        category.level === taskModalInfo.level &&
        category.templateId === taskModalInfo.templateId &&
        category.itemIds?.includes(taskModalInfo.documentItemId || "") &&
        (taskModalInfo?.documentCategoryId
          ? taskModalInfo?.documentCategoryId === category.id
          : true);

      if (validCategory) {
        opts.push({ name: category.title || "", value: category.id });
      }
    });

    return opts;
  }, [
    documentCategories,
    taskModalInfo?.id,
    taskModalInfo?.isDeleteDocItem,
    taskModalInfo?.templateId,
    taskModalInfo?.level,
    taskModalInfo?.documentCategoryId,
  ]);

  const setListFiles = useCallback(
    (attaches: TaskAttached[]) => {
      const newAttachesSelected: FileModel[] = [];
      if (attaches?.length) {
        attaches.forEach((imageObj) => {
          try {
            newAttachesSelected.push({
              src: imageObj.src,
              name: imageObj.name,
            } as FileModel);
          } catch {}
        });
      }
      setAttachesSelected(newAttachesSelected);
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (isCreatingNewTask) {
      setListImageSelected({
        images: [],
        confirmedImages: [],
      });
      setListFiles([]);
    }
  }, [clickInfo, isCreatingNewTask, setListFiles]);

  const onChangeTags = (tags: string[]) => {
    if (!taskModalInfo?.id) {
      return;
    }

    setTaskModalInfo({ ...taskModalInfo, tags });
    handleUpdateFieldsChangeData(["tags"]);
  };

  const handleSelectedDay = (field: string) => (day: Date | string) => {
    if (!taskModalInfo?.id) {
      return;
    }

    const newTaskModalInfo: TaskDTO = {
      ...taskModalInfo,
      [field]: day.toString(),
    };

    const keys: (keyof TaskDTO)[] = [field as keyof TaskDTO];

    if (
      field === "endDateScheduled" &&
      taskModalInfo?.status !== InspectionItemType.Confirmed &&
      !!day
    ) {
      newTaskModalInfo.status = InspectionItemType.Treated;
      keys.push("status");
    } else if (field === "confirmedDateTime" && !!day) {
      newTaskModalInfo.status = InspectionItemType.Confirmed;
      keys.push("status");
    }

    handleUpdateFieldsChangeData(keys);
    newTaskModalInfo?.dbId &&
      selectDbIds(newTaskModalInfo.dbId, {
        color:
          MapInspectionItemColor[
            (newTaskModalInfo.status ||
              InspectionItemType.Defect) as InspectionItemType
          ],
      });
    setTaskModalInfo(newTaskModalInfo);

    let type: any;

    switch (field) {
      case "deadline":
        type = "CHANGE_TARGET_DATE";
        break;
      case "confirmedDateTime":
        type = "CHANGE_CONFIRM_DATE";
        break;
      case "endDateScheduled":
        type = "CHANGE_COMPLETE_DATE";
        break;
    }

    saveModalData(
      newTaskModalInfo,
      () =>
        type &&
        addChatMessage(
          formatDate((newTaskModalInfo as any)?.[field], "YYYY/MM/DD"),
          type
        )
    );
  };

  const onChangeMemo = (field: string) => (event: any) => {
    if (!taskModalInfo?.id) {
      return;
    }

    handleUpdateFieldsChangeData([field as keyof TaskDTO]);
    setTaskModalInfo({ ...taskModalInfo, [field]: event.target.value });
  };

  const onChangeContentType = (value: string) => {
    if (!taskModalInfo?.id) {
      return;
    }

    const newTaskModal: TaskDTO = {
      ...taskModalInfo,
      taskTypeId: value,
    };
    handleUpdateFieldsChangeData(["taskTypeId"]);
    setTimeout(() => {
      if (newTaskModal.id === taskSelected?.id) {
        setTaskModalInfo(newTaskModal);
      }
      saveModalData(newTaskModal, () =>
        addChatMessage(value, "CHANGE_CONTENT_TYPE")
      );
    });
  };

  const onChangeIdentificationMode = (value: TASK_PRINT_MODE) => {
    if (!taskModalInfo?.id) {
      return;
    }

    const newTaskModalInfo: TaskDTO = {
      ...taskModalInfo,
      printMode: value,
    };

    handleUpdateFieldsChangeData(["printMode"]);
    setTaskModalInfo(newTaskModalInfo);
    saveModalData(newTaskModalInfo);
    addChatMessage(
      "",
      value === TASK_PRINT_MODE.PRINTABLE ? "PRINT_TARGET" : "UNPRINT_TARGET"
    );
  };

  const onChangeUserSingle = (
    field: keyof TaskDTO,
    user: any,
    chatMessage: keyof typeof COMMENT_TYPE
  ) => {
    if (!taskModalInfo?.id) {
      return;
    }

    const newTaskModal = {
      ...taskModalInfo,
      [field]: user?.value || "",
    };
    const keys: (keyof TaskDTO)[] = [field as keyof TaskDTO];

    if (field === "userConfirmed" && !!user) {
      keys.push("status");
      newTaskModal.status = InspectionItemType.Confirmed;
      newTaskModal?.dbId &&
        selectDbIds(newTaskModal.dbId, {
          color:
            MapInspectionItemColor[
              (newTaskModal.status ||
                InspectionItemType.Defect) as InspectionItemType
            ],
        });
    }
    handleUpdateFieldsChangeData(keys);
    setTaskModalInfo(newTaskModal);
    saveModalData(newTaskModal, () => {
      addChatMessage((newTaskModal?.[field] || "") as string, chatMessage);
    });
  };

  const onClickBtnSelectFile = (event: React.MouseEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    target.value = "";
  };

  const handleFileSelected = async ({
    fileList,
    type,
    field,
    originFileList,
    isNeedTimeOriginal = true,
  }: {
    fileList: FileList;
    originFileList?: FileModel[];
    type: TypeOfFile;
    field?: keyof SelectedImages;
    isNeedTimeOriginal?: boolean;
  }) => {
    if (!taskModalInfo?.id) {
      return;
    }

    const newListImageSelected = [
      ...(listImageSelected?.[field as keyof SelectedImages] || []),
    ];

    const newAttachesSelected = [...attachesSelected];
    for (let i = 0; i < fileList.length; i++) {
      const file = fileList[i] as File & { lastModifiedDate?: Date };
      let dateTimeOriginal;
      if (isNeedTimeOriginal) {
        dateTimeOriginal = await getDateTimeOriginalExif(file);
      }

      const imgString = await fileToBase64(file);
      const originFile = originFileList?.[i];

      const objectFile: FileModel = {
        src: imgString,
        name: file.name,
        originFile: originFile?.file,
        originName: originFile?.name,
        file,
        userUpload: currentUser?.id,
        originSrc: "",
        uploadTime: new Date(),
        lastModifiedDateFile: dateTimeOriginal
          ? dateTimeOriginal
          : file.lastModifiedDate
          ? file.lastModifiedDate
          : undefined,
      };
      if (type === TypeOfFile.IMAGE) {
        newListImageSelected.push(objectFile);
      } else if (type === TypeOfFile.ATTACH) {
        newAttachesSelected.push(objectFile);
      }
    }

    if (type === TypeOfFile.IMAGE) {
      setListImageSelected({
        ...listImageSelected,
        [field as keyof SelectedImages]: newListImageSelected,
      });
      const newTaskSelected = {
        ...taskModalInfo,
        [field as keyof SelectedImages]: newListImageSelected?.map(
          (i) => i?.src || (i as string)
        ),
        isLoadingUpdateTaskImage: true,
      };
      setIsDisableUploadImage(true, newTaskSelected);
    } else if (type === TypeOfFile.ATTACH) {
      setAttachesSelected(newAttachesSelected);
    }

    return {
      [type]:
        type === TypeOfFile.IMAGE ? newListImageSelected : newAttachesSelected,
    };
  };

  const handleSaveCaptureCamera =
    (field: keyof SelectedImages) =>
    async ({ file, originFile, isEdited }: HandleSelectDrawImageProps) => {
      if (!taskModalInfo?.id) {
        return;
      }

      handleUpdateFieldsChangeData([field as keyof TaskDTO]);
      let originImage: FileModel = undefined as any;
      if (isEdited) {
        originImage = {
          file: originFile,
          name: originFile.name,
        };
      }

      const imgString = await fileToBase64(file);
      const objectFile = {
        src: imgString,
        name: file.name,
        file,
        originSrc: "",
        userUpload: currentUser?.id,
        uploadTime: new Date(),
      };
      const fileIndex = listImageSelected?.[field]?.findIndex(
        (d) => d.name === file.name
      );

      if (fileIndex > -1) {
        listImageSelected[field][fileIndex] = objectFile;
        setListImageSelected({
          ...listImageSelected,
          [field]: [...listImageSelected[field]],
        });

        return;
      }

      setListImageSelected((list) => {
        return {
          ...list,
          [field]: [...(list[field] || []), objectFile],
        };
      });

      const fileData = await handleFileSelected({
        fileList: [file] as unknown as FileList,
        type: TypeOfFile.IMAGE,
        field,
        originFileList: [originImage],
      });

      await saveModalData(
        taskModalInfo,
        () =>
          addChatMessage(
            Object.keys(fileData || {}).length ? file.name : "",
            "CHANGE_IMAGE"
          ),
        fileData,
        field
      );
    };

  const onChangeFile =
    (field?: keyof SelectedImages) =>
    async (
      e: React.ChangeEvent<HTMLInputElement>,
      type: TypeOfFile,
      addCommentFunc?: () => void
    ) => {
      if (!taskModalInfo?.id) {
        return;
      }

      const fileList = e.target.files as FileList;
      if (!fileList.length) {
        return;
      }
      let isNeedTimeOriginal = true;
      if (type === TypeOfFile.ATTACH) {
        const indexArr: number[] = [];
        Array.from(fileList).forEach((_, index) => {
          indexArr.push(index + attachesSelected.length);
        });
        setIndexFileUpload(indexArr);
        isNeedTimeOriginal = false;
        handleUpdateFieldsChangeData(["attaches"]);
      } else {
        handleUpdateFieldsChangeData([field as keyof TaskDTO]);
      }
      const newFileData = await handleFileSelected({
        fileList,
        type,
        field,
        isNeedTimeOriginal,
      });

      saveModalData(taskModalInfo, addCommentFunc, newFileData, field);
    };

  const updateDataOnBlur = (
    type: keyof TaskDTO,
    addCommentFunc: () => void
  ) => {
    if (!taskModalInfo?.id) {
      return;
    }

    const isArray = Array.isArray(taskModalInfo[type]);
    if (
      !!lastTaskRef.current &&
      JSON.stringify(lastTaskRef.current[type] || (isArray ? [] : "")) !==
        JSON.stringify(taskModalInfo[type] || (isArray ? [] : ""))
    ) {
      saveModalData(taskModalInfo, addCommentFunc);
    }
  };

  const objectTypes = useMemo(() => {
    if (!taskModalInfo) {
      return [];
    }
    if (taskModalInfo.objectTypes) return taskModalInfo.objectTypes;
    if (taskModalInfo.externalId)
      return familyInstances[taskModalInfo.externalId]?.objectTypes.map(
        (item) => item.id
      );
  }, [familyInstances, taskModalInfo]);

  const saveModalData = useCallback(
    async (
      newTaskModalData: TaskDTO,
      addCommentFunc?: () => void,
      fileData?: {
        [TypeOfFile.IMAGE]?: FileModel[];
        [TypeOfFile.ATTACH]?: FileModel[];
      },
      field?: keyof SelectedImages,
      isCompress: boolean = true
    ) => {
      let taskId: string | number | undefined = newTaskModalData?.id;
      const indexId = newTaskModalData?.indexId || 0;
      let defaultTags: string[] | null = null;
      const isUpdateTask = !!taskId;

      if (!newTaskModalData?.position && !isUpdateTask) {
        return;
      }

      taskSavedRef.current = newTaskModalData;

      if (!taskId) {
        // set default tags
        const objectTypes =
          familyInstances[newTaskModalData?.externalId || 0]?.objectTypes || [];
        defaultTags = objectTypes.map((objectType) => objectType.name);
      }

      const listImage: FileUploadInfo[] = await Promise.all(
        (
          fileData?.[TypeOfFile.IMAGE] ||
          listImageSelected?.[field as keyof SelectedImages] ||
          []
        ).map(async (file) => {
          let originSrc = file?.originSrc;
          if (file.originFile) {
            originSrc = await uploadFileToS3(
              file.originFile,
              file.originName || "",
              "task"
            );
          }

          const isUploadFileToS3 =
            file.file &&
            !(
              file.src &&
              !!task?.[field as keyof SelectedImages]?.find(
                (image) => image.src === file
              )
            ) &&
            isCompress;
          if (isUploadFileToS3 && file.file) {
            const newFile = await compressionImageByFile({
              file: file.file,
              fileName: file.name,
            });

            const src = await uploadFileToS3(newFile, file.name || "", "task");

            return {
              src,
              originSrc,
              uploadTime: new Date(),
              userUpload: currentUser?.id || "",
              lastModifiedDateFile: file.lastModifiedDateFile
                ? file.lastModifiedDateFile
                : undefined,
            } as FileUploadInfo;
          }

          return {
            src: file.src || "",
            originSrc,
            uploadTime: file.uploadTime,
            userUpload: file.userUpload,
            lastModifiedDateFile: file.lastModifiedDateFile
              ? file.lastModifiedDateFile
              : undefined,
          };
        })
      ).then((img) => img.filter((i) => !!i.src));

      const attaches = await Promise.all(
        (fileData?.[TypeOfFile.ATTACH] || attachesSelected || [])?.map(
          async (file) => {
            const name = file.name;
            let src = file.src || "";
            const isExists = task?.attaches?.find(
              (att) => att.src === file.src
            );
            if (file.file && !isExists) {
              src = await uploadFileToS3(file.file, file.name || "", "task");
            }

            return { name, src };
          }
        )
      ).then((data) =>
        data.filter((item) => {
          const json = item;

          return !!json.src;
        })
      );

      let numberImage = (task?.numberImage || 0) - (task?.images?.length || 0);
      if (numberImage < 0) {
        numberImage = 0;
      }
      numberImage += listImage.length;

      const listUserSeen = [...(newTaskModalData?.userSeen || [])];
      if (!listUserSeen.includes(currentUser?.id!)) {
        listUserSeen.push(currentUser?.id!);
      }

      const now = new Date();
      const isOnline = getNetworkStatus();

      const bodyCreateTask: TaskDTO = {
        id: taskId,
        indexId,
        dbId: newTaskModalData?.dbId,
        externalId: newTaskModalData?.externalId,
        bimFileId: bimFileId || "",
        tags: newTaskModalData.tags || defaultTags || undefined,
        status: newTaskModalData?.status ?? InspectionItemType.Defect,
        priority: newTaskModalData.priority,
        position: newTaskModalData.position,
        objectTypes: objectTypes,
        deadline: newTaskModalData.deadline,
        hasComment: !!addCommentFunc || newTaskModalData.hasComment,
        level:
          newTaskModalData?.level ||
          (levelSelected?.guid ? levelSelected.label : ""),
        taskTypeId: newTaskModalData.taskTypeId || "",
        userCreated: isUpdateTask
          ? newTaskModalData?.userCreated || ""
          : newTaskModalData?.userCreated || currentUser?.id || "",
        userAssigned: newTaskModalData.userAssigned,
        memo: newTaskModalData.memo || "",
        attaches: attaches,
        numberImage,
        userSeen: listUserSeen,
        fov: newTaskModalData.fov || undefined,
        rotation: newTaskModalData.rotation || undefined,
        direction: newTaskModalData.direction || undefined,
        confirmedDateTime: newTaskModalData.confirmedDateTime,
        userConfirmed: newTaskModalData.userConfirmed,
        userTreated: newTaskModalData.userTreated,
        treatedComment: newTaskModalData.treatedComment,
        uranusUrl:
          newTaskModalData.uranusUrl || clickInfo?.forgeData?.uranus_url,
        uranusStepId:
          newTaskModalData.uranusStepId || clickInfo?.forgeData?.uranusStepId,
        endDateScheduled: newTaskModalData?.endDateScheduled,
        confirmedMemo: newTaskModalData?.confirmedMemo || "",
        images: field === "images" ? listImage : task?.images,
        confirmedImages:
          field === "confirmedImages" ? listImage : task?.confirmedImages,
        partnerCompany: newTaskModalData?.partnerCompany,
        printMode: newTaskModalData.printMode,
        documentItemId: newTaskModalData.documentItemId,
        updatedAt: now,
        createdAt: newTaskModalData.createdAt || now,
        documentCategoryId: newTaskModalData?.documentCategoryId,
        templateId: newTaskModalData?.templateId,
        isDeleteDocItem: newTaskModalData?.isDeleteDocItem,
      };

      const bodyUpdate: TaskDTO = {
        id: newTaskModalData.id,
        updatedAt: now,
        documentItemId: bodyCreateTask.documentItemId,
      } as any;
      if (!isOnline) {
        bodyUpdate.initData = initTaskDataRef.current?.initData || ({} as any);
      }

      if (isUpdateTask) {
        fieldUpdateDataRef.current.forEach((key) => {
          bodyUpdate[key] = bodyCreateTask[key] as never;
        });
      }

      let { data: newTask } = isUpdateTask
        ? await taskApi.updateTask(bodyUpdate)
        : await taskApi.createTask(bodyCreateTask);

      taskId = newTask?.id;

      newTask = { ...bodyCreateTask, ...newTask };
      if (isOnline) {
        handleInitTaskData({
          data: newTask,
          type: TypeHandleInitData.TASK,
        });
      }

      clearFieldUpdateData();

      if (newTask) {
        updateLabel(newTaskModalData.id, {
          id: newTaskModalData.id,
          position: newTaskModalData.position,
          title: mapTaskType?.[newTask?.taskTypeId || ""] || "-",
          indexId: newTaskModalData.indexId,
          showImage: Number(newTask?.images?.length) > 0,
          status: newTask?.status,
          externalId: newTaskModalData.externalId,
        });
        setSelectionColor({
          color:
            MapInspectionItemColor[
              (newTask?.status ||
                InspectionItemType.Defect) as InspectionItemType
            ],
          shouldRender: true,
        });
        const currentSelectedId = taskSelectedRef.current?.id;
        newTask.dbId = getDbIdByExternalId(newTask.externalId);
        if (!taskId) {
          addChatMessage(newTask?.id, "CREATE_TASK", [], newTask?.id);
        }

        if (newTask.id === currentSelectedId) {
          if (
            taskId &&
            JSON.stringify(attaches) !== JSON.stringify(newTask.attaches)
          ) {
            setListFiles(attaches);
          }
        }

        // check has  new file upload files
        lastTaskRef.current = newTask;

        // update list task
        const tasksToUpdate = [...tasks];
        let isExist = false;
        for (const index in tasksToUpdate) {
          if (tasksToUpdate[index].id === newTask.id) {
            tasksToUpdate[index] = newTask;
            isExist = true;
            break;
          }
        }

        if (!isExist) {
          tasksToUpdate.push(newTask);
        }

        sendWebSocketMessage({
          type: MessageType.UPDATE_TASK,
          data: { id: newTask.id, level: newTask.level },
        });
        dispatch(setListenProcessCreateTask(newTask));
        if (!isUpdateTask) {
          setTaskModalInfo(newTask);
        }

        dispatch(setListenProcessCreateTask(newTask));
        setTaskModalInfo(newTask);

        if (!isUpdateTask || currentSelectedId === newTask.id) {
          dispatch(setTaskSelected(newTask));
          selectLabel([newTask.id]);
        }
      }
      addCommentFunc?.();

      if (fileData?.[TypeOfFile.IMAGE]) {
        const newTaskSelected = {
          ...newTask,
          isLoadingUpdateTaskImage: false,
        };

        if (
          taskSelectedRef.current?.id &&
          taskSelectedRef.current?.id === newTaskSelected?.id
        ) {
          dispatch(setTaskSelected(newTaskSelected));
        }

        dispatch(setTask(newTaskSelected));
      }
      if (fileData?.[TypeOfFile.ATTACH]) setIndexFileUpload([]);

      taskSavedRef.current = null;
      setClickInfo(undefined);

      return task;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      familyInstances,
      levelSelected?.guid,
      levelSelected.label,
      listImageSelected,
      objectTypes,
      tasks,
      attachesSelected,
      bimFileId,
      currentUser?.id,
      clickInfo?.forgeData?.uranusStepId,
      clickInfo?.forgeData?.uranus_url,
      mapTaskType,
      task,
      dispatch,
      handleInitTaskData,
      addChatMessage,
      setClickInfo,
      setListFiles,
      setTaskModalInfo,
    ]
  );

  const handleSaveDrawImage =
    (field: keyof SelectedImages) => async (newListImage: FileModel[]) => {
      if (!taskModalInfo?.id) {
        return;
      }

      setListImageSelected({
        ...listImageSelected,
        [field as keyof SelectedImages]: newListImage,
      });
      handleUpdateFieldsChangeData([field as keyof TaskDTO]);

      await saveModalData(
        taskModalInfo,
        undefined,
        {
          [TypeOfFile.IMAGE]: newListImage,
        },
        field,
        false
      );

      addChatMessage(
        newListImage.length ? JSON.stringify(newListImage) : "",
        "CHANGE_POINTED_OUT_PHOTO"
      );
    };

  const onDeleteImage = async (
    index: number,
    addCommentFunc: () => void,
    field?: keyof SelectedImages
  ) => {
    if (!taskModalInfo?.id) {
      return;
    }

    handleUpdateFieldsChangeData([field as keyof TaskDTO]);
    const newsImages = [...listImageSelected[field as keyof SelectedImages]];
    const srcImage = newsImages?.[index]?.src;
    const srcOriginImage = newsImages?.[index]?.originSrc;
    const pathDeletes = [];
    if (srcImage) {
      pathDeletes.push(new URL(srcImage).pathname.substring(1));
    }

    if (srcOriginImage) {
      pathDeletes.push(new URL(srcOriginImage).pathname.substring(1));
    }

    newsImages.splice(index, 1);
    setIsDisableUploadImage(true, {
      ...taskModalInfo,
      [field as keyof SelectedImages]: newsImages?.map(
        (i) => i?.src || (i as string)
      ),
      isLoadingUpdateTaskImage: true,
    });
    setListImageSelected({
      ...listImageSelected,
      [field as keyof SelectedImages]: newsImages,
    });

    s3Api.deleteFiles(pathDeletes);

    saveModalData(
      taskModalInfo,
      addCommentFunc,
      {
        [TypeOfFile.IMAGE]: newsImages,
      },
      field
    );
  };

  const onDeleteTask = async () => {
    setIsDeleting(true);

    if (task?.id) {
      await taskApi.deleteTaskList([task.id]).then(() => {
        sendWebSocketMessage({
          type: MessageType.DELETE_TASK,
          data: { id: task.id, level: task.level },
        });
        dispatch(removeTask(task));
        dispatch(resetState());
        onClose();
      });
    }

    setIsDeleting(false);
  };

  const onDeleteAttachFile = async (index: number) => {
    if (!taskModalInfo?.id) {
      return;
    }

    setIsDeleteFile(true);
    const newsAttachs = [...attachesSelected];
    newsAttachs.splice(index, 1);
    setAttachesSelected(newsAttachs);
    handleUpdateFieldsChangeData(["attaches"]);
    await saveModalData(
      taskModalInfo,
      () => addChatMessage("", "DELETE_ATTACH"),
      {
        [TypeOfFile.ATTACH]: newsAttachs,
      }
    );
    setIsDeleteFile(false);
  };

  const changeStatusTask = useCallback(
    async (statusChange: string) => {
      if (!taskModalInfo?.id) {
        return;
      }

      const bodyUpdate: Partial<TaskDTO> = {
        status: statusChange,
        confirmedDateTime:
          statusChange === InspectionItemType.Confirmed &&
          !taskModalInfo?.confirmedDateTime
            ? now()
            : taskModalInfo?.confirmedDateTime,
        userConfirmed:
          statusChange === InspectionItemType.Confirmed &&
          !taskModalInfo?.userConfirmed
            ? currentUser?.id
            : taskModalInfo?.userConfirmed,
        userTreated:
          statusChange === InspectionItemType.Treated
            ? currentUser?.id
            : statusChange === InspectionItemType.Defect
            ? undefined
            : taskModalInfo.userTreated,
        treatedComment:
          statusChange === InspectionItemType.Defect
            ? undefined
            : taskModalInfo.treatedComment,
        endDateScheduled:
          statusChange === InspectionItemType.Treated &&
          !taskModalInfo?.endDateScheduled
            ? now()
            : taskModalInfo?.endDateScheduled,
      };
      const newTaskModalInfo = {
        ...(taskSavedRef.current || lastTaskRef.current || taskModalInfo),
        ...bodyUpdate,
      } as TaskDTO;

      setSelectionColor({
        color:
          MapInspectionItemColor[
            (newTaskModalInfo.status || "") as InspectionItemType
          ],
        shouldRender: true,
      });
      setTaskModalInfo(newTaskModalInfo);
      updateLabel(newTaskModalInfo.id, {
        id: newTaskModalInfo.id,
        position: newTaskModalInfo.position,
        title: mapTaskType[newTaskModalInfo.taskTypeId || ""] || "-",
        indexId: newTaskModalInfo.indexId,
        showImage: Number(newTaskModalInfo.images?.length) > 0,
        status: newTaskModalInfo.status,
        externalId: newTaskModalInfo.externalId,
      });
      dispatch(setTask(newTaskModalInfo));
      newTaskModalInfo?.dbId &&
        selectDbIds(newTaskModalInfo.dbId, {
          color:
            MapInspectionItemColor[
              (newTaskModalInfo.status ||
                InspectionItemType.Defect) as InspectionItemType
            ],
        });

      const { data: newTask } = await taskApi.updateTask({
        id: newTaskModalInfo.id,
        ...bodyUpdate,
      } as TaskDTO);
      if (newTask) {
        sendWebSocketMessage({
          type: MessageType.UPDATE_TASK,
          data: { id: newTaskModalInfo?.id, level: newTaskModalInfo.level },
        });
        addChatMessage(statusChange, "CHANGE_STATUS");
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      addChatMessage,
      currentUser?.id,
      dispatch,
      mapTaskType,
      setTaskModalInfo,
      taskModalInfo,
    ]
  );

  const setIsDisableUploadImage = useCallback(
    (loading: boolean, task?: TaskDTO) => {
      if (!taskModalInfo?.id) {
        return;
      }

      const images = (task || taskModalInfo).images;
      const confirmedImages = (task || taskModalInfo).confirmedImages;
      const newTaskSelected = {
        ...(task || taskModalInfo),
        isLoadingUpdateTaskImage: loading,
      };

      setTaskModalInfo((prev) => ({
        ...(prev || ({} as TaskDTO)),
        images,
        confirmedImages,
      }));
      dispatch(setTaskSelected(newTaskSelected));
      dispatch(setTask(newTaskSelected));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [taskModalInfo]
  );

  const isExistImages = useMemo(
    () => listImageSelected?.images?.length,
    [listImageSelected]
  );
  const isExistConfirmedImages = useMemo(
    () => listImageSelected?.confirmedImages?.length,
    [listImageSelected]
  );

  const handleChangePartnerCompanies = (id: string | string[]) => {
    if (!taskModalInfo?.id) {
      return;
    }

    const newTaskModal = {
      ...taskModalInfo,
      partnerCompany: id as string,
    };
    handleUpdateFieldsChangeData(["partnerCompany"]);
    setTaskModalInfo(newTaskModal);
    saveModalData(newTaskModal, () => {
      addChatMessage(id.length ? JSON.stringify(id) : "", "CHANGE_PARTNERS");
    });
  };

  const navigateDocumentItemModal = useCallback(
    (documentCategoryId: string) => {
      if (
        !projectBimFileId ||
        !bimFileId ||
        !version ||
        !taskModalInfo?.id ||
        !taskModalInfo.documentItemId
      ) {
        return;
      }

      let url = generatePath(routePath.ForgeViewer, {
        projectBimFileId,
        bimFileId: encodeURIComponent(bimFileId),
        version,
      });
      const data = {
        documentItemId: taskModalInfo.documentItemId,
        documentCategoryId,
      };
      const query = [
        `operation=${OPERATION.navigateDocumentItemModal}`,
        `data=${JSON.stringify(data)}`,
      ].join("&");
      url = `${url}?${query}`;

      navigate(url);
    },
    [
      projectBimFileId,
      bimFileId,
      version,
      taskModalInfo?.id,
      taskModalInfo?.documentItemId,
      navigate,
    ]
  );

  return {
    isMoveTaskLabel,
    user: currentUser,
    taskModalInfo,
    contentIndication,
    listImageSelected,
    attachesSelected,
    isOpenCameraModal,
    setOpenCameraModal,
    isOpenConfirmModal,
    isDeleting,
    handleSaveDrawImage,
    onOpenConfirmModal,
    onCloseConfirmModal,
    onDeleteTask,
    onChangeTags,
    handleSelectedDay,
    updateDataOnBlur,
    setContentIndication,
    onChangeMemo,
    onClickBtnSelectFile,
    onChangeFile,
    onDeleteImage,
    onDeleteAttachFile,
    saveModalData,
    handleSaveCaptureCamera,
    taskTypes,
    isDisableUploadImage: taskSelected?.isLoadingUpdateTaskImage,
    onChangeContentType,
    onChangeUserSingle,
    isDeleteFile,
    indexFileUpload,
    taskSelected,
    isCreatingNewTask,
    taskSavedRef,
    changeStatusTask,
    setIsDisableUploadImage,
    isOpenConfirmedCameraModal,
    setOpenConfirmedCameraModal,
    isExistImages,
    isExistConfirmedImages,
    handleChangePartnerCompanies,
    onChangeIdentificationMode,
    navigateDocumentItemModal,
    documentCategoryNameOpts,
  };
};

export default useTaskModal;
