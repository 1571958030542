import { useRef, forwardRef, useImperativeHandle } from "react";
import { Box } from "@chakra-ui/react";
import Cropper, { ReactCropperProps, ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";

export interface ImageCropperProps extends ReactCropperProps {
  image: string;
}

export interface ImageCropperRefType {
  getCroppedData: () => HTMLCanvasElement | undefined;
}

const ImageCropper = forwardRef<ImageCropperRefType, ImageCropperProps>(
  function ImageCropperImpl({ image, ...cropperProps }, ref) {
    const cropperRef = useRef<ReactCropperElement | null>(null);

    useImperativeHandle(
      ref,
      () => ({
        getCroppedData() {
          return cropperRef.current?.cropper.getCroppedCanvas();
        },
      }),
      []
    );

    return (
      <Box position="absolute" inset={0}>
        <Cropper
          ref={cropperRef}
          src={image}
          style={{ height: "100%", width: "100%" }}
          zoomTo={1}
          viewMode={1}
          background={false}
          responsive
          checkOrientation={false}
          guides
          {...cropperProps}
        />
      </Box>
    );
  }
);

export default ImageCropper;
