import {
  documentApi,
  documentCategoryApi,
  documentItemApi,
  projectApi,
  projectBimFileApi,
} from "apiClient/v2";
import {
  DEFAULT_BLACKBOARD_CONTENT_SIZE,
  listConstruction,
  listConstructionAddress,
  listOtherPartnerCompanies,
} from "constants/blackBoardTemplate";
import {
  DATE_JAPAN_FORMAT as DATE_JAPANESE_FORMAT,
  YYYY_MM_DD_SLASH,
} from "constants/date";
import { DEFAULT_GRID_SIZE } from "constants/documentTemplate";
import {
  Axis,
  DocumentCategoryStatusPriority,
  DocumentCategoryStatusType,
  DocumentTemplateType,
  MapDocumentCategoryStatusType,
  MapDocumentCategoryStatusTypeColor,
  MapDocumentCategoryStatusTypeColorText,
  Priority,
  TemplateComponentType,
} from "constants/enum";
import { DocumentCategoryDTO } from "interfaces/dtos/documentCategoryDTO";
import {
  DocumentItemDTO,
  DocumentSubItemDTO,
} from "interfaces/dtos/documentItemDTO";
import { FamilyInstanceDTO } from "interfaces/dtos/familyInstance";
import { Level, Sheet } from "interfaces/models";
import { NeptuneArea } from "interfaces/models/area";
import { Blackboard } from "interfaces/models/blackboard";
import {
  CellType,
  RowType,
  TemplateComponent,
} from "interfaces/models/component";
import { DataGenerate, Document } from "interfaces/models/document";
import { DocumentSubCategory } from "interfaces/models/documentCategory";
import { DocumentItem } from "interfaces/models/documentItem";
import {
  DocumentKeyNote,
  Keynote,
  KeynoteImageData,
} from "interfaces/models/documentKeynote";
import { DocumentTemplate } from "interfaces/models/documentTemplate";
import { PartnerCompany } from "interfaces/models/partnerCompany";
import { SizePosition } from "interfaces/models/rnd";
import { User, UserSetting } from "interfaces/models/user";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import { Position } from "react-rnd";
import { setComponents, setComponentSelected } from "redux/documentSlice";
import store from "redux/store";
import { removeEmptyProp } from "utils/common";
import { formatDate, formatStringDate } from "utils/date";
import { sortNearestComponentPosition } from "utils/document";
import { mapItemAreaIds } from "utils/documentItem";
import { getPreSignUrl } from "utils/file";
import { checkIsImageExists } from "utils/image";

interface CheckKeynoteValidProps {
  documentCategory: DocumentCategoryDTO;
  documentKeynote: DocumentKeyNote;
  guid?: string;
}

interface OnDispatchComponentsProps {
  newData: TemplateComponent;
  components: TemplateComponent[];
}

interface CheckHasOverlapProps {
  target: TemplateComponent;
  position: Position;
  size: { width: number; height: number };
  axis?: Axis.VERTICAL | Axis.HORIZONTAL;
  components: TemplateComponent[];
}

interface CheckIsOutOfLimitProps {
  position: { x: number; y: number };
  size: { width: number; height: number };
  isBlackboardTemplate?: boolean;
}

export const getDocumentDataByBimFileId = async (
  bimFileId: string,
  userAssigned: string = ""
) => {
  let documentCategories = await documentCategoryApi.handleGetCategoryList({
    bimFileId,
  });
  documentCategories = documentCategories.filter(
    (documentCategory) =>
      !(
        documentCategory.documentType ===
        DocumentTemplateType.COMMISSIONING_TABLE
      )
  );

  const documentItems = await documentItemApi.handleGetItemList({
    bimFileId,
    userAssigned,
  });

  return { documentCategories, documentItems };
};

export const processUpdateCurrentDocument = async (
  documentCategorySelected: DocumentCategoryDTO,
  bimFileId: string,
  currentUser?: User,
  documentItemSelected?: DocumentItemDTO
): Promise<{
  document: Document | null;
  isDiffTemplateComponent: boolean;
  templateDetail: DocumentTemplate;
}> => {
  const isModuleChiller =
    documentCategorySelected?.documentType ===
    DocumentTemplateType.EQUIPMENT_DATA_SHEET;
  const isDocumentCategoryModuleChiller =
    isModuleChiller && !documentItemSelected;
  const documentCategoryId = documentCategorySelected?.id || "";
  let document = (
    await documentApi.handleGetDocumentList({
      documentCategoryIds: [documentCategoryId],
    })
  )?.[0];
  let isDiffTemplateComponent = false;

  const { dataGenerate, templateDetail } = await createDataGenerateForDocument(
    documentCategorySelected,
    documentItemSelected
  );

  if (!document) {
    const { data } = await documentApi.createDocument({
      bimFileId,
      documentCategoryId: documentCategoryId,
      title: documentCategorySelected?.title || "",
      dataGenerate: dataGenerate,
      createdBy: currentUser?.id || "",
      createdAt: new Date(),
      updatedAt: new Date(),
    } as any);
    document = data;
  }

  removeEmptyProp(dataGenerate);
  const currentDataGenerate: DataGenerate =
    (document?.dataGenerate as DataGenerate) || {};
  removeEmptyProp(currentDataGenerate);
  const isDiffComponents =
    JSON.stringify(dataGenerate?.components || {}) !==
    JSON.stringify(currentDataGenerate?.components || {});
  const isDiffDocumentCategory =
    dataGenerate.updatedDateTimeDocCategory !==
    currentDataGenerate.updatedDateTimeDocCategory;
  const isDiffDocumentItems =
    JSON.stringify((dataGenerate?.updatedDateTimeDocItems || []).join(",")) !==
      JSON.stringify(
        (currentDataGenerate?.updatedDateTimeDocItems || []).join(",")
      ) ||
    dataGenerate?.updatedDateTimeDocItems?.length !==
      currentDataGenerate?.updatedDateTimeDocItems?.length;

  if (isDiffDocumentCategory || isDiffDocumentItems || isDiffComponents) {
    isDiffTemplateComponent = isDiffComponents && !!templateDetail?.id;

    if (!!document?.id && !!document?.title) {
      const { data } = await documentApi.updateDocument({
        ...document,
        dataGenerate: dataGenerate,
        title: documentCategorySelected?.title || "",
        updatedAt: new Date(),
        content: null as any,
      });
      document = data;
    }

    // Create document for documentItem belonging to Module chiller
    if (isDocumentCategoryModuleChiller) {
      await Promise.all(
        (documentCategorySelected?.documentItems || [])?.map((documentItem) => {
          return processUpdateCurrentDocument(
            documentCategorySelected,
            bimFileId!,
            currentUser!,
            documentItem
          );
        })
      );
    }
  }

  return { document, isDiffTemplateComponent, templateDetail };
};

export const checkIsIntancesDiffKeynotes = (
  intances: DocumentItem[],
  keynotes: Keynote[]
) => {
  const intanceStatusStr = (intances || [])
    ?.map((item) => item?.status || "")
    ?.join("");
  const keynoteStatusStr = (keynotes || [])
    ?.map((item) => item?.status || "")
    ?.join("");

  return (
    intanceStatusStr.valueOf() !== keynoteStatusStr.valueOf() ||
    intances?.length !== keynotes?.length
  );
};

export const getSheets = async ({
  documentCategorySelected,
}: {
  documentCategorySelected?: DocumentCategoryDTO;
}): Promise<Sheet[]> => {
  const state = store.getState();
  let dataProjectDetail = state.project.dataProjectDetail;
  if (!dataProjectDetail) {
    dataProjectDetail = await projectBimFileApi
      .getProject(state?.project?.projectDetail.id)
      .then((res) => res?.data);
  }
  const levelData = dataProjectDetail?.levelData || {};
  const _documentCategorySelected =
    documentCategorySelected || state.document.documentCategorySelected;
  const level = _documentCategorySelected?.level as string;
  const sheetData = levelData?.[level || ""]?.sheets || [];

  return sheetData;
};

export const getUniqDocumentItemStatusFromSubCategory = (
  subCategory: DocumentSubCategory
) => {
  const arrStatus = (subCategory?.documentItems || [])?.map(
    (item) => item?.status || DocumentCategoryStatusType.NotStarted
  );
  const arrStatusPriority = Object.values(DocumentCategoryStatusPriority);
  const newArrStatus = arrStatus.map((status) =>
    arrStatusPriority.findIndex((i) => i === status)
  );
  const uniqStatus = Math.min(...newArrStatus);

  return DocumentCategoryStatusPriority[
    uniqStatus as keyof typeof DocumentCategoryStatusPriority
  ];
};

export const transformKeynoteIndex = ({
  documentItems,
  documentSubItem,
}: {
  documentItems: DocumentItemDTO[];
  documentSubItem: DocumentSubItemDTO;
}) => {
  const documentItem = documentItems?.find(
    (item) => item.id === documentSubItem.itemId
  );

  let keyNoteIndex = ((documentItems?.find(
    (item) => item?.id === documentSubItem.itemId
  )?.displayOrder || 0) + 1) as any;

  const itemIndex =
    Number(
      documentItem?.subItems?.findIndex((i) => i.id === documentSubItem?.id) ||
        0
    ) + 1;

  keyNoteIndex = `${keyNoteIndex}-${itemIndex}`;

  return keyNoteIndex;
};

export const getDocumentItemHasImage = (
  documentCategorySelected: DocumentCategoryDTO,
  dataBlackboards: Blackboard[] = []
) => {
  const documentItemsHaveImage: DocumentSubItemDTO[] = [];
  const documentItemBlackboard: DocumentSubItemDTO[] = [];
  const documentItems = documentCategorySelected.documentItems?.slice() || [];
  const subItems = documentItems?.map((item) => item.subItems || []).flat(1);

  subItems.forEach((subItem) => {
    let dataBlackboard;
    if (subItem.blackboardId) {
      dataBlackboard = (dataBlackboards || [])?.find(
        (blackboard) => blackboard.id === subItem.blackboardId
      );
    }
    if (!!(subItem?.images as any)?.src) {
      const keyNoteIndex = transformKeynoteIndex({
        documentItems: documentItems,
        documentSubItem: subItem,
      });
      const newPos: SizePosition | undefined =
        subItem?.blackboardImagePosition as any;

      const item = {
        documentItem: subItem,
        ...subItem,
        blackBoardInfo: dataBlackboard
          ? {
              ...subItem,
              id: subItem.id!,
              blackBoard: dataBlackboard,
              title: subItem?.title ?? "",
              image: "",
              takeImageDates:
                subItem?.takeImageDates?.[0] && !!subItem.images
                  ? formatDate(subItem?.takeImageDates?.[0], YYYY_MM_DD_SLASH)
                  : "",
              shootingTime: formatDate(
                dataBlackboard?.shootingTime,
                DATE_JAPANESE_FORMAT
              ),
              constructionNameText: dataBlackboard?.constructionNameText || "",
              constructionAddressText:
                dataBlackboard?.constructionAddressText || "",
              builder: `${dataBlackboard?.builderFirstText || ""} ${
                !!dataBlackboard?.builderFirstText &&
                !!dataBlackboard?.builderSecondText
                  ? "~"
                  : ""
              } ${dataBlackboard?.builderSecondText || ""}`,
              blackboardImagePosition: newPos
                ? [
                    newPos?.x,
                    newPos?.y,
                    newPos?.width,
                    newPos?.imgWidth,
                    newPos?.height,
                    newPos?.imgHeight,
                  ]
                : [],
              commentManageExecute: dataBlackboard?.commentManageExecute,
              keyNoteIndex,
            }
          : (undefined as any),
      };
      if (!!(subItem?.images as any)?.src) {
        documentItemsHaveImage.push({
          ...item,
          blackBoardInfo: item.blackBoardInfo
            ? {
                ...item.blackBoardInfo,
                image: subItem?.images,
              }
            : undefined,
        });
      } else {
        documentItemsHaveImage.push({ ...item });
      }
      documentItemBlackboard.push(subItem);
    }
  });

  return {
    documentItemBlackboard,
    documentItemsHaveImage,
    documentItems,
  };
};

export const preprocessSaveEditor = async ({
  mapDocumentItemStatus = {},
  mapDocumentItemCheckbox = {},
  documentCategorySelected,
  isSaveData = true,
  familyInstances,
  areas,
}: {
  mapDocumentItemStatus?: { [key: string]: string | undefined };
  mapDocumentItemCheckbox?: { [key: string]: { [key: string]: string } };
  documentCategorySelected: DocumentCategoryDTO | undefined;
  isSaveData?: boolean;
  areas: NeptuneArea[];
  familyInstances: { [key: string]: FamilyInstanceDTO };
}) => {
  if (
    !documentCategorySelected ||
    Object.keys(mapDocumentItemCheckbox)?.length
  ) {
    return;
  }

  const documentItems = documentCategorySelected?.documentItems;
  const newDocumentSubCategories = cloneDeep(
    documentCategorySelected?.documentSubCategories || []
  );

  const isStatus = Object.keys(mapDocumentItemStatus)?.length;
  const isCheckbox = Object.keys(mapDocumentItemCheckbox)?.length;

  const checkExists = (docItem: DocumentItem) => {
    if (Object.keys(mapDocumentItemStatus)?.length) {
      return docItem?.id && docItem.id in mapDocumentItemStatus;
    }

    if (Object.keys(mapDocumentItemCheckbox)?.length) {
      return (
        docItem?.id &&
        Object.values(mapDocumentItemCheckbox?.[docItem.id as any] || {})
          ?.length
      );
    }

    return false;
  };

  const handleForStatus = (docItem: DocumentItem) => {
    const newStatus = mapDocumentItemStatus[docItem.id as any];

    return {
      ...docItem,
      updatedAt: new Date(),
      status: newStatus,
    };
  };

  const handleForCheckbox = (docItem: DocumentItem): DocumentItem => {
    let dataJson = cloneDeep(docItem.data) || {};
    const mapCheckboxValue = mapDocumentItemCheckbox[docItem.id!];
    dataJson = {
      ...dataJson,
      ...mapCheckboxValue,
    };

    return {
      ...docItem,
      updatedAt: new Date(),
      data: dataJson,
    };
  };

  let hasUpdateDocument = false;
  const newDocumentItems = await Promise.all(
    (documentItems || []).map(async (docItem) => {
      const isExists = checkExists(docItem);

      if (isExists) {
        let newDocumentItem: DocumentItemDTO = mapItemAreaIds({
          item: {
            ...docItem,
          },
          areas,
          familyInstances,
        });

        if (isStatus) {
          newDocumentItem = handleForStatus(docItem);
        }

        if (isCheckbox) {
          newDocumentItem = handleForCheckbox(docItem);
        }

        hasUpdateDocument = true;

        if (isSaveData) {
          newDocumentItem.id
            ? await documentItemApi.updateItem(newDocumentItem)
            : await documentItemApi.createItem(newDocumentItem);
        }

        const isNotFlexibleDuct =
          documentCategorySelected.documentType !==
          DocumentTemplateType.SELF_INSPECTION;
        if (isNotFlexibleDuct) {
          for (const eIndex in newDocumentSubCategories) {
            const subCategory = newDocumentSubCategories[eIndex];
            if (subCategory.externalId === newDocumentItem.externalId) {
              const dIndex = subCategory.documentItems?.findIndex(
                (d) => d.id === newDocumentItem.id
              );
              if (dIndex !== undefined && dIndex >= 0) {
                newDocumentSubCategories[eIndex].documentItems![dIndex] =
                  newDocumentItem;
              }
              break;
            }
          }
        }

        return newDocumentItem;
      }

      return docItem;
    })
  );

  return {
    hasUpdateDocument,
    newDocumentItems,
    newDocumentSubCategories,
  };
};

export interface FilterDocumentCategoryByUserSettingProps {
  settings: UserSetting;
  searchDocumentValue: string;
  areas: NeptuneArea[];
  documentTemplates: { [key: string]: DocumentTemplate };
  documentCategories: DocumentCategoryDTO[];
}

export const filterDocumentItemsByUserSettting = ({
  documentCategory,
  documentItems = [],
  settings,
  searchDocumentValue = "",
  areas,
  documentTemplates,
  skipFilterByDocumentTemplate = false,
  isIgnoreSearchDocument = false,
}: FilterDocumentCategoryByUserSettingProps & {
  documentItems: DocumentItemDTO[];
  documentCategory: DocumentCategoryDTO | undefined;
  skipFilterByDocumentTemplate?: boolean;
  isIgnoreSearchDocument?: boolean;
}) => {
  let isMatchSearchDocumentCategory = true;
  const isDocumentCategoryContainTags = !!documentCategory?.tags?.filter(
    (tag) => settings?.documentTags?.includes(tag)
  )?.length;

  if (searchDocumentValue && !isIgnoreSearchDocument) {
    isMatchSearchDocumentCategory =
      (documentCategory?.title?.toUpperCase() || "").includes(
        searchDocumentValue.toUpperCase()
      ) ||
      (documentCategory?.level?.toUpperCase() || "").includes(
        searchDocumentValue.toUpperCase()
      ) ||
      (
        areas
          .find((area) => area.id === documentCategory?.areaId)
          ?.name?.toUpperCase() || ""
      ).includes(searchDocumentValue.toUpperCase());
  }

  return documentItems?.filter((documentItem: DocumentItemDTO) => {
    const isCurrentUserAssign =
      !settings?.documentUserAssigned?.length ||
      settings.documentUserAssigned?.some((id) => {
        if (id) {
          return (
            documentCategory?.userAssigned?.includes(id) ||
            documentItem?.userAssigned?.includes(id)
          );
        }

        return !documentCategory?.userAssigned?.length;
      });

    const isCurrentUserCreated =
      !settings?.documentUserCreated?.length ||
      (documentItem.userCreated &&
        settings?.documentUserCreated.includes(documentItem.userCreated)) ||
      (documentCategory?.templateId &&
        settings?.documentUserCreated?.includes(
          documentTemplates[documentCategory?.templateId]?.createdBy
        ));

    const isTypeDocumentInspection =
      !settings?.documentTypeInspections?.length ||
      documentItem.objectTypes?.some((objectType) =>
        settings?.documentTypeInspections?.includes(objectType)
      );
    const isCurrentPriority =
      settings?.documentPriority?.includes(
        Number(documentItem?.priority || Priority.High)
      ) ||
      settings?.documentPriority?.includes(
        Number(documentCategory?.priority || Priority.High)
      );

    const isContainTags =
      !settings?.documentTags?.length ||
      isDocumentCategoryContainTags ||
      (documentItem.tags &&
        documentItem.tags.some((tag: string) =>
          settings?.documentTags.includes(tag)
        ));

    let isDisplayByImageCondition = true;

    if (settings?.documentHasImage !== settings?.documentHasNoImage) {
      const isHasImage = !!documentItem?.subItems?.some(
        (item) => !!item?.images?.src
      );
      if (settings?.documentHasImage) {
        if (!isHasImage && !documentItem.images?.length) {
          isDisplayByImageCondition = false;
        }
      }
      if (settings?.documentHasNoImage) {
        if (isHasImage || documentItem.images?.length) {
          isDisplayByImageCondition = false;
        }
      }
    }

    const isCurrentStatus = settings?.documentStatus?.includes(
      !documentItem?.status ||
        !Object.keys(MapDocumentCategoryStatusType).includes(
          documentItem?.status
        )
        ? DocumentCategoryStatusType.NotStarted
        : documentItem.status
    );

    const isMatchSearchDocumentItem =
      !searchDocumentValue ||
      isMatchSearchDocumentCategory ||
      (documentItem.title || "").includes(searchDocumentValue);

    return (
      isCurrentUserAssign &&
      isCurrentUserCreated &&
      isContainTags &&
      isTypeDocumentInspection &&
      isCurrentPriority &&
      isDisplayByImageCondition &&
      isCurrentStatus &&
      isMatchSearchDocumentItem
    );
  });
};

export const transformDocumentCategoryByUserSetting = ({
  documentCategory,
  settings,
  searchDocumentValue = "",
  areas,
  documentTemplates,
  documentCategories,
  skipFilterByDocumentTemplate,
}: FilterDocumentCategoryByUserSettingProps & {
  documentCategory: DocumentCategoryDTO | undefined;
  skipFilterByDocumentTemplate?: boolean;
}): DocumentCategoryDTO => {
  if (!documentCategory?.id) {
    return null as any;
  }
  const documentItems = filterDocumentItemsByUserSettting({
    documentCategory: documentCategory,
    documentItems: documentCategory?.documentItems || [],
    settings,
    searchDocumentValue,
    areas,
    documentCategories,
    documentTemplates,
    skipFilterByDocumentTemplate,
  });

  return { ...documentCategory, documentItems };
};

export const createDataGenerateForDocument = async (
  documentCategory?: DocumentCategoryDTO,
  documentItemSelected?: DocumentItemDTO
): Promise<{
  dataGenerate: DataGenerate;
  templateDetail: DocumentTemplate;
}> => {
  let dataGenerate = {} as DataGenerate;
  const isSleevePipe =
    documentCategory?.documentType === DocumentTemplateType.PHOTO_LEDGER;
  const isModuleChiller =
    documentCategory?.documentType ===
    DocumentTemplateType.EQUIPMENT_DATA_SHEET;
  let components: TemplateComponent[] = [] as any;
  let templateDetail: DocumentTemplate = {} as any;

  if (documentCategory?.templateId) {
    templateDetail =
      store.getState().document?.documentTemplates?.[
        documentCategory?.templateId
      ];
    components = templateDetail?.components || [];
  }

  // Generate data for document item of module chiller
  if (isModuleChiller && documentItemSelected) {
    dataGenerate = {
      updatedDateTimeDocCategory: "",
      updatedDateTimeDocItems: [
        formatStringDate(documentItemSelected?.updatedAt as any),
      ],
      components,
    };
  } else {
    dataGenerate = {
      updatedDateTimeDocCategory: formatStringDate(
        documentCategory?.updatedAt as any
      ),
      updatedDateTimeDocItems: (documentCategory?.documentItems || [])?.map(
        (doc) => formatStringDate(doc.updatedAt as any)
      ),
      components,
    };
  }

  const isHasKeynoteComponent = !!components.find(
    (component) =>
      component.type === TemplateComponentType.Image &&
      component.detail?.checkedImage
  )?.componentId;

  if (isSleevePipe && !isHasKeynoteComponent) {
    const documentItemHasImage =
      documentCategory?.documentItems?.filter(
        (item) => !!item?.images?.length
      ) || [];

    dataGenerate.updatedDateTimeDocItems = documentItemHasImage?.map((doc) =>
      formatStringDate(doc.updatedAt as any)
    );
  }

  return { templateDetail, dataGenerate };
};

export const checkKeynoteValid = async ({
  documentCategory,
  documentKeynote,
  guid,
}: CheckKeynoteValidProps) => {
  const keynoteImageData =
    documentKeynote?.keynoteImageData || ({} as KeynoteImageData);
  const newGuid = guid ?? keynoteImageData?.guid;
  const keylabels = keynoteImageData?.mapLabelsByGuid?.[newGuid]?.keyLabels;
  let isImageUrlValid = false;
  if (keynoteImageData?.mapLabelsByGuid?.[newGuid]?.imageUrl) {
    const imageUrl = await getPreSignUrl(
      keynoteImageData?.mapLabelsByGuid?.[newGuid]?.imageUrl,
      ""
    );
    isImageUrlValid = await checkIsImageExists(imageUrl).catch(() => false);
  }

  const isCalculatePostion = !!keylabels;
  const selectedExternalIds = documentCategory?.selectedExternalIds || [];
  const documentItems = (documentCategory?.documentItems || [])?.filter(
    (item) => selectedExternalIds.includes(item.id || "")
  );

  const instances = documentItems;
  const instancesStatusStr = instances
    ?.map((ins: DocumentItemDTO) => ins?.status)
    .join("");
  const instancesDocItemExternalIds = instances
    ?.map((ins: DocumentItemDTO) => ins.id)
    ?.join("");
  const keyLabelsStatusStr = keylabels?.map((item) => item?.status).join("");
  const keyLabelsIdsStr = keylabels?.map((item) => item?.id).join("");
  const isDiffIds =
    instancesDocItemExternalIds?.valueOf() !== keyLabelsIdsStr?.valueOf();
  const isDiffStatus =
    instancesStatusStr?.valueOf() !== keyLabelsStatusStr?.valueOf();

  const instancesFilter = (instances as DocumentItemDTO[])?.filter(
    (ins: DocumentItemDTO) => ins.id
  );
  const mapInstance: { [key: string]: DocumentItemDTO } = Object.assign(
    {},
    ...instancesFilter?.map((ins: DocumentItemDTO) => ({
      [ins?.id || ""]: ins,
    }))
  );
  const isDiffInstanceLength = instances?.length !== keylabels?.length;
  const isDiffPosition = keylabels?.some((item) => {
    const docItem = mapInstance?.[item?.id];

    return docItem?.id && !isEqual(docItem?.position, item?.positionFromForge);
  });

  const isInstancesDiffKeynotes =
    isDiffIds || isDiffStatus || isDiffInstanceLength || isDiffPosition;

  return {
    isCalculatePostion: isCalculatePostion && isImageUrlValid,
    isInstancesDiffKeynotes,
    keynoteImageData,
    instances,
  };
};

export const onDispatchComponents = ({
  newData,
  components,
}: OnDispatchComponentsProps) => {
  const dispatch = store.dispatch;
  let newComponents = [...components];
  const index = newComponents.findIndex(
    (i) => i.componentId === newData.componentId
  );
  if (index !== -1) {
    newComponents.splice(index, 1, newData);
    dispatch(setComponents(newComponents));

    return;
  }
  newComponents = [...newComponents, newData];

  dispatch(setComponents([...newComponents, newData]));
  dispatch(setComponentSelected({ ...newData }));
};

export const checkHasOverlap = ({
  target,
  position,
  size,
  axis,
  components,
}: CheckHasOverlapProps) => {
  if (!target || !position || !size) return undefined;

  let list = [...components];

  if (axis) {
    list = list.sort((c1, c2) =>
      sortNearestComponentPosition(target, c1, c2, position as Position, axis)
    );
  }

  return list.find((component) => {
    if (
      component.page !== target.page ||
      component.componentId === target.componentId
    ) {
      return false;
    }

    const isHorizontal =
      (component.position.x < position.x &&
        position.x < component.position.x + component.size.width) ||
      (position.x < component.position.x &&
        component.position.x < position.x + size.width);

    const isVertical =
      (component.position.y < position.y &&
        position.y < component.position.y + component.size.height) ||
      (position.y < component.position.y &&
        component.position.y < position.y + size.height);

    return isHorizontal && isVertical;
  });
};

export const checkIsOutOfLimit = ({
  position,
  size,
  isBlackboardTemplate = false,
}: CheckIsOutOfLimitProps) => {
  return (
    position.x < 0 ||
    position.y < 0 ||
    Math.floor(position.x + size.width) >
      (isBlackboardTemplate
        ? DEFAULT_BLACKBOARD_CONTENT_SIZE.width
        : DEFAULT_GRID_SIZE.width) ||
    Math.floor(position.y + size.height) >
      (isBlackboardTemplate
        ? DEFAULT_BLACKBOARD_CONTENT_SIZE.height
        : DEFAULT_GRID_SIZE.height)
  );
};

export const checkExistsLinkedTable = (components: TemplateComponent[]) => {
  return !!components?.find((item) => !!item?.linkedHeaderId);
};

export const checkHasExistsHeader = (components: TemplateComponent[]) => {
  return components?.some(
    (item) => item.type === TemplateComponentType.TableHeader
  );
};

export const getDataBlackboardDefault = ({
  documentItem,
  currentLevel,
  levels,
  users,
  currentUser,
  documentCategoryId,
}: {
  documentItem?: DocumentItemDTO | undefined;
  currentLevel?: string;
  levels: Level[];
  users: User[];
  currentUser: User | null;
  partnerCompanies?: PartnerCompany[];
  documentCategoryId?: string;
}): Partial<Blackboard> => {
  const defaultFloor = {
    label: documentItem?.level,
    value:
      levels?.find((item) => item?.label === documentItem?.level)?.guid || "",
  };

  const listOptionUsers = users
    ?.map((user) => ({
      label: user?.name || "",
      value: user?.id || "",
    }))
    ?.filter((user) => !!user?.value);

  return {
    constructionNameId: listConstruction[0].value,
    constructionNameText: listConstruction[0].label,
    constructionAddressId: defaultFloor.value,
    constructionAddressText: defaultFloor.label,
    userCheck: listOptionUsers?.[0]?.label,
    witness: [listOptionUsers?.[0]?.label],
    supervisorText: listOptionUsers?.[0]?.label,
    supervisorId: listOptionUsers?.[0]?.value,
    shootingFloorText: currentLevel || "",
    shootingFloorId: levels.find((level) => level.label === currentLevel)?.guid,
    builderFirstId: listOtherPartnerCompanies?.[0]?.value,
    builderFirstText: listOtherPartnerCompanies?.[0]?.label,
    shootingAreaText: listConstructionAddress[0].label,
    shootingAreaId: listConstructionAddress[0].value,
    content: [],
    createdBy: currentUser?.id || "",
    thumbnail: "",
    documentCategoryId,
  };
};

export const handleUpdateDataDocCategory = (
  documentCategory: DocumentCategoryDTO,
  documentItem: DocumentItemDTO
) => {
  const newDocumentCategory = { ...documentCategory };

  newDocumentCategory.documentItems = newDocumentCategory?.documentItems?.map(
    (docItem) => {
      if (docItem?.id === documentItem?.id) return documentItem;

      return docItem;
    }
  );

  newDocumentCategory.documentSubCategories =
    newDocumentCategory?.documentSubCategories?.map((docSub) => {
      if (docSub?.externalId === documentItem?.externalId) {
        const documentItems = docSub?.documentItems?.map((docItem) => {
          if (docItem?.id === documentItem?.id) return documentItem;

          return docItem;
        });

        return {
          ...docSub,
          documentItems,
        };
      }

      return docSub;
    });

  return newDocumentCategory;
};

export const getDefaultDataByTemplate = (template: DocumentTemplate) => {
  const defaultDataForDocCategory: Record<string, string> = {};
  const defaultDataForDocItem: Record<string, string> = {};

  const components = template?.components;
  const tables = components?.filter(
    (component: TemplateComponent) =>
      component?.type === TemplateComponentType.Table &&
      !component?.linkedHeaderId
  );

  const headers = components?.filter(
    (component: TemplateComponent) =>
      component?.type === TemplateComponentType.TableHeader
  );

  tables.forEach((table: TemplateComponent) => {
    table?.detail?.rows?.forEach((row: RowType) => {
      row?.cells?.forEach((cell: CellType) => {
        if (cell?.cellId && cell?.cellLinkedData?.options?.dynamicFieldValue) {
          defaultDataForDocCategory[cell.cellId] =
            cell?.cellLinkedData?.options?.dynamicFieldValue;
        }
      });
    });
  });

  headers.forEach((header: TemplateComponent) => {
    header?.detail?.rows?.[0]?.cells?.forEach((cell: CellType) => {
      if (cell?.subTable) {
        const lastRowIndex = Number(cell?.subTable?.rows?.length || 0) - 1;
        cell?.subTable?.rows?.[lastRowIndex]?.cells?.forEach(
          (subCell: CellType) => {
            if (
              subCell?.cellId &&
              subCell?.cellLinkedData?.options?.dynamicFieldValue &&
              !subCell?.repeatedFromCellId
            ) {
              defaultDataForDocItem[subCell.cellId] =
                subCell?.cellLinkedData?.options?.dynamicFieldValue;
            }
          }
        );
      }

      if (
        cell?.cellId &&
        cell?.cellLinkedData?.options?.dynamicFieldValue &&
        !cell?.repeatedFromCellId
      ) {
        defaultDataForDocItem[cell.cellId] =
          cell?.cellLinkedData?.options?.dynamicFieldValue;
      }
    });
  });

  return { defaultDataForDocCategory, defaultDataForDocItem };
};

export const getHeaderComponentElementId = (componentId: string) =>
  `header-${componentId}`;

export const getColorTextByStatus = (
  status?: DocumentCategoryStatusType | string
) => {
  return MapDocumentCategoryStatusTypeColorText[
    (status ||
      DocumentCategoryStatusType.NotStarted) as DocumentCategoryStatusType
  ];
};

export const getColorByStatus = (
  status?: DocumentCategoryStatusType | string
) => {
  return MapDocumentCategoryStatusTypeColor[
    (status ||
      DocumentCategoryStatusType.NotStarted) as DocumentCategoryStatusType
  ];
};
