import { authApi, forgeApi } from "apiClient/v2";
import { ItemBIMType, ModalType, StatusType } from "constants/enum";
import { Condition } from "constants/family";
import {
  DEFAULT_BOUND_2D,
  DEFAULT_BOUND_3D,
  DEFAULT_HEIGHT_ELEMENT,
  FIT_TO_VIEW_OFFSET,
  GRAY_OUT_OPACITY,
  GRAY_OUT_THEME_COLOR,
  LEVEL_ALL,
  LEVEL_OTHER,
  RATIO_ZOOM_ON_FAMILY,
  REGEX_FAMILY_INSTANCE,
} from "constants/forge";
import { FORGE_DATA_FOLDER_PATH } from "constants/s3";
import { RIGHT_SIDEBAR_MODAL_CLASSNAME } from "constants/styleProps";
import { FamilyInstanceDTO } from "interfaces/dtos/familyInstance";
import { Level, ModelTree, Sheet, Vector3 } from "interfaces/models";
import { ApiResponse } from "interfaces/models/api";
import { Space } from "interfaces/models/area";
import { DataProjectModel } from "interfaces/models/dataProjectModel";
import { DerivativesReq } from "interfaces/models/derivatives";
import {
  DocumentCategory,
  DocumentSubCategory,
} from "interfaces/models/documentCategory";
import { Family } from "interfaces/models/family";
import { FamilyInstance } from "interfaces/models/familyInstance";
import { ungzip } from "pako";
import { resetState, setModelTree } from "redux/forgeViewerSlice";
import store from "redux/store";
import { axiosECS } from "services/baseAxios";
import { getBimFileInfo } from "utils/bim";
import { removeEmptyProp, sleep, wait } from "utils/common";
import { downloadFileFromS3 } from "utils/download-multipart";
import { logDev, logError } from "utils/logs";
import { uploadMultipartToS3 } from "utils/upload-multipart";
import { getLabelExtension } from "./extensions/custom-label";
import {
  calculatePositionOnSheet,
  find2DBounds,
  ___sheetTransformMatrix,
  ___viewer2d,
} from "./forge2d";
import { find3DBounds, ___viewer3d } from "./forge3d";

export let ___mapExternalId: { [key: string]: number };
export let ___mapDbId: { [key: number]: string };
export let ___modelDbIds: { main: number[]; linked: number[] } = {
  main: [],
  linked: [],
};
export let ___currentThemeColor: THREE.Vector4 | undefined = undefined;

const CAPTURE_VIEWER_IMAGE_WIDTH = 2_000;

export interface iSetSelectionMutilColorByDbId {
  viewer?: Autodesk.Viewing.GuiViewer3D;
  selections: { dbId: number | undefined; color: THREE.Vector4 }[];
}

export interface FamilyDataOnLevel {
  families: FamilyInstance[];
}

export interface ItemDataUpdate {
  listDocumentCategoryFlexible?: DocumentCategory[];
  listDocumentCategoryPAC?: {
    documentCategory?: DocumentCategory;
    documentSubCategories?: DocumentSubCategory[];
  }[];
  listDocumentCategorySleevePipe?: {
    documentCategory?: DocumentCategory;
    documentSubCategories?: DocumentSubCategory[];
  }[];
  bimFileId: String;
  lastIdInfo: {
    listDocumentCategoryFlexible?: number;
    listDocumentCategoryPAC?: number;
    listDocumentCategorySleevePipe?: number;
  };
}

export interface LevelInfoGenerated {
  hasPac: boolean;
  hasSleeve: boolean;
  hasFlexible: boolean;
}

export const setMapExternalId = (value: any) => {
  ___mapExternalId = value;
};
export const setMapDbId = (value: any) => {
  ___mapDbId = value;
};
export const getMapDbId = () => {
  return ___mapDbId;
};

export const setModelDbIds = (value: any) => {
  ___modelDbIds = value;
  setThemingColor(undefined);
};
export const setThemingColor = (color?: THREE.Vector4) => {
  ___currentThemeColor = color;
};

export const clearAllData = () => {
  setMapExternalId({});
  setMapDbId({});
  setModelDbIds({});
};

export const buildModelTree = (model: Autodesk.Viewing.Model) => {
  // builds model tree recursively
  function _buildModelTreeRec(node: any) {
    it?.enumNodeChildren(node.dbId, function (childId: any) {
      node.children = node.children || [];
      const childNode = {
        dbId: childId,
        name: it.getNodeName(childId),
        isCheck: true,
      };

      node.children.push(childNode);
      _buildModelTreeRec(childNode);
    });
  }

  // get model instance tree and root component
  const it = model.getData().instanceTree;

  const rootId = it?.getRootId();

  const rootNode: ModelTree = {
    dbId: rootId,
    name: it?.getNodeName(rootId),
  };

  _buildModelTreeRec(rootNode);

  logDev({ modelTree: rootNode });
  store.dispatch(setModelTree(rootNode));

  return rootNode;
};

const BATCH_DBIDS_SIZE = 4000;

async function postRetry(
  url: string,
  options: any,
  delay: number,
  tries: number
): Promise<any> {
  const result = await axiosECS.post(url, options);
  if (!result) {
    const triesLeft = tries - 1;
    if (!triesLeft) {
      return;
    }
    await wait(delay);
    logDev(triesLeft);

    return await postRetry(url, options, delay, triesLeft);
  }

  return result;
}

export const getViewableProperties = async ({
  bimFileId,
  version,
  level,
  keys,
}: {
  bimFileId: string;
  version: string;
  level: { guid: string; name: string };
  keys?: string[];
}): Promise<
  ApiResponse<{
    total: number;
    data: {
      objectid: string;
      name: string;
      externalId: string;
      dbId: number;
      properties: { [key: string]: any };
    }[];
  }>
> => {
  return axiosECS.post(
    `/v1/forge/projects/null/bims/version/${encodeURIComponent(
      `${bimFileId}?version=${version}`
    )}/properties`,
    {
      level,
      keys: keys || [
        "Reference Level",
        "Level",
        "System Name",
        "記号",
        "System Type",
        "Type Name",
        "タイプ名",
        "ファンの種類",
        "積算_施工区分",
        "符号",
        "形式",
        "Size",
        "サイズ",
        "Design Option",
        "ダクト径_半径",
        "風量",
        "デザイン オプション",
        "開口率",
        "面風速",
      ],
    }
  );
};

export const getDbIdProperties = async (
  dbIds: number[],
  bimFileId: string,
  version: string,
  bimGuid?: string,
  keys?: string[]
) => {
  const length = Math.ceil(dbIds.length / BATCH_DBIDS_SIZE);
  const promiseArray = [];
  for (let i = 0; i < length; i++) {
    const itemPuts = dbIds.slice(
      i * BATCH_DBIDS_SIZE,
      Math.min(i * BATCH_DBIDS_SIZE + BATCH_DBIDS_SIZE, dbIds.length)
    );
    promiseArray.push(
      postRetry(
        `/v1/forge/projects/null/bims/version/${encodeURIComponent(
          `${bimFileId}?version=${version}`
        )}/properties`,
        {
          keys: keys || [
            "Reference Level",
            "Level",
            "System Name",
            "記号",
            "System Type",
            "Type Name",
            "ファンの種類",
            "積算_施工区分",
            "符号",
            "形式",
            "Size",
            "Design Option",
            "ダクト径_半径",
            "風量",
            "デザイン オプション",
          ],
          dbIds: itemPuts,
          bimGuid: bimGuid || "all",
        },
        500,
        3
      )
    );
  }

  return (await Promise.all(promiseArray))
    .map((item) => item?.data?.data)
    .flat(1);
};

export const getExternalId = async (
  bimFileId: string,
  version: string,
  shouldCache?: boolean
) => {
  const res = await forgeApi.getExternalIdsByVersionId({
    projectId: "null",
    versionId: encodeURIComponent(`${bimFileId}?version=${version}`),
    shouldCache,
  });

  return res?.data;
};

export const showElements = (showMain: boolean, showLinked: boolean) => {
  const viewer = getCurrentViewer();
  if (!viewer) {
    return;
  }

  if (showMain) {
    viewer.show(___modelDbIds.main);
  } else {
    viewer.hide(___modelDbIds.main);
  }

  if (showLinked) {
    viewer.show(___modelDbIds.linked);
  } else {
    viewer.hide(___modelDbIds.linked);
  }
};

export const getExternalIdFromDbId = (dbId: number) => {
  if (!___mapDbId) {
    return;
  }

  return ___mapDbId[dbId];
};

export const getDbIdByExternalId = (externalId?: string) => {
  if (!___mapExternalId || !externalId) {
    return NaN;
  }

  return Number(___mapExternalId[externalId]);
};

export const getAECData = async (urn: string, shouldCache = false) => {
  const { data } = await forgeApi.getAECByVersionId({
    projectId: "null",
    versionId: encodeURIComponent(urn),
    shouldCache,
  });

  return data;
};

export const getSheetsData = async ({
  projectId,
  versionId,
}: DerivativesReq) => {
  let sheets: Sheet[] = [];
  if (!projectId || !versionId) return sheets;
  const { data: res } = await forgeApi.getDerivativesByVersionId({
    projectId: projectId || "",
    versionId: encodeURIComponent(versionId),
  });
  const aecData: any = await getAECData(decodeURIComponent(versionId));

  if (res.children?.length) {
    sheets = res.children
      .filter(
        (item: any) =>
          item.status === StatusType.SUCCESS &&
          item.hasThumbnail === "true" &&
          item.role === "2d" &&
          item.type === ItemBIMType.GEOMETRY
      )
      .map(
        (item: any) =>
          ({
            guid: item.guid,
            name: item.name,
            isMissingViewport: !aecData.viewports.find(
              (viewport: any) => viewport.sheetGuid === item.guid
            ),
          } as Sheet)
      );
  }

  return sheets;
};

export const transformLevelByAecDataAndDerivative = async ({
  projectId,
  versionId,
}: {
  projectId: string;
  versionId: string;
}) => {
  const { data: derivativeData } = await forgeApi.getDerivativesByVersionId({
    projectId: projectId || "",
    versionId: encodeURIComponent(versionId),
  });
  const aecData: any = await getAECData(versionId);
  const levels: Level[] = [];
  const levelsAceFilter: any[] = [];

  const derivatives = derivativeData.children || [];
  let derivativesLevels3D = derivatives.filter(
    (item: any) =>
      item.hasThumbnail === "true" &&
      item.role === "3d" &&
      item.type === ItemBIMType.GEOMETRY
  );

  aecData?.levels?.forEach((level: any) => {
    const derivative = derivativesLevels3D.find((item: any) => {
      const name: string = item.name;

      const arrName = name.split("_");
      arrName.shift();
      const levelName = arrName.map((n) => n.replaceAll(" ", "")).join("");

      const currentLevelName = level.name.replace(" ", "").replace("_", "");
      const otherCondition = arrName.some(
        (name) => name.replace(/L$/, "") === currentLevelName.replace(/L$/, "")
      );

      const isIncludeName =
        levelName.includes(currentLevelName) || otherCondition;
      if (isIncludeName) {
        derivativesLevels3D = derivativesLevels3D.filter(
          (i: any) => i.guid !== item.guid
        );
      }

      return isIncludeName;
    });
    if (derivative) {
      levelsAceFilter.push(level);
      levels.push({
        guid: String(derivative.guid),
        label: String(level.name),
        sheets: [],
        zMin: level.elevation,
        zMax: level.elevation + level.height,
      });
    }
  });

  return { levels, levelsAceFilter };
};

export const getLevelsData = async ({
  projectId,
  versionId,
}: DerivativesReq) => {
  const optionAll: Level = { ...LEVEL_ALL };
  if (!projectId || !versionId) return [optionAll];
  const { levels } = await transformLevelByAecDataAndDerivative({
    projectId,
    versionId,
  });
  const optionOther: Level = { ...LEVEL_OTHER };

  return [optionAll, ...levels, optionOther];
};

export const getCurrentViewer = () => {
  return ___viewer2d || ___viewer3d;
};

export const getLevelOfPosition = (position: Vector3 | THREE.Vector3) => {
  const levels = store.getState().forgeViewer.levels || [];
  const level = levels.find(
    (level: any) => level.zMin <= position.z && position.z <= level.zMax
  );

  return level?.label;
};

export const getLevelOfObject = (dbId: number, instanceTree: any) => {
  const levels = store.getState().forgeViewer.levels || [];
  const nodeBox = new Float32Array(6);
  instanceTree.getNodeBox(dbId, nodeBox);
  const nodeBoxMinZ = nodeBox[2];
  const nodeBoxMaxZ = nodeBox[5];
  for (const level of levels) {
    const zMin = level.zMin || 0;
    const zMax = level.zMax || 0;
    if (
      (nodeBoxMinZ >= zMin && nodeBoxMinZ <= zMax) ||
      (nodeBoxMaxZ >= zMin && nodeBoxMaxZ <= zMax) ||
      (nodeBoxMinZ <= zMin && nodeBoxMaxZ >= zMax)
    ) {
      return level.label;
    }
  }

  return null;
};

export const zoomInOut = (zoomScale: number) => {
  const viewer = getCurrentViewer();
  const dollyTarget = viewer?.navigation.getWorldPoint(0.5, 0.5)!;
  viewer?.navigation.dollyFromPoint(zoomScale, dollyTarget);
};

export const displayObjects = (show: boolean, dbIds: number[]) => {
  const viewer = getCurrentViewer();
  if (show) {
    viewer?.show(dbIds);
  } else {
    viewer?.hide(dbIds);
  }
};

export const covertToDbIds = (
  ids?: number | string | number[] | string[] | undefined
) => {
  if (typeof ids === "undefined") {
    return [];
  }
  if (typeof ids === "number") {
    return [ids];
  }
  if (typeof ids === "string") {
    const dbId = getDbIdByExternalId(ids);

    return [dbId];
  }
  if (Array.isArray(ids)) {
    return ids.map((id) => {
      if (typeof id === "number") {
        return id;
      }

      return getDbIdByExternalId(id);
    });
  }

  return [] as number[];
};

export const setSelectionColor = ({
  viewer,
  color,
  shouldRender,
}: {
  viewer?: Autodesk.Viewing.GuiViewer3D;
  color: THREE.Color | string;
  shouldRender?: boolean;
}) => {
  if (!viewer) {
    viewer = getCurrentViewer();
  }
  if (!viewer?.model) {
    return;
  }

  const selectionColor = new THREE.Color(color);
  if (viewer.model.is2d()) {
    viewer.set2dSelectionColor(selectionColor, 1);
  } else {
    viewer.setSelectionColor(selectionColor, 0);
  }
  if (shouldRender) {
    viewer.impl.invalidate(true);
  }
};

const createColor = (color: string) => {
  const { b, g, r } = new THREE.Color(color);

  return new THREE.Vector4(r, g, b);
};

const handleGrayOut3d = (viewer: Autodesk.Viewing.GuiViewer3D) => {
  ___currentThemeColor = new THREE.Vector4(0.8, 0.8, 0.8, 1);
  ___modelDbIds?.main?.forEach((id) =>
    viewer.model.setThemingColor(id, ___currentThemeColor!)
  );
  ___modelDbIds?.linked?.forEach((id) =>
    viewer.model.setThemingColor(id, ___currentThemeColor!)
  );
};

const handleGrayOut2d = (viewer: Autodesk.Viewing.GuiViewer3D) => {
  const fragments = viewer.model.getFragmentList();
  if (!fragments.dbIdOpacity) {
    return;
  }
  ___currentThemeColor = createColor(GRAY_OUT_THEME_COLOR);
  ___modelDbIds?.main?.forEach((id) => {
    viewer.model.setThemingColor(id, ___currentThemeColor!);
    fragments.dbIdOpacity[id] = GRAY_OUT_OPACITY;
  });
  ___modelDbIds?.linked?.forEach((id) => {
    viewer.model.setThemingColor(id, ___currentThemeColor!);
    fragments.dbIdOpacity[id] = GRAY_OUT_OPACITY;
  });
};

export const selectDbIds = (
  ids: number | string | number[] | string[] | undefined,
  options: { color?: string }
) => {
  const viewer = getCurrentViewer();
  if (!viewer?.model || !viewer?.impl) return;
  const is3d = viewer.model.is3d();
  if (is3d) {
    handleGrayOut3d(viewer);
  } else {
    handleGrayOut2d(viewer);
  }
  const dbIds = covertToDbIds(ids);
  if (options?.color) {
    setSelectionColor({ viewer, color: new THREE.Color(options.color) });
  } else {
    setSelectionColor({ viewer, color: new THREE.Color(0.4, 0.6, 1) });
  }
  viewer.select(dbIds);
  viewer.impl.invalidate(true, true);
};

export const clearSelectionId = (dbId: number, isResetColor = true) => {
  const viewer = getCurrentViewer();
  if (!viewer?.model || !viewer?.impl) return;
  const fragments = viewer.model.getFragmentList();
  viewer.select(viewer?.getSelection().filter((id) => id !== dbId));
  if (isResetColor && ___currentThemeColor) {
    viewer.model.setThemingColor(dbId, ___currentThemeColor!, true);
    fragments.dbIdOpacity[dbId] = 1;
  }
  viewer.impl.invalidate(true, true);
};

export const clearForgeSelection = (viewer?: Autodesk.Viewing.GuiViewer3D) => {
  if (!viewer) {
    viewer = getCurrentViewer();
  }
  if (!viewer?.model || !viewer?.impl) {
    return;
  }
  if (!!___currentThemeColor) {
    ___currentThemeColor = undefined;
    setSelectionColor({ viewer, color: new THREE.Color(0.4, 0.6, 1) });
    viewer.clearThemingColors(viewer.model);
    const fragments = viewer.model.getFragmentList();
    ___modelDbIds?.main?.forEach((id) => {
      fragments.dbIdOpacity[id] = 1;
    });
    ___modelDbIds?.linked?.forEach((id) => {
      fragments.dbIdOpacity[id] = 1;
    });
  }
  if (!!viewer.getSelectionCount()) {
    viewer.clearSelection();
  }
  viewer.impl.invalidate(true, true);
};

export const fitToViewByDbId = (
  ids?: number | string | number[] | string[],
  familyInstance?: FamilyInstance,
  imediate: boolean = true
) => {
  const viewer = getCurrentViewer();
  const dbIds = covertToDbIds(ids);
  if (!viewer || !dbIds.length || (___viewer2d && !___sheetTransformMatrix))
    return;

  viewer.fitToView(dbIds, undefined, imediate);
  const y = familyInstance?.bounds?.max?.y || DEFAULT_HEIGHT_ELEMENT;
  zoomInOut(RATIO_ZOOM_ON_FAMILY * y);
};

export const fitToViewByPositions = (
  positions: Vector3[] | THREE.Vector3[],
  imediate: boolean = true
) => {
  const viewer = getCurrentViewer();
  if (!viewer?.model || (___viewer2d && !___sheetTransformMatrix)) {
    return;
  }
  const is2d = viewer.model.is2d();

  const pos = positions.map((pos) => {
    const absolutePos = is2d ? calculatePositionOnSheet(pos) : pos;

    return new THREE.Vector3(absolutePos.x, absolutePos.y, absolutePos.z);
  });

  const bounds = viewer.model.is2d() ? DEFAULT_BOUND_2D : DEFAULT_BOUND_3D;
  let bound = new THREE.Box3().setFromPoints(pos);
  bound = new THREE.Box3(
    new THREE.Vector3(
      bound.min.x - bounds.size().x,
      bound.min.y - bounds.size().y,
      bound.min.z
    ),
    new THREE.Vector3(
      bound.max.x + bounds.size().x,
      bound.max.y + bounds.size().y,
      bound.max.z
    )
  );
  viewer.navigation.fitBounds(imediate, bound);
};

export const fitToViewByPosition = (
  position: Vector3 | THREE.Vector3,
  imediate: boolean = true,
  dbId?: number,
  offset = FIT_TO_VIEW_OFFSET
) => {
  const viewer = getCurrentViewer();
  if (!viewer?.model || (___viewer2d && !___sheetTransformMatrix)) {
    return;
  }

  const bound = new THREE.Box3(
    new THREE.Vector3(
      position.x - offset.x,
      position.y - offset.y,
      position.z - offset.z
    ),
    new THREE.Vector3(
      position.x + offset.x,
      position.y + offset.y,
      position.z + offset.z
    )
  );

  if (viewer?.model?.is2d() && ___sheetTransformMatrix) {
    bound.applyMatrix4(___sheetTransformMatrix);
  }

  if (dbId) {
    const instanceTree = viewer.model.getData().instanceTree;
    const fragList = viewer.model.getFragmentList();
    if (instanceTree && !!instanceTree.getNodeIndex(dbId) && fragList) {
      const objectBound = viewer.model.is2d()
        ? find2DBounds(fragList, instanceTree, dbId)
        : find3DBounds(fragList, instanceTree, dbId);
      bound.max.x = Math.max(bound.max.x, objectBound.max.x);
      bound.max.y = Math.max(bound.max.y, objectBound.max.y);
      bound.max.z = Math.max(bound.max.z, objectBound.max.z);
      bound.min.x = Math.min(bound.min.x, objectBound.min.x);
      bound.min.y = Math.min(bound.min.y, objectBound.min.y);
      bound.min.z = Math.min(bound.min.z, objectBound.min.z);
    }
  }

  viewer.navigation.fitBounds(imediate, bound);
};

export const isInsideSectionBox = (point: THREE.Vector3) => {
  const viewer = getCurrentViewer();
  if (!viewer) {
    return;
  }

  const cutPlanes = viewer
    .getCutPlanes()
    .filter((plane) => Math.abs(plane.z) < 1000000000);
  let validPlane = 0;
  for (const cutPlane of cutPlanes) {
    if (
      cutPlane.x * point.x +
        cutPlane.y * point.y +
        cutPlane.z * point.z +
        cutPlane.w <
      0
    ) {
      validPlane++;
    }
  }

  return validPlane === cutPlanes.length;
};

export const fitViewerToCanvasHeight = (
  viewer: Autodesk.Viewing.GuiViewer3D
) => {
  if (!viewer?.model) {
    return;
  }

  const bound = viewer.model.getBoundingBox();
  const center = bound.getCenter();
  bound.min.x = center.x;
  bound.max.x = center.x;

  if (!!viewer.model.is2d()) {
    bound.max.y = viewer.model.getMetadata("page_dimensions", "page_height");
    bound.min.y = 0;
  }

  viewer.navigation.fitBounds(true, bound);
};

export const setCameraToTop = (viewer?: Autodesk.Viewing.GuiViewer3D) => {
  if (!viewer?.model) {
    return;
  }
  const bound = viewer.model.getBoundingBox();
  const target = new THREE.Vector3(
    bound.getCenter().x,
    bound.getCenter().y,
    bound.min.z
  );
  const position = new THREE.Vector3(
    bound.getCenter().x,
    bound.getCenter().y,
    bound.max.z + 120
  );
  viewer.navigation.setPosition(position);
  viewer.navigation.setTarget(target);
  viewer.navigation.orientCameraUp(true);
  viewer.autocam.setCurrentViewAsHome(true);
};

export const setCameraToTopStart = (viewer?: Autodesk.Viewing.GuiViewer3D) => {
  if (!viewer?.model) {
    return;
  }
  const bound = viewer.model.getBoundingBox();
  const target = new THREE.Vector3(
    bound.getCenter().x + (bound.max.z - bound.min.z) / 2,
    bound.getCenter().y + (bound.max.z - bound.min.z) / 2,
    bound.min.z
  );
  const position = new THREE.Vector3(
    bound.min.x,
    bound.min.y,
    bound.max.z + 120
  );
  viewer.navigation.setPosition(position);
  viewer.navigation.setTarget(target);
  viewer.navigation.orientCameraUp(true);
  viewer.autocam.setCurrentViewAsHome(true);
};

const waitCameraProcess = async (viewer: Autodesk.Viewing.GuiViewer3D) => {
  let { x: newX, y: newY, z: newZ } = viewer.getCamera().getWorldPosition();
  let { x: oldX, y: oldY, z: oldZ } = viewer.getCamera().getWorldPosition();
  let loop = 10;
  do {
    oldX = viewer.getCamera().getWorldPosition().x;
    oldY = viewer.getCamera().getWorldPosition().y;
    oldZ = viewer.getCamera().getWorldPosition().z;
    await sleep(100);
    newX = viewer.getCamera().getWorldPosition().x;
    newY = viewer.getCamera().getWorldPosition().y;
    newZ = viewer.getCamera().getWorldPosition().z;

    if (oldX === newX && oldY === newY && oldZ === newZ) {
      loop--;
    }
  } while (oldX !== newX || oldY !== newY || oldZ !== newZ || loop);
  await sleep(1000);
};

export const setCameraToHomeAsync = async (
  viewer: Autodesk.Viewing.GuiViewer3D
) => {
  return new Promise(async (resolve) => {
    const viewerUtil: any = viewer?.utilities;
    viewerUtil?.goHome?.();
    await waitCameraProcess(viewer);

    resolve(true);
  });
};

export const setCameraToTopAsync = async (
  viewer?: Autodesk.Viewing.GuiViewer3D
) => {
  return new Promise(async (resolve) => {
    if (!viewer?.model) {
      resolve(false);

      return;
    }

    setCameraToTop(viewer);
    await waitCameraProcess(viewer);

    resolve(true);
  });
};

export const captureKeynote = async (
  selections?: iSetSelectionMutilColorByDbId["selections"]
) => {
  const viewer = getCurrentViewer();
  if (!viewer?.model) {
    return;
  }

  clearForgeSelection(viewer);
  let blobUrl = "";
  let imageWidth = 0;
  let imageHeight = 0;
  const isHasTaskSelected = !!store.getState().task.taskSelected?.id;
  const docCateModalEle = document.getElementById(
    RIGHT_SIDEBAR_MODAL_CLASSNAME
  );
  const modalType = store.getState().forgeViewer.modalType;
  const isModalTask = modalType === ModalType.TASK;

  const showOrHideModalElement = (isShow = false) => {
    if (docCateModalEle) {
      docCateModalEle.style.display = isShow ? "flex" : "none";
    }
  };

  try {
    showOrHideModalElement(false);
    await sleep(300);
    const isResetState = isHasTaskSelected && isModalTask;

    // gray out forge viewer and highlight object of keynote
    setSelectionMutilColorByDbId({ viewer, selections: selections || [] });

    if (!!viewer?.model?.is2d()) {
      const {
        width: widthOfModelBoundingBox,
        height: heightOfModelBoundingBox,
      } = getSizeOfModelBoundingBox();

      let width = Math.floor(widthOfModelBoundingBox);
      let height = Math.floor(heightOfModelBoundingBox);

      const ratio = width / height;
      width = CAPTURE_VIEWER_IMAGE_WIDTH;
      height = Math.floor(width / ratio);

      if (isResetState) {
        store.dispatch(resetState());
        viewer.select(undefined);
      }

      fitViewerToCanvasHeight(viewer);

      imageWidth = width;
      imageHeight = height;

      blobUrl = await new Promise((resolve) => {
        viewer.getScreenShot(width, height, (blobUrl: string) => {
          resolve(blobUrl);
        });
      });
    } else {
      if (isResetState) {
        store.dispatch(resetState());
      }

      viewer.select(undefined);
      await setCameraToHomeAsync(viewer);
      await setCameraToTopAsync(viewer);

      imageWidth = viewer.canvas.clientWidth;
      imageHeight = viewer.canvas.clientHeight;

      blobUrl = await new Promise((resolve) => {
        viewer.getScreenShot(imageWidth, imageHeight, (blobUrl: string) => {
          resolve(blobUrl);
        });
      });
    }

    // reset position of pin in forge view
    getLabelExtension()?.updateLabels();

    showOrHideModalElement(true);
  } catch (e) {
    logError("captureViewer", e);
    showOrHideModalElement(true);
  }

  return { blobUrl, canvas: viewer.canvas, imageWidth, imageHeight };
};

export const convertPositionWorldToClient = (pos: Vector3) => {
  const viewer = getCurrentViewer();

  return viewer?.worldToClient(new THREE.Vector3(pos.x, pos.y, pos.z));
};

export const getSizeOfModelBoundingBox = () => {
  const viewer = getCurrentViewer();
  const bounding = viewer?.model.getBoundingBox();

  const boundingMin = convertPositionWorldToClient(bounding?.min as Vector3);
  const boundingMax = convertPositionWorldToClient(bounding?.max as Vector3);

  const width = Math.abs(Number(boundingMax?.x) - Number(boundingMin?.x));
  const height = Math.abs(Number(boundingMax?.y) - Number(boundingMin?.y));

  return {
    width,
    height,
  };
};

export const overwriteHandleKeyDownFunction = (
  viewer: Autodesk.Viewing.GuiViewer3D
) => {
  const handleKeyDownEvent = (viewer.impl as any).controls.handleKeyDown;
  if (!handleKeyDownEvent) {
    return;
  }
  (viewer.impl as any).controls.handleKeyDown = function (e: any) {
    const modalType = store.getState().forgeViewer.modalType;
    if (modalType) {
      return;
    }
    handleKeyDownEvent.call(this, e);
  };
};

export const getForgeToken = async () => {
  return await authApi.getForgeToken().then((response) => {
    return {
      accessToken: response.access_token,
      expiresIn: response.expires_in,
      tokenType: response.token_type,
    };
  });
};

export const getLeafFragIds = (
  model: Autodesk.Viewing.Model,
  leafId: number
) => {
  const instanceTree = model.getData().instanceTree;
  const fragIds: number[] = [];

  instanceTree.enumNodeFragments(leafId, function (fragId: number) {
    fragIds.push(fragId);
  });

  return fragIds;
};

export const pointerToRaycaster = (
  domElement: any,
  camera: any,
  pointer: any
) => {
  const pointerVector = new THREE.Vector3();
  const pointerDir = new THREE.Vector3();
  const ray = new THREE.Raycaster();

  const rect = domElement.getBoundingClientRect();

  const x = ((pointer.clientX - rect.left) / rect.width) * 2 - 1;
  const y = -((pointer.clientY - rect.top) / rect.height) * 2 + 1;

  if (camera.isPerspective) {
    pointerVector.set(x, y, 0.5);

    pointerVector.unproject(camera);

    ray.set(camera.position, pointerVector.sub(camera.position).normalize());
  } else {
    pointerVector.set(x, y, -1);

    pointerVector.unproject(camera);

    pointerDir.set(0, 0, -1);

    ray.set(pointerVector, pointerDir.transformDirection(camera.matrixWorld));
  }

  return ray;
};

export const setSelectionMutilColorByDbId = ({
  viewer,
  selections,
}: iSetSelectionMutilColorByDbId) => {
  if (!viewer) {
    viewer = getCurrentViewer();
  }
  if (!viewer?.model) {
    return;
  }
  const is3d = viewer.model.is3d();
  if (is3d) {
    handleGrayOut3d(viewer);
  } else {
    handleGrayOut2d(viewer);
  }
  const fragments = viewer.model.getFragmentList();

  selections.forEach(({ dbId, color }) => {
    if (dbId) {
      viewer!.model.setThemingColor(dbId, color, true);
      fragments.dbIdOpacity[dbId] = 1;
    }
  });
  getLabelExtension()?.updateLabels();
  viewer.impl.invalidate(true, true);
};

export const checkIsDiffDataProjectVersion = (
  dataProjectDetail: DataProjectModel
) => {
  const urn = dataProjectDetail?.defaultBimPathId?.split("/").pop();
  const prevUrn = dataProjectDetail?.prevDefaultBimPathId?.split("/").pop();

  if (!prevUrn || !urn) {
    return false;
  }

  const { version: currentForgeVersion, bimFileId: currentBimFileId } =
    getBimFileInfo(urn || "");
  const { version: prevForgeVersion, bimFileId: prevBimFileId } =
    getBimFileInfo(prevUrn || "");

  return (
    currentForgeVersion !== prevForgeVersion &&
    currentBimFileId === prevBimFileId
  );
};

export const uploadFamilyInstancesToS3 = async ({
  bimFileId,
  version,
  levels,
  familyInstances,
}: {
  bimFileId: string;
  levels: Level[];
  familyInstances: { [key: string]: FamilyInstance };
  version: string;
}): Promise<boolean> => {
  const uploaded = await uploadMultipartToS3({
    fileData: { levels, familyInstances },
    filePath: FORGE_DATA_FOLDER_PATH,
    fileName: `f-data-${encodeURIComponent(bimFileId)}-v${version}.json`,
  });

  return uploaded;
};

export const getSpacesFromS3 = async ({
  bimFileId,
  version,
  shouldCache,
}: {
  bimFileId: string;
  version?: string;
  shouldCache?: boolean;
}): Promise<{ spaces: Space[] } | undefined> => {
  if (!version) {
    return;
  }
  const arrayBuffer = await downloadFileFromS3({
    filePath: FORGE_DATA_FOLDER_PATH,
    fileName: `f-spaces-${encodeURIComponent(bimFileId)}-v${version}.json`,
    shouldCache,
  });

  let textData = "";
  try {
    textData = ungzip(arrayBuffer, { to: "string" });
  } catch (err) {
    textData = new TextDecoder().decode(arrayBuffer);
  }
  // decompress data
  try {
    return JSON.parse(textData);
  } catch (err) {
    return;
  }
};

export const getFamilyInstancesFromS3 = async ({
  bimFileId,
  version,
  shouldCache,
}: {
  bimFileId: string;
  version?: string;
  shouldCache?: boolean;
}): Promise<
  | { familyInstances: { [key: string]: FamilyInstanceDTO }; levels: Level[] }
  | undefined
> => {
  if (!version) {
    return;
  }

  const arrayBuffer = await downloadFileFromS3({
    filePath: FORGE_DATA_FOLDER_PATH,
    fileName: `f-data-${encodeURIComponent(bimFileId)}-v${version}.json`,
    shouldCache,
  });

  let textData = "";
  try {
    textData = ungzip(arrayBuffer, { to: "string" });
  } catch (err) {
    textData = new TextDecoder().decode(arrayBuffer);
  }

  // decompress data
  try {
    return JSON.parse(textData);
  } catch (err) {
    return;
  }
};

export const getPropertiesFromModelAsync = (
  dbId: number
): Promise<FamilyInstanceDTO | undefined> => {
  return new Promise(async (resolve) => {
    const viewer = getCurrentViewer();
    const model = viewer?.model;

    if (!viewer || !model) {
      return resolve(undefined);
    }

    const project = store.getState().project.dataProjectDetail;
    const urn = project.defaultBimPathId?.split("/").pop();
    const { bimFileId, version } = getBimFileInfo(urn || "");

    const aecData: any = await getAECData(`${bimFileId}?version=${version}`);
    const levelsData: any[] = aecData?.levels?.map((l: any) => ({
      ...l,
      zMin: l.elevation,
      zMax: l.elevation + l.height,
    }));

    model.getProperties(
      dbId,
      (result) => {
        const instanceTree = viewer.model.getData().instanceTree;
        const fragList = viewer.model.getFragmentList();

        const bounds = find3DBounds(fragList, instanceTree, dbId);
        const sizeOfBounds = bounds.getCenter();
        if (!sizeOfBounds.x && !sizeOfBounds.y && !sizeOfBounds.z) {
          return resolve(undefined);
        }

        const position = bounds.getCenter();

        const systemType = result.properties?.find(
          (p) => p.displayName === "Type Name" || p.displayName === "Category"
        )?.displayValue;
        const symbol = result.properties?.find(
          (p) => p.displayName === "記号"
        )?.displayValue;
        const systemName = result.properties?.find(
          (p) => p.displayName === "System Name"
        )?.displayValue;
        const fanType = result.properties?.find(
          (p) => p.displayName === "ファンの種類"
        )?.displayValue;
        const designOption =
          result.properties?.find((p) => p.displayName === "Design Option")
            ?.displayValue ||
          result.properties?.find(
            (p) => p.displayName === "デザイン オプション"
          )?.displayValue;
        const sign = result.properties?.find(
          (p) => p.displayName === "符号"
        )?.displayValue;
        const estimateConstructionCategory = result.properties?.find(
          (p) => p.displayName === "積算_施工区分"
        )?.displayValue;
        const form = result.properties?.find(
          (p) => p.displayName === "形式"
        )?.displayValue;
        const size = result.properties?.find(
          (p) => p.displayName === "Size"
        )?.displayValue;
        const diameterRadius = result.properties?.find(
          (p) => p.displayName === "ダクト径_半径"
        )?.displayValue as any;
        const airVolume = result.properties?.find(
          (p) => p.displayName === "風量"
        )?.displayValue;

        const familyInstance: FamilyInstanceDTO = {
          bounds,
          dbId,
          externalId: result?.externalId || "",
          name: result.name || "",
          position,
          level:
            levelsData.find(
              (level, index) =>
                (Number(level.zMin) <= position.z || index === 0) &&
                (position.z <= Number(level.zMax) || index === level.length - 1)
            )?.name || "",
          systemType,
          symbol,
          systemName,
          fanType,
          designOption,
          sign,
          estimateConstructionCategory,
          form,
          size,
          diameterRadius,
          airVolume,
          objectTypes: [],
        };

        const getObjectTypesOfFamilyInstance = (instance: FamilyInstance) => {
          const families = store.getState().forgeViewer.families;
          const objectTypes: Family[] = [];

          families.forEach((family) => {
            if (
              family.conditions.every((condition) => {
                const value = String(
                  (instance as any)[condition.attribute] || ""
                ).toUpperCase();
                const conditionValue = (
                  condition.value || ""
                ).toLocaleUpperCase();
                switch (condition.condition) {
                  case Condition.Contains:
                    return !!value && value.includes(conditionValue);
                  case Condition.Equal:
                    return !!value && value === conditionValue;
                  case Condition.Exists:
                    return !!value;
                  case Condition.Greater:
                    return !!value && value > conditionValue;
                  case Condition.GreaterOrEqual:
                    return !!value && value >= conditionValue;
                  case Condition.Less:
                    return !!value && value < conditionValue;
                  case Condition.LessOrEqual:
                    return !!value && value <= conditionValue;
                  case Condition.NotContains:
                    return !!value && !value.includes(conditionValue);
                  case Condition.NotEqual:
                    return !!value && value !== conditionValue;
                  case Condition.NotExists:
                    return !value;
                  default:
                    return false;
                }
              })
            ) {
              objectTypes.push(family);
            }
          });

          return objectTypes;
        };

        familyInstance.objectTypes =
          getObjectTypesOfFamilyInstance(familyInstance);
        removeEmptyProp(familyInstance);

        return resolve(familyInstance);
      },
      () => {
        return resolve(undefined);
      }
    );
  });
};

export const getAllDbIds = (model?: Autodesk.Viewing.Model) => {
  if (!model) model = getCurrentViewer()?.model;
  if (!model) return [];
  const instanceTree = model.getData().instanceTree!;

  return Object.keys(instanceTree.nodeAccess?.dbIdToIndex || {}).map(function (
    id
  ) {
    return parseInt(id);
  });
};

export const getAllInstanceDbIds = (model?: Autodesk.Viewing.Model) => {
  if (!model) model = getCurrentViewer()?.model;
  if (!model) return [];
  const instanceTree = model.getData().instanceTree;
  if (!instanceTree) {
    return [];
  }
  const allDbIds = getAllDbIds(model);
  const instanceDbIds = allDbIds.filter((dbId) => {
    const name = instanceTree.getNodeName(dbId);
    if (!name) {
      return false;
    }
    const parentId = instanceTree.getNodeParentId(dbId);

    // TODO hardcode: module chiller
    if (name.includes("空冷モジュールチラー")) {
      return (
        name === "TTE_03020_RR_空冷モジュールチラー_三菱電機_CAHV-MP [22638185]"
      );
    }
    if (name === "RUA-SP424H") {
      return true;
    }

    return (
      REGEX_FAMILY_INSTANCE.test(name) &&
      (!parentId ||
        !REGEX_FAMILY_INSTANCE.test(instanceTree.getNodeName(parentId) || ""))
    );
  });

  return instanceDbIds;
};

export const FONT_SIZE_DISPLAY_ORDER = {
  1: "16px",
  2: "14px",
  3: "11px",
  4: "9px",
  5: "8px",
};
