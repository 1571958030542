import {
  blackboardApi,
  documentCategoryApi,
  documentItemApi,
} from "apiClient/v2";
import { message } from "components/base";
import {
  DocumentCategoryKey,
  DocumentCategoryStatusType,
  DocumentTemplateType,
  FamilyTitleDisplayType,
  ModalType,
  Priority,
  SystemModeType,
} from "constants/enum";
import { DocumentCategoryDTO } from "interfaces/dtos/documentCategoryDTO";
import {
  DocumentItemDTO,
  DocumentSubItemDTO,
} from "interfaces/dtos/documentItemDTO";
import { FamilyInstanceDTO } from "interfaces/dtos/familyInstance";
import { Level } from "interfaces/models";
import { Blackboard } from "interfaces/models/blackboard";
import {
  FamilyTitleDisplayForCustomDocumentItem,
  TitleDisplay,
} from "interfaces/models/component";
import { DocumentItem, DocumentSubItem } from "interfaces/models/documentItem";
import { DocumentTemplate } from "interfaces/models/documentTemplate";
import { PartnerCompany } from "interfaces/models/partnerCompany";
import { User, UserSetting } from "interfaces/models/user";
import {
  FilterDocumentCategoryByUserSettingProps,
  filterDocumentItemsByUserSettting,
  getDataBlackboardDefault,
} from "models/document";
import {
  doMapDocumentCategories,
  isPhotoLedgerTemplate,
} from "models/documentCategory";
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  fetchBlackBoardByCategoryId,
  setDataBlackboard,
  setDocumentGroupSelected,
  setDocumentItemSelected,
  setDocumentSubCategorySelected,
  updateDocumentInfoOfTemplate,
} from "redux/documentSlice";
import {
  resetState,
  setIsCreateDocumentItem,
  setModalType,
} from "redux/forgeViewerSlice";
import store, { RootState } from "redux/store";
import { updateTaskByDocumentItemId } from "redux/taskSlice";
import { updateForgeWhenSelectCategory } from "utils/document";
import { sleep, uuid } from "utils/common";
import {
  getDocumentItemTitleByFamilyTitle,
  hightLightDocumentItem,
} from "utils/documentItem";
import { getExternalIdFromDbId } from "utils/forge";

import { ClickInfo } from "utils/forge/extensions/click-extension";
import { getLabelExtension } from "utils/forge/extensions/custom-label";
import { arrayToObject } from "utils/object";
import { transformBodyForCombineData } from "utils/offline";
import useArea from "./useArea";
import { TypeHandleInitData } from "./useSupportSyncDataOffline";

interface iProps {
  clickInfo?: ClickInfo;
  filterDocumentCategoryOptions?: FilterDocumentCategoryByUserSettingProps;
  users?: User[];
  currentUser?: User | null;
  partnerCompanies?: PartnerCompany[];
  levels?: Level[];
  handleClickDocumentCategory: (documentCategory: DocumentCategoryDTO) => void;
  handleUpdateClickForgeInfo: (info?: ClickInfo) => void;
}

const MEASURED_POSITION_VALUE = 999;

const useHandleAddDocumentItem = ({
  clickInfo,
  filterDocumentCategoryOptions,
  users,
  currentUser,
  partnerCompanies,
  levels,
  handleClickDocumentCategory,
  handleUpdateClickForgeInfo,
}: iProps) => {
  const dispatch = useDispatch();
  const { systemMode, isCreateDocumentItem, levelSelected, familyInstances } =
    useSelector((state: RootState) => state.forgeViewer);
  const {
    documentCategorySelected,
    documentCategories,
    documentTemplates,
    documentItemSelected,
    documentItems,
  } = useSelector((state: RootState) => state.document);
  const { bimFileId } = useParams();
  const [isAddingDocumentItem, setIsAddingItem] = useState<boolean>(false);
  const { dataProjectDetail } = useSelector(
    (state: RootState) => state.project
  );
  const { areas: neptuneAreas } = useArea({ isHandleSideEffect: false });

  const isHighlightDocumentItemRef = useRef(false);
  const documentItemRef = useRef<DocumentItemDTO>();
  const documentCategorySelectedRef = useRef<DocumentCategoryDTO>();
  const dataBlackBoardDefaultRef = useRef<Partial<Blackboard> | undefined>();

  useEffect(() => {
    documentCategorySelectedRef.current = documentCategorySelected;
  }, [documentCategorySelected]);

  useEffect(() => {
    dataBlackBoardDefaultRef.current = getDataBlackboardDefault({
      levels: levels || [],
      currentUser: currentUser as any,
      users: users || [],
      partnerCompanies,
      currentLevel: documentCategorySelected?.level,
    });
  }, [
    documentCategorySelected?.level,
    currentUser,
    users,
    levels,
    partnerCompanies,
  ]);

  useEffect(() => {
    if (
      documentCategorySelected &&
      documentCategorySelected?.documentType ===
        DocumentTemplateType.SELF_INSPECTION
    ) {
      dispatch(setIsCreateDocumentItem(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentCategorySelected?.documentType]);

  useEffect(() => {
    if (isCreateDocumentItem) {
      dispatch(setIsCreateDocumentItem(false));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [levelSelected?.guid, dispatch, systemMode]);

  useEffect(() => {
    if (
      !clickInfo ||
      systemMode !== SystemModeType.Document ||
      !clickInfo.forgeData?.position ||
      !isCreateDocumentItem
    ) {
      return;
    }
    handleCreateDocumentItem();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clickInfo]);

  // highlight document item when created
  useLayoutEffect(() => {
    if (
      !documentItemSelected ||
      documentItemSelected.id !== documentItemRef.current?.id ||
      !isHighlightDocumentItemRef.current
    ) {
      return;
    }

    (async () => {
      await sleep(1);
      hightLightDocumentItem(documentItemSelected);
      isHighlightDocumentItemRef.current = false;
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentItemSelected?.id]);

  const showMessageObjectSelectedInvalid = useCallback(() => {
    return message.warning(
      "選択されたオブジェクトに既にピンが存在しているので新たにピンを追加できません。"
    );
  }, []);

  const getDocumentItemPayload = useCallback(
    async ({
      category,
      documentTemplate,
      familyInstance,
      isPhotoLedger,
      familyTitleOfDocumentItem,
      familyTitleOfDocumentSubItem,
      areaIds,
      documentItemDuplicateExternalId,
    }: {
      category: DocumentCategoryDTO;
      documentTemplate: DocumentTemplate;
      familyInstance: FamilyInstanceDTO;
      isPhotoLedger: boolean;
      familyTitleOfDocumentItem: TitleDisplay;
      familyTitleOfDocumentSubItem?: TitleDisplay;
      areaIds: string[];
      documentItemDuplicateExternalId?: DocumentItemDTO;
    }) => {
      const currentFamiliesOfTemplate = documentTemplate?.families || [];

      // handle document item title
      const isAddedFromForge =
        !familyInstance?.objectTypes?.every(
          (ob) => !!currentFamiliesOfTemplate.find((id) => ob.id === id)
        ) || !familyInstance?.objectTypes?.length;

      const documentItemTitle = getDocumentItemTitleByFamilyTitle({
        familyInstance,
        familyTitle: familyTitleOfDocumentItem,
      });

      let documentSubItemTitle = familyInstance?.symbol;
      if (familyTitleOfDocumentSubItem) {
        documentSubItemTitle = getDocumentItemTitleByFamilyTitle({
          familyInstance,
          familyTitle: familyTitleOfDocumentSubItem,
        });
      }

      // handle other field
      const blackboardTemplateId =
        documentTemplate?.blackboardTemplateDetail?.id;

      const body = {
        title: documentItemTitle,
        externalId: familyInstance.externalId,
        level: category.level,
        bimFileId,
        projectId: dataProjectDetail.projectId,
        systemNames: familyInstance?.systemName
          ? [familyInstance.systemName]
          : [],
        position: clickInfo?.forgeData?.position!,
        documentCategoryId: category.id,
        templateId: category.templateId,
        documentType: documentTemplate?.documentType,
        objectTypes:
          familyInstance?.objectTypes?.map((family) => family.id) || [],
        data: {},
        userCreated: currentUser?.id,
        status: DocumentCategoryStatusType.NotStarted,
        priority: Priority.High,
        displayOrder: category?.documentItems?.length,
        isAddedFromForge,
        areaIds,
      } as Partial<DocumentItem>;

      // create document item
      let documentItemData: DocumentItemDTO | undefined =
        documentItemDuplicateExternalId;
      const request: Promise<any>[] = [];
      if (!documentItemDuplicateExternalId) {
        request.push(
          documentItemApi.createItem(body as DocumentItem).then((res) => {
            documentItemData = res?.data;
          })
        );
      }

      // create blackboard for photo ledger
      let dataBlackBoard: Blackboard | undefined;
      const isCreateBlackboard =
        isPhotoLedger &&
        !!dataBlackBoardDefaultRef.current &&
        !!documentCategorySelectedRef.current?.id;
      if (isCreateBlackboard) {
        request.push(
          blackboardApi
            .createBlackboard({
              ...dataBlackBoardDefaultRef.current,
              documentCategoryId: documentCategorySelectedRef.current!.id,
            })
            .then((res) => {
              dataBlackBoard = res?.data;
            })
        );
      }

      await Promise.all(request);

      if (!documentItemData) {
        return;
      }

      // create sub item for photo ledger
      if (isPhotoLedger) {
        const bodySubItem: Partial<DocumentSubItemDTO> = {
          itemId: documentItemData.id,
          blackboardTemplateId: documentTemplate?.blackboardTemplateDetail?.id,
          isShowBlackboard: true,
          title: documentSubItemTitle,
          data: {},
          level: documentItemData?.level || "",
          templateId: documentItemData?.templateId || "",
          externalId: documentItemData?.externalId || "",
          bimFileId: documentItemData?.bimFileId || "",
          projectId: documentItemData?.projectId || "",
        };

        // linked blackboard to sub item
        if (dataBlackBoard?.id) {
          bodySubItem.blackboardId = dataBlackBoard.id;
          dispatch(setDataBlackboard(dataBlackBoard));
        }

        const res = await documentItemApi.createSubItem(
          bodySubItem as DocumentSubItem
        );

        if (!documentItemData?.subItems?.length) {
          documentItemData.subItems = [];
        }

        const subItem = res?.data;
        if (!documentItemData?.subItems?.length) {
          documentItemData.subItems = [];
        }

        if (subItem?.id) {
          documentItemData.subItems?.push(subItem as DocumentSubItemDTO);
        }

        documentItemData = {
          ...documentItemData,
          blackboardTemplateId,
          isShowBlackboard: true,
        };
      }

      return documentItemData;
    },
    [
      bimFileId,
      dataProjectDetail,
      clickInfo?.forgeData?.position,
      currentUser?.id,
      dispatch,
    ]
  );

  // handle add document item for sleeve pipe template
  const handleCreateDocumentItem = useCallback(async () => {
    const category = structuredClone(documentCategorySelectedRef.current);
    if (isAddingDocumentItem) {
      return;
    }

    try {
      setIsAddingItem(true);
      if (!category) {
        setIsAddingItem(false);

        return showMessageObjectSelectedInvalid();
      }
      let currentCategory = structuredClone(category);
      const documentTemplate = documentTemplates?.[currentCategory?.templateId];
      if (!documentTemplate) {
        setIsAddingItem(false);
        handleUpdateClickForgeInfo(undefined);

        return message.error(["ピンの追加", "テンプレートがありません。"], {
          duration: 2000,
        });
      }
      const position = clickInfo?.forgeData?.position as any;
      const spaceId = clickInfo?.areaData?.externalId;

      const dbId = clickInfo?.forgeData?.dbId;
      const externalId = dbId ? getExternalIdFromDbId(dbId) || uuid() : uuid();

      const documentItemsHidden = await documentItemApi.handleGetItemList({
        templateId: currentCategory.templateId,
        level: currentCategory.level,
        isDeleted: true,
      });
      let isRecoveryDocumentItemsData = false;
      const checkMersuredPosition = (n1: number, n2: number) => {
        const roundValue = (n: number) =>
          (Math.round(n * 1_000) / 1_000) * 1_000;

        return (
          Math.abs(roundValue(n1) - roundValue(n2)) <= MEASURED_POSITION_VALUE
        );
      };

      let documentItemsShouldRecoveryData = documentItemsHidden.filter(
        (item) => {
          const isEqualExternalId = item.externalId === externalId;
          const isEqualPosition =
            item.position &&
            position &&
            checkMersuredPosition(item.position?.x, position?.x) &&
            checkMersuredPosition(item.position?.y, position?.y) &&
            checkMersuredPosition(item.position?.z, position?.z);

          return isEqualExternalId || (!isEqualExternalId && isEqualPosition);
        }
      );

      if (documentItemsShouldRecoveryData.length) {
        isRecoveryDocumentItemsData = true;
      }

      let familyInstance: FamilyInstanceDTO | undefined =
        familyInstances[externalId || ""];

      if (!familyInstance || dbId === undefined) {
        familyInstance = {
          externalId,
        } as FamilyInstanceDTO;
      }

      const currentAreaIds = currentCategory?.neptuneAreaIds || [];
      const currentAreas = neptuneAreas.filter((area) =>
        currentAreaIds.includes(area.id)
      );
      const areaSelected = neptuneAreas.find(
        (item) =>
          item.externalIds.some((id) => spaceId === id) &&
          item.level === currentCategory.level
      );
      const areaIds = areaSelected ? [areaSelected.id] : [];

      const isInsideCurrentArea =
        currentAreas.some((area) =>
          area.externalIds.some((id) => spaceId === id)
        ) ||
        !!currentCategory?.allFloor ||
        (!spaceId && !!currentCategory.noArea);

      if (!isInsideCurrentArea) {
        setIsAddingItem(false);
        handleUpdateClickForgeInfo(undefined);

        return message.warning(
          "ピンは検査対象工区外であるため追加できません。"
        );
      }

      const isPhotoLedger =
        currentCategory.documentType === DocumentTemplateType.PHOTO_LEDGER;
      const documentItemsOfCategory = currentCategory?.documentItems || [];
      const documentItemDuplicateExternalId = documentItemsOfCategory.find(
        (i) => i.externalId === externalId
      );

      if (documentItemDuplicateExternalId) {
        setIsAddingItem(false);
        handleUpdateClickForgeInfo(undefined);

        return showMessageObjectSelectedInvalid();
      }

      if (!isPhotoLedger && documentItemsShouldRecoveryData?.length > 1) {
        const items = structuredClone(documentItemsShouldRecoveryData);
        documentItemsShouldRecoveryData = [
          items
            .sort((a, b) => (b.indexId || 0) - (a.indexId || 0))
            .shift() as DocumentItem,
        ];

        documentItemApi.deleteItemList(items.map((item) => item.id));
      }

      const currentFamiliesOfTemplate = documentTemplate?.families || [];

      // handle document item title
      const isAddedFromForge =
        !familyInstance?.objectTypes?.every(
          (ob) => !!currentFamiliesOfTemplate.find((id) => ob.id === id)
        ) || !familyInstance?.objectTypes?.length;
      let titleDisplayItem: TitleDisplay = {} as TitleDisplay;
      let titleDisplaySubItem: TitleDisplay = {} as TitleDisplay;

      if (isAddedFromForge) {
        const familyTitleDisplayForCustomDocumentItem: FamilyTitleDisplayForCustomDocumentItem =
          documentTemplate?.familyTitleDocItem || {};

        titleDisplayItem =
          familyTitleDisplayForCustomDocumentItem[
            FamilyTitleDisplayType.DocumentItem
          ];
        if (isPhotoLedgerTemplate(category.documentType)) {
          titleDisplayItem =
            familyTitleDisplayForCustomDocumentItem[
              FamilyTitleDisplayType.SubCategory
            ];
          titleDisplaySubItem =
            familyTitleDisplayForCustomDocumentItem[
              FamilyTitleDisplayType.DocumentItem
            ];
        }
      }

      if (!isAddedFromForge) {
        const mapObjectTypesById = arrayToObject(
          familyInstance?.objectTypes || [],
          "id"
        );
        let displayTypeItem =
          (documentTemplate?.familyTitle || {})?.[
            FamilyTitleDisplayType.DocumentItem
          ] || {};
        let objectTypeItem = Object.keys(displayTypeItem).filter(
          (key) => !!mapObjectTypesById?.[key]
        );

        titleDisplayItem = displayTypeItem?.[objectTypeItem?.[0] || ""];

        if (isPhotoLedgerTemplate(category.documentType)) {
          displayTypeItem =
            (documentTemplate?.familyTitle || {})?.[
              FamilyTitleDisplayType.SubCategory
            ] || {};
          const displayTypeSubItem =
            (documentTemplate?.familyTitle || {})?.[
              FamilyTitleDisplayType.DocumentItem
            ] || {};
          objectTypeItem = Object.keys(displayTypeItem).filter(
            (key) => !!mapObjectTypesById?.[key]
          );
          const objectTypeSubItem = Object.keys(displayTypeSubItem).filter(
            (key) => !!mapObjectTypesById?.[key]
          );

          titleDisplayItem = displayTypeItem?.[objectTypeItem?.[0] || ""];
          titleDisplaySubItem =
            displayTypeSubItem?.[objectTypeSubItem?.[0] || ""];
        }
      }

      let documentItemEps: DocumentItem[] = [];
      if (isRecoveryDocumentItemsData) {
        documentItemEps = await Promise.all(
          documentItemsShouldRecoveryData.map(async (item) => {
            const { data } = await documentItemApi.updateItem({
              id: item.id,
              isShowBlackboard: item?.isShowBlackboard ?? true,
              isRevertData: true,
              isRevertSubItem: isPhotoLedgerTemplate(item?.documentType),
            });

            return {
              ...data,
              ...item,
            } as DocumentItemDTO;
          })
        );
      }

      if (!isRecoveryDocumentItemsData) {
        const documentItemPayload = await getDocumentItemPayload({
          category: currentCategory,
          documentTemplate,
          familyInstance,
          isPhotoLedger,
          familyTitleOfDocumentItem: titleDisplayItem,
          familyTitleOfDocumentSubItem: titleDisplaySubItem,
          areaIds,
          documentItemDuplicateExternalId,
        });

        documentItemEps = documentItemPayload ? [documentItemPayload] : [];
      }

      if (!documentItemEps.length) {
        setIsAddingItem(false);

        return message.error(["ピンの追加", "ピンの追加に失敗しました。"], {
          duration: 2000,
        });
      }

      const templateDocumentItems = documentItems.filter(
        (item) => item.templateId === currentCategory.templateId
        // && item.id !== documentItemDuplicateExternalId?.id
      );
      const templateCategories = documentCategories.filter(
        (item) =>
          item.templateId === currentCategory.templateId &&
          item.level === currentCategory.level
      );

      const documentItemIds = documentItemEps.map((item) => item.id);
      const allDocumentItems = structuredClone([
        ...(templateDocumentItems || []),
        ...documentItemEps,
      ]);

      const documentIds: string[] = [];
      documentItemEps.forEach((item) => {
        if (item.id) {
          documentIds.push(item.id);
        }
      });

      const mapTemplateCategoriesById = arrayToObject(
        templateCategories,
        DocumentCategoryKey.ID
      );
      const allDocumentCategories = (templateCategories || []).map(
        (category) => {
          const selectedExternalIds = [
            ...new Set([
              ...(category.selectedExternalIds || []),
              ...documentIds,
            ]),
          ];
          const itemIds = [
            ...new Set([...(category?.itemIds || []), ...documentIds]),
          ];

          return {
            ...category,
            itemIds,
            selectedExternalIds,
          };
        }
      );

      const {
        documentCategories: newDocumentCategories,
        documentItems: newDocumentItems,
      } = doMapDocumentCategories({
        documentCategories: allDocumentCategories,
        documentItems: allDocumentItems,
        familyInstances,
        documentTemplates: [documentTemplate],
      });

      Promise.all(
        newDocumentCategories.map((category) => {
          return documentCategoryApi.updateCategory(
            transformBodyForCombineData<DocumentCategoryDTO>({
              body: {
                id: category.id,
                itemIds: category.itemIds,
                selectedExternalIds: category.selectedExternalIds,
              } as DocumentCategoryDTO,
              bodyBefore: mapTemplateCategoriesById[category.id],
              typeInitData: TypeHandleInitData.DOCUMENT_CATEGORY,
            })
          );
        })
      );

      dispatch(
        updateDocumentInfoOfTemplate({
          documentCategories: newDocumentCategories,
          documentItems: newDocumentItems,
        })
      );
      const lengthDocumentItems = currentCategory?.documentItems?.length;
      currentCategory = newDocumentCategories?.find(
        (item) => item.id === currentCategory.id
      )!;

      const documentItemShouldSelected = currentCategory?.documentItems?.find(
        (item) => item.id === documentItemEps?.[0]?.id
      );

      documentItemRef.current = documentItemShouldSelected;

      const settings =
        filterDocumentCategoryOptions?.settings || ({} as UserSetting);
      const searchDocumentValue =
        filterDocumentCategoryOptions?.searchDocumentValue || "";
      const areas = filterDocumentCategoryOptions?.areas || [];

      if (
        isPhotoLedgerTemplate(currentCategory.documentType) &&
        currentCategory?.documentItems?.length === lengthDocumentItems
      ) {
        message.success(["写真の追加", "写真を追加しました。"], {
          duration: 2000,
        });
      } else {
        message.success(["ピンの追加", "ピンを追加しました。"], {
          duration: 2000,
        });
      }

      // recovery task
      if (isRecoveryDocumentItemsData) {
        dispatch(
          updateTaskByDocumentItemId({
            documentItemIds,
            data: { isDeleteDocItem: false },
          })
        );
      }

      dispatch(resetState());
      dispatch(setDocumentSubCategorySelected());

      const documentItemsFilter = filterDocumentItemsByUserSettting({
        documentItems: documentItemEps,
        documentCategory: currentCategory,
        areas,
        settings,
        searchDocumentValue,
        documentTemplates,
        documentCategories: [currentCategory],
        isIgnoreSearchDocument: !!currentCategory.templateParentId,
      });
      if (!documentItemsFilter?.length) {
        setIsAddingItem(false);
        handleUpdateClickForgeInfo(undefined);

        return;
      }
      isHighlightDocumentItemRef.current = true;
      dispatch(setDocumentItemSelected(documentItemShouldSelected));
      dispatch(setModalType(ModalType.DOC_ITEM));
      setIsAddingItem(false);
      handleUpdateClickForgeInfo(undefined);
    } catch (e) {
      setIsAddingItem(false);
      handleUpdateClickForgeInfo(undefined);
      isHighlightDocumentItemRef.current = false;
    }
  }, [
    isAddingDocumentItem,
    documentTemplates,
    familyInstances,
    neptuneAreas,
    documentCategories,
    documentItems,
    clickInfo,
    filterDocumentCategoryOptions,
    dispatch,
    handleUpdateClickForgeInfo,
    showMessageObjectSelectedInvalid,
    getDocumentItemPayload,
  ]);

  const onClickAddDocumentItem = useCallback(
    (
      e: React.MouseEvent<HTMLDivElement>,
      documentCategory: DocumentCategoryDTO
    ) => {
      e.stopPropagation();
      e.preventDefault();
      const state = store.getState();
      const { isCreateDocumentItem } = state.forgeViewer;
      const {
        documentItemSelected,
        documentCategorySelected,
        documentGroupSelected,
      } = state.document;
      const isDiffDocumentCategory =
        documentCategorySelected?.id !== documentCategory.id;

      if (documentItemSelected?.id) {
        dispatch(resetState());
        dispatch(setDocumentItemSelected());
        getLabelExtension()?.setSelectedItems([]);
        dispatch(setModalType(ModalType.DOC_CATEGORY));
        if (!isDiffDocumentCategory) {
          updateForgeWhenSelectCategory(documentCategory);
        }
      }

      if (isDiffDocumentCategory) {
        dispatch(setIsCreateDocumentItem(true));
        handleClickDocumentCategory(documentCategory);
      } else {
        dispatch(setIsCreateDocumentItem(!isCreateDocumentItem));
      }
      if (documentGroupSelected) {
        dispatch(setDocumentGroupSelected());
      }

      if (
        isPhotoLedgerTemplate(documentCategory?.documentType) &&
        isDiffDocumentCategory
      ) {
        dispatch(fetchBlackBoardByCategoryId(documentCategory.id));
      }
    },
    [dispatch, handleClickDocumentCategory]
  );

  const onCloseAddDocumentItem = useCallback(() => {
    dispatch(setIsCreateDocumentItem(false));
  }, [dispatch]);

  return {
    onClickAddDocumentItem,
    isAddingDocumentItem,
    onCloseAddDocumentItem,
    isCreateDocumentItem,
  };
};

export default useHandleAddDocumentItem;
