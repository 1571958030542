import { FamilyInstanceDTO } from "interfaces/dtos/familyInstance";
import { Space } from "interfaces/models/area";
import { removeEmptyProp } from "utils/common";
import {
  getLeafFragIds,
  getViewableProperties,
  transformLevelByAecDataAndDerivative,
  ___mapDbId,
} from ".";
import { AreaMesh, getAreaExtension } from "./extensions/area-extension";

export let ___viewer3d: Autodesk.Viewing.GuiViewer3D | undefined;
export const setViewer3d = (viewer?: any) => {
  ___viewer3d = viewer;
};

export const find3DBounds = (
  fragList: any,
  instanceTree: any,
  dbId: number
) => {
  const bounds = new THREE.Box3();
  instanceTree.enumNodeFragments(
    dbId,
    (fragId: number) => {
      const box = new THREE.Box3();
      fragList.getWorldBounds(fragId, box);
      bounds.union(box);
    },
    true
  );

  return bounds;
};

export const find3dPosition = (dbId: number) => {
  const viewer = ___viewer3d;
  if (!viewer) {
    return;
  }
  const fragList = viewer.model.getFragmentList();
  const instanceTree = viewer.model.getInstanceTree();
  if (!fragList || !instanceTree) {
    return;
  }

  return find3DBounds(fragList, instanceTree, dbId).getCenter();
};

export const checkPointInsideMesh = (
  point: THREE.Vector3,
  mesh: THREE.Mesh
) => {
  const raycaster = new THREE.Raycaster();
  raycaster.set(point, new THREE.Vector3(0, 0, -1));
  const intersects = raycaster.intersectObject(mesh);

  return intersects.length % 2 === 1;
};

export const getFamilyInstancesProperties = async ({
  viewer,
  bimFileId,
  version,
  versionId,
  projectId,
}: {
  viewer: Autodesk.Viewing.GuiViewer3D;
  bimFileId: string;
  version: string;
  versionId: string;
  projectId: string;
}) => {
  const instanceTree = viewer.model.getData().instanceTree;
  const fragList = viewer.model.getFragmentList();
  const familyInstances: { [key: string]: FamilyInstanceDTO } = {};
  const { levels: levelsData, levelsAceFilter } =
    await transformLevelByAecDataAndDerivative({
      projectId,
      versionId,
    });

  const transformNumberValue = (value?: string) => {
    const numberString = value?.replace(/[^0-9.]/g, "");
    if (!numberString) {
      return "";
    }

    return `${isNaN(Number(numberString)) ? "" : Number(numberString)}`;
  };
  const spaces = (await getAreaExtension()?.getSpaces()) || [];

  await Promise.all(
    levelsAceFilter.map(async (level) => {
      const familyInstancePropertiesRes = await getViewableProperties({
        bimFileId,
        version,
        level: {
          guid: level.guid,
          name: level.name,
        },
      });
      const familyInstanceProperties = familyInstancePropertiesRes.data?.data;

      familyInstanceProperties.forEach((item) => {
        const properties = item.properties;
        const name = item?.name;
        const externalId = ___mapDbId[item.dbId];

        if (Object.keys(properties)?.length && name && externalId) {
          const bounds = find3DBounds(fragList, instanceTree, item.dbId);
          const size = bounds.size();

          if (!size.x && !size.y && !size.z) {
            return;
          }

          const position = bounds.getCenter();
          const spaceIds = spaces
            .filter(
              (space: AreaMesh) =>
                !!space && !!checkPointInsideMesh(position, space)
            )
            .map((space) => space.externalId);

          const level =
            properties["Level"] ||
            properties["Reference Level"] ||
            levelsData.find(
              (level) =>
                Number(level.zMin) <= position.z &&
                position.z <= Number(level.zMax)
            )?.label ||
            "";

          familyInstances[externalId] = {
            bounds,
            dbId: item.dbId,
            externalId: externalId,
            name,
            position,
            typeName: properties["Type Name"] || properties["タイプ名"],
            symbol: (properties["記号"] || "").toString(),
            level,
            systemName: properties["System Name"],
            systemType: properties["System Type"],
            fanType: properties["ファンの種類"],
            designOption:
              properties["Design Option"] || properties["デザイン オプション"],
            sign: properties["符号"],
            estimateConstructionCategory: properties["積算_施工区分"],
            form: properties["形式"],
            size: properties["Size"] || properties["サイズ"],
            diameterRadius: properties["ダクト径_半径"],
            airVolume: transformNumberValue(properties["風量"]),
            openingRate: transformNumberValue(properties["開口率"]),
            faceWindSpeed: transformNumberValue(properties["面風速"]),
            objectTypes: [],
            spaceIds,
          };
          removeEmptyProp(familyInstances[externalId]);
        }
      });
    })
  );

  return familyInstances;
};

export const getComponentGeometryInfo = (dbId: number) => {
  const viewer = ___viewer3d!;
  const viewerImpl = viewer.impl;
  const model = viewer.model;
  const fragIds = getLeafFragIds(model, dbId);
  let matrixWorld: any = null;

  const meshes = fragIds.map((fragId) => {
    const renderProxy = viewerImpl.getRenderProxy(model, fragId);

    const geometry = renderProxy.geometry;
    const attributes = geometry.attributes;
    const positions = geometry.vb ? geometry.vb : attributes.position.array;

    const indices = attributes.index.array || geometry.ib;
    const stride = geometry.vb ? geometry.vbstride : 3;
    const offsets = geometry.offsets;

    matrixWorld = matrixWorld || renderProxy.matrixWorld.elements;

    return {
      positions,
      indices,
      offsets,
      stride,
    };
  });

  return {
    matrixWorld,
    meshes,
  };
};

export const getComponentGeometry = (data: any, vertexArray: any) => {
  const offsets = [
    {
      count: data.indices.length,
      index: 0,
      start: 0,
    },
  ];

  for (let oi = 0, ol = offsets.length; oi < ol; ++oi) {
    const start = offsets[oi].start;
    const count = offsets[oi].count;
    const index = offsets[oi].index;

    for (let i = start, il = start + count; i < il; i += 3) {
      const a = index + data.indices[i];
      const b = index + data.indices[i + 1];
      const c = index + data.indices[i + 2];

      const vA = new THREE.Vector3();
      const vB = new THREE.Vector3();
      const vC = new THREE.Vector3();

      vA.fromArray(data.positions, a * data.stride);
      vB.fromArray(data.positions, b * data.stride);
      vC.fromArray(data.positions, c * data.stride);

      vertexArray.push(vA);
      vertexArray.push(vB);
      vertexArray.push(vC);
    }
  }
};

export const buildComponentMesh = (data: any) => {
  const vertexArray: any[] = [];

  for (let idx = 0; idx < data.nbMeshes; ++idx) {
    const meshData = {
      positions: data[`positions${idx}`],
      indices: data[`indices${idx}`],
      stride: data[`stride${idx}`],
    };

    getComponentGeometry(meshData, vertexArray);
  }

  const geometry = new THREE.Geometry();

  for (let i = 0; i < vertexArray.length; i += 3) {
    geometry.vertices.push(vertexArray[i]);
    geometry.vertices.push(vertexArray[i + 1]);
    geometry.vertices.push(vertexArray[i + 2]);

    const face = new THREE.Face3(i, i + 1, i + 2);
    geometry.faces.push(face);
  }

  const matrixWorld = new THREE.Matrix4();
  (matrixWorld as any).fromArray(data.matrixWorld);

  const mesh = new THREE.Mesh(geometry) as any;
  mesh.applyMatrix(matrixWorld);
  mesh.boundingBox = data.boundingBox;
  mesh.dbId = data.dbId;

  return mesh;
};

const buildMesh = (dbId: number, model: Autodesk.Viewing.Model) => {
  const geometry = getComponentGeometryInfo(dbId);
  const data: any = {
    boundingBox: find3DBounds(
      model.getFragmentList(),
      model.getInstanceTree(),
      dbId
    ),
    matrixWorld: geometry.matrixWorld,
    nbMeshes: geometry.meshes.length,
    dbId,
  };

  geometry.meshes.forEach((mesh, idx) => {
    data[`positions${idx}`] = mesh.positions;
    data[`indices${idx}`] = mesh.indices;
    data[`stride${idx}`] = mesh.stride;
  });

  return buildComponentMesh(data);
};

// only work on PC
export const getSpaces = async (model?: Autodesk.Viewing.Model) => {
  if (!model) model = ___viewer3d?.model;
  if (!model) return [];
  const instanceTree = model.getData().instanceTree;
  if (!instanceTree) {
    return [];
  }
  const allDbIds = Object.keys(instanceTree.nodeAccess?.dbIdToIndex || {}).map(
    function (id) {
      return parseInt(id);
    }
  );

  const spaces: Promise<Space>[] = [];
  allDbIds.forEach((dbId) => {
    const nodeName = instanceTree.getNodeName(dbId);

    if (["Spaces", "スペース"].includes(nodeName)) {
      instanceTree.enumNodeChildren(dbId, (id: any) => {
        if (typeof id === "number") {
          spaces.push(
            new Promise((resolve, reject) => {
              model!.getProperties(
                id,
                (data) => {
                  const title = data.name?.slice(0, data.name.indexOf("["));
                  const level = data.properties.find(
                    (prop) =>
                      ["Level", "レベル"].includes(prop.displayName) &&
                      ["拘束", "Constraints"].includes(prop.displayCategory)
                  )?.displayValue;

                  const mesh = (buildMesh(id, model!) as THREE.Mesh).toJSON();
                  mesh.dbId = id;
                  resolve({
                    externalId: data.externalId,
                    title,
                    level: level,
                    mesh,
                  } as Space);
                },
                (err) => reject(err)
              );
            })
          );
        }
      });
    }
  });

  return await Promise.all(spaces).then((res) =>
    res.filter((res) => !!res.level)
  );
};
