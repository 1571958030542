import { useCallback, useEffect, useMemo, useState } from "react";

interface iProps {
  totalPage: number;
  taskSheetsRef: React.MutableRefObject<(HTMLDivElement | null)[]>;
  taskSheetContainerRef: React.MutableRefObject<HTMLDivElement>;
}

const HEADER_HEIGHT = 130;

const useScrollTaskSheetPage = ({
  totalPage,
  taskSheetsRef,
  taskSheetContainerRef,
}: iProps) => {
  const [currentPage, setCurrentPage] = useState<number>(0);

  const taskSheetContainer = useMemo(
    () => taskSheetContainerRef.current,

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [taskSheetContainerRef.current]
  );

  const handleScrollTaskSheetContainer = useCallback(() => {
    taskSheetsRef.current.forEach((_, k) => {
      const element = taskSheetsRef?.current[k];
      if (element) {
        const elementRect = taskSheetsRef.current[k]?.getBoundingClientRect();
        const relativePos = {
          top:
            taskSheetContainer.getBoundingClientRect().top -
              elementRect?.top! || 0,
        };
        const isElementInViewport =
          relativePos.top + HEADER_HEIGHT > 0 &&
          Math.abs(relativePos.top) + HEADER_HEIGHT < element.clientHeight;
        if (isElementInViewport) {
          setCurrentPage(k);
        }
      }
    });
  }, [taskSheetsRef, taskSheetContainer]);

  useEffect(() => {
    if (taskSheetContainer) {
      taskSheetContainer.addEventListener(
        "scroll",
        handleScrollTaskSheetContainer
      );

      return () => {
        taskSheetContainer.removeEventListener(
          "scroll",
          handleScrollTaskSheetContainer
        );
      };
    }
  }, [taskSheetContainer, handleScrollTaskSheetContainer]);

  const smoothScrollTo = useCallback(
    (container: HTMLElement, offset: number, callback: () => void) => {
      const onScroll = () => {
        const scrollTop = Math.round(container.scrollTop);
        const clientHeight = Math.round(container.clientHeight);
        const scrollHeight = Math.round(container.scrollHeight);

        if (scrollTop === offset || scrollTop + clientHeight === scrollHeight) {
          container.removeEventListener("scroll", onScroll);
          callback();
        }
      };
      container.addEventListener("scroll", onScroll);
      onScroll();
      container.scrollTo({
        top: offset,
        behavior: "auto",
      });
    },
    []
  );

  const onPrevPage = useCallback(() => {
    const offset =
      currentPage - 1 === 0
        ? 0
        : taskSheetsRef.current
            .filter((_, k) => k <= currentPage - 2)
            .reduce(
              (acc, curr) =>
                acc + Math.round(curr?.getBoundingClientRect()?.height! || 0),
              0
            );

    smoothScrollTo(taskSheetContainer, offset, () => {
      setCurrentPage(currentPage - 1);
    });
  }, [currentPage, smoothScrollTo, taskSheetsRef, taskSheetContainer]);

  const onNextPage = useCallback(() => {
    const offset = taskSheetsRef.current
      .filter((_, k) => k <= currentPage)
      .reduce(
        (acc, curr) =>
          acc + Math.round(curr?.getBoundingClientRect()?.height! || 0),
        0
      );

    smoothScrollTo(taskSheetContainer, offset, () => {
      setCurrentPage(currentPage + 1);
    });
  }, [smoothScrollTo, taskSheetsRef, currentPage, taskSheetContainer]);

  const onBlurPage = useCallback(() => {
    const page =
      !currentPage || currentPage < 0
        ? 0
        : Math.min(currentPage, totalPage - 1);

    const offset = !page
      ? 0
      : taskSheetsRef.current
          .filter((_, k) => k <= page - 1)
          .reduce(
            (acc, curr) =>
              acc + Math.round(curr?.getBoundingClientRect()?.height! || 0),
            0
          );

    smoothScrollTo(taskSheetContainer, offset, () => {
      setCurrentPage(!currentPage || currentPage < 0 ? 0 : page);
    });
  }, [
    totalPage,
    currentPage,
    taskSheetContainer,
    smoothScrollTo,
    taskSheetsRef,
  ]);

  const onChangePage = useCallback(
    (valueAsString: string, valueAsNumber: number) => {
      setCurrentPage(valueAsNumber - 1);
    },
    []
  );

  return {
    currentPage,
    onBlurPage,
    onPrevPage,
    onNextPage,
    onChangePage,
  };
};

export default useScrollTaskSheetPage;
