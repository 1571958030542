import { DeleteIcon } from "@chakra-ui/icons";
import {
  Button,
  Checkbox,
  Flex,
  IconButton,
  Text,
  useBoolean,
} from "@chakra-ui/react";
import { documentTemplateApi } from "apiClient/v2";
import { CACHE_DOCUMENT_CATEGORY_SELECTED_KEY } from "constants/cache";
import {
  CellDisplayValueType,
  DocumentCategoryStatusType,
  DocumentTemplateType,
  FamilyTitleDisplayType,
  MapDocumentCategoryStatusTypeColor,
  ModalType,
  SystemModeType,
} from "constants/enum";
import { DocumentItemDTO } from "interfaces/dtos/documentItemDTO";
import { FamilyInstanceDTO } from "interfaces/dtos/familyInstance";
import { Vector3 } from "interfaces/models";
import { isEmpty } from "lodash";
import { getUniqDocumentItemStatusFromSubCategory } from "models/document";
import ButtonPlus from "pages/document/template-page/ButtonPlus";
import CustomMenu from "pages/document/template-page/CustomMenu";
import useHandleEditFamily from "pages/forge-viewer/hooks/useHandleEditFamily";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
  useWatch,
} from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  clearState,
  setDefaultDocumentTemplates,
  setDocumentTemplates,
} from "redux/documentSlice";
import type { RootState } from "redux/store";
import { hightLightDocumentItem } from "utils/documentItem";
import {
  fitToViewByPositions,
  getCurrentViewer,
  selectDbIds,
} from "utils/forge";
import {
  CustomLabelExtension,
  DisplayItem,
} from "utils/forge/extensions/custom-label";
import ConfirmModal from "./ConfirmDeleteModal";

interface FormValues {
  families: {
    family?: string;
  }[];
}

const FamilyEditModal = ({
  setClickedLabelInfo,
}: {
  setClickedLabelInfo?: (data: any | undefined) => void;
}) => {
  const [isDeleteAllData, setIsDeleteAllData] = useBoolean();
  const [isShowConfirmDeleteData, setIsShowConfirmDeleteData] = useBoolean();
  const [isLoadingConfirmDeleteData, setIsLoadingConfirmDeleteData] =
    useBoolean();
  const [messageConfirmDeleteData, setMessageConfirmDeleteData] = useState("");
  const [messageConfirmDeleteAllData, setMessageConfirmDeleteAllData] =
    useState("");

  const dispatch = useDispatch();

  const { documentItems, documentItemSelected, documentSubCategorySelected } =
    useSelector((state: RootState) => state.document);

  const { modalType, familyInstances, families } = useSelector(
    (state: RootState) => state.forgeViewer
  );

  const [
    { documentCategory, currentTemplate },
    { toggle: toggleEditingFamily },
  ] = useHandleEditFamily();

  const isSubmittedRef = useRef(false);
  const isCheckPersistData = useRef(true);

  const labelExtension = useMemo(() => {
    const viewer = getCurrentViewer();

    return viewer?.getExtension("CustomLabelExtension") as
      | CustomLabelExtension
      | undefined;
  }, []);

  const documentFamilies = useMemo(() => {
    return families.map((family) => ({
      name: family.name,
      value: family.id,
    }));
  }, [families]);

  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting, isValidating, isDirty },
  } = useForm<FormValues>({
    defaultValues: {
      families: ((currentTemplate?.families ?? []) as string[]).map(
        (value) => ({
          family: value,
        })
      ),
    },
  });

  const familyFieldArray = useFieldArray({
    name: "families",
    control,
  });

  const allFamilies = useWatch({
    control,
    name: "families",
  });

  const { temporaryFamilyInstances, currentFamilyInstances } = useMemo(() => {
    const familyIds = allFamilies.map((item) => item.family);
    const currentFamilyIds = currentTemplate?.families || [];

    const temporaryFamilyInstances = Object.values(familyInstances).filter(
      (item) => {
        if (item.level !== documentCategory?.level) {
          return false;
        }

        return item.objectTypes.some((type) => familyIds.includes(type.id));
      }
    );

    const currentFamilyInstances = Object.values(familyInstances).filter(
      (item) => {
        if (item.level !== documentCategory?.level) {
          return false;
        }

        return item.objectTypes.some((type) =>
          currentFamilyIds.includes(type.id)
        );
      }
    );

    return { temporaryFamilyInstances, currentFamilyInstances };
  }, [
    currentTemplate?.families,
    allFamilies,
    documentCategory?.level,
    familyInstances,
  ]);

  const { externalIds, labelItems, currentlabelItems } = useMemo<{
    externalIds: string[];
    labelItems: DisplayItem[];
    currentlabelItems: DisplayItem[];
  }>(() => {
    if (!documentCategory) {
      return { externalIds: [], labelItems: [], currentlabelItems: [] };
    }

    const generateLabelItems = (familyInstances: FamilyInstanceDTO[]) => {
      return familyInstances.map((instance, index) => {
        const currentDocItem: DocumentItemDTO | undefined = documentItems.find(
          (item) =>
            item.templateId === documentCategory.templateId &&
            item.level === documentCategory.level &&
            documentCategory.areaId &&
            item.areaIds?.includes(documentCategory.areaId!) &&
            item.externalId === instance.externalId
        );
        if (!!currentDocItem) {
          return {
            id: `${documentCategory.id}/${currentDocItem.externalId}`,
            position: currentDocItem.position!,
            title: documentCategory.title || "-",
            subTitle: currentDocItem.title || "-",
            displayOrder: index,
            status: DocumentCategoryStatusType.NotStarted,
            externalId: currentDocItem.externalId,
            templateId: currentDocItem.templateId,
          } as DisplayItem;
        }

        return {
          id: `${documentCategory.id}/${instance.externalId}`,
          position: instance.position!,
          title: documentCategory?.title || "-",
          subTitle: instance.symbol || "-",
          displayOrder: index,
          status: DocumentCategoryStatusType.NotStarted,
          externalId: instance.externalId,
          documentCategoryId: documentCategory.id,
        };
      });
    };

    const labelItems: DisplayItem[] = generateLabelItems(
      temporaryFamilyInstances
    );
    const currentlabelItems: DisplayItem[] = generateLabelItems(
      currentFamilyInstances
    );

    const externalIds = labelItems.map((item) => item.externalId!);

    return { externalIds, labelItems, currentlabelItems } as const;
  }, [
    documentCategory,
    temporaryFamilyInstances,
    currentFamilyInstances,
    documentItems,
  ]);

  const onSubmit: SubmitHandler<FormValues> = async (values) => {
    if (!isDirty) {
      toggleEditingFamily(false);

      return;
    }
    if (isSubmitting || isValidating) {
      return;
    }
    const familyTitleDisplayTypes =
      currentTemplate?.documentType === DocumentTemplateType.PHOTO_LEDGER
        ? [
            FamilyTitleDisplayType["DocumentItem"],
            FamilyTitleDisplayType["SubCategory"],
          ]
        : [FamilyTitleDisplayType["DocumentItem"]];

    const familyTitleDisplay: Record<
      FamilyTitleDisplayType,
      Record<string, { type: CellDisplayValueType }>
    > = {} as any;

    for (const { family } of values.families) {
      familyTitleDisplayTypes.forEach((type) => {
        familyTitleDisplay[type] = {
          ...familyTitleDisplay[type],
          [family as string]: {
            type: CellDisplayValueType.DEVICE_NUMBER,
          },
        };
      });
    }

    if (!currentTemplate) {
      return;
    }
    const currentFamilies = currentTemplate.families;
    const newFamilies = values.families.map((item) => item.family ?? "");

    const familyEdited: string[] = [];
    currentFamilies?.forEach((family) => {
      if (!newFamilies.includes(family)) {
        familyEdited.push(family);
      }
    });
    const isShowMessagePersistData = !!familyEdited.length;
    if (isShowMessagePersistData && isCheckPersistData.current) {
      setIsShowConfirmDeleteData.on();
      const familiesName = families
        .filter((family) => familyEdited.includes(family.id))
        .map((item) => item.name)
        .join("、");
      setMessageConfirmDeleteData(`${familiesName}のピンを削除します。`);
      setMessageConfirmDeleteAllData(
        `${familiesName}のデータの入っているピンも削除する`
      );

      return;
    }

    // Fix view
    const viewer = getCurrentViewer();
    viewer?.utilities.goHome();

    await documentTemplateApi.updateTemplate({
      ...currentTemplate,
      families: newFamilies,
      familyTitle: familyTitleDisplay,
      isDeleteAllData,
    });

    const [documentTemplates, defaultDocumentTemplates] = await Promise.all([
      documentTemplateApi.handleGetTemplateList({
        bimFileId: documentCategory?.bimFileId,
      }),
      documentTemplateApi.handleGetTemplateList({ isDefaultTemplate: true }),
    ]);

    isSubmittedRef.current = true;
    setIsDeleteAllData.off();
    dispatch(clearState());
    dispatch(setDocumentTemplates(documentTemplates));
    dispatch(setDefaultDocumentTemplates(defaultDocumentTemplates));

    localStorage.setItem(
      CACHE_DOCUMENT_CATEGORY_SELECTED_KEY,
      documentCategory?.id || ""
    );
  };

  const handleShowLabels = () => {
    labelExtension?.showLabels({
      mode: SystemModeType.Document,
      data: labelItems,
      options: {
        forceUpdate: true,
      },
    });

    selectDbIds(externalIds, {
      color:
        MapDocumentCategoryStatusTypeColor[
          DocumentCategoryStatusType.NotStarted
        ],
    });

    fitToViewByPositions(
      (labelItems.map((item) => ("position" in item ? item.position : [])) ||
        []) as Vector3[],
      false
    );
  };

  useEffect(() => {
    handleShowLabels();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allFamilies]);

  useEffect(() => {
    return () => {
      if (false === isSubmitting) {
        selectDbIds(
          documentCategory?.documentItems?.map((item) => item.externalId || ""),
          {
            color:
              MapDocumentCategoryStatusTypeColor[
                (documentCategory?.status ||
                  DocumentCategoryStatusType.NotStarted) as DocumentCategoryStatusType
              ],
          }
        );

        if (modalType === ModalType.DOC_CATEGORY) {
          fitToViewByPositions(
            (documentCategory?.documentItems ?? []).map(
              (item) => item.position
            ) as Vector3[],
            true
          );
        }

        if (!isEmpty(documentItemSelected)) {
          hightLightDocumentItem(documentItemSelected as DocumentItemDTO);
        }

        if (!isEmpty(documentSubCategorySelected)) {
          const externalIds = documentSubCategorySelected?.documentItems?.map(
            (item) => item?.externalId || ""
          );
          selectDbIds(externalIds, {
            color:
              MapDocumentCategoryStatusTypeColor[
                getUniqDocumentItemStatusFromSubCategory(
                  documentSubCategorySelected
                )
              ],
          });
        }
      }
    };
  }, [
    dispatch,
    documentCategory?.documentItems,
    documentCategory?.status,
    isSubmitting,
    modalType,
    documentItemSelected,
    documentSubCategorySelected,
  ]);

  const handleProcessPersistData = async () => {
    setIsLoadingConfirmDeleteData.on();
    isCheckPersistData.current = false;
    await onSubmit(getValues());
    setIsLoadingConfirmDeleteData.on();
    setIsShowConfirmDeleteData.off();
    isCheckPersistData.current = true;
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="family-edit-form"
        noValidate
      >
        <Flex justifyContent="space-between" alignItems="center" flexShrink={0}>
          <Text fontSize="1.4rem" fontWeight={700}>
            検査対象抽出条件を選択
          </Text>
          <ButtonPlus
            p={2}
            size="xs"
            isDisabled={false}
            onClick={() => {
              const options = documentFamilies.filter(({ value }) => {
                return (
                  false ===
                  getValues("families")
                    .map((item) => item.family)
                    .includes(value)
                );
              });

              familyFieldArray.append({
                family: options[0].value,
              });
            }}
          />
        </Flex>
        <Flex
          mt={8}
          gap={5}
          flexDirection="column"
          flexGrow={1}
          overflowY="auto"
        >
          {familyFieldArray.fields.map((field, index) => {
            return (
              <Flex key={field.id} w="100%" gap="10px" alignItems="center">
                <Controller
                  name={`families.${index}.family` as const}
                  control={control}
                  rules={{
                    required: true,
                    validate: {
                      inUsed(value) {
                        const familyGroup = getValues("families")
                          .map((item) => item.family)
                          .reduce((acc, cur) => {
                            if (cur) {
                              return {
                                ...acc,
                                [cur]: (acc[cur] ?? 0) + 1,
                              };
                            }

                            return acc;
                          }, {} as any);

                        return !value || familyGroup[value] <= 1;
                      },
                    },
                  }}
                  render={({ field: { ref, ...field } }) => {
                    const families = allFamilies.map((item) => item.family);
                    const options = documentFamilies.filter(({ value }) => {
                      if (field.value === value) {
                        return true;
                      }

                      return !families.includes(value);
                    });

                    return (
                      <CustomMenu
                        {...field}
                        height="4rem"
                        flexGrow={4}
                        options={options}
                        border={`1px solid ${
                          errors.families?.[index] ? "red" : "#A3A3A3"
                        }`}
                        color="#171717"
                      />
                    );
                  }}
                />
                <IconButton
                  variant="transparent"
                  aria-label="remove"
                  onClick={() => {
                    familyFieldArray.remove(index);
                  }}
                  icon={
                    <DeleteIcon
                      cursor="pointer"
                      color="#ee6d6d"
                      width="16px"
                      height="16px"
                    />
                  }
                />
              </Flex>
            );
          })}
        </Flex>
        <Flex mt={10} gap={5} justifyContent="flex-end" flexShrink={0}>
          <Button
            border="1px solid #A3A3A3"
            borderRadius={6}
            bg="#FFF"
            p="1.2rem 1.6rem"
            color="#009BE0"
            fontSize="1.4rem"
            isDisabled={isSubmitting}
            _hover={{}}
            onClick={() => {
              labelExtension?.showLabels({
                mode: SystemModeType.Document,
                data: currentlabelItems,
                options: {
                  forceUpdate: true,
                },
              });
              setClickedLabelInfo?.(undefined);
              toggleEditingFamily(false);
            }}
          >
            キャンセル
          </Button>
          <Button
            type="submit"
            borderRadius={6}
            bg="#009BE0"
            color="#FFF"
            fontSize="1.4rem"
            isLoading={isSubmitting}
            _hover={{}}
          >
            確定
          </Button>
        </Flex>
      </form>

      {isShowConfirmDeleteData && (
        <ConfirmModal
          title="一括抽出ピン削除の確認"
          content={
            <>
              <Text fontSize="1.4rem" mb="1rem">
                {messageConfirmDeleteData}
              </Text>
              <Text fontSize="1.4rem" mb="1rem">
                すでにデータの入っているピンは削除されません。
              </Text>
              <Checkbox
                fontSize="1.4rem"
                alignItems="flex-start"
                isChecked={isDeleteAllData}
                onChange={() => {
                  setIsDeleteAllData.toggle();
                }}
              >
                {messageConfirmDeleteAllData}
              </Checkbox>
            </>
          }
          buttonConfirm="OK"
          isLoading={isLoadingConfirmDeleteData}
          isOpen={isShowConfirmDeleteData}
          onClose={() => setIsShowConfirmDeleteData.off()}
          onProcessing={handleProcessPersistData}
        />
      )}
    </>
  );
};

export default FamilyEditModal;
