import { SystemModeType } from "constants/enum";
import { NormalDisplayItem } from ".";
import { getStatusColor, onLabelClick } from "./utils";
import { FONT_SIZE_DISPLAY_ORDER } from "utils/forge";
import { getColorTextByStatus } from "models/document";

export const documentLabel = (
  item: NormalDisplayItem,
  options?: { isSelected?: boolean }
) => {
  const documentStatusColor = getStatusColor(
    item.status,
    SystemModeType.Document
  );

  const displayOrderText = `${Number(item.displayOrder) + 1}`;
  const displayOrderLength = displayOrderText.length;
  const fontSizeDisplayOrder =
    FONT_SIZE_DISPLAY_ORDER[
      displayOrderLength as keyof typeof FONT_SIZE_DISPLAY_ORDER
    ];

  const colorText = getColorTextByStatus(item.status);

  const $label = $(`
    <label class="document-label markup update ${
      options?.isSelected ? "selected-label" : ""
    }" data-id="${item.id}">
      <div class="marker-pin" style="background-color: ${documentStatusColor}">
          <span class="marker-label  ${
            item.unVisible && "invisible"
          }" style="font-size: ${fontSizeDisplayOrder};color: ${colorText}">${displayOrderText}</span>
        </div>
    </label>`);

  $label.on("click", (e) => {
    onLabelClick(item, SystemModeType.Document);
  });

  return $label;
};
