export const CHANNEL_ALL = "ALL";

export enum MessageType {
  RELOAD_DOCUMENT = "RELOAD_DOCUMENT",
  RELOAD_TASK = "RELOAD_TASK",
  ADD_TASK_COMMENT = "ADD_TASK_COMMENT",
  ADD_TASK_TYPE = "ADD_TASK_TYPE",
  UPDATE_TASK_TYPE = "UPDATE_TASK_TYPE",
  UPDATE_TASK_COMMENT = "UPDATE_TASK_COMMENT",
  UPDATE_TASK = "UPDATE_TASK",
  CHANGE_TASK_MODAL_INFO = "CHANGE_TASK_MODAL_INFO",
  CHANGE_TASK_TAGS_MODAL_INFO = "CHANGE_TASK_TAGS_MODAL_INFO",
  DELETE_TASK = "DELETE_TASK",
  CHANGE_LIST_IMAGES = "CHANGE_LIST_IMAGES",
  CHANGE_ATTACHES = "CHANGE_ATTACHES",
  CHANGE_DOC_ITEM = "CHANGE_DOC_ITEM",
  CHANGE_DOC_ITEMS = "CHANGE_DOC_ITEMS",
  CHANGE_DOC_ITEM_BLACKBOARD = "CHANGE_DOC_ITEM_BLACKBOARD",
  CHANGE_DOC_CATEGORY_MODAL_INFO = "CHANGE_DOC_CATEGORY_MODAL_INFO",
  ADD_DOC_GROUP = "ADD_DOC_GROUP",
  DELETE_DOC_GROUP = "DELETE_DOC_GROUP",
  CHANGE_DOC_GROUP_MODAL_INFO = "CHANGE_DOC_GROUP_MODAL_INFO",
  CHANGE_DOC_ITEM_LOG = "CHANGE_DOC_ITEM_LOG",
  CHANGE_CURRENT_DOCUMENT = "CHANGE_CURRENT_DOCUMENT",
  CHANGE_DOCUMENT_TEMPLATE = "CHANGE_DOCUMENT_TEMPLATE",
  CHANGE_DOC_CATEGORY = "CHANGE_DOC_CATEGORY",
}
