import { Box, Flex, Text, Tooltip } from "@chakra-ui/react";
import { documentItemApi, documentItemLogApi } from "apiClient/v2";
import { SvgIcon } from "components/SvgIcon";
import { TypeFieldDynamic } from "constants/enum";
import useUserOfProject from "hooks/useUserOfProject";
import { DocumentCategoryDTO } from "interfaces/dtos/documentCategoryDTO";
import {
  DocumentItemDTO,
  DocumentSubItemDTO,
} from "interfaces/dtos/documentItemDTO";
import { FamilyInstanceDTO } from "interfaces/dtos/familyInstance";
import { UserDTO } from "interfaces/dtos/UserDTO";
import { Level } from "interfaces/models";
import { Blackboard as IBlackboard } from "interfaces/models/blackboard";
import { DataProjectModel } from "interfaces/models/dataProjectModel";
import { DocumentTemplate } from "interfaces/models/documentTemplate";
import { User } from "interfaces/models/user";
import { getColorTextByStatus } from "models/document";
import {
  GetContentItemLog,
  getDocumentContentLog,
} from "models/documentItemLog";
import { TypeHandleInitData } from "pages/forge-viewer/hooks/useSupportSyncDataOffline";
import { memo, useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { updateDocumentItem } from "redux/documentSlice";
import { updateElementInArray } from "utils/array";
import { transformBodyForCombineData } from "utils/offline";
import DynamicFields from "../DocumentCategoryModal/DynamicFields";
import Blackboard from "./Blackboard";
import FieldNoComponent from "./FieldNoComponent";
import { useSubItemLogs } from "./hooks";
import ItemLog from "./ItemLog";
import ItemTitle, { ItemTitleType } from "./ItemTitle";

interface Props {
  users: UserDTO[];
  currentUser?: User | null;
  subItemSelected?: DocumentSubItemDTO;
  documentItemSelected?: DocumentItemDTO;
  documentTemplate?: DocumentTemplate;
  documentCategorySelected?: DocumentCategoryDTO;
  dataBlackboardDefault?: Partial<IBlackboard>;
  itemStatusColor: string;
  familyInstance: FamilyInstanceDTO;
  levelSelected?: Level;
  listOptionFloor?: { label: string; value: string }[];
  listOptionUsers?: { label: string; value: string }[];
  listPartnerCompanies?: { label: string; value: string }[];
  dataProjectDetail?: DataProjectModel;
  isDisabled?: boolean;
  projectName?: string;
  isLoadedBlackboard: boolean;
  dataBlackBoards: IBlackboard[];
  itemDisplayOrder: number | string;

  onClose: () => void;
}

const ModalTypeSubItem = (props: Props) => {
  const {
    users,
    currentUser,
    subItemSelected,
    documentTemplate,
    documentItemSelected,
    documentCategorySelected,
    dataBlackboardDefault,
    itemStatusColor,
    familyInstance,
    levelSelected,
    listOptionFloor,
    listOptionUsers,
    listPartnerCompanies,
    projectName,
    isLoadedBlackboard,
    dataBlackBoards,
    itemDisplayOrder,
    isDisabled,
    onClose,
  } = props;

  const dispatch = useDispatch();
  const { listUserById } = useUserOfProject();
  const { subItemLogs, setSubItemLogs } = useSubItemLogs({
    subItemId: subItemSelected?.id || "",
  });

  const insertSubItemLog = useCallback(
    async ({
      field,
      value,
      operation,
      nameDynamicField,
      prefixFieldName,
      dynamicFieldKeysUpdatedAt,
    }: GetContentItemLog) => {
      if (!currentUser?.id) {
        return;
      }

      const content = getDocumentContentLog({
        field,
        value,
        operation,
        nameDynamicField,
        prefixFieldName,
      }) as any;

      const now = new Date();
      const res = await documentItemLogApi.createItemLog({
        createdBy: currentUser.id,
        content,
        field,
        value,
        title: content?.title,
        subItemId: subItemSelected?.id,
        dynamicFieldKeysUpdatedAt,
        createdAt: now,
        updatedAt: now,
      });

      const log = res?.data;
      if (log?.id && !!content) {
        setSubItemLogs((prev) => [...prev, log]);
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [subItemSelected?.id, currentUser?.id]
  );

  const handleSaveDynamicField = useCallback(
    async (item: DocumentItemDTO) => {
      if (!documentItemSelected?.id || !subItemSelected?.id) {
        return;
      }

      const now = new Date();
      const documentItem = structuredClone(documentItemSelected);
      updateElementInArray({
        array: documentItem?.subItems || [],
        keyIndex: "id",
        element: {
          ...subItemSelected,
          data: item.data,
          dynamicFieldKeysUpdatedAt: item.dynamicFieldKeysUpdatedAt,
          updatedAt: now,
        } as DocumentSubItemDTO,
      });

      documentItemApi.updateSubItem(
        transformBodyForCombineData<DocumentSubItemDTO>({
          body: {
            id: subItemSelected?.id,
            itemId: subItemSelected.itemId,
            data: item.data,
            dynamicFieldKeysUpdatedAt: item.dynamicFieldKeysUpdatedAt,
            updatedAt: now,
            isIgnoreInsertLog: true,
          } as DocumentSubItemDTO,
          bodyBefore: subItemSelected,
          typeInitData: TypeHandleInitData.SUB_ITEM,
        })
      );

      dispatch(updateDocumentItem(documentItem));
    },
    [documentItemSelected, subItemSelected, dispatch]
  );

  const noSubItem = useMemo(
    () =>
      `${itemDisplayOrder}-${
        (documentItemSelected?.subItems?.findIndex(
          (sub) => sub.id === subItemSelected?.id
        ) || 0) + 1
      }`,
    [itemDisplayOrder, documentItemSelected?.subItems, subItemSelected?.id]
  );

  return (
    <Flex flexDirection="column" padding="0px 1.6rem" minHeight="100%">
      {subItemSelected?.isLoadingUpdateImage && (
        <Box
          position="absolute"
          inset="0px"
          backgroundColor="rgba(0,0,0,0.2)"
          zIndex={999}
        ></Box>
      )}

      {/* navigate */}
      <Flex alignItems="center" gap="3rem" mb="3.2rem">
        <SvgIcon
          onClick={onClose}
          cursor="pointer"
          flexShrink={0}
          src="/img/arrow-move.svg"
        />
        <Flex
          flexGrow={1}
          alignItems="center"
          gap="1rem"
          maxW={"calc(100% - 40px)"}
        >
          <Box flexGrow={"auto"}>
            <FieldNoComponent
              color={itemStatusColor}
              no={itemDisplayOrder}
              colorText={getColorTextByStatus(documentItemSelected?.status)}
            />
          </Box>
          <Box flexGrow={"auto"} maxW={"calc(100% - 40px)"}>
            <Tooltip label={documentItemSelected?.title}>
              <Text
                whiteSpace={"nowrap"}
                overflow={"hidden"}
                textOverflow="ellipsis"
                fontSize="1.8rem"
                color="var(--primary-text-color)"
              >
                {documentItemSelected?.title}
              </Text>
            </Tooltip>
          </Box>
        </Flex>
      </Flex>

      {/* title */}
      <Flex gap="1rem" alignItems="center" mb="1.6rem">
        <SvgIcon
          flexShrink={0}
          src="/img/icon-camera-black.svg"
          width="2.4rem"
          height="2.4rem"
        />
        <Text
          fontSize="1.4rem"
          color="var(--primary-text-color)"
          flexShrink={0}
        >
          {noSubItem}
        </Text>
        <ItemTitle
          type={ItemTitleType.SUB_ITEM}
          item={documentItemSelected}
          subItem={subItemSelected}
          insertItemLog={insertSubItemLog}
          inputProps={{ isDisabled }}
        />
      </Flex>

      {/* photo */}
      <Box paddingLeft="3.4rem" overflow="hidden" mb="3.2rem">
        <Box
          padding="0.8rem"
          backgroundColor={
            subItemSelected?.images?.src || !isDisabled ? "#E8E8E8" : "#fff"
          }
          borderRadius="6px"
        >
          <Blackboard
            users={users}
            currentUser={currentUser}
            documentTemplate={documentTemplate}
            documentItemSelected={documentItemSelected}
            subItem={subItemSelected}
            documentCategorySelected={documentCategorySelected}
            dataBlackboardDefault={dataBlackboardDefault}
            familyInstance={familyInstance}
            levelSelected={levelSelected}
            listOptionFloor={listOptionFloor}
            listOptionUsers={listOptionUsers}
            listPartnerCompanies={listPartnerCompanies}
            projectName={projectName}
            isLoadedBlackboard={isLoadedBlackboard}
            dataBlackBoards={dataBlackBoards}
            insertSubItemLog={insertSubItemLog}
            isDisabled={isDisabled}
          />
        </Box>
      </Box>

      <DynamicFields
        type={TypeFieldDynamic.TYPE_ITEM}
        template={documentTemplate}
        documentItemSelected={subItemSelected as DocumentItemDTO}
        listUserById={listUserById}
        insertDocumentItemLog={insertSubItemLog}
        onSaveDataForDocumentItem={handleSaveDynamicField}
        isDisabled={isDisabled}
        marginBottom="3.2rem"
      />

      {/* logs */}
      <Box
        backgroundColor="#E8E8E8"
        ml="-1.6rem"
        mt="auto"
        width="calc(100% + 3.2rem)"
        padding="2rem 1.6rem"
      >
        <Text
          display="block"
          fontSize="1.6rem"
          color="var(--primary-text-color)"
          fontWeight="bold"
          mb="1.2rem"
        >
          変更履歴
        </Text>
        {subItemLogs?.map((item) => (
          <ItemLog
            key={item.id}
            log={item}
            user={listUserById?.[item?.createdBy || ""]}
          />
        ))}
      </Box>
    </Flex>
  );
};

export default memo(ModalTypeSubItem);
