import { Flex, Text } from "@chakra-ui/react";
import { DocumentItemLog } from "interfaces/models/documentItem";
import { User } from "interfaces/models/user";
import { memo } from "react";
import { formatDate } from "utils/date";

interface Props {
  log: DocumentItemLog;
  user?: User;
}

const ItemLog = (props: Props) => {
  const { log, user } = props;

  return (
    <Flex
      alignItems="center"
      color={log?.field === "status" ? "#CE2121" : "#737373"}
      py="0.5rem"
      fontSize="1rem"
    >
      <Text>{user?.name}</Text>
      <Text ml="1rem">{log?.title}</Text>
      <Text ml="1rem">
        {log.createdAt ? formatDate(new Date(log.createdAt)) : ""}
      </Text>
    </Flex>
  );
};

export default memo(ItemLog);
