import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";

const useScissorsTitleViewerPropertyPanel = (): React.ReactElement | null => {
  const [viewerPropertyPanel, setViewerPropertyPanel] =
    useState<HTMLElement | null>(null);
  const [dockingPanelTitle, setDockingPanelTitle] =
    useState<HTMLElement | null>(null);
  const isHoldingDockingPanelTitleRef = useRef(false);
  const { isLoadedViewer } = useSelector(
    (state: RootState) => state.forgeViewer
  );

  useEffect(() => {
    if (!isLoadedViewer) {
      return;
    }
    const elementViewerPropertyPanel = document.getElementById(
      "ViewerPropertyPanel"
    );
    const elementDockingPanelTitle = document.querySelector(
      "div.docking-panel-delimiter-shadow.docking-panel-title"
    ) as any;
    if (elementViewerPropertyPanel && !viewerPropertyPanel) {
      elementViewerPropertyPanel.classList.add("left-50vw");
      setViewerPropertyPanel(elementViewerPropertyPanel);
    }
    if (elementDockingPanelTitle && !dockingPanelTitle) {
      setDockingPanelTitle(elementDockingPanelTitle);
    }
  }, [isLoadedViewer]);

  const handleMouseDownViewerPropertyPanel = (e: any) => {
    if (e.target.closest("#ViewerPropertyPanel")) {
      isHoldingDockingPanelTitleRef.current = true;
    }
  };

  const handleMouseUpViewerPropertyPanel = () => {
    isHoldingDockingPanelTitleRef.current = false;
  };

  const handleMousemoveViewerPropertyPanel = useCallback(
    (event: MouseEvent) => {
      if (isHoldingDockingPanelTitleRef.current && viewerPropertyPanel) {
        const x = event.clientX;
        const y = event.clientY;
        viewerPropertyPanel.classList.remove("left-50vw");
        viewerPropertyPanel.style.left = `${x}px`;
        viewerPropertyPanel.style.top = `${y}px`;
      }
    },
    [viewerPropertyPanel]
  );

  useEffect(() => {
    document.addEventListener(
      "mousedown",
      handleMouseDownViewerPropertyPanel,
      true
    );
    document.addEventListener(
      "mouseup",
      handleMouseUpViewerPropertyPanel,
      true
    );

    return () => {
      document.removeEventListener(
        "mousedown",
        handleMouseDownViewerPropertyPanel,
        true
      );
      document.removeEventListener(
        "mouseup",
        handleMouseUpViewerPropertyPanel,
        true
      );
    };
  }, []);

  useEffect(() => {
    if (dockingPanelTitle && viewerPropertyPanel) {
      dockingPanelTitle.addEventListener(
        "mousemove",
        handleMousemoveViewerPropertyPanel
      );

      // Clean up the event listeners when the component unmounts or the element changes
      return () => {
        dockingPanelTitle.removeEventListener(
          "mouseup",
          handleMouseUpViewerPropertyPanel
        );
      };
    }
  }, [
    dockingPanelTitle,
    handleMousemoveViewerPropertyPanel,
    viewerPropertyPanel,
  ]);

  return null;
};

export default useScissorsTitleViewerPropertyPanel;
