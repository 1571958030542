import { iUseGetContentOfCell } from "components/editor-builder/component-preview/TableComponentPreview/TableTdComponentPreview";
import {
  renderCellPropertyCheckbox,
  renderContentTypeCommon,
} from "components/editor-builder/component-preview/TableComponentPreview/utils";
import { EMPTY_OPTION } from "constants/document";
import {
  CellProperty,
  LinkedDataField,
  TableDefaultStyle,
} from "constants/enum";
import { CellType } from "interfaces/models/component";
import { DocumentItem } from "interfaces/models/documentItem";
import { iCommonDocumentTemplateProps } from "interfaces/models/documentTemplate";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { formatDateCurrent } from "utils/date";
import NormalTextPreview from "../../NormalTextPreview";

const useGetContentTypePac = (props: iUseGetContentOfCell) => {
  const {
    component,
    currentTemplate,
    zoomRatio,
    isBlackboardTemplateImage,
    displaySize,
    width,
    height,
    limit,
    isLastColumn,
    isDuplicateRow,
    sizePageRatio,
    pageDirectionRatio,
    cellSize,
    isOnlyView,
    components,
    selectedCells,
    documentContainerSize,
    componentSelected,
    isComponentDragging,
    contentType,
    pacProps,
  } = props;

  const { familyInstances } = useSelector(
    (state: RootState) => state.forgeViewer
  );

  const contentOfCellTypePac = useCallback(
    (cell: CellType) => {
      const data = structuredClone(cell);

      const headerComponent = pacProps?.headerComponent || [];
      let rowIndex = pacProps?.option?.rowIndex || 0;
      let cellIndex = pacProps?.option?.cellIndex || 0;
      const subRowIndex = pacProps?.option?.subRowIndex || 0;
      const subCellIndex = pacProps?.option?.subCellIndex || 0;
      const currentCell = pacProps?.option?.currentCell;
      const offsetItemLinked = pacProps?.offsetItemLinked || 0;
      const displayItems = pacProps?.displayItems || [];
      const documentCategorySelected = pacProps?.documentCategorySelected;
      const numberOfRows = component?.detail?.rows?.length || 0;
      const numberOfActualCell =
        (component?.detail?.rows?.[0]?.cells?.length || 0) /
        (component?.detail?.numOfRepeatTable || 1);

      if (
        component?.detail?.isRepeat &&
        component?.detail?.isRepeatTable &&
        component?.detail?.numOfRepeatTable &&
        offsetItemLinked
      ) {
        rowIndex =
          rowIndex + offsetItemLinked * (component.detail.numOfRepeatTable - 1);
      }

      if (cell?.isRepeatedTable && cell?.repeatedTableIndex) {
        rowIndex += numberOfRows * cell.repeatedTableIndex;
        cellIndex -= numberOfActualCell * cell.repeatedTableIndex;
      }

      const documentItems = [
        ...(pacProps?.displayItems || []),
      ] as DocumentItem[];
      const documentItem = documentItems![rowIndex + offsetItemLinked];
      const documentItemData = documentItem?.data || {};
      const cellEle = document.getElementById(cell.cellId);
      const isSubCell = !!cell?.isSubCell;

      const headerTitleId = isSubCell
        ? headerComponent?.[cellIndex]?.subTable?.rows?.[subRowIndex]?.cells?.[
            subCellIndex
          ]?.cellId
        : headerComponent?.[cellIndex]?.cellId;

      const renderCommon = renderContentTypeCommon({
        contentType,
        contentCommonProps: (pacProps || {}) as iCommonDocumentTemplateProps,
        cell,
        familyInstances,
        documentItems,
        documentItem,
        component,
        subTableComponentPreviewProps: {
          component,
          parentCell: cell,
          displaySize: {
            width:
              (width / component.size.width) * displaySize.width -
                TableDefaultStyle.DEFAULT_BORDER_SIZE || 0,
            height:
              (height / component.size.height) * displaySize.height -
                TableDefaultStyle.DEFAULT_BORDER_SIZE || 0,
          },
          zoomRatio,
          cellSize,
          sizePageRatio,
          pageDirectionRatio,
          isOnlyView,
          isDuplicateRow,
          isParentLastColumn: isLastColumn,
          limit,
          isBlackboardTemplateImage,
          components,
          selectedCells,
          documentContainerSize,
          currentTemplate,
          componentSelected,
          isComponentDragging,
          contentType,
          pacProps,
        },
      });

      if (renderCommon) {
        return renderCommon;
      }

      switch (cell?.cellProperty) {
        case CellProperty.TEXT:
          break;

        case CellProperty.NO:
          data.value = documentItem?.id
            ? String((documentItem?.displayOrder || 0) + 1)
            : "";
          break;

        case CellProperty.DOCUMENT_DATA:
          switch (data?.cellLinkedData?.field) {
            case LinkedDataField.COMMON.REMARKS:
              data.value = documentItem?.memo;
              break;

            case LinkedDataField.DOCUMENT_ITEM.LEVEL:
              data.value = documentCategorySelected?.level;
              break;

            case LinkedDataField.DOCUMENT_ITEM.TITLE:
              data.value = component?.detail?.isRepeatTable
                ? displayItems?.[Number(currentCell?.rowIndex) - 1]?.title
                : documentItem?.title;
              break;

            case LinkedDataField.COMMON.CHECK_BOX:
              const options =
                data?.cellLinkedData?.options?.valueOfCheckbox
                  ?.split("・")
                  .filter((e) => e) || [];
              const numberOfCheckbox =
                data.cellLinkedData?.options?.numberOfCheckbox || 1;
              const isMultipleCheckbox = numberOfCheckbox > 1;
              const isNoneLabel = options.length === 0;

              const value =
                documentItemData[`${headerTitleId}-${documentItem?.id}`] || "";

              const isAllowSave = !(isMultipleCheckbox && isNoneLabel);
              cellEle?.setAttribute("data-is-allow-save", `${isAllowSave}`);

              if (!isNoneLabel) {
                data.value = value !== EMPTY_OPTION ? value : "";
              }
              break;

            case LinkedDataField.DOCUMENT_ITEM.LAST_CHANGE_STATUS_DATETIME:
              data.value = formatDateCurrent(
                documentItem?.startDateScheduled,
                cell?.cellLinkedData?.options?.dateFormat
              );
              break;

            default:
              break;
          }
          break;

        default:
          break;
      }

      if (!data) {
        return null;
      }

      cellEle?.setAttribute("data-document-id", documentItem?.id || "");
      cellEle?.setAttribute("data-header-title-id", headerTitleId || "");
      cellEle?.setAttribute("data-field-content", data?.value || "");
      cellEle?.setAttribute(
        "data-field-name",
        data?.cellLinkedData?.field || ""
      );
      cellEle?.setAttribute(
        "data-options",
        data?.cellLinkedData?.options?.valueOfCheckbox || ""
      );

      if (
        rowIndex + offsetItemLinked >= displayItems?.length &&
        cell.cellProperty !== CellProperty.NO
      ) {
        return <></>;
      }

      switch (data?.cellProperty) {
        case CellProperty.NO:
        case CellProperty.TEXT:
        case CellProperty.INPUT_DATA:
        case CellProperty.CALENDAR: {
          return <NormalTextPreview data={data} />;
        }

        case CellProperty.DOCUMENT_DATA:
          switch (data?.cellLinkedData?.field) {
            case LinkedDataField.COMMON.CHECK_BOX:
              return renderCellPropertyCheckbox(data, component);

            default:
              return <NormalTextPreview data={data} />;
          }

        default:
          return <NormalTextPreview data={data} />;
      }
    },
    [
      contentType,
      isComponentDragging,
      components,
      selectedCells,
      documentContainerSize,
      currentTemplate,
      componentSelected,
      limit,
      isLastColumn,
      cellSize,
      displaySize,
      width,
      height,
      zoomRatio,
      sizePageRatio,
      pageDirectionRatio,
      isOnlyView,
      isDuplicateRow,
      isBlackboardTemplateImage,
      familyInstances,
      component,
      pacProps,
    ]
  );

  return {
    contentOfCellTypePac,
  };
};

export default useGetContentTypePac;
