/* eslint-disable no-loop-func */
import { Avatar, Flex, Text } from "@chakra-ui/react";
import { withPresignedUrl } from "components/HOC/presignedUrl";
import { TaskComment } from "interfaces/models/task";
import { User } from "interfaces/models/user";
import { COMMENT_TYPE } from "models/commentLog";
import { Fragment } from "react";
import { formatDate } from "utils/date";
import ChatComment from "./ChatComment";
import { TaskDTO } from "interfaces/dtos/taskDTO";

const AvatarPresigned = withPresignedUrl(Avatar);
interface Props {
  comments: TaskComment[];
  listUserById: { [key: string]: User | null };
  loadingImage: boolean;
  status: any;
  isOnline: boolean;
  onUpdateImageComment: (
    e: any,
    comment: TaskComment,
    srcImage: string
  ) => Promise<void>;
  onDeleteImage: (comment: TaskComment, srcImage: string) => Promise<void>;
  onPreviewImage: (src: string) => void;
  taskSelected: TaskDTO | undefined;
}
const RecordComment = ({
  comments,
  listUserById,
  loadingImage,
  status,
  isOnline,
  onUpdateImageComment,
  onDeleteImage,
  onPreviewImage,
  taskSelected,
}: Props) => {
  const getCommentCombine = () => {
    const lengthComments = comments.length;
    const listCommentCombine: TaskComment[][] = [];
    let childArIndex = 0;
    let i = 0;
    while (i < lengthComments) {
      const isChatComment = isTypeComment(comments[i].type);
      if (
        isChatComment ||
        comments[i]?.createdBy !==
          listCommentCombine[childArIndex]?.[0]?.createdBy ||
        isTypeComment(listCommentCombine[childArIndex]?.[0]?.type)
      ) {
        childArIndex++;
      }
      if (!listCommentCombine[childArIndex]) {
        listCommentCombine[childArIndex] = [];
      }

      if (
        !listCommentCombine.find(
          (child: TaskComment[]) => child?.[0]?.id === comments[i]?.id
        ) &&
        !listCommentCombine[childArIndex].find(
          (child: TaskComment) => child?.id === comments[i]?.id
        )
      ) {
        listCommentCombine[childArIndex].push(comments[i]);
      }

      i++;
    }

    return listCommentCombine.filter(
      (comment: TaskComment[]) => comment?.length
    );
  };

  const isTypeComment = (type: number | undefined) => {
    return (
      type === COMMENT_TYPE.ADD_COMMENT.typeKey ||
      type === COMMENT_TYPE.ADD_COMMENT_IMAGE.typeKey ||
      type === COMMENT_TYPE.ADD_COMMENT_AUDIO.typeKey ||
      type === COMMENT_TYPE.ADD_COMMENT_FILE.typeKey
    );
  };

  const listComment = getCommentCombine() || [];
  const getTypeData = (recordComment: TaskComment) => {
    const typeData =
      typeof recordComment.type === "number" &&
      Object.entries(COMMENT_TYPE).find(
        ([_, value]) => value.typeKey === recordComment.type
      )?.[1];

    if (!typeData || !("text" in typeData)) {
      return COMMENT_TYPE.ADD_COMMENT.text;
    }
    if (typeof typeData.text === "function") {
      return typeData.text(recordComment.content || "");
    }

    return typeData.text || "";
  };

  return (
    <>
      <Flex
        background="#E5E5E5"
        p="0.5rem 1.6rem"
        flexDir="column"
        color="#737373"
      >
        <Flex alignItems="center" mt="0.5rem" color={"#737373"}>
          <AvatarPresigned
            w="2.4rem"
            h="2.4rem"
            mr="8px"
            borderRadius="full"
            objectFit="cover"
            src={listUserById[taskSelected?.userCreated || ""]?.avatar}
          />
          <Text fontSize="1rem" mr="2rem" as="b">
            {listUserById[taskSelected?.userCreated || ""]?.name || "user name"}
          </Text>
          <Text
            fontSize="1rem"
            mr="2rem"
            lineHeight="1.5rem"
            as="b"
            dangerouslySetInnerHTML={{
              __html: COMMENT_TYPE.CREATE_TASK.text,
            }}
          />
          <Text fontSize="1rem" lineHeight="1.5rem">
            {formatDate(taskSelected?.createdAt)}
          </Text>
        </Flex>
      </Flex>
      {!!listComment.length &&
        listComment.map((commentsHead: TaskComment[], index: number) => {
          return commentsHead.length === 1 ? (
            <Fragment
              key={`${commentsHead[0]?.id}${commentsHead[0]?.images?.join(
                ","
              )}`}
            >
              <Flex
                background="#E5E5E5"
                p="0.5rem 1.6rem"
                flexDir="column"
                color="#737373"
              >
                <Flex
                  alignItems="center"
                  mt="0.5rem"
                  color={
                    commentsHead[0]?.type === COMMENT_TYPE.CHANGE_STATUS.typeKey
                      ? "#DC2626"
                      : "#737373"
                  }
                >
                  <AvatarPresigned
                    w="2.4rem"
                    h="2.4rem"
                    mr="8px"
                    borderRadius="full"
                    objectFit="cover"
                    src={listUserById[commentsHead[0]?.createdBy || ""]?.avatar}
                  />
                  <Text fontSize="1rem" mr="2rem" as="b">
                    {listUserById[commentsHead[0]?.createdBy || ""]?.name ||
                      "user name"}
                  </Text>
                  <Text
                    fontSize="1rem"
                    mr="2rem"
                    lineHeight="1.5rem"
                    as="b"
                    dangerouslySetInnerHTML={{
                      __html: getTypeData(commentsHead[0]),
                    }}
                  />
                  <Text fontSize="1rem" lineHeight="1.5rem">
                    {formatDate(commentsHead[0]?.createdAt)}
                  </Text>
                </Flex>
                <ChatComment
                  isOnline={isOnline}
                  comment={commentsHead[0]}
                  status={status}
                  loadingImage={loadingImage}
                  listUserById={listUserById}
                  onUpdateImageComment={onUpdateImageComment}
                  onDeleteImage={onDeleteImage}
                  onPreviewImage={onPreviewImage}
                />
              </Flex>
            </Fragment>
          ) : (
            <Fragment key={commentsHead[0]?.id}>
              <Flex
                borderBottom="1px solid #e2e2e3"
                background="#E5E5E5"
                p="0.5rem 1.5rem"
                flexDir="column"
                color="#737373"
              >
                <Flex mt="0.5rem" h="100%">
                  <AvatarPresigned
                    w="2.4rem"
                    h="2.4rem"
                    mr="8px"
                    borderRadius="full"
                    objectFit="cover"
                    src={listUserById[commentsHead[0]?.createdBy || ""]?.avatar}
                  />
                  <Flex flexDir="column" alignItems="start">
                    {commentsHead.map(
                      (comment: TaskComment, indexCommentHead: number) => {
                        return (
                          <Fragment key={indexCommentHead}>
                            <Flex
                              mr="16px"
                              color={
                                comment.type ===
                                COMMENT_TYPE.CHANGE_STATUS.typeKey
                                  ? "#DC2626"
                                  : ""
                              }
                            >
                              <Text fontSize="1rem" mr="1rem">
                                {listUserById[comment?.createdBy || ""]?.name ||
                                  "user name"}
                              </Text>
                              <Text
                                fontSize="1rem"
                                mr="1rem"
                                lineHeight="1.5rem"
                                as="b"
                                dangerouslySetInnerHTML={{
                                  __html: getTypeData(comment),
                                }}
                              />
                              <Text fontSize="1rem" lineHeight="1.5rem">
                                {formatDate(comment?.createdAt)}
                              </Text>
                            </Flex>
                          </Fragment>
                        );
                      }
                    )}
                  </Flex>
                </Flex>
              </Flex>
            </Fragment>
          );
        })}
    </>
  );
};

export default RecordComment;
