import SubTableComponentPreview from "components/editor-builder/component-preview/TableComponentPreview/SubTableComponentPreview";
import { iUseGetContentOfCell } from "components/editor-builder/component-preview/TableComponentPreview/TableTdComponentPreview";
import {
  handleDynamicFieldCategoryContent,
  renderCellPropertyCheckbox,
  renderContentTypeCommon,
} from "components/editor-builder/component-preview/TableComponentPreview/utils";
import {
  CellProperty,
  DocumentStatusType,
  DocumentTemplateType,
  LinkedDataField,
  LinkedDynamicFieldsType,
  MapExtractionInspectionResultType,
  TableDefaultStyle,
} from "constants/enum";
import { CellType } from "interfaces/models/component";
import { iCommonDocumentTemplateProps } from "interfaces/models/documentTemplate";
import { useCallback, useMemo } from "react";
import { formatDate } from "utils/date";
import NormalTextPreview from "../../NormalTextPreview";

const useGetContentTypePreview = (props: iUseGetContentOfCell) => {
  const {
    component,
    currentTemplate,
    zoomRatio,
    isBlackboardTemplateImage,
    displaySize,
    width,
    height,
    limit,
    isLastColumn,
    isDuplicateRow,
    sizePageRatio,
    pageDirectionRatio,
    cellSize,
    isOnlyView,
    components,
    selectedCells,
    documentContainerSize,
    componentSelected,
    isComponentDragging,
    contentType,

    previewProps,
  } = props;

  const {
    familyInstances = {},
    projectName = "",
    neptuneAreas = [],
    documentTemplateName,
  } = previewProps || {};

  const mapTitleArea: { [key: string]: string } = useMemo(() => {
    const mapObject = {};

    neptuneAreas.forEach((item) => {
      Object.assign(mapObject, {
        [String(item?.id)]: item?.name,
      });
    });

    return mapObject;
  }, [neptuneAreas]);

  const contentOfCellTypePreivew = useCallback(
    (cell: CellType) => {
      let data = structuredClone(cell);

      const documentCategorySelected = previewProps?.documentCategorySelected;
      const documentItemSelected = previewProps?.documentItemSelected;
      const listUserById = previewProps?.listUserById;
      const companiesById = previewProps?.companiesById;

      const isModuleChiller =
        documentCategorySelected?.documentType ===
        DocumentTemplateType.EQUIPMENT_DATA_SHEET;
      const documentItem = documentCategorySelected?.documentItems?.find(
        (item) => item.id === documentItemSelected?.id
      );

      if (documentItem) {
        const renderCommon = renderContentTypeCommon({
          contentType,
          contentCommonProps: (previewProps ||
            {}) as iCommonDocumentTemplateProps,
          cell,
          familyInstances,
          documentItems: [documentItem],
          documentItem,
          component,
          subTableComponentPreviewProps: {
            component,
            parentCell: cell,
            displaySize: {
              width:
                (width / component.size.width) * displaySize.width -
                  TableDefaultStyle.DEFAULT_BORDER_SIZE || 0,
              height:
                (height / component.size.height) * displaySize.height -
                  TableDefaultStyle.DEFAULT_BORDER_SIZE || 0,
            },
            zoomRatio,
            cellSize,
            sizePageRatio,
            pageDirectionRatio,
            isOnlyView,
            isDuplicateRow,
            isParentLastColumn: isLastColumn,
            limit,
            isBlackboardTemplateImage,
            components,
            selectedCells,
            documentContainerSize,
            currentTemplate,
            componentSelected,
            isComponentDragging,
            contentType,
            previewProps,
          },
        });

        if (renderCommon) {
          return renderCommon;
        }
      }

      switch (cell?.cellProperty) {
        case CellProperty.TEXT:
          data = cell;
          break;

        case CellProperty.DOCUMENT_DATA:
          if (cell.cellLinkedData?.type === CellProperty.TEXT) {
            data = cell;

            break;
          }

          const dateFormat =
            data?.cellLinkedData?.options?.dateFormat || "YYYY/MM/DD";

          switch (cell?.cellLinkedData?.field) {
            case LinkedDataField.COMMON.DYNAMIC_FIELDS_FOR_CATEGORY:
              data = handleDynamicFieldCategoryContent({
                cell: data,
                documentCategorySelected,
                documentItemSelected,
                listUserById,
              });

              break;

            case LinkedDataField.DOCUMENT_CATEGORY.INSPECTION_DATE:
            case LinkedDataField.DOCUMENT_CATEGORY.DEADLINE:
            case LinkedDataField.DOCUMENT_CATEGORY.EXECUTE_DATE:
            case LinkedDataField.DOCUMENT_CATEGORY.APPROVE_DATE:
            case LinkedDataField.DOCUMENT_CATEGORY.IMPLEMENT_DATE:
              data.value = formatDate(
                documentCategorySelected?.[cell?.cellLinkedData?.field],
                dateFormat
              );
              break;

            case LinkedDataField.DOCUMENT_CATEGORY.MEASURE_DATE:
              const measureDate =
                isModuleChiller && documentItem
                  ? documentItem?.measureStartTime
                  : documentCategorySelected?.measureDate;
              data.value = formatDate(measureDate, dateFormat);
              break;

            case LinkedDataField.DOCUMENT_CATEGORY.USER_ASSIGNED:
              data.value = documentCategorySelected?.userAssigned
                ?.map((e) => listUserById?.[e]?.name)
                ?.join(",");
              break;

            case LinkedDataField.DOCUMENT_CATEGORY.USER_MEASURE:
              data.value =
                listUserById?.[documentCategorySelected?.userMeasure || ""]
                  ?.name || "";

              break;

            case LinkedDataField.DOCUMENT_CATEGORY.USER_MANAGE:
              data.value =
                listUserById?.[documentCategorySelected?.userManage || ""]
                  ?.name || "";
              break;

            case LinkedDataField.DOCUMENT_CATEGORY.MANAGER:
              data.value =
                listUserById?.[documentCategorySelected?.manager || ""]?.name ||
                "";
              break;

            case LinkedDataField.DOCUMENT_CATEGORY.CORPORATE_COMPANY:
              data.value = documentCategorySelected?.corporateCompany || "";
              break;

            case LinkedDataField.DOCUMENT_CATEGORY.PARTNER_COMPANY:
              data.value =
                companiesById?.[documentCategorySelected?.partnerCompany || ""]
                  ?.name || "";
              break;

            case LinkedDataField.DOCUMENT_CATEGORY.AREA_ID: {
              data.value = documentCategorySelected?.allFloor
                ? "すべてのエリア"
                : documentCategorySelected?.neptuneAreaIds
                    ?.map((id) => mapTitleArea[id])
                    .join(",");
              break;
            }

            case LinkedDataField.DOCUMENT_CATEGORY.TAGS:
              data.value = documentCategorySelected?.tags
                ?.map((e) => e)
                .join(",");
              break;

            case LinkedDataField.DOCUMENT_CATEGORY.PROJECT_NAME:
              data.value = projectName;
              break;

            case LinkedDataField.DOCUMENT_CATEGORY.INSPECTION_RESULT:
              data.value =
                MapExtractionInspectionResultType[
                  (documentCategorySelected?.inspectionResult as DocumentStatusType) ||
                    DocumentStatusType.Incomplete
                ];

              break;
            case LinkedDataField.DOCUMENT_CATEGORY.USER_IMPLEMENT:
              data.value =
                listUserById?.[documentCategorySelected?.userImplement!]
                  ?.name || "";
              break;

            case LinkedDataField.DOCUMENT_CATEGORY.TEMPLATE_NAME:
              data.value = documentCategorySelected?.title || ""; // all template name is title document
              break;

            default:
              data.value =
                (documentCategorySelected as any)?.[
                  data?.cellLinkedData?.field as any
                ] || "";
              break;
          }

          break;

        default:
          break;
      }

      if (!data) {
        return null;
      }

      if (data?.subTable?.rows?.length) {
        return (
          <SubTableComponentPreview
            component={component}
            parentCell={cell}
            displaySize={{
              width:
                (width / component.size.width) * displaySize.width -
                  TableDefaultStyle.DEFAULT_BORDER_SIZE || 0,
              height:
                (height / component.size.height) * displaySize.height -
                  TableDefaultStyle.DEFAULT_BORDER_SIZE || 0,
            }}
            zoomRatio={zoomRatio}
            cellSize={cellSize}
            sizePageRatio={sizePageRatio}
            pageDirectionRatio={pageDirectionRatio}
            isOnlyView={isOnlyView}
            isDuplicateRow={isDuplicateRow}
            isParentLastColumn={isLastColumn}
            limit={limit}
            isBlackboardTemplateImage={isBlackboardTemplateImage}
            components={components}
            selectedCells={selectedCells}
            documentContainerSize={documentContainerSize}
            currentTemplate={currentTemplate}
            componentSelected={componentSelected}
            isComponentDragging={isComponentDragging}
            contentType={contentType}
          />
        );
      }

      switch (data?.cellProperty) {
        case CellProperty.TEXT:
        case CellProperty.INPUT_DATA:
        case CellProperty.FAMILY_INSTANCE:
        case CellProperty.CALENDAR: {
          return <NormalTextPreview data={data} />;
        }

        case CellProperty.DOCUMENT_DATA:
          switch (data?.cellLinkedData?.field) {
            case LinkedDataField.COMMON.DYNAMIC_FIELDS_FOR_CATEGORY:
              switch (data?.cellLinkedData?.options?.dynamicFieldType) {
                case LinkedDynamicFieldsType.CHECK:
                  return renderCellPropertyCheckbox(data, component);

                default:
                  return <NormalTextPreview data={data} />;
              }

            default:
              return <NormalTextPreview data={data} />;
          }

        case CellProperty.CHECK_BOX: {
          return renderCellPropertyCheckbox(data, component);
        }

        default:
          return <NormalTextPreview data={data} />;
      }
    },
    [
      previewProps,
      contentType,
      familyInstances,
      component,
      width,
      displaySize.width,
      displaySize.height,
      height,
      zoomRatio,
      cellSize,
      sizePageRatio,
      pageDirectionRatio,
      isOnlyView,
      isDuplicateRow,
      isLastColumn,
      limit,
      isBlackboardTemplateImage,
      components,
      selectedCells,
      documentContainerSize,
      currentTemplate,
      componentSelected,
      isComponentDragging,
      projectName,
      mapTitleArea,
    ]
  );

  return {
    contentOfCellTypePreivew,
  };
};

export default useGetContentTypePreview;
