import { Flex, Progress } from "@chakra-ui/react";
import { memo } from "react";

const LoadingImagePhotoBooks = ({ isLoading }: { isLoading: boolean }) => {
  if (!isLoading) {
    return <></>;
  }

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      position="absolute"
      width="100%"
      height="100%"
      bg="gray"
    >
      <Progress size="xs" isIndeterminate width="60%" />
    </Flex>
  );
};

export default memo(LoadingImagePhotoBooks);
