import { Vector3 } from "interfaces/models";
import { getCurrentViewer } from "..";

import ReactPanel from "./react-panel";

interface CreatePortalOption {
  id?: string;
  visible?: boolean;
  position?: THREE.Vector3 | Vector3;
}

export class PortalExtension extends Autodesk.Viewing.Extension {
  private portals = new Set<
    [THREE.Vector3 | Vector3 | undefined, ReactPanel]
  >();

  load() {
    const updateCallback = () => {
      this.portals.forEach(([position, portal]) => {
        if (!!portal.container && portal.isVisible()) {
          portal.setPosition(position);
        }
      });
    };

    this.viewer.addEventListener(
      Autodesk.Viewing.CAMERA_CHANGE_EVENT,
      updateCallback
    );
    this.viewer.addEventListener(
      Autodesk.Viewing.CUTPLANES_CHANGE_EVENT,
      updateCallback
    );

    return true;
  }

  unload() {
    return true;
  }

  public createPortal(content: React.ReactNode, options: CreatePortalOption) {
    const portal = this.createReactPanel(content, options);

    this.portals.add([options.position, portal]);

    return portal;
  }

  private createReactPanel(
    content: React.ReactNode,
    options: CreatePortalOption
  ) {
    return new ReactPanel(this.viewer, content, {
      id: options.id,
      visible: options.visible ?? true,
      position: options.position,
      placement: "bottom",
    });
  }

  static register = () => {
    Autodesk.Viewing.theExtensionManager.registerExtension(
      "PortalExtension",
      PortalExtension
    );
  };
}

export const getPortalExtension = () => {
  return getCurrentViewer()?.getExtension("PortalExtension") as PortalExtension;
};
