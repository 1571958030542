import { RootState } from "redux/store";
import { useSelector } from "react-redux";
import {
  EditDocumentCategoryPermission,
  EditTaskModalPermission,
  ROLE,
  RoleSettings,
} from "interfaces/models/role";
import { useSearchParams } from "react-router-dom";
import { useMemo } from "react";
import _ from "lodash";

const defaultEditTaskModalPermission: Partial<EditTaskModalPermission> = {
  canEditTaskTypeField: true,
  canEditTagsField: true,
  canEditImagesField: true,
  canEditMemoField: true,
  canEditAssignedUserField: true,
  canEditDeadlineField: true,
  canEditPartnerCompaniesField: true,
  canEditConfirmedImagesField: true,
  canEditConfirmedMemoField: true,
  canEditEndDateScheduledField: true,
  canEditConfirmedDateTimeField: true,
  canEditConfirmedUserField: true,
  canEditAttachsField: true,
  canEditCommentField: true,
  canViewImageOriginal: true,
};

const defaultPermission: RoleSettings = {
  canAddProject: false,
  canEditProject: false,
  canDeleteProject: false,
  canViewProject: false,
  canEditUserList: false,
  canViewUserList: false,
  canAccessDocumentTemplate: false,
  canAccessBlackboardTemplate: false,
  canAccessTaskTypeList: false,
  canAccessUserDashboard: false,
  canAccessProjectDashboard: false,
  canAccessForgeViewer: false,
  canAccessTask: false,
  canAccessTaskSheetDoc: false,
  canAccessDocument: false,
  canAccessCheckItem: false,
  canViewTaskModal: false,
  canEditTaskModal: defaultEditTaskModalPermission,
  canAccessSettingFamily: false,
  canEditTaskList: false,

  // Folder
  canAddFolder: false,
  canEditFolder: false,
  canDeleteFolder: false,

  // Document category
  canAddDocumentCategory: false,
  canEditDocumentCategory: false,
  canDeleteDocumentCategory: false,
  canEditDocumentCategoryTitle: false,
  canEditDocumentCategoryInfo: false,

  // Document items
  canAddDocumentItem: false,
  canEditDocumentItem: false,
  canDeleteDocumentItem: false,

  // Task
  canEditTaskCorrection: false,
  canAddTask: false,
  canDeleteTask: false,
};

export const usePermission = (
  role?: ROLE | string | undefined
): RoleSettings => {
  const [params] = useSearchParams();

  const currentRole = params.get("role") || role;

  let permissions: Partial<RoleSettings> = {};

  switch (currentRole) {
    case ROLE.TAKASAGO_ADMIN:
      permissions = {
        canAddProject: true,
        canEditProject: true,
        canDeleteProject: true,
        canViewProject: true,
        canEditUserList: true,
        canViewUserList: true,
        canAccessDocumentTemplate: true,
        canAccessBlackboardTemplate: true,
        canAccessTaskTypeList: true,
        canAccessUserDashboard: true,
        canAccessProjectDashboard: true,
        canAccessForgeViewer: true,
        canAccessTask: true,
        canAccessTaskSheetDoc: true,
        canAccessDocument: true,
        canAccessCheckItem: true,
        canViewTaskModal: true,
        canAccessSettingFamily: true,
        canAddFolder: true,
        canEditFolder: true,
        canDeleteFolder: true,
        canAddDocumentCategory: true,
        canEditDocumentCategory: true,
        canDeleteDocumentCategory: true,
        canAddDocumentItem: true,
        canEditDocumentItem: true,
        canDeleteDocumentItem: true,
        canDeleteTask: true,
        canEditTaskModal: defaultEditTaskModalPermission,
        canEditTaskList: true,
        canEditDocumentCategoryInfo: true,
        canEditDocumentCategoryTitle: true,
      };
      break;

    case ROLE.TAKASAGO_FIELD_AGENT:
      permissions = {
        canAccessUserDashboard: true,
        canAccessForgeViewer: true,
        canAccessTask: true,
        canAccessDocument: true,
        canAccessCheckItem: true,
        canViewTaskModal: true,
        canEditFolder: true,
        canAddDocumentItem: true,
        canEditDocumentItem: true,
        canDeleteDocumentItem: true,
        canDeleteTask: true,
        canEditTaskModal: defaultEditTaskModalPermission,
        canEditTaskList: true,
        canEditDocumentCategoryTitle: true,
        canEditDocumentCategoryInfo: true,

        canAddFolder: true,
        canAddDocumentCategory: true,
        canEditDocumentCategory: true,
      };
      break;

    case ROLE.TAKASAGO_ONSITE:
      permissions = {
        canAccessUserDashboard: true,
        canAccessForgeViewer: true,
        canAccessTask: true,
        canAccessDocument: true,
        canAccessCheckItem: true,
        canViewTaskModal: true,
        canEditTaskModal: defaultEditTaskModalPermission,
        canAddDocumentItem: true,
        canEditDocumentItem: true,
        canDeleteDocumentItem: true,
        canDeleteTask: true,
        canEditTaskList: true,
        canEditDocumentCategoryTitle: true,
        canEditDocumentCategoryInfo: true,
      };
      break;

    case ROLE.PARTNER_LEADER:
      permissions = {
        canAccessUserDashboard: true,
        canAccessProjectDashboard: true,
        canAccessForgeViewer: true,
        canAccessTask: true,
        canAccessDocument: true,
        canAccessCheckItem: true,
        canViewTaskModal: true,
        canEditTaskModal: {
          canEditEndDateScheduledField: true,
          canEditConfirmedMemoField: true,
          canEditCommentField: true,
          canEditTaskTypeField: false,
          canEditTagsField: false,
          canEditImagesField: false,
          canEditMemoField: false,
          canEditAssignedUserField: false,
          canEditDeadlineField: false,
          canEditPartnerCompaniesField: false,
          canEditConfirmedDateTimeField: false,
          canEditConfirmedUserField: false,
          canEditAttachsField: false,
          canViewImageOriginal: false,
        },
      };
      break;
  }

  return _.merge(defaultPermission, permissions);
};

export const useRoles = () => {
  const { currentUser } = useSelector((state: RootState) => state.user);
  const userRole = currentUser?.role as (typeof ROLE)[keyof typeof ROLE];

  const isTakasagoGroup = useMemo(() => {
    const takasagoRoles = [
      ROLE.TAKASAGO_ADMIN,
      ROLE.TAKASAGO_FIELD_AGENT,
      ROLE.TAKASAGO_ONSITE,
    ];

    return takasagoRoles.includes(userRole);
  }, [userRole]);

  const isTakasagoGroupAndPartnerLeader = useMemo(() => {
    const takasagoRoles = [
      ROLE.TAKASAGO_ADMIN,
      ROLE.TAKASAGO_FIELD_AGENT,
      ROLE.TAKASAGO_ONSITE,
      ROLE.PARTNER_LEADER,
    ];

    return takasagoRoles.includes(userRole);
  }, [userRole]);

  const isAdmin = useMemo(() => {
    return userRole === ROLE.TAKASAGO_ADMIN;
  }, [userRole]);

  const isTakasagoAgent = useMemo(() => {
    return userRole === ROLE.TAKASAGO_FIELD_AGENT;
  }, [userRole]);

  const isPartner = useMemo(() => {
    const partnerRoles = [ROLE.PARTNER_LEADER, ROLE.PARTNER_MEMBER];

    return partnerRoles.includes(userRole);
  }, [userRole]);

  const isTakasagoUser = useMemo(() => {
    return !isAdmin && isTakasagoGroup;
  }, [isAdmin, isTakasagoGroup]);

  const isLeader = useMemo(() => userRole === ROLE.PARTNER_LEADER, [userRole]);

  return {
    isTakasagoGroup,
    isAdmin,
    isPartner,
    isTakasagoGroupAndPartnerLeader,
    isTakasagoUser,
    isTakasagoAgent,
    isLeader,
  };
};

export const useAuthorization = (): RoleSettings => {
  const { currentUser } = useSelector((state: RootState) => state.user);
  const permissions = usePermission(currentUser?.role);

  return currentUser?.role ? permissions : defaultPermission;
};
