import { LinkedDataField } from "constants/enum";
import { memo } from "react";

interface iProps
  extends React.DetailedHTMLProps<
    React.LabelHTMLAttributes<HTMLLabelElement>,
    HTMLLabelElement
  > {
  checked: boolean;
  label?: string;
}
const CheckBox: React.FC<iProps> = ({ checked, label, ...rest }) => {
  return (
    <label
      className="custom-checkbox"
      style={{
        position: "relative",
        marginLeft: "0.5rem",
        display: "inline-flex",
        alignItems: "center",
        verticalAlign: "top",
        width: "fit-content",
      }}
      {...rest}
    >
      {checked ? (
        <svg
          data-field-type={LinkedDataField.COMMON.CHECK_BOX}
          aria-checked="true"
          viewBox="0 0 14 14"
          focusable="false"
          style={{
            cursor: "pointer",
            width: "16px",
            border: "1px solid rgb(212, 212, 213)",
            borderRadius: "4px",
            height: "16px",
            verticalAlign: "middle",
            color: "rgb(0, 0, 0)",
          }}
        >
          <g fill="currentColor">
            <polygon points="5.5 11.9993304 14 3.49933039 12.5 2 5.5 8.99933039 1.5 4.9968652 0 6.49933039"></polygon>
          </g>
        </svg>
      ) : (
        <svg
          data-field-type={LinkedDataField.COMMON.CHECK_BOX}
          aria-checked="false"
          viewBox="0 0 14 14"
          focusable="false"
          style={{
            cursor: "pointer",
            width: "16px",
            height: "16px",
            border: "1px solid rgb(212, 212, 213)",
            borderRadius: "4px",
            verticalAlign: "middle",
            color: "rgb(0, 0, 0)",
          }}
        >
          <g fill="currentColor"></g>
        </svg>
      )}

      <span
        className="custom-checkbox__label"
        style={{
          marginInlineStart: "0.5rem",
          msUserSelect: "none",
          userSelect: "none",
          fontSize: "1.4rem",
          marginLeft: "1rem",
        }}
      >
        {label || ""}
      </span>
    </label>
  );
};

export default memo(CheckBox);
