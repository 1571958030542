import { documentTemplateApi, taskApi, taskTypeApi } from "apiClient/v2";
import { MessageType } from "constants/websocket";
import { useAppWebSocket } from "hooks/useAppWebSocket";
import { DocumentItemDTO } from "interfaces/dtos/documentItemDTO";

import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addDocumentGroup,
  removeDocumentGroup,
  setDocumentCategories,
  setDocumentCategory,
  setDocumentTemplates,
  updateDocumentCategory,
  updateDocumentItem,
  updateDocumentItems,
} from "redux/documentSlice";
import { resetState } from "redux/forgeViewerSlice";
import { RootState } from "redux/store";
import { removeTask, setTask, setTaskType } from "redux/taskSlice";

const useSyncTaskData = () => {
  const dispatch = useDispatch();
  const { webSocketMessage, isWebSocketOpen, sendWebSocketMessage } =
    useAppWebSocket();

  const { documentCategories, documentItems, documentTemplates } = useSelector(
    (state: RootState) => state.document
  );

  const { levelSelected } = useSelector(
    (state: RootState) => state.forgeViewer
  );
  const levelSelectedRef = useRef(levelSelected);
  const documentTemplatesRef = useRef(documentTemplates);
  const documentItemsRef = useRef(documentItems);
  const documentCategoriesRef = useRef(documentCategories);

  useEffect(() => {
    levelSelectedRef.current = levelSelected;
    documentTemplatesRef.current = documentTemplates;
    documentItemsRef.current = documentItems;
    documentCategoriesRef.current = documentCategories;
  }, [levelSelected, documentTemplates, documentItems, documentCategories]);

  useEffect(() => {
    if (!webSocketMessage) {
      return;
    }
    const { type, data, docCategoryId, docItemId } = webSocketMessage;
    const level = levelSelectedRef.current;

    switch (type) {
      case MessageType.UPDATE_TASK_TYPE:
      case MessageType.ADD_TASK_TYPE:
        data.id &&
          taskTypeApi.getTaskType(data.id).then((data) => {
            data.data?.id && dispatch(setTaskType(data.data));
          });
        break;

      case MessageType.DELETE_TASK:
        dispatch(removeTask(data));
        dispatch(resetState());

        break;

      case MessageType.UPDATE_TASK:
        if ((!level.guid || data?.level === level.label) && data.id) {
          taskApi.getTask(data.id).then((data) => {
            data.data?.id && dispatch(setTask(data.data));
          });
        }

        break;

      case MessageType.ADD_DOC_GROUP:
        dispatch(addDocumentGroup(data));
        break;

      case MessageType.DELETE_DOC_GROUP:
        dispatch(removeDocumentGroup(data));
        break;

      case MessageType.CHANGE_DOC_CATEGORY_MODAL_INFO:
        const docCate = documentCategoriesRef.current.find(
          (e) => e.id === docCategoryId
        );

        dispatch(setDocumentCategory({ ...docCate, ...data }));

        break;

      case MessageType.CHANGE_DOC_ITEM:
        let docItem = documentItemsRef.current.find((e) => e.id === docItemId);

        if (docItem?.id) {
          docItem = { ...docItem, ...data } as DocumentItemDTO;
          dispatch(updateDocumentItem(docItem));
        }

        break;

      case MessageType.CHANGE_DOC_ITEMS:
        const _data: {
          documentItemId: string;
          status: string;
        }[] = data;

        const updatedDocumentItems: DocumentItemDTO[] = [];

        _data.forEach((item) => {
          let docItem = documentItemsRef.current.find(
            (e) => e.id === item.documentItemId
          );
          if (docItem?.id) {
            docItem = {
              ...docItem,
              updatedAt: new Date(),
              status: item.status,
            };

            updatedDocumentItems.push(docItem);
          }

          dispatch(updateDocumentItems(updatedDocumentItems));
        });

        break;

      case MessageType.CHANGE_DOC_ITEM_BLACKBOARD:
        dispatch(updateDocumentItem(data));
        break;

      case MessageType.CHANGE_DOCUMENT_TEMPLATE:
        (async () => {
          const documentTemplateId = data;
          const { data: documentTemplateDetail } =
            await documentTemplateApi.getTemplate(documentTemplateId);
          const cloneDocumentTemplates = structuredClone(
            documentTemplatesRef.current
          );
          if (documentTemplateDetail?.id) {
            cloneDocumentTemplates[documentTemplateDetail.id] =
              documentTemplateDetail;

            dispatch(
              setDocumentTemplates(Object.values(cloneDocumentTemplates))
            );
          }
        })();
        break;

      case MessageType.CHANGE_DOC_CATEGORY:
        const documentCategory = documentCategoriesRef.current.find(
          (node) => node.id === docCategoryId
        );
        if (!documentCategory) break;
        const category = structuredClone(documentCategory);
        const merged = { ...category, ...data };
        dispatch(updateDocumentCategory(merged));

        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webSocketMessage]);

  return { webSocketMessage, isWebSocketOpen, sendWebSocketMessage };
};

export default useSyncTaskData;
