import { Box } from "@chakra-ui/react";
import { LINE_HEIGHT_DEFAULT } from "components/modal/PreviewDocumentCategory/hooks/useHandleTextOverflow";
import { PHOTO_BOOK_CONTENT_TYPE } from "constants/enum";
import { TaskDTO } from "interfaces/dtos/taskDTO";
import { EditData } from "interfaces/models";
import { DocumentTaskDataFieldPhotoBooks } from "interfaces/models/documentTask";
import { memo, useCallback, useRef } from "react";
import { iHandleDocumentTaskDetail } from "../hooks/usePreviewTaskSheetTemplate";
import ContentOfField from "./ContentOfField";

interface iProps {
  confirmedMemo: string;
  endDateScheduled: string;
  confirmedDateTime: string;
  userConfirmed: string;
  task: TaskDTO;

  photoBookContentKey?: string;
  editMode: boolean;
  defaultValue?: string;
  editDataStyle: EditData["style"];

  onEditPhotoBooksContent?: iHandleDocumentTaskDetail["onEditPhotoBooksContent"];
}

const ContentPhotoBooksReport = ({
  task,
  editMode,
  editDataStyle,
  confirmedMemo,
  endDateScheduled,
  confirmedDateTime,
  userConfirmed,
  photoBookContentKey,
  onEditPhotoBooksContent,
}: iProps) => {
  const boxContainerRef = useRef<HTMLDivElement>(null);
  const sizeDefaultRef = useRef({
    fontSize: 14,
    lineHeight: 14 * LINE_HEIGHT_DEFAULT,
  });

  const handleSubmit = useCallback(
    (field: keyof DocumentTaskDataFieldPhotoBooks) => (data: EditData) => {
      const element = boxContainerRef.current;
      if (!photoBookContentKey || !element) {
        return;
      }

      const fontSizeNumber = Number(
        getComputedStyle(element).fontSize.replace("px", "")
      );
      const style = {
        fontSize: fontSizeNumber,
        lineHeight: fontSizeNumber * LINE_HEIGHT_DEFAULT,
      };

      const newData = structuredClone(data);
      newData.origin = (task as any)?.[field];

      onEditPhotoBooksContent?.({
        key: photoBookContentKey,
        data: {
          [field]: newData,
          reportStyle: style,
          taskId: task.id,
          type: PHOTO_BOOK_CONTENT_TYPE.REPORT,
        },
      });
    },
    [onEditPhotoBooksContent, photoBookContentKey, task]
  );

  return (
    <Box
      ref={boxContainerRef}
      lineHeight={`${
        editDataStyle?.lineHeight || sizeDefaultRef.current.lineHeight
      }px`}
      fontSize={`${
        editDataStyle?.fontSize || sizeDefaultRef.current.fontSize
      }px`}
      textAlign="left"
      height="100%"
    >
      <ContentOfField
        editMode={editMode}
        value={confirmedMemo}
        editDataStyle={editDataStyle}
        onEditSubmit={handleSubmit("confirmedMemo")}
      />
      <ContentOfField
        editMode={editMode}
        value={endDateScheduled}
        editDataStyle={editDataStyle}
        onEditSubmit={handleSubmit("endDateScheduled")}
      />
      <ContentOfField
        editMode={editMode}
        value={confirmedDateTime}
        editDataStyle={editDataStyle}
        onEditSubmit={handleSubmit("confirmedDateTime")}
      />
      <ContentOfField
        editMode={editMode}
        value={userConfirmed}
        editDataStyle={editDataStyle}
        onEditSubmit={handleSubmit("userConfirmed")}
      />
    </Box>
  );
};

export default memo(ContentPhotoBooksReport);
