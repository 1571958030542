import { Tooltip, TooltipProps } from "@chakra-ui/react";
import React from "react";
import { useDeviceSelectors } from "react-device-detect";

const PCTooltip = React.forwardRef<HTMLParagraphElement, TooltipProps>(
  ({ children, ...props }, ref) => {
    const [{ isMobile }] = useDeviceSelectors(window.navigator.userAgent);

    return isMobile ? (
      <React.Fragment>{children}</React.Fragment>
    ) : (
      <Tooltip ref={ref} {...props}>
        {children}
      </Tooltip>
    );
  }
);

export { PCTooltip };
