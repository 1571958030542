export const isPercentage = (val: string) => {
  return typeof val === "string" && val.indexOf("%") > -1;
};

export const percentToPx = (value: any, comparativeValue: number) => {
  if (value.indexOf("px") > -1 || value === "auto" || !comparativeValue) {
    return value;
  }

  return `${(parseInt(value) / 100) * comparativeValue}px`;
};
export const pxToPercent = (value: any, comparativeValue: number) => {
  const val = (Math.abs(value) / comparativeValue) * 100;
  if (value < 0) {
    return -1 * val;
  }

  return Math.round(val);
};
export const getElementDimensions = (element: HTMLElement) => {
  const computedStyle = getComputedStyle(element);

  let height = element.clientHeight,
    width = element.clientWidth; // width with padding

  height -=
    parseFloat(computedStyle.paddingTop) +
    parseFloat(computedStyle.paddingBottom);
  width -=
    parseFloat(computedStyle.paddingLeft) +
    parseFloat(computedStyle.paddingRight);

  return {
    width,
    height,
  };
};

export const centimetersToPixels = (value: string) => {
  const numberCentimeter = Number((value ?? "").replace("cm", ""));

  return Math.round(numberCentimeter * (96 / 2.54));
};
