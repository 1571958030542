import { documentItemLogApi } from "apiClient/v2";
import { DocumentItemLog } from "interfaces/models/documentItem";
import { useEffect, useState } from "react";
import { sortArrayByField } from "utils/array";

interface Props {
  itemId?: string;
}

const useItemLogs = (props?: Props) => {
  const { itemId } = props || {};

  const [itemLogs, setItemLogs] = useState<DocumentItemLog[]>([]);

  useEffect(() => {
    (async () => {
      if (!itemId) {
        return;
      }

      const documentItemLogs = await documentItemLogApi.handleGetItemLogList({
        itemId: itemId,
      });
      setItemLogs(
        sortArrayByField<DocumentItemLog>(documentItemLogs, "createdAt")
      );
    })();
  }, [itemId]);

  return { itemLogs, setItemLogs };
};

export default useItemLogs;
