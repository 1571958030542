import uniqBy from "lodash/uniqBy";
import { CellProperty, TemplateComponentType } from "constants/enum";
import { DocumentTemplate } from "interfaces/models/documentTemplate";
import { useMemo } from "react";
import { getAllCells } from "utils/tableCell";

interface Props {
  template: DocumentTemplate | undefined;
}

const useFormCategoryForDocumentData = (props: Props) => {
  const { template } = props;

  const documentDataCells = useMemo(() => {
    if (!template) {
      return [];
    }

    const components = (template?.components || []).filter(
      (component) =>
        component.type === TemplateComponentType.Table &&
        !component?.linkedHeaderId
    );

    const cells = components
      .map((component) => getAllCells(component))
      .flat(1)
      .filter((cell) => {
        const field = cell?.cellLinkedData?.field;

        return (
          cell.cellProperty === CellProperty.DOCUMENT_DATA &&
          !!field &&
          field !== CellProperty.DYNAMIC_FIELDS_FOR_CATEGORY
        );
      });

    return uniqBy(cells, "cellLinkedData.field");
  }, [template]);

  return { documentDataCells };
};

export default useFormCategoryForDocumentData;
