import { blackboardApi, documentItemApi } from "apiClient/v2";
import { message } from "components/base";
import { DocumentItemKey } from "constants/enum";
import { FILE_SIZE_ERROR_MESSAGE, LIMIT_FILE_SIZE } from "constants/file";
import {
  DocumentItemDTO,
  DocumentSubItemDTO,
} from "interfaces/dtos/documentItemDTO";
import { FileModel, FileUploadInfo } from "interfaces/models";
import { Blackboard } from "interfaces/models/blackboard";
import { BlackboardTemplate } from "interfaces/models/blackboardTemplate";
import { SizePosition } from "interfaces/models/rnd";
import { User } from "interfaces/models/user";
import { GetContentItemLog, OPERATION_ITEM_LOG } from "models/documentItemLog";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { updateDocumentItem } from "redux/documentSlice";
import { updateElementInArray } from "utils/array";
import {
  compressionImageByFile,
  fileToBase64,
  getDateTimeOriginalExif,
  uploadFileToS3,
} from "utils/file";

interface Props {
  subItem?: DocumentSubItemDTO;
  dataBlackboard?: Blackboard;
  blackboardTemplate?: BlackboardTemplate;
  documentItemSelected?: DocumentItemDTO;
  currentUser?: User | null;
  originImgSelectedTemp: FileModel;

  insertSubItemLog: (params: GetContentItemLog) => Promise<void>;
  onOpenPositionBlackboardModal: () => void;
  onClosePositionBlackboardModal: () => void;
  handleUpdateDataBlackboardState: (data: Partial<Blackboard>) => void;
}

const usePhoto = (props: Props) => {
  const {
    subItem,
    dataBlackboard,
    blackboardTemplate,
    documentItemSelected,
    currentUser,
    originImgSelectedTemp,
    insertSubItemLog,
    onOpenPositionBlackboardModal,
    handleUpdateDataBlackboardState,
    onClosePositionBlackboardModal,
  } = props;

  const [image, setImage] = useState<FileModel | undefined>();

  const dispatch = useDispatch();
  const isHasBlackboard = useMemo(
    () =>
      !!Object.keys(image?.pos || {})?.length &&
      !!dataBlackboard &&
      !!blackboardTemplate?.id,
    [image, dataBlackboard, blackboardTemplate]
  );

  const isHasBlackboardWithOutPosition = useMemo(
    () => !!dataBlackboard && !!blackboardTemplate?.id,
    [dataBlackboard, blackboardTemplate]
  );

  useEffect(() => {
    const image = structuredClone(subItem?.images);

    if (!subItem?.id || !(image as any)?.src || !image) {
      setImage(undefined);

      return;
    }

    if ("file" in image) {
      delete image.file;
    }

    setImage({
      ...image,
      pos: subItem?.blackboardImagePosition as SizePosition,
    });
  }, [subItem?.id, subItem?.images, subItem?.blackboardImagePosition]);

  const blackboardPositionTemp = useMemo(() => {
    return [
      image?.pos?.x ?? 0,
      image?.pos?.y ?? 0,
      image?.pos?.width ?? 0,
      image?.pos?.imgWidth ?? 0,
      image?.pos?.height ?? 0,
      image?.pos?.imgHeight ?? 0,
    ];
  }, [image?.pos]);

  const handleClickSelectFile = useCallback(
    (event: React.MouseEvent<HTMLInputElement>) => {
      const target = event.target as HTMLInputElement;
      target.value = "";
    },
    []
  );

  const handleChangeFile = useCallback(
    async (event: React.MouseEvent<HTMLInputElement>) => {
      if (!documentItemSelected?.id || !subItem?.id) {
        return;
      }

      const target = event.target as any;
      const file: File & {
        lastModifiedDate?: Date;
        lastModifiedDateFile?: Date;
      } = (target.files as FileList)?.[0];

      if (file.size >= LIMIT_FILE_SIZE) {
        return message.error(FILE_SIZE_ERROR_MESSAGE);
      }

      const dateTimeOriginal = await getDateTimeOriginalExif(file);
      const imgString = await fileToBase64(file);
      const newFile = await compressionImageByFile({
        file: file,
        fileName: file.name,
      });

      const fileModel: FileModel = {
        src: imgString,
        name: file.name,
        isTemp: true,
        file: newFile,
        lastModifiedDateFile: dateTimeOriginal
          ? dateTimeOriginal
          : file.lastModifiedDate
          ? file.lastModifiedDate
          : undefined,
      };

      setImage(fileModel);

      if (blackboardTemplate?.id) {
        return onOpenPositionBlackboardModal();
      }

      const now = new Date();
      const documentItem = structuredClone(documentItemSelected);
      updateElementInArray({
        array: documentItem?.subItems || [],
        keyIndex: "id",
        element: {
          ...subItem,
          images: fileModel,
          updatedAt: now,
          isLoadingUpdateImage: true,
        } as DocumentSubItemDTO,
      });
      dispatch(updateDocumentItem(documentItem));
      const src = await uploadFileToS3(file, file?.name || "");
      const fileUploadInfo = {
        src,
        originSrc: src,
        uploadTime: new Date(),
        lastModifiedDateFile: fileModel.lastModifiedDateFile,
        userUpload: currentUser?.id || "",
      };

      const shootingTime = new Date();

      await Promise.all([
        documentItemApi.updateSubItem({
          id: subItem.id,
          itemId: subItem.itemId,
          images: fileUploadInfo,
          isIgnoreInsertLog: true,
        } as DocumentSubItemDTO),
        blackboardApi.updateBlackboard({
          id: subItem.blackboardId,
          documentCategoryId: documentItemSelected.documentCategoryId,
          shootingTime,
        }),
      ]);

      await insertSubItemLog({
        field: DocumentItemKey.IMAGES as any,
        value: fileUploadInfo,
        operation: OPERATION_ITEM_LOG.CREATE,
      });

      updateElementInArray({
        array: documentItem?.subItems || [],
        keyIndex: "id",
        element: {
          ...subItem,
          images: fileUploadInfo,
          updatedAt: now,
          isLoadingUpdateImage: false,
        } as DocumentSubItemDTO,
      });

      handleUpdateDataBlackboardState({ shootingTime });
      dispatch(updateDocumentItem(documentItem));
    },
    [
      currentUser?.id,
      documentItemSelected,
      subItem,
      blackboardTemplate?.id,
      insertSubItemLog,
      handleUpdateDataBlackboardState,
      onOpenPositionBlackboardModal,
      dispatch,
    ]
  );

  const handleSelectBlackboardPosition = useCallback(
    async (position: SizePosition) => {
      if (!documentItemSelected?.id || !subItem?.id || !currentUser?.id) {
        return;
      }

      const blackboardImagePosition: SizePosition = position;
      const documentItem = structuredClone(documentItemSelected);
      const file = image?.file;
      const now = new Date();

      updateElementInArray({
        array: documentItem?.subItems || [],
        keyIndex: "id",
        element: {
          ...subItem,
          isShowBlackboard: true,
          updatedAt: now,
          images: image,
          blackboardImagePosition,
          isLoadingUpdateImage: !!file,
        } as DocumentSubItemDTO,
      });

      dispatch(updateDocumentItem(documentItem));
      onClosePositionBlackboardModal();

      let fileUploadInfo: FileUploadInfo = structuredClone(
        subItem.images as any
      );
      if (file) {
        const src = await uploadFileToS3(file, file?.name || "");
        fileUploadInfo = {
          src,
          uploadTime: new Date(),
          lastModifiedDateFile: image.lastModifiedDateFile,
          userUpload: currentUser?.id || "",
          originSrc: image.originSrc ?? src,
        };
        if (originImgSelectedTemp.file) {
          const src = await uploadFileToS3(
            originImgSelectedTemp.file,
            originImgSelectedTemp?.name || ""
          );

          fileUploadInfo.originSrc = src;
        }
      }
      const shootingTime = new Date();
      await Promise.all([
        documentItemApi.updateSubItem({
          id: subItem.id,
          itemId: subItem.itemId,
          images: fileUploadInfo,
          isShowBlackboard: true,
          blackboardImagePosition,
          isIgnoreInsertLog: true,
        } as DocumentSubItemDTO),
        blackboardApi.updateBlackboard({
          id: subItem.blackboardId,
          documentCategoryId: documentItemSelected.documentCategoryId,
          shootingTime,
        }),
      ]);

      const promiseList: Promise<any>[] = [
        insertSubItemLog({
          field: DocumentItemKey.IMAGES as any,
          value: fileUploadInfo,
          operation: subItem?.images
            ? OPERATION_ITEM_LOG.UPDATE
            : OPERATION_ITEM_LOG.CREATE,
        }),
        insertSubItemLog({
          field: DocumentItemKey.BLACKBOARD_IMAGE_POSITION as any,
          value: blackboardImagePosition,
        }),
      ];

      if (!subItem.isShowBlackboard) {
        promiseList.push(
          insertSubItemLog({
            field: DocumentItemKey.IS_SHOW_BLACKBOARD as any,
            value: true,
          })
        );
      }

      await Promise.all(promiseList);

      if (file) {
        updateElementInArray({
          array: documentItem?.subItems || [],
          keyIndex: "id",
          element: {
            ...subItem,
            isShowBlackboard: true,
            updatedAt: now,
            images: fileUploadInfo,
            blackboardImagePosition,
            isLoadingUpdateImage: false,
          } as DocumentSubItemDTO,
        });
      }

      handleUpdateDataBlackboardState({ shootingTime });
      dispatch(updateDocumentItem(documentItem));
    },
    [
      originImgSelectedTemp,
      documentItemSelected,
      image,
      currentUser,
      subItem,
      dispatch,
      insertSubItemLog,
      handleUpdateDataBlackboardState,
      onClosePositionBlackboardModal,
    ]
  );

  const handleClosePositionBlackboardModal = useCallback(() => {
    onClosePositionBlackboardModal();
    setImage({
      ...subItem?.images,
      pos: (subItem?.blackboardImagePosition || {}) as SizePosition,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subItem?.images, subItem?.blackboardImagePosition]);

  return {
    isHasBlackboard,
    isHasBlackboardWithOutPosition,
    image,
    setImage,
    blackboardPositionTemp,
    handleClickSelectFile,
    handleChangeFile,
    handleSelectBlackboardPosition,
    handleClosePositionBlackboardModal,
  };
};

export default usePhoto;
