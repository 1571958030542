import { Flex, MenuButtonProps } from "@chakra-ui/react";
import { CURRENT_SYSTEM_MODE_KEY } from "constants/app";
import {
  DocumentCategoryStatusType,
  MapDocumentCategoryStatusTypeColor,
  ModalType,
  SystemModeType,
} from "constants/enum";
import { DocumentItemDTO } from "interfaces/dtos/documentItemDTO";
import { Vector3 } from "interfaces/models";
import { isObject } from "lodash";
import { isEmpty } from "lodash";
import { handleSelectTask } from "models/task";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useNavigate } from "react-router-dom";
import {
  toggleIsCaptureKeynoteByOperation,
  setIsLoadingDocument,
} from "redux/documentSlice";
import { setModalType, setSystemMode } from "redux/forgeViewerSlice";
import { RootState } from "redux/store";
import { routePath } from "routes/path";
import { getBimFileInfo } from "utils/bim";
import { updateForgeWhenSelectCategory } from "utils/document";
import { hightLightDocumentItem } from "utils/documentItem";
import {
  getCurrentViewer,
  selectDbIds,
  setCameraToHomeAsync,
} from "utils/forge";
import { getAreaExtension } from "utils/forge/extensions/area-extension";
import { clearSelectedLabel } from "utils/forge/extensions/custom-label";
import { getLocalStorage, setLocalStorage } from "utils/storage";

interface Props extends MenuButtonProps {
  onSelectChange?: (value: SystemModeType) => void;
  isForgePage?: boolean;
  isMobile?: boolean;
  projectId?: string;
}

export const changeStoredSystemMode = (mode: string) => {
  let currentSettingSystemMode = getLocalStorage(CURRENT_SYSTEM_MODE_KEY) || {};
  if (!isObject(currentSettingSystemMode)) currentSettingSystemMode = {};
  currentSettingSystemMode["value"] = mode;
  setLocalStorage(CURRENT_SYSTEM_MODE_KEY, currentSettingSystemMode);
};

export default function SystemModeSwitch({
  onSelectChange,
  isForgePage,
  projectId = "",
}: Props) {
  const { systemMode, levelSelected } = useSelector(
    (state: RootState) => state.forgeViewer
  );
  const { dataProjects } = useSelector((state: RootState) => state.project);
  const { taskSelected } = useSelector((state: RootState) => state.task);
  const {
    documentCategories,
    documentCategorySelected,
    documentItemSelected,
    documentSubCategorySelected,
  } = useSelector((state: RootState) => state.document);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChangeSystemMode = useCallback(
    async (value: any) => {
      changeStoredSystemMode(value);
      dispatch(setSystemMode(value));
      onSelectChange?.(value as SystemModeType);
      const viewer = getCurrentViewer() as
        | Autodesk.Viewing.GuiViewer3D
        | undefined;

      if (!isForgePage) {
        const project = dataProjects.find((p) => p.id === projectId);
        const urn = project?.defaultBimPathId?.split("/").pop();
        if (urn) {
          const { bimFileId, version } = getBimFileInfo(urn);
          navigate(
            generatePath(routePath.ForgeViewer, {
              projectId: projectId ?? "",
              bimFileId: encodeURIComponent(bimFileId),
              version,
            })
          );
        }
      }

      viewer?.clearSelection();
      clearSelectedLabel();
      getAreaExtension()?.clearSelection();
      if (value === SystemModeType.Task) {
        dispatch(toggleIsCaptureKeynoteByOperation(false));

        if (!isEmpty(taskSelected)) {
          dispatch(setModalType(ModalType.TASK));
          handleSelectTask(taskSelected, dispatch);

          return;
        }

        if (!isEmpty(documentCategorySelected)) {
          selectDbIds([], {});
        }

        if (viewer) {
          viewer.clearSelection();
          await setCameraToHomeAsync(viewer);
        }

        return;
      }

      if (
        value === SystemModeType.Document &&
        documentCategories.every(
          (category) => category.level !== levelSelected.label
        )
      ) {
        dispatch(setIsLoadingDocument(true));
      }

      if (!isEmpty(documentItemSelected)) {
        dispatch(setModalType(ModalType.DOC_ITEM));
        hightLightDocumentItem(documentItemSelected);

        return;
      }

      if (!isEmpty(documentSubCategorySelected?.documentItems?.[0])) {
        dispatch(setModalType(ModalType.DOC_SUB_CATEGORY));
        hightLightDocumentItem(
          documentSubCategorySelected?.documentItems?.[0] as DocumentItemDTO
        );

        return;
      }
      if (!isEmpty(documentCategorySelected)) {
        dispatch(setModalType(ModalType.DOC_CATEGORY));

        updateForgeWhenSelectCategory(documentCategorySelected);

        return;
      }

      if (viewer) {
        viewer.clearSelection();

        await setCameraToHomeAsync(viewer);
      }
    },
    [
      dispatch,
      onSelectChange,
      isForgePage,
      levelSelected.label,
      documentCategories,
      documentItemSelected,
      documentSubCategorySelected,
      documentCategorySelected,
      dataProjects,
      projectId,
      navigate,
      taskSelected,
    ]
  );

  useEffect(() => {
    const currentSettingSystemMode =
      getLocalStorage(CURRENT_SYSTEM_MODE_KEY) || {};
    const systemModeCurrent =
      currentSettingSystemMode["value"] || SystemModeType.Task;

    dispatch(setSystemMode(systemModeCurrent));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    changeStoredSystemMode(systemMode);
  }, [systemMode]);

  return (
    <Flex
      height="100%"
      p="0"
      w="25.6rem"
      ml="6.4rem"
      gap="1rem"
      fontWeight="bold"
      fontSize="1.4rem"
    >
      <Flex
        height="100%"
        alignItems="center"
        justifyContent="center"
        p=".8rem 2.4rem .8rem 2.4rem"
        borderBottom={
          systemMode === SystemModeType.Document ? "2px solid #009BE0" : "none"
        }
        cursor="pointer"
        onClick={() => handleChangeSystemMode(SystemModeType.Document)}
        style={{
          color: systemMode === SystemModeType.Document ? "#009BE0" : undefined,
        }}
      >
        書類一覧
      </Flex>
      <Flex
        height="100%"
        alignItems="center"
        justifyContent="center"
        p=".8rem 2.4rem .8rem 2.4rem"
        borderBottom={
          systemMode === SystemModeType.Task ? "2px solid #009BE0" : "none"
        }
        cursor="pointer"
        onClick={() => handleChangeSystemMode(SystemModeType.Task)}
        style={{
          color: systemMode === SystemModeType.Task ? "#009BE0" : undefined,
        }}
      >
        指摘一覧
      </Flex>
    </Flex>
  );
}
