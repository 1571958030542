/* eslint-disable @typescript-eslint/no-unused-expressions */
import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import axiosRetry from "axios-retry";
import { message } from "components/base";
import { AXIOS_TIMEOUT_ERROR_MESSAGE } from "constants/api";
import { EXP_TOKEN_COOKIE } from "constants/storage";
import store from "redux/store";
import { doRefreshToken } from "utils/authen";
import { getLocalStorage } from "utils/storage";
import { logout } from "utils/user";

export let axiosController = new AbortController();
export let axiosCacheController = new AbortController();

export const resetAxiosController = () => {
  axiosController = new AbortController();
};

export const resetAxiosCacheController = () => {
  axiosCacheController = new AbortController();
};

interface AxiosClientProps {
  config: AxiosRequestConfig;
}

class AxiosClient {
  readonly instance: AxiosInstance;
  readonly config: AxiosRequestConfig;
  public constructor({ config }: AxiosClientProps) {
    this.config = config;
    const { headers, ...restConfig } = config;

    this.instance = axios.create({
      timeout: 600000,
      timeoutErrorMessage: AXIOS_TIMEOUT_ERROR_MESSAGE,
      headers: {
        ...headers,
        "Content-Type": "application/json",
      },
      ...restConfig,
    });
    this._initializeResponseInterceptor();
  }

  private _initializeResponseInterceptor = () => {
    this.instance.interceptors.request.use(
      async (config: AxiosRequestConfig) => {
        const isOnline = store.getState().app.isOnline;
        // not send any request if on page 403
        if (isOnline && window.location.pathname === "/access-error") {
          return;
        }

        const isShouldCache = !!config?.params?.shouldCache;
        if (isShouldCache) {
          config.signal = axiosCacheController.signal;
          //@ts-ignore
          config.throwErrorAndRetry = true;
        } else {
          config.signal = axiosController.signal;
        }
        // Should cache is only used offline, but when online, if it is transmitted
        // the data in the cache will not match because the url is not the same
        delete config?.params?.shouldCache;

        const exp = getLocalStorage(EXP_TOKEN_COOKIE, null);
        // Check exp time less than 5 minutes from current time then refresh token
        if (isOnline && Number(exp) - 5 * 60 * 1000 < Date.now() && !!exp) {
          await doRefreshToken();
        }

        return config;
      },
      (error: AxiosError) => Promise.reject(error)
    );

    this.instance.interceptors.response.use(
      (response: AxiosResponse) => {
        const { data } = response;
        const errors = data.errors;
        const errorDetail = Array.isArray(errors) ? errors[0] : null;
        const errorCode = errorDetail?.code || 0;
        if (
          //@ts-ignore
          response?.config?.throwErrorAndRetry &&
          (errorCode || (response?.status !== 200 && response?.status !== 202))
        ) {
          // if error -> we must retry
          throw Error("エラーが発生しました。再度実行お願いします。");
        }
        const isOnline = store.getState().app.isOnline;
        const isCachingProject =
          !!store.getState().project.cachingProjectBimFileId;
        if (!isOnline && response?.status === 503) {
          return;
        }
        const syncOffline = errorDetail?.syncOffline || false;
        const isIgnoreShowMessageError = syncOffline && errorCode;
        if (
          errors &&
          isOnline &&
          !isCachingProject &&
          !isIgnoreShowMessageError
        ) {
          message.error([
            "エラーが発生しました。再度実行お願いします。",
            "システムエラー",
          ]);
        }

        return response.data;
      },
      async (error: AxiosError) => {
        // with case download project if has error -> alway throw error
        //@ts-ignore
        if (error?.config?.throwErrorAndRetry) {
          return Promise.reject(error);
        }
        const isCachingProject =
          !!store.getState().project.cachingProjectBimFileId;
        const isOnline = store.getState().app.isOnline;
        if (axios.isCancel(error)) {
          return;
        }

        if (error.config.url?.includes("/forge-token")) {
          return;
        }

        if (!isOnline && error?.response?.status === 503) {
          return;
        }

        const {
          response,
          config: { baseURL },
        } = error;
        const errStatus = response?.status!;

        if (
          errStatus === 403 &&
          baseURL?.includes(process.env.REACT_APP_API_HOST_URL!)
        ) {
          return window.location.replace(
            `${window.location.origin}/access-error`
          );
        }

        if (errStatus === 401) {
          return logout();
        }

        const errors = error.response?.data.errors;
        const errorDetail = Array.isArray(errors) ? errors[0] : null;
        const errorCode = errorDetail?.code || 0;
        const syncOffline = errorDetail?.syncOffline || false;

        const isIgnoreShowMessageError =
          error?.config?.headers?.isIgnoreShowMessageError === "true" ||
          (syncOffline && errorCode);

        if (
          isOnline &&
          !isIgnoreShowMessageError &&
          !error.config.url?.includes("/thumbnail") &&
          !isCachingProject
        ) {
          if (errStatus === 500 && errorDetail?.shouldRetry) {
            return Promise.reject(error);
          }
          // const traceId = response?.headers['neptune-trace-id'];
          message.error([
            "エラーが発生しました。再度実行お願いします。",
            "システムエラー",
          ]);
        }

        const result = {
          apiPath: error.config.url,
          errorCode: error.response?.status || error.message,
        };

        return Promise.resolve(result);
      }
    );
  };
}

export const axiosECS = new AxiosClient({
  config: {
    baseURL: process.env.REACT_APP_API_HOST_URL || "",
    withCredentials: true,
  },
}).instance;

export const axiosSaturn = new AxiosClient({
  config: {
    baseURL: process.env.REACT_APP_SATURN_HOST_URL || "",
    withCredentials: true,
  },
}).instance;

axiosRetry(axiosECS, {
  retries: 10,
  shouldResetTimeout: true,
  retryDelay: (retryCount) => {
    return retryCount * 3000;
  },
  retryCondition(error: AxiosError) {
    const errors = error.response?.data.errors;
    const isOnline = store.getState().app.isOnline;
    const config = error.config;

    return (
      isOnline &&
      ((Array.isArray(errors) && errors[0]?.shouldRetry) ||
        //@ts-ignore
        config.throwErrorAndRetry)
    );
  },
});
