import { InspectionItemType, SystemModeType } from "constants/enum";
import { NormalDisplayItem } from ".";
import { getStatusColor, mapTaskIconStatus, onLabelClick } from "./utils";

export const taskLabel = (
  item: NormalDisplayItem,
  options?: { isDisableLabelClick?: boolean; isSelected?: boolean }
) => {
  const $label = $(`
        <label class="task-label label-item markup update ${
          item.status || InspectionItemType.Defect
        } ${options?.isDisableLabelClick ? "disable-click" : ""} ${
    options?.isSelected ? "selected-label" : ""
  }"  data-id="${item.id}">
    <div class="marker-pin" style="background-color: ${getStatusColor(
      item.status,
      SystemModeType.Task
    )}">
        <span class="marker-label" id="${
          item.status || InspectionItemType.Defect
        }"> ${mapTaskIconStatus(item.status)}</span>
    </div>
  </label>`);

  if (!options?.isDisableLabelClick) {
    $label?.on("click", () => onLabelClick(item, SystemModeType.Task));
  }

  return $label;
};
