import {
  Box,
  Flex,
  FormLabel,
  Menu,
  MenuButton,
  MenuList,
  MenuOptionGroup,
  Placement,
  Spinner,
  Stack,
  Text,
} from "@chakra-ui/react";
import { DEFAULT_DROP_BOX_HEIGHT } from "constants/dropdown";
import {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

export const MultiSelectBox = <T extends { toString: () => string }>({
  isFullWidth = false,
  label,
  inputItems,
  menuItems,
  value,
  reRenderDropBox,
  disabled = false,
  rowHeight = undefined,
  isLoading,
}: {
  isFullWidth?: boolean;
  label: string;
  value: T[];
  inputItems: ReactNode[];
  menuItems: ReactNode[];
  reRenderDropBox?: number | undefined; // when filter box scroll then trigger update
  disabled?: boolean;
  rowHeight?: number;
  isLoading?: boolean;
}) => {
  const dropdownRef = useRef<any>(null);
  const menuButtonRef = useRef<HTMLButtonElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [dropBoxHeight, setDropBoxHeight] = useState(0);
  const [dropBoxPlacement, setDropBoxPlacement] = useState<
    Placement | undefined
  >(undefined);
  const [dropBoxOffsetY, setDropBoxOffsetY] = useState<number>(0);
  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    setScrollTop(0);
  }, [menuItems.length, rowHeight]);

  const displayInfo = useMemo(() => {
    let visibleItems = menuItems;
    let startIndex = 0;
    if (rowHeight) {
      const menuHeight = Math.min(dropBoxHeight, menuItems.length * rowHeight);
      startIndex = Math.floor(scrollTop / rowHeight);
      const endIndex = Math.min(
        startIndex + Math.ceil(menuHeight / rowHeight),
        menuItems.length
      );
      visibleItems = menuItems.slice(startIndex, endIndex);
    }

    return { visibleItems, startIndex };
  }, [scrollTop, rowHeight, menuItems, dropBoxHeight]);

  const handleScroll = useCallback((event: any) => {
    setScrollTop(event.target.scrollTop);
  }, []);

  /**
   * Reset new height after dropdown box is created
   */
  const calculatePlacementAndHeightDropBox = useCallback(() => {
    if (isOpen && dropdownRef.current) {
      const windowHeight = window.innerHeight;
      const parentBounds =
        dropdownRef.current?.parentElement?.getBoundingClientRect();
      const boxBounds =
        dropdownRef.current?.childNodes[0]?.childNodes[1]?.getBoundingClientRect();
      const menuHeaderHeight = 65;
      const minHeightDropBox = 100;
      let maxHeightDropBox = 0;
      let placementValue = "bottom-start" as Placement;
      let offsetY = 0;

      // Check exists DOM
      if (boxBounds && parentBounds) {
        const marginTop = 5;
        const marginBottom = 15;
        const topHeight = Math.abs(
          parentBounds.top -
            (parentBounds.top > boxBounds.top
              ? parentBounds.top
              : boxBounds.top - marginTop)
        );
        const bottomHeight = Math.abs(
          windowHeight -
            (boxBounds.bottom > windowHeight
              ? windowHeight
              : boxBounds.bottom + marginBottom)
        );

        // Check suitable area for display
        if (topHeight > bottomHeight && topHeight >= minHeightDropBox) {
          maxHeightDropBox = topHeight;
          placementValue = "top-start";
        } else if (
          topHeight < bottomHeight &&
          bottomHeight >= minHeightDropBox
        ) {
          maxHeightDropBox = bottomHeight;
          placementValue = "bottom-start";
        } else {
          if (boxBounds.top < menuHeaderHeight) {
            offsetY = menuHeaderHeight;
          }
          maxHeightDropBox = DEFAULT_DROP_BOX_HEIGHT;
          placementValue = "right-start";
        }
      }
      setDropBoxOffsetY(offsetY);
      setDropBoxPlacement(placementValue);
      setDropBoxHeight(Math.min(maxHeightDropBox, DEFAULT_DROP_BOX_HEIGHT));
    }
  }, [isOpen]);

  const handleOnOpen = () => {
    setIsOpen(true);
    calculatePlacementAndHeightDropBox();
  };

  const handleOnClose = () => {
    setIsOpen(false);
    setDropBoxOffsetY(0);
    setDropBoxPlacement(undefined);
    setScrollTop(0);
  };

  useEffect(() => {
    calculatePlacementAndHeightDropBox();
  }, [reRenderDropBox, inputItems?.length, calculatePlacementAndHeightDropBox]);

  const displayValue = useMemo(() => {
    return value.map((e) => (e ? e.toString() : ""));
  }, [value]);

  return (
    <FormLabel
      as="legend"
      ref={dropdownRef}
      sx={{
        cursor: "pointer",
        ".chakra-menu__menu-button > span": {
          pointerEvents: "auto",
        },
        ".chakra-menu__menu-list": {
          position: "relative",
          top: `${dropBoxOffsetY}px`,
        },
      }}
    >
      <Stack>
        <Text>{label}</Text>
        <Menu
          closeOnSelect={false}
          onClose={handleOnClose}
          onOpen={handleOnOpen}
          placement={dropBoxPlacement}
          flip={false}
        >
          <MenuButton ref={menuButtonRef} disabled={disabled}>
            <Flex
              padding="1rem"
              borderRadius="0.375rem"
              flexWrap="wrap"
              gap="0.5rem"
              minH="4.4rem"
              border="1px solid #e2e2e3"
              overflow="inherit"
              bgColor={disabled ? "#FAFAFA" : "#FFFFFFF"}
            >
              {inputItems}
            </Flex>
          </MenuButton>
          {isOpen && (
            <MenuList
              maxHeight={`${dropBoxHeight}px`}
              overflowY="auto"
              overflowX="hidden"
              display={`${dropBoxPlacement ? "block" : "none"}`}
              onScroll={handleScroll}
              {...(isFullWidth
                ? {
                    minW: Number(dropdownRef.current?.clientWidth),
                    maxW: Number(dropdownRef.current?.clientWidth),
                  }
                : {})}
            >
              <Box
                height={
                  rowHeight ? `${rowHeight * menuItems.length}px` : undefined
                }
              >
                <Box
                  sx={
                    rowHeight
                      ? {
                          position: "relative",
                          height: `${
                            displayInfo.visibleItems.length * rowHeight
                          }px`,
                          top: `${displayInfo.startIndex * rowHeight}px`,
                        }
                      : undefined
                  }
                >
                  <MenuOptionGroup
                    value={displayValue}
                    title=""
                    type="checkbox"
                  >
                    {displayInfo.visibleItems}
                  </MenuOptionGroup>
                </Box>
              </Box>
            </MenuList>
          )}
        </Menu>
      </Stack>
      {isLoading && (
        <Box position={"absolute"} right={0} top={0} height={"100%"}>
          <Spinner />
        </Box>
      )}
    </FormLabel>
  );
};
