import {
  Box,
  Button,
  Divider,
  Flex,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  ModalProps,
  Text,
  UseModalProps,
} from "@chakra-ui/react";
import { IconBase } from "components/base";
import { memo } from "react";

interface Props extends UseModalProps {
  title: string | React.ReactNode;
  content: string | React.ReactNode;
  buttonDelete: string;
  buttonCancel?: string;
  isLoading?: boolean;
  size?: ModalProps["size"];
  onProcessing?: () => void | Promise<void>;
}

function ConfirmDeleteItemModal({
  title,
  content,
  isOpen,
  isLoading,
  buttonDelete,
  buttonCancel = "キャンセル",
  size = "xl",
  onClose,
  onProcessing,
  ...rest
}: Props) {
  return (
    <Modal
      size={size}
      isOpen={isOpen}
      onClose={onClose}
      isCentered={true}
      closeOnOverlayClick={!isLoading}
      {...rest}
    >
      <ModalOverlay />
      <ModalContent
        position="relative"
        borderRadius="8px"
        p="2rem 0rem"
        width="1200px"
      >
        <Flex
          p="0rem 3.2rem"
          alignItems="center"
          justifyContent="space-between"
        >
          <Text fontSize="1.8rem" color="#000000CC" fontWeight="bold">
            {title}
          </Text>

          <ModalCloseButton
            fontSize="12px"
            color="#737373"
            _focus={{
              border: "none",
            }}
          />
        </Flex>

        <Divider my="2rem" opacity={1} borderColor="#D4D4D4" />

        <Box p="0rem 3.2rem">{content}</Box>

        <Flex p="0rem 3.2rem" justifyContent="right" mt="2.2rem">
          <Button
            onClick={onClose}
            disabled={isLoading}
            border="1px solid #A3A3A3"
            borderRadius={6}
            bg="#FFF"
            p="1.2rem 1.6rem"
            height="4rem"
            color="#009BE0"
            fontSize="1.4rem"
            _hover={{}}
          >
            {buttonCancel}
          </Button>
          <Button
            onClick={onProcessing}
            ml="0.8rem"
            bg="#DC2626"
            p="1.2rem 1.6rem"
            height="4rem"
            color="#FFF"
            fontSize="1.4rem"
            borderRadius={6}
            isLoading={isLoading}
            fontWeight="bold"
            leftIcon={
              <IconBase
                icon="/img/icon-trash.svg"
                width="2.4rem"
                height="2.4rem"
                color="white"
              />
            }
            _hover={{}}
          >
            {buttonDelete}
          </Button>
        </Flex>
      </ModalContent>
    </Modal>
  );
}

export default memo(ConfirmDeleteItemModal);
