import {
  PaperDirectionType,
  PaperSize,
  TemplateComponentType,
} from "constants/enum";
import { PageFormat, TemplateComponent } from "interfaces/models/component";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setComponents,
  setComponentSelected,
  setCurrentTemplate,
  setIsEditText,
  setPageSeleted,
  setSelectedCell,
} from "redux/documentSlice";
import { RootState } from "redux/store";

export default function useComponent() {
  const dispatch = useDispatch();
  const { components, currentTemplate, pageSelected } = useSelector(
    (state: RootState) => state.document
  );

  const subcategories = useMemo(
    () =>
      components
        ?.filter((item) => item?.isLinkedHeader)
        ?.map((item) => ({
          name: String(item?.detail?.value),
          value: String(item?.componentId),
        })),
    [components]
  );

  const deleteComponent = (component: TemplateComponent) => {
    dispatch(setIsEditText(false));

    if (component?.type === TemplateComponentType.TableHeader) {
      const updatedComponents = components?.filter((item) => {
        if (item?.type === TemplateComponentType.TableHeader) {
          return false;
        }

        if (
          item?.type === TemplateComponentType.Table &&
          !!item?.linkedHeaderId
        ) {
          return false;
        }

        if (
          subcategories?.some(
            (subcategory) => subcategory?.value === item?.componentId
          )
        ) {
          return false;
        }

        return true;
      });

      dispatch(setComponents(updatedComponents as TemplateComponent[]));
      dispatch(
        setCurrentTemplate({
          ...currentTemplate,
          components: updatedComponents,
        })
      );

      return;
    }

    let pages = currentTemplate.pages;
    if (
      component.type === TemplateComponentType.Keyplan &&
      pageSelected.isFullSize
    ) {
      let updatedPage: PageFormat = {} as PageFormat;

      pages = pages.map((p) => {
        if (p.pageId === pageSelected.pageId) {
          let numOfRows = PaperSize[p?.pageSize]?.numOfRows;
          let numOfColumns = PaperSize[p?.pageSize]?.numOfColumns;

          const pageDirectionRatio =
            p.pageDirection === PaperDirectionType.VERTICAL
              ? 1
              : numOfRows / numOfColumns;

          [numOfRows, numOfColumns] =
            p.pageDirection === PaperDirectionType.VERTICAL
              ? [numOfRows, numOfColumns]
              : [numOfColumns, numOfRows];

          updatedPage = {
            ...p,
            isFullSize: false,
            numOfRows,
            numOfColumns,
            pageDirectionRatio,
          };

          return updatedPage;
        }

        return p;
      });

      if (updatedPage.pageId) {
        dispatch(
          setPageSeleted({
            ...updatedPage,
            pageIndex: pageSelected.pageIndex,
          })
        );
      }
    }

    const newComponents = components
      .filter((item) => item.componentId !== component.componentId)
      ?.map((item) => {
        const maxDisplayOrderOfSubcategory = components.filter(
          (item) => item.isLinkedHeader
        )?.length;

        const displayOrder =
          Number(item.detail?.displayOrder) >= maxDisplayOrderOfSubcategory - 1
            ? maxDisplayOrderOfSubcategory - 1
            : item.detail?.displayOrder || 1;

        if (item.isLinkedHeader && component.isLinkedHeader) {
          return {
            ...item,
            detail: {
              ...item.detail,
              displayOrder,
            },
          };
        }

        return item;
      });

    component && dispatch(setComponents(newComponents));
    dispatch(setComponentSelected({} as TemplateComponent));
    dispatch(setSelectedCell([]));
    dispatch(
      setCurrentTemplate({
        ...currentTemplate,
        pages,
        components: newComponents,
      })
    );
  };

  return {
    deleteComponent,
  };
}
