import { MapInspectionItemColor, MapInspectionItemType } from "constants/enum";

const makeChangeStatus = (status: string) =>
  status in MapInspectionItemType
    ? `がステータスを
    <mark
      style=
        "background: none;
        color: ${
          MapInspectionItemColor[status as keyof typeof MapInspectionItemColor]
        };"
    >
      ${MapInspectionItemType[status as keyof typeof MapInspectionItemType]}
    </mark>
    に変更`
    : "";

const makeText = (category: string) => (value: string) => {
  if (!value) {
    return `が${category}を削除`;
  }

  return `が${category}を入力しました。`;
};

const getText =
  (name: string, sufix = "更新") =>
  (value: any) =>
    value ? `が${name}を${sufix}` : `が${name}を削除`;
const getDelText = (name: string) => `が${name}を削除`;

export const COMMENT_TYPE = {
  CREATE_TASK: { typeKey: 0, text: "が指摘を作成" }, // ok
  ADD_COMMENT: { typeKey: 1, text: "がコメントした" }, // ok
  ADD_COMMENT_IMAGE: { typeKey: 11, text: "が写真を登録" }, // ok
  ADD_COMMENT_AUDIO: { typeKey: 13, text: "が録音を登録" }, // ok
  ADD_COMMENT_FILE: { typeKey: 14, text: "が資料を登録" }, // ok
  CHANGE_STATUS: { typeKey: 2, text: makeChangeStatus },
  CHANGE_ASSIGN: { typeKey: 3, text: getText("高砂担当者", "変更") }, // ok
  CHANGE_USER_CREATED: { typeKey: 12, text: getText("高砂確認者", "変更") }, // ok
  CHANGE_TAGS: { typeKey: 4, text: getText("タグ", "変更") }, // ok
  CHANGE_TARGET_DATE: { typeKey: 5, text: getText("完了期限", "変更") }, // ok
  CHANGE_COMPLETE_DATE: { typeKey: 30, text: getText("是正完了日", "変更") }, // ok
  CHANGE_CONFIRM_DATE: { typeKey: 22, text: getText("高砂確認日", "変更") }, // ok
  CHANGE_DESCRIPTION: { typeKey: 6, text: getText("是正内容", "入力") }, // ok
  CHANGE_DESCRIPTION_CRITICISM: {
    typeKey: 23,
    text: getText("指摘内容", "入力"),
  }, // ok
  CHANGE_IMAGE: { typeKey: 7, text: "が是正写真を登録" }, // ok
  CHANGE_PRIORITY: { typeKey: 8, text: getText("緊急度を") },
  CHANGE_CONTENT_TYPE: { typeKey: 9, text: getText("タイトル", "変更") }, // ok
  CHANGE_ATTACH: { typeKey: 10, text: "が添付資料を登録" }, // ok
  DELETE_IMAGE: { typeKey: 20, text: "が是正写真を削除" }, // ok
  DELETE_ATTACH: { typeKey: 21, text: "が添付資料を削除" }, // ok
  PRINT_TARGET: { typeKey: 25, text: "が印刷対象を変更" }, // ok
  UNPRINT_TARGET: { typeKey: 26, text: "が印刷対象外を変更" }, // ok
  CHANGE_POINTED_OUT_PHOTO: { typeKey: 27, text: "指摘写真を登録" }, // ok
  DELETE_POINTED_OUT_PHOTO: { typeKey: 28, text: getDelText("指摘写真") }, // ok
  CHANGE_PARTNERS: { typeKey: 29, text: getText("協力会社", "変更") }, // ok
} as const;
