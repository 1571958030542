import { useEffect, useMemo, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import type { RootState } from "redux/store";
import { toggleIsEditingFamily } from "redux/documentSlice";
import { ModalType, SystemModeType } from "constants/enum";

const useHandleEditFamily = () => {
  const dispatch = useDispatch();

  const { systemMode, modalType, isCreateDocumentItem } = useSelector(
    (state: RootState) => state.forgeViewer
  );

  const { documentTemplateSelected, documentTemplates, isEditingFamily } =
    useSelector((state: RootState) => state.document);

  const documentIdRef = useRef<string | undefined>(undefined);

  const currentTemplate = useMemo(() => {
    const templateId = documentTemplateSelected?.id;

    return templateId ? documentTemplates[templateId] : null;
  }, [documentTemplateSelected?.id, documentTemplates]);

  const isOpen =
    systemMode === SystemModeType.Document &&
    !!documentTemplateSelected &&
    !!currentTemplate &&
    isEditingFamily;

  const toggle = useCallback<(isOpen?: unknown) => void>(
    (isOpen) => {
      const value = typeof isOpen === "boolean" ? isOpen : !isEditingFamily;
      if (isEditingFamily === value) {
        return;
      }

      dispatch(toggleIsEditingFamily(value));
    },
    [dispatch, isEditingFamily]
  );

  useEffect(() => {
    if (!documentTemplateSelected?.id) {
      toggle(false);
    } else {
      if (
        documentIdRef.current &&
        documentIdRef.current !== documentTemplateSelected.id
      ) {
        toggle(false);
      }
      documentIdRef.current = documentTemplateSelected?.id;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentTemplateSelected?.id]);

  useEffect(() => {
    if (
      systemMode !== SystemModeType.Document ||
      (modalType !== ModalType.NONE && modalType !== ModalType.DOC_TEMPLATE) ||
      isCreateDocumentItem
    ) {
      toggle(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreateDocumentItem, modalType, systemMode]);

  return [
    {
      isOpen,
      documentCategory: documentTemplateSelected?.documentCategories?.[0],
      currentTemplate,
    },
    {
      toggle,
    },
  ] as const;
};

export default useHandleEditFamily;
