import { Box, Flex, Text } from "@chakra-ui/react";
import { documentItemApi, documentItemLogApi } from "apiClient/v2";
import { TypeFieldDynamic } from "constants/enum";
import useUserOfProject from "hooks/useUserOfProject";
import { DocumentCategoryDTO } from "interfaces/dtos/documentCategoryDTO";
import { DocumentItemDTO } from "interfaces/dtos/documentItemDTO";
import { DocumentItemLog } from "interfaces/models/documentItem";
import { User } from "interfaces/models/user";
import { getColorTextByStatus } from "models/document";
import {
  GetContentItemLog,
  getDocumentContentLog,
} from "models/documentItemLog";
import { TypeHandleInitData } from "pages/forge-viewer/hooks/useSupportSyncDataOffline";
import { memo, useCallback } from "react";
import { useDispatch } from "react-redux";
import { updateDocumentItem } from "redux/documentSlice";
import { transformBodyForCombineData } from "utils/offline";
import { DocumentTemplate } from "../../../interfaces/models/documentTemplate";
import DynamicFields from "../DocumentCategoryModal/DynamicFields";
import FieldNoComponent from "./FieldNoComponent";
import { useItemLogs } from "./hooks";
import ItemLog from "./ItemLog";
import ItemStatusMenu from "./ItemStatusMenu";
import ItemTasks from "./ItemTasks";
import ItemTitle, { ItemTitleType } from "./ItemTitle";

type Props = {
  documentItemSelected?: DocumentItemDTO;
  documentCategorySelected?: DocumentCategoryDTO;
  documentTemplate: DocumentTemplate;
  currentUser?: User | null;
  isLoadedViewer: boolean;
  itemStatusColor: string;
  isOnline: boolean;
  itemDisplayOrder: number | string;
};

const FormItemDataSheet = ({
  documentItemSelected,
  documentTemplate,
  isLoadedViewer,
  documentCategorySelected,
  currentUser,
  itemStatusColor,
  isOnline,
  itemDisplayOrder,
}: Props) => {
  const dispatch = useDispatch();

  const { listUserById } = useUserOfProject();
  const { itemLogs, setItemLogs } = useItemLogs({
    itemId: documentItemSelected?.id || "",
  });

  const insertDocumentItemLog = useCallback(
    async ({
      field,
      value,
      operation,
      nameDynamicField,
      prefixFieldName,
      dynamicFieldKeysUpdatedAt,
    }: GetContentItemLog) => {
      if (!currentUser?.id || !documentItemSelected?.id) {
        return;
      }

      const content = getDocumentContentLog({
        field,
        value,
        operation,
        nameDynamicField,
        prefixFieldName,
      }) as any;

      const now = new Date();
      const res = await documentItemLogApi.createItemLog({
        content,
        createdBy: currentUser.id,
        title: content?.title,
        field,
        value,
        itemId: documentItemSelected?.id,
        dynamicFieldKeysUpdatedAt,
        createdAt: now,
        updatedAt: now,
      } as DocumentItemLog);

      const log = res?.data;
      if (log?.id) {
        setItemLogs((prev) => [...prev, log]);
      }
    },
    [currentUser?.id, documentItemSelected?.id, setItemLogs]
  );

  const onHandleSaveDynamicField = useCallback(
    async (item: DocumentItemDTO) => {
      if (!documentItemSelected?.id) {
        return;
      }

      const now = new Date();
      documentItemApi.updateItem(
        transformBodyForCombineData<DocumentItemDTO>({
          body: {
            id: documentItemSelected?.id,
            data: item.data,
            dynamicFieldKeysUpdatedAt: item.dynamicFieldKeysUpdatedAt,
            updatedAt: now,
            isIgnoreInsertLog: true,
          } as DocumentItemDTO,
          bodyBefore: documentItemSelected,
          typeInitData: TypeHandleInitData.DOCUMENT_ITEM,
        })
      );

      dispatch(
        updateDocumentItem({
          ...documentItemSelected,
          data: item.data,
          dynamicFieldKeysUpdatedAt: item.dynamicFieldKeysUpdatedAt,
          updatedAt: now,
        })
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [documentItemSelected, dispatch]
  );

  return (
    <Flex
      flexDirection="column"
      gap="3.2rem"
      padding="0px 1.6rem"
      minHeight="100%"
      paddingBottom="5.8rem"
    >
      {/* title */}
      <Flex gap="1.2rem" alignItems="center">
        <FieldNoComponent
          color={itemStatusColor}
          no={itemDisplayOrder}
          colorText={getColorTextByStatus(documentItemSelected?.status)}
        />

        <Flex flexDirection="column" flex={1}>
          <Text lineHeight="2.2rem" color="font.default" fontSize="1.4rem">
            機器データシート
          </Text>
          <ItemTitle
            type={ItemTitleType.ITEM}
            item={documentItemSelected as DocumentItemDTO}
            insertItemLog={insertDocumentItemLog}
          />
        </Flex>
      </Flex>

      {/* dynamic field*/}
      <DynamicFields
        type={TypeFieldDynamic.TYPE_ITEM}
        template={documentTemplate}
        documentItemSelected={documentItemSelected}
        listUserById={listUserById}
        insertDocumentItemLog={insertDocumentItemLog}
        onSaveDataForDocumentItem={onHandleSaveDynamicField}
      />

      {/* insert task */}
      <Box borderTop="1px solid #A3A3A3" pt="2rem">
        <ItemTasks
          isOnline={isOnline}
          documentItem={documentItemSelected as DocumentItemDTO}
        />
      </Box>

      {/* item logs */}
      <Box
        backgroundColor="#E8E8E8"
        ml="-1.6rem"
        mt="auto"
        width="calc(100% + 3.2rem)"
        padding="2rem 1.6rem"
      >
        <Text
          display="block"
          fontSize="1.6rem"
          color="var(--primary-text-color)"
          fontWeight="bold"
          mb="1.2rem"
        >
          変更履歴
        </Text>
        {itemLogs?.map((documentItemLog) => (
          <ItemLog
            key={documentItemLog.id}
            log={documentItemLog}
            user={listUserById?.[documentItemLog?.createdBy || ""]}
          />
        ))}
      </Box>

      {/* status */}
      <Flex
        position="absolute"
        bottom="0"
        left="0"
        width="100%"
        height="5.8rem"
        zIndex="12"
        backgroundColor="#E8E8E8"
        borderTop="1px solid rgba(0,0,0,0.15)"
        justifyContent="space-between"
        padding="0px 1.6rem"
        alignItems="center"
        gap="0.8rem"
      >
        <Text
          flexShrink={0}
          color="var(--primary-text-color)"
          fontSize="1.4rem"
        >
          ステータス
        </Text>
        <Box flex={1} maxW="35rem">
          <ItemStatusMenu
            isLoadedViewer={isLoadedViewer}
            currentUser={currentUser}
            documentItemSelected={documentItemSelected}
            documentCategorySelected={documentCategorySelected}
            insertDocumentItemLog={insertDocumentItemLog}
          />
        </Box>
      </Flex>
    </Flex>
  );
};

export default memo(FormItemDataSheet);
