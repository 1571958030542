import {
  DocumentKeyNote,
  KeynoteImageData,
} from "interfaces/models/documentKeynote";
import React from "react";

const PreviewDocumentCategoryContext = React.createContext<{
  documentKeynoteData: DocumentKeyNote;
  keynoteImageData: KeynoteImageData;
  isChangeSheet: boolean;
  setDocumentKeynoteData: (
    value: React.SetStateAction<DocumentKeyNote>
  ) => void;
  setKeynoteImageData: (value: React.SetStateAction<KeynoteImageData>) => void;
}>({} as any);

export default PreviewDocumentCategoryContext;
