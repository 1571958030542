export const checkIsImageExists = async (imgUrl: string): Promise<boolean> => {
  if (!imgUrl) {
    return false;
  }

  return new Promise((resolve) => {
    const image = new Image();
    image.crossOrigin = "anonymous";
    image.onload = () => resolve(true);
    image.onerror = () => resolve(false);
    image.src = imgUrl;
  });
};
