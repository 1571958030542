import { AxiosResponse } from "axios";
import cryptoRandomString from "crypto-random-string";
import { validate } from "uuid";

export const STRING_BASE_LENGTH = 36;
export const STRING_GENERATE_LENGTH = 5;

export function generateRandomString(
  _base = STRING_BASE_LENGTH,
  length = STRING_GENERATE_LENGTH
) {
  return cryptoRandomString({ length, type: "alphanumeric" });
}

export const capitalize = (text: string) =>
  text[0].toUpperCase() + text.substr(1, text.length);
export const weightDescription = (weight: number) =>
  weight === 400 ? "Regular" : weight === 500 ? "Medium" : "Bold";

export const getApiName = (response: AxiosResponse<any, any>) => {
  const query = (JSON.parse(response.config.data || "")?.query || "") as string;

  const element = query.split(/[({]/)[0].trim();
  const start = element.lastIndexOf(" ") + 1;

  return element.slice(start);
};

export const validateUUID = (uuid?: string) => {
  if (!uuid) return false;
  const regexUuidV4 =
    /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/;

  return validate(uuid) || regexUuidV4.test(uuid);
};
