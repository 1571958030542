import { fabric } from "fabric";

interface CanvasData {
  width: number;
  height: number;
}

type StateHistory = [string, CanvasData];

Object.assign(fabric.Canvas.prototype, {
  historyInit() {
    this.stateManager = new StateManager(this);

    this.on("object:added", (options: any) => {
      if (options.target.data?.noSaveState === true) {
        return;
      }
      this.saveState();
    });

    ["object:removed", "object:modified", "object:skewing"].forEach((event) => {
      this.on(event, (options: any) => {
        this.saveState();
      });
    });
  },
  lockHistory(locked: boolean) {
    this.stateManager.locked = locked;
  },
  saveState(canvasData?: CanvasData) {
    this.stateManager.saveState(canvasData);
    this.renderAll();
  },
  undo(callback?: (canvas: fabric.Canvas) => void) {
    this.stateManager.undo(callback);
  },
  redo(callback?: (canvas: fabric.Canvas) => void) {
    this.stateManager.redo(callback);
  },
});

class StateManager {
  private currentState: StateHistory;
  private stateStack: StateHistory[]; //Undo stack
  private redoStack: StateHistory[]; //Redo stack
  private locked: boolean; //Determines if the state can currently be saved.
  private maxCount: number = 100; //We keep 100 items in the stacks at any time.

  constructor(readonly canvas: fabric.Canvas) {
    this.currentState = [
      JSON.stringify(canvas.toDatalessJSON()),
      {
        width: canvas.getWidth(),
        height: canvas.getHeight(),
      },
    ];
    this.locked = false;
    this.redoStack = [];
    this.stateStack = [];
  }

  saveState(_canvasData?: CanvasData) {
    if (!this.locked) {
      if (this.stateStack.length === this.maxCount) {
        //Drop the oldest element
        this.stateStack.shift();
      }

      const canvasData: CanvasData = {
        width: _canvasData?.width || this.canvas.getWidth(),
        height: _canvasData?.height || this.canvas.getHeight(),
      };

      //Add the current state
      this.stateStack.push([this.currentState[0], canvasData]);

      //Make the state of the canvas the current state
      this.currentState = [
        JSON.stringify(this.canvas.toJSON()),
        {
          width: this.canvas.getWidth(),
          height: this.canvas.getHeight(),
        },
      ];

      //Reset the redo stack.
      //We can only redo things that were just undone.
      this.redoStack.length = 0;
    }
  }

  //Pop the most recent state. Use the specified callback method.
  undo(callback?: Function) {
    if (this.stateStack.length > 0)
      this.applyState(this.redoStack, this.stateStack.pop()!, callback);
  }

  //Pop the most recent redo state. Use the specified callback method.
  redo(callback?: Function) {
    if (this.redoStack.length > 0)
      this.applyState(this.stateStack, this.redoStack.pop()!, callback);
  }

  //Root function for both undo and redo; operates on the passed-in stack
  private applyState(
    stack: StateHistory[],
    newState: StateHistory,
    callBack?: Function
  ) {
    //Push the current state
    stack.push(this.currentState);

    //Make the new state the current state
    this.currentState = newState;

    //Lock the stacks for the incoming change
    const thisStateManager = this;
    this.locked = true;

    //Update canvas with the new current state
    const [objectData, canvasData] = this.currentState;

    this.canvas
      .setDimensions({
        width: canvasData.width,
        height: canvasData.height,
      })
      .loadFromJSON(objectData, () => {
        if (callBack !== undefined) callBack(this.canvas);

        //Unlock the stacks
        thisStateManager.locked = false;
      })
      .renderAll();
  }
}
