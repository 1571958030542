import { PartnerCompany } from "interfaces/models/partnerCompany";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchPartnerCompanies as _fetchPartnerCompanies,
  resetFetchedPartnerCompanies,
} from "redux/projectSlice";
import { RootState } from "redux/store";

interface iProps {
  projectBimFileId?: string;
}

const useGetPartnerCompanies = ({ projectBimFileId }: iProps) => {
  const {
    partnerCompanies,
    isFetchingPartnerCompanies,
    isFetchedPartnerCompanies,
  } = useSelector((state: RootState) => state.project);
  const dispatch = useDispatch();

  const mapPartnerCompanyById: { [key: string]: PartnerCompany } =
    useMemo(() => {
      const map = partnerCompanies?.map((p) => ({ [p.id]: p }));
      if (!map) {
        return {};
      }

      return Object.assign({}, ...map);
    }, [partnerCompanies]);

  const fetchPartnerCompanies = useCallback(() => {
    if (!projectBimFileId) {
      return;
    }

    dispatch(_fetchPartnerCompanies(projectBimFileId));
  }, [dispatch, projectBimFileId]);

  useEffect(() => {
    dispatch(resetFetchedPartnerCompanies());
  }, [projectBimFileId]);

  return {
    partnerCompanies,
    mapPartnerCompanyById,
    fetchPartnerCompanies,
    isFetchingPartnerCompanies,
    isFetchedPartnerCompanies,
  };
};

export default useGetPartnerCompanies;
