import { useDisclosure } from "@chakra-ui/react";
import { projectBimFileApi, userSettingApi } from "apiClient/v2";
import { message } from "components/base";
import { CURRENT_SYSTEM_MODE_KEY } from "constants/app";
import {
  DocumentCategoryStatusType,
  DocumentTemplateType,
  InspectionItemType,
  MapDocumentCategoryStatusTypeColor,
  MapInspectionItemColor,
  ModalType,
  STATUS_UPDATE_FORGE_VERSION,
  SystemModeType,
  WidgetsMode,
} from "constants/enum";
import {
  ALL_LEVEL_LABEL,
  CURRENT_LEVEL_KEY,
  DISPLAY_MODE,
  LEVEL_ALL,
  LEVEL_OTHER_ID,
} from "constants/forge";
import { OPERATION } from "constants/task";
import { MessageType } from "constants/websocket";
import { DocumentCategoryDTO } from "interfaces/dtos/documentCategoryDTO";
import { TaskDTO } from "interfaces/dtos/taskDTO";
import {
  FilterDataType,
  Level,
  OperationCaptureKeyplanData,
  Vector3,
} from "interfaces/models";
import { WSMessage } from "interfaces/models/websocket";
import isEmpty from "lodash/isEmpty";
import { FilterDocumentCategoryByUserSettingProps } from "models/document";
import {
  isBlockDocumentCategory,
  isSelfInspectionTemplate,
} from "models/documentCategory";
import { handleSelectTask } from "models/task";
import useHandleEditFamily from "pages/forge-viewer/hooks/useHandleEditFamily";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import useStateRef from "react-usestateref";
import {
  fetchBlackBoardByCategoryId,
  setDocumentCategorySelected,
  setDocumentGroupSelected,
  setDocumentItemSelected,
  setSelectedItemIdsWhenMovingDoc,
  toggleIsCaptureKeynoteByOperation,
} from "redux/documentSlice";
import {
  resetState,
  resetToInitState,
  setCreateSelfInspectionTask,
  setCreateTask,
  setDisplayMode,
  setLevelSelected,
  setModalType,
  setSystemMode,
  setWidgetsMode,
} from "redux/forgeViewerSlice";
import {
  fetchPartnerCompanies,
  setDataProjectDetail,
  setDataProjects,
} from "redux/projectSlice";
import store, { RootState, useAppDispatch } from "redux/store";
import {
  fetchTaskByBimFile,
  fetchTaskTypes,
  setIsLoadedDocumentTasks,
  setTaskSelected,
  transformDbIdForTasks,
} from "redux/taskSlice";
import {
  fetchListUser,
  fetchUserSetting,
  setIsFetchedUsers,
  setUserSetting,
} from "redux/userSlice";
import { routePath } from "routes/path";
import {
  checkIsDiffDataProjectVersion,
  clearAllData,
  fitToViewByPositions,
  getDbIdByExternalId,
  getExternalId,
  getExternalIdFromDbId,
  getLevelOfPosition,
  selectDbIds,
  setMapDbId,
  setMapExternalId,
} from "utils/forge";
import { ClickInfo } from "utils/forge/extensions/click-extension";
import { DisplayItem, selectLabel } from "utils/forge/extensions/custom-label";
import { getLocalStorage, setLocalStorage } from "utils/storage";
import { DataFromURL, FilterDataFromURL } from "..";
import useCheckUpdateForgeVersion from "./useCheckUpdateForgeVersion";
import useGetVersionsOfForge from "./useGetVersionsOfByForge";
import useGetPartnerCompanies from "hooks/useGetPartnerCompanies";

export const DATE_TIME_VERSION_FORMAT = "YYYY-MM-DD HH:mm:ss";
export const DATE_TIME_VERSION_FORMAT_SELECT = "YYYYMMDDHHmmss";

interface Props {
  webSocketMessage: WSMessage | null;
  sendWebSocketMessage: (message: WSMessage) => void;
}

export default function useForgePage(props: Props) {
  const { webSocketMessage, sendWebSocketMessage } = props;

  const { bimFileId, version, projectBimFileId } = useParams();
  const { isOnline } = useSelector((state: RootState) => state.app);
  const {
    levelSelected,
    modalType,
    neptuneAreas: allArea,
    isCreateTask,
    isCreateSelfInspectionTask,
    levels,
    isLoadedSpaces,
    isLoadedLevels,
    isLoadedFamilyInstances,
    displayMode,
    isGeneratingFamilyInstances,
    systemMode,
    isWaittingCaptureKeynote,
    statusUpdateForgeVersion,
    isLoadedViewer,
    isLoadedViewerModelData,
    isDownloadPdfOnMobile,
  } = useSelector((state: RootState) => state.forgeViewer);
  const {
    tasks,
    taskSelected,
    listenProcessCreateTask,
    isLoadedDocumentTasks,
    taskTypes,
  } = useSelector((state: RootState) => state.task);
  const {
    documentCategories,
    documentItems,
    isLoadingDocument,
    documentItemSelected,
    documentGroupSelected,
    documentCategorySelected,
    documentTemplateSelected,
    documentTemplates,
    isMovingDoc,
    selectedItemIdsWhenMovingDoc,
    isCaptureKeynoteByOperation,
  } = useSelector((state: RootState) => state.document);
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();
  const { dataProjectDetail, dataProjects } = useSelector(
    (state: RootState) => state.project
  );
  const { settings } = useSelector((state: RootState) => state.user);

  const navigate = useNavigate();
  const timeoutFocusLabelRef = useRef<any>();
  const [{ isOpen: isShowEditingFamily }] = useHandleEditFamily();

  const [headerHeight, setHeaderHeight] = useState(0);
  const [isSettingFloor, setIsSettingFloor] = useState<boolean>(true);
  const [searchTaskValue, setSearchTaskValue] = useState<string>("");
  const [searchDocumentValue, setSearchDocumentValue] = useState<string>("");

  const { isOpen: isOpenVersion, onClose: onCloseVersion } = useDisclosure();
  const [hasUpdate, setUpdate] = useState<boolean>(false);
  const [isLoadedExternalId, setIsLoadedExternalId] = useState<boolean>(false);
  const [clickInfo, setClickInfo] = useState<ClickInfo>();
  const [clickedLabelInfo, setClickedLabelInfo, clickedLabelInfoRef] =
    useStateRef<{
      item: DisplayItem;
      systemMode: SystemModeType;
      shouldFitBound?: boolean;
    }>();

  const [operation, setOperation] = useState(
    new URLSearchParams(window.location.search).get("operation")
  );

  const modalTypeRef = useRef<ModalType>(ModalType.NONE);
  const [documentCategoryIdFromUrl, setDocumentCategoryIdFromUrl] =
    useState("");
  const [taskIdFromUrl, setTaskIdFromUrl] = useState("");
  const levelFromUrl = useRef<string | null>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const levelSelectedRef = useRef(levelSelected);
  const taskSelectedRef = useRef(taskSelected);
  const systemModeRef = useRef(systemMode);
  const imageURL = useRef<string>();
  const contentHeight = `calc(var(--app-height) - ${headerHeight}px)`;
  const { users, isFetchedUsers } = useSelector(
    (state: RootState) => state.user
  );
  const { isSyncOfflineData } = useSelector((state: RootState) => state.app);

  const location = useLocation();
  const { projectId } = dataProjectDetail;

  const { versions } = useGetVersionsOfForge({ bimFileId, projectId });
  useCheckUpdateForgeVersion({
    versions,
    bimFileId,
    projectBimFileId: dataProjectDetail.id,
    dataProjectDetail,
    isLoadedViewer,
    isLoadedLevels,
    isLoadedExternalId,
    isGeneratingFamilyInstances,
    levels,
  });

  const areas = useMemo(() => {
    return allArea.filter((area) => area.level === levelSelected.label);
  }, [allArea, levelSelected.label]);

  const mapDocumentItemBlockIdsByTemplateId = useMemo(() => {
    const result: { [templateId: string]: Set<string> } = {};

    documentCategories.forEach((category) => {
      if (isBlockDocumentCategory(category.blockedBy)) {
        (category?.documentItems || [])?.forEach((item) => {
          result[category.templateId] = (
            result[category.templateId] || new Set()
          ).add(item.id);
        });
      }
    });

    return result;
  }, [documentCategories]);

  useEffect(() => {
    modalTypeRef.current = modalType as ModalType;
    levelSelectedRef.current = levelSelected;
    taskSelectedRef.current = taskSelected;
    systemModeRef.current = systemMode;
  }, [modalType, levelSelected, taskSelected, systemMode]);

  const filterDocumentCategoryOptions: FilterDocumentCategoryByUserSettingProps =
    useMemo(() => {
      return {
        settings,
        areas,
        searchDocumentValue,
        documentTemplates,
        documentCategories,
      };
    }, [
      settings,
      areas,
      searchDocumentValue,
      documentTemplates,
      documentCategories,
    ]);

  useEffect(() => {
    // process open document category modal when open forge viewer from other page
    if (
      documentCategoryIdFromUrl &&
      !isLoadingDocument &&
      documentCategories?.length &&
      isLoadedFamilyInstances
    ) {
      const documentCategorySelected = documentCategories.find(
        (item) => item.id === documentCategoryIdFromUrl
      );
      if (documentCategorySelected) {
        dispatch(setModalType(ModalType.DOC_CATEGORY));
        dispatch(setDocumentItemSelected());
        dispatch(setDocumentCategorySelected(documentCategorySelected));
        fitToViewDocumentCategory(documentCategorySelected);
      }
      // only display once time
      setDocumentCategoryIdFromUrl("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isLoadingDocument,
    isLoadedFamilyInstances,
    documentCategories,
    documentCategoryIdFromUrl,
  ]);

  useEffect(() => {
    if (taskIdFromUrl && tasks?.length) {
      const taskSelected = tasks.find((item) => item.id === taskIdFromUrl);

      if (taskSelected) {
        handleSelectTask(taskSelected, dispatch);
      }

      // only display once time
      setTaskIdFromUrl("");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tasks, taskIdFromUrl]);

  useEffect(() => {
    // TODO: userId
    return function cleanUp() {
      clearAllData();
      dispatch(resetState());
    };
  }, [dispatch]);

  const { isFetchedPartnerCompanies, fetchPartnerCompanies } =
    useGetPartnerCompanies({
      projectBimFileId,
    });

  useEffect(() => {
    if (!(documentItemSelected || documentCategorySelected || taskSelected)) {
      return;
    }

    if (!isFetchedPartnerCompanies) {
      fetchPartnerCompanies();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isFetchedPartnerCompanies,
    documentItemSelected,
    documentCategorySelected,
    taskSelected,
  ]);

  const isExistDocumentCategoryOffline = useMemo(() => {
    return documentCategories.some((category) => !!category.blockedBy);
  }, [documentCategories]);

  useEffect(() => {
    if (isFetchedUsers) return;

    if (
      !(
        documentItemSelected ||
        documentCategorySelected ||
        taskSelected ||
        isExistDocumentCategoryOffline
      )
    ) {
      return;
    }

    dispatch(fetchListUser());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isFetchedUsers,
    documentItemSelected,
    documentCategorySelected,
    taskSelected,
    isExistDocumentCategoryOffline,
  ]);

  useEffect(() => {
    dispatch(setIsFetchedUsers(false));
  }, [projectBimFileId]);

  useEffect(() => {
    (async () => {
      setIsLoadedExternalId(false);
      const mapExternalId =
        (await getExternalId(decodeURIComponent(bimFileId!), version || "")) ||
        {};
      setMapExternalId(mapExternalId);
      const mapDbId: any = {};
      for (const key in mapExternalId) {
        mapDbId[mapExternalId[key]] = key;
      }

      setMapDbId(mapDbId);
      dispatch(transformDbIdForTasks());
      setIsLoadedExternalId(true);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bimFileId, projectBimFileId, version]);

  useEffect(() => {
    if (operation === OPERATION.ExportTask) {
      return;
    }

    dispatch(fetchUserSetting());
  }, [dispatch, operation]);

  useEffect(() => {
    if (!versions) {
      return;
    }

    if (versions.length >= 2) {
      // has more version
      const currentVersionNumber = bimFileId
        ?.split("?")[1]
        ?.replace("version=", "");

      const latestVersionNumber = versions[versions.length - 1].versionNumber;
      if (Number(currentVersionNumber) < Number(latestVersionNumber)) {
        const resultNoticeLatestVersionBimFile = getLocalStorage(
          "resultNoticeLatestVersionBimFile"
        );
        const bimFileIdParam = bimFileId?.split("?")[0];
        const realBimId =
          bimFileIdParam?.replace("fs.file:vf.", "dm.lineage:") || "";
        const lastLatestVersion = resultNoticeLatestVersionBimFile[realBimId];
        if (
          !lastLatestVersion ||
          Number(lastLatestVersion) < Number(latestVersionNumber)
        ) {
          setUpdate(true);
          resultNoticeLatestVersionBimFile[realBimId] =
            versions[versions.length - 1].versionNumber;
          setLocalStorage(
            "resultNoticeLatestVersionBimFile",
            resultNoticeLatestVersionBimFile
          );
        }
      }
    }
  }, [versions, bimFileId]);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const operation = urlParams.get("operation");

    setOperation(operation);
  }, [location]);

  const operationCreateTask = useCallback(
    (data: string) => {
      const dataFromURL: DataFromURL = JSON.parse(data || "{}");
      imageURL.current = dataFromURL.image;
      const position = dataFromURL.position;
      if (!levelFromUrl.current) {
        levelFromUrl.current =
          dataFromURL.view_name || getLevelOfPosition(position) || "";

        return;
      }
      setLocalStorage(CURRENT_SYSTEM_MODE_KEY, {
        value: SystemModeType.Task,
      });
      dispatch(setSystemMode(SystemModeType.Task));
      dispatch(setCreateTask(true));
      setClickInfo({
        forgeData: {
          dbId: dataFromURL.dbId,
          externalId: getExternalIdFromDbId(dataFromURL.dbId) || "",
          position: position,
          rotation: dataFromURL.rotation,
          fov: dataFromURL.fov || "",
          direction: dataFromURL.direction || "",
          level: levelFromUrl.current || "",
          uranus_url: dataFromURL.uranus_url,
          uranusStepId: dataFromURL.uranusStepId,
        },
      });
    },
    [dispatch]
  );

  const mapTaskType = useMemo(() => {
    return taskTypes.reduce((prev, item) => {
      return Object.assign(prev, {
        [item.id]: item.title,
      });
    }, {}) as { [key: string]: string };
  }, [taskTypes]);

  const fitToViewDocumentCategory = (documentCategory: DocumentCategoryDTO) => {
    if (documentCategory.documentItems) {
      selectLabel(documentCategory.documentItems.map((item) => item.id));
      const dbIds = documentCategory.documentItems
        .map((item) => item.externalId || "")
        .filter((externalId) => !!externalId);
      selectDbIds(dbIds, {
        color:
          MapDocumentCategoryStatusTypeColor[
            (documentCategory.status ||
              DocumentCategoryStatusType.NotStarted) as DocumentCategoryStatusType
          ],
      });
      fitToViewByPositions(
        (documentCategory.documentItems
          ?.map((item) => item?.position)
          .filter((item) => !!item) || []) as Vector3[],
        false
      );
    }
  };

  const operationExportTask = (data: string) => {
    const dataFromURL = JSON.parse(decodeURIComponent(data) || "{}");
    const filterData: FilterDataFromURL = JSON.parse(
      dataFromURL?.filterData || "{}"
    );

    const label = filterData.levelSelected?.label;
    if (filterData?.levelSelected?.label) {
      const level = levels.find((l) => l.label === label);
      let sheetGuid = dataFromURL?.guid || level?.sheetGuid;

      if (sheetGuid === DISPLAY_MODE["3D"]) {
        sheetGuid = "";
        dispatch(setDisplayMode(DISPLAY_MODE["3D"]));
      }

      if (level) {
        dispatch(setLevelSelected({ ...level, sheetGuid }));
        setLocalStorage("currentLevel", level);
      }
    }

    (async () => {
      const { data } = await userSettingApi.getUserSetting();
      let settings = data[0];

      if (dataFromURL) {
        settings = { ...settings, ...dataFromURL.settings };
        setSearchTaskValue(dataFromURL.searchValue);
      }

      if (dataFromURL?.taskId) {
        setTaskIdFromUrl(dataFromURL.taskId);
      }

      dispatch(setUserSetting(settings));

      //reset url
      navigate(window.location.pathname, { replace: true });
    })();
  };

  const operationShowModalDocumentCategory = (data: string) => {
    const dataFromURL = JSON.parse(data || "{}");
    levelFromUrl.current = dataFromURL.level || ALL_LEVEL_LABEL;
    setDocumentCategoryIdFromUrl(dataFromURL.documentCategoryId || "");
  };

  const operationShowModalTask = (data: string) => {
    const dataFromURL = JSON.parse(data || "{}");
    if (!levelFromUrl.current) {
      levelFromUrl.current = dataFromURL.level || ALL_LEVEL_LABEL;

      return;
    }
    setLocalStorage(CURRENT_SYSTEM_MODE_KEY, {
      value: SystemModeType.Task,
    });
    dispatch(setSystemMode(SystemModeType.Task));
    setTaskIdFromUrl(dataFromURL.taskId);
  };

  const operationCaptureKeynote = useCallback(
    (data: string) => {
      const dataFromURL = JSON.parse(decodeURIComponent(data) || "{}");
      const docCateId = dataFromURL?.documentCategoryId;
      const documentCategory = documentCategories.find(
        (cate) => cate.id === docCateId
      );

      if (docCateId) {
        dispatch(fetchBlackBoardByCategoryId(docCateId));
      }
      dispatch(setDocumentCategorySelected(documentCategory));
      dispatch(setModalType(ModalType.DOC_CATEGORY));
    },
    [documentCategories, dispatch]
  );

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const data = urlParams.get("data");
    if (!levels?.length || operation !== OPERATION.CaptureKeynote || !data) {
      return;
    }

    if (operation === OPERATION.CaptureKeynote) {
      dispatch(toggleIsCaptureKeynoteByOperation(true));
      const dataFromURL = JSON.parse(decodeURIComponent(data) || "{}");
      const levelLabel = dataFromURL?.levelLabel;
      const sheetGuid = dataFromURL?.guid;
      let targetLevel = levels.find((item) => item.label === levelLabel);
      const isExistsGuid = !!targetLevel?.sheets?.find(
        (sheet) => sheet.guid === sheetGuid
      )?.guid;
      const mode =
        targetLevel?.guid && targetLevel?.sheets?.length
          ? DISPLAY_MODE["2D"]
          : DISPLAY_MODE["3D"];

      if (!targetLevel) {
        return;
      }

      if (sheetGuid && isExistsGuid && mode === DISPLAY_MODE["2D"]) {
        targetLevel = { ...targetLevel, sheetGuid };
      }
      dispatch(setDisplayMode(mode));
      dispatch(setLevelSelected(targetLevel));
      dispatch(setSystemMode(SystemModeType.Document));
    }

    if (!documentCategories.length || isLoadingDocument) {
      return;
    }

    operationCaptureKeynote(data);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    operation,
    isLoadingDocument,
    documentCategories?.length,
    operationCaptureKeynote,
    levels?.length,
  ]);

  const operationCaptureKeyplan = (data: string) => {
    const dataFromURL: OperationCaptureKeyplanData = JSON.parse(
      decodeURIComponent(data) || "{}"
    );

    let sheetGuid = dataFromURL?.guid;
    const filterData: FilterDataType = JSON.parse(dataFromURL?.filterData);
    const label = filterData.levelSelected?.label;
    if (filterData?.levelSelected?.label) {
      const level = levels.find((l) => l.label === label);
      if (sheetGuid === DISPLAY_MODE["3D"]) {
        sheetGuid = "";
        dispatch(setDisplayMode(DISPLAY_MODE["3D"]));
      }

      if (level) {
        dispatch(setLevelSelected({ ...level, sheetGuid }));
      }
    }

    (async () => {
      const { data } = await userSettingApi.getUserSetting();
      let settings = data[0];
      if (filterData) {
        settings = { ...settings, ...filterData.settings };
        setSearchTaskValue(filterData.searchValue);
      }
      dispatch(setUserSetting(settings));
    })();
  };

  useEffect(() => {
    if (operation === OPERATION.CaptureKeynote || !levels?.length) {
      return;
    }

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const data = urlParams.get("data");
    if (data) {
      dispatch(
        setSystemMode(
          [
            OPERATION.ShowModalDocumentCategory,
            OPERATION.navigateDocumentItemModal,
          ].includes(operation as any)
            ? SystemModeType.Document
            : SystemModeType.Task
        )
      );
      switch (operation) {
        case OPERATION.CaptureKeyplan:
          operationCaptureKeyplan(data);
          break;
        case OPERATION.CreateTask:
          operationCreateTask(data);
          break;
        case OPERATION.ExportTask:
          operationExportTask(data);
          break;
        case OPERATION.ShowModalDocumentCategory:
          operationShowModalDocumentCategory(data);
          break;
        case OPERATION.ShowModalTask:
          operationShowModalTask(data);
          break;

        default:
          levelFromUrl.current = "";
          break;
      }
    } else {
      levelFromUrl.current = "";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operation, isSettingFloor, levels.length]);

  useEffect(() => {
    if (!dataProjectDetail?.id) {
      return;
    }
    if (!dataProjectDetail?.defaultBimPathId) {
      message.error(
        `Project ${dataProjectDetail?.name}はBIMファイルを指定していません。プロジェクト概要に移動して、BIMファイルを選択してください。`
      );
      navigate(generatePath(routePath.Home));

      return;
    }

    const mapLevelDataDefault: { [key: string]: Level } =
      dataProjectDetail?.levelData || {};
    const levelsOfProject = Object.values(mapLevelDataDefault).filter(
      (i) => !!i?.guid
    );

    if (!levelsOfProject.length) {
      message.error(
        "Revitモデルにレベルの３Dビューが入っていないので開けません。"
      );
      navigate(generatePath(routePath.Home));

      return;
    }
  }, [dataProjectDetail]);

  useEffect(() => {
    (async () => {
      if (!dataProjectDetail?.id) {
        return;
      }

      // floor was set
      if (!isSettingFloor || !isLoadedLevels || !levels.length) {
        return;
      }

      const currentSelectedLevel = getLocalStorage(CURRENT_LEVEL_KEY) || {};
      const currentLevelId = currentSelectedLevel[String(projectBimFileId)];
      const isDiffVersion = checkIsDiffDataProjectVersion(dataProjectDetail);
      if (isDiffVersion && isOnline) {
        dispatch(setLevelSelected(LEVEL_ALL));
        setIsSettingFloor(false);

        return;
      }

      let level = levels.find((f: Level) => {
        if (f.guid === LEVEL_OTHER_ID && !f.sheets?.length) {
          return false;
        }

        if (levelFromUrl?.current) {
          return f.label === levelFromUrl.current;
        }

        return f.guid === currentLevelId;
      });

      const setLevelDefault = () => {
        level = levels.filter((l) =>
          isOnline ? l.guid : l?.sheets?.length && l.guid
        )?.[0];

        if (!level && !isOnline) {
          message.warning(
            "2Dシートがまだ設定されませんのでプロジェクト概要で2Dシートを選択ください。"
          );

          return navigate(generatePath(routePath.Home));
        }

        const currentLevel = getLocalStorage(CURRENT_LEVEL_KEY) || {};
        currentLevel[String(projectBimFileId)] = level.guid;
        const currentLevelState = (location.state as any)?.currentLevelState;

        if (!isEmpty(currentLevelState)) {
          setLocalStorage(CURRENT_LEVEL_KEY, currentLevelState);
        } else {
          setLocalStorage(CURRENT_LEVEL_KEY, currentLevel);
        }
      };

      if (!level?.guid || (!level?.sheets?.length && !isOnline)) {
        setLevelDefault();
      }

      if (level && operation !== OPERATION.ExportTask) {
        const levelSelectedState = (location.state as any)?.levelSelectedState;

        if (!isEmpty(levelSelectedState)) {
          dispatch(setLevelSelected(levelSelectedState));
        } else {
          dispatch(setLevelSelected(level));
        }

        dispatch(resetState());

        // Reset documentTaskIds when back to forge viewer from task sheet
        if (!!settings?.documentTaskIds?.length) {
          dispatch(setUserSetting({ ...settings, documentTaskIds: [] }));
        }
      }
      setIsSettingFloor(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    bimFileId,
    projectBimFileId,
    isLoadedLevels,
    levels,
    dataProjectDetail,
    operation,
  ]);

  useEffect(() => {
    if (
      !bimFileId ||
      isSettingFloor ||
      !levelSelected.guid ||
      statusUpdateForgeVersion !== STATUS_UPDATE_FORGE_VERSION.CHECKED ||
      isLoadedDocumentTasks
    ) {
      return;
    }

    if (documentItemSelected || systemMode === SystemModeType.Task) {
      dispatch(
        fetchTaskByBimFile({
          bimFileId: bimFileId,
          level: levelSelected.guid ? levelSelected.label : undefined,
        })
      );
    }
  }, [
    bimFileId,
    levelSelected,
    isSettingFloor,
    statusUpdateForgeVersion,
    dispatch,
    documentItemSelected,
    systemMode,
    isLoadedDocumentTasks,
  ]);

  useEffect(() => {
    dispatch(setIsLoadedDocumentTasks(false));
  }, [levelSelected, bimFileId]);

  const handleReloadTaskDataAfterSync = useCallback(async () => {
    projectBimFileId && fetchPartnerCompanies();
    dispatch(fetchTaskTypes());
    const tasks = (
      await appDispatch(
        fetchTaskByBimFile({
          bimFileId: bimFileId,
          level: levelSelectedRef.current.guid
            ? levelSelectedRef.current.label
            : undefined,
        })
      )
    ).payload as TaskDTO[];

    if (systemModeRef.current !== SystemModeType.Task) {
      return;
    }

    const findTask = tasks.find(
      (task) => task.id === taskSelectedRef.current?.id
    );
    if (findTask) {
      handleSelectTask(findTask, dispatch, false);
    } else {
      selectDbIds([], {});
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchPartnerCompanies]);

  useEffect(() => {
    if (
      isSyncOfflineData ||
      !bimFileId ||
      isSettingFloor ||
      !levelSelected.guid
    ) {
      return;
    }

    sendWebSocketMessage({
      type: MessageType.RELOAD_TASK,
      data: "",
    });
    handleReloadTaskDataAfterSync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSyncOfflineData]);

  useEffect(() => {
    if (!webSocketMessage) {
      return;
    }

    if (webSocketMessage?.type === MessageType.RELOAD_TASK) {
      handleReloadTaskDataAfterSync();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webSocketMessage]);

  useEffect(() => {
    if (operation === OPERATION.CaptureKeynote) {
      return;
    }

    if (!isSettingFloor) {
      dispatch(
        setDisplayMode(
          !!levelSelected.guid && levelSelected.sheets?.length
            ? DISPLAY_MODE["2D"]
            : DISPLAY_MODE["3D"]
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSettingFloor, operation]);

  useEffect(() => {
    const header = headerRef.current!;

    const observer = new ResizeObserver(() => {
      setHeaderHeight(header?.offsetHeight);
    });
    if (header) {
      observer.observe(header);
    }

    return () => {
      if (header) {
        observer.unobserve(header);
      }
    };
  }, []);

  // handle side effect when click label on forge viewer
  useEffect(() => {
    if (!clickedLabelInfo) {
      return;
    }
    const item = clickedLabelInfo.item;
    const isModeTask = clickedLabelInfo.systemMode === SystemModeType.Task;
    const isModeDocument =
      clickedLabelInfo.systemMode === SystemModeType.Document;

    if (isModeTask) {
      const dbId = getDbIdByExternalId(item.externalId);
      selectDbIds(dbId, {
        color:
          MapInspectionItemColor[
            (clickedLabelInfo.item.status ||
              InspectionItemType.Defect) as InspectionItemType
          ],
      });
      const task = tasks.find((t) => t.id === item.id);
      if (!task) {
        if (!isEmpty(taskSelected)) {
          dispatch(setTaskSelected());
          dispatch(setModalType(ModalType.NONE));
        }

        return;
      }
      selectLabel([task.id]);
      dispatch(setTaskSelected(task));
      dispatch(setModalType(ModalType.TASK));

      return;
    }

    if (isModeDocument && isMovingDoc && documentCategorySelected?.id) {
      const isPhotoLedger =
        documentCategorySelected.documentType ===
        DocumentTemplateType.PHOTO_LEDGER;
      const newIds = [...selectedItemIdsWhenMovingDoc];
      const updateNewIds = (id?: string) => {
        if (!id) {
          return;
        }

        const isExistsId = selectedItemIdsWhenMovingDoc.includes(id);
        isExistsId ? newIds.splice(newIds.indexOf(id), 1) : newIds.push(id);
      };

      if (!isPhotoLedger) {
        const documentItemId = documentCategorySelected.documentItems?.find(
          (i) => i.externalId === item.externalId
        )?.id;
        updateNewIds(documentItemId);
      }

      dispatch(setSelectedItemIdsWhenMovingDoc(newIds));

      return;
    }
    const state = store.getState();
    if (isModeDocument) {
      const onClickDocumentItemLabel = (
        item: DisplayItem,
        isMatchExternal = true
      ) => {
        const category = documentCategories.find(
          (cate) => cate.id === item?.categoryId
        );
        if (!category) {
          return;
        }
        let docItem;
        if (isSelfInspectionTemplate(category.documentType)) {
          const documentItemsLength = category.documentItems?.length || 0;
          for (
            let documentItemIndex = 0;
            documentItemIndex < documentItemsLength;
            documentItemIndex++
          ) {
            const documentItem = category.documentItems?.[documentItemIndex];
            if (documentItem?.id === item.id) {
              docItem = { ...documentItem, displayOrder: documentItemIndex };
              break;
            }
          }
        } else {
          docItem = category.documentItems?.find((t) => {
            return t.id === item.id;
          });
        }
        if (!docItem && !!item.externalId && isMatchExternal) {
          docItem = category.documentItems?.find((t) => {
            return t.externalId === item.externalId;
          });
        }

        if (docItem) {
          const isBlockDocumentItem =
            !!mapDocumentItemBlockIdsByTemplateId[
              docItem?.templateId || ""
            ]?.has(docItem.id) && !docItem?.offlineId;
          if (isBlockDocumentItem) {
            return;
          }

          const selectedIds = [docItem.id];
          if (isSelfInspectionTemplate(category.documentType)) {
            selectedIds.push(category?.id);
          }
          selectLabel(selectedIds);
          if (state.document.documentItemSelected?.id !== item.id) {
            dispatch(setDocumentItemSelected(docItem));
          }
          if (state.document.documentCategorySelected?.id !== category.id) {
            dispatch(setDocumentCategorySelected(category));
          }
          if (state.document.documentGroupSelected) {
            dispatch(setDocumentGroupSelected());
          }
          dispatch(setModalType(ModalType.DOC_ITEM));
        }
      };

      const onClickDocumentCategoryLabel = (item: DisplayItem) => {
        const categoryId = item.id.split("/")[0];
        const docCategory = documentCategories.find(
          (doc) => doc.id === categoryId
        );

        if (!docCategory) {
          return;
        }
        if (isSelfInspectionTemplate(docCategory.documentType)) {
          selectLabel([docCategory.id]);
        }
        state.document.documentItemSelected &&
          dispatch(setDocumentItemSelected());
        state.document.documentGroupSelected &&
          dispatch(setDocumentGroupSelected());
        if (state.document.documentCategorySelected?.id !== docCategory.id) {
          dispatch(setDocumentCategorySelected(docCategory));
        }
        if (modalType !== ModalType.DOC_CATEGORY) {
          dispatch(setModalType(ModalType.DOC_CATEGORY));
        }
      };

      const template = documentTemplates[item.templateId!];
      if (!template) {
        if (documentItemSelected) {
          dispatch(setDocumentItemSelected());
          dispatch(
            setModalType(
              documentCategorySelected ? ModalType.DOC_CATEGORY : ModalType.NONE
            )
          );
        }

        return;
      }
      if (isSelfInspectionTemplate(template.documentType)) {
        if (isShowEditingFamily) {
          return;
        }
        if (!!(item as any).indexId) {
          onClickDocumentItemLabel(item, false);
        } else {
          onClickDocumentCategoryLabel(item);
        }
      } else {
        clearTimeout(timeoutFocusLabelRef.current);
        timeoutFocusLabelRef.current = setTimeout(() => {
          const dbId = getDbIdByExternalId(item.externalId);
          selectDbIds(dbId, {
            color:
              MapDocumentCategoryStatusTypeColor[
                (item.status ||
                  DocumentCategoryStatusType.NotStarted) as DocumentCategoryStatusType
              ],
          });
          timeoutFocusLabelRef.current = undefined;
        }, 700);
        timeoutFocusLabelRef.current = undefined;
        if (isShowEditingFamily) {
          return;
        }
        onClickDocumentItemLabel(item);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clickedLabelInfo, isShowEditingFamily]);

  const handleCloseDocument = () => {
    dispatch(resetState());
  };

  const onUpdateVersion = async (version: string | number) => {
    const fullBimPath = `${bimFileId}?version=${version}`;
    if (projectBimFileId) {
      const { data: res } = await projectBimFileApi.updateProject({
        ...dataProjectDetail,
        id: projectBimFileId,
        defaultBimPathId: encodeURIComponent(fullBimPath),
        isSelected: true,
      });
      if (res?.id) {
        dispatch(setDataProjectDetail(res));
        const newDataProjects = [...dataProjects].map((dataProject) => {
          return dataProject.id === dataProjectDetail?.id ? res : dataProject;
        });
        dispatch(setDataProjects(newDataProjects));
      }
    }
  };

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    systemMode === SystemModeType.Task
      ? setSearchTaskValue(e.target.value)
      : setSearchDocumentValue(e.target.value);
  };

  const handleOpenProjectDashboard = useCallback(() => {
    dispatch(setWidgetsMode(WidgetsMode.PROJECT_DASHBOARD));
  }, [dispatch]);

  const handleOpenUserDashboard = useCallback(() => {
    dispatch(setWidgetsMode(WidgetsMode.USER_DASHBOARD));
  }, [dispatch]);

  const handleCloseWidgets = useCallback(() => {
    dispatch(setWidgetsMode(WidgetsMode.NONE));
  }, [dispatch]);

  const handleOpenDocumentByLevel = useCallback(() => {
    dispatch(resetToInitState());

    navigate(
      generatePath(routePath.DocumentLevel, {
        projectBimFileId: dataProjectDetail.id,
      })
    );
  }, [dispatch, navigate, dataProjectDetail]);

  const handleRouteToHome = useCallback(() => {
    window.location.href = "/";
  }, []);

  useEffect(() => {
    handleCloseWidgets();
    setSearchTaskValue("");
    setSearchDocumentValue("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [systemMode]);

  useEffect(() => {
    setSearchTaskValue("");
    setSearchDocumentValue("");
  }, [levelSelected]);

  const onCloseCreateTask = useCallback(() => {
    dispatch(setCreateTask(false));
    dispatch(setCreateSelfInspectionTask(false));
  }, [dispatch]);

  const handleUpdateClickForgeInfo = useCallback((info?: ClickInfo) => {
    setClickInfo(info);
  }, []);

  return {
    mapDocumentItemBlockIdsByTemplateId,
    isOnline,
    operation,
    isLoadedViewerModelData,
    isLoadedSpaces,
    isCaptureKeynoteByOperation,
    isWaittingCaptureKeynote,
    documentItemSelected,
    documentGroupSelected,
    isLoadedDocumentTasks,
    systemMode,
    documentCategorySelected,
    documentTemplateSelected,
    clickInfo,
    contentHeight,
    tasks,
    levelSelected,
    modalType,
    taskSelected,
    documentCategories,
    documentItems,
    areas,
    isCreateTask,
    isCreateSelfInspectionTask,
    levels,
    isLoadingDocument,
    settings,
    searchTaskValue,
    searchDocumentValue,
    hasUpdate,
    isOpenVersion,
    headerRef,
    bimFileId,
    isSettingFloor,
    version,
    versions,
    headerHeight,
    levelFromUrl,
    imageURL,
    isLoadedFamilyInstances,
    isLoadedLevels,
    documentTemplates,
    isGeneratingFamilyInstances,
    isLoadedExternalId,
    displayMode,
    projectId,
    listenProcessCreateTask,
    clickedLabelInfo,
    clickedLabelInfoRef,
    filterDocumentCategoryOptions,
    mapTaskType,
    statusUpdateForgeVersion,
    isDownloadPdfOnMobile,
    setUpdate,
    handleCloseWidgets,
    onCloseVersion,
    handleCloseDocument,
    onUpdateVersion,
    onSearch,
    handleOpenDocumentByLevel,
    handleOpenUserDashboard,
    handleOpenProjectDashboard,
    setClickInfo,
    setClickedLabelInfo,
    dispatch,
    handleRouteToHome,
    setSearchTaskValue,
    setSearchDocumentValue,
    onCloseCreateTask,
    handleUpdateClickForgeInfo,
    projectBimFileId,
  };
}
