import { PageFormat } from "./component";

export enum TEMPLATE_TASKSHEET_TYPE {
  INSTRUCTIONS = "INSTRUCTIONS",
  REPORT = "REPORT",
}

export interface TaskSheetTemplate {
  id?: string;
  templateName: string;
  pages: PageFormat[];
  templateType?: TEMPLATE_TASKSHEET_TYPE;
  components: any[];
  isDefault: boolean;
  defaultTemplateId?: string;
  originTemplateId?: string;
  createdBy?: string;
  createdAt?: Date;
  updatedAt?: Date;
}
