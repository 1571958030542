import { fabric } from "fabric";
import { SharpBase } from "./sharp-base";

let origX = 0;
let origY = 0;

// Draw Rectangle/Square
export class Rectangle extends SharpBase {
  constructor(canvas: fabric.Canvas) {
    super(canvas, "Rectangle");
  }

  onMouseMove = function (o: { e: any }) {
    const instance = this;

    if (!instance.isEnable()) {
      return;
    }
    const pointer = instance.canvas.getPointer(o.e);
    const activeObj = instance.canvas.getActiveObject();

    activeObj.stroke = instance.canvas.freeDrawingBrush.color;
    activeObj.strokeWidth = instance.canvas.lineThickness;
    activeObj.fill = "transparent";

    if (origX > pointer.x) {
      activeObj.set({
        left: pointer.x,
      });
    }
    if (origY > pointer.y) {
      activeObj.set({
        top: pointer.y,
      });
    }

    activeObj.set({
      width: Math.abs(origX - pointer.x),
    });
    activeObj.set({
      height: Math.abs(origY - pointer.y),
    });

    activeObj.setCoords();
    instance.canvas.renderAll();
  };

  onMouseDown = function (o: { e: any }) {
    const instance = this;
    instance.enable();

    const pointer = instance.canvas.getPointer(o.e);
    origX = pointer.x;
    origY = pointer.y;

    const rect = new fabric.Rect({
      left: origX,
      top: origY,
      originX: "left",
      originY: "top",
      width: pointer.x - origX,
      height: pointer.y - origY,
      hasBorders: false,
      hasControls: false,
      selectable: false,
    });

    instance.canvas.add(rect).setActiveObject(rect);
  };
}
