import { saveAs } from "file-saver";
import axios from "axios";
import {
  FONT_SIZE_MIN,
  FONT_SIZE_OFFSET,
  LINE_HEIGHT_DEFAULT,
} from "components/modal/PreviewDocumentCategory/hooks/useHandleTextOverflow";
import { message } from "components/base";

let timeout: NodeJS.Timeout;
const MAX_LOOP_CHECK_TEXT_OVERFLOW = 5_000;

export const moveCaretToEnd = (element?: HTMLElement) => {
  if (!element) {
    return;
  }

  const range = document.createRange();
  range.selectNodeContents(element);
  range.collapse(false);

  const selection = window.getSelection();
  if (selection) {
    selection.removeAllRanges();
    selection.addRange(range);
  }
  element.focus();
};

export const transformSizeForTextElement = ({
  element,
  isShowMessage = true,
  lastInpytTypingTextRef,
  sizeDefault,
  isTransformForParent = false,
  otherConditionCheckTextOverflow,
}: {
  otherConditionCheckTextOverflow?: () => void;
  isShowMessage?: boolean;
  element: HTMLElement;
  lastInpytTypingTextRef: React.MutableRefObject<string | undefined>;
  isTransformForParent?: boolean;
  sizeDefault: {
    fontSize: number;
    lineHeight: number;
  };
}) => {
  const parentElement = element.parentElement;
  if (!parentElement) {
    return {
      shouldUpdate: false,
      text: element.innerHTML,
    };
  }

  const checkIsOverflow = (element: HTMLElement) => {
    const parentElement = element.parentElement;
    if (!parentElement) {
      return false;
    }

    let res =
      otherConditionCheckTextOverflow?.() ||
      element.clientHeight > parentElement.clientHeight ||
      parentElement.scrollHeight > parentElement.clientHeight;
    if (isTransformForParent) {
      res =
        otherConditionCheckTextOverflow?.() ||
        parentElement.scrollHeight > parentElement.clientHeight;
    }

    return res;
  };

  const fontSizeNumber = Number(
    getComputedStyle(
      isTransformForParent ? parentElement : element
    ).fontSize.replace("px", "")
  );

  const isLimitExceedFontSize = fontSizeNumber === FONT_SIZE_MIN;
  let isOverflow = checkIsOverflow(element);
  let text = element.innerHTML || "";
  let shouldUpdate = false;
  const isLimitLineHeight = (fs: number) =>
    element.clientHeight <= fs * LINE_HEIGHT_DEFAULT;

  if (isOverflow && isLimitExceedFontSize) {
    if (isShowMessage) {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        message.warning("テキストが長すぎるため入力できません。");
      }, 300);
    }
  }

  if (
    !lastInpytTypingTextRef.current ||
    !isOverflow ||
    !isLimitExceedFontSize
  ) {
    lastInpytTypingTextRef.current = text;
  }

  if (isOverflow && isLimitExceedFontSize && lastInpytTypingTextRef.current) {
    shouldUpdate = !isLimitLineHeight(fontSizeNumber);

    return {
      text: lastInpytTypingTextRef.current,
      shouldUpdate,
    };
  }

  const eleToSizeStyle = isTransformForParent ? parentElement : element;

  // increase size
  if (!isOverflow) {
    let isOverflowToMuchAfterChangeFontsize = checkIsOverflow(element);
    let fs = fontSizeNumber;
    let maxLoop = MAX_LOOP_CHECK_TEXT_OVERFLOW;

    while (
      !isOverflowToMuchAfterChangeFontsize &&
      fs !== sizeDefault.fontSize &&
      maxLoop >= 0
    ) {
      maxLoop--;
      fs = fs + FONT_SIZE_OFFSET;
      fs = fs > sizeDefault.fontSize ? sizeDefault.fontSize : fs;
      const lh = fs * LINE_HEIGHT_DEFAULT;

      eleToSizeStyle.style.fontSize = `${fs}px`;
      eleToSizeStyle.style.lineHeight = `${lh}px`;
      isOverflowToMuchAfterChangeFontsize = checkIsOverflow(element);

      if (isOverflowToMuchAfterChangeFontsize) {
        fs = fs - FONT_SIZE_OFFSET;

        eleToSizeStyle.style.fontSize = `${fs}px`;
        eleToSizeStyle.style.lineHeight = `${fs * LINE_HEIGHT_DEFAULT}px`;

        break;
      }
    }
  }

  // reduce size
  let maxLoop = MAX_LOOP_CHECK_TEXT_OVERFLOW;
  let fs = fontSizeNumber;
  while (isOverflow && maxLoop >= 0) {
    maxLoop--;

    if (isLimitLineHeight(fs)) {
      shouldUpdate = false;

      break;
    }

    if (isOverflow && fs !== FONT_SIZE_MIN) {
      fs = fs - FONT_SIZE_OFFSET;

      eleToSizeStyle.style.fontSize = `${fs}px`;
      eleToSizeStyle.style.lineHeight = `${fs * 1.2}px`;
    }

    isOverflow = checkIsOverflow(element);
    if (isOverflow && fs === FONT_SIZE_MIN) {
      shouldUpdate = true;
      text = text.slice(0, -1);
      element.innerHTML = text;
    }
  }

  return {
    text,
    shouldUpdate,
  };
};

export const downloadPdfByUrl = async (url: string, fileName: string) => {
  const arraybuffer = await axios(url, {
    responseType: "arraybuffer",
  }).then((res) => res.data);
  const newPdfBlob = new Blob([arraybuffer], {
    type: "application/octet-stream",
  });

  saveAs(newPdfBlob, fileName);
};
