import { useEffect, useState } from "react";

interface Props {
  parentRef?: React.RefObject<HTMLDivElement>;
}

function useScroll({ parentRef }: Props) {
  const [isScroll, setIsScroll] = useState(false);

  useEffect(() => {
    if (!parentRef?.current) return;

    const handleScroll = () => {
      const timer = setTimeout(() => {
        setIsScroll(false);
      }, 500);

      if (!isScroll) {
        setIsScroll(true);
        clearTimeout(timer);
      }
    };

    parentRef?.current.addEventListener("scroll", handleScroll);

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      parentRef?.current?.removeEventListener("scroll", handleScroll);
    };
  });

  return { isScroll };
}

export default useScroll;
