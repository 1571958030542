import {
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text,
} from "@chakra-ui/react";
import { documentCategoryApi, documentItemApi } from "apiClient/v2";
import { SvgIcon } from "components/SvgIcon";
import {
  DocumentCategoryStatusType,
  DocumentItemKey,
  MapDocumentCategoryStatusType,
  MapDocumentCategoryStatusTypeColor,
} from "constants/enum";
import { DocumentCategoryDTO } from "interfaces/dtos/documentCategoryDTO";
import { DocumentItemDTO } from "interfaces/dtos/documentItemDTO";
import { User } from "interfaces/models/user";
import { getColorTextByStatus } from "models/document";
import { GetContentItemLog, OPERATION_ITEM_LOG } from "models/documentItemLog";
import { TypeHandleInitData } from "pages/forge-viewer/hooks/useSupportSyncDataOffline";
import { memo } from "react";
import { useDispatch } from "react-redux";
import {
  setDocumentCategorySelected,
  updateDocumentCategory,
  updateDocumentItem,
} from "redux/documentSlice";
import store from "redux/store";
import { updateElementInArray } from "utils/array";
import { getDocumentStatus } from "utils/document";
import { setSelectionColor } from "utils/forge";
import { transformBodyForCombineData } from "utils/offline";

interface Props {
  isLoadedViewer: boolean;
  currentUser?: User | null;
  documentCategorySelected?: DocumentCategoryDTO;
  documentItemSelected?: DocumentItemDTO;
  isDisabled?: boolean;
  insertDocumentItemLog?: (params: GetContentItemLog) => Promise<void>;
}

const ItemStatusMenu = (props: Props) => {
  const {
    isLoadedViewer,
    documentItemSelected,
    documentCategorySelected,
    currentUser,
    isDisabled,
    insertDocumentItemLog,
  } = props;
  const dispatch = useDispatch();

  const {
    title: titleStatus,
    bgColor: bgColorStatus,
    status,
  } = getDocumentStatus(documentItemSelected?.status);

  const handleChangeStatus = async (e: string) => {
    if (!documentItemSelected?.id) {
      return;
    }

    const value = String(e);
    const now = new Date();

    const bodyUpdate: Partial<DocumentItemDTO> = {
      id: documentItemSelected.id,
      status: value,
      isIgnoreInsertLog: true,
    };

    if (value === DocumentCategoryStatusType.Approved) {
      bodyUpdate.approvedDateTime = now;
    }

    setSelectionColor({
      color:
        MapDocumentCategoryStatusTypeColor[value as DocumentCategoryStatusType],
      shouldRender: true,
    });

    const newItem: DocumentItemDTO = {
      ...documentItemSelected,
      ...bodyUpdate,
    };

    dispatch(updateDocumentItem(newItem));
    documentItemApi
      .updateItem(
        transformBodyForCombineData<DocumentItemDTO>({
          body: bodyUpdate as DocumentItemDTO,
          bodyBefore: documentItemSelected,
          typeInitData: TypeHandleInitData.DOCUMENT_ITEM,
        })
      )
      .then(() => {
        insertDocumentItemLog?.({
          field: DocumentItemKey.STATUS,
          value: newItem.status,
          operation: OPERATION_ITEM_LOG.UPDATE,
        });
      });

    if (!documentCategorySelected?.id) {
      return;
    }

    const documentCategory = structuredClone(
      store
        .getState()
        .document.documentCategories.find(
          (node) => node.id === documentCategorySelected.id
        )
    );

    if (!documentCategory) return;

    if (value === DocumentCategoryStatusType.SendBack) {
      documentCategory.status = value;

      await documentCategoryApi.updateCategory(
        transformBodyForCombineData<DocumentCategoryDTO>({
          body: {
            id: documentCategorySelected.id,
            status: documentCategory.status,
          } as DocumentCategoryDTO,
          bodyBefore: documentCategorySelected,
          typeInitData: TypeHandleInitData.DOCUMENT_CATEGORY,
        })
      );
    }

    if (value === DocumentCategoryStatusType.Approved) {
      documentCategory.status = value;
      documentCategory.approveDate = now.toString();
      documentCategory.implementDate = now.toString();
      if (currentUser?.id) {
        const userApproved = documentCategory.userApproved || [];
        documentCategory.userApproved = userApproved.includes(currentUser.id)
          ? userApproved
          : [...userApproved, currentUser.id];
      }

      documentCategoryApi.updateCategory(
        transformBodyForCombineData<DocumentCategoryDTO>({
          body: {
            id: documentCategorySelected.id,
            status: documentCategory.status,
            approveDate: documentCategory.approveDate,
            implementDate: documentCategory.implementDate,
            userApproved: documentCategory.userApproved,
          } as DocumentCategoryDTO,
          bodyBefore: documentCategorySelected,
          typeInitData: TypeHandleInitData.DOCUMENT_CATEGORY,
        })
      );
    }

    updateElementInArray({
      array: documentCategory?.documentItems || [],
      keyIndex: DocumentItemKey.ID,
      element: newItem,
    });

    dispatch(updateDocumentCategory(documentCategory));
  };

  return (
    <Menu matchWidth closeOnSelect isLazy>
      {({ isOpen }) => (
        <>
          <MenuButton
            w="100%"
            sx={{
              span: {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                paddingLeft: "2rem",
                paddingRight: "1.5rem",
                bgColor: bgColorStatus,
                borderRadius: "4px",
              },
            }}
            disabled={!isLoadedViewer || isDisabled}
          >
            <Text
              display="flex"
              h="4rem"
              alignItems="center"
              fontWeight="bold"
              color={getColorTextByStatus(status)}
            >
              {titleStatus}
            </Text>
            <SvgIcon
              src="/img/icon-navigation-arrow_drop_down.svg"
              pathFill={getColorTextByStatus(status)}
              transition="0.4s"
              transform={isOpen ? "rotate(180deg)" : "rotate(0deg)"}
            />
          </MenuButton>
          <MenuList zIndex={11} display={isOpen ? "block" : "none"}>
            <MenuOptionGroup
              value={`${status}`}
              title=""
              type="radio"
              onChange={handleChangeStatus as any}
            >
              {Object.keys(MapDocumentCategoryStatusType).map((type) => (
                <MenuItemOption
                  key={type}
                  value={type}
                  icon={<SvgIcon src="/img/icon-action-check_circle.svg" />}
                  padding="0"
                  paddingLeft="4px"
                  _focus={{ bg: "#F0F9FF" }}
                  isDisabled={
                    type === DocumentCategoryStatusType.Approved &&
                    ![
                      DocumentCategoryStatusType.WaitConfirm,
                      DocumentCategoryStatusType.Approved,
                    ].includes(status as DocumentCategoryStatusType)
                  }
                >
                  <Text
                    bg={
                      MapDocumentCategoryStatusTypeColor[
                        type as DocumentCategoryStatusType
                      ]
                    }
                    color={getColorTextByStatus(type)}
                    py=".3rem"
                    px="1rem"
                    mx="1rem"
                    my=".3rem"
                    borderRadius="4px"
                    display="flex"
                    alignItems="center"
                  >
                    {
                      MapDocumentCategoryStatusType[
                        type as DocumentCategoryStatusType
                      ]
                    }
                  </Text>
                </MenuItemOption>
              ))}
            </MenuOptionGroup>
          </MenuList>
        </>
      )}
    </Menu>
  );
};

export default memo(ItemStatusMenu);
