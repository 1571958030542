import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  forgeApi,
  partnerCompanyApi,
  projectApi,
  projectBimFileApi,
} from "apiClient/v2";
import { REDUCER_KEY } from "constants/redux";
import { DataProjectModel } from "interfaces/models/dataProjectModel";
import { PartnerCompany } from "interfaces/models/partnerCompany";
import { ForgeProject, ProjectDetail } from "interfaces/models/project";
import { cloneDeep } from "lodash";

export interface ProjectState {
  projects: ProjectDetail[];
  dataProjects: DataProjectModel[];
  projectDetail: ProjectDetail;
  dataProjectDetail: DataProjectModel;
  isFetchingProjects: boolean;
  isFetchingDataProjects: boolean;
  cachingProjectBimFileId: string | undefined;
  lastCheckCacheProject: number;
  partnerCompanies: PartnerCompany[];
  isDeleteProject: boolean;
  forgeProjects: ForgeProject[];
  isFetchingDataProjectDetail: boolean;
  isFetchingPartnerCompanies: boolean;
  isFetchedPartnerCompanies: boolean;
}

const initialState: ProjectState = {
  projects: [],
  dataProjects: [],
  projectDetail: {} as ProjectDetail,
  dataProjectDetail: {} as DataProjectModel,
  isFetchingProjects: true,
  isFetchingDataProjects: true,
  cachingProjectBimFileId: undefined,
  lastCheckCacheProject: Date.now(),
  partnerCompanies: [],
  isDeleteProject: false,
  forgeProjects: [],
  isFetchingDataProjectDetail: false,
  isFetchingPartnerCompanies: false,
  isFetchedPartnerCompanies: false,
};

export const fetchProjects = createAsyncThunk(
  "project/fetchProjectList",
  async (forceGet?: boolean) => {
    const { data: forgeProjects } = await forgeApi.getProjects(forceGet);
    const { data } = await projectApi.getProjectList();
    const mapForgeProject = new Map(
      forgeProjects.map((project) => [project.id, project])
    );

    return {
      projects: data.filter((node) => mapForgeProject.has(node.id)),
      forgeProjects,
    };
  }
);

export const fetchForceProjects = createAsyncThunk(
  "project/fetchForceProjects",
  async (forceGet?: boolean) => {
    const { data } = await forgeApi.getProjects(forceGet);

    return data;
  }
);

export const fetchDataProjects = createAsyncThunk(
  "project/fetchProjectDataList",
  async (forceGet?: boolean) => {
    const forceProjects = await forgeApi
      .getProjects(forceGet)
      .then((res) => res.data);
    const mapProject: { [projectId: string]: ProjectDetail } = Object.assign(
      {},
      ...forceProjects.map((project) => ({ [project.id]: project }))
    );

    let projectListRes = (await projectBimFileApi.getProjectList())?.data;
    if (Array.isArray(projectListRes)) {
      projectListRes = cloneDeep(projectListRes).filter((item) =>
        Object.keys(mapProject).includes(item.projectId)
      );
    }

    return projectListRes;
  }
);

export const fetchProjectDetail = createAsyncThunk(
  "project/fetchProjectDetail",
  async ({ projectId }: { projectId: string }) => {
    const { data } = await projectApi.getProject(projectId);

    return data;
  }
);

export const fetchDataProjectDetail = createAsyncThunk(
  "project/fetchDataProjectDetail",
  async (projectBimFileId: string) => {
    const response = await projectBimFileApi.getProject(projectBimFileId);

    return response.data;
  }
);

export const fetchPartnerCompanies = createAsyncThunk(
  "project/fetchPartnerCompanies",
  async (projectBimFileId: string) => {
    return await partnerCompanyApi.getPartnerList({ projectBimFileId });
  }
);

export const createParentProject = createAsyncThunk(
  "project/createUpdateProject",
  async (payload: ProjectDetail) => {
    const { data: response } = await projectApi.createProject(payload);

    return { ...response, ...payload };
  }
);

export const createUpdateProjectApi = createAsyncThunk(
  "project/createUpdateProjectBimFile",
  async (payload: DataProjectModel) => {
    const isCreate = payload.isCreate;
    delete payload.isCreate;
    const { data: response } = isCreate
      ? await projectBimFileApi.createProject(payload)
      : await projectBimFileApi.updateProject(payload);

    return response;
  }
);

export const deleteProject = createAsyncThunk(
  "projectBimFile/delete",
  async (id: string) => {
    await projectBimFileApi.deleteProjectList([id]);

    return id;
  }
);

export const projectSlice = createSlice({
  name: REDUCER_KEY.PROJECT,
  initialState,
  reducers: {
    setProjects: (state, action: PayloadAction<ProjectDetail[]>) => {
      state.projects = Array.isArray(action.payload) ? action.payload : [];
    },
    setProjectDetail: (state, action: PayloadAction<ProjectDetail>) => {
      state.projectDetail = action.payload;
    },
    setDataProjectDetail: (state, action: PayloadAction<DataProjectModel>) => {
      state.dataProjectDetail = action.payload;
    },
    setDataProjects: (state, action: PayloadAction<DataProjectModel[]>) => {
      state.dataProjects = Array.isArray(action.payload) ? action.payload : [];
    },
    setCachingProject: (state, action: PayloadAction<string | undefined>) => {
      state.cachingProjectBimFileId = action.payload;
    },
    checkCacheProject: (state) => {
      state.lastCheckCacheProject = Date.now();
    },
    resetFetchedPartnerCompanies: (state) => {
      state.isFetchedPartnerCompanies = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchProjects.fulfilled,
      (state, { payload: { projects, forgeProjects } }) => {
        state.projects = Array.isArray(projects) ? projects : [];
        state.forgeProjects = Array.isArray(forgeProjects) ? forgeProjects : [];
        state.isFetchingProjects = false;
      }
    );
    builder.addCase(fetchProjects.pending, (state) => {
      state.isFetchingProjects = true;
    });
    builder.addCase(fetchDataProjects.pending, (state) => {
      state.isFetchingDataProjects = true;
    });
    builder.addCase(fetchDataProjects.fulfilled, (state, action) => {
      state.dataProjects = Array.isArray(action.payload) ? action.payload : [];
      state.isFetchingDataProjects = false;
    });
    builder.addCase(fetchProjectDetail.fulfilled, (state, action) => {
      state.projectDetail = action.payload;
    });
    builder.addCase(fetchDataProjectDetail.pending, (state, action) => {
      state.isFetchingDataProjectDetail = true;
    });
    builder.addCase(fetchDataProjectDetail.fulfilled, (state, action) => {
      if (action.payload) {
        state.dataProjectDetail = action.payload;
      }
      state.isFetchingDataProjectDetail = false;
    });
    builder.addCase(fetchPartnerCompanies.pending, (state, action) => {
      state.isFetchingPartnerCompanies = true;
    });
    builder.addCase(fetchPartnerCompanies.fulfilled, (state, action) => {
      if (action.payload.data.length) {
        state.partnerCompanies = action.payload.data;
      }
      state.isFetchingPartnerCompanies = false;
      state.isFetchedPartnerCompanies = true;
    });
    builder.addCase(createUpdateProjectApi.fulfilled, (state, action) => {
      if (!action.payload?.id) return;
      state.dataProjectDetail = action.payload as any;
      const newDataProjects = [...state.dataProjects].map((dataProject) => {
        return dataProject.id === action.payload.id
          ? action.payload
          : dataProject;
      });
      state.dataProjects = newDataProjects;
    });
    builder.addCase(deleteProject.pending, (state) => {
      state.isDeleteProject = true;
    });
    builder.addCase(deleteProject.fulfilled, (state, action) => {
      state.isDeleteProject = false;
      state.dataProjects = state.dataProjects.filter(
        (node) => node.id !== action.payload
      );
    });
    builder.addCase(fetchForceProjects.fulfilled, (state, action) => {
      state.forgeProjects = action.payload;
    });
    builder.addCase(createParentProject.fulfilled, (state, action) => {
      state.projects.push(action.payload);
    });
  },
});

export const {
  setProjects,
  setDataProjects,
  setProjectDetail,
  setDataProjectDetail,
  setCachingProject,
  checkCacheProject,
  resetFetchedPartnerCompanies,
} = projectSlice.actions;

export default projectSlice.reducer;
