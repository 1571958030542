import { Box, Textarea, useOutsideClick } from "@chakra-ui/react";
import { getValueFromFamilyInstace } from "components/editor-builder/component-preview/TableComponentPreview/utils";
import {
  BlackboardKey,
  CellProperty,
  FamilyInstanceKey,
  LinkedFamilyData,
  TextPosition,
} from "constants/enum";
import {
  CellType,
  TableStyleType,
  TemplateComponent,
} from "interfaces/models/component";
import { FamilyInstance } from "interfaces/models/familyInstance";
import { memo, useEffect, useMemo, useRef, useState } from "react";
import NormalTextListPreview from "./NormalTextListPreview";

interface iInputProps {
  value: string;
  property?: CellProperty;
  field?: LinkedFamilyData;
  textAlign?: TextPosition;
  minRow: number;
}

interface iProps {
  cells: CellType[];
  familyInstance: FamilyInstance;
  commentManageExecute?: any[];
  isOnlyViewBlackboard?: boolean;
  isAutoResize?: boolean;
  component: TemplateComponent;
  cellStyle?: Partial<TableStyleType>;
  onChangeCommentManageExecute?: (value: any[]) => void;
}

const CommentManageExecuteTextInput = ({
  cells,
  cellStyle,
  familyInstance,
  isOnlyViewBlackboard,
  isAutoResize = true,
  commentManageExecute,

  onChangeCommentManageExecute,
}: iProps) => {
  const [inputs, setInputs] = useState<iInputProps[]>([
    { value: "", textAlign: TextPosition.START, minRow: 1 },
  ]);
  const [mapTextPositionOpen, setMapTextPositionOpen] = useState<{
    [key: string]: boolean;
  }>({});

  const inputRefs = useRef<(HTMLTextAreaElement | null)[]>([]);
  const boxContainerRef = useRef<HTMLDivElement>(null);
  const isChangedValueRef = useRef(false);
  const updateInputsRef = useRef<iInputProps[] | undefined>(undefined);

  const commentManageExecuteDefault = useMemo(() => {
    const data: iInputProps[] = [];
    cells?.forEach((cellOfBlackboard) => {
      let value = cellOfBlackboard?.value || "";
      let field = "" as any;
      const familyInstanceValue = getValueFromFamilyInstace(
        cellOfBlackboard?.cellLinkedData?.field as FamilyInstanceKey,
        familyInstance
      );

      if (cellOfBlackboard?.cellProperty === CellProperty.FAMILY_INSTANCE) {
        value = familyInstanceValue || "";
        field = cellOfBlackboard.cellLinkedData?.field || "";
      }

      const minRow = value.match(/\n/g);

      data.push({
        value,
        textAlign: (cellOfBlackboard?.style?.justifyContent ||
          TextPosition.START) as TextPosition,
        property: cellOfBlackboard?.cellProperty as CellProperty,
        field,
        minRow: (minRow?.length || 0) + 1,
      });
    });

    return data?.length
      ? data
      : [
          {
            value: "",
            textAlign: TextPosition.START,
            property: CellProperty.TEXT,
          },
        ];
  }, [cells, familyInstance]);

  // handle saveData
  useOutsideClick({
    ref: boxContainerRef,
    enabled: !!Object.keys(mapTextPositionOpen).length,
    handler: () => {
      removeHighlightBoxContainer();

      setMapTextPositionOpen({});
      inputs.forEach((_, key) => {
        setTimeout(() => {
          inputRefs.current?.[key]?.blur();
        }, 50);
      });
    },
  });

  // init value for inputs
  useEffect(() => {
    const commentManageExecutes = commentManageExecute || [];

    const inputs =
      !commentManageExecute?.length || !Array.isArray(commentManageExecutes)
        ? commentManageExecuteDefault
        : (commentManageExecutes as any[]);

    setInputs((prev) =>
      JSON.stringify(prev) !== JSON.stringify(inputs) ? inputs : prev
    );
  }, [commentManageExecute, commentManageExecuteDefault]);

  // save data when unmount but not blur
  useEffect(() => {
    return () => {
      const isChangedValue = isChangedValueRef.current;
      const updateInputs = updateInputsRef.current;

      if (isChangedValue && !!updateInputs && !!onChangeCommentManageExecute) {
        updateInputsRef.current = undefined;
        isChangedValueRef.current = false;

        onChangeCommentManageExecute?.(updateInputs);
      }
    };
  }, [onChangeCommentManageExecute]);

  const handleChangeInputData = ({
    field,
    key,
    value,
  }: {
    field: keyof iInputProps;
    value: string;
    key: number;
  }) => {
    isChangedValueRef.current = true;
    const updateInputs = structuredClone(inputs);
    const minRow = (value.match(/\n/g)?.length || 0) + 1;
    updateInputs[key][field] = value as never;
    updateInputs[key].minRow = minRow;
    updateInputsRef.current = updateInputs;

    setInputs(updateInputs);
  };

  const handleBlurInputData = ({
    field,
    key,
    value,
  }: {
    field: keyof iInputProps;
    value: string;
    key: number;
  }) => {
    isChangedValueRef.current = false;
    updateInputsRef.current = undefined;
    const updateInputs = structuredClone(inputs);
    const minRow = (value.match(/\n/g)?.length || 0) + 1;
    updateInputs[key][field] = value as never;
    updateInputs[key].minRow = minRow;

    onChangeCommentManageExecute?.(updateInputs);
  };

  const handleOpenTextPosition = (key: number) => {
    setMapTextPositionOpen({ [key]: true });
    setTimeout(() => {
      inputRefs.current?.[key]?.focus();
    }, 50);
  };

  if (isOnlyViewBlackboard) {
    const styles = inputs.map((input) => {
      return {
        textAlign: input.textAlign || (TextPosition.START as any),
        padding: 0,
        width: "100%",
        flexShrink: 0,
      };
    });
    const datas = inputs.map((input, inputKey) => {
      const data: Partial<CellType> = {
        cellId: String(inputKey),
        cellLinkedData: {
          field: BlackboardKey.COMMENT_MANAGE_EXECUTE,
        },
      };
      const value = input.value;

      return {
        ...data,
        value,
      };
    });

    return (
      <NormalTextListPreview
        isAutoResize={isAutoResize}
        datas={datas}
        styles={styles}
      />
    );
  }

  const highlightBoxContainer = () => {
    if (boxContainerRef.current) {
      boxContainerRef.current.style.borderColor = "#3182ce";
      boxContainerRef.current.style.boxShadow = "0 0 0 1px #3182ce";
    }
  };

  const removeHighlightBoxContainer = () => {
    if (boxContainerRef.current) {
      boxContainerRef.current.style.borderColor = "#fff";
      boxContainerRef.current.style.boxShadow = "none";
    }
  };

  const handleFocus = () => {
    const length = inputs.length;

    inputRefs.current[length - 1]?.focus();
    highlightBoxContainer();
    setMapTextPositionOpen({ "0": true });
  };

  return (
    <Box
      flex={1}
      ref={boxContainerRef}
      display="flex"
      flexDir="column"
      padding="0.5rem"
      border="1px solid #fff"
      borderRadius="4px"
      onClick={handleFocus}
      cursor="text"
    >
      {inputs.map((input, key) => (
        <Textarea
          key={key}
          onClick={(e) => {
            e.stopPropagation();
            highlightBoxContainer();
            handleOpenTextPosition(key);
          }}
          style={{
            fontSize: cellStyle?.fontSize,
            minHeight:
              (input.minRow || 1) * ((cellStyle?.fontSize || 12) * 1.4),
            height: "fit-content",
            textAlign: input.textAlign as any,
            padding: 0,
          }}
          resize="none"
          border="none"
          rows={input.minRow || 1}
          _focus={{ border: "none" }}
          _focusVisible={{ boxShadow: "none" }}
          ref={(el) => (inputRefs.current[key] = el)}
          value={input.value}
          onBlur={(e) =>
            handleBlurInputData({
              field: "value",
              value: e.target.value,
              key,
            })
          }
          onChange={(e) =>
            handleChangeInputData({
              field: "value",
              value: e.target.value,
              key,
            })
          }
        />
      ))}
    </Box>
  );
};

export default memo(CommentManageExecuteTextInput);
