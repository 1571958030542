import {
  DocumentCategoryKey,
  DocumentCategoryStatusType,
} from "constants/enum";
import { DocumentCategoryDTO } from "interfaces/dtos/documentCategoryDTO";
import isEqual from "lodash/isEqual";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { getAreaExtension } from "utils/forge/extensions/area-extension";

interface iProps {
  documentCategoryModalInfo: DocumentCategoryDTO | undefined;
  onBlurForm: (key: DocumentCategoryKey, value: string) => void;
  onBlurCombineForm?: (data: Partial<DocumentCategoryDTO>) => void;
  handleUpdateFieldsChangeData: (keys: (keyof DocumentCategoryDTO)[]) => void;
}
const useOtherForm = ({
  documentCategoryModalInfo,
  onBlurForm,
  onBlurCombineForm,
  handleUpdateFieldsChangeData,
}: iProps) => {
  const { currentUser } = useSelector((state: RootState) => state.user);
  const [data, setData] = useState<Partial<DocumentCategoryDTO>>({});
  const prevDataRef = useRef<DocumentCategoryDTO>();

  useEffect(() => {
    if (documentCategoryModalInfo) {
      setData(documentCategoryModalInfo);
      prevDataRef.current = documentCategoryModalInfo;
    }
  }, [documentCategoryModalInfo]);

  useEffect(() => {
    if (!documentCategoryModalInfo || !prevDataRef.current) {
      return;
    }

    if (
      !isEqual(
        prevDataRef.current?.approveDate,
        documentCategoryModalInfo?.approveDate
      ) ||
      !isEqual(
        prevDataRef.current?.implementDate,
        documentCategoryModalInfo?.implementDate
      )
    ) {
      prevDataRef.current = documentCategoryModalInfo;
      setData(documentCategoryModalInfo);
    }

    if (prevDataRef.current?.title !== documentCategoryModalInfo?.title) {
      prevDataRef.current = documentCategoryModalInfo;
      setData(documentCategoryModalInfo);
    }
  }, [documentCategoryModalInfo]);

  const onChange = (key: string) => (e: any) => {
    let value = e;

    const isTargetValue = [
      DocumentCategoryKey.TITLE,
      DocumentCategoryKey.MEASURE_DATE,
      DocumentCategoryKey.SYSTEM_NAME,
      "nameUserCreate",
      DocumentCategoryKey.CORPORATE_COMPANY,
      DocumentCategoryKey.CONSTRUCTION_NAME,
      DocumentCategoryKey.MANUFACTOR,
      DocumentCategoryKey.DUCT_SYSTEM,
      DocumentCategoryKey.INSPECTION_DATE,
      DocumentCategoryKey.MEASURING_DEVICE,
      DocumentCategoryKey.PERSON_IN_CHARGE,
    ].includes(key);

    const isValue = [
      DocumentCategoryKey.USER_CREATED,
      DocumentCategoryKey.USER_MEASURE,
      DocumentCategoryKey.USER_MANAGE,
      DocumentCategoryKey.MANAGER,
      DocumentCategoryKey.USER_IMPLEMENT,
      DocumentCategoryKey.PARTNER_COMPANY,
    ].includes(key as DocumentCategoryKey);

    const isBlur = [
      DocumentCategoryKey.PRIORITY,
      DocumentCategoryKey.STATUS,
      DocumentCategoryKey.USER_CREATED,
      DocumentCategoryKey.USER_MEASURE,
      DocumentCategoryKey.USER_MANAGE,
      DocumentCategoryKey.MANAGER,
      DocumentCategoryKey.USER_IMPLEMENT,
      DocumentCategoryKey.PARTNER_COMPANY,
      DocumentCategoryKey.AREA_ID,
      DocumentCategoryKey.INSPECTION_RESULT,
    ].includes(key as DocumentCategoryKey);

    const isBlurCombine =
      key === DocumentCategoryKey.STATUS &&
      value == DocumentCategoryStatusType.Approved;

    const isChangeDateTime = [
      DocumentCategoryKey.APPROVE_DATE,
      DocumentCategoryKey.IMPLEMENT_DATE,
      DocumentCategoryKey.DEADLINE,
      DocumentCategoryKey.MEASURE_DATE,
      DocumentCategoryKey.INSPECTION_DATE,
      DocumentCategoryKey.START_DATE_SCHEDULED,
      DocumentCategoryKey.END_DATE_SCHEDULED,
    ].includes(key as DocumentCategoryKey);

    if (isChangeDateTime) {
      onBlurForm(key as any, value as any);
    }

    if (isTargetValue) {
      value = e?.target?.value;
    } else if (isValue) {
      value = e?.value || "";
    }

    if (isBlur && !isBlurCombine) {
      onBlurForm(key as any, value as any);
    }

    let _data = {
      ...data,
      updatedAt: new Date(),
      [key]: value,
    };

    const keysChangeData = [key];
    if (isBlurCombine && onBlurCombineForm) {
      keysChangeData.push(
        ...[
          DocumentCategoryKey.APPROVE_DATE,
          DocumentCategoryKey.IMPLEMENT_DATE,
          DocumentCategoryKey.STATUS,
          DocumentCategoryKey.USER_APPROVED,
        ]
      );
      const fieldsUpdate = {
        [DocumentCategoryKey.APPROVE_DATE]: new Date().toString(),
        [DocumentCategoryKey.IMPLEMENT_DATE]: new Date().toString(),
        [DocumentCategoryKey.STATUS]: value,
        [DocumentCategoryKey.USER_APPROVED]: _data?.userApproved?.includes(
          currentUser?.id || ""
        )
          ? _data?.userApproved
          : [...(_data?.userApproved || []), currentUser?.id || ""],
      };

      _data = {
        ..._data,
        ...fieldsUpdate,
      };

      onBlurCombineForm(fieldsUpdate);
    }

    if (key === DocumentCategoryKey.STATUS) {
      if (_data.neptuneAreaIds?.length) {
        getAreaExtension()?.select(_data as DocumentCategoryDTO);
      }
    }

    setData(_data);
    handleUpdateFieldsChangeData(
      keysChangeData as (keyof DocumentCategoryDTO)[]
    );
  };

  const onBlur = (key: keyof DocumentCategoryDTO) => () => {
    onBlurForm(key, data?.[key] as any);
  };

  return { data, onChange, onBlur };
};

export default useOtherForm;
