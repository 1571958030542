import { Flex, Text, useBoolean } from "@chakra-ui/react";
import { documentItemApi } from "apiClient/v2";
import { IconBase, message } from "components/base";
import { SvgIcon } from "components/SvgIcon";
import CustomMenu from "components/ui/CustomMenu";
import { TYPE_USER } from "constants/app";
import {
  DocumentItemDTO,
  DocumentSubItemDTO,
} from "interfaces/dtos/documentItemDTO";
import { DocumentItem } from "interfaces/models/documentItem";
import { User } from "interfaces/models/user";
import { memo, useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { updateDocumentItem } from "redux/documentSlice";
import { updateElementInArray } from "utils/array";
import { checkDocumentItemUpdatedData } from "utils/documentItem";
import ConfirmDeleteItemModal from "./ConfirmDeleteItemModal";

interface Props {
  isOnline: boolean;
  subItem: DocumentSubItemDTO;
  documentItemSelected?: DocumentItemDTO;
  currentUser?: User | null;
  isHasBlackboard: boolean;
  isDisabled?: boolean;
  itemDisplayOrder: number | string;
  onSelectSubItem: (subItem: DocumentSubItemDTO) => void;
}

const SubItem = (props: Props) => {
  const {
    isOnline,
    subItem,
    currentUser,
    documentItemSelected,
    isHasBlackboard,
    itemDisplayOrder,
    isDisabled,
    onSelectSubItem,
  } = props;

  const [isDeletingSubItem, setIsDeletingSubItem] = useBoolean();
  const [isOpenConfirmDeleteItem, setIsOpenConfirmDeleteItem] = useBoolean();

  const dispatch = useDispatch();

  const isShowSubItem = useMemo(() => !subItem?.isHidden, [subItem?.isHidden]);

  const canDeleteDocumentItem = useMemo(() => {
    const takasagoRoles = Object.values(TYPE_USER);

    return takasagoRoles?.includes(
      currentUser?.role as (typeof TYPE_USER)[keyof typeof TYPE_USER]
    );
  }, [currentUser?.role]);

  const handleChangeShowSubItem = useCallback(() => {
    if (!documentItemSelected?.id || (!isShowSubItem && isDisabled)) {
      return;
    }

    const documentItem = structuredClone(documentItemSelected);
    updateElementInArray({
      array: documentItem?.subItems || [],
      keyIndex: "id",
      element: {
        ...subItem,
        isHidden: isShowSubItem,
      } as DocumentSubItemDTO,
    });

    dispatch(updateDocumentItem(documentItem));
    documentItemApi.updateSubItem({
      id: subItem.id,
      itemId: subItem.itemId,
      isHidden: isShowSubItem,
    } as DocumentSubItemDTO);
  }, [isShowSubItem, documentItemSelected, subItem, dispatch, isDisabled]);

  const handleDeleteSubItem = useCallback(async () => {
    if (!documentItemSelected?.id) {
      return;
    }

    setIsDeletingSubItem.on();
    const documentItem = structuredClone(documentItemSelected);
    documentItem.subItems = (documentItem?.subItems || []).filter(
      (sub) => sub.id !== subItem.id
    );

    const isSoftDelete = checkDocumentItemUpdatedData(subItem as DocumentItem);
    const promises: Promise<any>[] = [
      documentItemApi.deleteSubItemList([subItem.id], isSoftDelete),
    ];

    const now = new Date();
    if (isSoftDelete) {
      promises.push(
        documentItemApi.updateItem({
          id: documentItem.id,
          overrideUpdatedAt: now,
        })
      );

      documentItem.updatedAt = now;
    }

    await Promise.all(promises);

    setIsDeletingSubItem.off();
    dispatch(updateDocumentItem(documentItem));
    handleCloseConfirmDeleteItemModal();
    message.success(
      [
        "写真の削除",
        `${noSubItem} ${
          !!subItem?.title ? subItem?.title : ""
        }を削除しました。`,
      ],
      {
        duration: 2000,
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subItem, documentItemSelected, dispatch]);

  const handleOpenConfirmDeleteItemModal = useCallback(() => {
    setIsOpenConfirmDeleteItem.on();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseConfirmDeleteItemModal = useCallback(() => {
    setIsOpenConfirmDeleteItem.off();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const noSubItem = useMemo(
    () =>
      `${itemDisplayOrder}-${
        (documentItemSelected?.subItems?.findIndex(
          (sub) => sub.id === subItem?.id
        ) || 0) + 1
      }`,
    [subItem.id, itemDisplayOrder, documentItemSelected?.subItems]
  );

  return (
    <Flex
      gap="0.8rem"
      height="5rem"
      alignItems="center"
      borderBottom="1px solid #D4D4D4"
      padding="0px .8rem"
      backgroundColor={isShowSubItem ? "transparent" : "#E0E0E0"}
    >
      <IconBase
        flexShrink={0}
        icon="/img/icon-camera-black.svg"
        width="2.4rem"
        height="2.4rem"
        cursor="pointer"
        onClick={() => {
          if (isDisabled && !isShowSubItem) return;
          onSelectSubItem(subItem);
        }}
        color={(subItem?.images as any)?.src ? "orange" : "#737373"}
        opacity={isShowSubItem ? "1" : "0.6"}
      />

      {isHasBlackboard && subItem?.isShowBlackboard !== false && (
        <SvgIcon
          flexShrink={0}
          src="/img/icon-black-board.svg"
          width="2.4rem"
          height="2.4rem"
          cursor="pointer"
          onClick={() => {
            if (isDisabled && !isShowSubItem) return;
            onSelectSubItem(subItem);
          }}
        />
      )}

      <Text
        flexShrink={0}
        fontSize="1.4rem"
        userSelect="none"
        cursor="pointer"
        color="#737373"
        opacity={isShowSubItem ? "1" : "0.6"}
        onClick={() => {
          if (isDisabled && !isShowSubItem) return;
          onSelectSubItem(subItem);
        }}
      >
        {noSubItem}
      </Text>
      <Text
        noOfLines={1}
        flex={1}
        fontSize="1.6rem"
        color="#737373"
        opacity={isShowSubItem ? "1" : "0.6"}
        textDecoration={!isShowSubItem ? "line-through" : undefined}
        fontWeight="500"
        cursor="pointer"
        onClick={() => {
          if (isDisabled && !isShowSubItem) return;
          onSelectSubItem(subItem);
        }}
      >
        {subItem?.title || "-"}
      </Text>

      {!isShowSubItem && (
        <IconBase
          icon="/img/eye-off.svg"
          cursor="pointer"
          width="2.4rem"
          height="2.4rem"
          color="#737373"
          opacity="0.6"
          onClick={handleChangeShowSubItem}
        />
      )}

      {isShowSubItem && canDeleteDocumentItem && (
        <CustomMenu
          options={[
            {
              title: (
                <Flex gap="6px" alignItems="center">
                  <IconBase
                    flexShrink={0}
                    icon="/img/eye.svg"
                    width="2.4rem"
                    height="2.4rem"
                    color="#737373"
                  />
                  <Text color="#737373" fontSize="1.2rem">
                    欠番 ON/OFF
                  </Text>
                </Flex>
              ) as any,
              onClick: handleChangeShowSubItem,
            },
            {
              title: (
                <Flex gap="6px" alignItems="center">
                  <IconBase
                    flexShrink={0}
                    icon="/img/icon-trash.svg"
                    width="2.2rem"
                    height="2.2rem"
                    color="font.danger"
                  />
                  <Text fontSize="1.2rem" color="font.danger">
                    削除
                  </Text>
                </Flex>
              ) as any,
              isDisabled: !isOnline,
              onClick: handleOpenConfirmDeleteItemModal,
            },
          ]}
          icon={(isOpen) => (
            <Flex
              alignItems="center"
              justifyContent="center"
              width="2.4rem"
              height="2.4rem"
            >
              <IconBase
                icon="/img/three-dots-outline.svg"
                width="2.4rem"
                height="2.4rem"
                flexShrink="0"
                color={isOpen ? "var(--primary-color)" : "#A3A3A3"}
              />
            </Flex>
          )}
        />
      )}

      {isOpenConfirmDeleteItem && (
        <ConfirmDeleteItemModal
          size="6xl"
          content={
            <Flex
              gap="0.8rem"
              height="5rem"
              alignItems="center"
              padding="0px .8rem"
            >
              <IconBase
                flexShrink={0}
                icon="/img/icon-camera-black.svg"
                width="2.4rem"
                height="2.4rem"
                color={(subItem?.images as any)?.src ? "orange" : "#737373"}
              />
              <Text
                flexShrink={0}
                fontSize="1.4rem"
                userSelect="none"
                cursor="pointer"
                color="#737373"
                opacity={isShowSubItem ? "1" : "0.6"}
                onClick={() => {
                  onSelectSubItem(subItem);
                }}
              >
                {noSubItem}
              </Text>
              <Text
                noOfLines={1}
                flex={1}
                fontSize="1.6rem"
                color="#737373"
                fontWeight="500"
              >
                {subItem?.title || "-"}
              </Text>
            </Flex>
          }
          title="以下の写真を削除します。よろしいですか？"
          buttonDelete="削除する"
          buttonCancel="キャンセル"
          isOpen={isOpenConfirmDeleteItem}
          isLoading={isDeletingSubItem}
          onClose={handleCloseConfirmDeleteItemModal}
          onProcessing={handleDeleteSubItem}
        />
      )}
    </Flex>
  );
};

export default memo(SubItem);
