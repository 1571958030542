import { ComponentStyleConfig } from "@chakra-ui/react";

export const selectStyle: ComponentStyleConfig = {
  variants: {
    outline: {
      field: {
        bg: "transparent",
        border: "1px solid #E2E2E3",
        borderRadius: "0",
        fontSize: "1.4rem",
        color: "#000000CC",
        pl: "3rem",
        _focus: {
          boxShadow: "none",
        },
        h: "4rem",
      },
    },
    borderBottom: {
      field: {
        bg: "transparent",
        borderBottom: "1px solid #E2E2E3",
        borderRadius: 0,
        fontSize: "1.4rem",
        color: "#000000CC",
        pl: "1rem",
        _focus: {
          boxShadow: "none",
        },
        h: "2.7rem",
      },
    },
    noBorder: {
      field: {
        bg: "transparent",
        border: "none",
        fontSize: "1.8rem",
        fontWeight: "bold",
        color: "#000000",
        _focus: {
          boxShadow: "none",
        },
        h: "2.6rem",
      },
    },
    borderBottomW: {
      field: {
        bg: "transparent",
        borderBottom: "1px solid #fff",
        borderRadius: 0,
        fontSize: "1.4rem",
        color: "#fff",
        pl: "1rem",
        _focus: {
          boxShadow: "none",
        },
        h: "2.7rem",
      },
    },
  },
};
