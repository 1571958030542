import { Box, Flex } from "@chakra-ui/react";
import ComponentPreview from "components/editor-builder/component-preview";
import { GRID_TEMPLATE_SIZE } from "constants/document";
import {
  PaperDirectionType,
  PaperSize,
  PaperType,
  TemplateComponentType,
} from "constants/enum";
import { PageFormat, TemplateComponent } from "interfaces/models/component";
import { TaskSheetTemplate } from "interfaces/models/taskSheetTemplate";
import { memo, useCallback, useMemo } from "react";
import { centimetersToPixels } from "utils/measure";

interface iProps {
  template: TaskSheetTemplate;
}

const COMPONENT_SIZE_A4 = {
  width: 100,
  height: 120,
};

const COMPONENT_SIZE_PAGE_A3 = {
  width: COMPONENT_SIZE_A4.width * 1.5,
  height: COMPONENT_SIZE_A4.height * 1.7,
};

const CUSTOM_PAGE_A3_VERTICAL_WIDTH = 141.41;
const CUSTOM_PAGE_A3_HORIZONTAL_WIDTH = 200;

const CUSTOM_PAGE_A4_VERTICAL_WIDTH = 93.41;
const CUSTOM_PAGE_A4_HORIZONTAL_WIDTH = 117.41;

const TaskSheetTemplateItem = ({ template }: iProps) => {
  const components = useMemo(
    (): TemplateComponent[] => template?.components || [],
    [template]
  );
  const pages = useMemo(() => template.pages, [template]);
  const getComponentsOfPage = useCallback(
    (pageIndex: number) => components.filter((c) => c.page === pageIndex),
    [components]
  );

  const getPageSizeOfPage = useCallback((page: PageFormat) => {
    let width = centimetersToPixels(PaperSize[page.pageSize]?.width);
    let height = centimetersToPixels(PaperSize[page.pageSize]?.height);

    if (page?.pageDirection === PaperDirectionType.HORIZONTAL) {
      [width, height] = [height, width];
    }

    return {
      width,
      height,
    };
  }, []);

  return (
    <Flex gap="1.6rem">
      {pages.map((page, pageIndex) => {
        if (pageIndex === 5) {
          return "---";
        }

        if (pageIndex > 5) {
          return null;
        }

        const pageSize = getPageSizeOfPage(page);
        const components = getComponentsOfPage(pageIndex);

        const isHasOnlyImage =
          components.length === 1 &&
          !!components.find((c) =>
            [
              TemplateComponentType.Keyplan,
              TemplateComponentType.PhotoBooksReport,
              TemplateComponentType.PhotoBooksInstruction,
              TemplateComponentType.TasksImage,
            ].includes(c.type)
          )?.componentId;

        const pageWidth =
          page?.pageSize === PaperType.A3
            ? page?.pageDirection === PaperDirectionType.VERTICAL
              ? CUSTOM_PAGE_A3_VERTICAL_WIDTH
              : CUSTOM_PAGE_A3_HORIZONTAL_WIDTH
            : page?.pageDirection === PaperDirectionType.VERTICAL
            ? CUSTOM_PAGE_A4_VERTICAL_WIDTH
            : CUSTOM_PAGE_A4_HORIZONTAL_WIDTH;

        const pageHeight =
          page?.pageSize === PaperType.A3
            ? page.pageDirection === PaperDirectionType.VERTICAL
              ? CUSTOM_PAGE_A3_HORIZONTAL_WIDTH
              : CUSTOM_PAGE_A3_VERTICAL_WIDTH
            : page.pageDirection === PaperDirectionType.VERTICAL
            ? CUSTOM_PAGE_A4_HORIZONTAL_WIDTH
            : CUSTOM_PAGE_A4_VERTICAL_WIDTH;

        let width = COMPONENT_SIZE_A4.width;
        let height = COMPONENT_SIZE_A4.height;

        if (page.pageSize === PaperType.A3) {
          width = COMPONENT_SIZE_PAGE_A3.width;
          height = COMPONENT_SIZE_PAGE_A3.height;
        }

        let zoomRatio = width / pageSize.width;
        let zoomRatioHeight = height / pageSize.height;

        if (page.pageDirection === PaperDirectionType.HORIZONTAL) {
          [width, height] = [height, width];
          zoomRatio = width / pageSize.width;
          zoomRatioHeight = height / pageSize.height;
        }

        return (
          <Box
            key={pageIndex}
            height={`${pageHeight}px`}
            width={`${pageWidth}px`}
            position="relative"
            bg="#fff"
            boxShadow="0px 0px 3px #0000003D"
            sx={
              isHasOnlyImage
                ? {
                    "& > div": {
                      width: `${pageWidth}px !important`,
                      height: `${pageHeight}px !important`,
                    },
                  }
                : {}
            }
          >
            {components.map((component, componentIndex) => {
              return (
                <ComponentPreview
                  key={componentIndex}
                  isTaskSheetTemplate={true}
                  isTaskSheetTemplateItem={true}
                  isShowDummyOfTaskSheetTemplate={true}
                  isOnlyView={true}
                  page={page.pageIndex!}
                  pages={pages}
                  cellSize={{
                    width: GRID_TEMPLATE_SIZE,
                    height: GRID_TEMPLATE_SIZE,
                  }}
                  sizePageRatio={page.sizePageRatio}
                  pageDirectionRatio={page.pageDirectionRatio}
                  component={component}
                  zoomRatio={zoomRatio}
                  zoomRatioHeight={zoomRatioHeight}
                />
              );
            })}
          </Box>
        );
      })}
    </Flex>
  );
};

export default memo(TaskSheetTemplateItem);
