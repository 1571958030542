import {
  Box,
  BoxProps,
  Button,
  Flex,
  HTMLChakraProps,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Text,
  TooltipProps,
} from "@chakra-ui/react";
import { PCTooltip } from "components/PCTooltip";
import { SvgIcon } from "components/SvgIcon";
import useTextHover from "hooks/useTextHover";
import { memo, useEffect, useMemo, useRef, useState } from "react";

interface iOption {
  name: string;
  value: string;
  iconSrc?: string;
  command?: string;
  isDisabled?: boolean;
}

interface Props extends HTMLChakraProps<any> {
  groupOptions?: {
    title: string;
    options: iOption[];
  }[];
  options?: iOption[];
  showAddItem?: boolean;
  placeHolder?: string;
  value?: string;
  defaultValueIsEmpty?: boolean;
  onChange?: (value: any, preValue?: string) => void;
  defaultValue?: string;
  showIcon?: boolean;
  isDisabled?: boolean;
  boxMenuListContainerProps?: BoxProps;
  zIndexOfMenu?: number;
  isShowTooltip?: boolean;
  tooltipProps?: TooltipProps;
  fontSize?: string;
  clearable?: boolean;
  icon?: any;
  iconProps?: any;
}

interface iRenderMenuItem extends Partial<Props> {
  isSelected: boolean;
  option: iOption;
  handleChange: (value: string) => void;
}

const RenderMenuItem = ({
  isSelected,
  option,
  showIcon,
  isShowTooltip,
  tooltipProps,
  fontSize,
  handleChange,
  ...rest
}: iRenderMenuItem) => {
  const { name, value, iconSrc, command, isDisabled } = option;

  const { hoverStatus, textElementRef } = useTextHover();

  return (
    <MenuItem
      icon={
        showIcon && iconSrc ? (
          <SvgIcon src={String(iconSrc)} h="1.8rem" w="1.8rem" />
        ) : undefined
      }
      command={command}
      onClick={() => !isDisabled && handleChange(value)}
      borderBottom="1px solid var(--primary-border-color)"
      _last={{
        borderBottom: "none",
      }}
      _hover={{
        bgColor: "#F0F9FF",
      }}
      pl="2rem"
      fontSize={{ base: "1rem", "2xl": "1.4rem", xl: "1rem" }}
      height={{ base: "3rem", "2xl": "4rem", xl: "3rem" }}
      cursor={isDisabled ? "not-allowed" : "auto"}
      {...rest}
    >
      <PCTooltip
        label={name}
        isDisabled={isShowTooltip ? false : !hoverStatus}
        {...tooltipProps}
      >
        <Flex alignItems="center" gap="0.5rem" width="100%">
          <Box flexShrink={0} width="1.6rem" height="1.6rem">
            {isSelected && (
              <SvgIcon
                src="/img/icon-action-check_circle.svg"
                width="1.6rem"
                height="1.6rem"
              />
            )}
          </Box>
          <Text
            ref={textElementRef}
            className="text-ellipsis"
            color={isDisabled ? "var(--chakra-colors-gray-300)" : "#171717"}
            fontFamily="NotoSansJP"
            fontSize={fontSize}
          >
            {name}
          </Text>
        </Flex>
      </PCTooltip>
    </MenuItem>
  );
};

const Select = (props: Props) => {
  const {
    groupOptions = [],
    options = [],
    showAddItem,
    placeHolder = "選択します",
    onChange,
    onAddNewItem,
    value,
    defaultValueIsEmpty = false,
    defaultValue,
    showIcon,
    isDisabled,
    boxMenuListContainerProps,
    zIndexOfMenu,
    isShowTooltip,
    tooltipProps,
    fontSize,
    clearable,
    icon,
    iconProps = {},
    ...rest
  } = props;
  const dropdownRef = useRef<HTMLButtonElement>(null);
  const [selectedValue, setSelectedValue] = useState(defaultValue);

  const handleChange = (value: string) => {
    setSelectedValue(value);
    onChange?.(value);
  };

  useEffect(() => {
    setSelectedValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  const displayValue = useMemo(() => {
    const opts = [
      ...groupOptions?.map((group) => group.options)?.flat(1),
      ...options,
    ];

    const item = opts?.find((item) => item?.value === (value || selectedValue));
    if (item && (!!value || defaultValueIsEmpty)) {
      return (
        <Flex gap="5px" alignItems="center">
          {showIcon && (
            <SvgIcon src={String(item?.iconSrc)} h="1.8rem" w="1.8rem" />
          )}
          <Text>{item?.name}</Text>
        </Flex>
      );
    }

    return <Text color="var(--chakra-colors-gray-400)">{placeHolder}</Text>;
  }, [
    groupOptions,
    options,
    placeHolder,
    selectedValue,
    showIcon,
    value,
    defaultValueIsEmpty,
  ]);

  return (
    <Menu autoSelect={false}>
      {({ isOpen }) => (
        <>
          <MenuButton
            isDisabled={isDisabled}
            ref={dropdownRef}
            variant="whiteIcon"
            as={Button}
            textAlign="left"
            pl="2rem"
            lineHeight="4rem"
            rightIcon={
              clearable && selectedValue ? (
                <SvgIcon
                  src="/img/icon-delete-gray.svg"
                  transition="0.4s"
                  h="1.6rem"
                  w="1.6rem"
                  onClick={() => onChange?.(null)}
                  {...iconProps}
                />
              ) : icon ? (
                icon
              ) : (
                <SvgIcon
                  src={"/img/icon-navigation-arrow_drop_down.svg"}
                  transition="0.4s"
                  transform={isOpen ? "rotate(180deg)" : "rotate(0deg)"}
                  h="1.6rem"
                  w="1.6rem"
                  {...iconProps}
                />
              )
            }
            boxShadow="unset"
            css={`
              .chakra-text {
                text-overflow: ellipsis;
                overflow: hidden !important;
                display: inline;
              }
            `}
            border="1px solid var(--primary-border-color)"
            _expanded={{ opacity: 1 }}
            _focus={{ boxShadow: "none" }}
            fontSize={{ base: "1rem", "2xl": "1.4rem", xl: "1rem" }}
            {...rest}
          >
            {displayValue}
          </MenuButton>

          <MenuList
            position="relative"
            minW={Number(dropdownRef.current?.clientWidth)}
            maxW={dropdownRef.current?.clientWidth}
            {...(!!options?.length
              ? {}
              : {
                  p: 0,
                  borderWidth: 0,
                })}
            maxH="27rem"
            overflowY="auto"
            border="1px solid var(--primary-border-color)"
            borderRadius="6px"
            sx={{
              "& > div > .chakra-menu__group:not(:last-child) > .chakra-menu__menuitem":
                {
                  borderBottom: "1px solid var(--primary-border-color)",
                },
            }}
            zIndex={zIndexOfMenu}
          >
            <Box className="box-scroll-bar" {...boxMenuListContainerProps}>
              {options?.length ? (
                options?.map((option, key) => (
                  <RenderMenuItem
                    key={key}
                    option={option}
                    showIcon={showIcon}
                    isSelected={value === option?.value}
                    handleChange={handleChange}
                    isShowTooltip={isShowTooltip}
                    tooltipProps={tooltipProps}
                    fontSize={fontSize}
                  />
                ))
              ) : !groupOptions?.length ? (
                <MenuItem
                  display="flex"
                  justifyContent="center"
                  color="#ccc"
                  height="4.2rem"
                >
                  <Text display="flex" justifyContent="center">
                    オプションなし
                  </Text>
                </MenuItem>
              ) : (
                <></>
              )}

              {groupOptions?.map(
                ({ title, options }, index) =>
                  !!options?.length && (
                    <MenuGroup
                      key={index}
                      title={title}
                      fontSize={{ base: "1rem", "2xl": "1.4rem", xl: "1rem" }}
                    >
                      {options?.map((option, key) => (
                        <RenderMenuItem
                          key={key}
                          option={option}
                          showIcon={showIcon}
                          isSelected={value === option?.value}
                          handleChange={handleChange}
                          isShowTooltip={isShowTooltip}
                          tooltipProps={tooltipProps}
                        />
                      ))}
                    </MenuGroup>
                  )
              )}
            </Box>
          </MenuList>
        </>
      )}
    </Menu>
  );
};

export default memo(Select);
