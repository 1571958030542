import { documentItemApi, taskApi } from "apiClient/v2";
import { STATUS_UPDATE_FORGE_VERSION } from "constants/enum";
import { DISPLAY_MODE } from "constants/forge";
import { Level, Vector3 } from "interfaces/models";
import { DataProjectModel } from "interfaces/models/dataProjectModel";
import { DocumentItem } from "interfaces/models/documentItem";
import { Task } from "interfaces/models/task";
import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import {
  setDisplayMode,
  setLevelSelected,
  setStatusUpdateForgeVersion,
} from "redux/forgeViewerSlice";
import { createUpdateProjectApi } from "redux/projectSlice";
import { useAppDispatch } from "redux/store";
import {
  checkIsDiffDataProjectVersion,
  getDbIdByExternalId,
} from "utils/forge";
import { find3dPosition, ___viewer3d } from "utils/forge/forge3d";
import { logDev } from "utils/logs";
import { getLocalStorage, setLocalStorage } from "utils/storage";
import { iForgeVersion } from "./useGetVersionsOfByForge";

interface iProps {
  bimFileId?: string;
  projectBimFileId?: string;
  versions: iForgeVersion[] | null;
  dataProjectDetail: DataProjectModel;
  isLoadedViewer: boolean;
  isLoadedLevels: boolean;
  isLoadedExternalId: boolean;
  isGeneratingFamilyInstances: boolean;
  levels: Level[];
}

const useCheckUpdateForgeVersion = ({
  bimFileId,
  projectBimFileId,
  versions,
  dataProjectDetail,
  levels,
  isLoadedViewer,
  isLoadedLevels,
  isLoadedExternalId,
  isGeneratingFamilyInstances,
}: iProps) => {
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();

  const isGeneratingFamilyInstancesRef = useRef(isGeneratingFamilyInstances);
  useEffect(() => {
    isGeneratingFamilyInstancesRef.current = isGeneratingFamilyInstances;
  }, [isGeneratingFamilyInstances]);

  useEffect(() => {
    if (dataProjectDetail) {
      const urn = dataProjectDetail?.defaultBimPathId?.split("/").pop();
      const isDiffVersion = checkIsDiffDataProjectVersion(dataProjectDetail);

      if (!urn || !isDiffVersion || !___viewer3d) {
        dispatch(
          setStatusUpdateForgeVersion(STATUS_UPDATE_FORGE_VERSION.CHECKED)
        );

        return;
      }
    }

    if (
      !bimFileId ||
      !projectBimFileId ||
      !dataProjectDetail?.id ||
      !versions ||
      !isLoadedViewer ||
      !isLoadedLevels ||
      !isLoadedExternalId
    ) {
      return;
    }

    if (versions.length === 1 || !versions.length) {
      dispatch(
        setStatusUpdateForgeVersion(STATUS_UPDATE_FORGE_VERSION.CHECKED)
      );

      return;
    }

    (async () => {
      const tasksBody: Task[] = [];
      const documentItemsBody: DocumentItem[] = [];

      const [tasks, documentItems] = await Promise.all([
        taskApi.handleGetTasks({ bimFileId }),
        documentItemApi.handleGetItemList({ bimFileId }),
      ]);
      const checkIsDiffPosition = (externalId?: string, position?: Vector3) => {
        if (!externalId || !position) {
          return { isDiffPosition: false };
        }
        const dbId = getDbIdByExternalId(externalId);
        const currentPosition = find3dPosition(dbId);
        const isDiffPosition =
          (position?.x !== currentPosition?.x ||
            position?.y !== currentPosition?.y ||
            position?.z !== currentPosition?.z) &&
          currentPosition &&
          !isNaN(dbId);

        return { isDiffPosition, newPosition: currentPosition };
      };
      tasks.forEach((task) => {
        const { isDiffPosition, newPosition } = checkIsDiffPosition(
          task?.externalId,
          task?.position
        );
        if (isDiffPosition && newPosition) {
          tasksBody.push({
            ...task,
            position: newPosition,
          });
        }
      });

      documentItems.forEach((documentItem) => {
        const { isDiffPosition, newPosition } = checkIsDiffPosition(
          documentItem?.externalId,
          documentItem?.position
        );
        if (isDiffPosition && newPosition) {
          documentItemsBody.push({
            ...documentItem,
            position: newPosition,
          });
        }
      });
      const request = [];
      request.push(
        appDispatch(
          createUpdateProjectApi({
            ...dataProjectDetail,
            prevDefaultBimPathId: dataProjectDetail?.defaultBimPathId,
          })
        )
      );
      if (tasksBody?.length) {
        tasksBody.map((e) => {
          request.push(taskApi.updateTask(e));

          return e;
        });
      }

      if (documentItemsBody?.length) {
        documentItemsBody.map((e) => {
          return request.push(documentItemApi.updateItem(e));
        });
      }

      logDev({
        tasksBody,
        documentItemsBody,
      });
      if (documentItemsBody?.length || tasksBody?.length) {
        dispatch(
          setStatusUpdateForgeVersion(STATUS_UPDATE_FORGE_VERSION.SHOULD_UPDATE)
        );
      }

      await Promise.all(request);
      logDev("update version forge");
      dispatch(
        setStatusUpdateForgeVersion(STATUS_UPDATE_FORGE_VERSION.CHECKED)
      );

      if (!isGeneratingFamilyInstancesRef.current) {
        const defaultLevel = levels.filter((level) => level.guid)?.[0];
        dispatch(
          setDisplayMode(
            !!defaultLevel.guid && defaultLevel.sheets?.length
              ? DISPLAY_MODE["2D"]
              : DISPLAY_MODE["3D"]
          )
        );
        dispatch(setLevelSelected(defaultLevel));
        const currentLevel = getLocalStorage("currentLevel") || {};
        currentLevel[String(projectBimFileId)] = defaultLevel.guid;
        setLocalStorage("currentLevel", currentLevel);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isLoadedExternalId,
    isLoadedLevels,
    isLoadedViewer,
    dataProjectDetail?.id,
    dataProjectDetail?.defaultBimPathId,
    dataProjectDetail?.prevDefaultBimPathId,
    bimFileId,
    projectBimFileId,
    versions,
  ]);

  return {};
};

export default useCheckUpdateForgeVersion;
