import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Input,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
} from "@chakra-ui/react";
import SubTableComponentPreview from "components/editor-builder/component-preview/TableComponentPreview/SubTableComponentPreview";
import { iUseGetContentOfCell } from "components/editor-builder/component-preview/TableComponentPreview/TableTdComponentPreview";
import CommentManageExecuteTextInput from "components/editor-builder/component-preview/TableComponentPreview/ui/CommentManageExecuteTextInput";
import {
  getFormatContentTypeDate,
  renderNormalText,
} from "components/editor-builder/component-preview/TableComponentPreview/utils";
import TextContainer from "components/modal/PreviewDocumentCategory/PreviewComponent/Table/TextContainer";
import DayPicker from "components/ui/DayPicker";
import {
  BlackboardTemplateBackground,
  BlackboardTemplateBackgroundType,
  DEFAULT_BLACKBOARD_TEMPLATE_FONT_SIZE_SCALE,
  MapBlackboardKeyWithDataType,
} from "constants/blackBoardTemplate";
import { DOWNLOAD_BLACKBOARD_CLASS } from "constants/download";
import {
  BlackboardKey,
  CellProperty,
  LinkedDataField,
  TableDefaultStyle,
} from "constants/enum";
import useUserOfProject from "hooks/useUserOfProject";
import { DocumentSubItemDTO } from "interfaces/dtos/documentItemDTO";
import { FileUploadInfo, Level } from "interfaces/models";
import { Blackboard } from "interfaces/models/blackboard";
import { CellType } from "interfaces/models/component";
import isEqual from "lodash/isEqual";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { formatDate, formatStringDate } from "utils/date";
import { getDateModifiedDateFile } from "utils/file";

interface iRenderContentTypeOnlyView {
  cell: CellType;
  data: Blackboard;
  documentItem: DocumentSubItemDTO;
}

interface iRenderContentTypeSelect {
  cell: CellType;
  value: any;
  key: any;
  options: { label: string; value: string }[];
  zoomRatio: number;
  textColorOfBlackboard: string;
  background: string;
  fontSize: string | undefined;
  isDisabled?: boolean;

  handleChangeSelect: (value: string, type: BlackboardKey) => void;
}

interface iRenderContentTypeDate {
  cell: CellType;
  value: any;
  zoomRatio: number;
  textColorOfBlackboard: string;
  fontSize: string | undefined;
  isDisabled?: boolean;

  onSelectDate: (day: Date | string, field?: string) => void;
  onClose: (date?: Date | string) => void;
}

interface iRenderContentTypeInput {
  value: string;
  placeholder: string;
  fontSize: string | undefined;
  isDisabled?: boolean;

  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const useGetContentTypeBlackboard = (
  props: iUseGetContentOfCell & { isDisabled?: boolean }
) => {
  const {
    component,
    currentTemplate,
    zoomRatio,
    isBlackboardTemplateImage,
    displaySize,
    width,
    height,
    limit,
    isLastColumn,
    isDuplicateRow,
    sizePageRatio,
    pageDirectionRatio,
    cellSize,
    isOnlyView,
    components,
    selectedCells,
    documentContainerSize,
    componentSelected,
    isComponentDragging,
    contentType,
    blackboardData,
    blackboardTemplateProps,
    fontSize,
    onChangeBlackboardData,
    isDisabled,
  } = props;
  const [blackboardLocalData, setBlackboardLocalData] =
    useState<Partial<Blackboard>>();

  const {
    levelSelected = {} as any,
    familyInstance = {} as any,
    listOptionUsers = [],
    listOptionFloor = [],
    listPartnerCompanies = [],
    blackboardTemplate = {} as any,
    projectName = "",
  } = blackboardTemplateProps || {};

  const levelSelectedRef = useRef<Level>();

  useEffect(() => {
    levelSelectedRef.current = levelSelected;
  }, [levelSelected]);

  useEffect(() => {
    if (
      !blackboardData?.id &&
      !!Object.keys(blackboardData || {})?.length &&
      !isEqual(blackboardData, blackboardLocalData)
    ) {
      setBlackboardLocalData(blackboardData);

      return;
    }

    if (
      !blackboardData?.id ||
      (formatStringDate(blackboardLocalData?.updatedAt as any) ===
        formatStringDate(blackboardData?.updatedAt as any) &&
        blackboardData?.id === blackboardLocalData?.id)
    ) {
      return;
    }

    setBlackboardLocalData(blackboardData);
  }, [blackboardData, blackboardLocalData]);

  const isOnlyViewBlackboard =
    (blackboardTemplateProps?.isOnlyView || isDisabled) ?? false;
  const textColorOfBlackboard = useMemo(() => {
    return blackboardTemplate?.backgroundType ===
      BlackboardTemplateBackgroundType.GREEN
      ? "#fff"
      : "#171717";
  }, [blackboardTemplate]);

  const handleChangeDate = useCallback(
    (value: string | Date, key: BlackboardKey) => {
      setBlackboardLocalData((prev) => ({ ...prev, [key]: value }));
    },
    []
  );

  const handleChangeCommentManageExecute = useCallback(
    (value: any[]) => {
      const data = { [BlackboardKey.COMMENT_MANAGE_EXECUTE]: value };

      setBlackboardLocalData((prev) => ({
        ...prev,
        ...data,
      }));
      onChangeBlackboardData?.({
        id: blackboardLocalData?.id,
        ...data,
      } as Blackboard);
    },
    [blackboardLocalData?.id, onChangeBlackboardData]
  );

  const handleBlurInput = useCallback(
    (value: string, key: BlackboardKey) => {
      if (blackboardLocalData?.id) {
        onChangeBlackboardData?.({
          id: blackboardLocalData?.id,
          [key]: value,
        } as Blackboard);
      }
    },
    [onChangeBlackboardData, blackboardLocalData?.id]
  );

  const handleChangeInput = useCallback((value: string, key: BlackboardKey) => {
    setBlackboardLocalData((prev) => ({ ...prev, [key]: value }));
  }, []);

  const handleChangeSelect = useCallback(
    (value: any, key: BlackboardKey) => {
      const dataType = MapBlackboardKeyWithDataType?.[key];

      if (dataType?.type !== "select" || !dataType?.selectOption) {
        return;
      }

      let options: { label: string; value: string }[] = [];
      switch (dataType?.selectOption?.options) {
        case "list-user":
          options = listOptionUsers;
          break;
        case "floor":
          options = listOptionFloor;
          break;
        case "partner-companies":
          options = listPartnerCompanies;
          break;
        default:
          options = dataType.selectOption.options;
          break;
      }
      const option = options?.find((opt) => opt.value === value);

      if (!option?.value) {
        return;
      }

      const data = {
        id: blackboardLocalData?.id,
        [dataType?.selectOption?.fieldId]: option?.value,
        [dataType?.selectOption?.fieldText]: option?.label,
      };

      setBlackboardLocalData({ ...blackboardLocalData, ...data });
      if (onChangeBlackboardData) {
        onChangeBlackboardData(data as Blackboard);
      }
    },
    [
      blackboardLocalData,
      onChangeBlackboardData,
      listOptionUsers,
      listOptionFloor,
      listPartnerCompanies,
    ]
  );

  const renderSubtable = useCallback(
    (cell: CellType) => {
      return (
        <SubTableComponentPreview
          component={component}
          parentCell={cell}
          displaySize={{
            width:
              (width / component.size.width) * displaySize.width -
                TableDefaultStyle.DEFAULT_BORDER_SIZE || 0,
            height:
              (height / component.size.height) * displaySize.height -
                TableDefaultStyle.DEFAULT_BORDER_SIZE || 0,
          }}
          zoomRatio={zoomRatio}
          cellSize={cellSize}
          sizePageRatio={sizePageRatio}
          pageDirectionRatio={pageDirectionRatio}
          isOnlyView={isOnlyView}
          isDuplicateRow={isDuplicateRow}
          isParentLastColumn={isLastColumn}
          limit={limit}
          isBlackboardTemplateImage={isBlackboardTemplateImage}
          components={components}
          selectedCells={selectedCells}
          documentContainerSize={documentContainerSize}
          currentTemplate={currentTemplate}
          componentSelected={componentSelected}
          isComponentDragging={isComponentDragging}
          contentType={contentType}
          blackboardData={blackboardData}
          blackboardTemplateProps={blackboardTemplateProps}
          onChangeBlackboardData={onChangeBlackboardData}
        />
      );
    },
    [
      limit,
      isBlackboardTemplateImage,
      components,
      selectedCells,
      documentContainerSize,
      currentTemplate,
      componentSelected,
      isComponentDragging,
      contentType,
      blackboardData,
      blackboardTemplateProps,
      onChangeBlackboardData,
      component,
      width,
      height,
      displaySize,
      zoomRatio,
      cellSize,
      sizePageRatio,
      pageDirectionRatio,
      isOnlyView,
      isDuplicateRow,
      isLastColumn,
    ]
  );

  const { listUserById } = useUserOfProject();

  const renderContentTypeOnlyView = useCallback(
    ({ cell, data, documentItem }: iRenderContentTypeOnlyView) => {
      const field = cell?.cellLinkedData?.field;

      if (field === LinkedDataField.BLACKBOARD.SHOOTING_TIME) {
        return (
          <Box
            className={`${DOWNLOAD_BLACKBOARD_CLASS}-time`}
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <TextContainer
              data-field-name={cell?.cellLinkedData?.field}
              autoReduceSize={
                component?.detail?.allowDynamicAdjustFontSize || false
              }
              alignText={cell.style?.justifyContent}
              style={{
                paddingLeft: `${0.5 * zoomRatio}rem`,
                paddingRight: `${0.5 * zoomRatio}rem`,
                minWidth: "fit-content",
                width: "fit-content",
                height: "fit-content",
              }}
            >
              {documentItem?.images?.src
                ? data?.shootingTime
                  ? getFormatContentTypeDate(data?.shootingTime)
                  : "-"
                : "-"}
            </TextContainer>
          </Box>
        );
      }
      if (field === LinkedDataField.BLACKBOARD.IMAGE_UPLOAD_INFO) {
        const documentSubItem = blackboardTemplateProps?.documentSubItem;
        const imageInfo: FileUploadInfo = documentSubItem?.images || {
          src: "",
        };
        const user = imageInfo.userUpload
          ? listUserById?.[imageInfo.userUpload]?.name || "-"
          : "-";

        return (
          <Box
            className={`${DOWNLOAD_BLACKBOARD_CLASS}-time`}
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <TextContainer
              data-field-name={cell?.cellLinkedData?.field}
              autoReduceSize={
                component?.detail?.allowDynamicAdjustFontSize || false
              }
              alignText={cell.style?.justifyContent}
              style={{
                paddingLeft: `${0.5 * zoomRatio}rem`,
                paddingRight: `${0.5 * zoomRatio}rem`,
                minWidth: "fit-content",
                width: "fit-content",
                height: "fit-content",
              }}
            >
              {`${user} \n ${formatDate(imageInfo.uploadTime).replaceAll(
                " ",
                "\n"
              )}` || "-"}
            </TextContainer>
          </Box>
        );
      }

      const key = cell?.cellLinkedData?.field!;
      let value = data?.[key as keyof Blackboard] || "";
      const dataType = MapBlackboardKeyWithDataType[key];

      if (dataType?.type === "select" && !value) {
        switch (dataType?.selectOption?.options) {
          case "list-user":
            value = listOptionUsers?.[0]?.label;
            break;
          case "floor":
            value =
              levelSelectedRef.current?.label || listOptionFloor?.[0]?.label;
            break;
          case "partner-companies":
            value = listPartnerCompanies?.[0]?.label;
            break;

          default:
            value = dataType?.selectOption?.options?.[0]?.label as any;
            break;
        }
      }

      if (key === LinkedDataField.BLACKBOARD.USER_EXECUTE_TEXT) {
        value = data?.[key as keyof Blackboard] || "";
      }

      return (
        <Box
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <TextContainer
            data-field-name={cell?.cellLinkedData?.field}
            autoReduceSize={
              component?.detail?.allowDynamicAdjustFontSize || false
            }
            alignText={cell.style?.justifyContent}
            style={{
              paddingLeft: `${0.5 * zoomRatio}rem`,
              paddingRight: `${0.5 * zoomRatio}rem`,
              minWidth: "fit-content",
              width: "fit-content",
              height: "fit-content",
            }}
          >
            {String(value)?.replace(/ /g, "\u00A0")}
          </TextContainer>
        </Box>
      );
    },
    [
      component?.detail?.allowDynamicAdjustFontSize,
      zoomRatio,
      blackboardTemplateProps?.documentSubItem,
      listUserById,
      listOptionUsers,
      listOptionFloor,
      listPartnerCompanies,
    ]
  );

  const menuButtonRef = useRef<HTMLButtonElement>(null);
  const renderContentTypeSelect = useCallback(
    ({
      cell,
      value,
      key,
      options,
      zoomRatio,
      background,
      fontSize,
      handleChangeSelect,
      isDisabled,
    }: iRenderContentTypeSelect) => {
      const onCloseMenu = () => {
        const ele = document.getElementById(`resize-${component?.componentId}`);
        if (ele) {
          ele.style.zIndex = ele.getAttribute("data-zindex") || "1";
        }
      };

      const onFocusMenuList = () => {
        const ele = document.getElementById(`resize-${component?.componentId}`);
        if (ele) {
          ele.style.zIndex = "10";
        }
      };

      return (
        <Flex w="100%" h="100%" alignItems="center">
          <Menu onClose={onCloseMenu}>
            <MenuButton
              ref={menuButtonRef}
              as={Button}
              px={`${1 * zoomRatio}rem`}
              style={{
                width: "100%",
                height: "calc(100% - 1px)",
                background,
                color: cell?.style?.color,
                borderRadius: "0px",
              }}
              fontSize={fontSize}
              rightIcon={<ChevronDownIcon />}
              sx={{
                "& > span:first-of-type": {
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                },
              }}
            >
              {options?.find((opt) => opt.value === value)?.label}
            </MenuButton>
            <MenuList
              zIndex={10}
              minW={menuButtonRef?.current?.clientWidth}
              maxH="15rem"
              overflowY="auto"
              outline="none"
              className="box-scroll-bar"
              borderRadius="6px"
              onFocus={onFocusMenuList}
            >
              <MenuOptionGroup
                value={value}
                title=""
                type="radio"
                onChange={(value: any) => {
                  if (isDisabled) return;
                  handleChangeSelect(value, key);
                }}
              >
                {options.map((item) => (
                  <MenuItemOption
                    key={item.value}
                    value={item.value}
                    _focus={{}}
                    color="var(--primary-text-color)"
                    _hover={{ backgroundColor: "#F0F9FF" }}
                    backgroundColor={item.value === value ? "#F0F9FF" : "#fff"}
                    borderBottom="1px solid var(--primary-border-color)"
                    _last={{ borderBottom: "none" }}
                    fontSize="1rem"
                    sx={{
                      ".chakra-menu__icon-wrapper": {
                        color: "#009BE0",
                      },
                    }}
                  >
                    {item?.label}
                  </MenuItemOption>
                ))}
              </MenuOptionGroup>
            </MenuList>
          </Menu>
        </Flex>
      );
    },
    [component?.componentId]
  );

  const renderContentTypeDate = useCallback(
    ({
      cell,
      value,
      zoomRatio,
      fontSize,
      onSelectDate,
      onClose,
      isDisabled,
    }: iRenderContentTypeDate) => {
      return (
        <Box
          style={{
            margin: "0 auto",
            width: "calc(100% - 4px)",
            height: "calc(100% - 4px)",
          }}
          sx={{
            "& > div, & > div > div, & > div > div > div": {
              height: "100%",
            },
          }}
        >
          <DayPicker
            value={value}
            formatValue={getFormatContentTypeDate(value)}
            onSelectedDay={onSelectDate}
            onClose={onClose}
            isShowIcon={false}
            placeholder=""
            isDisabled={isDisabled}
            inputProps={{
              height: "100%",
              paddingLeft: `${1 * zoomRatio}rem`,
              paddingRight: `${2.5 * zoomRatio}rem`,
              borderWidth: `${1 * zoomRatio}px`,
              color: cell?.style?.color,
              fontSize,
              isDisabled,
            }}
            inputRightElementProps={{
              width: `${2.5 * zoomRatio}rem`,
              height: `${2.5 * zoomRatio}rem`,
              margin: 0,
              sx: {
                svg: {
                  width: `${20 * zoomRatio}px`,
                  height: `${20 * zoomRatio}px`,
                },
              },
            }}
          />
        </Box>
      );
    },
    []
  );

  const renderContentTypeInput = useCallback(
    ({
      value,
      placeholder,
      fontSize,
      onChange,
      onBlur,
      isDisabled,
    }: iRenderContentTypeInput) => {
      return (
        <Input
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          style={{
            fontSize,
            width: "100%",
            height: "100%",
            borderRadius: "0px",
            border: "none",
          }}
          isDisabled={isDisabled}
        />
      );
    },
    []
  );

  const contentOfCellTypeBlackboard = useCallback(
    (cell: CellType) => {
      const data = structuredClone(cell);

      if (data?.subTable?.rows?.length) {
        return renderSubtable(cell);
      }
      const documentSubItem: DocumentSubItemDTO =
        blackboardTemplateProps?.documentSubItem as any;

      switch (data?.cellProperty) {
        case CellProperty.NO:
        case CellProperty.TEXT:
        case CellProperty.INPUT_DATA:
        case CellProperty.FAMILY_INSTANCE:
        case CellProperty.CALENDAR: {
          return renderNormalText(data, component);
        }

        case CellProperty.DOCUMENT_DATA:
          switch (data?.cellLinkedData?.field) {
            case LinkedDataField.BLACKBOARD.COMMENT_MANAGE_EXECUTE:
              return (
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    height: "100%",
                    padding: `${0.5 * zoomRatio}rem`,
                    overflow: "hidden",
                  }}
                >
                  <CommentManageExecuteTextInput
                    cells={
                      (data?.cellLinkedData?.options?.blackboard?.cells ||
                        []) as CellType[]
                    }
                    familyInstance={familyInstance || ({} as any)}
                    isOnlyViewBlackboard={isOnlyViewBlackboard}
                    isAutoResize={false}
                    commentManageExecute={
                      blackboardLocalData
                        ? blackboardLocalData?.[
                            LinkedDataField.BLACKBOARD.COMMENT_MANAGE_EXECUTE
                          ]
                        : []
                    }
                    cellStyle={{
                      fontSize:
                        (data?.style?.fontSize || 14) *
                        zoomRatio *
                        DEFAULT_BLACKBOARD_TEMPLATE_FONT_SIZE_SCALE,
                    }}
                    component={component}
                    onChangeCommentManageExecute={
                      handleChangeCommentManageExecute
                    }
                  />
                </Box>
              );

            case LinkedDataField.BLACKBOARD.CONSTRUCTION_NAME_TEXT:
              return (
                <Box
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <TextContainer
                    data-field-name={cell?.cellLinkedData?.field}
                    autoReduceSize={
                      component?.detail?.allowDynamicAdjustFontSize || false
                    }
                    alignText={cell.style?.justifyContent}
                    style={{
                      paddingLeft: `${0.5 * zoomRatio}rem`,
                      paddingRight: `${0.5 * zoomRatio}rem`,
                      minWidth: "fit-content",
                      width: "fit-content",
                      height: "fit-content",
                      color: cell?.style?.color,
                    }}
                  >
                    {projectName}
                  </TextContainer>
                </Box>
              );
            case LinkedDataField.BLACKBOARD.IMAGE_UPLOAD_INFO:
              const imageInfo: FileUploadInfo = documentSubItem?.images || {
                src: "",
              };
              const user = imageInfo.userUpload
                ? listUserById?.[imageInfo.userUpload]?.name || "-"
                : "-";

              return (
                <Box
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <TextContainer
                    data-field-name={cell?.cellLinkedData?.field}
                    autoReduceSize={
                      component?.detail?.allowDynamicAdjustFontSize || false
                    }
                    alignText={cell.style?.justifyContent}
                    style={{
                      paddingLeft: `${0.5 * zoomRatio}rem`,
                      paddingRight: `${0.5 * zoomRatio}rem`,
                      minWidth: "fit-content",
                      width: "fit-content",
                      height: "fit-content",
                      color: cell?.style?.color,
                    }}
                  >
                    {`${user} \n ${getDateModifiedDateFile(
                      imageInfo
                    ).replaceAll(" ", "\n")}` || "-"}
                  </TextContainer>
                </Box>
              );

            default:
              if (isOnlyViewBlackboard) {
                return renderContentTypeOnlyView({
                  cell: data,
                  documentItem: documentSubItem,
                  data: blackboardLocalData as Blackboard,
                });
              }

              const key = data?.cellLinkedData?.field!;
              const dataType = MapBlackboardKeyWithDataType[key];

              switch (dataType?.type) {
                case "select":
                  let options: { label: string; value: string }[] = [];
                  let value =
                    blackboardLocalData?.[dataType?.selectOption?.fieldId!] ||
                    "";

                  switch (dataType?.selectOption?.options) {
                    case "list-user":
                      options = listOptionUsers;
                      break;
                    case "floor":
                      options = listOptionFloor;
                      if (!value && levelSelectedRef.current) {
                        value = levelSelectedRef.current?.guid;
                      }

                      break;
                    case "partner-companies":
                      options = listPartnerCompanies;
                      break;
                    default:
                      options = dataType?.selectOption?.options || [];
                      break;
                  }

                  return renderContentTypeSelect({
                    cell,
                    fontSize,
                    zoomRatio,
                    textColorOfBlackboard,
                    background:
                      BlackboardTemplateBackground[
                        (blackboardTemplate?.backgroundType as BlackboardTemplateBackgroundType) ||
                          BlackboardTemplateBackgroundType.GREEN
                      ]?.backgroundColor || "",
                    options,
                    key,
                    value,
                    handleChangeSelect,
                    isDisabled: cell.isDisabled,
                  });

                case "date":
                  if (
                    key === LinkedDataField.BLACKBOARD.SHOOTING_TIME &&
                    !documentSubItem?.images?.src
                  ) {
                    return renderContentTypeOnlyView({
                      cell,
                      data: blackboardLocalData as Blackboard,
                      documentItem: documentSubItem,
                    });
                  }

                  return renderContentTypeDate({
                    cell,
                    value: blackboardLocalData?.[key as keyof Blackboard],
                    zoomRatio,
                    fontSize,
                    textColorOfBlackboard,

                    onSelectDate: (val) => {
                      handleChangeDate(val, key as BlackboardKey);
                      onChangeBlackboardData?.({
                        id: blackboardLocalData?.id,
                        [key]: val,
                      } as Blackboard);
                    },
                    onClose: () => {},
                    isDisabled: cell.isDisabled,
                  });

                default:
                  return renderContentTypeInput({
                    value:
                      blackboardLocalData?.[key as keyof Blackboard] ||
                      ("" as any),
                    placeholder:
                      data?.cellLinkedData?.options?.blackboard?.label || "",
                    fontSize,
                    onBlur: (e) =>
                      handleBlurInput(e.target.value, key as BlackboardKey),
                    onChange: (e) =>
                      handleChangeInput(e.target.value, key as BlackboardKey),
                    isDisabled: cell.isDisabled,
                  });
              }
          }

        default:
          return renderNormalText(data, component);
      }
    },
    [
      component,
      blackboardTemplateProps?.documentSubItem,
      familyInstance,
      zoomRatio,
      isOnlyViewBlackboard,
      blackboardLocalData,
      projectName,
      listUserById,
      fontSize,
      textColorOfBlackboard,
      blackboardTemplate?.backgroundType,
      listOptionUsers,
      listOptionFloor,
      listPartnerCompanies,
      renderSubtable,
      handleChangeCommentManageExecute,
      renderContentTypeOnlyView,
      renderContentTypeSelect,
      handleChangeSelect,
      renderContentTypeDate,
      renderContentTypeInput,
      handleBlurInput,
      handleChangeDate,
      onChangeBlackboardData,
      handleChangeInput,
    ]
  );

  return {
    contentOfCellTypeBlackboard,
  };
};

export default useGetContentTypeBlackboard;
