import { Box, Divider, Flex, Text, useBoolean } from "@chakra-ui/react";
import { documentItemApi, documentItemLogApi } from "apiClient/v2";
import { IconBase, message } from "components/base";
import Spinner from "components/ui/Spinner";
import { DOCUMENT_TYPE_INFO } from "constants/document";
import { FamilyTitleDisplayType } from "constants/enum";
import { DocumentCategoryDTO } from "interfaces/dtos/documentCategoryDTO";
import {
  DocumentItemDTO,
  DocumentSubItemDTO,
} from "interfaces/dtos/documentItemDTO";
import { FamilyInstanceDTO } from "interfaces/dtos/familyInstance";
import {
  FamilyTitleDisplayForCustomDocumentItem,
  TitleDisplay,
} from "interfaces/models/component";
import {
  DocumentItemLog,
  DocumentSubItem,
} from "interfaces/models/documentItem";
import { DocumentTemplate } from "interfaces/models/documentTemplate";
import { User } from "interfaces/models/user";
import { getColorTextByStatus } from "models/document";
import {
  GetContentItemLog,
  getDocumentContentLog,
} from "models/documentItemLog";
import { memo, useCallback } from "react";
import { useDispatch } from "react-redux";
import { updateDocumentItem } from "redux/documentSlice";
import { getDocumentItemTitleByFamilyTitle } from "utils/documentItem";
import { arrayToObject } from "utils/object";
import FieldNoComponent from "./FieldNoComponent";
import ItemStatusMenu from "./ItemStatusMenu";
import ItemTasks from "./ItemTasks";
import ItemTitle, { ItemTitleType } from "./ItemTitle";
import SubItem from "./SubItem";

interface Props {
  currentUser?: User | null;
  documentItemSelected?: DocumentItemDTO;
  isLoadedViewer: boolean;
  documentCategorySelected?: DocumentCategoryDTO;
  familyInstance: FamilyInstanceDTO;
  documentTemplate?: DocumentTemplate;
  itemStatusColor: string;
  isOnline: boolean;
  isDisabled?: boolean;
  itemDisplayOrder: number | string;

  onSelectSubItem: (subItem: DocumentSubItemDTO) => void;
}

const ModalTypePin = (props: Props) => {
  const {
    isLoadedViewer,
    documentItemSelected,
    documentCategorySelected,
    currentUser,
    familyInstance,
    documentTemplate,
    itemStatusColor,
    isOnline,
    itemDisplayOrder,

    onSelectSubItem,
    isDisabled,
  } = props;

  const [isLoadingAddSubItem, setIsLoadingAddSubItem] = useBoolean();

  const dispatch = useDispatch();

  const insertDocumentItemLog = useCallback(
    async ({
      field,
      value,
      operation,
      nameDynamicField,
    }: GetContentItemLog) => {
      if (!currentUser?.id || !documentItemSelected?.id) {
        return;
      }

      const content = getDocumentContentLog({
        field,
        value,
        operation,
        nameDynamicField,
      }) as any;

      const now = new Date();
      documentItemLogApi.createItemLog({
        createdBy: currentUser.id,
        content,
        title: content?.title,
        field,
        value,
        itemId: documentItemSelected?.id,
        updatedAt: now,
        createdAt: now,
      } as DocumentItemLog);
    },
    [currentUser?.id, documentItemSelected?.id]
  );

  const handleAddSubItem = async () => {
    if (isLoadingAddSubItem || !documentItemSelected?.id) {
      return;
    }

    setIsLoadingAddSubItem.on();

    // handle set title for sub item
    const isAddedFromForge = documentItemSelected?.isAddedFromForge;
    let familyTitle: TitleDisplay = {} as any;

    if (isAddedFromForge) {
      const familyTitleDisplayForCustomDocumentItem: FamilyTitleDisplayForCustomDocumentItem =
        documentTemplate?.familyTitleDocItem || {};
      familyTitle =
        familyTitleDisplayForCustomDocumentItem[
          FamilyTitleDisplayType.DocumentItem
        ];
    } else {
      const mapObjectTypesById = arrayToObject(
        familyInstance?.objectTypes || [],
        "id"
      );
      const displayTypeSubItem =
        (documentTemplate?.familyTitle || {})?.[
          FamilyTitleDisplayType.DocumentItem
        ] || {};
      const objectTypes = Object.keys(displayTypeSubItem).filter(
        (key) => !!mapObjectTypesById?.[key]
      );
      familyTitle = displayTypeSubItem?.[objectTypes?.[0] || ""];
    }

    const title = getDocumentItemTitleByFamilyTitle({
      familyInstance,
      familyTitle,
    });

    const body: Partial<DocumentSubItemDTO> = {
      itemId: documentItemSelected.id,
      blackboardTemplateId: documentTemplate?.blackboardTemplateDetail?.id,
      isShowBlackboard: true,
      title,
      data: {},
      level: documentItemSelected?.level || "",
      templateId: documentItemSelected?.templateId || "",
      externalId: documentItemSelected?.externalId || "",
      bimFileId: documentItemSelected?.bimFileId || "",
      projectId: documentItemSelected?.projectId || "",
    };

    const documentItem = structuredClone(documentItemSelected);
    const res = await documentItemApi.createSubItem(body as DocumentSubItem);
    setIsLoadingAddSubItem.off();
    const subItem = res?.data;
    if (!subItem.id) {
      return message.error("Create new sub item failure");
    }
    if (!documentItem.subItems) {
      documentItem.subItems = [];
    }

    documentItem.subItems?.push(subItem as DocumentSubItemDTO);

    dispatch(updateDocumentItem(documentItem));
    message.success(["写真の追加", "写真を追加しました。"]);
  };

  return (
    <Box padding="0px 1.6rem 5.8rem" minHeight="100%">
      {/* title */}
      <Flex gap="1.2rem" alignItems="center">
        <FieldNoComponent
          color={itemStatusColor}
          no={itemDisplayOrder}
          colorText={getColorTextByStatus(documentItemSelected?.status)}
        />

        <Flex flexDirection="column" flex={1}>
          <Text lineHeight="2.2rem" color="#737373" fontSize="1.4rem">
            {
              //@ts-ignore
              DOCUMENT_TYPE_INFO[documentItemSelected?.documentType || ""]
                ?.typeName || ""
            }
          </Text>
          <ItemTitle
            inputProps={{
              fontWeight: "bold",
              isDisabled,
            }}
            type={ItemTitleType.ITEM}
            item={documentItemSelected}
          />
        </Flex>
      </Flex>

      <Divider opacity="1" mt="3.2rem" borderColor="#D4D4D4" />

      {/* sub item */}
      <Box pl="5.2rem" mb="3rem">
        <Flex flexDirection="column">
          {(documentItemSelected?.subItems || [])?.map((subItem) => (
            <SubItem
              key={subItem.id}
              isOnline={isOnline}
              subItem={subItem}
              currentUser={currentUser}
              documentItemSelected={documentItemSelected}
              isHasBlackboard={!!documentTemplate?.blackboardTemplateDetail?.id}
              isDisabled={isDisabled}
              itemDisplayOrder={itemDisplayOrder}
              onSelectSubItem={onSelectSubItem}
            />
          ))}
        </Flex>

        {/* add sub item */}
        {!isDisabled && (
          <Flex
            gap="0.4rem"
            alignItems="center"
            mt="1rem"
            ml="4rem"
            cursor="pointer"
            width="fit-content"
            onClick={handleAddSubItem}
          >
            {isLoadingAddSubItem ? (
              <Spinner
                emptyColor="gray.200"
                thickness="3px"
                color="blue.500"
                width="1.6rem"
                height="1.6rem"
              />
            ) : (
              <IconBase
                icon="/img/icon-plus-outline.svg"
                width="1.6rem"
                height="1.6rem"
                color="#737373"
              />
            )}
            <Text
              fontWeight={500}
              color="#737373"
              lineHeight="1.6rem"
              fontSize="1.6rem"
            >
              写真を追加
            </Text>
          </Flex>
        )}
      </Box>

      {/* insert task */}
      <Box borderTop="1px solid #A3A3A3" pt="2rem">
        <ItemTasks
          isOnline={isOnline}
          documentItem={documentItemSelected!}
          isDisabled={isDisabled}
        />
      </Box>

      {/* status */}
      <Flex
        position="absolute"
        bottom="0"
        left="0"
        width="100%"
        height="5.8rem"
        zIndex="12"
        backgroundColor="#E8E8E8"
        borderTop="1px solid rgba(0,0,0,0.15)"
        justifyContent="space-between"
        padding="0px 1.6rem"
        alignItems="center"
        gap="0.8rem"
      >
        <Text
          flexShrink={0}
          color="var(--primary-text-color)"
          fontSize="1.4rem"
        >
          ステータス
        </Text>
        <Box flex={1} maxW="35rem">
          <ItemStatusMenu
            isLoadedViewer={isLoadedViewer}
            currentUser={currentUser}
            documentItemSelected={documentItemSelected}
            documentCategorySelected={documentCategorySelected}
            insertDocumentItemLog={insertDocumentItemLog}
            isDisabled={isDisabled}
          />
        </Box>
      </Flex>
    </Box>
  );
};

export default memo(ModalTypePin);
