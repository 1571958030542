import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { REDUCER_KEY } from "constants/redux";
import { TemplateComponent } from "interfaces/models/component";

export interface AppState {
  regionSelect: {
    enable: boolean;
    gridSize: {
      rows: number;
      columns: number;
      maxRows: number;
      maxColumns: number;
    };
    gridPosition: {
      x: number;
      y: number;
    };
  };
  isScanGrid: boolean;
  scanComponents: TemplateComponent[];
}

const initialState: AppState = {
  regionSelect: {
    enable: true,
    gridSize: {
      rows: 0,
      columns: 0,
      maxRows: 0,
      maxColumns: 0,
    },
    gridPosition: {
      x: 0,
      y: 0,
    },
  },
  isScanGrid: false,
  scanComponents: [],
};

export const editorSlice = createSlice({
  name: REDUCER_KEY.EDITOR,
  initialState,
  reducers: {
    setGridSize: (
      state,
      action: PayloadAction<{
        rows: number;
        columns: number;
        maxRows: number;
        maxColumns: number;
      }>
    ) => {
      state.regionSelect.gridSize = action.payload;
    },
    setGridPosition: (
      state,
      action: PayloadAction<{ x: number; y: number }>
    ) => {
      state.regionSelect.gridPosition = action.payload;
    },
    setEnableRegionSelect: (
      state,
      action: PayloadAction<boolean | undefined>
    ) => {
      state.regionSelect.enable =
        typeof action.payload !== "undefined"
          ? action.payload
          : !state.regionSelect.enable;
    },
    setScanComponents: (
      state,
      action: PayloadAction<TemplateComponent[] | []>
    ) => {
      state.scanComponents = [...action.payload];
    },
    setIsScanGrid: (state, action: PayloadAction<boolean>) => {
      state.isScanGrid = action.payload;
    },
  },
});

export const {
  setGridSize,
  setGridPosition,
  setEnableRegionSelect,
  setScanComponents,
  setIsScanGrid,
} = editorSlice.actions;

export default editorSlice.reducer;
