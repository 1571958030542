const CrossLine = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        background: `
          linear-gradient(to top right,
          rgba(0,0,0,0) 0%,
          rgba(0,0,0,0) calc(50% - 0.8px),
          rgba(0,0,0,1) 50%,
          rgba(0,0,0,0) calc(50% + 0.8px),
          rgba(0,0,0,0) 100%)`,
      }}
    />
  );
};

export default CrossLine;
