import { bimFileApi } from "apiClient/v2";
import { message } from "components/base";
import { CACHED_PROJECT_INFO_KEY } from "constants/cache";
import { DataProjectModel } from "interfaces/models/dataProjectModel";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDeviceSelectors } from "react-device-detect";
import { useDispatch } from "react-redux";
import { clearState as clearAppState, setSyncDataOption } from "redux/appSlice";
import { checkCacheProject, setCachingProject } from "redux/projectSlice";
import store from "redux/store";
import {
  axiosCacheController,
  resetAxiosCacheController,
} from "services/baseAxios";
import { doRefreshToken } from "utils/authen";
import { getBimFileInfo } from "utils/bim";
import {
  cacheCommonModelData,
  cacheModelData,
  cacheProjectData,
  deleteModelCache,
  deleteProjectCache,
  getCacheProgress,
  getCacheProjectByStorage,
  getUrlsOfModel,
  PROJECT_RESOURCE,
  saveProgressToStorage,
  _currentCachingProgress,
} from "utils/cache";
import { sleep } from "utils/common";
import { dispatchEvent } from "utils/event";
import { getIndexedDb } from "utils/indexedDb";
import { arrayToObject } from "utils/object";
import { setLocalStorage } from "utils/storage";

export interface iCachedProject {
  cached: number;
  total: number;
  progress: number;
  lastCacheTime?: Date;
  isPause?: boolean | undefined;
}

interface iProps {
  project: DataProjectModel | undefined;
  isServiceWorkerReady: boolean;
  cachingProjectBimFileId: string | undefined;
  isOnline: boolean;
  cachedInfo: iCachedProject | undefined;
  handleClickProject?: any;
}

const DELAY_RECACHE_TIME = 2000;

const useCheckProjectCached = ({
  project,
  isServiceWorkerReady,
  cachingProjectBimFileId,
  cachedInfo,
  isOnline,
  handleClickProject,
}: iProps) => {
  const dispatch = useDispatch();
  const [cachingStatus, setCachingStatus] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean | undefined>(undefined);
  const [progress, setProgress] = useState<number>(0);
  const [isClearing, setIsClearing] = useState<boolean>(false);
  const [{ isSafari }] = useDeviceSelectors(window.navigator.userAgent);
  const isCachingRef = useRef(false);

  const isCaching = useMemo(() => {
    return !!project?.id && cachingProjectBimFileId === project.id;
  }, [cachingProjectBimFileId, project?.id]);

  useEffect(() => {
    isCachingRef.current = isCaching;
  }, [isCaching]);

  useEffect(() => {
    if (!project?.id || !("caches" in window)) {
      return;
    }
    (async () => {
      try {
        setIsLoading(true);
        const currentCacheInfo =
          _currentCachingProgress[project?.id || ""] || {};

        if (!isCaching) {
          if (cachedInfo) {
            setProgress(cachedInfo.progress);
            _currentCachingProgress[project.id] = {
              total: cachedInfo.total,
              cached: cachedInfo.cached,
            };
          } else {
            setProgress(0);
            saveProgressToStorage({
              progress: 0,
              projectId: project?.id || "",
            });
          }
        } else {
          setProgress(
            Math.floor(
              ((currentCacheInfo.cached ?? 0) * 100) / currentCacheInfo.total
            )
          );
        }

        setIsLoading(false);
      } catch (e) {
        /* eslint-disable no-console */
        console.log(e);
        setIsLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project?.id, cachedInfo]);

  const handleEvent = (e: any) => {
    if (!isCachingRef.current) {
      return;
    }
    const payload = e.detail;
    const progress = Math.floor((payload.cached * 100) / payload.total);
    setProgress(progress);
    if (progress >= 100) {
      dispatch(setCachingProject(undefined));
      setCachingStatus(false);
      if (!store.getState().project.dataProjectDetail?.id) {
        handleClickProject?.();
      }
    }
  };

  // stop download project when offline
  useEffect(() => {
    if (!isOnline && isCaching) {
      stopCacheProject();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCaching, isOnline]);

  useEffect(() => {
    if (!project?.id || !isServiceWorkerReady || !isOnline) {
      return;
    }

    document.addEventListener(`cache-${project.id}`, handleEvent);

    return function cleanUp() {
      document.removeEventListener(`cache-${project.id}`, handleEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project?.id, isServiceWorkerReady, isOnline]);

  useEffect(() => {
    if (!isCaching && cachingStatus) {
      setCachingStatus(false);
    }
  }, [isCaching, cachingStatus]);

  const cacheProject = useCallback(
    async (isIgnoreCheckIsCaching = false) => {
      const { cachedProject: currentCaching } = getCacheProjectByStorage(
        project?.id || ""
      );

      if (
        cachingStatus ||
        !isServiceWorkerReady ||
        (isCaching && !isIgnoreCheckIsCaching) ||
        !project?.id ||
        (!!currentCaching?.total &&
          currentCaching?.cached === currentCaching?.total)
      ) {
        return;
      }

      dispatch(setCachingProject(project.id));
      setCachingStatus(false);
      resetAxiosCacheController();

      setTimeout(async () => {
        try {
          await doRefreshToken();
          const {
            cachedLength,
            totalLength,
            cachedModelData,
            cachedProjectData,
          } = await getCacheProgress(project, isSafari);
          _currentCachingProgress[project.id] = {
            total: totalLength,
            cached: cachedLength,
            isPause: false,
          };
          const newProgress = Math.floor(
            ((cachedLength ?? 0) * 100) / totalLength
          );
          setProgress(newProgress);
          saveProgressToStorage({
            progress: newProgress,
            cached: cachedLength,
            total: totalLength,
            projectId: project?.id || "",
          });
          _currentCachingProgress[project.id].cached = cachedLength;
          await cacheCommonModelData(project, cachedModelData, isSafari);
          await cacheProjectData(project, cachedProjectData);
          await cacheModelData(project, cachedModelData);
          setCachingStatus(true);
        } catch (e) {
          const { cachedProject } = getCacheProjectByStorage(project?.id || "");
          if (cachedProject?.isPause) {
            /* eslint-disable no-console */
            console.log("download project has stopped");
          } else {
            message.error(
              `「${project.bimFileName}」のデータダウンロードが失敗しました。再度試してください。`
            );
            dispatch(setCachingProject(undefined));
          }
        }
      });
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      cachingProjectBimFileId,
      isServiceWorkerReady,
      isCaching,
      project?.id,
      dispatch,
    ]
  );

  const clearProjectCache = useCallback(
    async (isResetAppState = true) => {
      if (!isServiceWorkerReady || isCaching || !project?.id) {
        return;
      }

      const resetState = () => {
        dispatch(checkCacheProject());
        dispatch(setCachingProject(undefined));
        setIsClearing(false);
      };

      try {
        dispatch(setCachingProject("clear cache"));
        setIsClearing(true);
        const modelData = await getUrlsOfModel(project);
        const { cachedProjects } = getCacheProjectByStorage(project?.id || "");
        cachedProjects[project.id] = { progress: 0 } as any;
        setLocalStorage(CACHED_PROJECT_INFO_KEY, cachedProjects);

        // clear cache modal data
        await deleteModelCache(modelData);
        // clear api cache's data
        await deleteProjectCache(project.id);
        _currentCachingProgress[project.id].cached = 0;

        setProgress(0);
        dispatchEvent({
          type: `cache-${project.id}`,
          payload: _currentCachingProgress[project.id],
        });
        resetState();
        if (isResetAppState) {
          dispatch(clearAppState());
        }
      } catch (e) {
        console.log(e);

        message.error(`Clear cache project ${project.name} failed.`);
        resetState();
      }
    },
    [isCaching, isServiceWorkerReady, project, dispatch]
  );

  const syncNewProjectData = useCallback(
    async (cachedUrls?: string[]) => {
      if (!isServiceWorkerReady || isCaching || !project?.id) {
        return;
      }

      resetAxiosCacheController();
      dispatch(setCachingProject(project.id));
      const resetState = () => {
        dispatch(checkCacheProject());
        dispatch(setCachingProject(undefined));
      };

      try {
        const { cachedProjects } = getCacheProjectByStorage(project?.id || "");

        const indexedDb = await getIndexedDb();
        const projectDataCacheProgress: {
          id: string;
          key: string;
          projectId: string;
        }[] = (await indexedDb.getProjectDataCacheProgress()).filter(
          (item: any) => item.projectId === project.id
        );
        const mapProjectDataCacheProgressByKey = arrayToObject(
          projectDataCacheProgress,
          "key"
        );

        const deletedIds: string[] = [];
        const resourceNotCached: string[] = Object.values(
          PROJECT_RESOURCE
        ).filter((res) => !cachedUrls?.length || !cachedUrls.includes(res));

        resourceNotCached.forEach((key) => {
          const id = mapProjectDataCacheProgressByKey?.[key]?.id;
          id && deletedIds.push(id);
        });

        // delete older resource before sync new data
        await indexedDb.deleteProjectProgressByIds(deletedIds);

        // calculate the number of cached requests
        if (cachedProjects?.[project.id]?.cached) {
          cachedProjects[project.id].cached -= resourceNotCached.length || 0;
        } else {
          cachedProjects[project.id] = {
            cached: 0,
            progress: 0,
            total: 0,
          };
        }

        // store data to global variable and local storage
        const progress = Math.floor(
          ((cachedProjects[project.id].cached ?? 0) * 100) /
            cachedProjects[project.id].total
        );
        cachedProjects[project.id] = {
          ...cachedProjects[project.id],
          progress,
        };
        _currentCachingProgress[project.id].cached =
          cachedProjects[project.id].cached;
        _currentCachingProgress[project.id].total =
          cachedProjects[project.id].total;
        setLocalStorage(CACHED_PROJECT_INFO_KEY, cachedProjects);
        setProgress(progress);

        // cache api's data necessary
        await doRefreshToken();
        await cacheProjectData(project, cachedUrls || []);

        // set cache's time
        if (project?.id) {
          const syncDataOption = structuredClone(
            store.getState().app.syncDataOption
          );
          const mapSyncDataTimeByProject =
            syncDataOption?.mapSyncDataTimeByProject || {};
          mapSyncDataTimeByProject[project.id] = new Date();
          dispatch(setSyncDataOption({ mapSyncDataTimeByProject }));
        }
        resetState();
      } catch (e) {
        const { cachedProject } = getCacheProjectByStorage(project?.id || "");
        if (cachedProject.isPause) {
          /* eslint-disable no-console */
          console.log("cache project has stoped");
        } else {
          message.error(`Cache revit file ${project.bimFileName} interupted.`);
        }

        resetState();
      }
    },
    [isCaching, isServiceWorkerReady, project, dispatch]
  );

  const handleBeforeSyncProjectData = useCallback(async () => {
    if (progress === 0 || progress !== 100) {
      await cacheProject();

      return;
    }

    const syncDataOption = structuredClone(store.getState().app.syncDataOption);
    const syncDataTime =
      syncDataOption?.mapSyncDataTimeByProject?.[project?.id || ""] || null;
    if (!project?.defaultBimPathId) {
      return;
    }

    const urn = project?.defaultBimPathId?.split("/").pop();
    const currentBimfileId = getBimFileInfo(urn || "")?.bimFileId || "";
    if (!currentBimfileId) {
      return;
    }

    let cachedUrls: string[] = [];
    if (syncDataTime && currentBimfileId) {
      const res = await bimFileApi.getLastUpdatedResource({
        bimFileId: currentBimfileId,
        localTime: new Date(syncDataTime).toISOString(),
      });

      if (!res?.data?.length) {
        return;
      }

      let excludeCachedUrls: string[] = [];

      res.data.forEach((item) => {
        const resource = item.resource;

        switch (resource) {
          case "sub_items":
          case "document_items":
            excludeCachedUrls.push(PROJECT_RESOURCE.documentItems);
            break;

          case "families":
            excludeCachedUrls.push(PROJECT_RESOURCE.families);
            break;

          case "task_comments":
          case "tasks":
            excludeCachedUrls.push(PROJECT_RESOURCE.tasks);
            break;

          case "blackboards":
          case "document_categories":
            excludeCachedUrls.push(PROJECT_RESOURCE.documentCategories);
            excludeCachedUrls.push(PROJECT_RESOURCE.blackboardTemplate);
            break;

          case "task_sheet_templates":
            excludeCachedUrls.push(PROJECT_RESOURCE.taskSheetTemplates);
            break;

          case "document_templates":
            excludeCachedUrls.push(PROJECT_RESOURCE.documentTemplate);
            break;

          case "document_groups":
            excludeCachedUrls.push(PROJECT_RESOURCE.documentGroup);
            break;

          case "task_types":
            excludeCachedUrls.push(PROJECT_RESOURCE.taskTypes);
            break;

          case "users":
            excludeCachedUrls.push(PROJECT_RESOURCE.listUser);
            excludeCachedUrls.push(PROJECT_RESOURCE.userSetting);
            break;

          case "project_bim_file":
            excludeCachedUrls.push(PROJECT_RESOURCE.project);
            excludeCachedUrls.push(PROJECT_RESOURCE.projectDetail);
            excludeCachedUrls.push(PROJECT_RESOURCE.partnerCompanies);
            break;

          default:
            if (PROJECT_RESOURCE?.[resource as keyof typeof PROJECT_RESOURCE]) {
              excludeCachedUrls.push(
                PROJECT_RESOURCE?.[resource as keyof typeof PROJECT_RESOURCE]
              );
            }

            break;
        }
      });

      excludeCachedUrls = [...new Set(excludeCachedUrls)];
      cachedUrls = Object.values(PROJECT_RESOURCE).filter(
        (res) => !excludeCachedUrls.includes(res)
      );
    }

    await syncNewProjectData(cachedUrls);
  }, [
    progress,
    project?.id,
    project?.defaultBimPathId,
    cacheProject,
    syncNewProjectData,
  ]);

  const stopCacheProject = useCallback(() => {
    if (!isCaching || !cachingProjectBimFileId || !project?.id) {
      return;
    }

    _currentCachingProgress[project.id].isPause = true;
    const { cachedProjects, cachedProject } = getCacheProjectByStorage(
      project?.id || ""
    );
    cachedProjects[project.id] = {
      ...cachedProject,
      progress: Math.floor(
        ((cachedProject.cached ?? 0) * 100) / cachedProject.total
      ),
      isPause: true,
    };
    setLocalStorage(CACHED_PROJECT_INFO_KEY, cachedProjects);
    setCachingStatus(false);
    dispatch(checkCacheProject());
    dispatch(setCachingProject(undefined));
    setIsLoading(false);

    setTimeout(() => {
      axiosCacheController.abort();
    }, 100);
  }, [isCaching, cachingProjectBimFileId, project?.id, dispatch]);

  return {
    handleBeforeSyncProjectData,
    syncNewProjectData,
    clearProjectCache,
    cacheProject,
    stopCacheProject,
    progress,
    isLoading,
    isClearing,
  };
};

export default useCheckProjectCached;
