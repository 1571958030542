import {
  authApi,
  blackboardTemplateApi,
  documentCategoryApi,
  documentGroupApi,
  documentItemApi,
  documentTaskApi,
  documentTemplateApi,
  familyApi,
  forgeApi,
  partnerCompanyApi,
  projectApi,
  projectBimFileApi,
  s3Api,
  taskApi,
  taskSheetTemplateApi,
  taskTypeApi,
  userApi,
  userSettingApi,
} from "apiClient/v2";
import base64 from "base-64";
import { CACHED_PROJECT_INFO_KEY } from "constants/cache";
import { ItemBIMType } from "constants/enum";
import { FETCH_FAILED } from "constants/error";
import { LEVEL_OTHER_ID } from "constants/forge";
import {
  API_GET_CACHE,
  AVAILABLE_STORES,
  Operation,
  STATIC_FILES_CACHE,
  UpdateToOnlineStatus,
} from "constants/serviceWorker";
import { iCachedProject } from "hooks/useCheckProjectCached";
import { Level } from "interfaces/models";
import { DataProjectModel } from "interfaces/models/dataProjectModel";
import { iCachedItem } from "interfaces/models/serviceWorker";
import JSZip from "jszip";
import { setSyncDataOption } from "redux/appSlice";
import { checkCacheProject, setCachingProject } from "redux/projectSlice";
import store from "redux/store";
import { axiosECS } from "services/baseAxios";
import { doRefreshToken } from "./authen";
import { getBimFileInfo } from "./bim";
import { getNetworkStatus, sleep, uuid } from "./common";
import { dispatchEvent } from "./event";
import { retryFetch } from "./fetch";
import {
  getAECData,
  getExternalId,
  getFamilyInstancesFromS3,
  getForgeToken,
  getSpacesFromS3,
} from "./forge";
import { getIndexedDb } from "./indexedDb";
import { getLocalStorage, setLocalStorage } from "./storage";
import { getListUser } from "./user";

const createUpdateItems = async (_requestName: string, item: any) => {
  let requestName = _requestName;

  if (requestName === "sub-items") {
    requestName = "document-items/sub-items";
  }

  return await axiosECS.post(`v2/${requestName}?syncOffline=true`, item);
};

const deleteItems = async (_requestName: string, ids: string[]) => {
  let requestName = _requestName;

  if (requestName === "sub-items") {
    requestName = "document-items/sub-items";
  }

  return axiosECS.delete(`v2/${requestName}?syncOffline=true`, {
    data: { ids },
  });
};

const patchItem = async (_requestName: string, item: any) => {
  let requestName = _requestName;

  if (requestName === "sub-items") {
    requestName = "document-items/sub-items";
  }

  return axiosECS.patch(`v2/${requestName}?syncOffline=true`, item);
};

export const syncCachedDataToOnline = async () => {
  const indexedDb = await getIndexedDb();
  let cachedData: (iCachedItem & { store: string })[] = [];
  for (const store of AVAILABLE_STORES) {
    const data: iCachedItem[] = await indexedDb?.getAll(store);
    cachedData.push(...data.map((item) => ({ ...item, store })));
  }

  const mapCachedData: {
    [store: string]: (iCachedItem & { store: string })[];
  } = {};
  AVAILABLE_STORES.forEach((store) => {
    mapCachedData[store] = cachedData
      .filter((item) => item.store === store)
      .sort((a, b) => a.requestTime - b.requestTime);
  });

  cachedData = Object.values(mapCachedData).flat(1);
  let tempIdx = -1;

  const idsToDelete: { [key: string]: string[] } = {};

  for await (const item of cachedData) {
    const { status, data, operation, store, initData } = item;
    if (status !== UpdateToOnlineStatus.Fail) {
      continue;
    }

    if (operation === Operation.Delete) {
      if (idsToDelete[store]) {
        idsToDelete[store].push(data);
      } else {
        idsToDelete[store] = [data];
      }
    }

    if (operation === Operation.Put || data.offlineId) {
      const itemRes = await createUpdateItems(store, data);
      if (!itemRes?.data?.id) {
        await indexedDb?.delete(data.id);
      }
    }

    if (operation === Operation.Patch && !data.offlineId) {
      data.initData = initData;

      const pathRes = await patchItem(store, data);
      if (!pathRes?.data?.id) {
        await indexedDb?.delete(data.id);
      }
    }
  }

  await Promise.all(
    Object.keys(idsToDelete).map((key) => {
      return deleteItems(key, idsToDelete[key]);
    })
  );
};

export const syncCachedFilesToOnline = async () => {
  const indexedDb = await getIndexedDb();
  const cachedFiles: iCachedItem[] = await indexedDb.getAllFiles();

  const itemsToPut = cachedFiles
    .filter(
      (item) =>
        item.status === UpdateToOnlineStatus.Fail &&
        item.operation === Operation.Put
    )
    .sort((a, b) => a.requestTime - b.requestTime)
    .map((item) => item.data);

  const itemIdsToDelete = cachedFiles
    .filter(
      (item) =>
        item.status === UpdateToOnlineStatus.Fail &&
        item.operation === Operation.Delete
    )
    .map((item) => item.data!);

  const result: Promise<any>[] = [];
  if (itemsToPut.length) {
    itemsToPut.forEach((item) => {
      const promise = (async () => {
        const data = await s3Api.presignedUrl(item);
        const presignedUrl = data?.data;
        if (!presignedUrl) {
          return;
        }
        let finalUrl = `${process.env.REACT_APP_S3_URL}/${item.filePath}/${item.fileName}`;
        if (!item.filePath) {
          finalUrl = `${process.env.REACT_APP_S3_URL}/${item.fileName}`;
        }
        const cache = await caches.open(STATIC_FILES_CACHE);
        const request = new Request(finalUrl, {
          method: "GET",
        });
        const file = await (
          await cache.match(request, {
            ignoreMethod: true,
            ignoreSearch: true,
            ignoreVary: true,
          })
        )?.blob();

        if (!file) {
          return;
        }

        return await s3Api
          .uploadToS3({ presignedUrl, file })
          .then(() =>
            indexedDb?.deleteFile(`${item.filePath}/${item.fileName}`)
          );
      })();
      if (!!promise) {
        result.push(promise);
      }
    });
  }

  if (itemIdsToDelete.length) {
    result.push(
      s3Api.deleteFiles(itemIdsToDelete).then(() => {
        const deleteCacheRequests: Promise<any>[] = [];
        if (indexedDb) {
          for (let i = 0; i < itemIdsToDelete.length; i++) {
            deleteCacheRequests.push(indexedDb.deleteFile(itemIdsToDelete[i]));
          }
        }

        return Promise.all(deleteCacheRequests);
      })
    );
  }

  return await Promise.all(result);
};

export const cacheDefaultData = async () => {
  const refreshToken = await doRefreshToken();
  if (refreshToken) {
    await userApi.handleGetProfile(true);
  }
  await Promise.all([
    forgeApi.getProjects(),
    projectApi.getProjectList(),
    projectBimFileApi.getProjectList(),
  ]);
};

export const COMMON_AUTODESK_URL_TO_CACHE_FOR_CHROME = [
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/extensions/PDF/PDF.worker.min.js",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/extensions/PDF/PDF.min.js",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/extensions/PDF/PDF.min.js.map",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/extensions/PDF/PDF.worker.min.js.map",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/lmvworker.min.js",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/lmvworker.min.js.map",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/res/locales/ja/allstrings.json",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/res/locales/en/allstrings.json",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/res/ui/powered-by-autodesk-blk-rgb.png",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/extensions/AEC/AEC.min.js",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/extensions/AEC/AEC.min.js.map",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/extensions/ViewCubeUi/ViewCubeUi.min.js",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/extensions/ViewCubeUi/ViewCubeUi.min.js.map",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/extensions/MixpanelProvider/MixpanelProvider.min.js",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/extensions/MixpanelProvider/MixpanelProvider.min.js.map",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/res/locales/ja/allstrings.json",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/res/locales/en/allstrings.json",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/res/locales/ja/VCcrossRGBA8small.dds",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/res/textures/VCedge1.png",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/res/textures/VChome.png",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/res/textures/VCarrows.png",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/res/textures/VCcontext.png",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/res/textures/VChomeS.png",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/res/textures/VCarrowsS0.png",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/res/textures/VCarrowsS1.png",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/res/textures/VCcontextS.png",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/extensions/MemoryLimited/MemoryLimited.min.js",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/extensions/MemoryLimited/MemoryLimited.min.js.map",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/res/environments/boardwalk_irr.logluv.dds",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/res/environments/boardwalk_mipdrop.logluv.dds",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/res/textures/VCcompass-pointer-b.png",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/res/textures/VCcompass-base.png",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/extensions/Section/Section.min.js",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/extensions/Section/Section.min.js.map",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/extensions/LayerManager/LayerManager.min.js",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/extensions/LayerManager/LayerManager.min.js.map",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/extensions/BoxSelection/BoxSelection.min.js",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/extensions/BoxSelection/BoxSelection.min.js.map",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/extensions/CompGeom/CompGeom.min.js",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/extensions/CompGeom/CompGeom.min.js.map",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/extensions/MaterialConverterPrism/MaterialConverterPrism.min.js",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/extensions/MaterialConverterPrism/MaterialConverterPrism.min.js.map",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/res/fonts/tinos-v24-latin_latin-ext-regular.woff",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/res/fonts/cousine-v27-latin_latin-ext-regular.woff",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/res/fonts/arimo-v29-latin_latin-ext-regular.woff",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/res/fonts/FoxitDingbats.woff",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/res/fonts/FoxitSymbol.woff",
  "https://fonts.autodesk.com/ArtifaktElement/WOFF2/Artifakt%20Element%20Bold.woff2",
  "https://fonts.autodesk.com/ArtifaktElement/WOFF2/Artifakt%20Element%20Book.woff2",
  "https://fonts.autodesk.com/ArtifaktElement/WOFF/Artifakt%20Element%20Bold.woff",
  "https://fonts.autodesk.com/ArtifaktElement/WOFF/Artifakt%20Element%20Book.woff",
  "https://fonts.autodesk.com/ArtifaktElement/TTF/Artifakt%20Element%20Bold.ttf",
  "https://fonts.autodesk.com/ArtifaktElement/TTF/Artifakt%20Element%20Book.ttf",
  "https://fonts.autodesk.com/ArtifaktElement/WOFF2/Artifakt%20Element%20Light.woff2",
  "https://fonts.autodesk.com/ArtifaktElement/WOFF/Artifakt%20Element%20Light.woff",
  "https://fonts.autodesk.com/ArtifaktElement/TTF/Artifakt%20Element%20Light.ttf",
  "https://fonts.autodesk.com/ArtifaktElement/WOFF/Artifakt%20Element%20Regular.woff",
  "https://fonts.autodesk.com/ArtifaktElement/TTF/Artifakt%20Element%20Regular.ttf",
];

export const COMMON_AUTODESK_URL_TO_CACHE_FOR_SAFARI = [
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/extensions/PDF/PDF.worker.min.js",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/extensions/PDF/PDF.min.js",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/extensions/PDF/PDF.min.js.map",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/extensions/PDF/PDF.worker.min.js.map",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/lmvworker.min.js",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/lmvworker.min.js.map",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/res/locales/ja/allstrings.json",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/res/locales/en/allstrings.json",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/res/ui/powered-by-autodesk-blk-rgb.png",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/extensions/AEC/AEC.min.js",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/extensions/AEC/AEC.min.js.map",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/extensions/ViewCubeUi/ViewCubeUi.min.js",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/extensions/ViewCubeUi/ViewCubeUi.min.js.map",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/extensions/MixpanelProvider/MixpanelProvider.min.js",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/extensions/MixpanelProvider/MixpanelProvider.min.js.map",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/res/locales/ja/allstrings.json",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/res/locales/en/allstrings.json",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/res/locales/ja/VCcrossRGBA8small.dds",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/res/textures/VCedge1.png",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/res/textures/VChome.png",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/res/textures/VCarrows.png",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/res/textures/VCcontext.png",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/res/textures/VChomeS.png",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/res/textures/VCarrowsS0.png",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/res/textures/VCarrowsS1.png",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/res/textures/VCcontextS.png",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/extensions/MemoryLimited/MemoryLimited.min.js",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/extensions/MemoryLimited/MemoryLimited.min.js.map",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/res/environments/boardwalk_irr.logluv.dds",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/res/environments/boardwalk_mipdrop.logluv.dds",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/res/textures/VCcompass-pointer-b.png",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/res/textures/VCcompass-base.png",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/extensions/Section/Section.min.js",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/extensions/Section/Section.min.js.map",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/extensions/LayerManager/LayerManager.min.js",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/extensions/LayerManager/LayerManager.min.js.map",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/extensions/BoxSelection/BoxSelection.min.js",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/extensions/BoxSelection/BoxSelection.min.js.map",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/extensions/CompGeom/CompGeom.min.js",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/extensions/CompGeom/CompGeom.min.js.map",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/extensions/MaterialConverterPrism/MaterialConverterPrism.min.js",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/extensions/MaterialConverterPrism/MaterialConverterPrism.min.js.map",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/res/fonts/tinos-v24-latin_latin-ext-regular.woff",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/res/fonts/cousine-v27-latin_latin-ext-regular.woff",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/res/fonts/arimo-v29-latin_latin-ext-regular.woff",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/res/fonts/FoxitDingbats.woff",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/res/fonts/FoxitSymbol.woff",
  "https://fonts.autodesk.com/ArtifaktElement/WOFF2/Artifakt%20Element%20Bold.woff2",
  "https://fonts.autodesk.com/ArtifaktElement/WOFF2/Artifakt%20Element%20Book.woff2",
  "https://fonts.autodesk.com/ArtifaktElement/WOFF/Artifakt%20Element%20Bold.woff",
  "https://fonts.autodesk.com/ArtifaktElement/WOFF/Artifakt%20Element%20Book.woff",
  "https://fonts.autodesk.com/ArtifaktElement/TTF/Artifakt%20Element%20Bold.ttf",
  "https://fonts.autodesk.com/ArtifaktElement/TTF/Artifakt%20Element%20Book.ttf",
  "https://fonts.autodesk.com/ArtifaktElement/WOFF2/Artifakt%20Element%20Light.woff2",
  "https://fonts.autodesk.com/ArtifaktElement/WOFF/Artifakt%20Element%20Light.woff",
  "https://fonts.autodesk.com/ArtifaktElement/TTF/Artifakt%20Element%20Light.ttf",
  "https://fonts.autodesk.com/ArtifaktElement/WOFF/Artifakt%20Element%20Regular.woff",
  "https://fonts.autodesk.com/ArtifaktElement/TTF/Artifakt%20Element%20Regular.ttf",
];

const AUTODESK_DB_RESOURCE = [
  "objects_ids.json.gz",
  "objects_attrs.json.gz",
  "objects_avs.json.gz",
  "objects_vals.json.gz",
  "objects_offs.json.gz",
  "objects_viewables.json.gz",
];

const AUTODESK_F2D_RESOURCE = ["manifest.json.gz", "metadata.json.gz"];

const TINY_CLOUD_RESOURCE = [
  "/tinymce/6.4.2-17/tinymce.min.js",
  "/tinymce/6.4.2-17/plugins/autoresize/plugin.min.js",
  "/tinymce/6.4.2-17/themes/silver/theme.min.js",
  "/tinymce/6.4.2-17/models/dom/model.min.js",
  "/tinymce/6.4.2-17/plugins/link/plugin.min.js",
  "/tinymce/6.4.2-17/skins/ui/oxide/skin.min.css",
  "/tinymce/6.4.2-17/icons/default/icons.min.js",
  "/tinymce/6.4.2-17/skins/ui/oxide/content.min.css",
];

export const getUrlsOfModel = async (project: DataProjectModel) => {
  const credentials = await getForgeToken();
  const bimFileId = project.defaultBimPathId?.split("/").pop() || "";
  if (!bimFileId) {
    return [] as string[];
  }
  const base64BimUrn = base64.encode(decodeURIComponent(bimFileId));
  const encodedOrigin = encodeURIComponent(document.location.origin);
  const levelData = project?.levelData || {};
  const levels = (
    Object.values(levelData).map((value) => value) as Level[]
  ).filter((level) => level.guid !== LEVEL_OTHER_ID && !!level.guid);
  const levelsNotSheets = levels.filter((level) => !level.sheets?.length);
  const sheets = levels
    .map((level) => level.sheets?.map((sheet) => [sheet.guid, sheet.name]))
    .flat(2)
    .filter((item) => !!item);
  const keysToCache = sheets.concat(
    levelsNotSheets
      .map((level) => [
        level.guid,
        level.label,
        encodeURIComponent(level.label || ""),
      ])
      .flat(1)
  );
  const urls: string[] = [];

  urls.push(
    `https://cdn.derivative.autodesk.com/derivativeservice/v2/manifest/${encodeURIComponent(
      base64BimUrn
    )}?domain=${encodedOrigin}`
  );
  const manifest = await (
    await retryFetch(
      `https://developer.api.autodesk.com/modelderivative/v2/designdata/${base64BimUrn.replace(
        "/",
        "_"
      )}/manifest`,
      { headers: { Authorization: `Bearer ${credentials.accessToken}` } }
    )
  )?.json();

  if (!manifest) {
    throw new Error(FETCH_FAILED);
  }

  const getUrns = async (data: any) => {
    const decoder = new TextDecoder();
    const zip = new JSZip();

    if (!data) {
      return;
    }

    if (
      data?.type === ItemBIMType.GEOMETRY &&
      data?.hasThumbnail === "true" &&
      ["3d", "2d"].includes(data?.role) &&
      !keysToCache.includes(data?.guid || "")
    ) {
      return;
    }

    const requests: Promise<any>[] = [];
    if (data.derivatives) {
      data.derivatives.forEach((childObj: any) =>
        requests.push(getUrns(childObj))
      );

      return await Promise.all(requests);
    }

    if (data.urn) {
      const basePath = `${data.urn.slice(0, data.urn.lastIndexOf("/"))}`;
      switch (data.mime) {
        case "application/autodesk-db":
          AUTODESK_DB_RESOURCE.forEach((resource) => {
            const resourceUrn = `${basePath}/${resource}`;
            const url = `https://cdn.derivative.autodesk.com/derivativeservice/v2/derivatives/${encodeURIComponent(
              resourceUrn
            )}?domain=${encodedOrigin}`;
            urls.push(url);
          });
          break;
        case "application/autodesk-f2d":
          urls.push(
            `https://cdn.derivative.autodesk.com/derivativeservice/v2/derivatives/${encodeURIComponent(
              data.urn
            )}?domain=${encodedOrigin}`
          );

          AUTODESK_F2D_RESOURCE.forEach((resource) => {
            const resourceUrn = `${basePath}/${resource}`;
            const url = `https://cdn.derivative.autodesk.com/derivativeservice/v2/derivatives/${encodeURIComponent(
              resourceUrn
            )}?domain=${encodedOrigin}`;
            urls.push(url);
          });
          break;
        case "application/autodesk-svf":
          urls.push(
            `https://cdn.derivative.autodesk.com/derivativeservice/v2/derivatives/${encodeURIComponent(
              data.urn
            )}?domain=${encodedOrigin}`
          );

          const fetchRes = await retryFetch(
            `https://developer.api.autodesk.com/derivativeservice/v2/derivatives/${encodeURIComponent(
              data.urn
            )}`,
            {
              headers: { Authorization: `Bearer ${credentials.accessToken}` },
            }
          );

          if (!fetchRes) {
            break;
          }

          const res = await fetchRes.arrayBuffer();
          const pack = await zip.loadAsync(res, {
            checkCRC32: true,
            base64: false,
          });

          const manifestData = await pack
            .file("manifest.json")
            ?.async("arraybuffer");

          if (manifestData) {
            const manifest = JSON.parse(decoder.decode(manifestData));
            if (manifest.assets) {
              const uris = manifest.assets
                .map((asset: any) => asset.URI)
                .filter(
                  (uri: string) =>
                    uri.indexOf("embed:/") === -1 &&
                    !AUTODESK_DB_RESOURCE.some((resource) =>
                      uri.includes(resource)
                    )
                )
                .map(
                  (uri: string) =>
                    `https://cdn.derivative.autodesk.com/derivativeservice/v2/derivatives/${encodeURIComponent(
                      `${basePath}/${uri}`
                    )}?domain=${encodedOrigin}`
                );

              urls.push(...uris);
            }
          }
          break;

        case "application/json":
          if (data.urn.includes("AECModelData")) {
            const url = `https://cdn.derivative.autodesk.com/derivativeservice/v2/derivatives/${encodeURIComponent(
              data.urn
            )}?domain=${encodedOrigin}`;
            urls.push(url);
          }

          break;

        default:
          if (
            data.urn &&
            !data.urn.includes(".sdb") &&
            keysToCache.some((key) => data.urn.includes(key))
          ) {
            const url = `https://cdn.derivative.autodesk.com/derivativeservice/v2/derivatives/${encodeURIComponent(
              data.urn
            )}?domain=${encodedOrigin}`;
            urls.push(url);
          }
          break;
      }
    }

    if (data.children) {
      data.children.forEach((childObj: any) =>
        requests.push(getUrns(childObj))
      );

      return await Promise.all(requests);
    }
  };

  await getUrns(manifest);

  return urls;
};

export const PROJECT_RESOURCE = {
  tinyCloud: "TinyCloud",
  modernScreenshot: "modernScreenshot",
  refreshToken: "doRefreshTokenFromPlanets",
  externalId: "getExternalId",
  forgeData: "getForgeData",
  spacesData: "getSpacesData",
  versions: "getVersions",
  blackboardTemplate: "getBlackboardTemplate",
  listUser: "getListUser",
  userSetting: "getUserSetting",
  project: "getProject",
  documentTemplate: "getAllDocumentTemplateByProject",
  taskTypes: "getTaskTypes",
  tasks: "getTasks",
  documentCategories: "getDocumentCategoriesByBimFileId",
  documentItems: "getDocumentItems",
  projectDetail: "getProjectDetail",
  documentTasks: "getDocumentTasksByBimFileId",
  taskSheetTemplates: "getTaskSheetTemplates",
  partnerCompanies: "getPartnerCompanies",
  aecData: "getAECData",
  currentUser: "currentUser",
  families: "families",
  documentGroup: "getDocumentGroup",
};

export const _currentCachingProgress: {
  [key: string]: {
    total: number;
    cached: number;
    isPause?: boolean | undefined;
  };
} = {};

export const getCacheProjectByStorage = (projectId: string) => {
  const cachedProjects: {
    [key: string]: iCachedProject;
  } = getLocalStorage(CACHED_PROJECT_INFO_KEY, {});
  const cachedProject = cachedProjects[projectId || ""];

  return { cachedProject, cachedProjects };
};

export const saveProgressToStorage = ({
  progress,
  total,
  cached,
  lastCacheTime,
  projectId,
}: {
  projectId: string;
  progress: number;
  total?: number;
  cached?: number;
  lastCacheTime?: Date;
}) => {
  if (!projectId) {
    return;
  }
  const { cachedProjects } = getCacheProjectByStorage(projectId);
  cachedProjects[projectId] = {
    total:
      typeof total === "undefined" ? cachedProjects[projectId]?.total : total,
    cached:
      typeof cached === "undefined"
        ? cachedProjects[projectId]?.cached
        : cached,
    progress: progress || 0,
    lastCacheTime,
    isPause: false,
  };
  setLocalStorage(CACHED_PROJECT_INFO_KEY, cachedProjects);
};

const progressCallback = (projectId: string) => {
  if (_currentCachingProgress[projectId]?.isPause) {
    return;
  }
  _currentCachingProgress[projectId].cached += 1;
  const cachingData = _currentCachingProgress[projectId];
  const progress = Math.floor((cachingData.cached * 100) / cachingData.total);
  if (progress >= 100) {
    store.dispatch(checkCacheProject());
    store.dispatch(setCachingProject(undefined));
    if (projectId) {
      const syncDataOption = structuredClone(
        store.getState().app.syncDataOption
      );
      const mapSyncDataTimeByProject =
        syncDataOption?.mapSyncDataTimeByProject || {};
      mapSyncDataTimeByProject[projectId] = new Date();
      store.dispatch(setSyncDataOption({ mapSyncDataTimeByProject }));
    }
  }
  saveProgressToStorage({
    progress,
    total: cachingData.total,
    cached: cachingData.cached,
    lastCacheTime: new Date(),
    projectId,
  });
  dispatchEvent({
    type: `cache-${projectId}`,
    payload: _currentCachingProgress[projectId],
  });
};

export const cacheProjectData = async (
  project: DataProjectModel,
  cachedUrls: string[]
) => {
  const urn = project.defaultBimPathId?.split("/").pop();
  const { bimFileId, version } = getBimFileInfo(urn || "");
  const cachedProjects: {
    [key: string]: iCachedProject;
  } = getLocalStorage(CACHED_PROJECT_INFO_KEY, {});
  const cachedProject = cachedProjects[project.id];

  if (!bimFileId || !version || cachedProject?.isPause) {
    return;
  }

  const levelData = (project.levelData || {}) as {
    [key: string]: Level;
  };
  const levels = Object.values(levelData).filter((level) => !!level.guid);
  const isStopCache = cachedProject?.isPause;

  const indexedDb = await getIndexedDb();
  const updateProgress = async (key: string) => {
    if (isStopCache) {
      return;
    }

    const keys: string[] = (await indexedDb.getProjectDataCacheProgress())
      .filter((item: any) => item.projectId === project.id)
      .map((item: any) => item.key);
    if (keys.includes(key)) {
      return;
    }

    const result = await indexedDb?.putProjectProgress(uuid(), {
      projectId: project.id,
      key,
    });
    if (result) {
      progressCallback(project.id);
    }

    return;
  };

  if (!cachedUrls.includes(PROJECT_RESOURCE.tinyCloud)) {
    const requests = TINY_CLOUD_RESOURCE.map((resouce) =>
      retryFetch(
        `https://cdn.tiny.cloud/1/${process.env.REACT_APP_TINY_MCE_KEY}${resouce}`
      )
    );

    await Promise.all(requests);
    await updateProgress(PROJECT_RESOURCE.tinyCloud);
  }

  if (!cachedUrls.includes(PROJECT_RESOURCE.refreshToken)) {
    await doRefreshToken();
    await updateProgress(PROJECT_RESOURCE.refreshToken);
  }

  if (!cachedUrls.includes(PROJECT_RESOURCE.currentUser)) {
    const res = await authApi.getCurrentUser(true);
    if (!!res) {
      await updateProgress(PROJECT_RESOURCE.currentUser);
    }
  }

  if (!cachedUrls.includes(PROJECT_RESOURCE.externalId)) {
    const res = await getExternalId(
      decodeURIComponent(bimFileId!),
      version || "",
      true
    );
    if (res) {
      await updateProgress(PROJECT_RESOURCE.externalId);
    }
  }

  if (!cachedUrls.includes(PROJECT_RESOURCE.forgeData)) {
    const res = await getFamilyInstancesFromS3({
      bimFileId,
      version,
      shouldCache: true,
    });
    if (res) {
      await updateProgress(PROJECT_RESOURCE.forgeData);
    }
  }

  if (!cachedUrls.includes(PROJECT_RESOURCE.spacesData)) {
    const res = await getSpacesFromS3({
      bimFileId,
      version,
      shouldCache: true,
    });
    if (res) {
      await updateProgress(PROJECT_RESOURCE.spacesData);
    }
  }

  if (!cachedUrls.includes(PROJECT_RESOURCE.aecData)) {
    const res = await getAECData(`${bimFileId}?version=${version}`, true);
    if (res) {
      await updateProgress(PROJECT_RESOURCE.aecData);
    }
  }

  if (!cachedUrls.includes(PROJECT_RESOURCE.modernScreenshot)) {
    const res = await retryFetch(
      `${process.env.PUBLIC_URL}/modern-screenshot@4.4.31_dist_worker.js`
    );
    if (res) {
      await updateProgress(PROJECT_RESOURCE.modernScreenshot);
    }
  }

  if (!cachedUrls.includes(PROJECT_RESOURCE.versions)) {
    const { data: res } = await forgeApi.getVersionsByBimFileId({
      projectId: project?.projectId,
      bimFileId: bimFileId.replace("fs.file:vf.", "dm.lineage:"),
      shouldCache: true,
    });

    if (res) {
      await updateProgress(PROJECT_RESOURCE.versions);
    }
  }

  if (!cachedUrls.includes(PROJECT_RESOURCE.families)) {
    const res = await familyApi.getFamilyList(true);
    if (res) {
      await updateProgress(PROJECT_RESOURCE.families);
    }
  }

  if (!cachedUrls.includes(PROJECT_RESOURCE.tasks)) {
    for (const level of levels) {
      await taskApi.handleGetTasks({
        bimFileId,
        shouldCache: true,
        level: level.label,
      });
    }

    await updateProgress(PROJECT_RESOURCE.tasks);
  }

  if (!cachedUrls.includes(PROJECT_RESOURCE.taskTypes)) {
    const res = await taskTypeApi.handleGetTaskTypes({ shouldCache: true });
    if (res) {
      await updateProgress(PROJECT_RESOURCE.taskTypes);
    }
  }

  if (!cachedUrls.includes(PROJECT_RESOURCE.documentCategories)) {
    for (const level of levels) {
      await documentCategoryApi.handleGetCategoryList({
        bimFileId,
        level: level.label,
        shouldCache: true,
      });
    }

    await updateProgress(PROJECT_RESOURCE.documentCategories);
  }

  if (!cachedUrls.includes(PROJECT_RESOURCE.documentItems)) {
    for (const level of levels) {
      await documentItemApi.handleGetItemList({
        bimFileId,
        level: level.label,
        shouldCache: true,
      });
    }
    await updateProgress(PROJECT_RESOURCE.documentItems);
  }

  if (!cachedUrls.includes(PROJECT_RESOURCE.blackboardTemplate)) {
    const res = await blackboardTemplateApi.getTemplateList(true);
    if (res) {
      await updateProgress(PROJECT_RESOURCE.blackboardTemplate);
    }
  }

  if (!cachedUrls.includes(PROJECT_RESOURCE.listUser)) {
    const res = await getListUser(true);
    if (res.length) {
      await updateProgress(PROJECT_RESOURCE.listUser);
    }
  }

  if (!cachedUrls.includes(PROJECT_RESOURCE.userSetting)) {
    const res = await userSettingApi.getUserSetting(true);
    if (res) {
      await updateProgress(PROJECT_RESOURCE.userSetting);
    }
  }

  if (!cachedUrls.includes(PROJECT_RESOURCE.project)) {
    await forgeApi.getProjects();
    await projectBimFileApi.getProject(project.id, true);
    await updateProgress(PROJECT_RESOURCE.project);
  }

  if (!cachedUrls.includes(PROJECT_RESOURCE.documentTemplate)) {
    const res = await Promise.all([
      documentTemplateApi.handleGetTemplateList({
        bimFileId: project.bimFileId,
        isActive: true,
        shouldCache: true,
      }),
    ]);
    if (res) {
      await updateProgress(PROJECT_RESOURCE.documentTemplate);
    }
  }

  if (!cachedUrls.includes(PROJECT_RESOURCE.taskSheetTemplates)) {
    const res = await taskSheetTemplateApi.getTemplateList({
      shouldCache: true,
    });
    if (res) {
      await updateProgress(PROJECT_RESOURCE.taskSheetTemplates);
    }
  }

  if (!cachedUrls.includes(PROJECT_RESOURCE.projectDetail)) {
    const { data: res } = await projectBimFileApi.getProject(project.id, true);

    if (res) {
      await updateProgress(PROJECT_RESOURCE.projectDetail);
    }
  }

  if (!cachedUrls.includes(PROJECT_RESOURCE.documentTasks)) {
    const res = await documentTaskApi.getDocumentTasksByBimFileId({
      bimFileId,
      shouldCache: true,
    });
    if (res) {
      await updateProgress(PROJECT_RESOURCE.documentTasks);
    }
  }

  if (!cachedUrls.includes(PROJECT_RESOURCE.partnerCompanies)) {
    const res = await partnerCompanyApi.getPartnerList({
      projectBimFileId: project.id,
      shouldCache: true,
    });
    if (res) {
      await updateProgress(PROJECT_RESOURCE.partnerCompanies);
    }
  }

  if (!cachedUrls.includes(PROJECT_RESOURCE.documentGroup)) {
    const res = await documentGroupApi.getGroupsList({
      projectBimFileId: project.id,
      shouldCache: true,
    });
    if (res) {
      await updateProgress(PROJECT_RESOURCE.documentGroup);
    }
  }

  return true;
};

const addRequestToCache = async (url: string, res: Response) => {
  const cache = await caches.open(API_GET_CACHE);
  const cacheKey = new Request(url);
  await cache.put(cacheKey, res);
};

export const cacheCommonModelData = async (
  project: DataProjectModel,
  cachedUrls: string[],
  isSafari: boolean
) => {
  const commonUrls = isSafari
    ? COMMON_AUTODESK_URL_TO_CACHE_FOR_SAFARI
    : COMMON_AUTODESK_URL_TO_CACHE_FOR_CHROME;
  // cache common autodesk resource
  await Promise.all(
    commonUrls
      .filter((url) => !cachedUrls.includes(url))
      .map((url) =>
        retryFetch(url).then((res) => {
          if (res && res?.status !== 500) {
            return Promise.all([
              addRequestToCache(url, res),
              progressCallback(project.id),
            ]);
          }
        })
      )
  );
};

export const cacheModelData = async (
  project: DataProjectModel,
  cachedUrls: string[]
) => {
  // cache model
  const credentials = await getForgeToken();
  const modelUrls = await getUrlsOfModel(project);
  const urlsToCache = modelUrls.filter((url) => !cachedUrls.includes(url));
  let requests: Promise<any>[] = [];
  const cachedProjects: {
    [key: string]: iCachedProject;
  } = getLocalStorage(CACHED_PROJECT_INFO_KEY, {});
  const cachedProject = cachedProjects?.[project.id || ""];

  for (let i = 0; i < urlsToCache.length; i++) {
    const url = urlsToCache[i];
    requests.push(
      retryFetch(
        `${url}`,
        {
          headers: { Authorization: `Bearer ${credentials.accessToken}` },
        },
        3,
        project.id
      ).then((res) => {
        if (res?.ok) {
          progressCallback(project.id);
          addRequestToCache(url, res);
        }
      })
    );

    if (cachedProject?.isPause) {
      break;
    }

    if (requests.length >= 10) {
      await Promise.all(requests);
      await sleep(50);
      requests = [];
    }
  }
};

export const getCachedUrls = async (
  projectId: string,
  urlsOfModel: string[]
) => {
  const cachedModelData: string[] = [];
  const cacheNames = await caches.keys();
  let allUrlCached: string[] = [];
  for (let i = 0; i < cacheNames.length; i++) {
    const cache = await caches.open(cacheNames[i]);
    allUrlCached = allUrlCached.concat(
      (await cache.keys()).map((item) => item.url)
    );
  }

  for (let i = 0; i < urlsOfModel.length; i++) {
    const url = urlsOfModel[i];
    if (allUrlCached.includes(url)) {
      cachedModelData.push(url);
    }
  }

  const indexedDb = await getIndexedDb();
  const cachedProjectData = (await indexedDb.getProjectDataCacheProgress())
    .filter((item: any) => item.projectId === projectId)
    .map((item: any) => item.key);

  return { cachedModelData, cachedProjectData };
};

export const deleteModelCache = async (urlsOfModel: string[]) => {
  const cacheNames = await caches.keys();
  const deleteRequests: Promise<any>[] = [];
  for (let i = 0; i < cacheNames.length; i++) {
    const cache = await caches.open(cacheNames[i]);
    (await cache.keys()).forEach((item) => {
      if (urlsOfModel.includes(item.url)) {
        deleteRequests.push(cache.delete(item));
      }
    });
  }
  await Promise.all(deleteRequests);
};

export const deleteProjectCache = async (projectId: string) => {
  const indexedDb = await getIndexedDb();

  return (await indexedDb?.deleteProjectProgress(projectId)) || 0;
};

export const getCacheProgress = async (
  project: DataProjectModel,
  isSafari: boolean
) => {
  const commonUrls = isSafari
    ? COMMON_AUTODESK_URL_TO_CACHE_FOR_SAFARI
    : COMMON_AUTODESK_URL_TO_CACHE_FOR_CHROME;
  const modelData = [...commonUrls, ...(await getUrlsOfModel(project))];
  const projectData = [...Object.values(PROJECT_RESOURCE)];
  const totalLength = modelData.length + projectData.length;

  const { cachedModelData, cachedProjectData } = await getCachedUrls(
    project.id,
    modelData
  );
  const cachedLength = cachedModelData.length + cachedProjectData.length;

  return { cachedLength, totalLength, cachedModelData, cachedProjectData };
};

export const syncDataGetS3FileSize = async ({
  fileName,
  filePath,
}: {
  fileName: string;
  filePath: string;
}) => {
  const isOnline = getNetworkStatus();
  if (!isOnline) {
    return;
  }

  const cache = await caches.open(API_GET_CACHE);
  const cacheKeys = await cache.keys();
  const request = cacheKeys.find(async (req) => {
    const url = new URL(decodeURIComponent(req.url));
    const pathname = url.pathname;
    const data: { fileName: string; filePath: string } = JSON.parse(
      url.searchParams.get("data") || "{}"
    );
    const isGetS3FileSize = pathname.includes("get-s3-file-size");

    return (
      isGetS3FileSize &&
      data.filePath === filePath &&
      data.fileName === fileName
    );
  });

  if (request) {
    await cache.delete(request, {
      ignoreMethod: true,
      ignoreSearch: true,
      ignoreVary: true,
    });

    await s3Api.getS3FileSize({ fileName, filePath });
  }
};
