import { useCallback, useEffect, useRef } from 'react';
import { isVisibleInContainer } from 'utils/dom';

export const useFocus = (isSelected: boolean, parentRef?: React.MutableRefObject<HTMLElement | undefined>) => {
  const containerRef = useRef<HTMLElement>()
  const handleFocus = useCallback(() => {
    if (!containerRef.current) {
      return
    }
    if (!isVisibleInContainer( containerRef.current, parentRef?.current)) {
      containerRef.current?.scrollIntoView({
        behavior: "auto",
        block: "center",
      });
    }
  }, [])


  const onChangeRef = (e: any) => {
    if (!containerRef.current &&  e) {
      containerRef.current = e
      if (isSelected) {
        handleFocus()
      }
    }
  }

  useEffect(() => {
    if (isSelected) {
      handleFocus()
    }
  }, [isSelected])

  return {onChangeRef, containerRef}
}
