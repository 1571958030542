import { Text, TextProps } from "@chakra-ui/react";
import { memo } from "react";

interface iProps {
  title: string | React.ReactNode;
  children: React.ReactNode;
}

const SectionField = ({ title, children, ...rest }: iProps & TextProps) => {
  return (
    <>
      <Text
        mt="1.6rem"
        color="#0284C7"
        fontSize="1.4rem"
        fontWeight={700}
        lineHeight="2.2rem"
        {...rest}
      >
        {title}
      </Text>
      {children}
    </>
  );
};

export default memo(SectionField);
