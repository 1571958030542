import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputProps,
  PlacementWithLogical,
  Spinner,
  Text,
  useBoolean,
  useOutsideClick,
} from "@chakra-ui/react";
import { taskTypeApi } from "apiClient/v2";
import { DEFAULT_HEIGHT_MENU_TASK_TYPE_LIST } from "constants/document";
import { KeyBoardCode } from "constants/enum";
import { MessageType } from "constants/websocket";
import { TaskType } from "interfaces/models/taskType";
import { WSMessage } from "interfaces/models/websocket";
import { isEmpty } from "lodash";
import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store";
import { addTaskType, setTaskTypes } from "redux/taskSlice";
import { logDev } from "utils/logs";
import { fuzzySearch } from "utils/search";
import HoverBadGood from "./HoverBadGood";
import { message } from "./base";

interface Props {
  options?: TaskType[];
  value?: string | string[];
  inputProps?: InputProps;
  type?: "checkbox" | "radio";
  parentRef?: React.RefObject<HTMLDivElement>;
  placement?: PlacementWithLogical;
  tags?: string[];
  focusInput?: boolean;
  readOnly?: boolean;
  isOnline?: boolean;
  isLoading?: boolean;
  isAutoSave?: boolean;

  onChange: (value: string | string[]) => void;
  onClickLink?: (value?: string) => void;
  sendWebSocketMessage?: (message: WSMessage) => void;
  onLoading?(status: boolean): void;
}

let timeout: NodeJS.Timeout;
const ROW_HEIGHT = 30;
const MAX_DROP_DOWN_HEIGHT = 300; // 300px

const DropdownHover = React.forwardRef<any, Props>(
  (
    {
      options,
      value,
      type = "radio",
      parentRef,
      placement,
      tags,
      focusInput,
      readOnly,
      inputProps = {},
      isOnline = true,
      onChange,
      sendWebSocketMessage,
      isLoading,
      onLoading,
      isAutoSave = true,
    },
    ref
  ) => {
    const { taskTypes } = useSelector((state: RootState) => state.task);

    const inputRef = useRef<HTMLInputElement>(null);
    const dispatch = useDispatch();
    const [isOpenMenu, setOpenMenu] = useBoolean();

    const [currentTaskType, setcurrentTaskType] = useState<TaskType>();

    const [isShowHover, setHover] = useBoolean();
    const [currentHover, setCurrentHover] = useState<TaskType>();
    const [selected, setSelected] = useState<TaskType>();
    const [searchValue, setSearchValue] = useState<string>("");
    const [isValidPosition, setIsValidPosition] = useState<boolean>(true);
    const isComposingRef = useRef(false);
    const [focusItemIndex, setFocusItemIndex] = useState(-1);
    const menuRef = useRef<HTMLDivElement>(null);
    const lastSearchInputRef = useRef(
      (searchValue ?? selected?.title ?? "").trim()
    );
    const closeRef = useRef<() => void>();
    const timeFocusRef = useRef<any>();
    const boxContainerRef = useRef<HTMLDivElement>(null);
    const [scrollTop, setScrollTop] = useState(0);

    const filteredOptions = useMemo(() => {
      const selectedTaskType = options?.find((d) => d.id === value);

      return options?.filter((option) => {
        return isEmpty(selectedTaskType)
          ? !option.deletedAt
          : selectedTaskType.title !== option.title
          ? !option.deletedAt
          : true;
      });
    }, [options, value]);

    useOutsideClick({
      ref: boxContainerRef,
      enabled: isOpenMenu,
      handler: (e) => {
        const goodBadNode = document.getElementById("good-bad-item-show");
        const isNotContain =
          !menuRef.current?.contains((e as any).target) &&
          !inputRef.current?.contains((e as any).target) &&
          !goodBadNode?.contains((e as any).target);
        if (isNotContain) {
          closeRef.current?.();
        }
      },
    });

    const handleDisplayDropdown = () => {
      if (!parentRef?.current || !inputRef?.current) return;

      const heightModal = parentRef.current.getBoundingClientRect()
        .height as number;
      const menuHeight = menuRef?.current
        ? (menuRef.current.getBoundingClientRect().y as number)
        : DEFAULT_HEIGHT_MENU_TASK_TYPE_LIST;
      const yDropdown = inputRef.current?.getBoundingClientRect().y as number;

      if (yDropdown > heightModal) {
        setOpenMenu.off();

        return;
      }

      setIsValidPosition(heightModal - yDropdown > menuHeight);
    };

    useEffect(() => {
      if (focusInput) {
        inputRef.current?.focus();
      }
    }, [focusInput]);

    useEffect(() => {
      if (!parentRef?.current) return;

      parentRef.current.addEventListener("scroll", handleDisplayDropdown);

      return () => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        parentRef?.current?.removeEventListener(
          "scroll",
          handleDisplayDropdown
        );
      };
    });

    useEffect(() => {
      handleDisplayDropdown();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      const selectedTaskType = options?.find(
        (d) => d.id === value || (type === "checkbox" && d.id === value?.[0])
      );
      logDev("____TaskTypeId", value, selectedTaskType);
      setSelected(selectedTaskType);
      if (selectedTaskType) {
        setSearchValue(selectedTaskType.title);
      }
    }, [options, type, value]);

    const filter = useMemo(() => {
      const searchVal =
        (searchValue !== undefined ? lastSearchInputRef.current : undefined) ??
        selected?.title ??
        "";
      const searchValTrim = searchVal.trim();
      const results = fuzzySearch(filteredOptions ?? [], searchVal, {
        keys: ["title"],
      });
      const newResults =
        results.filter((option) => option.title?.trim() !== searchValTrim) ||
        [];
      if (searchValTrim) {
        let fullMatchingOption: TaskType | undefined = filteredOptions?.find(
          (option) => option.title.trim() === searchValTrim
        );
        if (!fullMatchingOption) {
          // create fake option
          fullMatchingOption = {
            title: searchVal,
            id: "",
            createdAt: new Date(),
          };
        }
        newResults.unshift(fullMatchingOption);
      }

      return newResults;
      // meed search value here
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filteredOptions, searchValue, selected?.title]);

    useEffect(() => {
      setScrollTop(0);
    }, [filter.length]);

    const displayInfo = useMemo(() => {
      let visibleItems = filter;
      let startIndex = 0;
      const menuHeight = Math.min(300, filter.length * ROW_HEIGHT);
      startIndex = Math.floor(scrollTop / ROW_HEIGHT);
      const endIndex = Math.min(
        startIndex + Math.ceil(menuHeight / ROW_HEIGHT),
        filter.length
      );
      visibleItems = filter.slice(startIndex, endIndex);

      return { visibleItems, startIndex };
    }, [scrollTop, filter]);

    const handleScroll = useCallback((event: any) => {
      setScrollTop(event.target.scrollTop);
    }, []);

    const onSearch: React.ChangeEventHandler<HTMLInputElement> = (ev) => {
      const value = ev.currentTarget.value;
      setSearchValue(value ?? "");
      lastSearchInputRef.current = value?.trim() || "";
      !isOpenMenu && setOpenMenu.on();
      setFocusItemIndex(lastSearchInputRef.current.trim() ? 0 : -1);
    };

    const existOption = useMemo(() => {
      const searchVal = searchValue ?? selected?.title ?? "";

      return options?.find(
        (option) => option.title.trim() === searchVal?.trim()
      );
    }, [searchValue, selected?.title, options]);

    const onAddTaskType = async (
      _searchValue = searchValue,
      isClearSearch: boolean = true
    ) => {
      logDev("__onAddTaskType", _searchValue);
      if (!_searchValue || !_searchValue.trim()) return;

      onLoading?.(true);
      const { data: results } = await taskTypeApi.getTaskTypeByTitle(
        _searchValue.trim()
      );

      const existedTaskType = results?.length ? results[0] : null;

      if (!existedTaskType) {
        const newTaskType = {
          title: _searchValue?.trim(),
          ...(tags?.length && { tags }),
          isDeleted: false,
          badImageUrl: currentTaskType?.badImageUrl,
          badDescription: currentTaskType?.badDescription,
          goodImageUrl: currentTaskType?.goodImageUrl,
          goodDescription: currentTaskType?.goodDescription,
        };

        const { data } = await taskTypeApi.createTaskType(newTaskType);
        dispatch(addTaskType(data));
        console.log(data);

        sendWebSocketMessage?.({
          type: MessageType.ADD_TASK_TYPE,
          data: { id: data?.id },
        });
        onChange(data?.id);

        onLoading?.(false);
        isClearSearch && setSearchValue("");

        return data?.id;
      }

      if (existedTaskType && (existedTaskType as TaskType)?.deletedAt) {
        const restoreTaskType: TaskType = {
          ...existedTaskType,
        };

        await taskTypeApi.updateTaskType(restoreTaskType);
        onChange(restoreTaskType?.id);
        sendWebSocketMessage?.({
          type: MessageType.UPDATE_TASK_TYPE,
          data: { id: restoreTaskType?.id },
        });
        let isFound = false;
        const clone = taskTypes.map((item) => {
          if (item.id === existedTaskType?.id) {
            isFound = true;

            return restoreTaskType;
          }

          return item;
        });

        if (!isFound) {
          clone.push(existedTaskType);
        }

        dispatch(setTaskTypes(clone));

        onLoading?.(false);
        isClearSearch && setSearchValue("");

        return restoreTaskType?.id;
      }

      onLoading?.(false);
      isClearSearch && setSearchValue("");

      return existedTaskType.id;
    };

    useImperativeHandle(
      ref,
      () => {
        return {
          onAddTaskType,
        };
      },
      [onAddTaskType]
    );

    const onClose = () => {
      if (typeof searchValue !== "undefined" && isOpenMenu && isAutoSave) {
        if (searchValue && searchValue.trim() !== selected?.title?.trim()) {
          closeModalWhenSubmitChangeData(searchValue?.trim() || "");
          handleSave(searchValue?.trim() || "");

          return;
        }

        if (!searchValue) {
          message.warning("指摘候補を入力してください。");
          if (selected) {
            setSearchValue(selected.title ?? "");
          }
        }
      }
      setOpenMenu.off();
      setHover.off();
    };

    const handleClick = () => {
      !isOpenMenu && setOpenMenu.on();
    };

    const handleSave = async (value: string) => {
      if (!value) {
        return onClose();
      }

      if (existOption && !existOption?.deletedAt) {
        const taskType: TaskType = filter.find(
          (d) => d.title.trim() === value?.trim()
        );
        taskType && onChange(taskType?.id || "");

        return;
      }

      await onAddTaskType(value, false);
    };

    const handleUpdateTaskType = async (taskType: TaskType) => {
      setcurrentTaskType(taskType);
      onLoading?.(true);
      const { data: results } = await taskTypeApi.getTaskTypeByTitle(
        taskType.title
      );
      const existedTaskType = results?.length ? results[0] : null;

      if (!existedTaskType) {
        onLoading?.(false);

        return;
      }

      const newTaskType = {
        ...existedTaskType,
        badImageUrl: taskType?.badImageUrl,
        badDescription: taskType?.badDescription,
        goodImageUrl: taskType?.goodImageUrl,
        goodDescription: taskType?.goodDescription,
      };

      await taskTypeApi.updateTaskType(newTaskType);

      onChange(existedTaskType?.id);

      const allTaskTypes = await taskTypeApi.handleGetTaskTypes({});

      const updatedTaskTypes = allTaskTypes?.map((item) => {
        if (item.id === existedTaskType?.id) {
          return newTaskType;
        }

        return item;
      });

      dispatch(setTaskTypes(updatedTaskTypes));
      onLoading?.(false);
    };

    const handleKeyDown = async (e: any) => {
      /**
       * on Chrome: Order event onComposition: Keydown -> composition start || Keydown -> composition end
       * on Safari: Order event onComposition:  composition start -> Keydown || composition end -> keyDown
       *
       */
      if (e.code !== KeyBoardCode.Enter || isComposingRef.current) {
        if (
          !isComposingRef.current &&
          (e.key === "ArrowDown" || e.key === "ArrowUp") &&
          isOpenMenu
        ) {
          const sign = e.key === "ArrowDown" ? 1 : -1;
          const maxIndex = filter.length - 1;
          let newFocusIndex = focusItemIndex + sign;
          if (newFocusIndex < 0) {
            newFocusIndex = Math.max(maxIndex, 0);
          } else if (newFocusIndex > maxIndex) {
            newFocusIndex = 0;
          }
          if (filter.length) {
            setSearchValue(filter[newFocusIndex].title ?? "");

            if (timeFocusRef.current) {
              clearTimeout(timeFocusRef.current);
            }
            timeFocusRef.current = setTimeout(() => {
              inputRef.current?.focus();
            }, 10);
          }
          setFocusItemIndex(newFocusIndex);
          const node = document.getElementById(`taskType${newFocusIndex}`);
          scrollToSelectElement(node, newFocusIndex);
        }

        return;
      }
      const value = (e.target.value as string)?.trim() || "";
      closeModalWhenSubmitChangeData(value);
      await handleSave(value);
      // must wait after create because input value can be flick
    };

    const closeModalWhenSubmitChangeData = (value: string) => {
      setOpenMenu.off();
      if (value) {
        setSelected({
          ...(selected || { id: "" }),
          title: value,
        });
      }
      setHover.off();
    };

    const handleOnSelectTask = async (task: TaskType) => {
      closeModalWhenSubmitChangeData(searchValue ?? "");
      if (task.id) {
        setSelected(task);
        setSearchValue(task.title ?? "");
        onChange(task.id);
      } else {
        await onAddTaskType(task.title, false);
      }
    };

    closeRef.current = onClose;

    const scrollToSelectElement = (
      node: HTMLElement | null,
      selectedIndex: number
    ) => {
      if (!node || !menuRef.current) {
        return;
      }
      const boundingRect = node.getBoundingClientRect();
      const elHeight = boundingRect.height;
      const scrollTop = menuRef.current.scrollTop || 0;
      const menuHeight = menuRef.current.getBoundingClientRect().height;
      const viewport = scrollTop + menuHeight;
      const elOffset = elHeight * selectedIndex;
      // up and down
      // (elOffset + elHeight) > viewport
      if (elOffset < scrollTop) {
        menuRef.current.scrollTop = elOffset;
      } else if (elOffset + elHeight > viewport) {
        if (elOffset <= menuRef.current.scrollTop + elHeight + menuHeight) {
          menuRef.current.scrollTop = menuRef.current.scrollTop + elHeight;
        } else {
          menuRef.current.scrollTop = elOffset;
        }
      }
    };

    const renderSuggestElement = (d: TaskType, newIndex: number) => {
      const onMouseEnter = () => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          setHover.on();
          setCurrentHover(d);
        }, 100);
      };

      return (
        <HoverBadGood
          key={d.id}
          isOpen={isShowHover && d.id === currentHover?.id}
          onOpen={setHover.on}
          taskType={d || currentHover || selected}
          placement={placement}
          preventOverflow={true}
          isOnline={isOnline}
          onSaveAddNew={handleUpdateTaskType}
          sendWebSocketMessage={sendWebSocketMessage}
        >
          <Box
            key={d.id}
            display="flex"
            flexDir="row"
            id={`taskType${newIndex}`}
            padding="0.5rem"
            cursor={"pointer"}
            background={d.id === currentHover?.id ? "#F0F9FF" : "white"}
            height="30px"
            _hover={{
              backgroundColor: "#F0F9FF",
            }}
            onMouseEnter={onMouseEnter}
            onClick={() => {
              if (!isShowHover || d.id !== currentHover?.id) {
                return;
              }
              handleOnSelectTask(d);
            }}
          >
            <Text
              flex={1}
              marginLeft="2.4rem"
              fontSize="1.4rem"
              fontWeight="medium"
              color="#171717"
              css={`
                text-overflow: ellipsis;
                overflow: hidden !important;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                word-break: break-all;
              `}
            >
              {d.title}
            </Text>
          </Box>
        </HoverBadGood>
      );
    };

    return (
      <Box width="100%" ref={boxContainerRef}>
        <Box position={"relative"}>
          <InputGroup>
            <Input
              ref={inputRef}
              flex={1}
              borderRadius="4px"
              pl="1.5rem"
              bg={readOnly ? "#f1f1f1" : "#FAFAFA"}
              placeholder="テキストを入力"
              onChange={onSearch}
              fontSize="1.6rem"
              height="4.4rem"
              value={searchValue}
              onFocus={setHover.off}
              onClick={handleClick}
              onKeyDown={handleKeyDown}
              onCompositionStart={() => {
                isComposingRef.current = true;
              }}
              onCompositionEnd={() => {
                setTimeout(() => {
                  isComposingRef.current = false;
                }, 50);
              }}
              readOnly={readOnly}
              cursor={readOnly ? "not-allowed" : "unset"}
              {...inputProps}
              isDisabled={inputProps?.isDisabled || isLoading}
            ></Input>
          </InputGroup>
          {isLoading && (
            <Flex
              width={"4rem"}
              alignItems={"center"}
              justifyContent={"center"}
              position={"absolute"}
              right={0}
              top={0}
              height={"100%"}
            >
              <Spinner />
            </Flex>
          )}
        </Box>

        {!readOnly && (
          <Box position={"relative"}>
            {isOpenMenu && (
              <>
                <Box
                  ref={menuRef}
                  maxH={`${MAX_DROP_DOWN_HEIGHT}px`}
                  display="block"
                  position="absolute"
                  overflowY="auto"
                  w="100%"
                  zIndex={99}
                  boxShadow="0 1px 2px 0 rgba(0, 0, 0, 0.05)"
                  paddingTop={"0.5rem"}
                  paddingBottom={"0.5rem"}
                  outlineOffset="2px"
                  outline="transparent solid 2px"
                  background="white"
                  borderRadius="0.375rem"
                  border="1px solid #E2E8F0"
                  {...(!isValidPosition && { bottom: "4.4rem" })}
                  onScroll={handleScroll}
                >
                  <Box height={`${ROW_HEIGHT * filter.length}px`}>
                    <Box
                      sx={{
                        position: "relative",
                        height: `${
                          displayInfo.visibleItems.length * ROW_HEIGHT
                        }px`,
                        top: `${displayInfo.startIndex * ROW_HEIGHT}px`,
                      }}
                    >
                      {(displayInfo.visibleItems ?? []).map((d, index) => {
                        return renderSuggestElement(d, index);
                      })}
                    </Box>
                  </Box>
                </Box>
              </>
            )}
          </Box>
        )}
      </Box>
    );
  }
);

export default DropdownHover;
