import { Box, Flex, Spinner, useBoolean } from "@chakra-ui/react";
import { Editor } from "@tinymce/tinymce-react";
import { icons } from "assets/icons/icons";
import useHandleEditorComponent, {
  getComponentTd,
} from "components/modal/PreviewDocumentCategory/PreviewComponent/hook/useHandleEditorComponent";
import { DATE_TIME_PLACE_HOLDER } from "constants/date";
import {
  documentItemProperties,
  MAX_ZOOM_VALUE,
  MIN_ZOOM_VALUE,
  STEP_ZOOM_VALUE,
} from "constants/document";
import {
  CheckboxDisplayOptionsEnum,
  KeyBoardCode,
  LinkedDataField,
  ZoomType,
} from "constants/enum";
import {
  KEYLABEL_POINT_CLASSNAME,
  KEYNOTE_IMAGE_CLASSNAME,
  MCE_EDITABLE_CLASSNAME,
} from "constants/styleProps";
import { handleMoveKeynoteElement } from "models/documentTask";
import {
  DEFAULT_FONT_SIZE,
  DEFAULT_TEXT_COLOR,
} from "pages/document/template-page/hooks";
import { useCallback, useEffect, useRef, useState } from "react";
import { Editor as iEditor } from "tinymce";
import { formatDateCurrent } from "utils/date";
import { transformSizeForTextElement } from "utils/download-pdf";
import { LINE_HEIGHT_DEFAULT } from "./modal/PreviewDocumentCategory/hooks/useHandleTextOverflow";
import DayPicker from "./ui/DayPicker";
import { iKeyLabel } from "./ui/KeyLabel";

interface Props {
  defaultValue?: string;
  disabled?: boolean;
  modeEdit?: boolean;
  editorProps?: any;
  contentCss?: string;
  isHideCursor?: boolean;
  pageZoom: number;
  zoomEditorRef: React.MutableRefObject<number>;
  isContentFromS3: boolean;
  onInit?: (editor: any) => void;
  onChange?: (value: any) => void;
  callbackDragKeynoteStop?: (props: Partial<iKeyLabel>) => void;
}

const EditorDocument = ({
  modeEdit = false,
  defaultValue,
  disabled,
  editorProps = {},
  contentCss,
  isHideCursor = false,
  isContentFromS3,
  pageZoom,
  zoomEditorRef,
  onInit,
  onChange,
  callbackDragKeynoteStop,
}: Props) => {
  const [isLoaded, setIsLoaded] = useBoolean();
  const [editorState, setEditorState] = useState<iEditor>();
  const [content, setContent] = useState(defaultValue);
  const [dataDate, setDataDate] = useState<{
    zoom: number;
    top: number;
    left: number;
    element: HTMLElement | undefined;
    date: Date | undefined;
  }>({
    zoom: 1,
    top: 0,
    left: 0,
    element: undefined,
    date: undefined,
  });
  const editorRef = useRef<iEditor>();
  const modeEditRef = useRef<boolean>(false);
  const dataInputRef = useRef<any>();
  const lastInpytTypingTextRef = useRef<string>();

  const { handleEditorDocumentTypeCheckbox, handleEditorDocumentTypeInput } =
    useHandleEditorComponent();

  useEffect(() => {
    if (!isLoaded) {
      return;
    }

    modeEditRef.current = modeEdit;
    disableFormatTextButtons();
  }, [modeEdit, isLoaded]);

  useEffect(() => {
    if (!editorState || !isLoaded) {
      return;
    }

    const body = editorState.getBody();
    const elements =
      body?.getElementsByClassName?.(MCE_EDITABLE_CLASSNAME) || [];
    const arrayEles = Array.from(elements);
    arrayEles.forEach((ele) => {
      ele.setAttribute("contenteditable", String(!!modeEdit));
    });
  }, [modeEdit, editorState, content, isLoaded]);

  // handle drag keynote
  useEffect(() => {
    const body = editorState?.getBody();
    const keynoteImageElement = editorState?.dom.get(KEYNOTE_IMAGE_CLASSNAME);
    if (
      !editorState ||
      !keynoteImageElement ||
      !body ||
      !isLoaded ||
      !modeEdit
    ) {
      return;
    }

    const elements = body.getElementsByClassName(KEYLABEL_POINT_CLASSNAME);
    const arrayEles = Array.from(elements);
    arrayEles.forEach((ele) => {
      const pointElement: HTMLElement = ele as any;
      const keylabelContentElement: HTMLElement = ele.parentElement as any;
      const lineElement: HTMLElement = keylabelContentElement
        .childNodes[0] as any;
      const iconWidth = Number(pointElement.getAttribute("data-icon-width"));
      const iconHeight = Number(pointElement.getAttribute("data-icon-height"));
      const label = pointElement.getAttribute("data-label") || "";
      const keylabelId = pointElement.getAttribute("data-keylabel-id") || "";
      const positionInit = {
        x: Number(pointElement.getAttribute("data-position-x")),
        y: Number(pointElement.getAttribute("data-position-y")),
      };
      const rotationInit = Number(lineElement.getAttribute("data-rotation"));

      handleMoveKeynoteElement({
        id: keylabelId,
        label,
        iconWidth,
        iconHeight,
        pointElement,
        lineElement,
        rotationInit,
        positionInit,
        keynoteImageElement,
        editorRef: editorRef as any,
        callbackDragStop: callbackDragKeynoteStop,
      });
    });

    const editor = editorRef.current;

    return () => {
      editor?.off("mouseup");
      editor?.off("mousemove");
      document.onmouseup = null;
      document.onmousemove = null;

      arrayEles.forEach((ele) => {
        const pointElement: HTMLElement = ele as any;
        pointElement.onmousedown = null;
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modeEdit, editorState, isLoaded]);

  useEffect(() => {
    if (!isLoaded) {
      return;
    }

    if (!modeEdit) {
      setContent(defaultValue || "");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modeEdit, defaultValue, isLoaded]);

  useEffect(() => {
    if (isContentFromS3) {
      return;
    }

    if (!modeEdit) {
      setIsLoaded.off();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modeEdit, isContentFromS3]);

  useEffect(() => {
    if (!isLoaded) {
      return;
    }

    onChange?.(content);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content, isLoaded]);

  useEffect(() => {
    if (!editorRef?.current || !isLoaded) return;

    const [div]: HTMLElement[] = editorRef?.current?.getBody()?.children as any;
    if (!div) return;

    const zoom = pageZoom / 100;

    (div.style as any).zoom = zoom;
    div.style.display = "flex";
    div.style.flexDirection = "column";
    div.style.gap = "0.5rem";
    div.style.padding = "5rem 0px";

    setDataDate((prev: any) => ({
      ...prev,
      zoom,
      element: undefined,
    }));

    div.setAttribute("data-mce-style", `zoom: ${zoom}`);

    editorRef.current?.undoManager.add();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageZoom, editorRef.current, content, isLoaded]);

  const getDataFieldType = (element: HTMLElement) => {
    try {
      return element?.getAttribute("data-field-type");
    } catch (err) {
      return "";
    }
  };

  const handleClickEditor = (e: any, editor: iEditor) => {
    lastInpytTypingTextRef.current = "";
    const target: HTMLElement = e.target;

    if (!modeEditRef.current) return;

    dataInputRef.current = documentItemProperties.find((c) =>
      target.classList.contains(c.key)
    );

    const selection = editor?.selection.getSel();

    const isFocus =
      (selection?.focusOffset === 0 &&
        selection?.focusNode?.nodeName !== "BODY") ||
      selection?.focusNode?.nodeName === "#text";

    if (isFocus) {
      enableFormatTextButtons();
    }

    focusElementCanEdit(target, editor);

    if (getDataFieldType(target) === LinkedDataField.COMMON.INPUT_DATA) {
      handleInputDataField(target);
    }

    if (target.tagName === "polygon") {
      const ele: HTMLElement = e.target?.parentNode?.parentElement;

      if (ele && getDataFieldType(ele) === LinkedDataField.COMMON.CHECK_BOX) {
        handleEditorDocumentTypeCheckbox(ele);
      }
    }

    if (target.tagName === "g") {
      const ele: HTMLElement = e.target?.parentElement;

      if (ele && getDataFieldType(ele) === LinkedDataField.COMMON.CHECK_BOX) {
        handleEditorDocumentTypeCheckbox(ele);
      }
    }

    if (getDataFieldType(target) === LinkedDataField.COMMON.CHECK_BOX) {
      handleEditorDocumentTypeCheckbox(target);
    }

    const isCheckBoxTypeOption =
      getDataFieldType(target) === `cb-type-${CheckboxDisplayOptionsEnum.Options}`;
    if (isCheckBoxTypeOption) {
      const ele: HTMLElement = target?.parentElement as any;
      if (!ele) {
        return;
      }

      const isActive = (e.target as HTMLElement).classList.contains(
        "check-box-options-active"
      );

      ele
        .querySelectorAll(
          `p[data-field-type='cb-type-${CheckboxDisplayOptionsEnum.Options}']`
        )
        .forEach((p) => {
          const elementPara: HTMLElement = p as any;

          editor?.dom.removeClass(elementPara, "check-box-options-active");
          editor?.dom.addClass(elementPara, "check-box-options-inactive");
        });

      editor?.dom.removeClass(
        editor.selection.getNode(),
        !isActive ? "check-box-options-inactive" : "check-box-options-active"
      );
      editor?.dom.addClass(
        editor.selection.getNode(),
        isActive ? "check-box-options-inactive" : "check-box-options-active"
      );

      const parentTd = getComponentTd(target);

      if (parentTd) {
        const optValue = isActive
          ? ""
          : target.getAttribute("data-option-value") || "";
        parentTd.setAttribute("data-field-content", optValue);
      }
    }
  };

  const handleClickDay = (day: Date | string) => {
    if (dataDate?.element) {
      setDataDate((prev: any) => ({ ...prev, date: day }));
      dataDate.element.innerHTML = day
        ? formatDateCurrent(day, DATE_TIME_PLACE_HOLDER)
        : "";
    }
  };

  const handleInputDataField = (element: HTMLElement) => {
    const editor = editorRef.current;
    const parentElement = element.parentElement;
    if (!parentElement || !editor) {
      return;
    }

    [
      "overflow",
      "textOverflow",
      "display",
      "webkitLineClamp",
      "webkitBoxOrient",
      "overflowWrap",
      "maxHeight",
    ].forEach((att) => (element.style[att as any] = null as any));

    const fontSizeNumber = Number(
      element.getAttribute("data-font-size") || DEFAULT_FONT_SIZE
    );
    const lineHeightNumber = fontSizeNumber * LINE_HEIGHT_DEFAULT;

    const { text, shouldUpdate } = transformSizeForTextElement({
      element,
      lastInpytTypingTextRef,
      sizeDefault: {
        fontSize: fontSizeNumber,
        lineHeight: lineHeightNumber,
      },
    });
    const color = element.getAttribute("data-text-color") || DEFAULT_TEXT_COLOR;

    if (shouldUpdate) {
      element.innerHTML = text;
    }

    if (!editorRef.current) {
      return;
    }

    element.style.color = color;
    editorRef.current.dom.setStyle(
      element,
      "font-size",
      getComputedStyle(element).fontSize
    );
    editorRef.current.dom.setStyle(element, "color", color);
    editorRef.current.dom.setStyle(
      element,
      "line-height",
      getComputedStyle(element).lineHeight
    );

    handleEditorDocumentTypeInput(element as HTMLElement);
  };

  const handleZoom = useCallback(
    (editor: any, value: ZoomType) => {
      if (!editor) return;

      const [div] = editor.getBody().children;
      let newZoom = zoomEditorRef.current;

      if (value === ZoomType.ZOOM_IN) {
        if (zoomEditorRef.current >= MAX_ZOOM_VALUE) return;
        newZoom = zoomEditorRef.current + STEP_ZOOM_VALUE;
      } else {
        if (zoomEditorRef.current <= MIN_ZOOM_VALUE) return;
        newZoom = zoomEditorRef.current - STEP_ZOOM_VALUE;
      }

      div.style.zoom = newZoom / 100;
      div.style.display = "flex";
      div.style.flexDirection = "column";
      div.style.gap = "0.5rem";
      div.style.padding = "5rem 0px";
      div.setAttribute("data-mce-style", `zoom: ${newZoom / 100}`);

      setDataDate((prev: any) => ({
        ...prev,
        zoom: newZoom / 100,
        element: undefined,
      }));

      zoomEditorRef.current = newZoom;

      editor.undoManager.add();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [zoomEditorRef.current]
  );

  const focusElementCanEdit = (element: HTMLElement, editor?: iEditor) => {
    const child: HTMLElement = element.children?.[0] as any;
    if (!editor || !child) {
      return;
    }

    if (child.classList.contains("mceEditable")) {
      editor.selection.collapse(false);
      setTimeout(() => {
        editor.selection.select(child, true);
        editor.selection.collapse(false);
      }, 50);
    } else {
      focusElementCanEdit(child, editor);
    }
  };

  const disableFormatTextButtons = () => {
    const boldButton = document.querySelectorAll('[aria-label="Bold"]')?.[0];
    const underlineButton = document.querySelectorAll(
      '[aria-label="Underline"]'
    )?.[0];

    [boldButton, underlineButton].forEach((button) => {
      if (button) {
        button.setAttribute("disabled", "true");
        button.setAttribute("style", "cursor: pointer");
        button.setAttribute(
          "onmouseover",
          "this.style.backgroundColor='#cce2fa'"
        );
        button.setAttribute(
          "onmouseout",
          "this.style.backgroundColor='#e5e5e5'"
        );
      }
    });
  };

  const enableFormatTextButtons = () => {
    const boldButton = document.querySelectorAll('[aria-label="Bold"]')?.[0];
    const underlineButton = document.querySelectorAll(
      '[aria-label="Underline"]'
    )?.[0];

    [boldButton, underlineButton].forEach((button) => {
      if (button) {
        button?.removeAttribute("disabled");
        button.removeAttribute("onmouseover");
        button.removeAttribute("onmouseout");
        button.setAttribute("style", "backgroundColor: unset");
      }
    });
  };

  if (!content) {
    return <></>;
  }

  return (
    <Box h="100%" w="100%" position="relative" zIndex="1">
      {!isLoaded && !isContentFromS3 && (
        <Flex
          position="absolute"
          width="100%"
          height="100%"
          top="50%"
          left="50%"
          zIndex="modal"
          transform="translate(-50%,-50%)"
          alignItems="center"
          justifyContent="center"
          backgroundColor="black"
        >
          <Spinner size="xl" color="white" />
        </Flex>
      )}

      <Editor
        onInit={(_, editor) => {
          setIsLoaded.on();
          editorRef.current = editor;
          setEditorState(editor);
          if (onInit) {
            onInit(editor);
          }
          disableFormatTextButtons();
        }}
        apiKey={process.env.REACT_APP_TINY_MCE_KEY}
        onEditorChange={setContent}
        value={content || ""}
        disabled={disabled}
        cloudChannel={"6.4.2-17"}
        init={{
          verify_html: false,
          noneditable_class: "mceNonEditable",
          force_br_newlines: true,
          forced_root_blocks: false,
          height: editorProps.height || 1400,
          menubar: editorProps.menubar || false,
          object_resizing: editorProps.object_resizing || false,
          statusbar: editorProps.statusbar || false,
          plugins: `link ${editorProps.plugins || ""}`,
          end_container_on_empty_block: true,
          extended_valid_elements: "*[*]",
          toolbar:
            "selection undo redo alignleft aligncenter alignright alignjustify outdent indent bold underline link zoomin zoomout",
          content_css: contentCss,
          content_style: `${editorProps.content_style || ""} ${
            isHideCursor ? "#tinymce { caret-color: transparent}" : ""
          }`,
          newline_behavior: "invert",
          init_instance_callback: function (editor) {
            editor.on("tap", (e) => {
              const target: HTMLElement = e.target as any;
              focusElementCanEdit(target, editor);
            });
            editor.on("click", (e) => {
              handleClickEditor(e, editor);
            });
            editor.on("ExecCommand", function (e) {
              const element = editor.selection.getNode();
              if (
                e.command === "mceInsertContent" &&
                element.getAttribute("data-field-type") ===
                  LinkedDataField.COMMON.INPUT_DATA
              ) {
                handleInputDataField(element);
              }
            });
            editor.on("input", function () {
              const element = editor.selection.getNode() as HTMLElement;
              const isCheckBoxTypeValue =
                element.getAttribute("data-field-type") ===
                `cb-type-${CheckboxDisplayOptionsEnum.Value}`;
              if (isCheckBoxTypeValue) {
                const parentTd = getComponentTd(element);
                if (parentTd) {
                  const optValue = element.innerText;
                  parentTd.setAttribute("data-field-content", optValue);
                }
              } else if (element?.tagName === "TD") {
                element.setAttribute("data-field-content", element.innerText);
              } else if (
                element.getAttribute("data-field-type") ===
                LinkedDataField.COMMON.INPUT_DATA
              ) {
                handleInputDataField(element);
              }
            });
            editor.on("keyup", function (e: any) {
              if (
                !modeEditRef.current ||
                dataInputRef.current?.key === "takeImageDate"
              ) {
                e.preventDefault();

                return false;
              }
            });
            editor.on("keydown", function (e: any) {
              if (
                !modeEditRef.current ||
                dataInputRef.current?.key === "takeImageDate"
              ) {
                e.preventDefault();

                return false;
              }
              const element = editor.selection.getNode();
              const isInputData =
                getDataFieldType(element) === LinkedDataField.COMMON.INPUT_DATA;
              const isCommentField =
                element.getAttribute("data-field-name") ===
                LinkedDataField.BLACKBOARD.COMMENT_MANAGE_EXECUTE;
              const parentElement = element.parentElement;
              if (!parentElement) {
                return;
              }
              const childNodes: ChildNode[] = [];
              for (const node of parentElement.childNodes) {
                const ele: HTMLElement = node as any;
                if (ele.tagName !== "BR") {
                  childNodes.push(node);
                }
              }

              if (
                isCommentField &&
                childNodes.length > 1 &&
                (element?.textContent || "")?.length <= 1
              ) {
                element.remove();
                const childs = parentElement.childNodes;
                const ele: HTMLElement = childs[
                  parentElement.childNodes.length - 1
                ] as any;
                editor.selection.collapse(false);
                setTimeout(() => {
                  editor.selection.select(ele, true);
                  editor.selection.collapse(false);
                }, 50);

                return;
              }
              if (
                (element?.textContent || "")?.length <= 1 &&
                isInputData &&
                [
                  KeyBoardCode.Delete as string,
                  KeyBoardCode.Backspace as string,
                ].includes(e.code)
              ) {
                e.preventDefault();
                $(element).html("&nbsp");

                return false;
              }
            });
            editor.on("keypress", function (e: any) {
              if (
                !modeEditRef.current ||
                dataInputRef.current?.key === "takeImageDate"
              ) {
                e.preventDefault();

                return false;
              }
            });
          },
          setup: (editor) => {
            editor.ui.registry.addToggleButton("selection", {
              icon: "selection",
              onAction: () => {
                // TODO:
              },
            });
            editor.ui.registry.addIcon("selection", icons.selection);
            editor.ui.registry.addIcon("bold", icons.bold);
            editor.ui.registry.addIcon("underline", icons.underline);
            editor.ui.registry.addIcon("link", icons.link);
            editor.ui.registry.addButton(ZoomType.ZOOM_IN, {
              icon: "zoom-in",
              onAction: () => handleZoom(editor, ZoomType.ZOOM_IN),
            });
            editor.ui.registry.addButton(ZoomType.ZOOM_OUT, {
              icon: "zoom-out",
              onAction: () => handleZoom(editor, ZoomType.ZOOM_OUT),
            });
          },
        }}
      />
      {dataDate.element && (
        <Box
          position="absolute"
          border="1px solid var(--primary-border-color)"
          bgColor="#fff"
          borderRadius="4px"
          top={(100 + dataDate.top) * dataDate.zoom}
          left={dataDate.left * dataDate.zoom}
        >
          <DayPicker
            value={dataDate.date}
            onSelectedDay={handleClickDay}
            onClose={() => {}}
          />
        </Box>
      )}
    </Box>
  );
};

export default EditorDocument;
