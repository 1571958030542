import { documentCategoryApi } from "apiClient/v2";
import { DocumentCategoryDTO } from "interfaces/dtos/documentCategoryDTO";
import { DocumentItemDTO } from "interfaces/dtos/documentItemDTO";
import { TypeHandleInitData } from "pages/forge-viewer/hooks/useSupportSyncDataOffline";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { updateDocumentCategory } from "redux/documentSlice";
import store from "redux/store";
import { logDev } from "utils/logs";
import { transformBodyForCombineData } from "utils/offline";

const useVisibleDocumentItem = () => {
  const dispatch = useDispatch();

  const onToggleVisibleDocumentItem = useCallback(
    async (event: any, documentItem: DocumentItemDTO) => {
      event.preventDefault();
      event.stopPropagation();
      const documentItemId = documentItem?.id;
      const now = new Date();

      if (!documentItemId) {
        return;
      }
      const state = store.getState().document;
      const documentCategory = state.documentCategories.find(
        (item) => item.id === documentItem.documentCategoryId
      );
      if (!documentCategory) {
        return;
      }
      let isVisible = false;
      try {
        const newSelectedExternalIds =
          (documentCategory.selectedExternalIds ?? []).filter(
            (externalId) => externalId !== documentItemId
          ) || [];
        if (
          newSelectedExternalIds?.length ===
          documentCategory.selectedExternalIds?.length
        ) {
          isVisible = true;
          newSelectedExternalIds.push(documentItemId);
        }
        dispatch(
          updateDocumentCategory({
            ...documentCategory,
            selectedExternalIds: newSelectedExternalIds,
            updatedAt: now,
          })
        );

        await documentCategoryApi.updateCategory(
          transformBodyForCombineData({
            body: {
              id: documentItem.documentCategoryId!,
              selectedExternalIds: newSelectedExternalIds,
              updatedAt: now,
            } as DocumentCategoryDTO,
            bodyBefore: documentCategory,
            typeInitData: TypeHandleInitData.DOCUMENT_CATEGORY,
          })
        );
      } catch (error) {
        logDev("🚀 ~ onUnVisible ~ error:", error);
        // revert if error
        const state = store.getState();
        const documentCategories = state.document.documentCategories;
        const currentCategory = documentCategories.find(
          (cate) => cate.id === documentCategory.id
        );
        if (!currentCategory) {
          return;
        }
        let newSelectedExternalIds: string[] = [];
        if (isVisible) {
          newSelectedExternalIds =
            currentCategory.selectedExternalIds?.filter(
              (id) => id !== documentItem.id
            ) || [];
        } else {
          newSelectedExternalIds = [
            ...(currentCategory.selectedExternalIds || []),
            documentItem.id,
          ];
        }
        dispatch(
          updateDocumentCategory({
            ...currentCategory,
            selectedExternalIds: newSelectedExternalIds,
          })
        );
      } finally {
        // setLoading(false);
      }
    },
    [dispatch]
  );

  return {
    onToggleVisibleDocumentItem,
  };
};

export default useVisibleDocumentItem;
