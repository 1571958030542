import {
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputProps,
  NumberInputStepper,
  Text,
} from "@chakra-ui/react";
import { message } from "components/base";
import { SvgIcon } from "components/SvgIcon";
import { NUMBER_REGEX } from "constants/regex";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";

interface Props {
  unit?: string;
  initValue: string;
  name?: string;
  borderRadius?: string;
  textAlign?: string;
  title?: string;
  delayTyping?: number;
  placeHolder?: string;
  disableTyping?: boolean;
  messageMaxNumber?: string;
  messageMinNumber?: string;

  onChangeStyle?: (
    prevValue: string,
    valueNumber: number,
    name?: string,
    inputType?: InputType
  ) => void;
}

export type InputType = "typing" | "click";
export interface iNumberInputFormatHandle {
  setValue: React.Dispatch<React.SetStateAction<string>>;
  value: string;
}

const MIN_VALUE = 1;

let changeStyleTimeout: NodeJS.Timeout;
let showMaxNumberMessageTimeout: NodeJS.Timeout;
let showMinNumberMessageTimeout: NodeJS.Timeout;

function NumberInputFormat(
  {
    unit = "",
    initValue,
    name,
    borderRadius = "4px",
    textAlign = "center",
    title,
    delayTyping,
    placeHolder,
    disableTyping = false,
    messageMaxNumber,
    messageMinNumber,
    onBlur,
    onChangeStyle,
    ...props
  }: Props & NumberInputProps,
  ref: React.ForwardedRef<iNumberInputFormatHandle>
) {
  const [value, setValue] = React.useState(initValue);

  const formatValue = (val: string | number) => `${val}${unit}`;
  const parseValue = (val: string) => val.replace(/^\$/, "");
  const prevValueRef = useRef<number | undefined>(Number(initValue));

  const handleChange = (valueString: string, valueNumber: number) => {
    const inputType: InputType =
      Math.abs(Number(valueNumber) - Number(value)) !== 1 || value === ""
        ? "typing"
        : "click";

    if (!NUMBER_REGEX.test(valueString) && valueString !== "") {
      return;
    }

    if (valueNumber < MIN_VALUE) {
      return;
    }

    if (props.max && Number.isFinite(props.max) && valueNumber > props.max) {
      return;
    }

    clearTimeout(changeStyleTimeout);

    changeStyleTimeout = setTimeout(() => {
      onChangeStyle?.(value, valueNumber, name, inputType);
    }, delayTyping || 1);

    setValue(parseValue(valueString));
  };

  useEffect(() => {
    if (value !== initValue) {
      setValue(initValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initValue]);

  useImperativeHandle(ref, () => {
    return { setValue, value };
  });

  return (
    <NumberInput
      borderColor="#A3A3A3"
      borderRadius={borderRadius}
      onChange={handleChange}
      value={formatValue(value)}
      min={MIN_VALUE}
      h="4.8rem"
      backgroundColor="#fff"
      sx={{
        input: {
          paddingLeft: "1rem",
        },
        position: "relative",
        display: "flex",
        alignItems: "center",
      }}
      position="relative"
      {...(props.isDisabled ? { cursor: "not-allowed" } : {})}
      {...props}
    >
      {props.isDisabled ? (
        <Text
          h="100%"
          pl="1rem"
          pr="calc(var(--number-input-stepper-width) + 0.5rem);"
          display="grid"
          placeItems="center"
          fontWeight="600"
          color="#b6b6b6"
          whiteSpace="nowrap"
        >
          {formatValue(value)}
        </Text>
      ) : (
        <NumberInputField
          readOnly={disableTyping}
          h="100%"
          textAlign={textAlign}
          fontSize="1.4rem"
          fontWeight={500}
          color="#171717"
          borderRadius={`${borderRadius} !important`}
          value={formatValue(value)}
          onBlur={onBlur}
          maxLength={3}
        />
      )}

      {title && (
        <Text
          position="absolute"
          right="3.2rem"
          fontSize="1.4rem"
          fontWeight={400}
          color={props.isDisabled ? "#cccccc" : "#737373"}
          zIndex={99}
        >
          {title}
        </Text>
      )}

      {placeHolder && (
        <Text
          position="absolute"
          top="calc(50% - 10px)"
          right="3rem"
          color="#999999"
          zIndex={99}
        >
          {placeHolder}
        </Text>
      )}

      <NumberInputStepper>
        <NumberIncrementStepper
          border="1px solid var(--primary-border-color)"
          borderTop="none"
          borderRight="none"
          fontSize="1rem"
          onClick={() => {
            const prevValue =
              prevValueRef.current === undefined
                ? Number(value)
                : Number(prevValueRef.current);
            const isShowMessage =
              messageMaxNumber &&
              props.max !== undefined &&
              props.max <= prevValue;

            if (isShowMessage) {
              clearTimeout(showMaxNumberMessageTimeout);
              showMaxNumberMessageTimeout = setTimeout(() => {
                message.warning(messageMaxNumber);
              }, 300);
            }

            prevValueRef.current = Number(value);
          }}
          children={<SvgIcon src="/img/icon-arrow-up-gray.svg" />}
        />
        <NumberDecrementStepper
          border="1px solid var(--primary-border-color)"
          borderBottom="none"
          borderRight="none"
          fontSize="1rem"
          onClick={() => {
            const prevValue =
              prevValueRef.current === undefined
                ? Number(value)
                : Number(prevValueRef.current);

            const isShowMessage =
              messageMinNumber &&
              props.min !== undefined &&
              props.min >= prevValue;

            if (isShowMessage) {
              clearTimeout(showMinNumberMessageTimeout);
              showMinNumberMessageTimeout = setTimeout(() => {
                message.warning(messageMinNumber)
              }, 300);
            }

            prevValueRef.current = Number(value);
          }}
          children={<SvgIcon src="/img/icon-arrow-down-gray.svg" />}
        />
      </NumberInputStepper>
    </NumberInput>
  );
}

export default forwardRef(NumberInputFormat);
