import {
  Box,
  BoxProps,
  Checkbox,
  CheckboxGroup,
  Flex,
  FlexProps,
  Input,
  PlacementWithLogical,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverContentProps,
  PopoverTrigger,
  Portal,
  Stack,
  Text,
  useBoolean,
  useOutsideClick,
} from "@chakra-ui/react";
import { message } from "components/base";
import EmptyData from "components/EmptyData";
import { SvgIcon } from "components/SvgIcon";
import { SUB_MENU_TAG_INPUT_CLASSNAME } from "constants/styleProps";
import { listCustomTags, listOfficeTags, listSystemTags } from "constants/task";
import { flattenDeep } from "lodash";
import {
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDeviceSelectors } from "react-device-detect";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { compareArray, containsArray } from "utils/array";
import Tag from "./Tag";

interface MenuOption {
  name: string;
  value: string;
}

export interface TagsInputProps {
  name?: string;
  placeHolder?: string;
  value?: string[] | null;
  onBlurPopover?: any;
  separators?: string[];
  options: MenuOption[];
  isWithSubmenu?: boolean;
  isRevertSubmenu?: boolean;
  subMenuPlacement?: PlacementWithLogical;
  menuPlacement?: "top" | "bottom";
  allowInputText?: boolean;
  readonly?: boolean;
  triggerElement?: React.ReactElement;
  menuWidth?: string;
  isDisable?: boolean;
  zIndex?: number;
  marginTop?: string;
  popoverContentProps?: PopoverContentProps;
  padding?: string;
  isDocument?: boolean;
  flexProps?: FlexProps;
  boxProps?: BoxProps;
  subMenuHeight?: string;
  isShowIconSubMenu?: boolean;
  subMenuOffset?: [number, number];
  isShowAllMenuOption?: boolean;
  isTaskTypeForm?: boolean;

  onBlur?: (tags: string[]) => void;
  onChange?: (tags: string[]) => void;
  onMouseLeave?: (event: MouseEvent<HTMLDivElement>) => void;
  onExisting?: (tag: string) => void;
  onRemoved?: (tag: string) => void;
}

export const DEFAULT_TAGS_HEIGHT = 130;

const allMenuOption = {
  label: "全て",
  value: "all",
};

// initialize goober once
const defaultSeparators = ["Enter"];

const tagMenus = [
  {
    label: "工種",
    value: "TagType.CUSTOM",
  },
  {
    label: "検査対象抽出条件",
    value: "TagType.FAMILY",
  },
  {
    label: "系統",
    value: "TagType.SYSTEM",
  },
  {
    label: "事業所",
    value: "TagType.OFFICE",
  },
];

export const TagsInput = ({
  name,
  placeHolder,
  value = [],
  options,
  onBlurPopover,
  separators,
  onChange,
  onMouseLeave,
  onExisting,
  onRemoved,
  isWithSubmenu,
  subMenuPlacement = "right",
  allowInputText = false,
  readonly = false,
  triggerElement,
  menuPlacement = "bottom",
  menuWidth,
  flexProps,
  boxProps,
  marginTop,
  popoverContentProps,
  padding,
  isDocument = false,
  isDisable,
  onBlur,
  subMenuHeight,
  isShowIconSubMenu = true,
  subMenuOffset,
  isShowAllMenuOption,
  isTaskTypeForm = false,
}: TagsInputProps) => {
  const { families } = useSelector((state: RootState) => state.forgeViewer);
  const submenuRef = useRef<any>();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const tagInputRef = useRef<HTMLInputElement | null>(null);

  const [isRemove, setIsRemove] = useState(false);
  const [tags, setTags] = useState(value || []);
  const [isOpen, setIsOpen] = useBoolean();

  const [mapIsShowSubMenu, setMapIsShowSubMenu] = useState<{
    [key: string]: boolean;
  }>({});
  const [subMenuOptions, setSubMenuOptions] = useState<MenuOption[]>([]);
  const [{ isSafari, isMobile }] = useDeviceSelectors(
    window.navigator.userAgent
  );
  const isActiveClickOutside = useMemo(() => {
    if (!isWithSubmenu) {
      return isOpen;
    }

    const arrIsShowSubMenu = Object.values(mapIsShowSubMenu);
    const isShowSubMenu = arrIsShowSubMenu.some((e) => e);

    return isOpen && !isShowSubMenu;
  }, [isOpen, isWithSubmenu, mapIsShowSubMenu]);

  const isSelectedAll = useMemo(() => {
    const allTags = flattenDeep([
      listCustomTags.map((item) => item.value),
      listOfficeTags.map((item) => item.value),
      listSystemTags.map((item) => item.value),
      families.map((family) => family.name) || [],
    ]);

    return compareArray(allTags, tags);
  }, [families, tags]);

  const handleClickOutside = useCallback(() => {
    setMapIsShowSubMenu({} as any);

    onBlur && onBlur(tags);

    if (isOpen) {
      setIsOpen.off();
    }
    onBlurPopover && onBlurPopover();
  }, [tags, setIsOpen, onBlur, onBlurPopover, isOpen]);

  useOutsideClick({
    ref: submenuRef,
    enabled: isActiveClickOutside,
    handler: handleClickOutside,
  });

  useEffect(() => {
    if (!isRemove) {
      return;
    }

    setIsRemove(false);
    onBlur && onBlur(tags);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(tags), isRemove]);

  useEffect(() => {
    if (!isRemove) {
      setTags(value || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(value), isRemove]);

  const handleCloseSubmenu = () => {
    setMapIsShowSubMenu({} as any);
  };

  const handleOpenSubMenu = (key: string) => {
    setMapIsShowSubMenu({ [key]: true });
  };

  const handleOnKeyUp = (e: any) => {
    e.stopPropagation();

    const text = e.target.value;

    if (e.key === "Backspace" && tags.length && !text) {
      const newTags = tags.slice(0, -1);
      setTags(newTags);
      onChange && onChange(newTags);

      return;
    }

    if (text && (separators || defaultSeparators).includes(e.key)) {
      if (tags.includes(text)) {
        onExisting && onExisting(text);
        if (inputRef?.current) {
          inputRef.current.value = "";
        }
        message.warning("このタグはもう存在しています。");

        return;
      }
      const newTags = [...tags, text];
      setTags(newTags);
      onChange && onChange(newTags);

      e.target.value = "";
      e.preventDefault();
    }
  };

  const onTagRemove = (text: string) => {
    if (readonly) {
      return;
    }

    const newTags = tags.filter((tag) => tag !== text);
    setTags(newTags);
    onChange && onChange(newTags);
    onRemoved && onRemoved(text);

    setIsRemove(true);
  };

  const handleShowSubMenu = useCallback(
    (value?: string) => {
      const allTags = flattenDeep([
        listCustomTags,
        listOfficeTags,
        listSystemTags,
        families.map((family) => ({
          name: family.name,
          value: family.name,
        })) || [],
      ]);
      const customTags = tags?.filter(
        (item) => !allTags?.map((tag) => tag.value).includes(item)
      );

      if (!value) {
        return;
      }

      handleOpenSubMenu(value);

      switch (value) {
        case "TagType.CUSTOM": {
          setSubMenuOptions([
            ...customTags?.map((item) => ({
              name: item,
              value: item,
            })),
            ...listCustomTags,
          ]);
          break;
        }
        case "TagType.OFFICE": {
          setSubMenuOptions(listOfficeTags);
          break;
        }

        case "TagType.SYSTEM": {
          setSubMenuOptions(listSystemTags);
          break;
        }
        case "TagType.FAMILY": {
          setSubMenuOptions(
            families.map((family) => ({
              name: family.name,
              value: family.name,
            }))
          );
          break;
        }
        default: {
          break;
        }
      }
    },
    [families, tags]
  );

  const handleMouseLeaveSubmenu = useCallback(() => {
    handleCloseSubmenu();
  }, []);

  const onClickCheckBox = (value: (string | number)[]) => {
    setIsRemove(false);
    onChange?.(value as string[]);

    setTimeout(() => {
      if (tagInputRef.current) {
        tagInputRef.current.scrollTop = tagInputRef.current.scrollHeight;
      }

      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 10);
  };

  const handleTrigger = useCallback(() => {
    setIsOpen.toggle();
    inputRef?.current?.focus();
    onBlurPopover?.();
  }, [onBlurPopover, setIsOpen]);

  const handleClickContainer = () => {
    if (isOpen) {
      setMapIsShowSubMenu({} as any);
    }
    setIsOpen.toggle();
    inputRef?.current?.focus();
  };

  const handleCheckAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;

    const newOptions = subMenuOptions?.map((item) => item.value);

    if (isChecked) {
      onChange?.([
        ...(value || []),
        ...newOptions.filter(
          (option) => !value?.some((item) => item === option)
        ),
      ]);

      return;
    }

    onChange?.(
      (value || [])?.filter(
        (item) => !newOptions?.some((option) => option === item)
      )
    );
  };

  return (
    <Box ref={submenuRef} position="relative" {...boxProps}>
      {triggerElement && <Flex onClick={handleTrigger}>{triggerElement}</Flex>}

      <Box>
        {!triggerElement && (
          <Flex
            zIndex={10}
            onMouseLeave={onMouseLeave}
            padding={padding ? padding : "1rem"}
            borderRadius="0.375rem"
            flexWrap="wrap"
            gap="0.5rem"
            minH="4.4rem"
            maxH="8rem"
            overflowY={"auto"}
            border="1px solid var(--primary-border-color)"
            className="box-scroll-bar"
            onClick={handleClickContainer}
            bgColor={!isDisable || readonly ? "#f1f1f1" : "#ebebeb70"}
            {...flexProps}
            ref={tagInputRef}
            alignItems="center"
            cursor={readonly ? "not-allowed" : "unset"}
          >
            {isSelectedAll
              ? placeHolder
              : tags?.map((tag) => (
                  <Tag
                    bg="var(--primary-border-color)"
                    color={isDocument ? "#000" : "#000"}
                    borderRadius={4}
                    key={tag}
                    text={tag}
                    boxProps={{
                      p: ".1rem .8rem",
                      bg: "var(--primary-border-color)",
                    }}
                    styleText={{
                      color: "#171717",
                      fontWeight: 400,
                    }}
                    remove={onTagRemove}
                  />
                ))}

            {isDisable && (
              <Text w="100%" textAlign="center" opacity={0.5}>
                ー
              </Text>
            )}

            {allowInputText && (
              <Input
                ref={inputRef}
                name={name}
                minW="30%"
                flex={1}
                p="0 6px"
                fontSize="1.4rem"
                border="none"
                onChange={() => {}}
                placeholder={!tags.length ? placeHolder : ""}
                onKeyDown={handleOnKeyUp}
                _hover={{
                  border: "none",
                }}
                _focus={{
                  border: "none",
                }}
              />
            )}
          </Flex>
        )}

        {!!options?.length && isWithSubmenu && !readonly && (
          <Box
            hidden={!isOpen}
            border="1px solid #e2e2e3"
            position="absolute"
            left="0"
            top={
              menuPlacement === "top"
                ? isTaskTypeForm
                  ? "-11rem"
                  : "-13rem"
                : "unset"
            }
            mt={menuPlacement === "bottom" ? marginTop || "5px" : "unset"}
            width={menuWidth || "100%"}
            bg="white"
            borderRadius="4px"
            boxShadow="0 2px 6px 0 rgba(0, 0, 0, 0.2)"
            zIndex={12}
            maxH={`${DEFAULT_TAGS_HEIGHT}px`}
            overflowY="auto"
          >
            {tagMenus?.map((menu, index) => (
              <Box
                key={index}
                borderBottom="1px solid var(--primary-border-color)"
                _last={{ borderBottom: "none" }}
                sx={{
                  ".chakra-popover__popper": {
                    zIndex: isSafari || isMobile ? 12 : 10,
                  },
                }}
              >
                <Popover
                  isLazy
                  isOpen={!!mapIsShowSubMenu[menu.value]}
                  onOpen={() => handleOpenSubMenu(menu.value)}
                  onClose={handleCloseSubmenu}
                  placement={subMenuPlacement}
                  closeOnBlur={false}
                  trigger="hover"
                  offset={subMenuOffset}
                >
                  <PopoverTrigger>
                    <Flex
                      key={index}
                      justifyContent="space-between"
                      alignItems="center"
                      p=".5rem 1.5rem"
                      _hover={{
                        bg: "#f0f9ff",
                      }}
                      onMouseEnter={() => handleShowSubMenu(menu.value)}
                    >
                      <Text>{menu?.label}</Text>
                      <SvgIcon src="/img/arrow-right.svg" />
                    </Flex>
                  </PopoverTrigger>

                  {!!mapIsShowSubMenu[menu.value] && (
                    <Portal>
                      <PopoverContent
                        onMouseLeave={handleMouseLeaveSubmenu}
                        zIndex={10}
                        minW="30rem"
                        {...popoverContentProps}
                      >
                        {!!mapIsShowSubMenu[menu.value] &&
                          isShowIconSubMenu && <PopoverArrow />}

                        <PopoverBody
                          maxH={subMenuHeight || "18rem"}
                          overflowY="auto"
                          className={`box-scroll-bar ${SUB_MENU_TAG_INPUT_CLASSNAME}`}
                          zIndex={10}
                          p={0}
                        >
                          {isShowAllMenuOption && (
                            <Checkbox
                              className={SUB_MENU_TAG_INPUT_CLASSNAME}
                              value={allMenuOption.value}
                              isChecked={containsArray(
                                value as Array<any>,
                                subMenuOptions?.map((item) => item.value)
                              )}
                              w="100%"
                              p=".5rem 1.5rem"
                              mt="0px !important"
                              borderBottom="1px solid var(--primary-border-color)"
                              _hover={{ backgroundColor: "#EDF2F7" }}
                              sx={{
                                ".chakra-checkbox__label": {
                                  flex: "1",
                                },
                              }}
                              onChange={handleCheckAll}
                            >
                              {allMenuOption.label}
                            </Checkbox>
                          )}

                          <CheckboxGroup
                            value={tags}
                            onChange={onClickCheckBox}
                          >
                            <Stack spacing="1rem">
                              {subMenuOptions?.length ? (
                                subMenuOptions?.map((d) => (
                                  <Checkbox
                                    className={SUB_MENU_TAG_INPUT_CLASSNAME}
                                    key={d.value}
                                    value={d.value}
                                    p=".5rem 1.5rem"
                                    mt="0px !important"
                                    borderBottom="1px solid var(--primary-border-color)"
                                    _last={{ borderBottom: "none" }}
                                    _hover={{ backgroundColor: "#EDF2F7" }}
                                    sx={{
                                      ".chakra-checkbox__label": {
                                        flex: "1",
                                      },
                                    }}
                                  >
                                    {d.name}
                                  </Checkbox>
                                ))
                              ) : (
                                <EmptyData />
                              )}
                            </Stack>
                          </CheckboxGroup>
                        </PopoverBody>
                      </PopoverContent>
                    </Portal>
                  )}
                </Popover>
              </Box>
            ))}
          </Box>
        )}
      </Box>

      {!options?.length && !readonly && (
        <Box
          id="test012311"
          border="1px solid #e2e2e3"
          position="absolute"
          left="0"
          top="5.4rem"
          width="100%"
          bg="white"
          borderRadius="4px"
          boxShadow="0 2px 6px 0 rgba(0, 0, 0, 0.2)"
          zIndex={10}
          padding="1rem"
          hidden={!isOpen}
        >
          <CheckboxGroup value={tags} onChange={onClickCheckBox}>
            <Stack spacing="1rem">
              {options.map((d) => (
                <Checkbox key={d.value} value={d.value}>
                  {d.name}
                </Checkbox>
              ))}
            </Stack>
          </CheckboxGroup>
        </Box>
      )}
    </Box>
  );
};
