import { DeleteIcon } from "@chakra-ui/icons";
import { Button, Flex, FormControl, Text, useBoolean } from "@chakra-ui/react";
import { message } from "components/base";
import FileUpload from "components/FileUpload";
import { SvgIcon } from "components/SvgIcon";
import { FILE_SIZE_LIMIT } from "constants/document";
import { DocumentItemKey } from "constants/enum";
import { buttonOutlineStyle } from "constants/styleProps";
import { DocumentSubItemDTO } from "interfaces/dtos/documentItemDTO";
import { FileModel } from "interfaces/models";
import { OPERATION_ITEM_LOG } from "models/documentItemLog";
import { useEffect, useState } from "react";
import { fileToBase64, getPreSignUrl, uploadFileToS3 } from "utils/file";

interface Props {
  document: DocumentSubItemDTO;
  isOnline: boolean;
  groupName?: string;
  isDisabled?: boolean;
  updateDocument: (params: {
    key: keyof DocumentSubItemDTO;
    value: any;
    child: DocumentSubItemDTO;
    operation?: OPERATION_ITEM_LOG;
    groupName?: string;
  }) => Promise<void>;
}

const AttachFile = ({
  isOnline = true,
  document,
  isDisabled,
  groupName,
  updateDocument,
}: Props) => {
  const [attaches, setAttaches] = useState<FileModel[]>([]);
  const [loadingUpload, setLoadingUpload] = useBoolean(false);

  useEffect(() => {
    if (loadingUpload) return;

    const newAttaches: FileModel[] = [],
      searchString = "photo/";

    if (document.attaches?.length) {
      document.attaches.forEach((image: string, _) => {
        if (!image) {
          return;
        }

        newAttaches.push({
          src: image,
          name: image?.substring(
            image.indexOf(searchString) + searchString.length
          ),
        } as FileModel);
      });
    }
    setAttaches(newAttaches);
  }, [document, loadingUpload]);

  const handleSelect = (event: React.MouseEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    target.value = "";
  };

  const handleUploadAttaches = async (newAttaches: FileModel[]) => {
    const listAttachFile = await Promise.all(
      (newAttaches || attaches || []).map(async (file) => {
        if (file.file) {
          return await uploadFileToS3(file.file, file.name || "", "", {
            keepOriginName: true,
          });
        } else {
          return file.src || "";
        }
      })
    );

    return listAttachFile.filter((src) => !!src);
  };

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoadingUpload.on();
    const target = e.target as any;
    const fileList = target.files as FileList;
    if (!fileList.length) {
      setLoadingUpload.off();

      return;
    }
    const totalFilesSize = [...fileList].reduce((total, file) => {
      return total + file.size;
    }, 0);

    if (totalFilesSize > FILE_SIZE_LIMIT) {
      setLoadingUpload.off();

      return message.error("10Mを超えるファイルをアップロードできません。");
    }

    const newAttaches = [...attaches];
    for (let i = 0; i < fileList.length; i++) {
      const file = fileList[i];
      const imgString = await fileToBase64(file);
      const objectFile = {
        src: imgString,
        name: file.name,
        file,
      };
      newAttaches.push(objectFile);
    }
    setAttaches(newAttaches);
    const linkAttaches = await handleUploadAttaches(newAttaches);
    await updateDocument({
      key: DocumentItemKey.ATTACHES,
      value: linkAttaches,
      child: document,
      operation: OPERATION_ITEM_LOG.CREATE,
      groupName,
    });
    setLoadingUpload.off();
  };

  const handleDelete = async (index: number) => {
    setLoadingUpload.on();
    const newAttaches = [...attaches];
    newAttaches.splice(index, 1);

    setAttaches(newAttaches);
    const linkAttaches = await handleUploadAttaches(newAttaches);
    await updateDocument({
      key: DocumentItemKey.ATTACHES,
      value: linkAttaches,
      child: document,
      operation: OPERATION_ITEM_LOG.DELETE,
    });

    setLoadingUpload.off();
  };

  const getFileName = (src: string) => src.replace(/^.*[\\/]/, "");

  return (
    <Flex my="16px" gap=".8rem">
      <Text w="8rem" mr="2rem" color="var(--primary-text-color)">
        添付資料
      </Text>
      <Flex
        width="100%"
        alignItems="center"
        justifyContent="space-between"
        backgroundColor="#E8E8E8"
        color="var(--primary-text-color)"
        fontSize="1.4rem"
        p="0.8rem 0.8rem 0.8rem 1.2rem"
        minHeight="8rem"
        borderRadius={4}
      >
        {!attaches?.length && (
          <Text color="var(--primary-text-color)">資料を添付できます</Text>
        )}
        {!!attaches?.length && (
          <Flex flex="1" alignItems="center" gap="0.5rem 1rem" flexWrap="wrap">
            {attaches?.map((file, index) => {
              return (
                <Flex
                  width="95px"
                  cursor={isDisabled ? "not-allowed" : "pointer"}
                  position="relative"
                  key={index}
                  opacity={isDisabled ? 0.4 : 1}
                  onClick={() => {
                    if (isDisabled) return;
                    const src = document?.attaches?.[index];
                    if (
                      src &&
                      src.includes(`${process.env.REACT_APP_S3_URL}/`) &&
                      !src.includes("X-Amz-SignedHeaders=host")
                    ) {
                      getPreSignUrl(src, "")
                        .then((presignedUrl) => {
                          return fetch(presignedUrl);
                        })
                        .catch(() => fetch(src))
                        .then((res) => res.blob())
                        .then((blob) => {
                          const blobUrl = window.URL.createObjectURL(blob);
                          window.open(blobUrl, "_blank");
                        })
                        .catch();
                    }
                  }}
                  alignItems="center"
                  gap="0.2rem"
                >
                  <SvgIcon width="100%" src="/img/doc7.svg" />
                  <Text
                    width="100%"
                    whiteSpace="nowrap"
                    textOverflow="ellipsis"
                    overflow="hidden"
                  >
                    {getFileName(file.src || "")}
                  </Text>
                  {!loadingUpload && !isDisabled && isOnline && (
                    <DeleteIcon
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDelete(index);
                      }}
                      color="grey"
                    />
                  )}
                  {index !== attaches.length - 1 && ","}
                </Flex>
              );
            })}
          </Flex>
        )}
        <FormControl
          ml="auto"
          isRequired
          height="4rem"
          w="auto"
          onClick={handleSelect}
          onChange={(e) => handleChange(e as any)}
        >
          <Flex>
            <FileUpload
              isDisabled={loadingUpload || isDisabled}
              width="auto"
              multiple
            >
              <Button
                {...buttonOutlineStyle}
                cursor={isDisabled ? "not-allowed" : "pointer"}
                isLoading={loadingUpload}
              >
                選択
              </Button>
            </FileUpload>
          </Flex>
        </FormControl>
      </Flex>
    </Flex>
  );
};

export default AttachFile;
