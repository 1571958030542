import { useEffect, useState } from "react";

export const DEFAULT_FONT_SIZE = 9;

export const useResize = () => {
  const [width, setWidth] = useState<number>(window.innerWidth);
  const [height, setHeight] = useState<number>(window.innerHeight);
  const [rootFontSize, setRootFontSize] = useState<number>(0);

  useEffect(() => {
    onResize();
    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
    const rootFontSize = window
      .getComputedStyle(document.documentElement)
      .getPropertyValue("font-size");
    const fontSize = rootFontSize
      ? Number(rootFontSize.replace("px", ""))
      : DEFAULT_FONT_SIZE;
    setRootFontSize(fontSize);
  };

  return { width, height, rootFontSize };
};
