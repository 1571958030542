import {
  Box,
  Button,
  Flex,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
  UseModalProps,
} from "@chakra-ui/react";
import { SvgIcon } from "components/SvgIcon";
import { useEffect, useState } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { routePath } from "routes/path";
import { DATE_TIME_VERSION_FORMAT, formatDate } from "utils/date";

interface Props extends UseModalProps {
  versions?: any[] | null;
  onUpdate?: (version: string | number) => void;
}

function BimModelUpdate({ versions, onClose, onUpdate, ...rest }: Props) {
  const navigate = useNavigate();
  const { bimFileId, projectBimFileId } = useParams();
  const [currentVersion, setCurrentVersion] = useState<any>(null);
  const [latestVersion, setLatestVersion] = useState<any>(null);

  useEffect(() => {
    if (versions?.length) {
      setLatestVersion(versions[versions?.length - 1]);
      const currentVersionNumber = bimFileId
        ?.split("?")[1]
        ?.replace("version=", "");
      const currentVersion = versions.find(
        (version) =>
          Number(version.versionNumber) === Number(currentVersionNumber)
      );
      setCurrentVersion(currentVersion);
    }
  }, [versions, bimFileId]);

  const _onUpdate = async () => {
    if (onUpdate) {
      await onUpdate(latestVersion.versionNumber);
    }
    navigate(
      generatePath(routePath.ForgeViewer, {
        projectBimFileId: projectBimFileId,
        bimFileId,
        version: latestVersion.versionNumber,
      })
    );
    onClose();
  };

  const onCancel = () => {
    onClose();
  };

  if (!currentVersion || !latestVersion) {
    return null;
  }

  return (
    <Modal onClose={onClose} {...rest}>
      <ModalOverlay />
      <ModalContent borderRadius="8px" mt="20vh" w="90%" maxW="74.2rem">
        <Flex
          bg="#0000001F"
          h="4.2rem"
          alignItems="center"
          justifyContent="space-between"
        >
          <Text ml="3rem" fontSize="1.8rem" color="#000000CC">
            BIMモデルのアップデート
          </Text>
          <SvgIcon
            onClick={onClose}
            src="/img/icon-navigation-close.svg"
            className="button"
            mr="1.5rem"
          />
        </Flex>
        <Box p="3rem">
          <Text
            fontSize="1.8rem"
            color="#000000CC"
            mt="1rem"
            ml="2rem"
            className="text"
          >
            {`参照のBIMモデルが更新されました。\nViewを更新しますか？`}
          </Text>
          <Box
            mt="3rem"
            alignItems="center"
            border="1px solid #C8C8C8"
            maxW="56.2rem"
            w="100%"
            mx="auto"
            p="2rem 3.5rem"
            borderRadius="4px"
          >
            <Flex>
              <Text mr="4.8rem">V{currentVersion.versionNumber}</Text>
              <Text>現在のバージョン</Text>
              <Text ml="4.8rem">
                {`${formatDate(currentVersion.createTime)}`}
              </Text>
            </Flex>
            <Flex mt="2.6rem">
              <Text mr="4.8rem">V{latestVersion.versionNumber}</Text>
              <Text>最新のバージョン</Text>
              <Text ml="4.8rem">
                {`${formatDate(latestVersion.createTime)}`}
              </Text>
            </Flex>
          </Box>
          <Flex justifyContent="right" gap="1rem" mt="6.4rem">
            <Button
              mt="1.8rem"
              onClick={onCancel}
              variant="white"
              border="1px solid #0000001F"
              color="#3CA4FF"
              boxShadow="none"
            >
              キャンセル
            </Button>
            <Button mt="1.8rem" onClick={_onUpdate} variant="blueLite">
              更新する
            </Button>
          </Flex>
        </Box>
      </ModalContent>
    </Modal>
  );
}

export default BimModelUpdate;
