import { documentApi } from "apiClient/v2";
import { iKeyLabel } from "components/ui/KeyLabel";
import { DocumentItemKey, DocumentTemplateType } from "constants/enum";
import { DocumentCategoryDTO } from "interfaces/dtos/documentCategoryDTO";
import { DocumentDTO } from "interfaces/dtos/documentDTO";
import {
  DocumentItemDTO,
  DocumentSubItemDTO,
} from "interfaces/dtos/documentItemDTO";
import { BlackBoardInfo } from "interfaces/models/blackboard";
import { DocumentKeyNote } from "interfaces/models/documentKeynote";
import { transformKeynoteIndex } from "models/document";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setStatusContentFromS3 } from "redux/documentSlice";
import { RootState } from "redux/store";
import { Editor } from "tinymce";
import { sortArrayByField } from "utils/array";
import { sleep } from "utils/common";
import { getTextFromUrl } from "utils/file";
import { logError } from "utils/logs";

interface Props {
  ref: any;
  isLoadingDeleteDocument?: boolean;
  isOpen: boolean;
  isCreatingKeynote?: boolean;
  documentKeyNote?: DocumentKeyNote | null | undefined;
  documentItemsHasImage?: DocumentSubItemDTO[];
  documentCategorySelected?: DocumentCategoryDTO;
  documentItemSelected?: DocumentItemDTO;
  currentDocument?: DocumentDTO | null;
  isForgeViewerPage?: boolean;
  setCurrentEditor?: (editor: Editor | null) => void;
}

const usePreviewData = ({
  isCreatingKeynote,
  isOpen,
  documentCategorySelected,
  documentItemSelected,
  currentDocument,
  documentItemsHasImage,
  setCurrentEditor,
}: Props) => {
  const { documentCategories, dataBlackboards } = useSelector(
    (state: RootState) => state.document
  );
  const [keyLabelsEdited, setKeyLabelsEdited] = useState<Partial<iKeyLabel>[]>(
    []
  );
  const [contentEditor, setContentEditor] = useState<string>("");
  const [
    contentEditorOfDocumentItemsForCategory,
    setContentEditorOfDocumentItemsForCategory,
  ] = useState<
    | {
        [key: string]: string;
      }
    | undefined
  >(undefined);
  const [modeEdit, setModeEdit] = useState(false);
  const [isSavingDocument, setIsSavingDocument] = useState(false);
  const editorRef = useRef<Editor | null>(null);
  const [isLoadingFromS3, setIsLoadingFromS3] = useState(false);
  const [isFirstLoading, setIsFirstLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isOpen) {
      setModeEdit(false);
      setIsFirstLoading(true);
    }
  }, [isOpen]);

  useEffect(() => {
    if (!modeEdit || isOpen) {
      setKeyLabelsEdited([]);
    }
  }, [modeEdit, isOpen]);

  useEffect(() => {
    (async () => {
      if (!currentDocument) return;
      let content: string = "";
      const contents: { [key: string]: string } = {};

      // Handle for Module chiller
      const isCategoryModuleChiller =
        documentCategorySelected?.documentType ===
          DocumentTemplateType.EQUIPMENT_DATA_SHEET && !documentItemSelected;
      const documentItems = documentCategorySelected?.documentItems;

      // Get all s3 url of document items when document category is selected
      if (
        isCategoryModuleChiller &&
        documentItems?.length &&
        currentDocument?.id
      ) {
        setIsLoadingFromS3(true);
        const editorFromS3 = await documentApi.handleGetDocumentList({
          documentCategoryIds:
            documentItems
              ?.filter((item) => item?.id)
              ?.map((item) => item.id!) || [],
        });
        editorFromS3?.forEach((item) => {
          if (item?.content && item?.documentCategoryId) {
            contents[item.documentCategoryId] = item.content;
          }
        });
        const objKeyContents = contents && Object.keys(contents);
        dispatch(setStatusContentFromS3(false));
        try {
          const contentEditor = Object.fromEntries(
            await Promise.all(
              objKeyContents?.map((id) =>
                (async () => [id, await getTextFromUrl(contents[id])])()
              )
            )
          );
          if (Object.keys(contentEditor)?.length) {
            setContentEditorOfDocumentItemsForCategory(contentEditor);
          }
        } catch (error) {
          logError(error);
        }
        setIsLoadingFromS3(false);
      } else if (currentDocument?.content && currentDocument?.id) {
        if (isFirstLoading) {
          setIsLoadingFromS3(true);
        }

        if (currentDocument?.content) {
          content = await getTextFromUrl(currentDocument.content);
          setContentEditor(content);
          dispatch(setStatusContentFromS3(true));
        }

        if (isFirstLoading) {
          setIsLoadingFromS3(false);
          setIsFirstLoading(false);
        }
      } else {
        setIsFirstLoading(false);
        dispatch(setStatusContentFromS3(false));
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentDocument,
    documentCategorySelected,
    documentItemSelected,
    isFirstLoading,
  ]);

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    (async () => {
      const t = (await sleep(100)) as any;
      const previewDocumentCategoryEle =
        document.getElementById("container-preview");

      if (!previewDocumentCategoryEle) {
        return;
      }

      const handleSetContentEditor = () => {
        if (previewDocumentCategoryEle.childNodes[0]) {
          const content = (previewDocumentCategoryEle.childNodes[0] as any)
            .innerHTML;

          setContentEditor(content);
        }
      };

      handleSetContentEditor();
      const callback = () => {
        handleSetContentEditor();
      };

      // Create an observer instance linked to the callback function
      const observer = new MutationObserver(callback);
      // Start observing the target node for configured mutations
      observer.observe(previewDocumentCategoryEle, {
        attributes: true,
        childList: true,
        subtree: true,
        attributeOldValue: true,
      });

      return () => {
        clearTimeout(t);
        observer.disconnect();
      };
    })();
  }, [isOpen, isCreatingKeynote]);

  const callbackDragKeynoteStop = useCallback(
    (keylabel: Partial<iKeyLabel>) => {
      setKeyLabelsEdited((prev) => {
        const res = structuredClone(prev);
        const index = res.findIndex((item) => item.id === keylabel?.id);
        if (index !== -1) {
          res[index] = keylabel;
        } else {
          res.push(keylabel);
        }

        return res;
      });
    },
    []
  );

  const onInitEditor = useCallback((editor: any) => {
    if (setCurrentEditor) {
      setCurrentEditor(editor);
    }
    editorRef.current = editor;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const transformDocumentItemsPhotoLedger = useCallback(
    ({
      documentCategory,
      documentItemsHasImage,
    }: {
      documentCategory: DocumentCategoryDTO;
      documentItemsHasImage: DocumentSubItemDTO[];
    }) => {
      const selectedExternalIds = documentCategory?.selectedExternalIds || [];
      const documentItems: DocumentItemDTO[] =
        sortArrayByField<DocumentItemDTO>(
          (documentCategory?.documentItems || [])?.filter((item) =>
            selectedExternalIds.includes(item.id || "")
          ),
          DocumentItemKey.DISPLAY_ORDER
        );

      const sortSubItems = documentItems
        .map((item) => item?.subItems || [])
        .flat(1)
        .filter((sub) => !sub?.isHidden);
      const mapDocumentItemsHasImage = Object.assign(
        {},
        ...(documentItemsHasImage || [])?.map((item) => ({
          [item?.id || ""]: item,
        }))
      );

      return sortSubItems.map((documentSubItem) => {
        const keyNoteIndex = transformKeynoteIndex({
          documentItems,
          documentSubItem,
        });

        const blackBoardInfo = (
          mapDocumentItemsHasImage?.[documentSubItem?.id || ""] || {}
        )?.blackBoardInfo;

        return {
          ...documentSubItem,
          ...blackBoardInfo,
          keyNoteIndex,
        } as any;
      });
    },
    []
  );

  const formatDocumentItems: BlackBoardInfo[] = useMemo(() => {
    return transformDocumentItemsPhotoLedger({
      documentCategory: documentCategorySelected || ({} as DocumentCategoryDTO),
      documentItemsHasImage: documentItemsHasImage || [],
    });
  }, [
    documentItemsHasImage,
    documentCategorySelected,
    transformDocumentItemsPhotoLedger,
  ]);

  return {
    keyLabelsEdited,
    isLoadingFromS3,
    documentCategories,
    modeEdit,
    isSavingDocument,
    contentEditor,
    contentEditorOfDocumentItemsForCategory,
    formatDocumentItems,
    dataBlackboards,
    editorRef,
    setContentEditor,
    setContentEditorOfDocumentItemsForCategory,
    setModeEdit,
    setIsSavingDocument,
    onInitEditor,
    setIsFirstLoading,
    callbackDragKeynoteStop,
    transformDocumentItemsPhotoLedger,
  };
};

export default usePreviewData;
