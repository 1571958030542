import { Level } from "interfaces/models";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import useFetchDocumentTasks from "./useFetchDocumentTasks";
import { SystemModeType } from "constants/enum";

interface iProps {
  levelSelected: Level;
  bimFileId: string | undefined;
}
const useDocumentTask = ({ levelSelected, bimFileId }: iProps) => {
  const { documentTasks } = useSelector((state: RootState) => state.task);
  const { documentItemSelected } = useSelector(
    (state: RootState) => state.document
  );
  const { systemMode } = useSelector((state: RootState) => state.forgeViewer);

  useFetchDocumentTasks({
    bimFileId,
    isFetch: systemMode === SystemModeType.Task || !!documentItemSelected,
  });

  const documentTasksByLevel = useMemo(() => {
    if (!documentTasks) {
      return [];
    }

    // case all floor
    if (!levelSelected?.guid) {
      return documentTasks?.filter((doc) => !!doc.title);
    }

    return documentTasks?.filter(
      (documentTask) =>
        documentTask?.level === levelSelected?.label && !!documentTask?.title
    );
  }, [levelSelected, documentTasks]);

  return { documentTasksByLevel };
};

export default useDocumentTask;
