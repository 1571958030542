import { message } from "components/base";
import { PaperDirectionType, PaperType } from "constants/enum";
import { DocumentCategoryDTO } from "interfaces/dtos/documentCategoryDTO";
import { DocumentItemDTO } from "interfaces/dtos/documentItemDTO";
import jsPDF from "jspdf";
import { domToJpeg } from "modern-screenshot";
import { useDeviceSelectors } from "react-device-detect";
import { useDispatch } from "react-redux";
import { setIsDownloadPdfOnMobile } from "redux/forgeViewerSlice";
import { Editor } from "tinymce";
import { sleep } from "utils/common";
import { downloadPdfByUrl } from "utils/download-pdf";
import { getCurrentViewer } from "utils/forge";
import { logDev } from "utils/logs";

interface Props {
  documentItemSelected?: DocumentItemDTO;
  documentCategorySelected: DocumentCategoryDTO | undefined;
  editorRef: React.MutableRefObject<Editor | null>;
  setLoading: (isLoading: boolean) => void;
  setLoadingPrintPdf: (isLoading: boolean) => void;
}

export const DOM_TO_IMAGE_SCALE_VALUE = 2;

export const useDownloadPdf = ({
  documentCategorySelected,
  documentItemSelected,
  editorRef,
  setLoading,
  setLoadingPrintPdf,
}: Props) => {
  const [{ isMobile }] = useDeviceSelectors(window.navigator.userAgent);
  const dispatch = useDispatch();
  const viewer = getCurrentViewer();

  const closeLoading = async (isPrint = false) => {
    if (isMobile && viewer) {
      await sleep(3000);
      dispatch(setIsDownloadPdfOnMobile(false));
    }

    if (isPrint) {
      setLoadingPrintPdf(false);
    } else {
      setLoading(false);
    }
  };

  const downloadPdfByClass = async (
    classDom: string,
    isPrint = false,
    isFromContentEditor = false
  ) => {
    const className = classDom.replace("div.", "");
    let contents: HTMLElement[] = [];

    if (isFromContentEditor && !editorRef.current) {
      message.error("エラーが発生しました。再度実行お願いします。");
      closeLoading(isPrint);

      return;
    }
    if (isFromContentEditor && editorRef.current) {
      contents = editorRef.current.dom.select(classDom);
    }

    const parentElement = document.getElementById("container-preview");
    if (!isFromContentEditor && parentElement) {
      document
        .querySelectorAll(
          `#container-preview .document-content-container, #container-preview iframe`
        )
        .forEach((el: any) => {
          if (el?.nodeName === "IFRAME") {
            el?.contentWindow?.document?.body
              ?.querySelectorAll(`.${className}`)
              ?.forEach((elIframe: any) => {
                contents.push(elIframe);
              });
          } else {
            contents.push(el);
          }
        });
    }

    if (!contents?.length) {
      message.error("エラーが発生しました。再度実行お願いします。");
      closeLoading(isPrint);

      return;
    }

    if (isMobile && viewer) {
      dispatch(setIsDownloadPdfOnMobile(true));
    }

    const arrayNodes = Array.from(contents);
    const pageSize = contents[0].getAttribute("data-page-size") || PaperType.A4;
    const pageDirection =
      contents[0].getAttribute("data-page-direction") ===
      PaperDirectionType.VERTICAL
        ? "p"
        : "l";

    const pdf: any = new jsPDF(pageDirection, "px", pageSize);
    try {
      for await (const [index, node] of arrayNodes.entries()) {
        const width = pdf.internal.pageSize.getWidth();
        const height = pdf.internal.pageSize.getHeight();
        const image = await domToJpeg(node, {
          scale: DOM_TO_IMAGE_SCALE_VALUE,
          font: !isMobile as any,
          workerUrl: "/modern-screenshot@4.4.31_dist_worker.js",
        });

        pdf.addImage(image, "jpeg", 0, 0, width, height, undefined, "FAST");
        if (index < contents.length - 1) {
          const _pageDirection =
            contents[index + 1].getAttribute("data-page-direction") ===
            PaperDirectionType.VERTICAL
              ? "p"
              : "l";
          const _pageSize =
            contents[index + 1].getAttribute("data-page-size") || PaperType.A4;

          pdf.addPage(_pageSize, _pageDirection);
        }
      }
      if (isPrint) {
        closeLoading(isPrint);
        window.open(pdf.output("bloburl"), "_blank");

        return;
      }

      const downloadTitle = documentItemSelected?.title
        ? `${documentCategorySelected?.title} ${documentItemSelected?.title}`
        : documentCategorySelected?.title;

      const blob = pdf.output("bloburl");
      await downloadPdfByUrl(blob, `${downloadTitle || "pdf"}.pdf`);
      closeLoading(isPrint);
    } catch (err) {
      logDev(err, "print errror--");

      message.error("エラーが発生しました。再度実行お願いします。");

      closeLoading(isPrint);
    }
  };

  const handleDownloadPDF = async (
    isPrint = false,
    isFromContentEditor = false
  ) => {
    if (isPrint) {
      setLoadingPrintPdf(true);
    } else {
      setLoading(true);
    }

    await downloadPdfByClass(
      "div.document-content-container",
      isPrint,
      isFromContentEditor
    );
  };

  const handlePrintPdf = async (isFromContentEditor = false) => {
    if (!documentCategorySelected?.id) {
      return;
    }

    await handleDownloadPDF(true, isFromContentEditor);
  };

  return {
    handleDownloadPDF,
    handlePrintPdf,
  };
};
