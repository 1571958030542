import { Box, BoxProps } from "@chakra-ui/layout";
import { useMemo } from "react";
import SVG, { Props as SVGProps } from "react-inlinesvg";

interface Props {
  pathFill?: string;
  rectFill?: string;
  ellipseFill?: string;
  ref?: any;
}

export type SVGIconProps = Props & SVGProps & BoxProps;

function SvgIcon({
  pathFill,
  rectFill,
  ellipseFill,
  ref,
  ...rest
}: SVGIconProps) {
  const style = useMemo(() => {
    const result: any = {};
    if (pathFill) {
      result["path"] = {
        fill: `${pathFill} !important`,
      };
    }

    if (rectFill) {
      result["rect"] = {
        fill: `${rectFill} !important`,
      };
    }

    if (ellipseFill) {
      result["ellipse"] = {
        stroke: `${ellipseFill} !important`,
      };
    }

    return result;
  }, [pathFill, rectFill, ellipseFill]);

  return <Box as={SVG} sx={style} ref={ref} {...rest} />;
}

export { SvgIcon };
