import { render } from "index";
import { Vector3 } from "interfaces/models";
import ReactDOM from "react-dom/client";

import { uuid } from "utils/common";
import { calculatePositionOnSheet } from "utils/forge/forge2d";

interface ReactPanelOption extends Autodesk.Viewing.UI.DockingPanelOptions {
  id?: string;
  visible: boolean;
  placement?: "auto" | "center" | "bottom";
  position?: THREE.Vector3 | Vector3;
}

export default class ReactPanel extends Autodesk.Viewing.UI.DockingPanel {
  id: string;
  private viewer: Autodesk.Viewing.GuiViewer3D;
  private root: ReactDOM.Root;
  private placement: ReactPanelOption["placement"] = "auto";
  constructor(
    viewer: Autodesk.Viewing.GuiViewer3D,
    content: React.ReactNode,
    options: ReactPanelOption
  ) {
    const id = options.id ?? uuid();
    const { visible, placement, position, ...panelOptions } = options;
    super(viewer.container, id, "", panelOptions);
    this.id = id;
    this.viewer = viewer;

    Object.assign(this.container?.style, {
      display: "none",
      boxShadow: "none",
    });

    this.root = ReactDOM.createRoot(this.container);
    render(this.root, content);
    setTimeout(() => {
      this.setVisible(visible);
      setTimeout(() => {
        this.setPosition(options.position, placement);
      });
    });
  }

  replaceContent(content: React.ReactNode) {
    render(this.root, content);

    return this;
  }

  setVisible(show: boolean) {
    Object.assign(this?.container?.style || {}, {
      display: show ? "block" : "none",
    });
  }

  setPosition(
    _position?: THREE.Vector3 | Vector3,
    _placement?: ReactPanelOption["placement"]
  ) {
    if (_placement) {
      this.placement = _placement;
    }

    const position = this.calculateAbsolutePosition(_position);

    let left = position.x;
    let top = position.y;

    const size = this.getContentSize();
    if (this.placement === "center") {
      left -= size.width / 2;
      top -= size.height / 2;
    } else if (this.placement === "bottom") {
      top -= size.height;
    }

    Object.assign(this.container?.style, {
      left: `${left}px`,
      top: `${top}px`,
    });
  }

  uninitialize() {
    this.container = null;
    try {
      this.root.unmount();
    } catch {}
  }

  private calculateAbsolutePosition(position?: THREE.Vector3 | Vector3) {
    return this.viewer.worldToClient(
      this?.viewer?.model?.is2d()
        ? calculatePositionOnSheet(position)
        : new THREE.Vector3(position?.x, position?.y, position?.z)
    );
  }
}
