import { Vector3 } from "interfaces/models";
import { compareFloat } from "./number";

export const calculateDistance2d = (
  a: Vector3 | THREE.Vector3,
  b: Vector3 | THREE.Vector3
) => {
  return Math.sqrt(Math.pow(a.x - b.x, 2) + Math.pow(a.y - b.y, 2));
};

export const calculateCenterPoint = (
  a: Vector3 | THREE.Vector3,
  b: Vector3 | THREE.Vector3
) => {
  return {
    x: (a.x + b.x) / 2,
    y: (a.y + b.y) / 2,
    z: (a.z + b.z) / 2,
  } as Vector3;
};

export const addVectors = (
  a: THREE.Vector3 | Vector3,
  b: THREE.Vector3 | Vector3
) => {
  return {
    x: a.x + b.x,
    y: a.y + b.y,
    z: a.z + b.z,
  } as Vector3;
};

export const subtractVectors = (
  a: THREE.Vector3 | Vector3,
  b: THREE.Vector3 | Vector3
) => {
  return {
    x: a.x - b.x,
    y: a.y - b.y,
    z: a.z - b.z,
  } as Vector3;
};

export const compareVectors = (
  a: THREE.Vector3 | Vector3,
  b: THREE.Vector3 | Vector3
) => {
  return (
    compareFloat(a.x, b.x) === 0 &&
    compareFloat(a.y, b.y) === 0 &&
    compareFloat(a.z, b.z) === 0
  );
};
