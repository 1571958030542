import { Spinner as SpinnerFC, Flex, FlexProps } from "@chakra-ui/react";

interface Props extends FlexProps {
  size?: string;
  speed?: string;
  thickness?: string;
  emptyColor?: string;
  color?: string;
}

function Spinner({
  size = "xl",
  speed = "0.65s",
  thickness = "4px",
  emptyColor = "gray.200",
  color = "blue.500",
  ...rest
}: Props) {
  return (
    <Flex justifyContent="center" alignItems="center" width="100%" {...rest}>
      <SpinnerFC
        thickness={thickness}
        speed={speed}
        emptyColor={emptyColor}
        color={color}
        size={size}
        {...rest}
      />
    </Flex>
  );
}

export default Spinner;
