import { forgeApi } from "apiClient/v2";
import { useEffect, useState } from "react";

interface iProps {
  bimFileId?: string;
  projectId?: string;
}

export interface iForgeVersion {
  createTime: string;
  createUserId: string;
  createUserName: string;
  displayName: string;
  extension: any;
  fileType: string;
  id: string;
  lastModifiedTime: string;
  lastModifiedUserId: string;
  lastModifiedUserName: string;
  name: string;
  storageSize: number;
  type: string;
  versionNumber: number;
}

const useGetVersionsOfForge = ({ bimFileId, projectId }: iProps) => {
  const [versions, setVersions] = useState<iForgeVersion[] | null>(null);

  useEffect(() => {
    if (!bimFileId || !projectId) {
      return;
    }

    (async () => {
      const bimFileIdParam = bimFileId?.split("?")[0];
      const realBimId = bimFileIdParam?.replace("fs.file:vf.", "dm.lineage:");
      const res = await forgeApi.getVersionsByBimFileId({
        projectId: projectId || "",
        bimFileId: realBimId || "",
      });
      if (res?.data) {
        const versions: iForgeVersion[] = res.data;
        versions?.sort((a: iForgeVersion, b: iForgeVersion) => {
          return a.versionNumber - b.versionNumber;
        });
        setVersions(versions);
      }
    })();
  }, [bimFileId, projectId]);

  return {
    versions,
  };
};

export default useGetVersionsOfForge;
