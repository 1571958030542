import { Text } from "@chakra-ui/react";
import { IconBase } from "components/base";
import { CSSProperties, memo } from "react";
import { FONT_SIZE_DISPLAY_ORDER } from "utils/forge";

interface Props {
  no: any;
  width?: string;
  height?: string;
  color?: CSSProperties["color"];
  colorText?: CSSProperties["color"];
}

const FieldNoComponent = ({
  no,
  width = "40px",
  height = "40px",
  color = "#737373",
  colorText = "white",
}: Props) => {
  return (
    <IconBase
      color="white"
      icon="/img/pin-on-map.svg"
      width={width}
      height={height}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <IconBase
        color={color}
        icon="/img/pin-on-map.svg"
        width={`calc(${width} - 2px)`}
        height={`calc(${height} - 2px)`}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Text
          fontSize={
            FONT_SIZE_DISPLAY_ORDER[
              no.toString().length as keyof typeof FONT_SIZE_DISPLAY_ORDER
            ]
          }
          mb="3px"
          fontWeight="400"
          color={colorText}
        >
          {no}
        </Text>
      </IconBase>
    </IconBase>
  );
};

export default memo(FieldNoComponent);
