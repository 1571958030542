import { CellPropertyDocumentDataForTableOptions } from "constants/documentTemplate";
import {
  DocumentCategoryKey,
  DocumentCategoryStatusType,
  DocumentItemKey,
  MapDocumentCategoryStatusType,
} from "constants/enum";

export enum OPERATION_ITEM_LOG {
  NONE = 0,
  CREATE = 1,
  UPDATE = 2,
  DELETE = 3,
}

export interface GetContentItemLog {
  field: DocumentItemKey;
  value?: any;
  operation?: OPERATION_ITEM_LOG;
  nameDynamicField?: string;
  prefixFieldName?: string;
  dynamicFieldKeysUpdatedAt?: { [key: string]: Date };
}

export interface GetContentCategoryLog {
  field: DocumentCategoryKey;
  value?: any;
  operation?: OPERATION_ITEM_LOG;
  nameDynamicField?: string;
  prefixFieldName?: string;
  dynamicFieldKeysUpdatedAt?: { [key: string]: Date };
}

export const getDocumentCategoryContentLog = ({
  field,
  value,
  nameDynamicField,
  prefixFieldName = "",
}: GetContentCategoryLog) => {
  let title = "";
  switch (field) {
    case DocumentCategoryKey.GROUP_ID:
      title = "が書類を追加する場所を変更";
      break;

    case DocumentCategoryKey.TEMPLATE_ID:
      title = "が書類テンプレートを変更";
      break;

    case DocumentCategoryKey.LEVEL:
      title = "が階を変更";
      break;

    case DocumentCategoryKey.NO_AREA:
    case DocumentCategoryKey.NEPTUNE_AREA_IDS:
    case DocumentCategoryKey.ALL_FLOOR:
      title = "が検査対象工区を変更";
      break;

    case DocumentCategoryKey.TAGS:
      title = "がタグを変更";
      break;
    case DocumentCategoryKey.TITLE:
      title = "がタイトルを変更";
      break;
    case DocumentCategoryKey.USER_ASSIGNED:
      title = "が担当者を変更";
      break;
    case DocumentCategoryKey.USER_CREATED:
      title = "が作成者を変更";
      break;
    case DocumentCategoryKey.STATUS:
      if (value) {
        title = `がステータスを${
          MapDocumentCategoryStatusType[value as DocumentCategoryStatusType]
        }に変更`;
      } else {
        title = "ステータスが変更されました";
      }
      break;
    case "dynamicField" as any:
      if (nameDynamicField) {
        title = `が${nameDynamicField}を設定`;
      }
      break;
    default:
      const name = CellPropertyDocumentDataForTableOptions.find(
        (opt) => opt.value === field
      )?.name;
      title = `${name}を変更`;

      break;
  }
  if (!title) {
    return null;
  }
  if (prefixFieldName) {
    if (title.startsWith("が")) {
      title = `が${prefixFieldName}の${title.substring(1)}`;
    } else {
      title = `${prefixFieldName}の${title}`;
    }
  }

  return { title, field };
};

export const getDocumentContentLog = ({
  field,
  value,
  operation = OPERATION_ITEM_LOG.UPDATE,
  nameDynamicField,
  prefixFieldName = "",
}: GetContentItemLog) => {
  let title = "";
  switch (field) {
    case "statusSubItemSelfInspection" as any:
      title = "がチェックボックスをチェック";
      break;

    case DocumentItemKey.MEASURE_START_TIME:
      title = "が測定年月日を設定";
      break;

    case DocumentItemKey.TAGS:
      title = "がタグを変更";
      break;
    case DocumentItemKey.SYSTEM_NAMES:
      title = "が系統名を変更";
      break;
    case DocumentItemKey.TITLE:
      title = "がタイトルを変更";
      break;
    case DocumentItemKey.MEMO:
      title = "が備考を変更";
      break;
    case DocumentItemKey.USER_ASSIGNED:
      title = "が担当者を変更";
      break;
    case DocumentItemKey.USER_CREATED:
      title = "が作成者を変更";
      break;
    case DocumentItemKey.DEADLINE:
      title = "が期日を変更";
      break;
    case DocumentItemKey.PRIORITY:
      title = "が緊急度を変更";
      break;
    case DocumentItemKey.IS_CONFIRM_BLACKBOARD:
      title = "が黒板の確認を変更";
      break;
    case "blackboard" as any:
      title = "が黒板を変更";
      break;
    case DocumentItemKey.IMAGES:
      if (operation === OPERATION_ITEM_LOG.CREATE) {
        title = "が写真を登録";
      } else if (operation === OPERATION_ITEM_LOG.DELETE) {
        title = "が写真を削除";
      } else {
        title = "が写真を変更";
      }
      break;
    case DocumentItemKey.ATTACHES:
      if (operation === OPERATION_ITEM_LOG.CREATE) {
        title = "が添付資料を登録";
      } else if (operation === OPERATION_ITEM_LOG.DELETE) {
        title = "が添付資料を削除";
      } else {
        title = "が添付資料を変更";
      }
      break;
    case DocumentItemKey.STATUS:
      if (value) {
        title = `がステータスを${
          MapDocumentCategoryStatusType[value as DocumentCategoryStatusType]
        }に変更`;
      } else {
        title = "ステータスが変更されました";
      }
      break;
    case DocumentItemKey.START_DATE_SCHEDULED:
      title = "が開始予定日を設定";
      break;
    case DocumentItemKey.END_DATE_SCHEDULED:
      title = "が完了予定日を設定";
      break;
    case "dynamicField" as any:
      if (nameDynamicField) {
        title = `が${nameDynamicField}を設定`;
      }
      break;
    case "createTask" as any:
      title = "が指摘を設定";
      break;
    case "isShowBlackboard" as any:
      title = "が黒板の表示を変更";
      break;
    case "treatmentContent" as any:
      title = "が処置内容を変更";
      break;
    default:
      break;
  }
  if (!title) {
    return null as any;
  }

  if (prefixFieldName) {
    if (title.startsWith("が")) {
      title = `が${prefixFieldName}の${title.substring(1)}`;
    } else {
      title = `${prefixFieldName}の${title}`;
    }
  }

  return { title, field };
};
