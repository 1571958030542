import { fabric } from "fabric";
import { SharpBase } from "./sharp-base";

let origX = 0;
let origY = 0;

// Draw Circle
export class Circle extends SharpBase {
  constructor(canvas: fabric.Canvas) {
    super(canvas, "Circle");
  }

  onMouseMove = function (o: { e: any }) {
    const instance = this;
    if (!instance.isEnable()) {
      return;
    }

    const pointer = instance.canvas.getPointer(o.e);
    const activeObj = instance.canvas.getActiveObject();
    activeObj.stroke = instance.canvas.freeDrawingBrush.color;
    activeObj.strokeWidth = instance.canvas.lineThickness;
    activeObj.fill = "transparent";

    if (origX > pointer.x) {
      activeObj.set({
        left: pointer.x,
      });
    }

    if (origY > pointer.y) {
      activeObj.set({
        top: pointer.y,
      });
    }

    activeObj.set({
      rx: Math.abs(origX - pointer.x) / 2,
    });
    activeObj.set({
      ry: Math.abs(origY - pointer.y) / 2,
    });
    activeObj.setCoords();
    instance.canvas.renderAll();
  };

  onMouseDown = function (o: { e: any }) {
    const instance = this;
    instance.enable();

    const pointer = instance.canvas.getPointer(o.e);
    origX = pointer.x;
    origY = pointer.y;

    const ellipse = new fabric.Ellipse({
      top: origY,
      left: origX,
      rx: 0,
      ry: 0,
      hasBorders: false,
      hasControls: false,
      selectable: false,
      data: {
        noSaveState: true,
      },
    });

    instance.canvas.add(ellipse).setActiveObject(ellipse);
  };

  onMouseUp = () => {
    const instance = this;
    instance.disable();
    const activeObj = instance.canvas.getActiveObject();
    if (activeObj && activeObj.width && activeObj.height) {
      activeObj.hasControls = true;
      instance.canvas.saveState();
      instance.canvas.discardActiveObject();
      instance.canvas.renderAll();
    }
  };
}
