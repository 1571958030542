import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setOpenSelectBlackboardTemplateModal } from "redux/documentSlice";
import { RootState } from "redux/store";

const useSelectBlackboardTemplateModal = () => {
  const { isOpenSelectBlackboardTemplateModal } = useSelector(
    (state: RootState) => state.document
  );
  const dispatch = useDispatch();

  const onOpenSelectBlackboardTemplateModal = useCallback(() => {
    dispatch(setOpenSelectBlackboardTemplateModal(true));
  }, [dispatch]);

  const onCloseSelectBlackboardTemplateModal = useCallback(() => {
    dispatch(setOpenSelectBlackboardTemplateModal(false));
  }, [dispatch]);

  return {
    isOpenSelectBlackboardTemplateModal,
    onOpenSelectBlackboardTemplateModal,
    onCloseSelectBlackboardTemplateModal,
  };
};

export default useSelectBlackboardTemplateModal;
