import { Avatar, Flex, Text } from "@chakra-ui/react";
import { withPresignedUrl } from "components/HOC/presignedUrl";
import { DropdownTag, DropdownTagsInputProps } from "components/ui/DropdownTag";
import { User } from "interfaces/models/user";
import { CSSProperties } from "react";
import { defaultAvatarPath } from "constants/file";

const AvatarPresigned = withPresignedUrl(Avatar);
interface Props extends DropdownTagsInputProps {
  users: {
    [key: string]: User | null;
  };
  value?: string[];
  onChange?: (value: any) => void;
  placeHolder?: string;
  isShowIcon?: boolean;
  styleTag?: CSSProperties;
  styleWrapTag?: CSSProperties;
  strategy?: "absolute" | "fixed";
  iconProps?: any;
  icon?: any;
  placeholderProps?: any;
  isDisabled?: boolean;
  isLoading?: boolean;
}

const SelectUserMulti = ({
  users = {},
  value = [],
  onChange,
  isShowIcon = true,
  placeHolder = "ユーザーを選択します",
  styleTag,
  styleWrapTag,
  strategy = "absolute",
  isLoading,
  ...rest
}: Props) => {
  return (
    <DropdownTag
      options={Object.keys(users).map((userId) => ({
        name: (
          <>
            <Flex w="100%" height="4.2rem" alignItems="center">
              <AvatarPresigned
                w="2.5rem"
                h="2.5rem"
                src={users[userId]?.avatar || defaultAvatarPath}
              />
              <Text
                style={{
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
                as="div"
                ml="1rem"
                color="#171717"
              >
                {users[userId]?.name}
              </Text>
            </Flex>
          </>
        ),
        value: userId,
      }))}
      styleTag={{
        height: "4rem",
        ...styleTag,
      }}
      value={value}
      onChange={onChange}
      placeHolder={placeHolder}
      name="全てのユーザー"
      styleProps={{ w: "100%" }}
      styleContent={{
        w: "100%",
        overflowY: "auto",
        height: "auto",
        maxHeight: "30rem",
      }}
      strategy={strategy}
      styleWrapTag={styleWrapTag}
      isLoading={isLoading}
      {...rest}
    />
  );
};

export default SelectUserMulti;
