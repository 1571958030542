/* eslint-disable no-console */
import { ID_TOKEN_COOKIE } from "constants/storage";
import { WSMessage, WSMessageAction } from "interfaces/models/websocket";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { RootState } from "redux/store";
import { safelyParseJSON } from "utils/common";
import { getLocalStorage } from "utils/storage";

export const useAppWebSocket = () => {
  const { projectBimFileId } = useParams();
  const DEFAULT_CHANNEL = projectBimFileId || "ALL";
  const token = getLocalStorage(ID_TOKEN_COOKIE, "").replaceAll('"', "");
  const { isOnline } = useSelector((state: RootState) => state.app);
  const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket(
    process.env.REACT_APP_COMMENT_WEB_SOCKET!,
    {
      share: true,
      queryParams: {
        authorization: token,
      },
      heartbeat: isOnline
        ? {
            message: "ping",
            timeout: 60000,
            interval: 45000,
          }
        : undefined,
      reconnectAttempts: 2,
      reconnectInterval: (attemptNumber) =>
        Math.min(Math.pow(2, attemptNumber) * 1000, 10000),
      onOpen: () => {
        console.log("ws connected!");
        sendJsonMessage({
          action: WSMessageAction.ADD_CHANNEL,
          message: DEFAULT_CHANNEL,
        });
      },
      onClose: () => console.log("ws disconnected!"),
      shouldReconnect: () => isOnline,
      filter: ({ data }) => {
        return (
          data && safelyParseJSON(data).action === WSMessageAction.SEND_MESSAGE
        );
      },
    },
    isOnline
  );

  const sendWebSocketMessage = useCallback(
    (message: WSMessage) => {
      if (!isOnline) return;
      sendJsonMessage({
        action: WSMessageAction.SEND_MESSAGE,
        message: { ...message, channel: message.channel || DEFAULT_CHANNEL },
      });
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isOnline, DEFAULT_CHANNEL]
  );

  const webSocketMessage = useMemo(() => {
    if (!lastJsonMessage || !isOnline) return null;

    return (lastJsonMessage as any).message as WSMessage;
  }, [lastJsonMessage, isOnline]);

  return {
    sendWebSocketMessage,
    webSocketMessage,
    isWebSocketOpen: readyState === ReadyState.OPEN,
  };
};
