import { useOutsideClick } from "@chakra-ui/react";
import { DEFAULT_BLACKBOARD_CONTENT_SIZE } from "constants/blackBoardTemplate";
import {
  CellSizeSetting,
  ImageComponentSize,
  PaperDirectionType,
  PaperSize,
  PaperType,
  TableDefaultStyle,
  TemplateComponentType,
  TextBoxComponentSize,
} from "constants/enum";
import {
  BUTTON_CREATE_CUSTOM_COMPONENT_ID,
  CONTAINER_LAYOUT_ID,
  DOCUMENT_TEMPLATE_RIGHT_SIDEBAR_ID,
} from "constants/styleProps";
import useGetPageInfo from "hooks/useGetPageInfo";
import { PageFormat, TemplateComponent } from "interfaces/models/component";
import { getHeaderComponentElementId } from "models/document";
import { useMemo, useRef } from "react";
import { useDispatch } from "react-redux";
import { Rnd } from "react-rnd";
import {
  setComponentSelected,
  setIsCreateComponent,
  setIsEditText,
  setSelectedCell,
} from "redux/documentSlice";
import { setIsScanGrid, setScanComponents } from "redux/editorSlice";
import { getMinColumnWidth, getMinRowHeight } from "utils/document";
import { centimetersToPixels } from "utils/measure";
import { iUseComponentPreview } from "..";

const useComponentPreview = (props: iUseComponentPreview) => {
  const {
    isOnlyView,
    zoomRatio,
    sizePageRatio,
    pageDirectionRatio,
    zoomRatioHeight,
    cellSize,
    isBlackboardTemplate = false,
    componentSelected,
    component,
    pageSelected,
    page,
    pages,
  } = props;

  const dispatch = useDispatch();
  const { pageSize } = useGetPageInfo();
  const componentRef = useRef<Rnd | null>();

  useOutsideClick({
    ref: { current: componentRef.current?.getSelfElement() } as any,
    enabled:
      !!componentSelected?.componentId &&
      componentSelected.componentId === component.componentId &&
      !isOnlyView,
    handler: (event: any) => {
      const selection = window.getSelection();
      if (selection?.toString().length) {
        return;
      }

      const element: HTMLElement | null = event?.target as any;
      const headerComponentEle = document.getElementById(
        getHeaderComponentElementId(component?.componentId)
      );
      const buttonCreateCustomComponentELe = document.getElementById(
        BUTTON_CREATE_CUSTOM_COMPONENT_ID
      );
      const documentTemplateRightSidebarEle = document.getElementById(
        DOCUMENT_TEMPLATE_RIGHT_SIDEBAR_ID
      );
      const containerLayoutEle = document.getElementById(CONTAINER_LAYOUT_ID);
      if (!containerLayoutEle?.contains(element)) {
        return;
      }

      if (
        element &&
        (buttonCreateCustomComponentELe?.contains(element) ||
          headerComponentEle?.contains(element) ||
          documentTemplateRightSidebarEle?.contains(element))
      ) {
        return;
      }

      dispatch(setComponentSelected({} as TemplateComponent));
      dispatch(setIsCreateComponent(false));
      dispatch(setSelectedCell([]));
      dispatch(setIsScanGrid(false));
      dispatch(setScanComponents([]));
      dispatch(setIsEditText(false));
    },
  });

  const scale = useMemo(
    () => ({
      x: 1 / zoomRatio / sizePageRatio / pageDirectionRatio,
      y:
        (1 / (zoomRatioHeight ?? zoomRatio) / sizePageRatio) *
        pageDirectionRatio,
      displayX: zoomRatio * sizePageRatio * pageDirectionRatio,
      displayY:
        ((zoomRatioHeight ?? zoomRatio) * sizePageRatio) / pageDirectionRatio,
    }),
    [zoomRatio, sizePageRatio, pageDirectionRatio, zoomRatioHeight]
  );

  const displayComponent = useMemo(() => {
    const pageInfo = pages?.[page] as PageFormat | undefined;
    const _pageSize = (pageInfo?.pageSize ||
      PaperType.A4) as keyof typeof PaperSize;
    let numOfColumns = PaperSize[_pageSize]?.numOfColumns;
    let numOfRows = PaperSize[_pageSize]?.numOfRows;
    if (pageInfo?.pageDirection === PaperDirectionType.HORIZONTAL) {
      [numOfColumns, numOfRows] = [numOfRows, numOfColumns];
    }

    let maxWidth = numOfColumns * cellSize.width;
    let maxHeight = numOfRows * cellSize.height;

    if (isBlackboardTemplate) {
      const offsetWidth = Math.floor(
        DEFAULT_BLACKBOARD_CONTENT_SIZE.width * zoomRatio -
          DEFAULT_BLACKBOARD_CONTENT_SIZE.width
      );
      const offsetHeight = Math.floor(
        DEFAULT_BLACKBOARD_CONTENT_SIZE.height *
          (zoomRatioHeight ?? zoomRatio) -
          DEFAULT_BLACKBOARD_CONTENT_SIZE.height
      );

      maxWidth =
        DEFAULT_BLACKBOARD_CONTENT_SIZE.width +
        offsetWidth +
        TableDefaultStyle.DEFAULT_BORDER_SIZE;

      maxHeight =
        DEFAULT_BLACKBOARD_CONTENT_SIZE.height +
        offsetHeight +
        TableDefaultStyle.DEFAULT_BORDER_SIZE;
    }

    const newWidth = component.size.width * scale.displayX;
    const newHeight = component.size.height * scale.displayY;

    if (pageSelected.isFullSize || component?.detail?.isFullsize) {
      const size = {
        width:
          centimetersToPixels(PaperSize[pageSelected?.pageSize]?.width) *
          zoomRatio,
        height:
          centimetersToPixels(PaperSize[pageSelected?.pageSize]?.height) *
          zoomRatio,
      };

      [maxWidth, maxHeight] =
        pageSelected?.pageDirection === PaperDirectionType.VERTICAL
          ? [size.width, size.height]
          : [size.height, size.width];
    }

    const newPositionX = component.position.x * scale.displayX;
    const newPositionY = component.position.y * scale.displayY;

    const width = newWidth > maxWidth ? maxWidth : newWidth;
    const height = newHeight > maxHeight ? maxHeight : newHeight;

    const x = newPositionX + width > maxWidth ? maxWidth - width : newPositionX;
    const y =
      newPositionY + height > maxHeight ? maxHeight - height : newPositionY;

    return {
      size: {
        maxWidth,
        maxHeight,
        width: component.detail?.isFullsize
          ? pageSize.width * zoomRatio
          : width,
        height: component.detail?.isFullsize
          ? pageSize.height * zoomRatio
          : height,
      },
      position: {
        x,
        y,
      },
    };
  }, [
    pageSelected,
    cellSize.width,
    cellSize.height,
    isBlackboardTemplate,
    component.size.width,
    component.size.height,
    component.position.x,
    component.position.y,
    component.detail?.isFullsize,
    page,
    pages,
    scale.displayX,
    scale.displayY,
    pageSize.width,
    pageSize.height,
    zoomRatio,
    zoomRatioHeight,
  ]);

  const minSize = useMemo(() => {
    let minW = 0;
    let minH = 0;
    let minRowH = [] as number[];
    let minColumnW = [] as number[];

    switch (component.type) {
      case TemplateComponentType.Table:
      case TemplateComponentType.FilterPhoto:
      case TemplateComponentType.LinkedImage:
      case TemplateComponentType.TableHeader:
        minRowH = getMinRowHeight(component);
        minH =
          minRowH.reduce((prev, cur) => prev + cur) ||
          CellSizeSetting.MIN_HEIGHT;

        minColumnW = getMinColumnWidth(component);
        minW = minColumnW.reduce((prev, cur) => prev + cur, 0);
        break;

      case TemplateComponentType.QR_CODE:
      case TemplateComponentType.TasksImage:
      case TemplateComponentType.Keyplan:
      case TemplateComponentType.Image:
        minW = ImageComponentSize.MIN_WIDTH;
        minH = ImageComponentSize.MIN_HEIGHT;
        break;

      case TemplateComponentType.Text:
        minW = TextBoxComponentSize.MIN_WIDTH;
        minH = TextBoxComponentSize.MIN_HEIGHT;
        break;
    }

    return {
      minWidth: minW,
      minHeight: minH,
      minRowHeight: minRowH,
      minColumnWidth: minColumnW,
    };
  }, [component]);

  return { componentRef, scale, displayComponent, minSize };
};

export default useComponentPreview;
