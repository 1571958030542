import { DropdownTagsInputProps } from "components/ui/DropdownTag";

export const SelectUserSingleSx = {
  ".containerSelectInput > div": {
    background: "#FFF",
  },
  "div[class*='SelectInput']": {
    width: "100%",
  },
  "div[class*='ValueContainer']": {
    padding: "0.8rem",
  },
  "div[class*='option']": {
    zIndex: 10,
  },
  "div[class*='singleValue'] > div": {
    height: "3rem",
  },
};

export const DEFAULT_DROP_BOX_HEIGHT = 250;

export const SelectUserMultiStyle: DropdownTagsInputProps = {
  styleContent: {
    w: "100%",
    overflow: "auto",
    maxHeight: "21rem",
    overflowY: "auto",
  },
  styleProps: {
    w: "100%",
    minH: "4.4rem",
    p: 0,
  },
  styleWrapTag: {
    padding: ".5rem 1rem",
  },
  styleTag: {
    height: "3.6rem",
  },
};
