import { Text, TextProps } from "@chakra-ui/react";
import useHandleTextOverflow from "components/modal/PreviewDocumentCategory/hooks/useHandleTextOverflow";
import { CellSizeSetting, LinkedDataField, TextPosition } from "constants/enum";
import { MCE_EDITABLE_CLASSNAME } from "constants/styleProps";
import React, { CSSProperties, memo, useEffect, useRef, useState } from "react";

export interface iTextContainer {
  children?: React.ReactElement | string;
  parentLoop?: number | undefined;
  autoReduceSize?: boolean;
  tab?: number;
  alignText?: string;
  style?: CSSProperties;
}

const TextContainer = ({
  children,
  style = {},
  parentLoop,
  autoReduceSize = false,
  tab,
  alignText,
  ...rest
}: iTextContainer & TextProps) => {
  const [element, setElement] = useState<any>();
  const textRef = useRef<HTMLParagraphElement>(null);

  const { lineClamp } = useHandleTextOverflow({
    parentLoop,
    element,
    autoReduceSize,
  });

  const isText = typeof children === "string" && !children?.includes("\n");

  useEffect(() => {
    if (textRef.current) {
      setElement(textRef.current);
    }
  }, []);

  useEffect(() => {
    if (
      textRef.current &&
      typeof children === "string" &&
      children.includes("\n")
    ) {
      textRef.current.innerHTML = children?.replaceAll("\n", "<br/>");
    }
  }, [children]);

  return (
    <Text
      ref={textRef}
      className={MCE_EDITABLE_CLASSNAME}
      data-field-type={LinkedDataField.COMMON.INPUT_DATA}
      style={{
        paddingRight: "0.5rem",
        paddingLeft: "0.5rem",
        textAlign:
          alignText === TextPosition.START
            ? TextPosition.LEFT
            : alignText === TextPosition.END
            ? TextPosition.RIGHT
            : alignText === TextPosition.JUSTIFY
            ? TextPosition.CENTER
            : TextPosition.CENTER,
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "initial",
        wordBreak: "break-all",
        display: "-webkit-box",
        lineClamp: lineClamp === Infinity || lineClamp < 0 ? 1 : lineClamp,
        WebkitLineClamp:
          lineClamp === Infinity || lineClamp < 0 ? 1 : lineClamp,
        WebkitBoxOrient: "vertical",
        minWidth: CellSizeSetting.MIN_WIDTH,
        minHeight: "1em",
        width: "100%",
        ...style,
      }}
      {...rest}
    >
      {isText && children}
    </Text>
  );
};

export default memo(TextContainer);
