import { Box, Spinner } from "@chakra-ui/react";
import { createContext, useCallback, useState } from "react";

export const LoadingContext = createContext({
  isLoading: false,
  turnOnLoading: () => {},
  turnOffLoading: () => {},
});

const LoadingProvider = ({ children }: { children: React.ReactElement }) => {
  const [isLoading, setIsLoading] = useState(false);

  const turnOnLoading = useCallback(() => {
    setIsLoading(true);
  }, []);

  const turnOffLoading = useCallback(() => {
    setIsLoading(false);
  }, []);

  return (
    <LoadingContext.Provider
      value={{ turnOnLoading, turnOffLoading, isLoading }}
    >
      {isLoading && (
        <Box
          position="fixed"
          top="0"
          left="0"
          w="100vw"
          h="var(--app-height)"
          zIndex={10000}
          bg="rgba(0,0,0,0.4)"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Spinner
            w="30px"
            h="30px"
            speed="0.65s"
            emptyColor="transparent"
            color="white"
            size="xl"
          />
        </Box>
      )}
      {children}
    </LoadingContext.Provider>
  );
};

export default LoadingProvider;
