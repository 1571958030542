import { useDisclosure } from "@chakra-ui/react";

const useHandleForgeImageModal = () => {
  const {
    isOpen: isOpenForgeImageTaskModal,
    onOpen: onOpenForgeImageTaskModal,
    onClose: onCloseForgeImageTaskModel,
  } = useDisclosure();

  return {
    isOpenForgeImageTaskModal,
    onOpenForgeImageTaskModal,
    onCloseForgeImageTaskModel,
  };
};

export default useHandleForgeImageModal;
