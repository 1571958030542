import { InspectionItemType } from "constants/enum";
import { TaskDTO } from "interfaces/dtos/taskDTO";
import { FileUploadInfo } from "interfaces/models";
import { calculatePositionOnSheet } from "./forge/forge2d";

export const getBoundsFromTasks = (tasks: TaskDTO[]) => {
  const pointTask = tasks
    .filter((task) => task.position)
    .reduce(
      (init: any, task: TaskDTO, index: number) => {
        const absolutePosition = calculatePositionOnSheet(task.position);

        return index > 0
          ? {
              top:
                absolutePosition.y > init?.top?.y ? absolutePosition : init.top,
              left:
                absolutePosition.x < init?.left?.x
                  ? absolutePosition
                  : init.left,
              bottom:
                absolutePosition.y < init?.bottom?.y
                  ? absolutePosition
                  : init.bottom,
              right:
                absolutePosition.x > init?.right?.x
                  ? absolutePosition
                  : init.right,
            }
          : {
              top: absolutePosition,
              left: absolutePosition,
              bottom: absolutePosition,
              right: absolutePosition,
            };
      },
      {
        top: {},
        left: {},
        bottom: {},
        right: {},
      } as any
    );

  return new THREE.Box3(
    new THREE.Vector3(pointTask.left.x - 12, pointTask.bottom.y - 12, 0),
    new THREE.Vector3(pointTask.right.x + 24, pointTask.top.y + 12, 0)
  );
};

export const getTaskMap = (tasks: any[]) => {
  const map: { [key: string]: any } = {};
  tasks.forEach((item) => {
    const key: string = item.id || item.taskId || "";
    map[key] = item;
  });

  return map;
};

export const getNumberOfTask = (tasks: TaskDTO[], documentItemId?: string) => {
  const newTasks = tasks.filter(
    (task) => task?.documentItemId === documentItemId
  );

  if (!newTasks.length) return;

  const taskCompleted = newTasks.filter(
    (task) => task.status === InspectionItemType.Confirmed
  );

  return {
    total: newTasks.length,
    numberOfCompleted: taskCompleted.length,
  };
};
