import {
  Box,
  BoxProps,
  Button,
  Text,
  TextProps,
  Tooltip,
} from "@chakra-ui/react";

import { JSXElementConstructor, ReactElement } from "react";

interface TagProps {
  text: string | ReactElement<any, string | JSXElementConstructor<any>>;
  remove: any;
  color?: string;
  styleText?: TextProps;
  bg?: string;
  borderRadius?: number;
  boxProps?: BoxProps;
  isShowTooltip?: boolean;
}

export default function Tag({
  text,
  remove,
  bg = "#edf2f7",
  styleText,
  color = "white",
  borderRadius = 0,
  boxProps,
  isShowTooltip = false,
  ...rest
}: TagProps) {
  const handleOnRemove = (e: any) => {
    e.stopPropagation();
    remove(text);
  };

  return (
    <Box
      bg="var(--primary-border-color)"
      display="inline-flex"
      justifyContent="center"
      alignItems={"center"}
      p="1px 0.8rem"
      borderRadius="4px"
      minHeight="2.4rem"
      textAlign="left"
      {...boxProps}
      {...rest}
    >
      <Tooltip label={text} isDisabled={!isShowTooltip}>
        <Text
          as="div"
          color="var(--primary-text-color)"
          fontSize="1.4rem"
          display="inline"
          {...styleText}
        >
          {text}
        </Text>
      </Tooltip>

      <Button
        background="none"
        border="none"
        borderRadius="full"
        p="0 0.5rem"
        color={color}
        _hover={{
          background: "none",
        }}
        _focus={{
          background: "none",
        }}
        _active={{
          background: "none",
        }}
        onClick={handleOnRemove}
      >
        &#10005;
      </Button>
    </Box>
  );
}
