export const YYYY_MM_DD_HH_MM_SS = "YYYY-MM-DD HH:mm:ss";
export const YYYY_MM_DD_HH_MM = "YYYY/MM/DD HH:mm";
export const YYYY_MM_DD = "YYYY.MM.DD";
export const YYYY_MM_DD_HYPHEN = "YYYY-MM-DD";
export const YYYY_MM_DD_SLASH = "YYYY/MM/DD";
export const YYYY_M_D = "YYYY/M/D";

export const DATE_PLACE_HOLDER = "yyyy/mm/dd";
export const DATE_TIME_PLACE_HOLDER = "yyyy/mm/dd hh:mm ~ hh:mm";
export const DATE_RANGE_PLACE_HOLDER = "yyyy/mm/dd - yyyy/mm/dd";
export const TIME_PLACE_HOLDER = "HH:mm";
export const DATE_JAPAN_FORMAT = "YYYY 年 MM 月 DD 日";

export const DAY_OF_WEEK_JP = {
  0: "日",
  1: "月",
  2: "火",
  3: "水",
  4: "木",
  5: "金",
  6: "土",
};
