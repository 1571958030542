import {
  Box,
  BoxProps,
  Flex,
  TooltipProps,
  useBoolean,
} from "@chakra-ui/react";
import { PCTooltip } from "components/PCTooltip";
import { SvgIcon } from "components/SvgIcon";
import { memo, useMemo, useRef } from "react";

export interface ForgeToolbarButtonProps extends BoxProps {
  icon?: string;
  iconClass?: string;
  tooltip: string;
  isActive?: boolean;
  isDisabled?: boolean;
  popup?: any;
  isStroke?: boolean;
  isHoverStyle?: boolean;
  svgIconProps?: BoxProps;
  tooltipProps?: Partial<TooltipProps>;
}

const ForgeToolbarButton = ({
  icon,
  iconClass,
  tooltip,
  isActive,
  isDisabled,
  popup,
  onClick,
  isHoverStyle,
  isStroke = false,
  svgIconProps,
  tooltipProps,
  ...rest
}: ForgeToolbarButtonProps) => {
  const buttonRef = useRef<HTMLDivElement | null>();
  const popupRef = useRef<HTMLDivElement | null>();
  const [isOpenPopUp, setOpenPopup] = useBoolean();

  const hoverStyle = useMemo(() => {
    return isHoverStyle && !isDisabled && !isActive
      ? {
          border: "1px solid #009BE0",
          color: "#009BE0",
          svg: {
            "path, rect, circle, line": {
              ...(isStroke ? { stroke: "#009BE0" } : { fill: "#009BE0" }),
            },
          },
        }
      : {};
  }, [isActive, isDisabled, isHoverStyle, isStroke]);

  const sx = useMemo(() => {
    return {
      svg: isDisabled
        ? {
            "path, rect": {
              opacity: 0.2,
            },
          }
        : isActive
        ? {
            "path, rect": {
              fill: "#009BE0",
              opacity: 1,
            },
          }
        : {
            "path, rect": {
              opacity: 1,
            },
          },
    };
  }, [isActive, isDisabled]);

  const style = useMemo(() => {
    return isActive
      ? {
          boxShadow: "0 0 1px 1px #009BE0",
          borderRadius: "4px",
        }
      : {};
  }, [isActive]);

  return (
    <Flex position="relative">
      <PCTooltip
        bg="rgba(0,0,0,0.7)"
        color="white"
        placement="top"
        fontSize="1.2rem"
        borderRadius="2px"
        label={tooltip}
        {...tooltipProps}
      >
        <Box
          ref={(e) => (buttonRef.current = e)}
          {...rest}
          display="inline-block"
          p="7px"
          m="1px"
          outline="none"
          boxSizing="border-box"
          cursor={isDisabled ? "not-allowed" : "pointer"}
          color="#171717"
          border="1px solid #fff"
          borderRadius="4px"
          _hover={hoverStyle}
          sx={sx}
          {...style}
          onClick={(e) => {
            if (isDisabled) {
              return;
            }
            if (onClick) {
              onClick(e);
            }
            if (popup) {
              setOpenPopup.toggle();
            }
          }}
        >
          {icon && (
            <SvgIcon
              src={icon}
              w="24px"
              h="24px"
              sx={{
                "path, rect, circle, line": {
                  ...(isStroke ? { stroke: "#171717" } : {}),
                },
              }}
              {...(svgIconProps as any)}
            />
          )}
          {iconClass && (
            <Flex
              className={iconClass}
              alignItems="center"
              fontSize="2.1rem"
              color={isActive ? "#009BE0" : "unset"}
              opacity={isDisabled ? 0.2 : 1}
            />
          )}
        </Box>
      </PCTooltip>

      {popup && isOpenPopUp ? (
        <>
          <Box
            className="overlay"
            position="fixed"
            left={0}
            top={0}
            width="100vw"
            height="var(--app-height)"
            zIndex={1}
            onClick={() => setOpenPopup.off()}
          ></Box>
          <Box
            ref={(e) => (popupRef.current = e)}
            position="absolute"
            bgColor="rgba(0,0,0,0.8)"
            borderRadius="4px"
            bottom="50px"
            left={0}
            zIndex={2}
          >
            {popup}
          </Box>
        </>
      ) : (
        <></>
      )}
    </Flex>
  );
};

export default memo(ForgeToolbarButton);
