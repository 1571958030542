import { Box, Flex, Image, Text } from "@chakra-ui/react";
import { withPresignedUrl } from "components/HOC/presignedUrl";
import { errorImagePath } from "constants/file";
import { boxContentOfTaskForPhotoBooks } from "constants/styleProps";
import useUserOfProject from "hooks/useUserOfProject";
import { TaskDTO } from "interfaces/dtos/taskDTO";
import { DocumentTaskDataFieldPhotoBooks } from "interfaces/models/documentTask";
import { TaskType } from "interfaces/models/taskType";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import ContentPhotoBooksInstructions from "./ContentPhotoBooksInstructions";
import useGetPhotoBookContentInstruction from "./hooks/useGetPhotoBookContentInstruction";
import { iHandleDocumentTaskDetail } from "./hooks/usePreviewTaskSheetTemplate";
import LoadingImageOfPhotoBooks from "./LoadingImageOfPhotoBooks";
import PhotoBooksHeader from "./PhotoBooksHeader";
import {
  IMAGE_KEYS,
  PHOTO_BOOKS_CONTAINER_PADDING_BOTTOM,
} from "./PhotoBooksReportItem";

const ImagePresigned = withPresignedUrl(Image);

export interface TaskPhotoBookInstruction extends TaskDTO {
  isShowHeader?: boolean;
  partnerCompanyName?: string;
  photoBookContentKey?: string;
  photoBookContentEdit?: DocumentTaskDataFieldPhotoBooks;
}

interface Props {
  task: TaskPhotoBookInstruction;
  taskTypes: TaskType[];
  contentTypeChanged?: string;
  taskImageHeight?: number;
  checkImageLoaded?: (taskId: string) => void;
  editMode: boolean;
  onEditPhotoBooksContent?: iHandleDocumentTaskDetail["onEditPhotoBooksContent"];
}

const PhotoBooksInstructionsItem = ({
  task,
  editMode,
  taskImageHeight = 230,
  taskTypes,
  checkImageLoaded,
  onEditPhotoBooksContent,
}: Props) => {
  const [mapStatusAllImage, setMapStatusAllImage] = useState<{
    [key: string]: boolean;
  }>({});
  const { listUserById } = useUserOfProject();

  const contentPhotoBookInstruction = useGetPhotoBookContentInstruction({
    taskTypes,
    listUserById,
    task,
    photoBookContentEdit: task?.photoBookContentEdit,
  });

  useEffect(() => {
    if (!task.id) {
      return;
    }

    if (!task.images?.length) {
      checkImageLoaded?.(task.id);

      return;
    }

    const arrStatusAllImage = Object.values(mapStatusAllImage);

    if (arrStatusAllImage.every((s) => s)) {
      checkImageLoaded?.(task.id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapStatusAllImage, task]);

  const onErrorTaskImage = (e: any) => {
    (e as any).target.src = errorImagePath;
    setMapStatusAllImage((prev) => ({
      ...prev,
      [IMAGE_KEYS.TASK_IMAGE]: true,
    }));
  };

  const onLoadTaskImage = () => {
    setMapStatusAllImage((prev) => ({
      ...prev,
      [IMAGE_KEYS.TASK_IMAGE]: true,
    }));
  };

  return (
    <Box
      w="100%"
      pb={`${PHOTO_BOOKS_CONTAINER_PADDING_BOTTOM}px`}
      className="task-item-container"
      overflow="hidden"
    >
      {task.isShowHeader && <PhotoBooksHeader task={task} />}

      <Flex w="100%">
        <Flex
          position="relative"
          w="calc(50% - 8px)"
          h={`${taskImageHeight}px`}
          mr="16px"
          bgColor="rgb(218 218 218 / 9%)"
          border="1px solid #1A1A1A"
          justifyContent="center"
        >
          {task?.images?.length ? (
            <>
              <LoadingImageOfPhotoBooks
                isLoading={!mapStatusAllImage?.[IMAGE_KEYS.TASK_IMAGE]}
              />
              <Flex
                alignItems="center"
                justifyContent="center"
                width="100%"
                height="100%"
              >
                <ImagePresigned
                  src={task?.images[0].src}
                  alt=""
                  crossOrigin="anonymous"
                  className="image-item"
                  display="block"
                  maxH={`${taskImageHeight - 2}px`}
                  boxLoadingHeight={`${taskImageHeight - 2}px`}
                  isConvertToBase64={isMobile}
                  onLoad={onLoadTaskImage}
                  onError={onErrorTaskImage}
                />
              </Flex>
            </>
          ) : (
            <Flex
              alignItems="center"
              justifyContent="center"
              h={`${taskImageHeight}px`}
              width="100%"
              textAlign="center"
            >
              <Text>写真なし</Text>
            </Flex>
          )}
        </Flex>

        <Flex
          w="calc(50% - 8px)"
          h={`${taskImageHeight}px`}
          {...boxContentOfTaskForPhotoBooks}
        >
          <ContentPhotoBooksInstructions
            editMode={editMode}
            editDataStyle={task?.photoBookContentEdit?.instructionStyle}
            {...contentPhotoBookInstruction}
            photoBookContentKey={task?.photoBookContentKey}
            onEditPhotoBooksContent={onEditPhotoBooksContent}
          />
        </Flex>
      </Flex>
    </Box>
  );
};

export default React.memo(PhotoBooksInstructionsItem);
