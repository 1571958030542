import {
  Box,
  Button,
  ButtonProps,
  Flex,
  Modal,
  ModalContent,
  ModalContentProps,
  ModalOverlay,
  ModalProps,
  Text,
  UseModalProps,
} from "@chakra-ui/react";
import { SvgIcon } from "components/SvgIcon";

interface Props extends UseModalProps {
  title: string | React.ReactNode;
  content: string | React.ReactNode;
  buttonConfirm?: string;
  buttonCancel?: string;
  isLoading?: boolean;
  size?: ModalProps["size"];
  modalContentProps?: ModalContentProps;
  isShowCloseIcon?: boolean;
  footer?: React.ReactNode;
  iconCloseProps?: ButtonProps;
  onProcessing?: () => void | Promise<void>;
}

function ConfirmModal({
  title,
  content,
  isOpen,
  isLoading,
  buttonConfirm,
  buttonCancel = "キャンセル",
  size = "xl",
  modalContentProps,
  isShowCloseIcon = true,
  footer,
  onClose,
  onProcessing,
  iconCloseProps,
  ...rest
}: Props) {
  return (
    <Modal
      size={size}
      isOpen={isOpen}
      onClose={onClose}
      isCentered={true}
      closeOnOverlayClick={!isLoading}
      {...rest}
    >
      <ModalOverlay />
      <ModalContent
        position="relative"
        borderRadius="8px"
        p="2rem 3.7rem 2.1rem 3.2rem"
        {...modalContentProps}
      >
        {isShowCloseIcon && (
          <Button
            position="absolute"
            top="1.8rem"
            right="1.8rem"
            width="2.4rem"
            height="2.4rem"
            p="0"
            bgColor="unset"
            _focus={{ backgroundColor: "unset !important" }}
            onClick={onClose}
            isDisabled={isLoading}
            {...iconCloseProps}
          >
            <SvgIcon src="/img/icon-close-menu.svg" />
          </Button>
        )}
        <Text fontSize="1.8rem" color="#000000CC" fontWeight="bold">
          {title}
        </Text>
        <Box mt="2rem">{content}</Box>

        {footer ? (
          footer
        ) : (
          <Flex justifyContent="right" mt="2.2rem">
            <Button
              onClick={onClose}
              isDisabled={isLoading}
              border="1px solid #A3A3A3"
              borderRadius={6}
              bg="#FFF"
              p="1.2rem 1.6rem"
              color="#009BE0"
              fontSize="1.4rem"
              _hover={{}}
            >
              {buttonCancel}
            </Button>
            <Button
              onClick={onProcessing}
              ml="0.8rem"
              bg="#009BE0"
              p="1.2rem 1.6rem"
              color="#FFF"
              fontSize="1.4rem"
              borderRadius={6}
              isLoading={isLoading}
              _hover={{}}
            >
              {buttonConfirm}
            </Button>
          </Flex>
        )}
      </ModalContent>
    </Modal>
  );
}

export default ConfirmModal;
