import { projectBimFileApi } from "apiClient/v2";
import { message } from "components/base";
import { DISPLAY_MODE } from "constants/forge";
import { Level, Sheet } from "interfaces/models";
import { cloneDeep } from "lodash";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLevels, setLevelSelected } from "redux/forgeViewerSlice";
import { setDataProjectDetail } from "redux/projectSlice";
import { RootState } from "redux/store";
import {
  getAllDbIds,
  getCurrentViewer,
  getExternalIdFromDbId,
} from "utils/forge";
import { calculatePositionOnSheet, find2DBounds } from "utils/forge/forge2d";

const MISSING_VIEWPORT_MESSAGE =
  "にビューポートが存在しないため、ピンの位置はずれることがあります。";
const INCORRECT_SHEET_MESSAGE =
  "ではピンの位置がずれています。2Dシートに図面枠が入っていない可能性があるため、再度シートを確認お願いします。";

export default function useCheckSheet() {
  const dispatch = useDispatch();
  const { dataProjectDetail } = useSelector(
    (state: RootState) => state.project
  );
  const {
    displayMode,
    levelSelected,
    familyInstances,
    isLoadedFamilyInstances,
    isLoadedViewerModelData,
    isLoadedSheetTransformRatio,
  } = useSelector((state: RootState) => state.forgeViewer);

  const guid = useMemo(() => {
    const is2d = displayMode === DISPLAY_MODE["2D"];

    return is2d
      ? levelSelected.sheetGuid ||
          levelSelected.sheets?.[0]?.guid ||
          levelSelected.guid ||
          ""
      : levelSelected.guid;
  }, [
    displayMode,
    levelSelected.guid,
    levelSelected.sheetGuid,
    levelSelected.sheets,
  ]);

  const isEqual = (pos1: THREE.Vector3, pos2: THREE.Vector3) => {
    const isFloatEqual = (a: number, b: number) => {
      const diff = Math.abs(a - b);
      const diffPercent = diff / Math.abs(a);

      return diffPercent >= 0.3;
    };

    return !isFloatEqual(pos1.x, pos2.x) || !isFloatEqual(pos1.y, pos2.y);
  };

  const currentSheet = useMemo(() => {
    const projectDetail = cloneDeep(dataProjectDetail);
    const levelData = projectDetail.levelData || {};
    const currentLevelData: Level = levelData[levelSelected.label || ""];
    if (!currentLevelData) {
      return;
    }
    const sheetData: Sheet[] = currentLevelData.sheets || [];

    return sheetData.find((sheet) => sheet.guid === guid);
  }, [dataProjectDetail, guid, levelSelected.label]);

  const handleIncorrectSheet = useCallback(() => {
    const projectDetail = cloneDeep(dataProjectDetail);

    // update level data
    const levelData = projectDetail.levelData || {};
    let newLevelData = null;
    Object.keys(levelData).forEach((levelId) => {
      const currentLevelData: Level = levelData[levelId];
      if (!currentLevelData) {
        return;
      }
      let levelSheets: Sheet[] = currentLevelData.sheets || [];
      const currentSheet = levelSheets.find((sheet) => sheet.guid === guid);
      if (
        !currentSheet ||
        currentSheet.isMissingViewport ||
        currentSheet.isIncorrectViewport
      ) {
        return;
      }
      levelSheets = levelSheets.map((sheet) => {
        if (sheet.guid === guid) {
          sheet.isIncorrectViewport = true;
        }

        return sheet;
      });
      currentLevelData.sheets = levelSheets;
      levelData[levelId] = currentLevelData;

      if (currentLevelData.guid === levelSelected.guid) {
        newLevelData = currentLevelData;
      }
    });
    if (newLevelData) {
      dispatch(setLevelSelected(newLevelData));
    }

    projectDetail.levelData = levelData;

    // update sheet data
    let sheetData = projectDetail.sheetData || [];
    sheetData = sheetData.map((sheet) => {
      if (sheet.guid === guid) {
        return {
          ...sheet,
          isIncorrectViewport: true,
        };
      }

      return sheet;
    });
    projectDetail.sheetData = sheetData;

    dispatch(setDataProjectDetail(projectDetail));
    dispatch(setLevels(Object.values(levelData)));

    projectBimFileApi.updateProject(projectDetail);
  }, [dataProjectDetail, dispatch, guid, levelSelected.guid]);

  useEffect(() => {
    if (
      displayMode !== DISPLAY_MODE["2D"] ||
      !guid ||
      !isLoadedViewerModelData ||
      !isLoadedSheetTransformRatio ||
      !isLoadedFamilyInstances ||
      !currentSheet
    ) {
      return;
    }
    if (currentSheet.isMissingViewport) {
      message.warning(`「${currentSheet.name}」${MISSING_VIEWPORT_MESSAGE}`);

      return;
    }
    if (currentSheet.isIncorrectViewport) {
      message.warning(`「${currentSheet.name}」${INCORRECT_SHEET_MESSAGE}`);

      return;
    }
    const viewer = getCurrentViewer();
    if (!viewer) {
      return;
    }
    const fragList = viewer.model.getFragmentList();
    const instanceTree = viewer.model.getInstanceTree();
    if (!fragList || !instanceTree) {
      return;
    }
    const dbIds = getAllDbIds();
    if (dbIds.length === 0) {
      message.warning(`「${currentSheet.name}」${INCORRECT_SHEET_MESSAGE}`);
      handleIncorrectSheet();

      return;
    }
    const maxCheck = Math.min(100, dbIds.length);
    let maxInvalid = Math.min(10, dbIds.length / 2);
    for (let i = 0; i < maxCheck; i++) {
      const externalId = getExternalIdFromDbId(dbIds[i])!;
      const instance = familyInstances[externalId];
      if (!instance) {
        continue;
      }
      const mappedPosition = calculatePositionOnSheet(instance.position);
      const currentPosition = find2DBounds(
        fragList,
        instanceTree,
        dbIds[i]
      ).getCenter();
      if (
        !currentPosition.equals(new THREE.Vector3(0, 0, 0)) &&
        !isEqual(mappedPosition, currentPosition)
      ) {
        maxInvalid--;
      }
      if (maxInvalid <= 0) {
        message.warning(`「${currentSheet.name}」${INCORRECT_SHEET_MESSAGE}`);
        handleIncorrectSheet();

        return;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    guid,
    displayMode,
    familyInstances,
    isLoadedFamilyInstances,
    isLoadedViewerModelData,
    isLoadedSheetTransformRatio,
  ]);
}
