import CustomCell from "components/editor-builder/component-preview/CustomCell";
import ImageCell from "components/editor-builder/component-preview/ImageCell";
import SubTableComponentPreview from "components/editor-builder/component-preview/TableComponentPreview/SubTableComponentPreview";
import { iUseGetContentOfCell } from "components/editor-builder/component-preview/TableComponentPreview/TableTdComponentPreview";
import { BlackboardDataDefaultOfFilterPhoto } from "constants/document";
import {
  CellPropertyDetail,
  CellPropertyDocumentDataForTableHeaderOptions,
  CellPropertyDocumentDataForTableOptions,
  CellPropertyDocumentTaskDataOptions,
  CellPropertyForBlackboardTemplateOptions,
  CellPropertyTaskItemDataOptions,
  DynamicFieldsDataTypeOptions,
  FamilyInstanceTypeOptions,
} from "constants/documentTemplate";
import {
  CellProperty,
  CheckboxDirectionOptionsEnum,
  CheckboxDisplayOptionsEnum,
  LinkedDataField,
  LinkedDynamicFieldsType,
  TableDefaultStyle,
  TemplateComponentType,
  TextPosition,
} from "constants/enum";
import { CellType, TemplateComponent } from "interfaces/models/component";
import { DocumentTemplate } from "interfaces/models/documentTemplate";
import { useCallback, useMemo } from "react";
import NormalTextPreview from "../../NormalTextPreview";

interface iGetContentEditorFromDocumentData {
  cell: CellType;
  component: TemplateComponent;
  currentTemplate: DocumentTemplate;
  isBlackboardTemplate?: boolean;
}

interface DataItems {
  icon: string;
  title: string;
}

const useGetContentTypeEditor = (props: iUseGetContentOfCell) => {
  const {
    cell,
    component,
    currentTemplate,
    zoomRatio,
    css,
    isBlackboardTemplate = false,
    isBlackboardTemplateImage,
    isTaskSheetTemplateItem = false,
    displaySize,
    width,
    height,
    limit,
    isLastColumn,
    isDuplicateRow,
    sizePageRatio,
    pageDirectionRatio,
    cellSize,
    isOnlyView,
    selectedCells,
    componentSelected,
    documentContainerSize,
    isComponentDragging,
    components,
    contentType,
    fontSize,
  } = props;

  const handleCheckBox = useCallback((cell: CellType) => {
    const { cellLinkedData } = cell;
    const valueOfCheckbox = cellLinkedData?.options?.valueOfCheckbox ?? "";
    const options = valueOfCheckbox.split("・");
    const numberOfCheckbox = cellLinkedData?.options?.numberOfCheckbox ?? 1;
    const displayOption = cellLinkedData?.options?.displayCheckbox;

    const isJustified = cell.style?.justifyContent === TextPosition.JUSTIFY;

    let dataItems: DataItems[] = [];
    let other: React.ReactNode = null;

    switch (displayOption) {
      case CheckboxDisplayOptionsEnum.Options:
        if (!isJustified) {
          dataItems = [{ icon: "", title: valueOfCheckbox }];
          break;
        }

        const newValueArr = valueOfCheckbox.split("");
        other = newValueArr.map((item, index) => (
          <p key={`${item}-${index}`} style={{ fontSize: 12 }}>
            {item}
          </p>
        ));
        break;

      case CheckboxDisplayOptionsEnum.Value:
        if (!isJustified) {
          dataItems = [{ icon: "", title: options[0] ?? "" }];
          break;
        }
        other = <p style={{ fontSize: 12 }}>{options[0]}</p>;
        break;

      default:
        for (let i = 0; i < numberOfCheckbox; i++) {
          dataItems.push({
            icon: CellPropertyDetail[CellProperty.CHECK_BOX].iconSrc,
            title: options[i] ?? "",
          });
        }
    }

    return { dataItems, other };
  }, []);

  const renderSubTable = useMemo(() => {
    return (
      <SubTableComponentPreview
        component={component}
        parentCell={cell}
        displaySize={{
          width:
            (width / component.size.width) * displaySize.width -
              TableDefaultStyle.DEFAULT_BORDER_SIZE || 0,
          height:
            (height / component.size.height) * displaySize.height -
              TableDefaultStyle.DEFAULT_BORDER_SIZE || 0,
        }}
        zoomRatio={zoomRatio}
        cellSize={cellSize}
        sizePageRatio={sizePageRatio}
        pageDirectionRatio={pageDirectionRatio}
        isOnlyView={isOnlyView}
        isDuplicateRow={isDuplicateRow}
        isParentLastColumn={isLastColumn}
        limit={limit}
        isBlackboardTemplateImage={isBlackboardTemplateImage}
        isTaskSheetTemplateItem={isTaskSheetTemplateItem}
        components={components}
        selectedCells={selectedCells}
        documentContainerSize={documentContainerSize}
        currentTemplate={currentTemplate}
        componentSelected={componentSelected}
        isComponentDragging={isComponentDragging}
        contentType={contentType}
      />
    );
  }, [
    contentType,
    isTaskSheetTemplateItem,
    isComponentDragging,
    componentSelected,
    components,
    selectedCells,
    documentContainerSize,
    currentTemplate,
    limit,
    isLastColumn,
    cellSize,
    displaySize,
    component,
    cell,
    width,
    height,
    zoomRatio,
    sizePageRatio,
    pageDirectionRatio,
    isOnlyView,
    isDuplicateRow,
    isBlackboardTemplateImage,
  ]);

  const getContentEditorFromDynamicFields = useCallback(
    (cell: CellType) => {
      let items: { icon: string; title: string }[] = [];
      let other = null;

      switch (cell?.cellLinkedData?.options?.dynamicFieldType) {
        case LinkedDynamicFieldsType.NUMBER:
        case LinkedDynamicFieldsType.TEXT:
          items = [
            {
              icon: "",
              title: "",
            },
          ];

          break;

        case LinkedDynamicFieldsType.FIXED_VALUE:
          items = [
            {
              icon: "",
              title: cell?.cellLinkedData?.options?.dynamicFieldValue || "",
            },
          ];
          break;

        case LinkedDynamicFieldsType.DATE_TIME:
          items = [
            {
              icon: "",
              title: cell?.cellLinkedData?.options?.dateFormat || "",
            },
          ];
          break;

        case LinkedDynamicFieldsType.USER_DROPDOWN:
          items = [
            {
              icon: CellPropertyDetail[CellProperty.DOCUMENT_DATA].iconSrc,
              title:
                DynamicFieldsDataTypeOptions.find(
                  (opt) => opt.value === LinkedDynamicFieldsType.USER_DROPDOWN
                )?.name || "",
            },
          ];
          break;

        case LinkedDynamicFieldsType.CHECK:
          const { dataItems: checkItems, other: otherCheck } =
            handleCheckBox(cell);
          items = checkItems;
          other = otherCheck;

          break;

        default:
          break;
      }

      return { dataItems: items, other };
    },
    [handleCheckBox]
  );

  const getContentEditorFromTaskItemData = useCallback(
    ({ cell }: iGetContentEditorFromDocumentData) => {
      let items: DataItems[] = [];

      const getOptionName = () => {
        const name = CellPropertyTaskItemDataOptions.find(
          (opt) => opt.value === cell?.cellLinkedData?.field
        )?.name;

        return name;
      };

      const title = getOptionName() || "";

      items = [
        {
          icon: title
            ? CellPropertyDetail[CellProperty.TASK_ITEM_DATA]?.iconSrc
            : "",
          title,
        },
      ];

      if (
        [
          LinkedDataField.TASK_ITEM_DATA.TASK_ITEM_FORGE_IMAGE,
          LinkedDataField.TASK_ITEM_DATA.TASK_ITEM_IMAGE,
        ].includes(cell.cellLinkedData?.field as any)
      ) {
        items[0].icon = "";
      }

      return { dataItems: items, other: null };
    },
    []
  );

  const getContentEditorFromDocumentTaskData = useCallback(
    ({ cell, component }: iGetContentEditorFromDocumentData) => {
      let items: DataItems[] = [];

      const getOptionName = () => {
        const name = CellPropertyDocumentTaskDataOptions.find(
          (opt) => opt.value === cell?.cellLinkedData?.field
        )?.name;

        return name;
      };

      if (!component?.linkedHeaderId) {
        const title = getOptionName() || "";

        items = [
          {
            icon: title
              ? CellPropertyDetail[CellProperty.DOCUMENT_TASK_DATA]?.iconSrc
              : "",
            title,
          },
        ];
      }

      return { dataItems: items, other: null };
    },
    []
  );

  const getContentEditorFromDocumentData = useCallback(
    ({
      cell,
      currentTemplate,
      component,
      isBlackboardTemplate,
    }: iGetContentEditorFromDocumentData) => {
      const { cellLinkedData } = cell ?? {};
      let items: DataItems[] = [];

      if (cellLinkedData?.type === CellProperty.TEXT) {
        return {
          dataItems: [],
          other: (
            <NormalTextPreview
              isAutoResize={false}
              isEditable={false}
              data={cell}
              zoomRatio={zoomRatio}
            />
          ),
        };
      }

      if (cellLinkedData?.type === CellProperty.IMAGE) {
        return {
          dataItems: [],
          other: (
            <ImageCell
              component={component}
              cellData={cell}
              zoomRatio={zoomRatio}
              isNormalImage={[
                TemplateComponentType.FilterPhoto,
                TemplateComponentType.LinkedImage,
              ].includes(component.type)}
            />
          ),
        };
      }

      if (cellLinkedData?.type == CellProperty.COMMENT) {
        return {
          dataItems: [],
          other: renderSubTable,
        };
      }

      if (cellLinkedData?.type == CellProperty.TASK_ITEM_HEADER) {
        return {
          dataItems: [],
          other: renderSubTable,
        };
      }

      if (cellLinkedData?.type === CellProperty.CHECK_BOX) {
        const dataCheck = handleCheckBox(cell);

        return {
          dataItems: dataCheck.dataItems,
          other: dataCheck.other,
        };
      }

      const getOptionName = () => {
        let name = CellPropertyDocumentDataForTableOptions.find(
          (option) => option.value === cellLinkedData?.field
        )?.name;

        if (cellLinkedData?.type === CellProperty.DYNAMIC_FIELDS_FOR_CATEGORY) {
          name = cellLinkedData?.options?.dynamicFieldLabel;
        }

        if (isBlackboardTemplate && !name) {
          name = CellPropertyForBlackboardTemplateOptions.find(
            (opt) => opt.value === cell?.cellLinkedData?.field
          )?.name;
        }

        return name;
      };

      if (
        !!component.linkedHeaderId ||
        component.type === TemplateComponentType.LinkedImage
      ) {
        const options =
          CellPropertyDocumentDataForTableHeaderOptions[
            currentTemplate.documentType
          ];
        const option = options?.find(
          (opt) => opt.value === cellLinkedData?.field
        );
        const isText = cellLinkedData?.type === CellProperty.TEXT;
        const iconByType =
          CellPropertyDetail[
            cellLinkedData?.type as keyof typeof CellPropertyDetail
          ]?.iconSrc;
        const titleByType = option?.name || "";

        items = [
          {
            icon: isText
              ? ""
              : iconByType
              ? iconByType
              : !option?.iconSrc
              ? ""
              : option?.iconSrc,
            title: !isText ? titleByType : "",
          },
        ];
      }

      if (
        !component?.linkedHeaderId &&
        component.type !== TemplateComponentType.LinkedImage
      ) {
        const title = getOptionName() || "";

        items = [
          {
            icon: title
              ? CellPropertyDetail[CellProperty.DOCUMENT_DATA]?.iconSrc
              : "",
            title,
          },
        ];
      }

      return { dataItems: items, other: null };
    },
    [handleCheckBox, renderSubTable, zoomRatio]
  );

  const contentOfCellTypeEditor = useCallback(
    (cell: CellType) => {
      let items: { icon: string; title: string }[] = [];

      if (cell?.subTable?.rows?.length) {
        return renderSubTable;
      }

      switch (cell.cellProperty) {
        case CellProperty.IMAGE:
          return (
            <ImageCell
              component={component}
              cellData={cell}
              zoomRatio={zoomRatio}
              isNormalImage={
                [
                  TemplateComponentType.FilterPhoto,
                  TemplateComponentType.LinkedImage,
                ].includes(component.type) && !component.detail?.isHasBlackboard
              }
            />
          );

        case CellProperty.BLACK_BOARD_DATA:
          let name = CellPropertyForBlackboardTemplateOptions.find(
            (opt) => opt.value === cell?.cellLinkedData?.field
          )?.name;
          if (component.type === TemplateComponentType.FilterPhoto && !name) {
            name = BlackboardDataDefaultOfFilterPhoto.find(
              (opt) => opt.field === cell?.cellLinkedData?.field
            )?.label;
          }

          items = [
            {
              icon: CellPropertyDetail[CellProperty.BLACK_BOARD_DATA].iconSrc,
              title: name || "",
            },
          ];
          break;

        case CellProperty.DOCUMENT_TASK_DATA:
          const {
            dataItems: documentTaskDataItems,
            other: documentTaskDataOther,
          } = getContentEditorFromDocumentTaskData({
            cell,
            component,
            currentTemplate,
            isBlackboardTemplate,
          });

          if (documentTaskDataOther) {
            return documentTaskDataOther;
          }
          items = documentTaskDataItems;
          break;

        case CellProperty.TASK_ITEM_DATA:
          const { dataItems: taskItemsData, other: taskItemsDataOther } =
            getContentEditorFromTaskItemData({
              cell,
              component,
              currentTemplate,
              isBlackboardTemplate,
            });

          if (taskItemsDataOther) {
            return taskItemsDataOther;
          }
          items = taskItemsData;
          break;

        case CellProperty.DOCUMENT_DATA:
          const { dataItems: documentDataItems, other: documentDataOther } =
            getContentEditorFromDocumentData({
              cell,
              component,
              currentTemplate,
              isBlackboardTemplate,
            });

          if (documentDataOther) {
            return documentDataOther;
          }
          items = documentDataItems;
          break;

        case CellProperty.FAMILY_INSTANCE:
          items = [
            {
              icon: CellPropertyDetail[CellProperty.INPUT_DATA].iconSrc,
              title:
                FamilyInstanceTypeOptions.find(
                  (opt) => opt.value === cell?.cellLinkedData?.field
                )?.name || "",
            },
          ];
          break;

        case CellProperty.DYNAMIC_FIELDS_FOR_ITEM:
          const { other, dataItems } = getContentEditorFromDynamicFields(cell);
          if (other) {
            return other;
          }

          items = dataItems;
          break;

        case CellProperty.TEXT:
          items = [
            {
              icon: cell.iconSrc || "",
              title: "",
            },
          ];
          break;

        case CellProperty.INPUT_DATA:
          items = [
            {
              icon:
                cell.iconSrc ||
                CellPropertyDetail[CellProperty.INPUT_DATA].iconSrc,
              title:
                cell.placeholder ||
                CellPropertyDetail[CellProperty.INPUT_DATA].name,
            },
          ];
          break;

        case CellProperty.CHECK_BOX:
          const { dataItems: checkItems, other: checkOther } =
            handleCheckBox(cell);
          if (checkOther) {
            return checkOther;
          }
          items = checkItems;
          break;

        default:
          return (
            <NormalTextPreview
              isAutoResize={false}
              isEditable={false}
              data={cell}
              zoomRatio={zoomRatio}
            />
          );
      }

      return (
        <CustomCell
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace={"nowrap"}
          items={items}
          justifyContent={css?.justifyContent}
          zoomRatio={zoomRatio}
          color={css?.color}
          fontSize={fontSize}
          direction={
            ((cell?.cellLinkedData?.options?.displayCheckbox ||
              CheckboxDisplayOptionsEnum.Checkbox) ===
              CheckboxDisplayOptionsEnum.Checkbox &&
              (cell?.cellLinkedData?.options
                ?.directionOfCheckbox as CheckboxDirectionOptionsEnum)) ||
            CheckboxDirectionOptionsEnum.HORIZONTAL
          }
        />
      );
    },
    [
      fontSize,
      isBlackboardTemplate,
      renderSubTable,
      component,
      currentTemplate,
      zoomRatio,
      css,
      getContentEditorFromTaskItemData,
      getContentEditorFromDocumentTaskData,
      handleCheckBox,
      getContentEditorFromDynamicFields,
      getContentEditorFromDocumentData,
    ]
  );

  return {
    contentOfCellTypeEditor,
  };
};

export default useGetContentTypeEditor;
