import { TYPE_USER } from "constants/app";
import { UserDTO } from "interfaces/dtos/UserDTO";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";

const useUserOfProject = () => {
  const [listUserById, setListUserById] = useState<{
    [key: string]: UserDTO;
  }>({});

  const { users } = useSelector((state: RootState) => state.user);
  const { dataProjectDetail } = useSelector(
    (state: RootState) => state.project
  );

  useEffect(() => {
    if (!users?.length) {
      return;
    }

    const newListUserById: { [key: string]: UserDTO } = {};

    users?.forEach((user: UserDTO) => {
      const isAuthor = user.id === dataProjectDetail?.userCreated;
      const isAdmin = user.role === TYPE_USER.ROLE_ADMIN;
      const isAssigned = dataProjectDetail?.personInCharge?.includes(
        user.id as string
      );
      const isPushed = isAdmin || isAuthor || isAssigned;

      if (isPushed) {
        newListUserById[user.id || ""] = user;
      }
    });
    setListUserById(newListUserById);
  }, [users, dataProjectDetail]);

  return { listUserById };
};

export default useUserOfProject;
