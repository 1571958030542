import { Box, Flex, FlexProps, Spinner, Stack, Text } from "@chakra-ui/react";
import {
  DocumentCategoryStatusType,
  MapDocumentCategoryStatusTypeColor,
  MapDocumentCategoryStatusTypeProgressColor,
} from "constants/enum";
import { DocumentCategoryDTO } from "interfaces/dtos/documentCategoryDTO";
import { DocumentItemDTO } from "interfaces/dtos/documentItemDTO";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";

interface Props {
  documentCategorySelected?: DocumentCategoryDTO;
  propListCounts?: FlexProps;
}

export default function DocumentCategoryProgress({
  documentCategorySelected,
  propListCounts,
}: Props) {
  const { isLoadingDocumentItem } = useSelector(
    (state: RootState) => state.document
  );

  return (
    <>
      <Flex flexDirection="row" width="100%">
        {Object.values(DocumentCategoryStatusType).map((type) => {
          const list = documentCategorySelected?.documentItems || [];
          const count = list.filter(
            (item: DocumentItemDTO) =>
              (!item.status &&
                type === DocumentCategoryStatusType.NotStarted) ||
              item.status === type
          ).length;
          const percent = !documentCategorySelected?.documentItems?.length
            ? type === DocumentCategoryStatusType.NotStarted
              ? 100
              : 0
            : (count / documentCategorySelected.documentItems?.length) * 100;

          return (
            <Box
              key={type}
              backgroundColor={MapDocumentCategoryStatusTypeProgressColor[type]}
              height="6px"
              w={`${percent}%`}
            ></Box>
          );
        })}
      </Flex>

      <Flex justifyContent="space-between" {...propListCounts}>
        <Text fontSize="1.4rem" fontWeight={500}>
          {Object.values(DocumentCategoryStatusType).map((type, typeIndex) => {
            const list = documentCategorySelected?.documentItems || [];
            const notSpred =
              typeIndex ===
              Object.values(DocumentCategoryStatusType).length - 1;

            return (
              <span
                key={type}
                style={{ color: MapDocumentCategoryStatusTypeColor[type] }}
              >
                {`${
                  list.filter(
                    (item: DocumentItemDTO) =>
                      (!item.status &&
                        type === DocumentCategoryStatusType.NotStarted) ||
                      item.status === type
                  ).length
                } ${notSpred ? "" : "/"} `}
              </span>
            );
          })}
          <span
            color={"#171717"}
            style={{ paddingLeft: "4px", wordBreak: "break-word" }}
          >
            {isLoadingDocumentItem ? (
              <Spinner size={"sm"} />
            ) : (
              `(${documentCategorySelected?.documentItems?.length ?? 0})`
            )}
          </span>
        </Text>
      </Flex>
    </>
  );
}
