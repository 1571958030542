import { useBoolean } from "@chakra-ui/react";
import { documentItemApi } from "apiClient/v2";
import { HandleSelectDrawImageProps } from "components/modal/DrawToolModal";
import {
  DocumentItemDTO,
  DocumentSubItemDTO,
} from "interfaces/dtos/documentItemDTO";
import { FileModel, FileUploadInfo } from "interfaces/models";
import { User } from "interfaces/models/user";
import { useCallback, useRef } from "react";
import { useDispatch } from "react-redux";
import { updateDocumentItem } from "redux/documentSlice";
import { updateElementInArray } from "utils/array";
import { fileToBase64, uploadFileToS3 } from "utils/file";

interface Props {
  currentUser?: User | null;
  subItem?: DocumentSubItemDTO;
  documentItemSelected?: DocumentItemDTO;
  originImgSelectedTemp: FileModel;
  isHasBlackboardWithOutPosition: boolean;
  imageCaptured: React.MutableRefObject<File | undefined>;

  setImage: React.Dispatch<React.SetStateAction<FileModel | undefined>>;
  onClosePositionBlackboardModal: () => void;
  onOpenPositionBlackboardModal: () => void;
  handleOpenCamera: () => void;
}

const useDrawImage = (props: Props) => {
  const {
    isHasBlackboardWithOutPosition,
    documentItemSelected,
    currentUser,
    subItem,
    originImgSelectedTemp,
    imageCaptured,
    setImage,
    handleOpenCamera,
    onClosePositionBlackboardModal,
    onOpenPositionBlackboardModal,
  } = props;

  const [isOpenDrawImage, setIsOpenDrawImage] = useBoolean();
  const isRecaptureImageRef = useRef(false);
  const dispatch = useDispatch();

  const handleOpenDrawImage = useCallback(() => {
    setIsOpenDrawImage.on();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseDrawImage = useCallback(() => {
    isRecaptureImageRef.current = false;
    setIsOpenDrawImage.off();
    onClosePositionBlackboardModal();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectDrawImage = useCallback(
    async ({ file, isEdited, originFile }: HandleSelectDrawImageProps) => {
      if (!documentItemSelected?.id || !subItem?.id) {
        return handleCloseDrawImage();
      }

      const images: FileUploadInfo | undefined = subItem?.images as any;
      const isHasOriginImgSelectedTemp = !!originImgSelectedTemp?.file;
      const isExistsOriginFile = !!images?.originSrc;
      const isRecaptureImage = isRecaptureImageRef.current;

      let lastModifiedDateCurrent: any;
      if (isEdited && !!images && !isRecaptureImage) {
        const lastModifiedDateFileFirst = images?.lastModifiedDateFile;
        const uploadTimeFileFirst = images?.uploadTime;

        lastModifiedDateCurrent = lastModifiedDateFileFirst
          ? new Date(lastModifiedDateFileFirst)
          : uploadTimeFileFirst
          ? new Date(uploadTimeFileFirst)
          : new Date();
      } else {
        lastModifiedDateCurrent = new Date(file.lastModified);
      }

      const fileClone = new File([file], file.name, {
        type: file.type,
        lastModified: lastModifiedDateCurrent.getTime(),
      });
      const src = await fileToBase64(fileClone);
      setImage((prev) => ({ ...prev, src, file: fileClone }));

      if (isHasBlackboardWithOutPosition) {
        handleCloseDrawImage();
        onOpenPositionBlackboardModal();
      } else {
        let isRemoveOrigin = false;
        let originFileModel: FileModel = undefined as any;
        if (isHasOriginImgSelectedTemp) {
          originFileModel = originImgSelectedTemp;
        } else if (isEdited && (!isExistsOriginFile || isRecaptureImage)) {
          originFileModel = { file: originFile, name: originFile.name };
        } else if (!isEdited && isRecaptureImage) {
          isRemoveOrigin = true;
        }

        const documentItem = structuredClone(documentItemSelected);
        let fileUploadInfo: FileUploadInfo = {} as any;
        fileUploadInfo.src = src;
        const promiseImages = [];
        promiseImages.push(
          uploadFileToS3(fileClone, fileClone?.name || "").then((src) => {
            fileUploadInfo = {
              src,
              uploadTime: new Date(),
              originSrc: fileUploadInfo.originSrc ?? images?.originSrc,
              lastModifiedDateFile: lastModifiedDateCurrent,
              userUpload: currentUser?.id || "",
            };
          })
        );
        if (isRemoveOrigin) {
          fileUploadInfo.originSrc = null as any;
        }
        if (originFileModel?.file) {
          promiseImages.push(
            uploadFileToS3(
              originFileModel.file,
              originFileModel?.file?.name || ""
            ).then((src) => {
              fileUploadInfo.originSrc = src;
            })
          );
        }

        await Promise.all(promiseImages);
        await documentItemApi.updateSubItem({
          id: subItem.id,
          itemId: subItem.itemId,
          images: fileUploadInfo,
        } as DocumentSubItemDTO);

        updateElementInArray({
          array: documentItem?.subItems || [],
          keyIndex: "id",
          element: {
            ...subItem,
            images: fileUploadInfo,
            isLoadingUpdateImage: true,
          },
        });
        dispatch(updateDocumentItem(documentItem));

        updateElementInArray({
          array: documentItem?.subItems || [],
          keyIndex: "id",
          element: {
            ...subItem,
            images: fileUploadInfo,
            isLoadingUpdateImage: false,
          },
        });
        dispatch(updateDocumentItem(documentItem));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      currentUser?.id,
      isHasBlackboardWithOutPosition,
      subItem,
      originImgSelectedTemp,
      documentItemSelected,
      handleCloseDrawImage,
      onOpenPositionBlackboardModal,
      onOpenPositionBlackboardModal,
      dispatch,
    ]
  );

  const handleRecaptureImage = useCallback(() => {
    isRecaptureImageRef.current = true;
    setIsOpenDrawImage.off();
    handleOpenCamera();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleOpenCamera]);

  const handleCloseDrawImageAfterSave = useCallback(() => {
    imageCaptured.current = undefined;
    setIsOpenDrawImage.off();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isOpenDrawImage,
    handleCloseDrawImage,
    handleOpenDrawImage,
    handleSelectDrawImage,
    handleRecaptureImage,
    handleCloseDrawImageAfterSave,
  };
};

export default useDrawImage;
