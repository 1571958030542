const readMatrixFromArray12 = (params: any) => {
  return (new THREE.Matrix4() as any).fromArray([
    params[0],
    params[1],
    params[2],
    0.0,
    params[3],
    params[4],
    params[5],
    0.0,
    params[6],
    params[7],
    params[8],
    0.0,
    params[9],
    params[10],
    params[11],
    1.0,
  ]);
};

const getViewportBounds = (vp: any, sheetUnitScale: number) => {
  const values = vp.geometryViewportRegion;
  if (!values) {
    return null;
  }
  const scale = getFeetToSheetUnits(sheetUnitScale);
  const boundsCorrection = 0.01;

  const res = new THREE.Box2();
  res.min.x = (values[0] + boundsCorrection) * scale;
  res.min.y = (values[1] + boundsCorrection) * scale;
  res.max.x = (values[3] - boundsCorrection) * scale;
  res.max.y = (values[4] - boundsCorrection) * scale;

  return res;
};

export const getFeetToSheetUnits = (sheetUnitScale: number) => {
  const FeetToMeter = 0.3048;
  const MeterToSheetUnits = 1.0 / sheetUnitScale;

  return FeetToMeter * MeterToSheetUnits;
};

const get3DTo2DModelSheetTransform = (vp: any, sheetUnitScale: number) => {
  if (!vp.modelToSheetTransform) {
    return null;
  }

  const values = vp.modelToSheetTransform;
  const matrix = readMatrixFromArray12(values);
  const feetToSheetUnits = getFeetToSheetUnits(sheetUnitScale);
  const scaleTf = new THREE.Matrix4().makeScale(
    feetToSheetUnits,
    feetToSheetUnits,
    feetToSheetUnits
  );
  matrix.multiplyMatrices(scaleTf, matrix);

  return matrix;
};

const remapRectangle = (
  xMinFrom: number,
  yMinFrom: number,
  xMaxFrom: number,
  yMaxFrom: number,
  xMinTo: number,
  yMinTo: number,
  xMaxTo: number,
  yMaxTo: number
) => {
  const scaleX = (xMaxTo - xMinTo) / (xMaxFrom - xMinFrom);
  const scaleY = (yMaxTo - yMinTo) / (yMaxFrom - yMinFrom);
  const offsetX = xMinTo - scaleX * xMinFrom;
  const offsetY = yMinTo - scaleY * yMinFrom;
  const matrix = new THREE.Matrix4();
  matrix.elements[0] = scaleX;
  matrix.elements[5] = scaleY;
  matrix.elements[10] = scaleX;
  matrix.elements[12] = offsetX;
  matrix.elements[13] = offsetY;

  return matrix;
};

const getInverseViewportRotation = (viewRotationType: number) => {
  const matrix = new THREE.Matrix4();
  switch (viewRotationType) {
    case 0:
      return matrix;
    case 1: {
      matrix.elements[0] = 0;
      matrix.elements[4] = -1;
      matrix.elements[12] = 1;
      matrix.elements[1] = 1;
      matrix.elements[5] = 0;
      break;
    }
    case 2: {
      matrix.elements[0] = 0;
      matrix.elements[4] = 1;
      matrix.elements[1] = -1;
      matrix.elements[5] = 0;
      matrix.elements[13] = 1;
      break;
    }
    default:
      console.warn(
        "Unexpected enum value for view rotation: ",
        viewRotationType
      );
  }

  return matrix;
};

export const compute2Dto3DTransform = (vp: any, sheetUnitScale: number) => {
  const sheetRegion = getViewportBounds(vp, sheetUnitScale);
  if (!sheetRegion) {
    return;
  }
  const sectionBox = vp.sectionBox;
  const sectionBoxTransform = readMatrixFromArray12(sectionBox.transform);
  const sheetToViewport = remapRectangle(
    sheetRegion.min.x,
    sheetRegion.min.y,
    sheetRegion.max.x,
    sheetRegion.max.y,
    0,
    0,
    1,
    1
  );
  const vpRotationInv = getInverseViewportRotation(vp.viewportRotation);
  const viewportToSectionBox = remapRectangle(
    0,
    0,
    1,
    1,
    sectionBox.min.x,
    sectionBox.min.y,
    sectionBox.max.x,
    sectionBox.max.y
  );
  const matrix = sectionBoxTransform
    .multiply(viewportToSectionBox)
    .multiply(vpRotationInv)
    .multiply(sheetToViewport);

  return matrix;
};

export const get3DTo2DMatrix = (vp: any, sheetUnitScale: number) => {
  let matrix = get3DTo2DModelSheetTransform(vp, sheetUnitScale);

  if (matrix) {
    return matrix;
  }

  matrix = compute2Dto3DTransform(vp, sheetUnitScale);

  return matrix.invert();
};
