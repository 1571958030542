import { useMediaQuery } from "@chakra-ui/react";

const useDetectDevice = () => {
  const [isMobile] = useMediaQuery("(max-width:576px)");
  const [isTablet] = useMediaQuery("(min-width:768px) and (max-width:992px)");
  const [isLaptop] = useMediaQuery(
    "(min-width: 1200px) and (max-width: 1400px)"
  );
  const [isDesktop] = useMediaQuery("(min-width: 1400px)");

  return { isMobile, isTablet, isLaptop, isDesktop };
};

export const useDetectDeviceOS = () => {
  const platForm = navigator.platform;

  return {
    isMacOS: platForm.indexOf("Mac") > -1,
    isWindowOS: platForm.indexOf("Win") > -1,
  };
};

export default useDetectDevice;
