import { DEFAULT_COLOR } from "components/ui/ColorPicker";
import { ORIENTATION_TYPE } from "constants/enum";
import { SHA256 } from "crypto-js";
import fromEntries from "fromentries";
import ReactDOMServer from "react-dom/server";
import { logError } from "utils/logs";
import { flow, cloneDeep } from "lodash";
import { v4 as uuidV4, validate } from "uuid";
import { getLocalStorage } from "./storage";
import isEqual from "lodash/isEqual";

const MAX_CACHE_KEY_LENGTH = 3_000;

export const addCacheStorage = async ({
  cacheName,
  request,
  response,
}: {
  cacheName: string;
  request: Request;
  response: Response;
}) => {
  let cache = await caches.open(cacheName);
  let page = 0;
  let cacheKeys = await cache.keys();
  while (cacheKeys.length >= MAX_CACHE_KEY_LENGTH) {
    page++;
    cache = await caches.open(`${cacheName}-${page}`);
    cacheKeys = await cache.keys();
  }
  await cache.put(request, response);
};

export const matchCacheStorage = async ({
  cacheName,
  cacheKey,
  options = {},
}: {
  cacheName: string;
  cacheKey: Request;
  options?: CacheQueryOptions;
}) => {
  let cache = await caches.open(cacheName);
  let page = 0;
  let cacheKeys = await cache.keys();
  let match = await cache.match(cacheKey, options);
  while (!match && cacheKeys.length >= MAX_CACHE_KEY_LENGTH) {
    page++;
    cache = await caches.open(`${cacheName}-${page}`);
    cacheKeys = await cache.keys();
    match = await cache.match(cacheKey);
  }

  return match;
};

export const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const isNullOrEmpty = (
  value?: number | string | null
): value is null | undefined => {
  return (value ?? "") === "";
};

export const StringFormat = (str: string, ...args: string[]) =>
  str.replace(/{(\d+)}/g, (_match, index) => args[index] || "");

export const removeDuplicates = <T = string | number>(arr: T[]) => {
  const s = new Set(arr);
  const it = s.values();

  return Array.from(it);
};

export const parseQueryParams = <
  T = {
    [k: string]: string;
  }
>(
  search: string
) => {
  const urlSearchParams = new URLSearchParams(search);

  return fromEntries(urlSearchParams.entries()) as unknown as T;
};

export const removePropNull = (data: { [key: string]: any }) => {
  Object.keys(data).forEach((k) => {
    if (data[k] === null) {
      delete data[k];
    }
  });
};

export const removeEmptyProp = (data: { [key: string]: any }) => {
  Object.keys(data).forEach((k) => {
    if (data[k] === "" || data[k] === null || data[k] === undefined) {
      delete data?.[k];
    }
  });
};

export const tryJsonParse = (str: string) => {
  try {
    return JSON.parse(str);
  } catch {
    return;
  }
};

export const timeoutFn = async (fn: any, ms: any, ...args: any) => {
  await new Promise((resolve) => setTimeout(resolve, ms));

  return fn(...args);
};

export const PATH_IMAGES = [
  "jpg",
  "JPG",
  "png",
  "PNG",
  "jpeg",
  "JPEG",
  "jfif",
  "JFIF",
  "WebP",
  "webp",
  "GIF",
  "gif",
  "DNG",
  "dng",
  "psg",
  "PSG",
];

export const AudioType = "mp3";

export const debounce = (func: Function, delay: number = 400) => {
  let timmerId: ReturnType<typeof setTimeout>;

  return (...args: any[]) => {
    if (timmerId) {
      clearTimeout(timmerId);
    }
    timmerId = setTimeout(() => func(args), delay);
  };
};

export const getByValue = (map: any, searchValue: number | string) => {
  for (const key in map) {
    if (map[key] === searchValue) return key;
  }
};

export function hexToRgbA(hex: string, opacity = 1) {
  let c: any;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = `0x${c.join("")}`;

    return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(
      ","
    )},${opacity})`;
  }

  return DEFAULT_COLOR;
}

export function jsxToString(element: React.ReactElement | JSX.Element): string {
  return ReactDOMServer.renderToStaticMarkup(element);
}

export function uuid() {
  return uuidV4();
}

export const copyTextToClipboard = async (text: string) => {
  if (!navigator.clipboard) {
    return;
  }
  try {
    await navigator.clipboard.writeText(text);
  } catch (err) {
    logError({ err });
  }
};

export const gcd = (a: number, b: number): number => {
  return b ? gcd(b, a % b) : Math.abs(a);
};

export const lcm = (a: number, b: number) => {
  return (a * b) / gcd(a, b);
};

export const mergeObject = (a: any, b: any) => {
  const res = {} as any;
  Object.keys({ ...a, ...b }).forEach((key) => {
    res[key as keyof typeof res] = b?.[key] ?? a?.[key];
  });

  return res;
};
/**
 * It formats an object key for camelcase
 * @param {string} key - The key to be formatted.
 * @param {string} separator - The separator to split the key by.
 * @returns A new key
 */
export const formatKey = (key: string, separator: string = " ") => {
  let newKey = "";

  if (!key) {
    return "";
  }

  key.split(separator).forEach((key, index) => {
    newKey +=
      index === 0
        ? `${key?.[0]?.toLowerCase()}${key?.slice(1)}`
        : `${key?.[0]?.toUpperCase()}${key?.slice(1)}`;
  });

  return newKey;
};

export const areArraysEqual = (arr1: any[], arr2: any[]): boolean => {
  if (arr1.length !== arr2.length) return false;

  return arr1.every((item) => arr2.includes(item));
};

export const sha256 = (data: string) => {
  return SHA256(data).toString();
};

export const wait = (delay: number) => {
  return new Promise((resolve) => setTimeout(resolve, delay));
};

export const removeFalsyProps = (obj: { [key: string]: any }) => {
  const data = flow([
    Object.entries,
    (arr) => arr.filter(([_, value]: any) => value),
    Object.fromEntries,
  ])(obj);

  return data;
};

export const safelyParseJSON = (json: string) => {
  try {
    return JSON.parse(json);
  } catch (err) {
    return {};
  }
};

export const getNetworkStatus = () => {
  let isOnline = navigator.onLine;
  if (isOnline) {
    const storedData = getLocalStorage("persist:app");

    isOnline =
      typeof storedData?.isOnline !== "undefined"
        ? storedData.isOnline === "true"
        : isOnline;
  }

  return isOnline;
};

export const getOrientationType = (orientation: number): ORIENTATION_TYPE => {
  const absOrientation = Math.abs(orientation);
  if (absOrientation === 0 || absOrientation === 180) {
    return ORIENTATION_TYPE.PORTRAIT;
  }

  if (absOrientation === 90) {
    return ORIENTATION_TYPE.LANDSCAPE;
  }

  return ORIENTATION_TYPE.PORTRAIT;
};

export const validateBodyReq = (body: { [key: string]: any }) => {
  const data = cloneDeep(body);
  for (const key in data) {
    if (data[key] === "") data[key] = null as any;
  }

  return data;
};
export const validateListString = (arr: any[]) => {
  return arr.filter((e) => typeof e === "string" && e);
};

export const validateListUuid = (arr: any[]) => {
  return arr.filter((e) => typeof e === "string" && validate(e));
};

export const differentTwoArray = (arr1: any[], arr2: any[]) => {
  const set1 = new Set(arr1);
  const set2 = new Set(arr2);

  return (
    isEqual(set1.size, set2.size) && [...set1].every((value) => set2.has(value))
  );
};
