import dayjs, { ConfigType, Dayjs, OpUnitType } from "dayjs";
import "dayjs/locale/ja";
import isBetween from "dayjs/plugin/isBetween";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import localizedFormat from "dayjs/plugin/localizedFormat";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import kanjidate from "kanjidate";

dayjs.locale("ja");
dayjs.extend(isBetween);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

export const jpTimeZone = "Asia/Tokyo";

export const DEFAULT_FORMAT_DATE_DISPLAY = "YYYY年MM月DD日";
export const DATE_TIME_VERSION_FORMAT = "YYYY-MM-DD HH:mm:ss";
export const DATE_TIME_WITHOUT_SECONDS_FORMAT = "YYYY-MM-DD-HH:mm";
export const DATE_TIME_SLASH_WITHOUT_SECONDS_FORMAT = "YYYY/MM/DD HH:mm";
export const DATE_FORMAT = "YYYY/MM/DD";
export const DATE_TIME_VERSION_FORMAT_SELECT = "YYYYMMDDHHmmss";
export const ValidFormat = [
  "YYYYMMDDHHmmss",
  "YYYYMMDDHHmm",
  "YYYYMMDD",
  "YYYY/MM/DD",
  "YYYY/MM",
  "YYYYMM",
];
export const DATE_DAY_OF_WEEK_FORMAT = "YYYY/MM/DD（ddd）HH:mm:ss";

export interface DateRange {
  startDate?: ConfigType;
  endDate?: ConfigType;
}

/**
 * @param value The date needs formatting.
 * Default format: YYYY.MM.DD
 */
export const formatDate = (
  date?: ConfigType,
  format = DATE_DAY_OF_WEEK_FORMAT
): string => {
  const mDate = dayjs(date ?? "");

  if (mDate.isValid()) {
    return mDate.tz("Asia/Tokyo").format(format);
  }

  return "";
};

export const transformDataToJp = (date?: ConfigType): Dayjs => {
  const mDate = dayjs(date ?? "");

  if (mDate.isValid()) {
    const tz = mDate.tz("Asia/Tokyo");

    return tz;
  }

  return "" as any;
};

export const formatDateJp = (date?: Date): string => {
  const mDate = dayjs(date ?? "", ValidFormat);
  if (date && mDate.isValid()) {
    return kanjidate.format(kanjidate.f2, new Date(date));
  }

  return "-";
};

export const checkIsExpired = (
  date: Date | string,
  unitType: OpUnitType = "milliseconds"
) => {
  if (!date || !dayjs(date).isValid()) {
    return;
  }

  return !dayjs().isBefore(date, unitType);
};

export const formatDateCurrent = (
  date?: ConfigType,
  format = "YYYY-MM-DD"
): string => {
  const mDate = dayjs(date ?? "");

  if (mDate.isValid()) {
    return mDate.tz().format(format);
  }

  return "";
};

export const formatTime = (date?: Date | string) => {
  if (!date) return dayjs().format("LT");

  return dayjs(date).format("LT");
};

export const getDaysFromNow = (date: Date) => {
  const now = dayjs();
  const days = now.diff(dayjs(date), "days");

  if (isNaN(days) || !date) {
    return null;
  }

  return days;
};

/**
 * This function checks if a target date is between a start date and an end date.
 * @param  - - `targetDate`: The date that needs to be checked if it falls between the `startDate` and
 * `endDate`.
 * @returns a boolean value.
 */
export const checkIsBetween = ({
  targetDate,
  startDate,
  endDate,
  unit = "day",
}: {
  targetDate: ConfigType;
  startDate?: ConfigType;
  endDate?: ConfigType;
  unit?: OpUnitType;
}) => {
  if (!startDate && !endDate) {
    return true;
  }

  if (!targetDate) {
    return false;
  }

  if (startDate && endDate) {
    return dayjs(targetDate).isBetween(
      dayjs(startDate),
      dayjs(endDate),
      unit,
      "[]"
    );
  }

  if (!startDate) {
    return dayjs(targetDate).isSameOrBefore(dayjs(endDate), unit);
  }

  if (!endDate) {
    return dayjs(targetDate).isSameOrAfter(dayjs(startDate), unit);
  }
};

/**
 * This function takes two date ranges and returns the intersected date range
 * by comparing the start and end dates of each range.
 */
export const getIntersectedDateRange = (
  firstDateRange: DateRange,
  secondDateRange: DateRange
) => {
  const returnedDateRange: DateRange = {
    startDate: null,
    endDate: null,
  };

  if (
    !firstDateRange?.startDate &&
    !firstDateRange?.endDate &&
    !secondDateRange?.startDate &&
    !secondDateRange?.endDate
  ) {
    return returnedDateRange;
  }

  if (!firstDateRange?.startDate && !firstDateRange?.endDate) {
    return secondDateRange;
  }

  if (!secondDateRange?.startDate && !secondDateRange?.endDate) {
    return firstDateRange;
  }

  if (
    dayjs(firstDateRange?.endDate).isSameOrBefore(
      dayjs(secondDateRange?.startDate),
      "day"
    ) ||
    dayjs(secondDateRange?.endDate).isSameOrBefore(
      dayjs(firstDateRange?.startDate),
      "day"
    )
  ) {
    return {
      startDate: "",
      endDate: "",
    };
  }

  Object.keys(returnedDateRange)?.forEach((key) => {
    const _key = key as keyof typeof returnedDateRange;

    if (!firstDateRange?.[_key]) {
      if (secondDateRange?.[_key]) {
        returnedDateRange[_key] = secondDateRange?.[_key];
      }
    } else {
      if (!secondDateRange?.[_key]) {
        returnedDateRange[_key] = firstDateRange?.[_key];
      } else {
        const funcName = _key === "startDate" ? "isAfter" : "isBefore";

        if (
          dayjs(firstDateRange?.[_key])[funcName](
            dayjs(secondDateRange?.[_key]),
            "day"
          )
        ) {
          returnedDateRange[_key] = firstDateRange?.[_key];
        } else {
          returnedDateRange[_key] = secondDateRange?.[_key];
        }
      }
    }
  });

  return returnedDateRange;
};

/**
 * This function returns the first and last date of the current month
 */
export const getFirstAndLastDateOfMonth = () => {
  const lastDate = dayjs().endOf("month");
  const firstDate = dayjs().startOf("month");

  return {
    lastDate,
    firstDate,
  };
};

export const getDaypickerDate = (date: string | Date) => {
  if (!date || !dayjs(date).isValid()) {
    return undefined;
  }

  return dayjs(date).tz(jpTimeZone).toISOString();
};

export const formatStringDate = (val: string | undefined) => {
  if (!val) {
    return;
  }

  return Math.floor(Date.parse(val) / 1000);
};

/**
 * This function returns the current date and time in the "ja-JP" locale and "Asia/Tokyo"
 * timezone.
 */
export const now = () => {
  return dayjs().tz(jpTimeZone).toISOString();
};

export const convertStringToDate = (dateString: string) => {
  const [datePart, timePart] = dateString.split(" ");
  const [year, month, day] = datePart.split(":").map(Number);
  const [hour, minute, second] = timePart.split(":").map(Number);

  const dateObject = new Date(year, month - 1, day, hour, minute, second);

  return dateObject;
};
