import { useDisclosure } from "@chakra-ui/react";
import { documentTaskApi } from "apiClient/v2";
import { message } from "components/base";
import { iKeyLabel } from "components/ui/KeyLabel";
import { BASE_S3_URL } from "constants/app";
import { Axis, InspectionItemType, SystemModeType } from "constants/enum";
import {
  ALL_LEVEL_LABEL,
  DISPLAY_MODE,
  FORGE_SHEET_FOLDER_NAME,
  SHEET_PREFIX,
} from "constants/forge";
import { S3_PATH } from "constants/s3";
import { OPERATION } from "constants/task";
import { TaskDTO } from "interfaces/dtos/taskDTO";
import { FilterDataType, Level } from "interfaces/models";
import { DataProjectModel } from "interfaces/models/dataProjectModel";
import { DocumentTask, MainImageData } from "interfaces/models/documentTask";
import { ProjectDetail } from "interfaces/models/project";
import { TaskSheetTemplate } from "interfaces/models/taskSheetTemplate";
import isEqual from "lodash/isEqual";
import {
  checkCalculatePositonKeyplanByGuid,
  checkDuplicateDocumentTaskTitle,
  checkIsTaskLabelChangeStatusAndPosition,
  getKeyLabelProps,
  getLastIndexDuplicateTitle,
  getTaskLabelOfDocumentTask,
  transformDocumentTitle,
  uploadTaskListToS3,
} from "models/documentTask";
import { handleSelectTask } from "models/task";
import { toCanvas } from "qrcode";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { setDisplayMode, setSystemMode } from "redux/forgeViewerSlice";
import { setDataProjectDetail } from "redux/projectSlice";
import { RootState } from "redux/store";
import { addDocumentTasks } from "redux/taskSlice";
import { routePath } from "routes/path";
import { sleep } from "utils/common";
import { downloadObject, removeFileS3, uploadFileToS3 } from "utils/file";
import {
  captureKeynote,
  clearForgeSelection,
  getCurrentViewer,
  getDbIdByExternalId,
  getSizeOfModelBoundingBox,
  iSetSelectionMutilColorByDbId,
} from "utils/forge";
import { CustomLabelExtension } from "utils/forge/extensions/custom-label";

interface iProps {
  isLoadedViewerModelData: boolean;
  levelSelected: Level;
  displayMode?: DISPLAY_MODE;
  systemMode: string;
  dataProjectDetail: DataProjectModel;
  projectDetail: ProjectDetail;
  listTaskToDisplay: TaskDTO[];
  bimFileId: string;
  tasks: TaskDTO[];
  isLoadedSheetTransformRatio: boolean;
  documentTasksByLevel: DocumentTask[];
  taskSelected?: TaskDTO;
  taskSheetTemplateList: TaskSheetTemplate[];
  mapTaskType: { [key: string]: string };
  handleChangeSheet: (id: string, isSaveData?: boolean) => Promise<void>;
}

const useTaskSheet = ({
  systemMode,
  taskSheetTemplateList,
  taskSelected,
  documentTasksByLevel,
  isLoadedViewerModelData,
  levelSelected,
  displayMode,
  projectDetail,
  listTaskToDisplay,
  bimFileId,
  tasks,
  isLoadedSheetTransformRatio,
  dataProjectDetail,
  mapTaskType,
  handleChangeSheet,
}: iProps) => {
  const [documentTask, setDocumentTask] = useState<DocumentTask | null>(null);
  const [isCaptureKeyplan, setIsCaptureKeyplan] = useState<boolean>(false);
  const [loadingExportSheetTask, setLoadingExportSheetTask] =
    useState<boolean>(false);
  const [qrCodeImage, setQRCodeImage] = useState<string>("");
  const [operation] = useState(
    new URLSearchParams(window.location.search).get("operation")
  );
  const [listTaskFromS3, setListTaskFromS3] = useState<TaskDTO[]>(null as any);
  const {
    dataProjectDetail: { projectId },
  } = useSelector((state: RootState) => state.project);
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();

  const {
    isOpen: isOpenTaskSheet,
    onToggle: onToggleOpenTaskSheet,
    onClose: onCloseTaskSheet,
    onOpen: onOpenTaskSheet,
  } = useDisclosure();

  const isDisableCaptureKeyplan = useMemo(
    () =>
      !isLoadedViewerModelData || !tasks.length || !isLoadedSheetTransformRatio,
    [tasks?.length, isLoadedViewerModelData, isLoadedSheetTransformRatio]
  );

  const isCaptureKeyplanByOperation = useMemo(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const data: { documentTaskId: string; guid: string } = JSON.parse(
      urlParams.get("data") || "{}"
    );

    return (
      data?.documentTaskId &&
      data?.guid &&
      operation === OPERATION.CaptureKeyplan
    );
  }, [operation]);

  useEffect(() => {
    if (systemMode === SystemModeType.Document) {
      onCloseTaskSheet();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [systemMode]);

  useEffect(() => {
    (async () => {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const data: { documentTaskId: string; guid: string } = JSON.parse(
        urlParams.get("data") || "{}"
      );

      if (!isCaptureKeyplanByOperation) {
        return;
      }

      onToggleOpenTaskSheet();
      setLoadingExportSheetTask(true);

      dispatch(setSystemMode(SystemModeType.Task));
      const documentTask = await documentTaskApi.getDocumentTask(
        data?.documentTaskId
      );

      if (!documentTask?.id) {
        onCloseTaskSheet();
        setLoadingExportSheetTask(false);
        navigate(window.location.pathname, { replace: true });

        return;
      }

      onChangeKeyplanSheet(data?.guid, documentTask);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCaptureKeyplanByOperation]);

  useEffect(() => {
    if (!isCaptureKeyplan || isDisableCaptureKeyplan) {
      return;
    }
    handleDisplayTaskSheet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDisableCaptureKeyplan, isCaptureKeyplan]);

  const onGotoForgeViewPage = useCallback(
    (newParams?: { operation: string; data: string }) => {
      setListTaskFromS3(null as any);
      setDocumentTask(null);
      onCloseTaskSheet();

      let url = `/${projectId}/forge-viewer/${params.bimFileId}/${params.version}`;

      if (newParams) {
        url += `?operation=${newParams.operation}${
          newParams?.data ? `&data=${newParams.data}` : ""
        }`;
      }

      navigate(url);
    },
    [navigate, params, projectId, onCloseTaskSheet]
  );

  const handleCloseTaskSheet = () => {
    setListTaskFromS3(null as any);
    setDocumentTask(null);
    if (isCaptureKeyplanByOperation) {
      navigate(
        generatePath(routePath.DocumentTaskList, {
          projectBimFileId: dataProjectDetail.id,
        })
      );
    } else {
      onCloseTaskSheet();
    }
  };

  const onChangeKeyplanRatio = useCallback(async (axis: Axis) => {
    setDocumentTask((prev) => {
      if (!prev) {
        return prev;
      }

      let mainImageData: MainImageData = prev.mainImageData || {};
      const taskLabelOfDocumentTask = getTaskLabelOfDocumentTask(prev);
      const taskLabelByGuid = taskLabelOfDocumentTask?.taskLabelByGuid;
      const guid = mainImageData?.guid;

      if (!taskLabelByGuid) {
        return prev;
      }

      mainImageData = {
        ...mainImageData,
        mapTaskLabelsByGuid: {
          ...mainImageData?.mapTaskLabelsByGuid,
          [guid]: {
            ...taskLabelByGuid,
            axis,
          },
        },
      };

      return {
        ...prev,
        mainImageData,
      };
    });
  }, []);

  const onChangeKeyplanSheet = useCallback(
    async (value: string | undefined, initDocumentTask?: DocumentTask) => {
      const _documentTask = initDocumentTask || documentTask;
      let mainImageData: MainImageData =
        _documentTask?.mainImageData || ({} as any);
      const guid = value ?? mainImageData?.guid;

      if (
        (mainImageData?.guid === guid && !initDocumentTask) ||
        !_documentTask?.id
      ) {
        if (isCaptureKeyplanByOperation) {
          onCloseTaskSheet();
          setLoadingExportSheetTask(false);
          message.error("Error capture sheet");

          navigate(window.location.pathname, { replace: true });
        }

        return;
      }

      mainImageData = { ...mainImageData, guid };
      const body: DocumentTask = {
        ..._documentTask,
        id: _documentTask.id,
        mainImageData: mainImageData,
      };

      setDocumentTask(body);

      const {
        isDiffStatus: isDiffTasksStatus,
        isDiffPosition: isDiffTasksPosition,
      } = checkIsTaskLabelChangeStatusAndPosition(body, tasks);

      const isCalculatePostion = checkCalculatePositonKeyplanByGuid({
        guid,
        documentTask: _documentTask,
      });

      if (
        isCalculatePostion &&
        !initDocumentTask &&
        !isDiffTasksStatus &&
        !isDiffTasksPosition
      ) {
        setLoadingExportSheetTask(false);
        documentTaskApi.createUpdateDocumentTask(body);

        return;
      }

      if (guid === DISPLAY_MODE["3D"]) {
        dispatch(setDisplayMode(DISPLAY_MODE["3D"]));
      } else {
        dispatch(setDisplayMode(DISPLAY_MODE["2D"]));
        handleChangeSheet(guid, false);
      }

      setLoadingExportSheetTask(true);
      await sleep(100);
      setIsCaptureKeyplan(true);
      navigate(window.location.pathname, { replace: true });
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleChangeSheet, documentTask, dispatch, projectDetail]
  );

  const handleDisplayTaskSheet = useCallback(async () => {
    if (displayMode === DISPLAY_MODE["3D"] && !!levelSelected?.sheets?.length) {
      onOpenTaskSheet();
      setLoadingExportSheetTask(true);

      dispatch(setDisplayMode(DISPLAY_MODE["2D"]));

      await sleep(100);
      setIsCaptureKeyplan(true);

      return;
    }

    // open task sheet
    onOpenTaskSheet();
    setLoadingExportSheetTask(true);
    const viewer = getCurrentViewer();
    if (!viewer) {
      return;
    }

    let cloneListTaskToDisplay = structuredClone(listTaskToDisplay);
    const isHasTaskSelected = !!taskSelected?.id;
    const customLabelExtension: CustomLabelExtension = viewer?.getExtension(
      "CustomLabelExtension"
    ) as any;

    let taskIds = cloneListTaskToDisplay?.map((task) => task.id || "");

    // show current task label of documentTask
    if (documentTask?.id && !isEqual(taskIds, documentTask?.taskIds)) {
      await sleep(1000);
      const url =
        documentTask?.taskListUrl ||
        `${BASE_S3_URL}${S3_PATH.TaskSheet}/${documentTask.id}.json`;
      const tasksOfDocumentTask = (await downloadObject(url).catch(
        () => []
      )) as TaskDTO[];
      if (tasksOfDocumentTask.length) {
        cloneListTaskToDisplay = tasksOfDocumentTask;
      }

      taskIds = cloneListTaskToDisplay?.map((task) => task.id || "");

      customLabelExtension.showLabels({
        mode: SystemModeType.Task,
        data: cloneListTaskToDisplay.map((task) => ({
          id: task.id,
          indexId: task.indexId,
          position: task.position,
          title: mapTaskType[task.taskTypeId || ""] || "-",
          status: task.status,
          externalId: task.externalId,
        })),
      });
    }

    const MapInspectionItemColorVector4 = {
      [InspectionItemType.Confirmed]: new THREE.Vector4(
        0.00784313725490196,
        0.5176470588235295,
        0.7803921568627451,
        1
      ),
      [InspectionItemType.Defect]: new THREE.Vector4(
        0.8627450980392157,
        0.14901960784313725,
        0.14901960784313725,
        1
      ),
      [InspectionItemType.Treated]: new THREE.Vector4(
        0.592156862745098,
        0.4,
        0.6549019607843137,
        1
      ),
      "": new THREE.Vector4(1, 1, 1, 1),
    };
    // handle capture sheet
    const selections: iSetSelectionMutilColorByDbId["selections"] =
      cloneListTaskToDisplay.map((task) => ({
        dbId: getDbIdByExternalId(task.externalId),
        color:
          MapInspectionItemColorVector4[
            (task.status || InspectionItemType.Defect) as InspectionItemType
          ],
      }));

    const capture = await captureKeynote(selections);

    if (!isLoadedViewerModelData || !capture) {
      !capture && setLoadingExportSheetTask(false);

      return;
    }

    const guid =
      levelSelected?.sheetGuid ||
      levelSelected?.sheets?.[0]?.guid ||
      DISPLAY_MODE["3D"];

    // upload image
    const blob = await fetch(capture.blobUrl).then((r) => r.blob());
    const file = new File([blob as Blob], `${SHEET_PREFIX}-${guid}.png`, {
      type: "image/png",
    });
    const imageUrl =
      (await uploadFileToS3(file, file.name || "", FORGE_SHEET_FOLDER_NAME)) ||
      "";

    // get task label info
    let { width: widthOfModelBoundingBox, height: heightOfModelBoundingBox } =
      getSizeOfModelBoundingBox();
    if (displayMode === DISPLAY_MODE["3D"]) {
      widthOfModelBoundingBox = capture.imageWidth;
      heightOfModelBoundingBox = capture.imageHeight;
    }

    const offsetX =
      (Number(capture?.canvas?.offsetWidth) - widthOfModelBoundingBox) / 2;
    const offsetY =
      (Number(capture?.canvas?.offsetHeight) - heightOfModelBoundingBox) / 2;
    const imageWidth = widthOfModelBoundingBox;
    const imageHeight = heightOfModelBoundingBox;

    const keyLabelProps = await getKeyLabelProps({
      taskList: cloneListTaskToDisplay,
      imageWidth: widthOfModelBoundingBox,
      imageHeight: heightOfModelBoundingBox,
      offsetX,
      offsetY,
    });
    const mapTaskLabelsByGuid = (
      (documentTask?.mainImageData || {}) as MainImageData
    )?.mapTaskLabelsByGuid;
    const keyLabelsEdited = (
      mapTaskLabelsByGuid?.[guid]?.keyLabelsEdited || []
    ).map((item) => {
      const label = keyLabelProps.find((i) => i.id === item.id)?.label;

      return { ...item, label: label || item.label };
    });

    const mainImageData: MainImageData = {
      mapTaskLabelsByGuid: {
        ...mapTaskLabelsByGuid,
        [guid]: {
          keyLabelsEdited,
          keyLabels: keyLabelProps,
          imageWidth,
          imageHeight,
          imageUrl,
          offsetX,
          offsetY,
        },
      },
      guid,
    };

    // save document task
    if (!capture?.blobUrl) {
      setLoadingExportSheetTask(false);
      onCloseTaskSheet();

      return;
    }

    // remove old task list from s3
    if (documentTask?.taskListUrl) {
      removeFileS3([documentTask.taskListUrl.replace(BASE_S3_URL, "")]);
    }

    // save task list to s3
    const taskListUrl = await uploadTaskListToS3(cloneListTaskToDisplay);

    let documentTaskBody: Partial<DocumentTask> = {
      ...(documentTask || {}),
      filterData: documentTask?.filterData || {},
      taskIds,
      title: documentTask?.title || "",
      bimFileId: bimFileId!,
      projectId: projectId!,
      mainImageData: mainImageData,
      level: levelSelected.label || ALL_LEVEL_LABEL,
      createdAt: new Date(),
      taskListUrl,
    };

    if (documentTask?.id) {
      documentTaskBody.id = documentTask.id;
    }

    // init value when create new document task
    if (!documentTask?.id) {
      // sync keynote data
      const documentTasksSameTaskIds = documentTasksByLevel.filter(
        (item) =>
          item.level === levelSelected.label &&
          item.taskIds.some((i) => taskIds.includes(i))
      );
      const keyLabelsEdited = await Promise.all(
        documentTasksSameTaskIds.map((item) =>
          documentTaskApi.getDocumentTask(item.id)
        )
      ).then((data) => {
        const documentTaskDetails = data;
        const keyLabelsEditeds: Partial<iKeyLabel>[][] = [];

        documentTaskDetails.forEach((item) => {
          const taskLabels = getTaskLabelOfDocumentTask(item);
          const mainImageDataOfItem = taskLabels?.mainImageData;
          const mapTaskLabelsByGuid = mainImageDataOfItem?.mapTaskLabelsByGuid;
          const isExistsSheet =
            mapTaskLabelsByGuid && mapTaskLabelsByGuid?.[guid];
          const keyLabelsEdited = mapTaskLabelsByGuid?.[guid]?.keyLabelsEdited;

          isExistsSheet &&
            !!keyLabelsEdited?.length &&
            keyLabelsEditeds.push(keyLabelsEdited);
        });

        const max = keyLabelsEditeds.reduce(
          (prev, current) =>
            prev && prev.length > current.length ? prev : current,
          []
        );

        return max;
      });

      if (keyLabelsEdited.length) {
        mainImageData.mapTaskLabelsByGuid[guid].keyLabelsEdited =
          keyLabelsEdited;
        documentTaskBody.mainImageData = mainImageData;
      }

      let title = transformDocumentTitle({
        taskSheetTemplateId: dataProjectDetail?.taskSheetTemplateId,
        levelLabel: levelSelected?.label,
        taskSheetTemplateList,
        date: new Date(),
      });

      // check duplicate title
      const isDuplicateTitle = await checkDuplicateDocumentTaskTitle({
        title,
        bimFileId,
      });

      if (isDuplicateTitle) {
        const lastIndex = getLastIndexDuplicateTitle({
          titleDuplicate: title,
          documentTasks: documentTasksByLevel,
        });
        title = `${title} (${lastIndex + 1})`;
      }

      const filterData: Partial<FilterDataType> = {
        levelSelected: !levelSelected?.label
          ? { label: ALL_LEVEL_LABEL, guid: "" }
          : levelSelected,
      };

      documentTaskBody = {
        ...documentTaskBody,
        taskIds,
        filterData: filterData,
        title,
      };
    }

    const _documentTask = await documentTaskApi.createUpdateDocumentTask(
      documentTaskBody
    );

    documentTaskBody.id = _documentTask.id;

    dispatch(addDocumentTasks([_documentTask]));
    setDocumentTask(_documentTask);
    const exportUrl = `${window.location.origin}/shorten/${documentTaskBody.id}`;
    const qrCanvas = await toCanvas(exportUrl);
    const qrBlob = await new Promise((resolve) =>
      qrCanvas.toBlob(resolve, "image/webp")
    );
    const qrImg = window.URL.createObjectURL(qrBlob as Blob);
    setQRCodeImage(qrImg);
    setLoadingExportSheetTask(false);
    isCaptureKeyplan && setIsCaptureKeyplan(false);
    setListTaskFromS3(cloneListTaskToDisplay);

    if (documentTask?.id) {
      customLabelExtension.showLabels({
        mode: SystemModeType.Task,
        data: listTaskToDisplay.map((task) => ({
          id: task.id,
          indexId: task.indexId,
          position: task.position,
          title: mapTaskType[task.taskTypeId || ""] || "-",
          status: task.status,
          externalId: task.externalId,
        })),
      });
    }

    if (isHasTaskSelected) {
      handleSelectTask(taskSelected, dispatch);

      return;
    }

    clearForgeSelection();
    const viewerUtil: any = viewer?.utilities;
    viewerUtil?.goHome?.();
  }, [
    displayMode,
    documentTask,
    documentTasksByLevel,
    isCaptureKeyplan,
    isLoadedViewerModelData,
    levelSelected,
    onOpenTaskSheet,
    listTaskToDisplay,
    taskSelected,
    bimFileId,
    projectId,
    dispatch,
    onCloseTaskSheet,
    dataProjectDetail?.taskSheetTemplateId,
    taskSheetTemplateList,
    mapTaskType,
  ]);

  const onUpdateDocumentTask = (newDocumentTask: DocumentTask) => {
    setDocumentTask(newDocumentTask);
  };

  const onChangeTaskSheetTemplate = useCallback(
    (templateId: string, documentTitle: string) => {
      if (
        !dataProjectDetail?.taskSheetTemplateId &&
        !documentTask?.taskSheetTemplateId
      ) {
        dispatch(
          setDataProjectDetail({
            ...dataProjectDetail,
            taskSheetTemplateId: templateId,
          })
        );

        return;
      }

      setDocumentTask((prev) => ({
        ...(prev || ({} as DocumentTask)),
        taskSheetTemplateId: templateId,
        title: documentTitle,
      }));
    },
    [dispatch, dataProjectDetail, documentTask?.taskSheetTemplateId]
  );

  return {
    listTaskFromS3,
    documentTask,
    isOpenTaskSheet,
    qrCodeImage,
    loadingExportSheetTask,

    onChangeTaskSheetTemplate,
    onCloseTaskSheet: handleCloseTaskSheet,
    onChangeKeyplanRatio,
    onChangeKeyplanSheet,
    handleDisplayTaskSheet,
    onGotoForgeViewPage,
    onUpdateDocumentTask,
  };
};

export default useTaskSheet;
