import { useCallback } from "react";
import { getLabelExtension } from "utils/forge/extensions/custom-label";

const useForgeViewerZoomToLabel = () => {
  const activeZoom = useCallback((id: string) => {
    getLabelExtension()?.zoomToLabel(id);
  }, []);

  const onZoom = useCallback(
    (id: string) => {
      activeZoom(id);
    },
    [activeZoom]
  );

  return {
    onZoom,
  };
};

export default useForgeViewerZoomToLabel;
