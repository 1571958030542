import { documentApi, documentKeyNoteApi } from "apiClient/v2";
import { iKeyLabel } from "components/ui/KeyLabel";
import { S3_PATH } from "constants/s3";
import { RIGHT_SIDEBAR_MODAL_CLASSNAME } from "constants/styleProps";
import { MessageType } from "constants/websocket";
import PreviewDocumentCategoryContext from "contexts/PreviewDocumentCategoryContext";
import { useAppWebSocket } from "hooks/useAppWebSocket";
import { DocumentCategoryDTO } from "interfaces/dtos/documentCategoryDTO";
import { DocumentDTO } from "interfaces/dtos/documentDTO";
import {
  DocumentItemDTO,
  DocumentSubItemDTO,
} from "interfaces/dtos/documentItemDTO";
import { Document } from "interfaces/models/document";
import { DocumentSubCategory } from "interfaces/models/documentCategory";
import { DocumentKeyNote } from "interfaces/models/documentKeynote";
import { User } from "interfaces/models/user";
import { cloneDeep } from "lodash";
import { createDataGenerateForDocument } from "models/document";
import {
  getLabelsOfKeynote,
  transformKeylabelsByEdited,
} from "models/documentTask";
import { useContext, useEffect, useRef, useState } from "react";
import { useDeviceSelectors } from "react-device-detect";
import { useDispatch } from "react-redux";
import {
  setDocumentCategorySelected,
  setStatusContentFromS3,
} from "redux/documentSlice";
import { Editor } from "tinymce";
import { removeFileS3, uploadFileToS3 } from "utils/file";

const DEFAULT_PAGE_ZOOM = 100;
const DEFAULT_PAGE_ZOOM_IPAD = 70;

interface Props {
  keyLabelsEdited?: Partial<iKeyLabel>[];
  documentItemsHadBlackboard?: DocumentSubItemDTO[];
  documentItemsHasImage?: DocumentSubItemDTO[];
  documentCategorySelected?: DocumentCategoryDTO;
  documentItemSelected?: DocumentItemDTO;
  documentItems?: DocumentItemDTO[];
  currentDocument?: DocumentDTO | null;
  currentDocSubCategories?: DocumentSubCategory[];
  listUserById?: {
    [key: string]: User | null;
  };
  isForgeViewerPage?: boolean;
  modeEdit: boolean;
  contentEditor?: string;
  editorRef: React.MutableRefObject<Editor | null>;
  setCurrentCategory?: (documentCategory: DocumentCategoryDTO) => void;
  setCurrentEditor?: (editor: Editor | null) => void;
  setCurrentDocument?: (document: DocumentDTO | null) => void;
  onClose: () => void;
  setModeEdit: (value: boolean) => void;
  setContentEditor: (value: string) => void;
  setContentEditorOfDocumentItemsForCategory: (value?: {
    [key: string]: string;
  }) => void;
  setIsSavingDocument: (value: boolean) => void;
  setIsFirstLoading: (value: boolean) => void;
}

const usePreviewAction = ({
  keyLabelsEdited,
  documentCategorySelected,
  documentItemSelected,
  currentDocument,
  modeEdit,
  editorRef,
  setIsSavingDocument,
  onClose,
  setCurrentDocument,
  setModeEdit,
  setContentEditor,
  setContentEditorOfDocumentItemsForCategory,
  setIsFirstLoading,
}: Props) => {
  const [{ isMobile }] = useDeviceSelectors(window.navigator.userAgent);
  const { sendWebSocketMessage } = useAppWebSocket();
  const [pageZoom, setPageZoom] = useState<number>(() =>
    isMobile ? DEFAULT_PAGE_ZOOM_IPAD : DEFAULT_PAGE_ZOOM
  );
  const zoomEditorRef = useRef<number>(pageZoom);
  const dispatch = useDispatch();
  const {
    keynoteImageData,
    documentKeynoteData,
    setKeynoteImageData,
    setDocumentKeynoteData,
  } = useContext(PreviewDocumentCategoryContext);

  useEffect(() => {
    zoomEditorRef.current = pageZoom;
  }, [pageZoom]);

  const handleClose = () => {
    const docCateModalEle = document.getElementById(
      RIGHT_SIDEBAR_MODAL_CLASSNAME
    );
    const newDocumentCategorySelected = cloneDeep(documentCategorySelected);

    if (docCateModalEle) {
      docCateModalEle.style.display = "flex";
    }

    if (newDocumentCategorySelected?.keynote) {
      delete newDocumentCategorySelected.keynote;
      dispatch(setDocumentCategorySelected(newDocumentCategorySelected));
    }

    onClose();
    setModeEdit(false);
    setIsFirstLoading(false);
    setContentEditor("");
    setContentEditorOfDocumentItemsForCategory(undefined);
  };

  const handleChangeMode = () => {
    setModeEdit(!modeEdit);
  };

  const handleSaveKeynoteMoved = async () => {
    const currentGuid = keynoteImageData.guid;
    const keylabelByGuid = getLabelsOfKeynote(keynoteImageData)?.labelByGuid;
    if (
      !keyLabelsEdited?.length ||
      !keylabelByGuid ||
      !documentKeynoteData?.documentCategoryId
    ) {
      return;
    }
    const newKeynoteImageData = structuredClone(keynoteImageData);
    const keyLabels = keylabelByGuid?.keyLabels || [];
    const currentKeyLabelsEdited = keylabelByGuid?.keyLabelsEdited || [];

    const { cloneKeyLabels, cloneKeyLabelsEdited } = transformKeylabelsByEdited(
      {
        keyLabels,
        currentKeyLabelsEdited,
        keyLabelsEdited,
      }
    );

    keylabelByGuid.keyLabelsEdited = cloneKeyLabelsEdited;
    keylabelByGuid.keyLabels = cloneKeyLabels;
    newKeynoteImageData.mapLabelsByGuid = {
      ...newKeynoteImageData.mapLabelsByGuid,
      [currentGuid]: keylabelByGuid,
    };

    const newDocumentKeynoteData: DocumentKeyNote = {
      ...documentKeynoteData,
      keynoteImageData: newKeynoteImageData,
    };

    setDocumentKeynoteData(newDocumentKeynoteData);
    setKeynoteImageData(newKeynoteImageData);
    await documentKeyNoteApi.updateKeynote(newDocumentKeynoteData);
  };

  const handleSaveEditor = async () => {
    if (zoomEditorRef.current !== pageZoom) {
      setPageZoom(zoomEditorRef.current);
    }

    setIsSavingDocument(true);
    try {
      const editor = editorRef.current;
      if (editor) {
        const now = new Date();
        const content = editor!.getContent();
        let documentId = currentDocument?.id;

        const { dataGenerate } = await createDataGenerateForDocument(
          documentCategorySelected,
          documentItemSelected
        );

        const bodyDocumentReq: Document = {
          ...currentDocument,
          id: documentId || "",
          bimFileId: documentCategorySelected?.bimFileId || "",
          documentCategoryId: documentCategorySelected?.id || "",
          title:
            currentDocument?.title || documentCategorySelected?.title || "",
          createdAt: currentDocument?.createdAt || now,
          updatedAt: now,
          dataGenerate: dataGenerate,
        };

        if (!documentId) {
          const { data } = await documentApi.createDocument(bodyDocumentReq);
          documentId = data.id;
        }

        const documentUrl = await uploadFileToS3(
          new File([content], documentId),
          documentId,
          S3_PATH.Document
        );

        bodyDocumentReq.content = documentUrl;

        if (documentUrl && currentDocument?.content) {
          removeFileS3([currentDocument.content]);
        }

        const [{ data }] = await Promise.all([
          documentApi.updateDocument(bodyDocumentReq),
          handleSaveKeynoteMoved(),
        ]);
        const res = data;

        if (res) {
          if (setCurrentDocument) {
            setCurrentDocument(res);
          }

          setContentEditor(content);
          sendWebSocketMessage({
            type: MessageType.CHANGE_CURRENT_DOCUMENT,
            data: res,
          });
        }

        setModeEdit(false);
        setIsSavingDocument(false);
        dispatch(setStatusContentFromS3(true));
      }
    } catch (err) {
      setIsSavingDocument(false);
    }
  };

  const handleCancelEditDocument = () => {
    setModeEdit(false);
    zoomEditorRef.current = pageZoom;
  };

  const handleChangePageZoom = (_: string, value: number) => {
    setPageZoom(value);
  };

  return {
    pageZoom,
    handleClose,
    handleChangeMode,
    handleSaveEditor,
    handleCancelEditDocument,
    handleChangePageZoom,
    zoomEditorRef,
  };
};

export default usePreviewAction;
