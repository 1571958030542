import {
  Box,
  Button,
  Flex,
  SlideFade,
  Spinner,
  UseModalProps,
} from "@chakra-ui/react";
import EditorDocument from "components/EditorDocument";
import { SvgIcon } from "components/SvgIcon";
import { NumberInputFormat } from "components/input";
import Select from "components/input/Select";
import {
  MAX_ZOOM_VALUE,
  MIN_ZOOM_VALUE,
  STEP_ZOOM_VALUE,
} from "constants/document";
import { DocumentTemplateType } from "constants/enum";
import { DISPLAY_MODE } from "constants/forge";
import PreviewDocumentCategoryContext from "contexts/PreviewDocumentCategoryContext";
import { useResize } from "hooks/useResize";
import { DocumentDTO } from "interfaces/dtos/documentDTO";
import {
  DocumentItemDTO,
  DocumentSubItemDTO,
} from "interfaces/dtos/documentItemDTO";
import { NeptuneArea } from "interfaces/models/area";
import { TemplateComponent } from "interfaces/models/component";
import {
  DocumentCategory,
  DocumentSubCategory,
} from "interfaces/models/documentCategory";
import { DocumentItem } from "interfaces/models/documentItem";
import {
  DocumentTemplate,
  iBlackboardTemplateProps,
} from "interfaces/models/documentTemplate";
import { User } from "interfaces/models/user";
import { getSheets } from "models/document";
import {
  Fragment,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { setStatusContentFromS3 } from "redux/documentSlice";
import { RootState } from "redux/store";
import { Editor } from "tinymce";
import { sortArrayByField } from "utils/array";
import PreviewContainer from "./PreviewContainer";
import usePreviewAction from "./hooks/usePreviewAction";
import usePreviewData from "./hooks/usePreviewData";
import { Level } from "interfaces/models";
import { setLevels } from "redux/forgeViewerSlice";
import { PartnerCompany } from "interfaces/models/partnerCompany";
import { listOtherPartnerCompanies } from "constants/blackBoardTemplate";
import { useAuthorization } from "hooks/usePermission";

interface Props extends UseModalProps {
  isChangeSheet?: boolean;
  isDiffPrevModal?: boolean;
  isCreatingKeynote?: boolean;
  isLoadingDownload?: boolean;
  documentCategorySelected?: DocumentCategory;
  documentItemSelected?: DocumentItemDTO;
  documentItems?: DocumentItem[];
  documentItemsHasImage?: any;
  documentItemsHadBlackboard?: DocumentSubItemDTO[];
  currentDocument?: DocumentDTO | null;
  currentDocSubCategories?: DocumentSubCategory[];
  listUserById?: {
    [key: string]: User | null;
  };
  companiesById: Record<string, PartnerCompany>;
  loadingPrintPdf?: boolean;
  isLoadingDeleteDocument?: boolean;
  mainStyle?: React.CSSProperties;
  isForgeViewerPage?: boolean;
  isShowFullWidth?: boolean;
  neptuneAreas?: NeptuneArea[];
  isLoading?: boolean;
  template?: DocumentTemplate;
  components?: TemplateComponent[];
  setCurrentCategory?: (documentCategory: DocumentCategory) => void;
  setCurrentEditor?: (editor: Editor | null) => void;
  onDownload: (isContentFromS3: boolean) => void;
  handlePrintPdf: (isContentFromS3: boolean) => Promise<void>;
  setCurrentDocument?: (document: DocumentDTO | null) => void;
  onDeleteDocument?: () => void;
  handleChangeSelectedSheet?: (value: any) => void;
}

const DEFAULT_MAX_WIDTH_IPAD = 1336;

export interface IPreviewDocumentCategoryHandle {
  resetValuePreview: () => void;
}

const PreviewDocumentCategory = forwardRef<
  IPreviewDocumentCategoryHandle,
  Props
>(
  (
    {
      isChangeSheet = false,
      isDiffPrevModal,
      isCreatingKeynote,
      isOpen,
      isLoadingDownload,
      isLoadingDeleteDocument,
      documentItems = [],
      documentItemsHasImage,
      documentItemsHadBlackboard,
      documentCategorySelected,
      documentItemSelected,
      currentDocSubCategories,
      currentDocument,
      listUserById,
      companiesById,
      loadingPrintPdf,
      mainStyle,
      isForgeViewerPage,
      isShowFullWidth,
      isLoading,
      template,
      components,
      neptuneAreas,
      setCurrentCategory,
      setCurrentEditor,
      onDownload,
      setCurrentDocument,
      onClose,
      handlePrintPdf,
      handleChangeSelectedSheet,
    },
    ref
  ) => {
    const {
      keyLabelsEdited,
      isLoadingFromS3,
      modeEdit,
      isSavingDocument,
      contentEditor,
      contentEditorOfDocumentItemsForCategory,
      editorRef,
      formatDocumentItems,
      documentCategories,
      onInitEditor,
      setModeEdit,
      setIsSavingDocument,
      setContentEditor,
      setContentEditorOfDocumentItemsForCategory,
      setIsFirstLoading,
      callbackDragKeynoteStop,
    } = usePreviewData({
      ref,
      isLoadingDeleteDocument,
      isOpen,
      documentItemsHasImage,
      currentDocument,
      isForgeViewerPage,
      documentItemSelected,
      documentCategorySelected,
      setCurrentEditor,
      isCreatingKeynote,
    });

    const newDocumentItems =
      documentCategorySelected?.documentType ===
      DocumentTemplateType.PHOTO_LEDGER
        ? formatDocumentItems
        : documentItems;

    const {
      pageZoom,
      handleClose,
      handleChangeMode,
      handleSaveEditor,
      handleCancelEditDocument,
      handleChangePageZoom,
      zoomEditorRef,
    } = usePreviewAction({
      keyLabelsEdited,
      documentItemsHadBlackboard,
      documentItems: newDocumentItems as DocumentItemDTO[],
      documentCategorySelected,
      documentItemSelected,
      currentDocument,
      currentDocSubCategories,
      isForgeViewerPage,
      modeEdit,
      contentEditor,
      editorRef,
      setIsSavingDocument,
      setCurrentCategory,
      onClose,
      setCurrentDocument,
      setModeEdit,
      setContentEditor,
      setContentEditorOfDocumentItemsForCategory,
      setIsFirstLoading,
    });

    const { keynoteImageData } = useContext(PreviewDocumentCategoryContext);
    const { width } = useResize();

    const dispatch = useDispatch();
    const { isContentFromS3 } = useSelector(
      (state: RootState) => state.document
    );
    const { levels, levelSelected, familyInstances } = useSelector(
      (state: RootState) => state.forgeViewer
    );
    const { dataProjectDetail, partnerCompanies, projectDetail } = useSelector(
      (state: RootState) => state.project
    );
    const { users } = useSelector((state: RootState) => state.user);

    const [sheetOptions, setSheetOptions] = useState<
      { name: string; value: string }[]
    >([]);

    const getSheetOptions = async () => {
      const sheets = await getSheets({ documentCategorySelected });

      setSheetOptions(
        sheets?.map(({ name, guid }) => ({
          name,
          value: guid,
        }))
      );
    };

    const isHasSheets = useMemo(() => {
      const sheets =
        levels.find((level) => level.label === documentCategorySelected?.level)
          ?.sheets || [];

      return sheets?.length;
    }, [documentCategorySelected?.level, levels]);

    const groupSheetOptions = useMemo(() => {
      if (isHasSheets) {
        return [{ title: "2D Sheets", options: sheetOptions }];
      }

      return [
        {
          title: "3D View",
          options: [
            {
              name: "3D View",
              value: DISPLAY_MODE["3D"],
            },
          ],
        },
        { title: "2D Sheets", options: sheetOptions },
      ];
    }, [sheetOptions, isHasSheets]);

    const listOptionUsers: { label: string; value: string }[] = useMemo(() => {
      return users
        ?.map((user) => ({
          label: user?.name || "",
          value: user?.id || "",
        }))
        ?.filter((user) => !!user?.value);
    }, [users]);

    const listOptionFloor: { label: string; value: string }[] = useMemo(() => {
      return levels
        ?.filter((level) => !!level.guid)
        ?.map((item) => {
          return {
            value: String(item?.guid),
            label: String(item?.label),
          };
        });
    }, [levels]);

    const listPartnerCompanies: { label: string; value: string }[] =
      useMemo(() => {
        return [
          ...listOtherPartnerCompanies,
          ...(partnerCompanies
            ?.map((company) => ({
              label: company.name || "",
              value: company.id || "",
            }))
            ?.filter((company) => !!company.value) || []),
        ];
      }, [partnerCompanies]);

    const blackboardTemplateProps = useMemo(
      (): iBlackboardTemplateProps => ({
        levelSelected,
        projectName: projectDetail?.name,
        blackboardTemplate: template?.blackboardTemplateDetail,
        listOptionUsers,
        listOptionFloor,
        listPartnerCompanies,
        documentCategorySelected,
      }),
      [
        projectDetail?.name,
        documentCategorySelected,
        template?.blackboardTemplateDetail,
        listOptionUsers,
        listOptionFloor,
        listPartnerCompanies,
        levelSelected,
      ]
    );

    useEffect(() => {
      getSheetOptions();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [documentCategorySelected?.level]);

    useEffect(() => {
      if (levels?.length) {
        return;
      }

      const levelData = (dataProjectDetail.levelData || {}) as {
        [key: string]: Level;
      };

      dispatch(setLevels(Object.values(levelData)));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataProjectDetail, levels?.length]);

    const isShowSheetmenu = useMemo(() => {
      return components?.some((component) => component.detail?.checkedImage);
    }, [components]);

    const isCollapseLeftBar = useMemo(() => {
      if (!isOpen) return false;

      return localStorage.getItem("isCollapsedLeftBar") === "true";
    }, [isOpen]);

    useImperativeHandle(ref, () => ({
      resetValuePreview: () => {
        dispatch(setStatusContentFromS3(null as any));
        setModeEdit(false);
        setIsFirstLoading(false);
        setContentEditor("");
        setContentEditorOfDocumentItemsForCategory(undefined);
      },
    }));

    const renderEditor = useCallback(
      (id: string, contentEditor: string, isContentFromS3: boolean) => {
        if ((!modeEdit && !isContentFromS3) || !contentEditor) {
          return;
        }

        return (
          <Box
            key={id}
            sx={{
              ".tox-toolbar__primary": {
                display: modeEdit ? "flex" : "none !important",
              },
            }}
            display={modeEdit || isContentFromS3 ? "block" : "none !important"}
          >
            <EditorDocument
              onInit={onInitEditor}
              modeEdit={modeEdit}
              disabled={!modeEdit}
              defaultValue={contentEditor}
              isContentFromS3={isContentFromS3}
              contentCss="/styles/documents/inspection.css,/styles/documents/editor.css"
              editorProps={{
                plugins: "autoresize",
              }}
              pageZoom={pageZoom}
              zoomEditorRef={zoomEditorRef}
              callbackDragKeynoteStop={callbackDragKeynoteStop}
            />
          </Box>
        );
      },
      [callbackDragKeynoteStop, modeEdit, onInitEditor, pageZoom, zoomEditorRef]
    );

    const isCategoryOfModuleChiller = useMemo(() => {
      return (
        documentCategorySelected?.documentType ===
          DocumentTemplateType.EQUIPMENT_DATA_SHEET && !documentItemSelected
      );
    }, [documentCategorySelected?.documentType, documentItemSelected]);

    const renderPreview = useCallback(() => {
      const isDocumentItemOfModuleChiller =
        documentCategorySelected?.documentType ===
          DocumentTemplateType.EQUIPMENT_DATA_SHEET && documentItemSelected;

      // Render preview for Other type ( not Module chiller ) or document item of Module chiller
      if (!isCategoryOfModuleChiller) {
        return (
          <Box display={modeEdit ? "none" : "block"}>
            <PreviewContainer
              pageZoom={pageZoom}
              template={template}
              components={components}
              listUserById={listUserById}
              companiesById={companiesById}
              documentItems={
                documentCategorySelected?.documentType ===
                DocumentTemplateType.PHOTO_LEDGER
                  ? formatDocumentItems
                  : documentItems
              }
              documentItem={
                isDocumentItemOfModuleChiller ? documentItemSelected : undefined
              }
              documentCategories={documentCategories}
              currentDocSubCategories={currentDocSubCategories}
              documentCategorySelected={documentCategorySelected}
              currentDocument={currentDocument}
              isCreatingKeynote={isCreatingKeynote}
              neptuneAreas={neptuneAreas}
              blackboardTemplateProps={blackboardTemplateProps}
              familyInstances={familyInstances}
              projectName={projectDetail?.name}
            />
          </Box>
        );
      }

      // Render preview for Module chiller
      return sortArrayByField(documentItems, "title").map(
        (item: DocumentItem) => {
          const documentItemId =
            contentEditorOfDocumentItemsForCategory &&
            Object.keys(contentEditorOfDocumentItemsForCategory)?.find(
              (documentItemId) => item.id === documentItemId
            );
          if (documentItemId) {
            const contentHtml = contentEditorOfDocumentItemsForCategory[
              documentItemId
            ].replace(/\n/g, "");

            return (
              <Fragment key={item.id}>
                <div dangerouslySetInnerHTML={{ __html: contentHtml }}></div>
              </Fragment>
            );
          }

          return (
            <PreviewContainer
              key={item.id}
              pageZoom={pageZoom}
              template={template}
              components={components}
              listUserById={listUserById}
              companiesById={companiesById}
              documentItems={
                documentCategorySelected?.documentType ===
                DocumentTemplateType.PHOTO_LEDGER
                  ? formatDocumentItems
                  : documentItems
              }
              documentItem={item}
              documentCategories={documentCategories}
              currentDocSubCategories={currentDocSubCategories}
              documentCategorySelected={documentCategorySelected}
              currentDocument={currentDocument}
              isCreatingKeynote={isCreatingKeynote}
              neptuneAreas={neptuneAreas}
              blackboardTemplateProps={blackboardTemplateProps}
              familyInstances={familyInstances}
              projectName={projectDetail?.name}
            />
          );
        }
      );

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      familyInstances,
      blackboardTemplateProps,
      pageZoom,
      modeEdit,
      contentEditorOfDocumentItemsForCategory,
      documentCategories,
      formatDocumentItems,
      isCategoryOfModuleChiller,
      isLoading,
      neptuneAreas,
      components,
      currentDocSubCategories,
      currentDocument,
      documentCategorySelected,
      documentItemSelected,
      documentItems,
      listUserById,
      template,
      isCreatingKeynote,
    ]);

    const isCategoryModuleChiller = useMemo(() => {
      return (
        documentCategorySelected?.documentType ===
          DocumentTemplateType.EQUIPMENT_DATA_SHEET && !documentItemSelected
      );
    }, [documentCategorySelected?.documentType, documentItemSelected]);

    const { canEditDocumentItem } = useAuthorization();

    const isDisableButton = useMemo(() => {
      if (isCategoryModuleChiller) {
        return isLoading;
      }

      return isLoading || isChangeSheet;
    }, [isCategoryModuleChiller, isChangeSheet, isLoading]);

    const isLoadSpin = isLoading || isLoadingFromS3;

    return (
      <SlideFade
        id="preview-document-category-slidefade"
        in={isOpen}
        offsetY="20px"
      >
        <Box
          id="preview-document-category-box"
          position="absolute"
          zIndex={121}
          w={`${
            width > DEFAULT_MAX_WIDTH_IPAD &&
            !isShowFullWidth &&
            !isCollapseLeftBar
              ? `calc(100vw - 440px)`
              : `100vw`
          }`}
          maxW="unset"
          h={`calc(100vh - var(--header-height))`}
          right="0"
          top="0"
          p="0"
          overflow="hidden"
          mt={0}
          mb={0}
          bg="transparent"
          opacity={isOpen ? "1" : "0"}
          flexDir="row"
          ml={`${
            width > DEFAULT_MAX_WIDTH_IPAD && !isShowFullWidth && `37rem`
          }`}
          transform="none"
        >
          <Box
            h="100%"
            onClick={handleClose}
            position="fixed"
            top={0}
            left={0}
            zIndex={122}
          />

          <Box
            zIndex={122}
            bgColor="#000000"
            p="0"
            h="100%"
            boxShadow="0px 0 4px #707070"
            position="relative"
            overflowY="auto"
            style={mainStyle}
          >
            <Flex
              position="sticky"
              left="0px"
              top="0px"
              zIndex="sticky"
              p="1.2rem 1.6rem"
              bgColor="#E5E5E5"
            >
              {modeEdit ? (
                <>
                  <Button
                    variant="white"
                    mr="2rem"
                    isDisabled={isLoading}
                    isLoading={isSavingDocument}
                    onClick={handleSaveEditor}
                  >
                    保存して終了
                  </Button>
                  <Button
                    isDisabled={isLoading}
                    variant="red"
                    onClick={handleCancelEditDocument}
                  >
                    変更を破棄
                  </Button>
                  <Button
                    isDisabled={isLoading}
                    isLoading={loadingPrintPdf}
                    onClick={() => handlePrintPdf(true)}
                    variant="white"
                    ml="auto!important"
                  >
                    印刷
                  </Button>
                </>
              ) : (
                <Flex justifyContent="space-between" width="100%">
                  <Flex>
                    <SvgIcon
                      src="/img/icon-navigation-chevron_left_light.svg"
                      style={{
                        alignSelf: "center",
                        marginRight: ".8rem",
                        cursor: "pointer",
                      }}
                      onClick={handleClose}
                    />

                    <Button
                      isDisabled={isDisableButton}
                      variant="blue"
                      color="#FFFFFF"
                      bg="#009BE0"
                      borderRadius={6}
                      p="1.2rem 1.6rem"
                      height="4rem"
                      onClick={() => onDownload(isContentFromS3)}
                      isLoading={isLoadingDownload}
                    >
                      PDFダウンロード
                    </Button>
                    {!isCategoryModuleChiller && canEditDocumentItem && (
                      <Button
                        isDisabled={isDisableButton}
                        variant="white"
                        ml="1rem"
                        borderRadius={6}
                        border="1px solid #A3A3A3"
                        color="#009BE0"
                        p="1.2rem 1.6rem"
                        height="4rem"
                        onClick={handleChangeMode}
                      >
                        書類情報の編集
                      </Button>
                    )}
                    {/* <Button
                        disabled={isDisableButton}
                        variant="white"
                        ml="1rem"
                        borderRadius={6}
                        border="1px solid #A3A3A3"
                        color="#009BE0"
                        p="1.2rem 1.6rem"
                        height="4rem"
                      >
                        編集履歴表示
                      </Button> */}
                    <Button
                      isDisabled={isDisableButton}
                      isLoading={loadingPrintPdf}
                      onClick={() => handlePrintPdf(isContentFromS3)}
                      variant="white"
                      ml="1rem"
                      borderRadius={6}
                      border="1px solid #A3A3A3"
                      color="#009BE0"
                      p="1.2rem 1.6rem"
                      height="4rem"
                    >
                      印刷
                    </Button>
                  </Flex>

                  <Flex alignItems="center" gap="1rem" zIndex={1000}>
                    {/* TODO: Need to adjust the exact position of the keynote after rotating the page */}
                    {/*{isShowSheetmenu && (
                        <Select
                          isDisabled={isLoadSpin}
                          options={AXIS_OPTIONS}
                          value={keynoteAxis}
                          onChange={onChangeKeynoteAxis}
                          height="4rem"
                          minW="12rem"
                          maxW="12rem"
                          showIcon
                        />
                      )}*/}

                    <NumberInputFormat
                      isDisabled={isLoadSpin}
                      disableTyping={true}
                      border="1px solid #A3A3A3"
                      w="11rem"
                      height="4rem"
                      unit="%表示"
                      initValue={`${pageZoom}`}
                      min={MIN_ZOOM_VALUE}
                      max={MAX_ZOOM_VALUE}
                      step={STEP_ZOOM_VALUE}
                      onChangeStyle={handleChangePageZoom}
                      borderRadius="6px"
                      sx={{
                        "& > input": {
                          border: "none !important",
                          borderRadius: "6px !important",
                        },
                      }}
                    />

                    {isShowSheetmenu && (
                      <Select
                        isDisabled={isLoadSpin}
                        value={keynoteImageData?.guid}
                        // TODO: comment in if using 3D View in preview document
                        groupOptions={groupSheetOptions}
                        height="4rem"
                        minW="24rem"
                        maxW="24rem"
                        zIndex="dropdown"
                        showIcon
                        onChange={handleChangeSelectedSheet}
                      />
                    )}
                  </Flex>
                </Flex>
              )}
            </Flex>
            {width > DEFAULT_MAX_WIDTH_IPAD && !isShowFullWidth && (
              <Flex
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  position: "absolute",
                  right: "1rem",
                  zIndex: 10,
                  top: 80 + (modeEdit ? 108 : 0),
                }}
              >
                <SvgIcon
                  fill="#fff"
                  ml="auto"
                  onClick={handleClose}
                  className="button"
                  minW="3.7rem"
                  style={{
                    fill: "#fff",
                    minWidth: "4.7rem",
                    marginLeft: "auto",
                    scale: "1.3",
                  }}
                  src="/img/icon-navigation-close.svg"
                />
              </Flex>
            )}

            {isLoadSpin && (
              <Flex
                position="absolute"
                width="100%"
                height="100%"
                top="50%"
                left="50%"
                zIndex="modal"
                transform="translate(-50%,-50%)"
                alignItems="center"
                justifyContent="center"
              >
                <Spinner size="xl" color="white" />
              </Flex>
            )}

            <Box
              position="relative"
              opacity={isLoadSpin ? 0 : 1}
              transition="opacity 0.1s"
            >
              {isCategoryOfModuleChiller ? (
                <Box
                  id="container-preview"
                  zIndex={isOpen || isDiffPrevModal ? "1" : "2"}
                >
                  {renderPreview()}
                </Box>
              ) : (
                <>
                  <Box
                    id="container-preview"
                    zIndex={isOpen || isDiffPrevModal ? "1" : "2"}
                  >
                    {!isContentFromS3 && renderPreview()}
                  </Box>
                  {renderEditor(
                    documentCategorySelected?.id || "",
                    contentEditor,
                    isContentFromS3
                  )}
                </>
              )}
            </Box>
          </Box>
        </Box>
      </SlideFade>
    );
  }
);

export default PreviewDocumentCategory;
