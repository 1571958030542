import { ComponentStyleConfig } from "@chakra-ui/react";

export const badgeStyle: ComponentStyleConfig = {
  variants: {
    red: {
      borderRadius: "4px",
      bgColor: "var(--red)",
      fontSize: "1.1rem",
      lineHeight: "1.8rem",
      color: "#fff",
      p: "0 0.7rem",
      h: "1.8rem",
    },
    pin: {
      borderRadius: "2px",
      fontSize: "1.2rem",
      color: "#fff",
      h: "1.8rem",
      minW: "8rem",
      textAlign: "center",
    },
  },
};
