import { useMemo } from "react";
import { PaperDirectionType, PaperSize } from "constants/enum";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { centimetersToPixels } from "utils/measure";
import { GRID_TEMPLATE_SIZE } from "constants/document";

const useGetPageInfo = () => {
  const { pageSelected } = useSelector((state: RootState) => state.document);

  const pageSize = useMemo(() => {
    let width = centimetersToPixels(PaperSize[pageSelected.pageSize]?.width);
    let height = centimetersToPixels(PaperSize[pageSelected.pageSize]?.height);

    if (pageSelected?.pageDirection === PaperDirectionType.HORIZONTAL) {
      [width, height] = [height, width];
    }

    return {
      width,
      height,
    };
  }, [pageSelected?.pageDirection, pageSelected.pageSize]);

  const contentSize = useMemo(() => {
    let width =
      PaperSize[pageSelected.pageSize]?.numOfColumns * GRID_TEMPLATE_SIZE;
    let height =
      PaperSize[pageSelected.pageSize]?.numOfRows * GRID_TEMPLATE_SIZE;

    if (pageSelected?.pageDirection === PaperDirectionType.HORIZONTAL) {
      [width, height] = [height, width];
    }

    return {
      width,
      height,
    };
  }, [pageSelected?.pageDirection, pageSelected.pageSize]);

  const pagePadding = useMemo(() => {
    const top = (pageSize?.height - contentSize?.height) / 2;
    const left = (pageSize?.width - contentSize?.width) / 2;

    return {
      top,
      left,
    };
  }, [contentSize, pageSize]);

  return {
    pageSize,
    contentSize,
    pagePadding,
  };
};

export default useGetPageInfo;
