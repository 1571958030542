import { Input, InputProps } from "@chakra-ui/react";
import { documentItemApi } from "apiClient/v2";
import { DocumentItemKey, KeyBoardCode } from "constants/enum";
import {
  DocumentItemDTO,
  DocumentSubItemDTO,
} from "interfaces/dtos/documentItemDTO";
import { GetContentItemLog, OPERATION_ITEM_LOG } from "models/documentItemLog";
import { TypeHandleInitData } from "pages/forge-viewer/hooks/useSupportSyncDataOffline";
import { memo, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { updateDocumentItem } from "redux/documentSlice";
import { updateElementInArray } from "utils/array";
import { transformBodyForCombineData } from "utils/offline";

export enum ItemTitleType {
  ITEM,
  SUB_ITEM,
}

interface Props {
  type: ItemTitleType;
  subItem?: DocumentSubItemDTO;
  item?: DocumentItemDTO;
  inputProps?: InputProps;
  insertItemLog?: (params: GetContentItemLog) => Promise<void>;
}

const ItemTitle = (props: Props) => {
  const { type, subItem, item, inputProps, insertItemLog } = props;

  const dispatch = useDispatch();
  const titleInputRef = useRef<HTMLInputElement>(null);
  const currentTitle =
    type === ItemTitleType.ITEM ? item?.title : subItem?.title;
  const selfRef = useRef<any>({});
  const currentValueRef = useRef<string>("");

  useEffect(() => {
    currentValueRef.current = currentTitle || "";
  }, [currentTitle]);

  const onChange = (e: any) => {
    currentValueRef.current = e.target.value;
  };

  const handleChangeTitle = async () => {
    const newTitle = currentValueRef.current;
    if (
      !item?.id ||
      (type === ItemTitleType.SUB_ITEM && !subItem?.id) ||
      (newTitle || "") === (currentTitle || "")
    ) {
      return;
    }
    const now = new Date();

    if (type === ItemTitleType.SUB_ITEM) {
      const newItem = structuredClone(item);
      updateElementInArray({
        array: newItem?.subItems || [],
        keyIndex: "id",
        element: {
          ...subItem,
          title: newTitle,
          updatedAt: now,
        } as DocumentSubItemDTO,
      });

      dispatch(updateDocumentItem(newItem));
      if (subItem?.id) {
        documentItemApi.updateSubItem(
          transformBodyForCombineData<DocumentSubItemDTO>({
            body: {
              id: subItem?.id,
              itemId: subItem.itemId,
              title: newTitle || "",
              updatedAt: now,
            } as DocumentSubItemDTO,
            bodyBefore: subItem,
            typeInitData: TypeHandleInitData.SUB_ITEM,
          })
        );
      }
    } else if (type === ItemTitleType.ITEM) {
      const now = new Date();
      const newItem = {
        ...(item as DocumentItemDTO),
        title: newTitle,
        updatedAt: now,
      } as DocumentItemDTO;
      dispatch(updateDocumentItem(newItem));
      await documentItemApi.updateItem(
        transformBodyForCombineData<DocumentItemDTO>({
          body: {
            id: item.id,
            title: newTitle,
            updatedAt: now,
            isIgnoreInsertLog: !!insertItemLog,
          } as DocumentItemDTO,
          bodyBefore: item,
          typeInitData: TypeHandleInitData.DOCUMENT_ITEM,
        })
      );
    }
    insertItemLog?.({ field: DocumentItemKey.TITLE, value: newTitle });
  };

  selfRef.current.handleChangeTitle = handleChangeTitle;
  useEffect(() => {
    const self = selfRef.current;

    return () => {
      self?.handleChangeTitle();
    };
  }, []);

  return (
    <Input
      ref={titleInputRef}
      height="4.4rem"
      fontSize="1.4rem"
      fontWeight="400"
      w="100%"
      borderColor="border.default"
      color="font.default"
      defaultValue={currentTitle || ""}
      onBlur={handleChangeTitle}
      onChange={onChange}
      onKeyPress={(e) => {
        if (e.key === KeyBoardCode.Enter) {
          titleInputRef.current?.blur();
        }
      }}
      {...inputProps}
    />
  );
};

export default memo(ItemTitle);
