import { useRef } from "react";
import { useBoolean } from "@chakra-ui/react";

export const useDrawTool = () => {
  const [isOpenDraw, setOpenDraw] = useBoolean();
  const [isOpenConfirmedDraw, setOpenConfirmedDraw] = useBoolean();

  const imgDrawFile = useRef<File>();
  const confirmedImgDrawFile = useRef<File>();

  return {
    isOpenDraw,
    setOpenDraw,
    imgDrawFile,
    confirmedImgDrawFile,
    setOpenConfirmedDraw,
    isOpenConfirmedDraw,
  };
};
