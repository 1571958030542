import { BoxProps, Text } from "@chakra-ui/react";
import { SvgIcon } from "components/SvgIcon";
import { memo, useMemo } from "react";
import MenuDropdown from "components/MenuDropdown";
import { useAuthorization } from "hooks/usePermission";

interface Props extends BoxProps {
  onSelectDeleteMenuItem(): void;
  onSelectUpdateMenuItem(): void;
  isDisabled?: boolean;
}

const DocumentGroupMenu = ({
  isDisabled,
  onSelectDeleteMenuItem,
  onSelectUpdateMenuItem,
  ...rest
}: Props) => {
  const { canDeleteFolder } = useAuthorization();
  const options = useMemo(() => {
    const options: any[] = [];
    // [
    // {
    //   title: (
    //     <Text fontSize="1.2rem" fontWeight="400">
    //       設定
    //     </Text>
    //   ) as any,
    //   icon: <SvgIcon w="2.4rem" h="2.4rem" src="/img/icon-cogs.svg" />,
    //   onClick: onSelectUpdateMenuItem,
    // },
    // ]

    if (canDeleteFolder) {
      options.push({
        title: (
          <Text color="#EF4444" fontSize="1.2rem" fontWeight="400">
            削除
          </Text>
        ) as any,
        icon: <SvgIcon w="2.4rem" h="2.4rem" src="/img/icon-trash-red.svg" />,
        isDisabled,
        onClick: onSelectDeleteMenuItem,
      });
    }

    return options;
  }, [canDeleteFolder, isDisabled, onSelectDeleteMenuItem]);

  return <MenuDropdown {...rest} options={options} />;
};

export default memo(DocumentGroupMenu);
