import { compareVectors } from "./vector";

export const DEFAULT_BORDER_COLOR = "#808080";

export const getLeftTopVertexPosition = (mesh: THREE.Mesh) => {
  const vertices = mesh.geometry.vertices;
  let leftTopVertex = vertices[0];
  for (let i = 1; i < vertices.length; i++) {
    const vertex = vertices[i];
    if (
      vertex.x < leftTopVertex.x ||
      (vertex.x == leftTopVertex.x && vertex.y > leftTopVertex.y) ||
      (vertex.x == leftTopVertex.x &&
        vertex.y == leftTopVertex.y &&
        vertex.z > leftTopVertex.z)
    ) {
      leftTopVertex = vertex;
    }
  }

  const leftTopPosition = new THREE.Vector3();
  leftTopPosition.copy(leftTopVertex);
  mesh.localToWorld(leftTopPosition);

  return leftTopPosition;
};

export const getEdgesMesh = (mesh: THREE.Mesh) => {
  // Create edges mesh
  const lines: THREE.Vector3[][] = [];
  const normals: THREE.Vector3[] = [];
  const cylinderMaterial = new THREE.MeshBasicMaterial({
    color: DEFAULT_BORDER_COLOR,
  });

  const computeEdge = (
    v1: THREE.Vector3,
    v2: THREE.Vector3,
    faceNormal: THREE.Vector3
  ) => {
    const index = lines.findIndex((edge) => {
      return (
        (compareVectors(v1, edge[0]) && compareVectors(v2, edge[1])) ||
        (compareVectors(v1, edge[1]) && compareVectors(v2, edge[0]))
      );
    });

    if (index === -1) {
      lines.push([v1, v2]);
      normals.push(faceNormal);
    } else {
      if (compareVectors(faceNormal, normals[index])) {
        lines.splice(index, 1);
        normals.splice(index, 1);
      }
    }
  };
  const mapVertices = mesh.geometry.vertices;
  mesh.geometry.faces.forEach((face) => {
    //@ts-ignore
    const edge1 = mapVertices[face.a];
    const edge2 = mapVertices[face.b];
    const edge3 = mapVertices[face.c];
    computeEdge(edge1, edge2, face.normal);
    computeEdge(edge2, edge3, face.normal);
    computeEdge(edge3, edge1, face.normal);
  });

  const group = new THREE.Group();
  lines.forEach(([startPoint, endPoint]) => {
    const distance = startPoint.distanceTo(endPoint);
    const cylinderGeometry = new THREE.CylinderGeometry(
      0.075,
      0.075,
      distance,
      8
    );
    const cylinder = new THREE.Mesh(cylinderGeometry, cylinderMaterial);
    cylinder.position.set(startPoint.x, startPoint.y, startPoint.z);
    const direction = new THREE.Vector3()
      .subVectors(endPoint, startPoint)
      .normalize();

    cylinder.position.add(direction.clone().multiplyScalar(distance / 2));
    cylinder.quaternion.setFromUnitVectors(
      new THREE.Vector3(0, 1, 0),
      direction
    );
    group.add(cylinder);
  });

  return group;
};

export function getCenterPoint(meshes: THREE.Mesh[]) {
  const group = new THREE.Group();
  meshes.forEach((mesh) => {
    group.add(mesh.clone());
  });
  group.updateMatrixWorld(true);
  const center = new THREE.Box3()
    .setFromObject(group)
    .getCenter(group.position);

  return group.localToWorld(center);
}
