import { fabric } from "fabric";
import { logDev } from "utils/logs";

export function removeCanvasEvents(canvas: fabric.Canvas) {
  canvas.off("mouse:down");
  canvas.off("mouse:move");
  canvas.off("mouse:up");
  canvas.off("object:moving");
}

export function deleteObjects(canvas?: fabric.Canvas) {
  if (!canvas) return;

  const activeObject = canvas.getActiveObject() as any;
  if (activeObject?.type === "activeSelection") {
    activeObject.forEachObject(function (obj: any) {
      logDev("delete mutli objects");
      canvas.remove(obj);
    });
  } else {
    // If single object, then delete it
    if (activeObject) {
      canvas.remove(activeObject);
    }
  }
  canvas.discardActiveObject();
}
