import {
  DocumentCategoryStatusType,
  MapDocumentCategoryStatusTypeColor,
  SystemModeType,
} from "constants/enum";
import { DEFAULT_SPACING_OF_TOOLTIP } from "constants/task";
import { UserSetting } from "interfaces/models/user";
import { getColorTextByStatus } from "models/document";
import { getDocumentStatus } from "utils/document";
import { FONT_SIZE_DISPLAY_ORDER, getCurrentViewer } from "utils/forge";
import { LabelClicked } from "utils/forge/extensions/custom-label/constant";
import type { AreaDisplayItem } from ".";

export function createAreaLabel(
  item: AreaDisplayItem,
  options: {
    shouldBeHandleTooltip: boolean;
    selectedIds: string[];
    settings: UserSetting;
  }
) {
  const isSelected =
    options.selectedIds.includes(item.id) ||
    item.items.some((item) => options.selectedIds.includes(item.id));

  const statusColor =
    MapDocumentCategoryStatusTypeColor[
      (item.status ||
        DocumentCategoryStatusType.NotStarted) as DocumentCategoryStatusType
    ];

  const textColor = getColorTextByStatus(item.status);

  const $label = $(`
  <label class="area-label markup update  ${
    isSelected ? " selected-label " : ""
  }" data-id="${item.id}" style="--status-color: ${statusColor}" />
`);
  const $title = $(`<div class="area-title label-item-area-containerTitle">
    <div class="text-ellipsis" style="color: ${textColor}">${item.title}</div>
    <div class="area-title-tooltip label-title-tooltip">${item.title}</div>
  </div>`);

  if (!options.settings?.displayDocumentTitle) {
    $title.hide();
  }

  $title.on("click", () => {
    getCurrentViewer()?.dispatchEvent({
      type: LabelClicked,
      payload: {
        item,
        systemMode: SystemModeType.Document,
      },
    });
  });

  const $docContainer = $(`<div class="area-document-container"/>`);

  $label.append($title);
  $label.append($docContainer);

  item.items.forEach((doc, docIndex) => {
    const documentStatusColor = getDocumentStatus(doc.status).bgColor;
    const isSelected = options.selectedIds.includes(doc.id);
    const displayOrderText = `${Number(docIndex) + 1}`;
    const displayOrderLength = displayOrderText.length;
    const fontSizeDisplayOrder =
      FONT_SIZE_DISPLAY_ORDER[
        displayOrderLength as keyof typeof FONT_SIZE_DISPLAY_ORDER
      ];
    const $child = $(`
    <label class="area-combined-child-label-wrapper combined-child-label-wrapper ${
      isSelected ? "selected-label" : ""
    }" data-id="${
      doc.id
    }" style="--status-color:${documentStatusColor}; border: 2px solid var(--status-color)">
      <div class="marker-pin" style="background: var(--status-color)">
        <span class="marker-label" style="font-size: ${fontSizeDisplayOrder}}">${displayOrderText}</span>
      </div>
      <div class="combined-child-label">
        <div class="body label-item" >
          <div class="area-label-item label-item-area-containerSubTitle label-item-containerTitle" style="${
            !options.settings.displayDocumentSubTitle ? "display: none" : ""
          }">
            <span class="label-item-displayTitle combined-child-title">${
              doc.title || "-"
            }</span>
            <span class="label-title-tooltip label-title-tooltip-${doc.id}">${
      doc.title || "-"
    }</span>
          </div>
        </div>
      </div>
    </label>
  `);

    $child.on("click", () => {
      getCurrentViewer()?.dispatchEvent({
        type: LabelClicked,
        payload: {
          item: doc,
          systemMode: SystemModeType.Document,
        },
      });
    });

    if (options.shouldBeHandleTooltip) {
      $child.on("mouseenter", (e) => {
        if (!e?.currentTarget) return;

        const rect = e.currentTarget.getBoundingClientRect();
        const elementTooltip = e.currentTarget.getElementsByClassName(
          `label-title-tooltip-${doc.id}`
        );

        if (elementTooltip && rect) {
          Array.from(elementTooltip).forEach((el: any) => {
            el.style.top = `${
              rect.y + rect.height - DEFAULT_SPACING_OF_TOOLTIP
            }px`;
          });
        }
      });
    }

    $docContainer.append($child);
  });

  $docContainer[0].style.border = `2px solid ${getDocumentStatus(item.status)}`;

  return $label;
}
