import { useCallback, useState } from "react";

const useTaskSheetTemplateSelectModal = () => {
  const [
    isOpenTaskSheetTemplateSelectModal,
    setIsOpenTaskSheetTemplateSelectModal,
  ] = useState(false);

  const onOpenTaskSheetTemplateSelectModal = useCallback(() => {
    setIsOpenTaskSheetTemplateSelectModal(true);
  }, []);

  const onCloseTaskSheetTemplateSelectModal = useCallback(() => {
    setIsOpenTaskSheetTemplateSelectModal(false);
  }, []);

  return {
    isOpenTaskSheetTemplateSelectModal,
    onOpenTaskSheetTemplateSelectModal,
    onCloseTaskSheetTemplateSelectModal,
  };
};

export default useTaskSheetTemplateSelectModal;
