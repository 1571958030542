import {
  Avatar,
  Badge,
  Box,
  Button,
  Checkbox,
  Flex,
  FormLabel,
  Grid,
  HStack,
  MenuItemOption,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from "@chakra-ui/react";
import { withPresignedUrl } from "components/HOC/presignedUrl";
import { SvgIcon } from "components/SvgIcon";
import DayPicker from "components/ui/DayPicker";
import { TagsInput } from "components/ui/TagInput";
import Tag from "components/ui/TagInput/Tag";
import {
  InspectionItemType,
  MapInspectionItemColor,
  MapInspectionItemIcon,
  MapInspectionItemType,
  TASK_PRINT_MODE,
} from "constants/enum";
import { defaultAvatarPath } from "constants/file";
import { datePickerFilterStyles, radioDefaultCSS } from "constants/styleProps";
import { DocumentCategoryDTO } from "interfaces/dtos/documentCategoryDTO";
import { DocumentTask } from "interfaces/models/documentTask";
import { PartnerCompany } from "interfaces/models/partnerCompany";
import { TaskType } from "interfaces/models/taskType";
import { User, UserSetting } from "interfaces/models/user";
import { FC, useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { getDaypickerDate } from "utils/date";
import { MultiSelectBox } from "./MultiSelectBox";

const AvatarPresigned = withPresignedUrl(Avatar);

const FilterBoxTask: FC<{
  documentTasksByLevel: DocumentTask[];
  userList: User[];
  filterData: UserSetting;
  partnerCompanies: PartnerCompany[];
  isFetchingPartnerCompanies?: boolean;
  clearData: () => void;
  setFilterData: (data: UserSetting) => void;
}> = ({
  documentTasksByLevel,
  userList,
  filterData,
  partnerCompanies,
  setFilterData,
  clearData,
  isFetchingPartnerCompanies,
}) => {
  const { families } = useSelector((state: RootState) => state.forgeViewer);
  const { documentCategories } = useSelector(
    (state: RootState) => state.document
  );
  const { tasks, taskTypes } = useSelector((state: RootState) => state.task);
  const [reRenderDropBox, setReRenderDropBox] = useState<number | undefined>(
    undefined
  );

  const partnerCompanyOptions = useMemo(() => {
    return [
      {
        name: "未設定",
        value: "",
      },
      ...partnerCompanies?.map((item) => ({
        name: item?.name || "",
        value: item?.id,
      })),
    ];
  }, [partnerCompanies]);

  const userBox = (id: string) => {
    const user = userList.find((e) => e.id === id);

    return (
      <Flex alignItems="center" mr={8} key={id}>
        {user ? (
          <AvatarPresigned
            w="3.2rem"
            h="3.2rem"
            mr="1rem"
            src={user?.avatar || defaultAvatarPath}
          />
        ) : (
          <Box w="3.2rem" mr="1rem" />
        )}
        <Text
          color="#000"
          style={{
            maxWidth: "17.5rem",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {user?.name || "未設定"}
        </Text>
      </Flex>
    );
  };

  const onChange = useCallback(
    (value: any, field: keyof UserSetting) => {
      const newFilterData = { ...filterData, [field]: value };

      if (
        !!value &&
        (!!filterData?.deadlineFrom || !!filterData.deadlineTo) &&
        field === "defaultDeadline"
      ) {
        newFilterData.deadlineFrom = undefined;
        newFilterData.deadlineTo = undefined;
      }

      if (
        !!value &&
        ["deadLineFrom", "deadLineTo"]?.includes(field) &&
        filterData?.defaultDeadline
      ) {
        newFilterData.defaultDeadline = false;
      }

      setFilterData({ ...newFilterData, [field]: value });
    },
    [filterData, setFilterData]
  );

  const onChangeInputItem = useCallback(
    (filterData: any, field: keyof UserSetting, value: any) => {
      onChange(
        filterData?.[field]?.filter((v: any) => v !== value),
        field
      );
    },
    [onChange]
  );

  const handleScroll = useCallback((e: any) => {
    setReRenderDropBox(Math.random());
  }, []);

  const renderTag = useCallback(
    ({
      title,
      id,
      field,
    }: {
      title: string;
      id: string;
      field: keyof UserSetting;
    }) => {
      return (
        <HStack
          key={id}
          borderRadius="4px"
          display="flex"
          alignItems="center"
          p="0 5px"
          maxW="27rem"
        >
          <Tag
            key={id}
            text={title}
            boxProps={{
              maxW: "100%",
            }}
            styleText={{
              maxW: "90%",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            isShowTooltip={true}
            remove={() => {
              onChangeInputItem(filterData, field, id);
            }}
          />
        </HStack>
      );
    },
    [filterData, onChangeInputItem]
  );

  const renderInputDocumentTask = useCallback(() => {
    return (filterData?.documentTaskIds || [])?.map((id, index) => {
      const documentTask: DocumentTask | undefined = documentTasksByLevel?.find(
        (doc) => doc.id === id
      );

      if (!documentTask) {
        return null;
      }

      return renderTag({
        title: documentTask?.title || "",
        id: documentTask.id,
        field: "documentTaskIds",
      });
    });
  }, [filterData, documentTasksByLevel, renderTag]);

  const renderInputDocumentCategories = useCallback(() => {
    return (filterData?.taskCategoryIds || [])?.map((id, index) => {
      const documentCategory: DocumentCategoryDTO | undefined =
        documentCategories.find((doc) => doc.id === id);

      if (!documentCategory) {
        return null;
      }

      return renderTag({
        title: documentCategory?.title || "",
        id: documentCategory.id,
        field: "taskCategoryIds",
      });
    });
  }, [filterData?.taskCategoryIds, documentCategories, renderTag]);

  const renderInputPartnerCompany = useCallback(() => {
    return (filterData?.partnerCompanies || [])?.map((id, index) => {
      const partnerCompany: PartnerCompany | undefined = partnerCompanies?.find(
        (item) => item.id === id
      );

      return renderTag({
        title: partnerCompany?.name || "未設定",
        id: partnerCompany?.id || "",
        field: "partnerCompanies",
      });
    });
  }, [filterData?.partnerCompanies, partnerCompanies, renderTag]);

  const renderInputContents = useCallback(() => {
    return filterData?.contents?.map((id, index) => {
      const taskType: TaskType | undefined = taskTypes?.find(
        (item) => item?.id === id
      );

      if (!taskType) {
        return null;
      }

      return renderTag({
        title: taskType?.title,
        id,
        field: "contents",
      });
    });
  }, [filterData, taskTypes, renderTag]);

  const renderMenuContents = useCallback(() => {
    return taskTypes?.map(({ id, title }) => (
      <MenuItemOption
        key={id}
        value={id}
        p="0 1.5rem"
        h="40px"
        borderBottom="1px solid var(--primary-border-color)"
        _last={{ borderBottom: "none" }}
        style={{ maxWidth: "29rem" }}
        icon={<SvgIcon src="/img/icon-action-check_circle.svg" />}
        onClick={({ currentTarget }) =>
          onChange(
            filterData?.contents?.includes(currentTarget.value)
              ? filterData?.contents?.filter((v) => v !== currentTarget.value)
              : [...filterData?.contents, currentTarget.value],
            "contents"
          )
        }
      >
        <Badge
          variant="pin"
          bg="gray"
          mr="3rem"
          p="0.1rem 0.5rem"
          minH="1.8rem"
          h="auto"
          maxW="24rem"
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
          textTransform="none"
        >
          {title}
        </Badge>
      </MenuItemOption>
    ));
  }, [filterData?.contents, taskTypes, onChange]);

  const renderInputObjectType = useCallback(() => {
    return filterData?.objectTypes?.map((type, index) => {
      return renderTag({
        title: families.find((family) => family.id === type)?.name || "",
        id: type,
        field: "objectTypes",
      });
    });
  }, [families, filterData?.objectTypes, renderTag]);

  const renderMenuObjectType = useCallback(() => {
    return families.map((family) => (
      <MenuItemOption
        key={family.id}
        value={family.id}
        p=".5rem 1.5rem"
        borderBottom="1px solid var(--primary-border-color)"
        _last={{ borderBottom: "none" }}
        style={{ maxWidth: "29rem" }}
        icon={<SvgIcon src="/img/icon-action-check_circle.svg" />}
        onClick={({ currentTarget }) =>
          onChange(
            filterData?.objectTypes?.includes(currentTarget.value)
              ? filterData?.objectTypes?.filter(
                  (v) => v !== currentTarget.value
                )
              : [...(filterData?.objectTypes || []), currentTarget.value],
            "objectTypes"
          )
        }
      >
        <Badge
          variant="pin"
          bg="gray"
          mr="3rem"
          p="0.1rem 0.5rem"
          minH="1.8rem"
          h="auto"
          maxW="24rem"
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
          textTransform="none"
        >
          {family.name}
        </Badge>
      </MenuItemOption>
    ));
  }, [families, filterData?.objectTypes, onChange]);

  const documentCategoriesHaveTask = useMemo(() => {
    const documentItemIds: string[] = [];
    for (const t of tasks) {
      if (t.documentItemId) documentItemIds.push(t.documentItemId);
    }
    if (!documentItemIds.length) {
      return [];
    }

    return documentCategories.filter((cat) => {
      if (!cat.itemIds?.length && !cat.selectedExternalIds?.length)
        return false;

      return (cat.itemIds || cat.selectedExternalIds)?.find((itemId) =>
        documentItemIds.includes(itemId)
      );
    });
  }, [documentCategories, tasks]);

  const renderMenuDocumentCategories = useCallback(() => {
    return documentCategoriesHaveTask.map((cat) => (
      <MenuItemOption
        key={cat.id}
        value={cat.id}
        icon={<SvgIcon src="/img/icon-action-check_circle.svg" />}
        onClick={({ currentTarget }) =>
          onChange(
            (filterData?.taskCategoryIds || [])?.includes(currentTarget.value)
              ? (filterData?.taskCategoryIds || [])?.filter(
                  (v) => v !== currentTarget.value
                )
              : [...(filterData?.taskCategoryIds || []), currentTarget.value],
            "taskCategoryIds"
          )
        }
      >
        <Text
          maxW="24rem"
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          {cat.title}
        </Text>
      </MenuItemOption>
    ));
  }, [documentCategoriesHaveTask, filterData?.taskCategoryIds, onChange]);

  return (
    <Stack
      spacing={4}
      width="100%"
      height="100%"
      p={4}
      bgColor="white"
      overflow="hidden auto"
      onScroll={handleScroll}
    >
      <Flex justifyContent="space-between">
        <Text fontWeight="bold">フィルター</Text>
        <Button
          onClick={clearData}
          variant="link"
          colorScheme="blue"
          textDecoration="underline"
        >
          クリア
        </Button>
      </Flex>
      <MultiSelectBox<string>
        label="作成者"
        value={filterData?.userCreated}
        inputItems={filterData?.userCreated?.map((e) => userBox(e))}
        menuItems={userList
          .filter(({ name }) => name)
          .map(({ id }) => (
            <MenuItemOption
              key={id}
              value={id}
              p=".5rem 1.5rem"
              borderBottom="1px solid var(--primary-border-color)"
              _last={{ borderBottom: "none" }}
              icon={<SvgIcon src="/img/icon-action-check_circle.svg" />}
              onClick={({ currentTarget }) =>
                onChange(
                  filterData?.userCreated.includes(currentTarget.value)
                    ? filterData?.userCreated.filter(
                        (v) => v !== currentTarget.value
                      )
                    : [...filterData?.userCreated, currentTarget.value],
                  "userCreated"
                )
              }
            >
              {id && userBox(id)}
            </MenuItemOption>
          ))}
        disabled={!userList.filter(({ name }) => name).length}
      />
      <MultiSelectBox<string>
        label="担当者"
        value={filterData?.userAssigned}
        inputItems={filterData?.userAssigned?.map((e) => userBox(e))}
        menuItems={[
          {
            name: "未設定",
            id: "",
          },
          ...userList,
        ]
          .filter(({ name }) => name)
          .map(({ id }) => (
            <MenuItemOption
              key={id}
              value={id}
              p=".5rem 1.5rem"
              borderBottom="1px solid var(--primary-border-color)"
              _last={{ borderBottom: "none" }}
              icon={<SvgIcon src="/img/icon-action-check_circle.svg" />}
              onClick={({ currentTarget }) =>
                onChange(
                  filterData?.userAssigned?.includes(currentTarget.value)
                    ? filterData?.userAssigned?.filter(
                        (v) => v !== currentTarget.value
                      )
                    : [
                        ...(filterData?.userAssigned || []),
                        currentTarget.value,
                      ],
                  "userAssigned"
                )
              }
            >
              {userBox(id as string)}
            </MenuItemOption>
          ))}
        disabled={!userList.filter(({ name }) => name).length}
      />

      <MultiSelectBox<string>
        label="協力会社"
        value={filterData?.partnerCompanies || []}
        inputItems={renderInputPartnerCompany()}
        menuItems={partnerCompanyOptions?.map(({ name, value }) => (
          <MenuItemOption
            key={name}
            value={value}
            icon={<SvgIcon src="/img/icon-action-check_circle.svg" />}
            onClick={({ currentTarget }) => {
              onChange(
                (filterData?.partnerCompanies || [])?.includes(
                  currentTarget.value
                )
                  ? (filterData?.partnerCompanies || [])?.filter(
                      (v) => v !== currentTarget.value
                    )
                  : [
                      ...(filterData?.partnerCompanies || []),
                      currentTarget.value,
                    ],
                "partnerCompanies"
              );
            }}
          >
            <Text
              maxW="24rem"
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {name}
            </Text>
          </MenuItemOption>
        ))}
        disabled={!partnerCompanyOptions.length}
        isLoading={isFetchingPartnerCompanies}
      />

      <MultiSelectBox<string>
        label="是正指示書の書類名"
        value={filterData?.documentTaskIds || []}
        inputItems={renderInputDocumentTask()}
        menuItems={documentTasksByLevel.map(({ id, title }) => (
          <MenuItemOption
            key={id}
            value={id}
            icon={<SvgIcon src="/img/icon-action-check_circle.svg" />}
            onClick={({ currentTarget }) =>
              onChange(
                (filterData?.documentTaskIds || [])?.includes(
                  currentTarget.value
                )
                  ? (filterData?.documentTaskIds || [])?.filter(
                      (v) => v !== currentTarget.value
                    )
                  : [
                      ...(filterData?.documentTaskIds || []),
                      currentTarget.value,
                    ],
                "documentTaskIds"
              )
            }
          >
            <Text
              maxW="24rem"
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {title}
            </Text>
          </MenuItemOption>
        ))}
        disabled={!documentTasksByLevel.length}
      />

      <>
        <MultiSelectBox<string>
          label="指摘内容"
          value={filterData?.contents}
          reRenderDropBox={reRenderDropBox}
          inputItems={renderInputContents()}
          menuItems={renderMenuContents()}
          rowHeight={40}
          isFullWidth={true}
          disabled={!taskTypes?.length}
        />
        <MultiSelectBox<string>
          label="検査対象抽出条件"
          value={filterData?.objectTypes}
          reRenderDropBox={reRenderDropBox}
          inputItems={renderInputObjectType()}
          menuItems={renderMenuObjectType()}
          disabled={!families.length}
        />
        <MultiSelectBox<string>
          label="ステータス"
          value={filterData?.status}
          inputItems={filterData?.status?.map((e) => (
            <Text
              key={e}
              bg={MapInspectionItemColor[e as InspectionItemType]}
              color="#fff"
              py=".25rem"
              px="1.6rem"
              borderRadius="4px"
              display="flex"
              alignItems="center"
            >
              <SvgIcon src={MapInspectionItemIcon[e as InspectionItemType]} />
              {MapInspectionItemType[e as InspectionItemType]}
            </Text>
          ))}
          menuItems={Object.keys(MapInspectionItemType).map((type) => (
            <MenuItemOption
              key={type}
              value={type}
              icon={<SvgIcon src="/img/icon-action-check_circle.svg" />}
              onClick={({ currentTarget }) =>
                onChange(
                  filterData?.status?.includes(currentTarget.value)
                    ? filterData?.status?.filter(
                        (v) => v !== currentTarget.value
                      )
                    : [...filterData?.status, currentTarget.value],
                  "status"
                )
              }
            >
              <Text
                bg={MapInspectionItemColor[type as InspectionItemType]}
                color="#fff"
                py=".25rem"
                px="1.6rem"
                borderRadius="4px"
                display="flex"
                alignItems="center"
              >
                <SvgIcon
                  src={MapInspectionItemIcon[type as InspectionItemType]}
                />
                {MapInspectionItemType[type as InspectionItemType]}
              </Text>
            </MenuItemOption>
          ))}
        />
        <Stack>
          <FormLabel as="legend" w="100%">
            タグ
            <TagsInput
              options={families.map((family) => ({
                name: family.name,
                value: family.name,
              }))}
              value={filterData?.tags}
              placeHolder="タグ"
              onChange={(value) => onChange(value, "tags")}
              isWithSubmenu
            />
          </FormLabel>
        </Stack>

        <MultiSelectBox<string>
          label="検査書類名"
          value={filterData?.taskCategoryIds || []}
          reRenderDropBox={reRenderDropBox}
          inputItems={renderInputDocumentCategories()}
          menuItems={renderMenuDocumentCategories()}
          disabled={!documentCategoriesHaveTask.length}
        />
      </>

      <Stack>
        <FormLabel as="legend">
          開始予定日
          <Flex alignItems="center">
            <DayPicker
              name="startDateScheduledFrom"
              value={getDaypickerDate(
                filterData?.startDateScheduledFrom as Date
              )}
              maxDate={getDaypickerDate(
                filterData?.startDateScheduledTo as Date
              )}
              onSelectedDay={onChange as any}
              onClose={() => {}}
              isShowIcon={false}
              inputProps={{ fontSize: "1.2rem" }}
              datePickerFilterStyles={datePickerFilterStyles}
              startDate={new Date(filterData?.startDateScheduledFrom as Date)}
              endDate={new Date(filterData?.startDateScheduledTo as Date)}
              selectsStart
              shouldCloseOnSelect
            />
            <Text mx={2}>-</Text>
            <DayPicker
              name="startDateScheduledTo"
              value={getDaypickerDate(filterData?.startDateScheduledTo as Date)}
              minDate={getDaypickerDate(
                filterData?.startDateScheduledFrom as Date
              )}
              onSelectedDay={onChange as any}
              onClose={() => {}}
              isShowIcon={false}
              inputProps={{ fontSize: "1.2rem" }}
              datePickerFilterStyles={datePickerFilterStyles}
              startDate={new Date(filterData?.startDateScheduledFrom as Date)}
              endDate={new Date(filterData?.startDateScheduledTo as Date)}
              selectsEnd
              shouldCloseOnSelect
            />
          </Flex>
        </FormLabel>
      </Stack>

      <Stack>
        <FormLabel as="legend">
          完了期限
          <Flex alignItems="center">
            <DayPicker
              name="deadlineFrom"
              value={getDaypickerDate(filterData?.deadlineFrom as Date)}
              maxDate={getDaypickerDate(filterData?.deadlineTo as Date)}
              onSelectedDay={onChange as any}
              onClose={() => {}}
              isShowIcon={false}
              inputProps={{ fontSize: "1.2rem" }}
              datePickerFilterStyles={datePickerFilterStyles}
              startDate={new Date(filterData?.deadlineFrom as Date)}
              endDate={new Date(filterData?.deadlineTo as Date)}
              selectsStart
              shouldCloseOnSelect
            />
            <Text mx={2}>-</Text>
            <DayPicker
              name="deadlineTo"
              value={getDaypickerDate(filterData?.deadlineTo as Date)}
              minDate={getDaypickerDate(filterData?.deadlineFrom as Date)}
              onSelectedDay={onChange as any}
              onClose={() => {}}
              isShowIcon={false}
              inputProps={{ fontSize: "1.2rem" }}
              datePickerFilterStyles={datePickerFilterStyles}
              startDate={new Date(filterData?.deadlineFrom as Date)}
              endDate={new Date(filterData?.deadlineTo as Date)}
              selectsEnd
              shouldCloseOnSelect
            />
          </Flex>
        </FormLabel>
      </Stack>

      <Checkbox
        isChecked={filterData?.defaultDeadline}
        onChange={() => {
          onChange(!filterData?.defaultDeadline, "defaultDeadline");
        }}
      >
        完了期限未設定のみ表示
      </Checkbox>

      <Stack>
        <FormLabel as="legend">
          写真
          <Grid gridTemplateColumns={"50% 50%"}>
            <Checkbox
              flexGrow={1}
              isChecked={filterData?.hasImage}
              onChange={() => onChange(!filterData?.hasImage, "hasImage")}
            >
              写真設定済
            </Checkbox>
            <Checkbox
              flexGrow={1}
              isChecked={filterData?.hasNoImage}
              onChange={() => onChange(!filterData?.hasNoImage, "hasNoImage")}
            >
              写真未設定
            </Checkbox>
          </Grid>
        </FormLabel>
      </Stack>
      <Stack>
        <FormLabel as="legend">
          指摘 / オブジェクト表示
          <Grid gridTemplateColumns={"50% 50%"}>
            <Checkbox
              isChecked={filterData?.applyToLeftBar}
              onChange={() =>
                onChange(!filterData?.applyToLeftBar, "applyToLeftBar")
              }
            >
              指摘
            </Checkbox>
            <Checkbox
              isChecked={filterData?.applyToViewer}
              onChange={() =>
                onChange(!filterData?.applyToViewer, "applyToViewer")
              }
            >
              オブジェクト
            </Checkbox>
          </Grid>
        </FormLabel>
      </Stack>

      <Stack>
        <FormLabel as="legend">
          印刷対象
          <RadioGroup
            sx={radioDefaultCSS}
            value={filterData?.taskPrintMode || TASK_PRINT_MODE.ALL}
            onChange={(value: string) => onChange(value, "taskPrintMode")}
          >
            <Grid gridTemplateColumns={"50% 50%"}>
              <Radio value={TASK_PRINT_MODE.ALL}>すべて</Radio>
              <Radio value={TASK_PRINT_MODE.PRINTABLE}>印刷対象のみ</Radio>
            </Grid>
          </RadioGroup>
        </FormLabel>
      </Stack>
    </Stack>
  );
};

export default FilterBoxTask;
