import { useRef, useState } from "react";
import { MenuPlacement } from "react-select/dist/declarations/src";

const MAX_MENU_HEIGHT = 200;

const useDropdownDirection = () => {
  const initFormRef = {
    assignUserEl: null,
    partnerCompanyEl: null,
    confirmedUserEl: null,
    endDateScheduledEl: null,
    deadlineEl: null,
    confirmedDateTimeEl: null,
  };

  const formRef = useRef<{
    assignUserEl: HTMLDivElement | null;
    confirmedUserEl: HTMLDivElement | null;
    partnerCompanyEl: HTMLDivElement | null;
    endDateScheduledEl: HTMLDivElement | null;
    deadlineEl: HTMLDivElement | null;
    confirmedDateTimeEl: HTMLDivElement | null;
  }>(initFormRef);

  const [partnerCompanyDirection, setPartnerCompanyDirection] =
    useState<MenuPlacement>("top");
  const [assignUserDirection, setAssignUserDirection] =
    useState<MenuPlacement>("top");
  const [confirmedUserDirection, setConfirmedUserDirection] =
    useState<MenuPlacement>("top");
  const [endDateScheduledDirection, setEndDateScheduledDirection] =
    useState<MenuPlacement>("top");
  const [deadlineDirection, setDeadlineDirection] =
    useState<MenuPlacement>("top");
  const [confirmedDateTimeDirection, setConfirmedDateTimeDirection] =
    useState<MenuPlacement>("top");

  const scrollTopRef = useRef<number>(0);

  const calculateScrolltop = (e: React.UIEvent<HTMLElement>) => {
    const containerEl = e.target as HTMLElement;
    const scrollTop = containerEl?.scrollTop;

    if (!formRef.current?.partnerCompanyEl || !containerEl) {
      return;
    }

    if (
      scrollTop + MAX_MENU_HEIGHT >
      Number(formRef.current?.assignUserEl?.offsetTop)
    ) {
      setAssignUserDirection("bottom");
    } else {
      setAssignUserDirection("top");
    }

    if (
      scrollTop + MAX_MENU_HEIGHT >
      Number(formRef.current?.confirmedUserEl?.offsetTop)
    ) {
      setConfirmedUserDirection("bottom");
    } else {
      setConfirmedUserDirection("top");
    }

    scrollTopRef.current = scrollTop;
  };

  const checkDopdownDirection = () => {
    const scrollTop = scrollTopRef.current;

    if (!scrollTop) {
      return;
    }

    if (
      scrollTop + MAX_MENU_HEIGHT >
      Number(formRef.current?.partnerCompanyEl?.offsetTop)
    ) {
      setPartnerCompanyDirection("bottom");
    } else {
      setPartnerCompanyDirection("top");
    }

    if (
      scrollTop + MAX_MENU_HEIGHT >
      Number(formRef.current?.endDateScheduledEl?.offsetTop)
    ) {
      setEndDateScheduledDirection("bottom");
    } else {
      setEndDateScheduledDirection("top");
    }

    if (
      scrollTop + MAX_MENU_HEIGHT >
      Number(formRef.current?.deadlineEl?.offsetTop)
    ) {
      setDeadlineDirection("bottom");
    } else {
      setDeadlineDirection("top");
    }

    if (
      scrollTop + MAX_MENU_HEIGHT >
      Number(formRef.current?.confirmedDateTimeEl?.offsetTop)
    ) {
      setConfirmedDateTimeDirection("bottom");
    } else {
      setConfirmedDateTimeDirection("top");
    }
  };

  return {
    partnerCompanyDirection,
    assignUserDirection,
    confirmedUserDirection,
    endDateScheduledDirection,
    deadlineDirection,
    confirmedDateTimeDirection,
    formRef,
    calculateScrolltop,
    checkDopdownDirection,
  };
};

export default useDropdownDirection;
