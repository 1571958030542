import { documentItemLogApi } from "apiClient/v2";
import { DocumentCategoryKey } from "constants/enum";
import { DocumentItemLog } from "interfaces/models/documentItem";
import {
  GetContentCategoryLog,
  getDocumentCategoryContentLog,
} from "models/documentItemLog";
import { useCallback, useEffect, useState } from "react";
import { sortArrayByField } from "utils/array";

interface Props {
  documentCategoryId?: string;
  currentUserId?: string;
  isLoadingDocument: boolean;
}

const useDocumentCategoryLogs = (props?: Props) => {
  const { documentCategoryId, currentUserId, isLoadingDocument } = props || {};

  const [documentCategoryLogs, setDocumentCategoryLogs] = useState<
    DocumentItemLog[]
  >([]);

  useEffect(() => {
    (async () => {
      if (!documentCategoryId || isLoadingDocument) {
        setDocumentCategoryLogs([]);

        return;
      }

      const logs = await documentItemLogApi.handleGetItemLogList({
        categoryId: documentCategoryId,
      });
      setDocumentCategoryLogs(
        sortArrayByField<DocumentItemLog>(logs, "createdAt")
      );
    })();
  }, [documentCategoryId, isLoadingDocument]);

  const insertDocumentCategoryLog = useCallback(
    async ({
      field,
      value,
      nameDynamicField,
      prefixFieldName,
      dynamicFieldKeysUpdatedAt,
    }: GetContentCategoryLog) => {
      if (!currentUserId || !documentCategoryId) {
        return;
      }

      const now = new Date();
      const content = getDocumentCategoryContentLog({
        field: field as DocumentCategoryKey,
        value,
        nameDynamicField,
        prefixFieldName,
      }) as any;

      const res = await documentItemLogApi.createItemLog({
        content,
        createdBy: currentUserId,
        title: content?.title,
        field,
        value,
        categoryId: documentCategoryId,
        dynamicFieldKeysUpdatedAt,
        createdAt: now,
        updatedAt: now,
      } as DocumentItemLog);

      const log = res?.data;
      if (log?.id) {
        setDocumentCategoryLogs((prev) => [...prev, log]);
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [documentCategoryId, currentUserId]
  );

  return {
    documentCategoryLogs,
    setDocumentCategoryLogs,
    insertDocumentCategoryLog,
  };
};

export default useDocumentCategoryLogs;
