export const getBimFileInfo = (
  urn: string
): { bimFileId: string; version: string } => {
  const info = decodeURIComponent(urn).split("?");

  if (info.length >= 2) {
    return { bimFileId: info[0], version: info[1].split("=").pop() || "1" };
  }

  return { bimFileId: "", version: "" };
};
