import { ComponentStyleConfig } from "@chakra-ui/react";

export const buttonStyle: ComponentStyleConfig = {
  defaultProps: {
    minWidth: "1rem",
    px: 0,
  },
  baseStyle: {
    _active: {
      opacity: 0.7,
    },
    _disabled: {
      "&:active": {
        opacity: 0.4,
      },
    },
    _focus: {
      boxShadow: "none",
    },
    fontWeight: "medium",
    borderColor: "border.default",
  },
  variants: {
    solid: {
      height: "unset",
    },
    blue: {
      fontSize: "1.4rem",
      lineHeight: "2rem",
      color: "#FFFFFF",
      bgColor: "var(--blue)",
      borderRadius: "4px",
      p: "1.05rem 2.4rem",
      height: "unset",
      boxShadow: "inset 0px -1px 2px #00000029",
    },
    blueLite: {
      fontSize: "1.4rem",
      lineHeight: "2rem",
      color: "#FFFFFF",
      bgColor: "#1795FE",
      borderRadius: "4px",
      p: "1.2rem 2.4rem",
      height: "unset",
      boxShadow: "inset 0px -1px 2px #00000029",
      fontWeight: "medium",
    },
    white: {
      fontSize: "1.4rem",
      lineHeight: "2rem",
      color: "#3CA4FF",
      bgColor: "#FFFFFF",
      borderRadius: "4px",
      p: "1.05rem 2.4rem",
      height: "unset",
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "border.default",
    },
    whiteSquare: {
      fontSize: "1.4rem",
      lineHeight: "2rem",
      bgColor: "#FFFFFF",
      borderRadius: "4px",
      p: "1rem",
      height: "unset",
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "border.default",
      fontWeight: "bold",
    },
    whiteIcon: {
      fontSize: "1.4rem",
      fontWeight: "medium",
      lineHeight: "1.6rem",
      color: "#000000",
      bgColor: "#FFFFFF",
      borderRadius: "4px",
      p: "0.5rem 1rem",
      height: "4.8rem",
      boxShadow: "0px 3px 6px #00000029",
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "border.default",
      _focus: {
        boxShadow: "0px 3px 6px #00000029",
      },
    },
    red: {
      fontSize: "1.4rem",
      lineHeight: "2rem",
      color: "#FFFFFF",
      bgColor: "#B00020",
      borderRadius: "4px",
      p: "1.05rem 2.4rem",
      height: "unset",
      boxShadow: "inset 0px -1px 2px #00000029",
    },
    gray: {
      fontSize: "1.4rem",
      lineHeight: "2rem",
      color: "#00000099",
      bgColor: "#E0E1E2",
      borderRadius: "4px",
      p: "1.05rem 2.4rem",
      height: "unset",
      boxShadow: "inset 0px -1px 2px #0000003D",
    },
    black: {
      fontSize: "1.4rem",
      lineHeight: "2rem",
      color: "#FFFFFF",
      bgColor: "var(--black)",
      borderRadius: "4px",
      p: "1.05rem 2.4rem",
    },
    tab: {
      p: "1.4rem 3rem",
      fontSize: "1.6rem",
      color: "#000000CC",
      mt: "1.1rem",
      height: "unset",
    },
    back: {
      p: "0",
      fontSize: "1.6rem",
      color: "var(--blue)",
      height: "unset",
    },
  },
};
