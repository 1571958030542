export default class WorkerHandler {
  resolvers: { [key: string]: (arg: any) => unknown } = {};
  rejectors: { [key: string]: (arg: any) => unknown } = {};
  count = 0;
  worker: Worker | undefined = undefined;

  constructor(worker: Worker) {
    this.worker = worker;

    this.worker.onmessage = ({ data }: MessageEvent) => {
      const { result, error, __messageId } = data;
      if (error) {
        this.rejectors[__messageId](JSON.stringify(error));
      } else {
        this.resolvers[__messageId](result);
      }
      delete this.resolvers[__messageId];
      delete this.rejectors[__messageId];
    };
  }

  call(args: any) {
    if (!this.worker) {
      return;
    }

    const __messageId = this.count++;
    this.worker.postMessage({ args, __messageId });

    return new Promise((resolve, reject) => {
      this.resolvers[__messageId] = resolve;
      this.rejectors[__messageId] = reject;
    });
  }
}
