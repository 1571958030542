import {
  CellType,
  RowType,
  TemplateComponent,
} from "interfaces/models/component";
import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setComponentSelected, setSelectedCell } from "redux/documentSlice";
import { RootState } from "redux/store";
import { getCellPositionByCellId } from "utils/tableCell";
import { iUseSubTableComponentPreview } from "..";

const useSubTableComponentPreview = (props: iUseSubTableComponentPreview) => {
  const {
    selectedCells = [],
    componentSelected,
    component,
    parentCell,
    isOnlyView,
  } = props;
  const [canEditCell, setCanEditCell] = useState(false);
  const { scanComponents } = useSelector((state: RootState) => state.editor);
  const dispatch = useDispatch();

  const subTable = useMemo(() => {
    return parentCell.subTable;
  }, [parentCell.subTable]);

  const listMapCellSelected = useMemo(() => {
    const mapId: { [key: string]: string } = {};
    (selectedCells || []).forEach((item) => {
      const key: string = item.cellId || "";
      mapId[key] = key;
    });

    return mapId;
  }, [selectedCells]);

  const isSelectComponent = useMemo(() => {
    return (
      !isOnlyView &&
      (componentSelected?.componentId === component.componentId ||
        !!scanComponents?.find((c) => c?.componentId === component.componentId))
    );
  }, [
    component.componentId,
    componentSelected?.componentId,
    isOnlyView,
    scanComponents,
  ]);

  const isTableSelected =
    !isOnlyView && isSelectComponent && selectedCells?.length === 0;

  const isSelectSingleCell = (row: RowType, cell: CellType) => {
    return (
      selectedCells?.length === 1 &&
      selectedCells?.[0]?.idRow === row.idRow &&
      selectedCells?.[0]?.cellId === cell.cellId
    );
  };

  const addCellToSelectedCells = (cell: CellType) => {
    const newSelectedCells = [...selectedCells, cell];

    dispatch(setSelectedCell(newSelectedCells));

    return;
  };

  const removeCellFromSelectedCells = (cell: CellType) => {
    const newSelectedCells = selectedCells?.filter(
      (item) => item.cellId !== cell.cellId
    );

    dispatch(setSelectedCell(newSelectedCells));

    return;
  };

  // logic of repeat table
  //const selectMultiCells = (cell: CellType) => {
  //const isSelectedCellsIncludesCell = !selectedCells?.some(
  //(item) => item.cellId === cell.cellId
  //);

  //if (isSelectedCellsIncludesCell) {
  //addCellToSelectedCells(cell);

  //return;
  //}

  //removeCellFromSelectedCells(cell);

  //return;
  //};

  //const selectSingleCell = (cell: CellType) => {
  //const allCells = getAllCells(component);

  //const repeatedCells =
  //(componentSelected?.type === TemplateComponentType.TableHeader &&
  //componentSelected?.detail?.isRepeatTable) ||
  //componentSelected?.detail?.isRepeat
  //? allCells.filter((item) => {
  //return item.position?.idColumn === cell.position?.idColumn;
  //})
  //: [cell];

  //dispatch(setSelectedCell(repeatedCells));
  //};

  //const handleSelectCell = (
  //e: React.MouseEvent<HTMLElement>,
  //cell: CellType
  //) => {
  //e.preventDefault();
  //e.stopPropagation();

  //if (cell?.repeatedTableIndex || cell?.isRepeatedRow) {
  //dispatch(setComponentSelected(component));
  //dispatch(setSelectedCell([]));

  //return;
  //}

  //if (scanComponents.length) {
  //dispatch(setScanComponents([]));
  //}

  //// handle select component
  //if (componentSelected?.componentId !== component.componentId) {
  //dispatch(setComponentSelected(component as TemplateComponent));
  //}

  //if (
  //component.isLinkedTable &&
  //currentTemplate?.documentType !== DocumentTemplateType?.FLEXIBLE_DUCT &&
  //Number(cell.idRow?.split("-").pop()) > 0 &&
  //component?.detail?.isRepeat
  //) {
  //dispatch(setSelectedCell([]));

  //return;
  //}

  //if (!e.ctrlKey && !e.metaKey) {
  //selectSingleCell(cell);

  //return;
  //}

  //selectMultiCells(cell);
  //};

  const handleSelectCell = (
    e: React.MouseEvent<HTMLElement>,
    cell: CellType
  ) => {
    e.preventDefault();
    e.stopPropagation();

    const indexCell = getCellPositionByCellId({
      cellId: cell.cellId,
      component,
    });

    // handle selected component
    if (componentSelected?.componentId !== component.componentId) {
      dispatch(setComponentSelected(component as TemplateComponent));
      dispatch(setSelectedCell([cell]));

      return;
    }

    if (indexCell.parentIndex.row > 0 && component.detail?.isRepeat) {
      dispatch(setSelectedCell([]));

      return;
    }

    if (!e.ctrlKey && !e.metaKey) {
      // handle select cell
      if (
        selectedCells?.length > 1 ||
        selectedCells?.length === 0 ||
        canEditCell
      ) {
        setCanEditCell(false);
        dispatch(setSelectedCell([cell]));
      } else if (
        selectedCells[0].idRow === cell.idRow &&
        selectedCells[0].cellId === cell.cellId
      ) {
        setCanEditCell(true);
      } else {
        dispatch(setSelectedCell([cell]));
      }
    } else {
      if (selectedCells.some((item) => !item.isSubCell)) {
        return;
      }
      // if cell is existed  => remove this
      const newArr = [...selectedCells];
      const index = newArr.findIndex(
        (item) => item?.idRow === cell.idRow && item?.cellId === cell.cellId
      );
      // handle when cell is existed
      if (index !== -1) {
        newArr.splice(index, 1);
        if (newArr.length === 0) {
          dispatch(setComponentSelected({} as TemplateComponent));
        }
        dispatch(setSelectedCell(newArr));
      } else {
        dispatch(setSelectedCell([...newArr, cell]));
      }
    }
  };

  return {
    isSelectSingleCell,
    isTableSelected,
    listMapCellSelected,
    subTable,
    canEditCell,
    handleSelectCell,
  };
};

export default useSubTableComponentPreview;
