type Event = MouseEvent | TouchEvent;

export enum EventType {
  TOUCH_MOVE = "touch_move",
  TOUCH_START = "touch_start",
  TOUCH_END = "touch_end",
  CLICK = "click",
}

interface Option {
  /**
   * Double click or double touch
   * @param e
   * @returns
   */
  onDoubleEvent?: (e: Event) => void;

  /**
   * Single click or single touch
   * @param e
   * @returns
   */
  onEvent?: (e: Event) => void;
}

export class EventDetector {
  protected timeOut: any;
  protected delay = 300; // 300ms
  protected lastTouchTime: any;
  protected timeDelayClick = 200;

  public constructor(protected option: Option) {}

  public dispatchEvent = (type: EventType) => (event: any) => {
    switch (type) {
      case EventType.TOUCH_MOVE:
        if (this.lastTouchTime) {
          this.lastTouchTime = null;
        }
        break;
      case EventType.TOUCH_START:
        event.preventDefault();
        event.stopPropagation();
        if (event.touches?.length === 1) {
          this.lastTouchTime = Date.now();
        }
        break;
      case EventType.TOUCH_END:
        event.preventDefault();
        event.stopPropagation();
        if (this.lastTouchTime) {
          const total = Date.now() - this.lastTouchTime;
          this.lastTouchTime = null;
          if (total <= this.timeDelayClick) {
            return this.handler(event);
          }
        }
        break;
      case EventType.CLICK:
        event.preventDefault();
        event.stopPropagation();

        return this.handler(event);
    }
  };

  protected handler(event: any) {
    if (this.timeOut) {
      clearTimeout(this.timeOut);
      this.timeOut = null;
      this.option.onDoubleEvent?.(event);
    } else {
      this.timeOut = setTimeout(() => {
        this.option.onEvent?.(event);
        this.timeOut = null;
      }, this.delay);
    }
  }
}
