import { Table, TableCellProps, Tbody, Tr } from "@chakra-ui/react";
import { iLimit } from "components/editor-builder/component-preview/TableComponentPreview/TableTdComponentPreview";
import { GRID_TEMPLATE_SIZE } from "constants/document";
import { CellSizeSetting, ContentType, LinkedDataField } from "constants/enum";
import { Blackboard } from "interfaces/models/blackboard";
import {
  CellSize,
  CellType,
  DisplayComponent,
  TemplateComponent,
} from "interfaces/models/component";
import {
  DocumentTemplate,
  iSelfInspectionProps,
  iModuleChillerProps,
  iPacProps,
  iSleevePipeProps,
  iTaskItemDataProps,
} from "interfaces/models/documentTemplate";
import { DEFAULT_BORDER_COLOR_ACTIVE } from "pages/document/template-page/hooks";
import { memo } from "react";
import { DocumentState } from "redux/documentSlice";
import { getOriginalSize } from "utils/document";
import { getCellPositionByCellId } from "utils/tableCell";
import { getPreviewDocumentCommonProps, iPreviewDocumentCommonProps } from "..";
import useSubTableComponentPreview from "./hooks";
import SubCellComponentPreview from "./SubCellComponentPreview";

export interface iSubTableComponentPreviewProps
  extends iPreviewDocumentCommonProps {
  component: TemplateComponent;
  parentCell: CellType;
  displaySize: DisplayComponent["size"];
  zoomRatio: number;
  cellSize: CellSize;
  sizePageRatio: number;
  pageDirectionRatio: number;
  isOnlyView?: boolean;
  isDuplicateRow?: boolean;
  isParentLastColumn?: boolean;
  limit: iLimit;
  isBlackboardTemplateImage?: boolean;
  isBlackboardTemplate?: boolean;
  isTaskSheetTemplateItem?: boolean;
  selectedCells?: CellType[];
  componentSelected: TemplateComponent;
  isComponentDragging: boolean;
  components: TemplateComponent[];
  currentTemplate: DocumentTemplate;
  documentContainerSize: DocumentState["documentContainerSize"];

  onChangeBlackboardData?: (data: Blackboard) => void;
}

export interface iUseSubTableComponentPreview {
  selectedCells?: CellType[];
  componentSelected?: TemplateComponent;
  component: TemplateComponent;
  parentCell: CellType;
  isOnlyView?: boolean;
}

const SubTableComponentPreview = ({
  component,
  parentCell,
  displaySize,
  zoomRatio,
  cellSize,
  sizePageRatio,
  pageDirectionRatio,
  isOnlyView = false,
  isDuplicateRow = false,
  isParentLastColumn,
  limit,
  isBlackboardTemplateImage = false,
  isBlackboardTemplate = false,
  isTaskSheetTemplateItem = false,
  componentSelected,
  selectedCells = [],
  isComponentDragging = false,
  components = [],
  documentContainerSize,
  currentTemplate,
  onChangeBlackboardData,
  ...props
}: iSubTableComponentPreviewProps) => {
  const {
    contentType,
    pacProps,
    flexibleDuctProps,
    sleevePipeProps,
    moduleChillerProps,
    previewProps,
    blackboardData,
    blackboardTemplateProps,
    documentTaskDataProps,
    taskItemDataProps,
  } = getPreviewDocumentCommonProps(props);

  const {
    isSelectSingleCell,
    isTableSelected,
    listMapCellSelected,
    subTable,
    canEditCell,

    handleSelectCell,
  } = useSubTableComponentPreview({
    componentSelected,
    component,
    parentCell,
    isOnlyView,
    selectedCells,
  });

  const handleFunction =
    (func: Function) =>
    (...args: any[]) => {
      if (!isOnlyView && func) {
        func(...args);
      }
    };

  return (
    <Table
      id={`subTable-${component.componentId}`}
      style={{
        width: "calc(100% + 1px)",
        height: "100%",
        zIndex: 99,
      }}
    >
      <Tbody>
        {subTable?.rows?.map((row, rowIndex) => (
          <Tr
            style={{
              height: `${
                (getOriginalSize(subTable.rows!, row.cells?.[0]!).height /
                  (parentCell.height || 1)) *
                  displaySize.height -
                (rowIndex === 0 ? 0.5 : 0)
              }px`,
            }}
            key={rowIndex}
            id={row.idRow}
          >
            {row.cells?.map((cell, cellIndex) => {
              if (cell.colSpan === 0 || cell.rowSpan === 0) {
                return null;
              }

              const index = getCellPositionByCellId({
                cellId: cell.cellId,
                component,
              });

              const flexibleDuctOption: iSelfInspectionProps["option"] = {
                rowIndex: flexibleDuctProps?.option?.rowIndex || 0,
                cellIndex: index.parentIndex.col,
                subRowIndex: rowIndex,
                subCellIndex: cellIndex,
              };
              const pacOption: iPacProps["option"] = {
                rowIndex: pacProps?.option?.rowIndex || 0,
                cellIndex: index.parentIndex.col,
                subRowIndex: rowIndex,
                subCellIndex: cellIndex,
              };
              const sleevePipeOption: iSleevePipeProps["option"] = {
                rowIndex: sleevePipeProps?.option?.rowIndex || 0,
                cellIndex: index.parentIndex.col,
                subRowIndex: rowIndex,
                subCellIndex: cellIndex,
              };
              const moduleChillerOption: iModuleChillerProps["option"] = {
                rowIndex: moduleChillerProps?.option?.rowIndex || 0,
                cellIndex: index.parentIndex.col,
                subRowIndex: rowIndex,
                subCellIndex: cellIndex,
              };

              const taskItemDataOption: iTaskItemDataProps["option"] = {
                rowIndex: taskItemDataProps?.option?.rowIndex || 0,
              };

              const cells = row?.cells?.filter(
                (cell) => (cell?.colSpan || 0) > 0
              );
              const length = cells?.length || 1;
              const lastCell = cells?.[length - 1];
              const isLastColumn = lastCell?.cellId === cell.cellId;
              const numRow = subTable?.rows?.length;

              const tdProps = {
                "data-is-first-child": "true",
                "data-is-last-child": isLastColumn,
                "data-is-last-row": rowIndex + 1 === numRow,
                "data-num-row": numRow,
                "data-is-sub-cell": "true",
              } as any;

              return (
                <SubCellComponentPreview
                  key={`${cell.cellId}-${cellIndex}`}
                  tableId={component.componentId || ""}
                  borderColor={
                    !!listMapCellSelected[cell.cellId!] && !isOnlyView
                      ? DEFAULT_BORDER_COLOR_ACTIVE
                      : component.detail?.style?.border
                      ? component.detail?.style?.borderColor
                      : ""
                  }
                  borderStyle="solid solid hidden solid"
                  trId={row.idRow}
                  cell={cell}
                  parentCell={parentCell}
                  width={
                    cell.width ? cell.width : CellSizeSetting.DEFAULT_WIDTH
                  }
                  height={
                    cell.height ? cell.height : CellSizeSetting.DEFAULT_HEIGHT
                  }
                  cellSize={
                    cellSize || {
                      width: GRID_TEMPLATE_SIZE,
                      height: GRID_TEMPLATE_SIZE,
                    }
                  }
                  onSelectCell={handleFunction(handleSelectCell)}
                  isOnlyView={isOnlyView}
                  rowIndex={rowIndex}
                  isFirstColumn={cellIndex === 0}
                  isLastColumn={isLastColumn}
                  isLastRow={
                    (parentCell.subTable?.rows?.length || 0) ===
                    rowIndex + cell.rowSpan!
                  }
                  isTableSelected={isTableSelected}
                  currentComponent={component}
                  isDuplicateRow={isDuplicateRow}
                  zoomRatio={zoomRatio}
                  displaySize={displaySize}
                  sizePageRatio={sizePageRatio}
                  pageDirectionRatio={pageDirectionRatio}
                  canEditCell={
                    isSelectSingleCell(row, cell) ? canEditCell : false
                  }
                  isSelected={
                    !!listMapCellSelected[cell.cellId!] && !isOnlyView
                  }
                  isParentLastColumn={isParentLastColumn}
                  limit={limit}
                  isBlackboardTemplateImage={isBlackboardTemplateImage}
                  isBlackboardTemplate={isBlackboardTemplate}
                  isTaskSheetTemplateItem={isTaskSheetTemplateItem}
                  components={components}
                  isComponentDragging={isComponentDragging}
                  selectedCells={selectedCells}
                  documentContainerSize={documentContainerSize}
                  currentTemplate={currentTemplate}
                  contentType={contentType}
                  componentSelected={componentSelected}
                  tdProps={tdProps}
                  pacProps={{
                    ...(pacProps || ({} as iPacProps)),
                    option: pacOption,
                  }}
                  sleevePipeProps={{
                    ...(sleevePipeProps || ({} as iSleevePipeProps)),
                    option: sleevePipeOption,
                  }}
                  flexibleDuctProps={{
                    ...(flexibleDuctProps || ({} as iSelfInspectionProps)),
                    option: flexibleDuctOption,
                  }}
                  moduleChillerProps={{
                    ...(moduleChillerProps || ({} as iModuleChillerProps)),
                    option: moduleChillerOption,
                  }}
                  taskItemDataProps={{
                    ...(taskItemDataProps || ({} as iTaskItemDataProps)),
                    option: taskItemDataOption,
                  }}
                  documentTaskDataProps={documentTaskDataProps}
                  previewProps={previewProps}
                  blackboardData={blackboardData}
                  blackboardTemplateProps={blackboardTemplateProps}
                  onChangeBlackboardData={onChangeBlackboardData}
                />
              );
            })}
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};

export default memo(SubTableComponentPreview);
