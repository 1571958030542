import {
  CellProperty,
  DocumentCategoryKey,
  DocumentItemKey,
  DocumentTemplateType,
  TemplateComponentType,
  TypeFieldDynamic,
} from "constants/enum";
import { DocumentCategoryDTO } from "interfaces/dtos/documentCategoryDTO";
import { DocumentItemDTO } from "interfaces/dtos/documentItemDTO";
import {
  CellType,
  DynamicFieldInSection,
  TemplateComponent,
} from "interfaces/models/component";
import groupBy from "lodash/groupBy";
import isEqual from "lodash/isEqual";
import {
  GetContentCategoryLog,
  GetContentItemLog,
} from "models/documentItemLog";
import { useCallback, useMemo } from "react";
import { sortArrayByField } from "utils/array";
import { getHeader } from "utils/document";
import { iUseDynamicFields } from ".";
// eslint-disable-next-line no-restricted-imports

const useDynamicFields = ({
  type,
  template,
  documentCategorySelected,
  documentItemSelected,
  insertDocumentItemLog,
  onSaveDataForDocumentItem,
  onSaveDataForDocumentCategory,
}: iUseDynamicFields) => {
  const getDynamicFields = useCallback(
    (type: TypeFieldDynamic, cells: CellType[] | undefined) => {
      const field =
        type === TypeFieldDynamic.TYPE_CATEGORY
          ? [CellProperty.DYNAMIC_FIELDS_FOR_CATEGORY]
          : type === TypeFieldDynamic.TYPE_ITEM
          ? [CellProperty.DYNAMIC_FIELDS_FOR_ITEM, CellProperty.FAMILY_INSTANCE]
          : "";

      if (!field) {
        return [];
      }

      const data = [] as CellType[];
      cells?.forEach((cell) => {
        if (cell?.isRepeatedTable) return;

        const isSubTable = cell?.subTable?.rows?.length;
        if (
          cell?.cellLinkedData?.options &&
          field.includes(cell?.cellLinkedData?.field as any)
        ) {
          data.push(cell);
        }
        if (isSubTable) {
          cell.subTable?.rows?.forEach((subTable) => {
            data.push(...getDynamicFields(type, subTable.cells));
          });
        }
      });

      return data;
    },
    []
  );

  const groupDynamicFields = useMemo(() => {
    const isModuleChiller =
      template?.documentType ===
      DocumentTemplateType.EQUIPMENT_DATA_SHEET.toString();
    const componentType =
      type === TypeFieldDynamic.TYPE_CATEGORY
        ? TemplateComponentType.Table
        : type === TypeFieldDynamic.TYPE_ITEM
        ? TemplateComponentType.TableHeader
        : "";

    const arrFieldsNotInSection: CellType[] = [];
    const arrFieldsInSection: {
      [key: string]: { [key: string]: DynamicFieldInSection };
    } = {
      horizontal: {},
      vertical: {},
    };

    if (!template || !componentType) {
      return [];
    }

    const components = template.components;

    const handle = (
      isModuleChiller: boolean,
      isLinkedTable: boolean,
      type: TypeFieldDynamic,
      data: CellType[],
      component: TemplateComponent
    ) => {
      const cells = getDynamicFields(type, data);
      const isLinkedTableOfModuleChiller = isModuleChiller && isLinkedTable;

      if (!cells?.length) {
        return;
      }

      const groupCellsBySection = groupBy(cells, (cell: CellType) =>
        isLinkedTableOfModuleChiller
          ? cell.cellLinkedData?.options?.dynamicFieldLabelForCell?.concat(
              cell.position?.idRow || ""
            )
          : cell.cellLinkedData?.options?.dynamicFieldSection?.id
      );

      Object.keys(groupCellsBySection).forEach((key) => {
        const fields = groupCellsBySection[key];
        let section = undefined;
        let sectionName = "";
        let sectionOrder = 0;
        let sectionType = "vertical";

        if (key === undefined) {
          arrFieldsNotInSection.push(...fields);

          return;
        }

        if (!isLinkedTableOfModuleChiller) {
          section = component?.detail?.dynamicFieldsSections?.find(
            (section) => section.value === key
          );
          sectionName = section?.name || "";
          sectionOrder = section?.order || 0;

          if (!section?.value) {
            arrFieldsNotInSection.push(...fields);

            return;
          }
        }

        const fieldNotShowInSection = fields.filter(
          (field) =>
            !field.cellLinkedData?.options ||
            field.cellLinkedData?.options?.dynamicFieldSection?.isShow === false // prevent lost old data
        );
        arrFieldsNotInSection.push(...fieldNotShowInSection);

        const fieldsShowInSection = fields.filter(
          (field) =>
            !fieldNotShowInSection.map((f) => f.cellId).includes(field.cellId)
        );

        if (!fieldsShowInSection?.length) {
          return;
        }

        const mapFieldsShowInSection = Object.assign(
          {},
          ...fieldsShowInSection.map((field) => ({ [field.cellId]: field }))
        );
        const arrFieldsShowInSection = fieldsShowInSection.map((field) => ({
          cellId: field.cellId,
          title: field?.cellLinkedData?.options?.dynamicFieldLabel || "",
          order: isLinkedTableOfModuleChiller
            ? component?.detail?.dynamicFieldsSections?.find(
                (section) =>
                  section.value ===
                  field?.cellLinkedData?.options?.dynamicFieldSection?.id
              )?.order || 0
            : field?.cellLinkedData?.options?.dynamicFieldOrder || 1,
        }));

        const sortFieldsShowInSection: {
          title: string;
          cellId: string;
          order: number;
        }[] =
          !isModuleChiller || isLinkedTableOfModuleChiller
            ? sortArrayByField<{
                cellId: string;
                order: number;
              }>(arrFieldsShowInSection, "order")
            : arrFieldsShowInSection;

        if (isLinkedTableOfModuleChiller) {
          sectionType = "horizontal";
          sectionName = sortFieldsShowInSection![0]?.title || "";
          sectionOrder = 0;
        }

        if (arrFieldsInSection[sectionType][key]) {
          arrFieldsInSection[sectionType][key] = {
            ...arrFieldsInSection[sectionType][key],
            fields: [
              ...arrFieldsInSection[sectionType][key].fields,
              ...sortFieldsShowInSection?.map(
                (field) => mapFieldsShowInSection[field.cellId]
              ),
            ],
          };
        } else {
          arrFieldsInSection[sectionType][key] = {
            isSection: true,
            title: sectionName,
            order: sectionOrder,
            fields: sortFieldsShowInSection?.map(
              (field) => mapFieldsShowInSection[field.cellId]
            ),
            sections: isLinkedTableOfModuleChiller
              ? component?.detail?.dynamicFieldsSections
              : [],
          };
        }
      });
    };

    if (type === TypeFieldDynamic.TYPE_CATEGORY || isModuleChiller) {
      components
        ?.filter((component) => {
          // Module chiller case, get all dynamically with TABLE component ( exclude linked table )
          if (isModuleChiller) {
            return (
              !component.linkedHeaderId &&
              component.type === TemplateComponentType.Table
            );
          }

          // Normal case
          return component.type === componentType;
        })
        ?.forEach((component) => {
          const cells = component?.detail?.rows
            ?.map((row) => row.cells || [])
            ?.flat(1);

          handle(
            isModuleChiller,
            false,
            TypeFieldDynamic.TYPE_CATEGORY,
            cells || [],
            component
          );
        });
    }

    if (type === TypeFieldDynamic.TYPE_ITEM) {
      let cells = [];
      const component = components?.find((c) => {
        // Module chiller case, get component is TABLE and isLinkedTable type
        if (isModuleChiller) {
          return !!c.linkedHeaderId;
        }

        // Normal case, get component is TABLE_HEADER
        return c.type === componentType;
      });

      if (!component?.componentId) {
        return [];
      }

      if (isModuleChiller) {
        // Module chiller case
        const componentTableHeader = getHeader({ components });
        component?.detail?.rows?.forEach((row) => {
          handle(
            isModuleChiller,
            !!component?.linkedHeaderId,
            TypeFieldDynamic.TYPE_ITEM,
            row?.cells || [],
            componentTableHeader
          );
        });
      } else {
        // Normal case
        cells = component?.detail?.rows?.[0]?.cells || [];
        handle(
          isModuleChiller,
          !!component?.linkedHeaderId,
          TypeFieldDynamic.TYPE_ITEM,
          cells,
          component
        );
      }
    }

    const newArrFieldsNotInSection = arrFieldsNotInSection.map((field) => ({
      ...field,
      order: field?.cellLinkedData?.options?.dynamicFieldOrder || 1,
    }));

    let groupFields = [
      ...newArrFieldsNotInSection,
      ...Object.values(arrFieldsInSection["vertical"]),
    ];
    groupFields = sortArrayByField(groupFields, "order");
    const maxOrder = Math.max(...groupFields.map((g) => g.order));
    groupFields = [
      ...groupFields,
      ...Object.values(arrFieldsInSection["horizontal"]).map(
        (dynamicField) => ({
          ...dynamicField,
          order: maxOrder,
        })
      ),
    ];

    return groupFields;
  }, [template, type, getDynamicFields]);

  const dynamicFieldValues = useMemo(() => {
    if (type === TypeFieldDynamic.TYPE_CATEGORY) {
      return (documentCategorySelected?.data || {}) as {
        [key: string]: string;
      };
    } else if (type === TypeFieldDynamic.TYPE_ITEM) {
      return (documentItemSelected?.data || {}) as {
        [key: string]: string;
      };
    }

    return {};
  }, [documentCategorySelected?.data, type, documentItemSelected]);

  const handleDynamicFielsForCategory = useCallback(
    async (
      value: { [key: string]: string },
      name?: string,
      groupName?: string
    ) => {
      if (!documentCategorySelected?.id) return;

      const data =
        structuredClone(documentCategorySelected?.data) || ({} as any);

      if (isEqual(value, data)) return;

      const now = new Date();
      const dynamicFieldKeysUpdatedAt: { [key: string]: Date } =
        structuredClone(
          documentCategorySelected.dynamicFieldKeysUpdatedAt || {}
        );
      Object.keys(value).forEach((key) => {
        if (data[key] !== value[key]) {
          dynamicFieldKeysUpdatedAt[key] = now;
        }

        data[key] = value[key];
      });

      const newDocumentCategory: DocumentCategoryDTO = {
        ...documentCategorySelected,
        data,
        dynamicFieldKeysUpdatedAt,
        [DocumentCategoryKey.UPDATED_AT]: now,
      };

      const contentLogProps: GetContentCategoryLog = {
        field: "dynamicField" as any,
        value: data,
        operation: undefined,
        nameDynamicField: name,
        prefixFieldName: groupName,
        dynamicFieldKeysUpdatedAt,
      };
      onSaveDataForDocumentCategory?.(newDocumentCategory).then(() => {
        insertDocumentItemLog?.(contentLogProps as any);
      });
    },
    [
      documentCategorySelected,
      insertDocumentItemLog,
      onSaveDataForDocumentCategory,
    ]
  );

  const handleChangeDynamicFielsForItem = useCallback(
    async (
      value: { [key: string]: string },
      name?: string,
      groupName?: string
    ) => {
      if (!documentItemSelected?.id) return;

      const data = structuredClone(documentItemSelected?.data) || {};

      if (isEqual(value, data)) return;

      const now = new Date();
      const dynamicFieldKeysUpdatedAt: { [key: string]: Date } =
        structuredClone(documentItemSelected.dynamicFieldKeysUpdatedAt || {});
      Object.keys(value).forEach((key) => {
        if (data[key] !== value[key]) {
          dynamicFieldKeysUpdatedAt[key] = now;
        }

        data[key] = value[key];
      });

      const newDocumentItem = {
        ...documentItemSelected,
        data,
        dynamicFieldKeysUpdatedAt,
        [DocumentItemKey.UPDATED_AT]: now,
      } as DocumentItemDTO;

      onSaveDataForDocumentItem?.(newDocumentItem as any).then(() => {
        const contentLogProps: GetContentItemLog = {
          field: "dynamicField" as any,
          value: data,
          operation: undefined,
          nameDynamicField: name,
          prefixFieldName: groupName,
          dynamicFieldKeysUpdatedAt,
        };
        insertDocumentItemLog?.(contentLogProps as any);
      });
    },
    [documentItemSelected, insertDocumentItemLog, onSaveDataForDocumentItem]
  );

  const handleChangeDynamicFields = useCallback(
    async (
      data: { [key: string]: string },
      name?: string,
      groupName?: string
    ) => {
      if (type === TypeFieldDynamic.TYPE_CATEGORY) {
        return await handleDynamicFielsForCategory(data, name, groupName);
      }

      return await handleChangeDynamicFielsForItem(data, name, groupName);
    },
    [type, handleChangeDynamicFielsForItem, handleDynamicFielsForCategory]
  );

  return {
    groupDynamicFields,
    dynamicFieldValues,
    handleChangeDynamicFields,
  };
};

export default useDynamicFields;
