import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Text,
  useBoolean,
} from "@chakra-ui/react";
import { taskApi } from "apiClient/v2";
import { IconBase } from "components/base";
import DropdownHover from "components/DropdownHover";
import {
  InspectionItemType,
  MapInspectionItemColor,
  MapInspectionItemIconStatus,
  SIZE_ICON_TASK_STATUS,
  SystemModeType,
} from "constants/enum";
import { RIGHT_SIDEBAR_MODAL_CONTAINER_ID } from "constants/styleProps";
import { DocumentItemDTO } from "interfaces/dtos/documentItemDTO";
import { TaskDTO } from "interfaces/dtos/taskDTO";
import { GetContentItemLog, OPERATION_ITEM_LOG } from "models/documentItemLog";
import { handleSelectTask } from "models/task";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { setSystemMode } from "redux/forgeViewerSlice";
import { RootState } from "redux/store";
import { setTaskSelected, setTasks } from "redux/taskSlice";
import { sortArrayByField } from "utils/array";
import { getNameByContentType } from "utils/document";
import { hightLightDocumentItem } from "utils/documentItem";
import { getDbIdByExternalId, selectDbIds } from "utils/forge";
import { getStatusColor } from "utils/forge/extensions/custom-label/utils";
import Tasks from "./Tasks";

interface Props {
  documentItem: DocumentItemDTO;
  isDisabled?: boolean;
  isOnline?: boolean;

  insertDocumentItemLog?: (params: GetContentItemLog) => Promise<void>;
}

const ItemTasks = ({
  documentItem,
  isDisabled,
  isOnline = true,
  insertDocumentItemLog,
}: Props) => {
  const { bimFileId } = useParams();
  const dropdownRef = useRef<any>();

  const dispatch = useDispatch();
  const rightSidebarContainerRef = useRef<any>(
    document.getElementById(RIGHT_SIDEBAR_MODAL_CONTAINER_ID)
  );

  const { familyInstances, levelSelected } = useSelector(
    (state: RootState) => state.forgeViewer
  );
  const { tasks, taskTypes, isLoadingTaskType } = useSelector(
    (state: RootState) => state.task
  );
  const [isOpenCreateTask, setIsOpenCreateTask] = useBoolean();
  const [taskTypeId, setTaskTypeId] = useState<string | undefined>("");
  const canCreateTaskRef = useRef<boolean>(false);
  const [isSaving, setSaving] = useState(false);

  const taskList = useMemo(() => {
    return tasks.filter((c) => c.documentItemId === documentItem.id);
  }, [tasks, documentItem]);

  const listTaskTypeSorted = useMemo(() => {
    return sortArrayByField(taskTypes || [], "createdAt", false);
  }, [taskTypes]);

  useEffect(() => {
    const element = document.getElementById(RIGHT_SIDEBAR_MODAL_CONTAINER_ID);
    if (element) {
      rightSidebarContainerRef.current = element;
    }
  }, []);

  useEffect(() => {
    isOpenCreateTask && setIsOpenCreateTask.off();
    taskTypeId && setTaskTypeId("");
    canCreateTaskRef.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentItem?.id]);

  const handleClickTask = (task: TaskDTO) => {
    dispatch(setTaskSelected());
    dispatch(setSystemMode(SystemModeType.Task));

    setTimeout(() => {
      handleSelectTask(task, dispatch);
    }, 100);
  };

  const handleOpenCreateTask = () => {
    if (isDisabled) return;
    setIsOpenCreateTask.on();
  };

  const handleCloseTask = () => {
    if (isDisabled) return;
    setIsOpenCreateTask.off();
    taskTypeId && setTaskTypeId("");
  };

  const handleCreateTask = async (_taskTypeId = taskTypeId) => {
    if (isDisabled) return;
    if (bimFileId && documentItem.position) {
      if (!_taskTypeId) {
        canCreateTaskRef.current = true;

        return;
      }

      const newTask = {
        taskTypeId: _taskTypeId,
        position: documentItem.position,
        externalId: documentItem.externalId,
        bimFileId,
        level: levelSelected?.guid ? levelSelected.label || "" : "",
        objectTypes: (
          familyInstances[documentItem.externalId || ""]?.objectTypes ?? []
        ).map((family) => family.id),
        creationDateTime: new Date(),
        indexId: 0,
        status: InspectionItemType.Defect,
        dbId: getDbIdByExternalId(documentItem.externalId || ""),
        documentItemId: documentItem.id,
        templateId: documentItem.templateId,
      } as TaskDTO;

      if (documentItem.externalId) {
        selectDbIds([String(documentItem.externalId)], {
          color: MapInspectionItemColor[InspectionItemType.Defect],
        });
      }

      const { data: res } = await taskApi.createTask(newTask);
      const newTasks = [
        ...tasks,
        { ...res, templateId: documentItem.templateId },
      ] as TaskDTO[];
      dispatch(setTasks(newTasks));
      await insertDocumentItemLog?.({ field: "createTask" as any });
      hightLightDocumentItem(documentItem);
      handleCloseTask();
      canCreateTaskRef.current = false;
      setTaskTypeId(undefined);
    }
  };

  useEffect(() => {
    if (!canCreateTaskRef.current) {
      return;
    }

    handleCreateTask();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskTypeId]);

  const onSave = useCallback(async () => {
    const response = await dropdownRef.current?.onAddTaskType();
    handleCreateTask(response);
  }, [handleCreateTask]);

  return (
    <Box w="100%" mb="2rem">
      <Text
        marginBottom="2rem"
        textAlign="left"
        fontWeight={"bold"}
        fontSize="1.6rem"
      >
        指摘
      </Text>
      {!!taskList?.length && (
        <>
          {taskList.map((task) => {
            const taskName = getNameByContentType(task.taskTypeId || "") || "-";

            return (
              <Tasks
                marginLeft={"5rem"}
                width="calc(100% - 5rem)"
                padding="0.5rem"
                marginBottom="0"
                marginTop="0"
                borderRadius={0}
                key={`${task.indexId}`}
                task={task}
                taskName={taskName}
                onClick={() => handleClickTask(task)}
              />
            );
          })}
        </>
      )}
      {isOpenCreateTask ? (
        <Box my="1rem" marginLeft={"5rem"} width="calc(100% - 5rem)">
          <Flex flexDirection="row" alignItems="center">
            <Box ml="0.5rem" mr="0.8rem">
              <IconBase
                width="24px"
                height="24px"
                color={getStatusColor(
                  InspectionItemType.Defect,
                  SystemModeType.Task
                )}
                icon="/img/pin-on-map.svg"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <IconBase
                  marginTop="-4px"
                  {...SIZE_ICON_TASK_STATUS[InspectionItemType.Defect]}
                  color="white"
                  icon={MapInspectionItemIconStatus[InspectionItemType.Defect]}
                />
              </IconBase>
            </Box>
            <DropdownHover
              isOnline={isOnline}
              options={listTaskTypeSorted}
              onChange={(value) => {
                setTaskTypeId(value as string);
              }}
              value={taskTypeId}
              parentRef={rightSidebarContainerRef}
              inputProps={{
                placeholder: "指摘を入力してください",
                backgroundColor: "white",
                isDisabled,
              }}
              isLoading={isLoadingTaskType}
              ref={dropdownRef}
              onLoading={setSaving}
              isAutoSave={false}
            />
          </Flex>
          {!isDisabled && (
            <ButtonGroup
              display="flex"
              justifyContent="flex-end"
              mt="1rem"
              variant="outline"
              spacing="6"
            >
              <Button
                colorScheme="blue"
                color="#3CA4FF"
                padding="10px 16px 10px 16px"
                fontSize="1.4rem"
                borderColor="#A3A3A3"
                h="3.6rem"
                onClick={handleCloseTask}
              >
                キャンセル
              </Button>
              <Button
                fontSize="1.4rem"
                color="#fff"
                h="3.6rem"
                bgColor="#009BE0"
                border="none"
                padding="10px 16px 10px 16px"
                onClick={onSave}
                _hover={{
                  opacity: 0.9,
                }}
                isLoading={isSaving}
              >
                作成
              </Button>
            </ButtonGroup>
          )}
        </Box>
      ) : (
        <Box paddingLeft="calc(5.2rem + 4rem)">
          <Flex
            gap="0.4rem"
            alignItems="center"
            justifyContent="center"
            mt="1rem"
            cursor="pointer"
            width="fit-content"
            onClick={() => {
              if (isDisabled) return;
              handleOpenCreateTask();
            }}
            opacity={isDisabled ? 0.4 : 1}
          >
            <IconBase
              icon="/img/icon-plus-outline.svg"
              width="1.6rem"
              height="1.6rem"
              color="#737373"
            />
            <Text
              fontWeight={500}
              lineHeight="1.6rem"
              color="#737373"
              fontSize="1.6rem"
            >
              指摘を作成
            </Text>
          </Flex>
        </Box>
      )}
    </Box>
  );
};

export default memo(ItemTasks);
