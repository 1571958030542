import { CACHED_PROJECT_INFO_KEY } from "constants/cache";
import { iCachedProject } from "hooks/useCheckProjectCached";
import { wait } from "./common";
import { getLocalStorage } from "./storage";

export async function retryFetch(
  url: string,
  options?: RequestInit,
  retries: number = 3,
  projectBimfileId?: string
): Promise<Response | undefined> {
  const cachedProjects: {
    [key: string]: iCachedProject;
  } = getLocalStorage(CACHED_PROJECT_INFO_KEY, {});
  const cachedProject = cachedProjects?.[projectBimfileId || ""];

  if (projectBimfileId && cachedProject?.isPause) {
    return;
  }

  try {
    const response = await fetch(url, options);

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    return response;
  } catch (error) {
    if (retries > 0) {
      console.warn(`Failed to fetch ${url}. Retrying in 1 second...`);
      await wait(1000);

      return retryFetch(url, options, retries - 1);
    }
    throw error;
  }
}
