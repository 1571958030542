import { OPERATION } from "constants/task";
import { useRoles } from "hooks/usePermission";
import { DocumentTemplateDTO } from "interfaces/dtos/documentTemplateDTO";
import { DocumentTemplate } from "interfaces/models/documentTemplate";
import { isEqual } from "lodash";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { clearState as clearDocumentState } from "redux/documentSlice";
import { resetToInitState } from "redux/forgeViewerSlice";
import { RootState } from "redux/store";
import { routePath } from "routes/path";

const useDocumentTemplate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { projectBimFileId, bimFileId, version } = useParams();
  const { isTakasagoGroup } = useRoles();

  const { documentTemplateSelected } = useSelector(
    (state: RootState) => state.document
  );

  const documentTemplateIdRef = useRef<string | undefined>(undefined);

  const [documentTemplateModalInfo, setDocumentTemplateModalInfo] =
    useState<DocumentTemplateDTO>({} as DocumentTemplate);

  useEffect(() => {
    if (!documentTemplateSelected?.id) {
      return;
    }

    setDocumentTemplateModalInfo((documentTemplate: DocumentTemplateDTO) => {
      if (
        documentTemplate.id !== documentTemplateSelected.id ||
        documentTemplate.templateName !== documentTemplateSelected.templateName
      ) {
        if (documentTemplateIdRef.current) {
          documentTemplateIdRef.current = undefined;
        }

        return documentTemplateSelected;
      }
      if (
        !isEqual(
          documentTemplate.documentItems,
          documentTemplateSelected.documentItems
        )
      ) {
        documentTemplate.documentItems =
          documentTemplateSelected?.documentItems;
      }
      if (
        !isEqual(
          documentTemplate.documentCategories,
          documentTemplateSelected.documentCategories
        )
      ) {
        documentTemplate.documentCategories =
          documentTemplateSelected?.documentCategories;
      }

      return { ...documentTemplate };
    });
  }, [documentTemplateSelected]);

  const onGotoDocumentTemplateDetailPage = () => {
    if (!bimFileId || !version || !projectBimFileId) {
      return;
    }

    dispatch(clearDocumentState());
    dispatch(resetToInitState());
    let url = generatePath(routePath.DocumentTemplateEdit, {
      templateId: documentTemplateModalInfo.id,
    });

    const query = [
      `projectBimFileId=${projectBimFileId}`,
      `bimFileId=${bimFileId}`,
      `version=${version}`,
      `operation=${OPERATION.backToForgeViewer}`,
    ].join("&");
    url = `${url}?${query}`;
    navigate(url);
  };

  return {
    isTakasagoGroup,
    documentTemplateModalInfo,
    documentTemplateSelected,
    onGotoDocumentTemplateDetailPage,
  };
};

export default useDocumentTemplate;
