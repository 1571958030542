import { Flex, Text } from "@chakra-ui/react";
import { IconBase } from "components/base";

interface Props {
  isCreateDocumentItem: boolean;
  handleClickAddPin: (e: React.MouseEvent<HTMLDivElement>) => void;
}

export const AddPin = ({ isCreateDocumentItem, handleClickAddPin }: Props) => {
  return (
    <Flex
      alignItems="center"
      p="1.4rem 0 1.4rem 4rem"
      onClick={handleClickAddPin}
      borderTop="1px solid #E5E5E5"
    >
      <IconBase
        color={isCreateDocumentItem ? "#009BE0" : "#737373"}
        icon="/img/icon-plus-2.svg"
        width="2.4rem"
        height="2.4rem"
      />
      <Text
        color={isCreateDocumentItem ? "#009BE0" : "#737373"}
        size="1.6rem"
        fontWeight={500}
      >
        図面上にピンを追加
      </Text>
    </Flex>
  );
};
