import { Box, BoxProps, Text } from "@chakra-ui/react";
import { documentCategoryApi } from "apiClient/v2";
import MenuDropdown from "components/MenuDropdown";
import { SvgIcon } from "components/SvgIcon";
import { MessageType } from "constants/websocket";
import { useAuthorization, useRoles } from "hooks/usePermission";
import { DocumentCategory } from "interfaces/models/documentCategory";
import { WSMessage } from "interfaces/models/websocket";
import { useCallback, useMemo } from "react";
import { updateDocumentCategory } from "redux/documentSlice";
import { useAppDispatch } from "redux/store";

interface Props extends BoxProps {
  isDisabled?: boolean;
  documentCategory: DocumentCategory;
  currentUserId: string | undefined;
  isBlockDocumentCategory: boolean;
  onDelete(): void;
  onSelect(): void;
  onOpenOfflineModeTutorialModal: () => void;
  sendWebSocketMessage: (message: WSMessage) => void;
}

const DocumentCategoryMenu: React.FC<Props> = ({
  currentUserId,
  documentCategory,
  isDisabled,
  isBlockDocumentCategory,
  onDelete,
  onSelect,
  onOpenOfflineModeTutorialModal,
  sendWebSocketMessage,
  ...rest
}) => {
  const roles = useAuthorization();
  const { isAdmin } = useRoles();
  const dispatch = useAppDispatch();

  const toggleBlocked = useCallback(async () => {
    const isBlocked = !documentCategory.blockedBy;

    const response = await documentCategoryApi.updateCategory({
      id: documentCategory.id!,
      isBlocked,
      blockedBy: isBlocked ? currentUserId : "",
    });
    const category = structuredClone(documentCategory);
    category.blockedBy = response.data.blockedBy || null;
    category.isBlocked = !!category.blockedBy;
    dispatch(updateDocumentCategory(category));
    sendWebSocketMessage({
      type: MessageType.CHANGE_DOC_CATEGORY,
      docCategoryId: category.id,
      data: { blockedBy: category.blockedBy },
    });
  }, [documentCategory, dispatch, currentUserId, sendWebSocketMessage]);

  const handleOpenOfflineModeTutorial = useCallback(
    (event: any) => {
      event.preventDefault();
      event.stopPropagation();
      onOpenOfflineModeTutorialModal();
    },
    [onOpenOfflineModeTutorialModal]
  );

  const handleSelect = useCallback(
    (event: any) => {
      event.preventDefault();
      event.stopPropagation();
      onSelect();
    },
    [onSelect]
  );

  const handleDelete = useCallback(
    (event: any) => {
      event.preventDefault();
      event.stopPropagation();
      onDelete();
    },
    [onDelete]
  );

  const options = useMemo(() => {
    const options: any[] = [];

    options.push({
      title: (
        <Text
          color="#EF4444"
          fontSize="1.2rem"
          fontWeight="600"
          letterSpacing="-1px"
          lineHeight="1.5rem"
        >
          {documentCategory.blockedBy
            ? "「オフライン作業中」ラベルを非表示"
            : "「オフライン作業中」を 伝えるラベルを表示"}
        </Text>
      ) as any,
      icon: (
        <Box w="2.4rem">
          <SvgIcon
            w="2.4rem"
            h="2.4rem"
            src={
              documentCategory.blockedBy
                ? "/img/icon-offline-off.svg"
                : "/img/icon-offline.svg"
            }
          />
        </Box>
      ),
      isDisabled,
      onClick: toggleBlocked,
    });

    options.push({
      "data-id": "support",
      title: (
        <Text
          fontSize="1.2rem"
          lineHeight="1.5rem"
          fontWeight="400"
          letterSpacing="-1px"
          color="#737373"
        >
          オフラインモードの 使い方・注意点を確認
        </Text>
      ) as any,
      icon: (
        <Box w="2.4rem">
          <SvgIcon w="2.4rem" h="2.4rem" src="/img/icon-support.svg" />
        </Box>
      ),
      onClick: handleOpenOfflineModeTutorial,
    });

    if (roles.canEditDocumentCategory) {
      options.push({
        title: (
          <Text fontSize="1.2rem" fontWeight="400" color="#737373">
            設定
          </Text>
        ) as any,
        icon: (
          <Box w="2.4rem">
            <SvgIcon w="2.2rem" h="2.2rem" src="/img/icon-cogs.svg" />
          </Box>
        ),
        isDisabled: isDisabled || isBlockDocumentCategory,
        onClick: handleSelect,
      });
    }

    if (roles.canDeleteDocumentCategory) {
      options.push({
        title: (
          <Text color="#EF4444" fontSize="1.2rem" fontWeight="400">
            削除
          </Text>
        ) as any,
        icon: (
          <Box w="2.4rem">
            <SvgIcon w="2rem" h="2rem" src="/img/icon-trash-red.svg" />
          </Box>
        ),
        isDisabled: isDisabled || isBlockDocumentCategory,
        onClick: handleDelete,
      });
    }

    return options;
  }, [
    roles,
    isBlockDocumentCategory,
    documentCategory.blockedBy,
    isDisabled,
    handleOpenOfflineModeTutorial,
    handleSelect,
    handleDelete,
    toggleBlocked,
  ]);

  return (
    <MenuDropdown
      {...rest}
      options={options}
      sx={{
        "button[data-id='support']": {
          borderBottom: "1px solid var(--primary-border-color)",
        },
        "button:last-child": {
          borderBottomColor: "transparent",
        },
        ...{
          ...(isBlockDocumentCategory && isAdmin
            ? { pointerEvents: "all", height: "fit-content" }
            : {}),
        },
      }}
      menuButtonProps={
        isBlockDocumentCategory && isAdmin
          ? {
              backgroundColor: "white !important",
              pointerEvents: "all",
            }
          : {}
      }
    />
  );
};

export default DocumentCategoryMenu;
