import { Box, BoxProps } from "@chakra-ui/react";
import ImageComponentPreview from "components/editor-builder/component-preview/ImageComponentPreview";
import TableComponentPreview, {
  iTableComponentPreview,
} from "components/editor-builder/component-preview/TableComponentPreview";
import TextboxComponentPreview, {
  iTextboxComponentPreview,
} from "components/editor-builder/component-preview/TextboxComponentPreview";
import { DEFAULT_PAGE_RATIO, GRID_TEMPLATE_SIZE } from "constants/document";
import {
  Axis,
  ContentType,
  DocumentTemplateType,
  noDataIndex,
  PaperDirectionType,
  PaperSize,
  PaperType,
  SystemMode,
  SystemModeType,
  TemplateComponentType,
} from "constants/enum";
import PreviewDocumentCategoryContext from "contexts/PreviewDocumentCategoryContext";
import { DocumentCategoryDTO } from "interfaces/dtos/documentCategoryDTO";
import { DocumentDTO } from "interfaces/dtos/documentDTO";
import { DocumentItemDTO } from "interfaces/dtos/documentItemDTO";
import { FamilyInstanceDTO } from "interfaces/dtos/familyInstance";
import { NeptuneArea } from "interfaces/models/area";
import { BlackBoardInfo } from "interfaces/models/blackboard";
import { CellType, TemplateComponent } from "interfaces/models/component";
import {
  DocumentCategory,
  DocumentSubCategory,
} from "interfaces/models/documentCategory";
import { DocumentItem } from "interfaces/models/documentItem";
import {
  DocumentKeyNote,
  KeynoteImageData,
} from "interfaces/models/documentKeynote";
import {
  DocumentTemplate,
  iBlackboardTemplateProps,
} from "interfaces/models/documentTemplate";
import { PartnerCompany } from "interfaces/models/partnerCompany";
import { User } from "interfaces/models/user";
import { flattenDeep } from "lodash";
import { memo, useCallback, useContext, useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { centimetersToPixels } from "utils/measure";
import useKeynote from "./hooks/useKeynote";
import Image from "./PreviewComponent/Image";

interface PageSize {
  width: number;
  height: number;
}
interface Props extends BoxProps {
  isCreatingKeynote?: boolean;
  template?: DocumentTemplate;
  components?: TemplateComponent[];
  listUserById?: {
    [key: string]: User | null;
  };
  companiesById: Record<string, PartnerCompany>;
  documentItems: DocumentItem[] | BlackBoardInfo[];
  documentItem?: DocumentItem;
  documentCategories: DocumentCategory[];
  currentDocSubCategories?: DocumentSubCategory[];
  documentCategorySelected?: DocumentCategoryDTO;
  currentDocument: DocumentDTO | undefined | null;
  pageZoom?: number;
  documentKeynoteDataProp?: DocumentKeyNote;
  keynoteImageDataProp?: KeynoteImageData;
  neptuneAreas?: NeptuneArea[];
  blackboardTemplateProps?: iBlackboardTemplateProps;
  familyInstances: { [key: string]: FamilyInstanceDTO };
  projectName: string;
}

interface PageInfo {
  pageId: string;
  components: TemplateComponent[];
  numberPerPage: number;
  limitItemTableLinked: number;
  offsetItemLinked: number;
  pageSize: PageSize;
  isRepeat: boolean | undefined;
  isVertical?: boolean;
  sizePageRatio?: number;
  pageDirectionRatio?: number;
}

const PreviewContainer = (props: Props) => {
  const {
    projectName,
    isCreatingKeynote,
    components,
    listUserById,
    companiesById,
    documentItems,
    documentItem,
    template,
    currentDocSubCategories,
    documentCategories,
    documentCategorySelected,
    currentDocument,
    neptuneAreas,
    pageZoom,
    documentKeynoteDataProp,
    keynoteImageDataProp,
    style,
    blackboardTemplateProps,
    familyInstances,
    ...rest
  } = props;

  const { systemMode } = useSelector((state: RootState) => state.forgeViewer);
  const { documentKeynoteData: documentKeynoteDataContext } = useContext(
    PreviewDocumentCategoryContext
  );

  const documentKeynoteData =
    documentKeynoteDataProp || documentKeynoteDataContext;

  const { hasKeyNote, pageKeyNote, keynoteAxis } = useKeynote({
    components,
    template,
    documentKeynoteData,
  });

  const mapKeyNoteIndex = useMemo(() => {
    const map: { [key: string]: number } = {};

    documentItems?.forEach((item: any) => {
      Object.assign(map, {
        [item?.id]: item?.keyNoteIndex,
      });
    });

    return map;
  }, [documentItems]);

  const pages = useMemo(() => {
    if (!template) {
      return [];
    }
    const mapPage: {
      [key: number]: PageInfo;
    } = {};
    // init page

    template.pages.forEach((page, index) => {
      const size = PaperSize?.[page?.pageSize as PaperType];
      const isVertical = page?.pageDirection === PaperDirectionType.VERTICAL;
      const pageWidth = centimetersToPixels(size.width);
      const pageHeight = centimetersToPixels(size.height);
      const pageSize = {
        width: isVertical ? pageWidth : pageHeight,
        height: isVertical ? pageHeight : pageWidth,
      };

      mapPage[index] = {
        pageId: page.pageId,
        pageSize,
        components: [],
        numberPerPage: 0,
        limitItemTableLinked: 0,
        offsetItemLinked: 0,
        isRepeat: false,
        sizePageRatio: page.sizePageRatio,
        pageDirectionRatio: page.pageDirectionRatio,
        isVertical,
      };
    });

    const numOfRepeatTable =
      components?.find(
        (component) => component.type === TemplateComponentType.TableHeader
      )?.detail?.numOfRepeatTable || 1;

    const linkedTable = components?.find((item) => !!item.linkedHeaderId);
    const conflictComponents = (components || [])?.filter(
      (component) =>
        !!component?.linkedHeaderId || (component?.detail?.numOfCol || 0) >= 1
    );

    let perPage = Number(linkedTable?.detail?.rows?.length || 1);
    if (conflictComponents?.length > 1) {
      perPage = Math.min(
        ...conflictComponents.map((component) =>
          component.linkedHeaderId
            ? (component?.detail?.rows || [])?.length
            : (component?.detail?.numOfCol || 1) *
              (component?.detail?.numOfRepeat || 1)
        )
      );
    }

    let totalItem = documentItems?.length || 0;

    if (linkedTable && linkedTable?.detail?.isRepeatTable) {
      totalItem =
        Math.ceil(documentItems?.length / (perPage * (numOfRepeatTable || 1))) *
        perPage;
    }

    let numberItemPerPage: number = 0;
    const isTemplatePaginatePage =
      template?.documentType === DocumentTemplateType.COMMISSIONING_TABLE ||
      template?.documentType === DocumentTemplateType.PHOTO_LEDGER;

    // get number number item per page and map page
    components?.forEach((component) => {
      const page = Number(component.page || 0);
      const isComponentValid =
        !!component.linkedHeaderId ||
        [
          TemplateComponentType.FilterPhoto,
          TemplateComponentType.LinkedImage,
        ].includes(component.type);

      if (isComponentValid) {
        numberItemPerPage =
          component.detail?.numOfRepeat ||
          1 * (component?.detail?.numOfCol || 1);
        if (component.type === TemplateComponentType.LinkedImage) {
          numberItemPerPage =
            (component?.detail?.numOfRepeat || 1) *
            (component?.detail?.numOfCol || 1);
        }

        if (conflictComponents?.length > 1) {
          numberItemPerPage = Math.min(
            ...conflictComponents.map((component) =>
              component.linkedHeaderId
                ? component.detail?.numOfRepeat || 1
                : (component?.detail?.numOfCol || 1) *
                  (component?.detail?.numOfRepeat || 1)
            )
          );
        }

        mapPage[page].limitItemTableLinked = numberItemPerPage;
        mapPage[page].offsetItemLinked = 0;
        mapPage[page].numberPerPage = numberItemPerPage;
        mapPage[page].isRepeat = !!component.detail?.isRepeat;
      }

      mapPage[page].components.push(component);
    });

    // sort page add page paginate
    const listPageInfo: PageInfo[] = [];

    Object.keys(mapPage)
      .sort()
      .forEach((pageIndex: any) => {
        listPageInfo.push(mapPage[pageIndex]);

        if (
          numberItemPerPage > 0 &&
          numberItemPerPage < totalItem &&
          isTemplatePaginatePage &&
          mapPage[pageIndex].isRepeat
        ) {
          const numberPageCopy = Math.ceil(totalItem / numberItemPerPage);

          if (numberPageCopy <= 1) {
            return;
          }

          // add limit and offset for copy page
          for (let i = 2; i <= numberPageCopy; i++) {
            const newPage = { ...mapPage[pageIndex] };
            newPage.limitItemTableLinked = numberItemPerPage;
            newPage.offsetItemLinked = (i - 1) * numberItemPerPage;

            listPageInfo.push(newPage);
          }
        }
      });

    const startPageIndex =
      (flattenDeep(listPageInfo?.map((item) => item.components))?.find(
        (component) => component.type === TemplateComponentType.Table
      )?.page as number) || 0;

    const newPageInfo = listPageInfo?.map((page, pageIndex) => {
      return {
        ...page,
        components: page.components.map((component) => {
          return {
            ...component,
            detail: {
              ...component.detail,
              rows: component.detail?.rows?.map((row, rowIndex) => {
                return {
                  ...row,
                  cells: row?.cells?.map((cell) => {
                    const itemsPerPage = Number(
                      component?.detail?.rows?.length
                    );

                    const newIndex = component?.detail?.isRepeatTable
                      ? page.limitItemTableLinked *
                          (Number(cell?.repeatedTableIndex) || 0) +
                        rowIndex +
                        (pageIndex - startPageIndex) *
                          itemsPerPage *
                          Number(component?.detail?.numOfRepeatTable) +
                        1
                      : rowIndex + 1;

                    const newSubTable = cell.subTable?.rows?.length
                      ? {
                          ...cell.subTable,
                          rows: cell.subTable?.rows?.map((subRow) => ({
                            ...subRow,
                            cells: subRow.cells?.map((subCell) => ({
                              ...subCell,
                              rowIndex: newIndex,
                            })),
                          })),
                        }
                      : cell.subTable;

                    if (
                      newIndex >
                        documentItems?.length -
                          (pageIndex - startPageIndex) * itemsPerPage &&
                      !!component.linkedHeaderId
                    ) {
                      return {
                        ...cell,
                        width: cell.width,
                        height: cell.height,
                        subTable: newSubTable,
                      } as CellType;
                    }

                    if (
                      cell.cellLinkedData?.field === noDataIndex &&
                      !!component.linkedHeaderId &&
                      component?.detail?.isRepeatTable
                    ) {
                      return {
                        ...cell,
                        value: `${newIndex || ""}`,
                        rowIndex: newIndex,
                        subTable: newSubTable,
                      };
                    }

                    return {
                      ...cell,
                      rowIndex: newIndex,
                      subTable: newSubTable,
                    };
                  }),
                };
              }),
            },
          };
        }),
      };
    });

    // remove table component when second page has type linked image
    let pageHasLinkedIamgeIndex = -1;
    newPageInfo.forEach((page, pageIndex) => {
      page.components.every((component) => {
        if (component.type === TemplateComponentType.LinkedImage) {
          pageHasLinkedIamgeIndex = pageIndex;

          return false;
        }

        return true;
      });
    });
    if (pageHasLinkedIamgeIndex !== -1) {
      const processNewPageInfo = newPageInfo.map((page, index) => {
        let clonePage = page;
        if (index > pageHasLinkedIamgeIndex) {
          const linkedImageComponent = page.components.filter(
            (e) => e.type === TemplateComponentType.LinkedImage
          );

          if (!!linkedImageComponent?.length) {
            const newComponent = {
              ...linkedImageComponent[0],
              position: {
                x: 0,
                y: 0,
              },
            };

            clonePage = {
              ...clonePage,
              components: [newComponent],
            };
          }
        }

        return clonePage;
      });

      return processNewPageInfo;
    }

    return newPageInfo;
  }, [components, template, documentItems]);

  const headerComponent = useMemo(() => {
    return (
      (components || []).filter(
        (e) => e.type === TemplateComponentType.TableHeader
      )?.[0]?.detail?.rows?.[0]?.cells || []
    );
  }, [components]);

  const getScale = useCallback((page?: PageInfo) => {
    const sizePageRatio = page?.sizePageRatio || 1;
    const pageDirectionRatio = page?.pageDirectionRatio || 1;

    const scale = {
      x: 1 / sizePageRatio / pageDirectionRatio,
      y: (1 / sizePageRatio) * pageDirectionRatio,
      displayX: sizePageRatio * pageDirectionRatio,
      displayY: sizePageRatio / pageDirectionRatio,
    };

    return scale;
  }, []);

  const renderPreviewComponent = (
    type: string,
    component: TemplateComponent,
    limitItemTableLinked: number,
    offsetItemLinked: number,
    linkedTableIndex?: number,
    pageIndex?: number,
    page?: PageInfo,
    components?: TemplateComponent[],
    height?: number
  ) => {
    const scale = getScale(page);

    let tableComponentProps = {
      component,
      cellSize: {
        width: GRID_TEMPLATE_SIZE,
        height: GRID_TEMPLATE_SIZE,
      },
      zoomRatio: 1,
      isOnlyView: true,
      displaySize: {
        width: component?.size.width * scale.displayX,
        height: component?.size.height * scale.displayY,
      },
      sizePageRatio: page?.sizePageRatio || DEFAULT_PAGE_RATIO,
      pageDirectionRatio: page?.pageDirectionRatio || DEFAULT_PAGE_RATIO,
      isResizable: false,
      isResizing: false,
      isBlackboardTemplateImage: false,
      hasKeyNote,
      template,
    } as iTableComponentPreview;

    const textBoxComponentProps: iTextboxComponentPreview = {
      component,
      template: template!,
      zoomRatio: 1,
      isOnlyView: true,
      isResizable: false,
    };

    switch (type) {
      case TemplateComponentType.LinkedImage: {
        const documentItemsForLinkedImage = structuredClone(
          documentItems
        ).splice(offsetItemLinked, limitItemTableLinked);

        tableComponentProps = {
          ...tableComponentProps,
          contentType: ContentType.LINKED_IMAGE,
          linkedImageProps: {
            component,
            documentCategorySelected: documentCategorySelected!,
            displayItems: documentItemsForLinkedImage,
            offsetItemLinked,
            limitItems: limitItemTableLinked,
            familyInstances,
            blackboardTemplateProps: blackboardTemplateProps || ({} as any),
          },
        };

        return <TableComponentPreview {...tableComponentProps} />;
      }

      case TemplateComponentType.FilterPhoto: {
        tableComponentProps = {
          ...tableComponentProps,
          contentType: ContentType.PHOTO_LEDGER,
          sleevePipeProps: {
            headerComponent,
            listUserById,
            component,
            offsetItemLinked,
            limitItemTableLinked,
            documentItems: (documentItems || []) as DocumentItem[],
            mapKeyNoteIndex,
            hasKeyNote,
            pageIndex,
            blackboardTemplateProps: blackboardTemplateProps || ({} as any),
            familyInstances,
          },
        };

        return <TableComponentPreview {...tableComponentProps} />;
      }

      case TemplateComponentType.Table:
      case TemplateComponentType.TableHeader:
        if (!!component.linkedHeaderId) {
          switch (template?.documentType) {
            case DocumentTemplateType.COMMISSIONING_TABLE:
              tableComponentProps = {
                ...tableComponentProps,
                contentType: ContentType.PAC,
                pacProps: {
                  headerComponent,
                  listUserById,
                  component,
                  documentCategorySelected: documentCategorySelected!,
                  displayItems: documentItems || [],
                  offsetItemLinked,
                  limitItemTableLinked,
                },
              };
              break;

            case DocumentTemplateType.PHOTO_LEDGER:
              tableComponentProps = {
                ...tableComponentProps,
                contentType: ContentType.PHOTO_LEDGER,
                sleevePipeProps: {
                  headerComponent,
                  listUserById,
                  component,
                  offsetItemLinked,
                  limitItemTableLinked,
                  documentItems: (documentItems || []) as DocumentItem[],
                  mapKeyNoteIndex,
                  hasKeyNote,
                  pageIndex,
                  blackboardTemplateProps:
                    blackboardTemplateProps || ({} as any),
                  familyInstances,
                },
              };

              break;

            case DocumentTemplateType.SELF_INSPECTION:
              tableComponentProps = {
                ...tableComponentProps,
                contentType: ContentType.SELF_INSPECTION,
                flexibleDuctProps: {
                  listUserById,
                  components: components || [],
                  component,
                  headerComponent,
                  documentItems: (documentItems || []) as DocumentItem[],
                  linkedTableIndex,
                  offsetItemLinked,
                },
              };
              break;

            case DocumentTemplateType.EQUIPMENT_DATA_SHEET:
              tableComponentProps = {
                ...tableComponentProps,
                contentType: ContentType.EQUIPMENT_DATA_SHEET,
                moduleChillerProps: {
                  listUserById,
                  component,
                  headerComponent,
                  documentItem: documentItem!,
                  documentCategorySelected: documentCategorySelected!,
                  offsetItemLinked,
                },
              };
              break;

            default:
              break;
          }

          return <TableComponentPreview {...tableComponentProps} />;
        }

        return (
          <TableComponentPreview
            {...tableComponentProps}
            contentType={ContentType.PREVIEW}
            previewProps={{
              projectName,
              familyInstances,
              documentTemplateName: template?.templateName || "",
              neptuneAreas: neptuneAreas || [],
              documentCategorySelected:
                documentCategorySelected || ({} as DocumentCategoryDTO),
              documentItemSelected: documentItem || ({} as DocumentItemDTO),
              listUserById,
              component,
              companiesById,
            }}
          />
        );

      case TemplateComponentType.Image: {
        return (
          <Image
            component={component}
            imageUrl={undefined}
            keynoteImageDataProp={keynoteImageDataProp}
            isLoadingKeyNote={!!isCreatingKeynote}
            maxHeight={height}
          />
        );
      }

      case TemplateComponentType.ImageUploaded: {
        return (
          <ImageComponentPreview
            component={component}
            zoomRatio={1}
            isOnlyView={true}
            contentType={ContentType.PREVIEW}
            isResizable={false}
          />
        );
      }

      case TemplateComponentType.Text: {
        return <TextboxComponentPreview {...textBoxComponentProps} />;
      }

      default: {
        return null;
      }
    }
  };

  const getSizeOfComponent = (
    component: TemplateComponent,
    numberItemPerPage: number = 0,
    limit: number = 0
  ) => {
    const width = Number(component?.realSize?.width);
    let height = Number(component?.realSize?.height);

    const isCommon =
      component.type === TemplateComponentType.Table &&
      !!component.linkedHeaderId &&
      (template?.documentType === DocumentTemplateType.COMMISSIONING_TABLE ||
        template?.documentType === DocumentTemplateType.PHOTO_LEDGER);
    const isFilterPhoto = component.type === TemplateComponentType.FilterPhoto;
    const isLinkedImage = component.type === TemplateComponentType.LinkedImage;

    if (isCommon || isFilterPhoto || isLinkedImage) {
      const rowHeight = height / numberItemPerPage;
      height = rowHeight * limit;
    }

    return { width, height };
  };

  const mapLinkedTableIndex: { [key: string]: number } = useMemo(() => {
    const mapObject = {};

    components
      ?.filter((component) => !!component.linkedHeaderId)
      ?.forEach((component, index) => {
        Object.assign(mapObject, {
          [`${component.componentId}`]: index,
        });
      });

    return mapObject;
  }, [components]);

  const scale = Number(pageZoom) / 100;

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: `${scale * 0.5}rem`,
        padding: `${scale * 5}rem 0`,
        ...style,
      }}
      {...rest}
    >
      {pages.map((page, pageIndex) => {
        const pageHasKeyNote = page.components?.some(
          (component) => component.detail.checkedImage
        );

        const pageHasFullsizeComponent = page.components?.some(
          (component) =>
            component.type === TemplateComponentType.Image &&
            component.detail.isFullsize
        );

        let width = Number(page?.pageSize?.width) * scale;
        let height = Number(page?.pageSize?.height) * scale;
        let isReverseAxis = false;
        let stylePageKeynote: BoxProps["style"] = {};
        if (hasKeyNote && pageKeyNote?.pageId === page.pageId) {
          if (!pageHasFullsizeComponent && !pageHasKeyNote) {
            stylePageKeynote = { padding: 0 };
          }

          const axisDefault =
            pageKeyNote.pageDirection === PaperDirectionType.VERTICAL
              ? Axis.VERTICAL
              : Axis.HORIZONTAL;

          if (axisDefault !== keynoteAxis) {
            isReverseAxis = true;
          }
        }

        //Rotate page's direction
        if (isReverseAxis && systemMode === SystemMode[SystemModeType.Task]) {
          width = height;
          height = Number(page?.pageSize?.width) * scale;
        }

        const currentPage = template?.pages?.find(
          (pageOfTemplate) => pageOfTemplate?.pageId === page?.pageId
        );
        const paddingX = currentPage?.pageSize === PaperType.A4 ? 36 : 51;
        const paddingY = currentPage?.pageSize === PaperType.A4 ? 20 : 26;

        return (
          <Box
            className="disalbe-br mceNonEditable"
            key={pageIndex}
            style={{
              background: "black",
              padding: `${scale * 7}px ${scale * 3}rem`,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              id={`page-${pageIndex}`}
              className={`disalbe-br mceNonEditable document-content-container page-index-${pageIndex}`}
              data-page-size={currentPage?.pageSize || PaperType.A4}
              data-page-direction={
                currentPage?.pageDirection || PaperDirectionType.VERTICAL
              }
              style={{
                background: "white",
                padding: page.isVertical
                  ? `${scale * paddingY}px ${paddingX * scale}px`
                  : `${paddingX * scale}px ${paddingY * scale}px`,
                width,
                height,
                margin: "0 auto",
                ...stylePageKeynote,
              }}
            >
              <Box
                style={{
                  position: "relative",
                  transform: `scale(${Number(pageZoom) / 100})`,
                  transformOrigin: "0 0",
                }}
              >
                {page?.components?.map((component) => {
                  let { width, height } = getSizeOfComponent(
                    component,
                    page.numberPerPage,
                    page.limitItemTableLinked
                  );

                  if (height <= 1) {
                    return undefined;
                  }

                  const hasComponentAbove = page?.components
                    ?.filter((e) => e.componentId !== component.componentId)
                    ?.some((e) => {
                      return (
                        Math.ceil(Number(e?.realPosition?.y.toFixed(1))) ===
                        Math.ceil(
                          Number(component?.realPosition?.y.toFixed(1)) +
                            Number(height.toFixed(1))
                        )
                      );
                    });

                  let top = component.detail.isFullsize
                    ? component?.realPosition?.y + 1
                    : component?.realPosition?.y;
                  let left = component?.realPosition?.x;

                  if (
                    isReverseAxis &&
                    systemMode === SystemMode[SystemModeType.Task]
                  ) {
                    [height, width] = [width, height];
                    [top, left] = [left, top];
                  }

                  return (
                    <Box
                      className="disalbe-br mceNonEditable"
                      data-is-has-component-above={hasComponentAbove}
                      key={component?.componentId}
                      style={{
                        height: `${height}px`,
                        width: `${width}px`,
                        top: `${top}px`,
                        left: `${left}px`,
                        position: "absolute",
                      }}
                    >
                      {renderPreviewComponent(
                        component.type,
                        component,
                        page.limitItemTableLinked,
                        page.offsetItemLinked,
                        mapLinkedTableIndex[String(component?.componentId)],
                        pageIndex,
                        page,
                        page.components,
                        height
                      )}
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default memo(PreviewContainer);
