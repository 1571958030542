import { TemplateComponent } from "interfaces/models/component";

interface Size {
  width: number;
  height: number;
}

interface ConstructorProps {
  component: TemplateComponent;
  components?: TemplateComponent[];
  documentContainerSize?: Size;
  scale?: number;
}

export default class Component {
  component: TemplateComponent;
  components: TemplateComponent[];
  documentContainerSize?: Size;
  scale: number;

  constructor({
    component,
    components,
    documentContainerSize,
    scale = 1,
  }: ConstructorProps) {
    this.component = component;
    this.components = components || [];
    if (documentContainerSize?.width) {
      this.documentContainerSize = {
        width: (documentContainerSize?.width - 1) / scale,
        height: documentContainerSize?.height / scale,
      };
    }
    this.scale = scale;
  }

  /**
   * It returns the nearest components to the current component.
   * @returns An object with the nearest components to the component.
   */
  get nearestComponent() {
    const filterRight = ({ realPosition }: TemplateComponent) => {
      const positionBottomOfComponent =
        this?.component?.realPosition?.y + this?.component?.realSize?.height;

      const isOverLap =
        realPosition?.y < positionBottomOfComponent &&
        realPosition?.y > this?.component?.realPosition?.y;

      return (
        isOverLap &&
        realPosition?.x >
          this?.component?.realPosition?.x + this?.component?.realSize?.width
      );
    };

    return {
      top: this.components
        .filter(
          ({ realPosition, realSize }) =>
            realPosition?.y + realSize?.height <=
            this?.component?.realPosition?.y
        )
        ?.sort((a, b) => {
          return b?.realPosition?.y - a?.realPosition?.y;
        })?.[0],
      bottom: this.components.filter(filterRight)?.sort((a, b) => {
        return a?.realPosition?.y - b?.realPosition?.y;
      })?.[0],
      right: this.components
        .filter(
          ({ realPosition, realSize }) =>
            realPosition?.y + realSize?.height >=
              this?.component?.realPosition?.y &&
            realPosition?.y <
              this?.component?.realPosition?.y +
                this?.component?.realSize?.height &&
            realPosition.x >=
              this.component.realPosition.x + this.component.realSize.width
        )
        ?.sort((a, b) => {
          return a?.realPosition?.x - b?.realPosition?.x;
        })?.[0],
      left: this.components
        .filter(
          ({ realPosition, realSize }) =>
            realPosition?.x + realSize?.width <=
            this?.component?.realPosition?.x
        )
        ?.sort((a, b) => {
          return b?.realPosition?.x - a?.realPosition?.x;
        })?.[0],
    };
  }

  /**
   * It returns the available space for the component to move in the top and bottom directions
   * @returns The available space on the top and bottom of the component.
   */
  get availableSpace() {
    return {
      top: this.nearestComponent.top
        ? this.component?.realPosition?.y -
          (this.nearestComponent.top?.realPosition?.y +
            this.nearestComponent?.top?.realSize?.height)
        : this.component?.realPosition?.y,
      bottom: this.nearestComponent?.bottom
        ? this?.nearestComponent?.bottom?.realPosition?.y -
          (this?.component?.realPosition?.y + this?.component?.realSize?.height)
        : Number(this.documentContainerSize?.height) -
          (this?.component?.realPosition?.y +
            this?.component?.realSize?.height),
      right: this.nearestComponent.right
        ? this?.nearestComponent?.right?.realPosition?.x -
          (this?.component?.realPosition?.x + this?.component?.realSize?.width)
        : Number(this?.documentContainerSize?.width) -
          (this?.component?.realPosition?.x + this?.component?.realSize?.width),
      left: this?.nearestComponent?.left
        ? this?.component?.realPosition?.x -
          (this?.nearestComponent?.left?.realPosition?.x +
            this?.nearestComponent?.left?.realSize?.width)
        : this?.component?.realPosition?.x,
    };
  }

  /**
   * It checks if the component is intersected with another component
   * @param {TemplateComponent} component - TemplateComponent - The component that we want to check if
   * it intersects with the current component.
   * @returns An object with two properties, xDir and yDir.
   */
  isIntersectedWith(component: TemplateComponent) {
    return {
      xDir:
        (this?.component?.realPosition?.y >= component?.realPosition?.y &&
          this?.component?.realPosition?.y <=
            component?.realPosition?.y + component?.realSize?.height) ||
        (this?.component?.realPosition?.y + this?.component?.realSize?.height >=
          component?.realPosition?.y &&
          this?.component?.realPosition?.y +
            this?.component?.realSize?.height <=
            component?.realPosition?.y + component?.realSize?.height),
      yDir:
        (this?.component?.realPosition?.x >= component?.realPosition?.x &&
          this?.component?.realPosition?.x <=
            component?.realPosition?.x + component?.realSize?.width) ||
        (this?.component?.realPosition?.x + this?.component?.realSize?.width >=
          component?.realPosition?.x &&
          this?.component?.realPosition?.x + this?.component?.realSize?.width <=
            component?.realPosition?.x + component?.realSize?.width),
    };
  }
}
