import { Box, Flex, Text } from "@chakra-ui/react";
import { documentItemApi, documentItemLogApi } from "apiClient/v2";
import { DocumentItemKey } from "constants/enum";
import { useAuthorization } from "hooks/usePermission";
import useUserOfProject from "hooks/useUserOfProject";
import { DocumentCategoryDTO } from "interfaces/dtos/documentCategoryDTO";
import {
  DocumentItemDTO,
  DocumentSubItemDTO,
} from "interfaces/dtos/documentItemDTO";
import { DocumentItemLog } from "interfaces/models/documentItem";
import { DocumentTemplate } from "interfaces/models/documentTemplate";
import { User } from "interfaces/models/user";
import { getColorTextByStatus } from "models/document";
import {
  GetContentItemLog,
  getDocumentContentLog,
  OPERATION_ITEM_LOG,
} from "models/documentItemLog";
import { TypeHandleInitData } from "pages/forge-viewer/hooks/useSupportSyncDataOffline";
import { memo, useCallback, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { updateDocumentItem } from "redux/documentSlice";
import { updateElementInArray } from "utils/array";
import { ClickInfo } from "utils/forge/extensions/click-extension";
import { transformBodyForCombineData } from "utils/offline";
import FieldNoComponent from "./FieldNoComponent";
import { useItemLogs } from "./hooks";
import ItemChildren from "./ItemChildren";
import ItemLog from "./ItemLog";
import ItemStatusMenu from "./ItemStatusMenu";
import ItemTasksForSelfInspection from "./ItemTasksForSelfInspection";
import ItemTitle, { ItemTitleType } from "./ItemTitle";

interface Props {
  isLoadedViewer: boolean;
  documentItemSelected?: DocumentItemDTO;
  documentCategorySelected?: DocumentCategoryDTO;
  documentTemplate?: DocumentTemplate;
  currentUser?: User | null;
  itemStatusColor: string;
  isOnline: boolean;
  itemDisplayOrder: number | string;

  clickInfo?: ClickInfo;
  setClickInfo: React.Dispatch<React.SetStateAction<ClickInfo | undefined>>;
}

const FormItemSelfInspection = (props: Props) => {
  const {
    isLoadedViewer,
    documentItemSelected,
    documentCategorySelected,
    documentTemplate,
    currentUser,
    clickInfo,
    itemStatusColor,
    isOnline,
    itemDisplayOrder,
    setClickInfo,
  } = props;

  const dispatch = useDispatch();
  const { listUserById } = useUserOfProject();
  const { itemLogs, setItemLogs } = useItemLogs({
    itemId: documentItemSelected?.id,
  });

  const childrenRef = useRef<DocumentSubItemDTO[]>([]);
  const rules = useAuthorization();

  useEffect(() => {
    childrenRef.current = documentItemSelected?.subItems || [];
  }, [documentItemSelected?.subItems]);

  const insertItemLog = useCallback(
    async ({
      field,
      value,
      operation,
      nameDynamicField,
      prefixFieldName,
      dynamicFieldKeysUpdatedAt,
    }: GetContentItemLog) => {
      if (!currentUser?.id || !documentItemSelected?.id) {
        return;
      }

      const content = getDocumentContentLog({
        field,
        value,
        operation,
        nameDynamicField,
        prefixFieldName,
      }) as any;

      const now = new Date();
      const res = await documentItemLogApi.createItemLog({
        content,
        createdBy: currentUser.id,
        title: content?.title,
        field,
        value,
        itemId: documentItemSelected?.id,
        dynamicFieldKeysUpdatedAt,
        createdAt: now,
        updatedAt: now,
      } as DocumentItemLog);

      const log = res?.data;
      if (log?.id) {
        setItemLogs((prev) => [...prev, log]);
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [documentItemSelected?.id, currentUser?.id]
  );

  const handleSaveDynamicField = useCallback(
    async (subItem: DocumentSubItemDTO) => {
      if (!subItem?.id || !documentItemSelected?.id) {
        return;
      }

      const now = new Date();
      const initSubItem = documentItemSelected.subItems?.find(
        (sub) => sub.id === subItem.id
      );
      documentItemApi.updateSubItem(
        transformBodyForCombineData<DocumentSubItemDTO>({
          body: {
            id: subItem.id,
            data: subItem.data,
            dynamicFieldKeysUpdatedAt: subItem.dynamicFieldKeysUpdatedAt,
            updatedAt: now,
            isIgnoreInsertLog: true,
          } as DocumentSubItemDTO,
          bodyBefore: initSubItem,
          typeInitData: TypeHandleInitData.SUB_ITEM,
        })
      );

      const documentItem = structuredClone(documentItemSelected);
      updateElementInArray({
        array: documentItem?.subItems || [],
        keyIndex: "id",
        element: {
          ...subItem,
          data: subItem.data,
          dynamicFieldKeysUpdatedAt: subItem.dynamicFieldKeysUpdatedAt,
          updatedAt: now,
        } as DocumentSubItemDTO,
      });
      dispatch(
        updateDocumentItem({
          ...documentItem,
          updatedAt: now,
        })
      );
    },
    [documentItemSelected, dispatch]
  );

  const updateItemChild = useCallback(
    ({
      key,
      value: e,
      child,
      operation,
      groupName,
    }: {
      key: string;
      value: any;
      child: DocumentSubItemDTO;
      operation?: OPERATION_ITEM_LOG;
      groupName?: string;
    }) => {
      if (!documentItemSelected?.id) {
        return;
      }

      const value = key === DocumentItemKey.MEMO ? e.target.value : e;
      const children = childrenRef.current.length
        ? structuredClone(childrenRef.current || [])
        : structuredClone(documentItemSelected?.subItems || []);

      const documentItem = children?.find((d) => d.id === child?.id);

      if (value === documentItem?.[key as keyof typeof documentItem]) {
        return;
      }

      const now = new Date();
      const newDocumentItem = {
        ...documentItem,
        [key]: value,
        updatedAt: now,
      } as DocumentSubItemDTO;
      updateElementInArray({
        array: children,
        keyIndex: DocumentItemKey.ID,
        element: newDocumentItem,
      });

      if (!childrenRef.current.length) {
        childrenRef.current = children || [];
      } else {
        childrenRef.current =
          updateElementInArray({
            array: structuredClone(childrenRef.current || []),
            keyIndex: DocumentItemKey.ID,
            element: newDocumentItem,
          }) || [];
      }

      dispatch(
        updateDocumentItem({ ...documentItemSelected, subItems: children })
      );
      const request: Promise<any>[] = [];
      const requestLogs: Promise<any>[] = [];

      if (documentItem?.id) {
        request.push(
          documentItemApi.updateSubItem(
            transformBodyForCombineData<DocumentSubItemDTO>({
              body: {
                id: documentItem.id,
                itemId: documentItem.itemId,
                [key]: value,
                updatedAt: now,
              } as DocumentSubItemDTO,
              bodyBefore: documentItem,
              typeInitData: TypeHandleInitData.SUB_ITEM,
            })
          )
        );
      }

      let _key = key as keyof DocumentSubItemDTO;
      if (_key === "status") {
        _key = "statusSubItemSelfInspection" as any;
      } else if (key === DocumentItemKey.MEMO) {
        // change text write log from 備考　-> 処置内容
        _key = "treatmentContent" as any;
      }

      if (!operation) {
        request.push(
          insertItemLog({
            field: _key as any,
            value,
            prefixFieldName: groupName,
          })
        );
      } else {
        request.push(
          insertItemLog({
            field: _key as any,
            value,
            operation,
            prefixFieldName: groupName,
          })
        );
      }

      Promise.all(request).then(() => {
        Promise.all(requestLogs);
      });
    },
    [documentItemSelected, dispatch, insertItemLog]
  );

  const handleBlurValue = useCallback(
    ({
        key,
        child,
        groupName,
      }: {
        key: keyof DocumentSubItemDTO;
        child: DocumentSubItemDTO;
        groupName?: string;
      }) =>
      (value: any) => {
        updateItemChild({ key, child, value, groupName });
      },
    [updateItemChild]
  );

  const handleChangeValue = useCallback(
    ({
        key,
        child,
        groupName,
      }: {
        key: keyof DocumentSubItemDTO;
        child: DocumentSubItemDTO;
        groupName?: string;
      }) =>
      (value: any) => {
        if (!rules.canEditDocumentItem) return;
        if (!documentItemSelected?.id) {
          return;
        }

        if (key === DocumentItemKey.MEMO) {
          const children = documentItemSelected?.subItems?.map((d) => {
            if (d.id !== child?.id) return d;

            return {
              ...d,
              [key]: value.target.value,
            };
          });

          childrenRef.current = children || [];
          dispatch(
            updateDocumentItem({ ...documentItemSelected, subItems: children })
          );
        } else updateItemChild({ key, child, value, groupName });
      },
    [rules.canEditDocumentItem, documentItemSelected, updateItemChild, dispatch]
  );

  return (
    <Flex flexDirection="column" padding="0px 1.6rem 5.8rem" minHeight="100%">
      {/* title */}
      <Flex gap="1.2rem" alignItems="center" mb="3.2rem">
        <FieldNoComponent
          color={itemStatusColor}
          no={itemDisplayOrder}
          colorText={getColorTextByStatus(documentItemSelected?.status)}
        />

        <Flex flexDirection="column" flex={1}>
          <Text lineHeight="2.2rem" color="#737373" fontSize="1.4rem">
            検査帳票
          </Text>
          <ItemTitle
            insertItemLog={insertItemLog}
            type={ItemTitleType.ITEM}
            item={documentItemSelected}
            inputProps={{ isDisabled: !rules.canEditDocumentItem }}
          />
        </Flex>
      </Flex>

      {documentItemSelected?.subItems?.map((child, index) => (
        <ItemChildren
          key={child.id}
          item={child}
          index={index}
          isOnline={isOnline}
          parentItem={documentItemSelected}
          documentTemplate={documentTemplate}
          listUserById={listUserById}
          handleChangeValue={handleChangeValue}
          handleBlurValue={handleBlurValue}
          updateItemChild={updateItemChild}
          isDisabled={!rules.canEditDocumentItem}
          handleSaveDynamicField={handleSaveDynamicField}
          insertItemLog={insertItemLog}
        />
      ))}

      {/* insert task */}
      <ItemTasksForSelfInspection
        isOnline={isOnline}
        clickInfo={clickInfo}
        documentItem={documentItemSelected}
        documentCategorySelected={documentCategorySelected}
        setClickInfo={setClickInfo}
        insertDocumentItemLog={insertItemLog}
        isDisabled={!rules.canEditDocumentItem}
      />

      {/* logs */}
      <Box
        backgroundColor="#E8E8E8"
        ml="-1.6rem"
        mt="auto"
        width="calc(100% + 3.2rem)"
        padding="2rem 1.6rem"
      >
        <Text
          display="block"
          fontSize="1.6rem"
          color="var(--primary-text-color)"
          fontWeight="bold"
          mb="1.2rem"
        >
          変更履歴
        </Text>
        {itemLogs?.map((item) => (
          <ItemLog
            key={item.id}
            log={item}
            user={listUserById?.[item?.createdBy || ""]}
          />
        ))}
      </Box>

      {/* status */}
      <Flex
        position="absolute"
        bottom="0"
        left="0"
        width="100%"
        height="5.8rem"
        zIndex="12"
        backgroundColor="#E8E8E8"
        borderTop="1px solid rgba(0,0,0,0.15)"
        justifyContent="space-between"
        padding="0px 1.6rem"
        alignItems="center"
        gap="0.8rem"
      >
        <Text
          flexShrink={0}
          color="var(--primary-text-color)"
          fontSize="1.4rem"
        >
          ステータス
        </Text>
        <Box flex={1} maxW="35rem">
          <ItemStatusMenu
            isLoadedViewer={isLoadedViewer}
            currentUser={currentUser}
            documentItemSelected={documentItemSelected}
            documentCategorySelected={documentCategorySelected}
            insertDocumentItemLog={insertItemLog}
            isDisabled={!rules.canEditDocumentItem}
          />
        </Box>
      </Flex>
    </Flex>
  );
};

export default memo(FormItemSelfInspection);
