import { useBoolean, useDisclosure } from "@chakra-ui/react";
import { HandleSelectDrawImageProps } from "components/modal/DrawToolModal";
import {
  DocumentTemplateType,
  InspectionItemType,
  MapInspectionItemColor,
  ModalType,
  SystemModeType,
} from "constants/enum";
import { TaskDTO } from "interfaces/dtos/taskDTO";
import { DocumentCategory } from "interfaces/models/documentCategory";
import { DocumentItem } from "interfaces/models/documentItem";
import { DocumentTask } from "interfaces/models/documentTask";
import { PartnerCompany } from "interfaces/models/partnerCompany";
import { SizePosition } from "interfaces/models/rnd";
import { getTasksFromFilter } from "models/task";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  resetState,
  setCreateTask,
  setModalType,
} from "redux/forgeViewerSlice";
import { RootState } from "redux/store";
import { fetchTaskTypes, setTaskSelected } from "redux/taskSlice";
import {
  clearForgeSelection,
  fitToViewByPosition,
  getExternalIdFromDbId,
  getLevelOfPosition,
  selectDbIds,
} from "utils/forge";
import { ClickInfo } from "utils/forge/extensions/click-extension";
import {
  clearSelectedLabel,
  selectLabel,
} from "utils/forge/extensions/custom-label";
import { logDev } from "utils/logs";

interface Props {
  documentTasksByLevel: DocumentTask[];
  searchValue: string;
  clickInfo?: ClickInfo | null;
  imageURL: any;
  setClickInfo: (clickInfo: ClickInfo) => void;
}

export default function useTask({
  documentTasksByLevel,
  searchValue,
  clickInfo,
  imageURL,
  setClickInfo,
}: Props) {
  const { systemMode, levelSelected, familyInstances, isCreateTask } =
    useSelector((state: RootState) => state.forgeViewer);
  const { settings } = useSelector((state: RootState) => state.user);
  const imageCaptured = useRef<File>();
  const [isOpenCameraModal, setOpenCameraModal] = useBoolean();
  const [isOpenDraw, setOpenDraw] = useBoolean();
  const { bimFileId } = useParams();
  const dispatch = useDispatch();
  const { taskTypes, tasks, isFetchedTaskType } = useSelector(
    (state: RootState) => state.task
  );
  const { documentCategories, documentItemSelected } = useSelector(
    (state: RootState) => state.document
  );
  const navigate = useNavigate();

  const {
    isOpen: isOpenWarningCreateTaskModal,
    onOpen: onOpenWarningCreateTaskModal,
    onClose: onCloseWarningCreateTaskModal,
  } = useDisclosure();

  useEffect(() => {
    if (
      !isFetchedTaskType &&
      (systemMode === SystemModeType.Task || documentItemSelected)
    ) {
      dispatch(fetchTaskTypes());
    }
  }, [isFetchedTaskType, systemMode, documentItemSelected]);

  useEffect(() => {
    if (systemMode !== SystemModeType.Task) {
      return;
    }
    if (clickInfo && isCreateTask && clickInfo.forgeData?.position) {
      if (!levelSelected.guid && !clickInfo.forgeData.dbId) {
        const hasPersist = localStorage.getItem("close-warning-create-task");
        if (!hasPersist) {
          onOpenWarningCreateTaskModal();
        }
      } else {
        if (imageURL.current) {
          setOpenDraw.on();
        } else {
          handleCreateTask();
        }
      }

      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clickInfo]);

  const filterTasks = useMemo(() => {
    const res = getTasksFromFilter({
      tasks,
      settings,
      searchValue,
      documentTasks: documentTasksByLevel,
      taskTypes,
      documentCategories,
    });

    return res;
  }, [
    tasks,
    settings,
    searchValue,
    documentTasksByLevel,
    taskTypes,
    documentCategories,
  ]);

  const getFamilyInstanceOfDocumentCategory = (level: string) => {
    return Object.values(familyInstances)
      .filter((instance) => instance?.level?.includes(level))
      .sort((a, b) => b.dbId - a.dbId);
  };

  const handleChangeModeTask = (
    documentItem: DocumentItem,
    documentCategory?: DocumentCategory
  ) => {
    let dbId: any;
    let position: any;
    if (
      !documentItem.externalId &&
      documentCategory?.documentType === DocumentTemplateType.SELF_INSPECTION
    ) {
      const newFamilyInstances = getFamilyInstanceOfDocumentCategory(
        documentItem.level || ""
      );

      const [firstTask] = newFamilyInstances;
      dbId = firstTask?.dbId;
      position = firstTask?.position;
    } else {
      dbId = documentItem.externalId;
      position = documentItem.position;
    }
    const externalId = getExternalIdFromDbId(dbId) || "";
    const task = filterTasks.find((task) => task.externalId === externalId);
    if (task) {
      dispatch(resetState());
      dispatch(setTaskSelected(task));
      dispatch(setModalType(ModalType.TASK));
      selectLabel([task.id]);
      selectDbIds(dbId, {
        color:
          MapInspectionItemColor[
            (task.status || InspectionItemType.Defect) as InspectionItemType
          ],
      });
      fitToViewByPosition(task.position, false, dbId);

      return;
    }
    dispatch(setCreateTask(true));
    setClickInfo({
      forgeData: {
        dbId: dbId,
        externalId: externalId,
        position: position,
        level: documentItem.level || (position && getLevelOfPosition(position)),
      },
    });
  };

  const handleCreateTask = async () => {
    logDev("handleCreateTask", clickInfo);
    if (!clickInfo || !clickInfo.forgeData) {
      return;
    }
    clearSelectedLabel();
    if (systemMode === SystemModeType.Task && clickInfo.forgeData.externalId) {
      selectDbIds(clickInfo.forgeData.externalId, {
        color:
          MapInspectionItemColor[
            InspectionItemType.Defect as InspectionItemType
          ],
      });
    }
    const task = {
      dbId: clickInfo.forgeData.dbId,
      externalId: clickInfo.forgeData.externalId,
      position: {
        x: clickInfo.forgeData.position.x,
        y: clickInfo.forgeData.position.y,
        z: clickInfo.forgeData.position.z,
      },
      rotation: clickInfo.forgeData.rotation
        ? {
            x: clickInfo.forgeData.rotation.x,
            y: clickInfo.forgeData.rotation.y,
            z: clickInfo.forgeData.rotation.z,
          }
        : undefined,
      bimFileId: bimFileId,
      fov: clickInfo.forgeData.fov || "",
      direction: clickInfo.forgeData.direction || "",
      level: levelSelected.label || clickInfo.forgeData.level,
    } as TaskDTO;
    dispatch(setTaskSelected(task));
    dispatch(setModalType(ModalType.TASK));
    imageURL.current = "";
  };

  const handleSaveFileDrawled = ({ file }: HandleSelectDrawImageProps) => {
    imageCaptured.current = file;
    handleCreateTask();
  };

  const handleCameraError = () => {
    setOpenCameraModal.off();
    handleCreateTask();
  };

  const handleCloseTaskModal = () => {
    clearSelectedLabel();
    clearForgeSelection();
    dispatch(setModalType(ModalType.NONE));
    dispatch(setTaskSelected({} as TaskDTO));
    imageCaptured.current = undefined;
  };

  const onToggleCreateTask = () => {
    dispatch(setCreateTask(!isCreateTask));
  };

  const handleCapture = async (file: File, pos: SizePosition) => {
    imageCaptured.current = file;
    imageURL.current = "";
    setOpenDraw.on();
  };

  const handleRecapture = () => {
    setOpenDraw.off();
    setOpenCameraModal.on();
  };

  const handleCloseDrawToolModal = useCallback(() => {
    setOpenDraw.off();
    imageURL.current = "";

    // remove string query
    navigate(window.location.pathname, { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isOpenWarningCreateTaskModal,
    filterTasks,
    isOpenCameraModal,
    isOpenDraw,
    setOpenCameraModal,
    setOpenDraw,
    imageCaptured,
    handleCloseDrawToolModal,
    onToggleCreateTask,
    handleChangeModeTask,
    handleSaveFileDrawled,
    handleCameraError,
    handleCloseTaskModal,
    handleCapture,
    handleRecapture,
    onCloseWarningCreateTaskModal,
  };
}
