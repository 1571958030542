import { FamilyInstanceKey } from "constants/enum";
import { FamilyInstance } from "interfaces/models/familyInstance";

export const getValueFromFamilyInstace = (
  linkedData: FamilyInstanceKey | undefined,
  familyInstance: FamilyInstance
) => {
  switch (linkedData) {
    case FamilyInstanceKey.LEVEL:
      return familyInstance?.level || "";

    case FamilyInstanceKey.SYMBOL:
      return familyInstance?.symbol || "";

    case FamilyInstanceKey.ROOM:
      return "";

    case FamilyInstanceKey.TYPE_NAME:
      return familyInstance?.typeName || "";

    case FamilyInstanceKey.SIZE:
      return familyInstance?.size || "";

    case FamilyInstanceKey.AIR_VOLUME:
      return familyInstance?.airVolume
        ? String(+parseFloat(familyInstance?.airVolume || "0")?.toFixed(3))
        : "";

    case FamilyInstanceKey.DIAMETER_RADIUS:
      return "";

    default:
      return "";
  }
};
