import React, { memo, ReactNode, useCallback, useMemo } from "react";
import {
  Box,
  Button,
  ButtonProps,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalContentProps,
  ModalFooter,
  ModalHeader,
  ModalHeaderProps,
  ModalOverlay,
} from "@chakra-ui/react";
import { css } from "@emotion/react";

export type ModalInputProps = {
  isOpen: boolean;
  title: ReactNode;
  children: ReactNode;
  onClose?: () => void;
  minWidth?: number | string;
  isCentered?: boolean;
  okLabel?: ReactNode;
  cancelLabel?: ReactNode;
  okDisabled?: boolean;
  cancelDisabled?: boolean;
  btnPropsCancel?: ButtonProps;
  btnPropsOk?: ButtonProps;
  btnOk?: ReactNode;
  modalHeaderProps?: ModalHeaderProps;
  modalFooterProps?: ModalHeaderProps;
  modalContentProps?: ModalContentProps;
  onOk?: () => any;
  onCancel?: () => any;
  icon?: ReactNode;
  bodyProps?: any;
};

const ModalInput = memo<ModalInputProps>(
  ({
    isOpen,
    title,
    onClose,
    minWidth = 1000,
    isCentered = true,
    children,
    okLabel,
    cancelLabel,
    okDisabled,
    cancelDisabled,
    btnPropsCancel,
    btnPropsOk,
    modalHeaderProps,
    modalFooterProps,
    btnOk,
    modalContentProps,
    onOk,
    onCancel,
    icon,
    bodyProps = {},
  }) => {
    const onHandleClose = useCallback(() => {
      onClose && onClose();
    }, [onClose]);

    return (
      <>
        <Modal isOpen={isOpen} onClose={onHandleClose} isCentered={isCentered}>
          <ModalOverlay />
          <ModalContent
            maxW={"100%"}
            flex={`0 0 ${minWidth}`}
            {...modalContentProps}
          >
            <ModalHeader
              css={css`
                border-bottom: 1px solid #dedede;
              `}
              pt="3rem"
              {...modalHeaderProps}
            >
              <ModalCloseButton
                fontSize="12px"
                _focus={{
                  border: "none",
                }}
              />
              <Box
                css={css`
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  padding-top: 3rem;
                  gap: 1rem;
                `}
              >
                {icon}
                <Box
                  css={css`
                    font-size: 2.4rem; /* 36px */
                    line-height: 2.88rem; /* 40px */
                  `}
                >
                  {title}
                </Box>
              </Box>
            </ModalHeader>
            <ModalBody {...bodyProps}>{children}</ModalBody>
            <ModalFooter
              css={css`
                border-top: 1px solid #dedede;
                padding-top: 2rem;
                padding-bottom: 2rem;
                gap: 1rem;
                background: white;
              `}
              {...modalFooterProps}
            >
              {!!cancelLabel && (
                <ActionButton
                  btnProps={btnPropsCancel}
                  disabled={cancelDisabled}
                  onClick={onCancel}
                >
                  {cancelLabel}
                </ActionButton>
              )}
              {btnOk
                ? btnOk
                : !!okLabel && (
                    <ActionButton
                      btnProps={btnPropsOk}
                      onClick={onOk}
                      disabled={okDisabled}
                    >
                      {okLabel}
                    </ActionButton>
                  )}
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    );
  }
);

export const ModalInputSection = memo<{
  children: ReactNode;
  label?: ReactNode;
  tag?: ReactNode;
  tagColor?: "blue" | "red" | string;
  divider?: boolean;
  alignItems?: "center" | "start";
}>(
  ({
    children,
    label,
    tag,
    tagColor: _tagColor = "blue",
    divider,
    alignItems = "center",
  }) => {
    const tagColor = useMemo(() => {
      return (
        (
          {
            blue: "#009be0",
            red: "#ef4444",
          } as any
        )[_tagColor as any] || _tagColor
      );
    }, [_tagColor]);

    return (
      <>
        <Box
          display="flex"
          flexDirection="row"
          gap="3rem"
          alignContent={alignItems}
          borderBottom={divider ? "1px solid #dedede" : "unset"}
          paddingBlockStart={{
            base: "2rem",
            xl: "3rem",
          }}
          paddingBlockEnd={
            divider
              ? {
                  base: "2rem",
                  xl: "3rem",
                }
              : "0px"
          }
          marginInline={{
            base: "1.6rem",
            md: "2.4rem",
            lg: "3.2rem",
            xl: "7rem",
          }}
        >
          <Box
            minWidth={{
              base: "200px",
              xl: "250px",
            }}
            fontSize="1.5rem"
            lineHeight="2rem"
            display="flex"
            flexDirection="row"
            gap="1rem"
            alignItems="center"
          >
            <Box flexGrow={1}>{label || null}</Box>
            {!!tag && (
              <Box
                fontSize="1.25rem"
                lineHeight="1"
                border="1px solid"
                borderColor={tagColor}
                color={tagColor}
                padding="0.5rem"
              >
                {tag}
              </Box>
            )}
          </Box>
          <Box
            flexGrow={1}
            maxW={{
              base: "calc(100% - 200px - 3rem)",
              xl: "calc(100% - 250px - 3rem)",
            }}
            sx={{
              "*": {
                maxWidth: "100%",
                overflow: "hidden",
                textOverflow: "ellipsis",
              },
            }}
          >
            {children}
          </Box>
        </Box>
      </>
    );
  }
);

export const ActionButton = memo<{
  children: ReactNode;
  disabled?: boolean;
  onClick?: () => any;
  btnProps?: ButtonProps;
}>(({ children, disabled, btnProps, onClick }) => {
  return (
    <Button
      onClick={disabled ? undefined : onClick}
      _hover={
        disabled
          ? {}
          : {
              opacity: 0.8,
              color: "#fff",
              bgColor: "#009be0",
            }
      }
      css={css`
        height: 4rem;
        font-size: 1.5rem; /* 24px */
        line-height: 2rem; /* 32px */
        border: 1px solid ${disabled ? "#d4d4d4" : "#c6c6c6"};
        background: ${disabled ? "#d4d4d4" : "white"};
        color: ${disabled ? "#737373" : "#009be0"};
        font-weight: bold;
        cursor: ${disabled ? "no-drop" : ""};
      `}
      {...btnProps}
    >
      {children}
    </Button>
  );
});

export default ModalInput;

// Usage:
// <ModalInput
//   isOpen={true}
//   title={"フォルダを追加"}
//   onClose={() => {}}
//   okLabel={"追加"}
//   cancelLabel={"キャンセル"}
//   okDisabled={true}
// >
//   <ModalInputSection label={"書類名"} tag={"必須"} tagColor={"red"}>
//     <Input
//       placeholder={"入力してください"}
//       css={css`
//               border: 1px solid #a3a3a3;
//               height: 4rem;
//               font-size: 1.5rem; /* 24px */
//               line-height: 2rem; /* 32px */
//             `}
//     />
//   </ModalInputSection>
//   <ModalInputSection
//     label={"書類担当者"}
//     tag={"任意"}
//     tagColor={"blue"}
//     divider={true}
//   >
//     <Select
//       css={css`
//               &.chakra-select {
//                 border: 1px solid #a3a3a3 !important;
//                 border-radius: 3px;
//                 color: #bbc2cd;
//               }
//               height: 4rem;
//               font-size: 1.5rem; /* 24px */
//               line-height: 2rem; /* 32px */
//               padding-left: 1rem;
//               padding-right: 1rem;
//             `}
//       placeholder={"選択してください"}
//     />
//   </ModalInputSection>
//   <ModalInputSection
//     label={"書類テンプレート"}
//     tag={"必須"}
//     tagColor={"red"}
//     divider={true}
//   >
//     <Select
//       css={css`
//               &.chakra-select {
//                 border: 1px solid #a3a3a3 !important;
//                 border-radius: 3px;
//                 color: #bbc2cd;
//               }
//               height: 4rem;
//               font-size: 1.5rem; /* 24px */
//               line-height: 2rem; /* 32px */
//               padding-left: 1rem;
//               padding-right: 1rem;
//             `}
//       placeholder={"選択してください"}
//     />
//   </ModalInputSection>
//   <ModalInputSection label={"階"} tag={"必須"} tagColor={"red"}>
//     <Select
//       css={css`
//               &.chakra-select {
//                 border: 1px solid #a3a3a3 !important;
//                 border-radius: 3px;
//                 color: #bbc2cd;
//               }
//               height: 4rem;
//               font-size: 1.5rem; /* 24px */
//               line-height: 2rem; /* 32px */
//               padding-left: 1rem;
//               padding-right: 1rem;
//             `}
//       placeholder={"選択してください"}
//     />
//   </ModalInputSection>
//   <ModalInputSection label={"検査対象工区"} tag={"必須"} tagColor={"red"}>
//     <RadioGroup>
//       <Radio>階の全体（すべてのスペース）</Radio>
//       <Radio>階の一部（スペースを選択）</Radio>
//     </RadioGroup>
//   </ModalInputSection>
//   <ModalInputSection
//     label={"検査対象（ピン）の自動抽出"}
//     tag={"任意"}
//     tagColor={"blue"}
//     divider={true}
//   >
//     <Box>
//       <Checkbox>
//         <Box
//           css={css`
//                   margin-bottom: -1.875rem;
//                 `}
//         >
//           <Box>
//             検査対象工区内に含まれる、検査対象（ピン）を自動で抽出する
//           </Box>
//           <Box
//             css={css`
//                     font-size: 1rem; /* 16px */
//                     line-height: 1.5rem; /* 24px */
//                   `}
//           >
//             ※他の書類に既に含まれている検査対象（ピン）は、抽出されません。
//           </Box>
//         </Box>
//       </Checkbox>
//     </Box>
//   </ModalInputSection>
// </ModalInput>
