export const S3_PATH = {
  Photo: "photo",
  Audio: "audio",
  Document: "document",
  DocumentLog: "document-log",
  Avatar: "avatar",
  Task: "task",
  TaskSheet: "task-sheet",
};

export const FORGE_DATA_FOLDER_PATH = "forge-data";
